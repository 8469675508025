import React, {FunctionComponent, FormEvent, InputHTMLAttributes} from 'react'
import {Controller} from 'react-hook-form'

import {IFormFieldProps} from './IFormFieldProps'

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  id: string
  name: string
  classes?: string
  classesLabel?: string
  value?: string
  defaultValue?: string
  onChange?: (e: FormEvent<HTMLInputElement>) => void
  defaultChecked?: boolean
  isSmall?: boolean
}

const CheckboxToggle: FunctionComponent<IProps> = ({id, name, classes, classesLabel, label, onChange,defaultChecked, value, defaultValue, register, error, required, isSmall,  ...rest}) => {
  const inputElement = <div className="checkbox-toggle__wrapper">
    <input type="checkbox"
           className={`checkbox-toggle ${isSmall ? 'checkbox-toggle--small' : 'checkbox-toggle--light'} no-skin checkbox-toggle-radio-default`}
           defaultValue={defaultValue} {...rest}
           id={id}
           required={required}
           disabled={rest.readOnly}
           defaultChecked={defaultChecked}
           onChange={onChange}
           title={rest.help} {...register(name)}
    />
    <label className="checkbox-toggle__button" htmlFor={id} />
  </div>

  return (
    <div className={ classes ? `${classes}` : ''}>
      {label &&
        <div className={`form-control form-control--center ${classesLabel ? classesLabel : ''}`}>
          <label htmlFor={id} className={`form-control__label`}>{label}</label>
          {inputElement}
        </div>
      }
      {!label &&
        <div className="form-control form-control--center">
          {inputElement}
        </div>
      }
    </div>
  )
}

export default CheckboxToggle
