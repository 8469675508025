interface DocumentInterface {
  id: number|null
  updatedAt: string|null
  date: string|null
  person: string|null
  documentType: string|null
  title: string|null
  extension: string|null
}

interface DocumentListInterface {
  label: string
  value: string
  documents: DocumentListInterface[]
}

interface DocumentToViewInterface {
  content: string
}

interface DocumentToSendInterface {
  value: string
  title: string
  type: string
}

type DocumentType = {
  [key: string]: DocumentInterface[]
}

class Document implements DocumentInterface {
  public id: number|null
  public updatedAt: string|null
  public date: string|null
  public person: string|null
  public documentType: string|null
  public title: string|null
  public extension: string|null

  constructor(
    id: number|null,
    updatedAt: string|null,
    date: string|null,
    person: string|null,
    documentType: string|null,
    title: string|null,
    extension: string|null
  ) {
    this.id = id
    this.date = date
    this.person = person
    this.updatedAt = updatedAt
    this.documentType = documentType
    this.title = title
    this.extension = extension
  }
}

class DocumentToSend implements DocumentToSendInterface {
  public value: string
  public title: string
  public type: string

  constructor(
    value: string,
    title: string,
    type: string
  ) {
    this.value = value
    this.title = title
    this.type = type
  }
}

export {
  Document,
  DocumentToSend
}
export type { DocumentInterface, DocumentToViewInterface, DocumentListInterface, DocumentType}
