import React, {FunctionComponent} from "react";
import InputDate from "../../../Elements/InputDate";
import {useTranslation} from "react-i18next";
import {v4 as uuidV4} from "uuid";

interface Props {
  register: any
  control: any
  actionArray: any
  parentPath: string
  title: string
  classes?: string
}


const Actions: FunctionComponent<Props> = ({register, control, actionArray, classes = 'col-md-6', parentPath, title}) => {
  const {t} = useTranslation()

  return (
    <div className={classes}>
      <div className="form-bloc form-bloc--action">
      <div className="form-bloc__title">{title}</div>
      <div className="form-bloc__form flex-container">
        {actionArray && Object.entries(actionArray).map((action: [string, any]) => {
          return (
            <div key={uuidV4()} className={`col-md-6 actions-item`}>
              <button type="button"
                      className="button button--white"
                      onClick={() => console.log('action')}
              >{t(`commissioning.form.setting.actions-editions.${action[0]}`)}
              </button>
              <InputDate register={register}
                         control={control}
                         type={'text'}
                         name={`${parentPath || ""}${action[0]}`}
                         id={`${parentPath || ""}${action[0]}`}
              />
            </div>
          )
        })}
      </div>
      </div>
    </div>
  )
}

export default Actions
