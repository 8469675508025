import {FunctionComponent} from 'react'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import '../../../../../assets/styles/components/_tab.scss'

const TabPartnerAdd: FunctionComponent = () => {
  const {t} = useTranslation()

  return (
    <div className={`tab tab--partner-add`}>
      <NavLink
        to={`/${t('url.partner.add-general-information')}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('partner.add.tab-general-information')}
      </NavLink>
      <span className={'tab__item tab__item--disabled'}>{t('partner.add.tab-document-tracking')}</span>
      <span className={'tab__item tab__item--disabled'}>{t('partner.add.tab-beneficial-beneficiaries')}</span>
      <span className={'tab__item tab__item--disabled'}>{t('partner.add.tab-commissioning')}</span>
      <span className={'tab__item tab__item--disabled'}>{t('partner.add.tab-particularities')}</span>
      <span className={'tab__item tab__item--disabled'}>{t('partner.add.tab-transfert')}</span>
    </div>
  )
}

export default TabPartnerAdd
