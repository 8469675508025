import ProductGatewayInterface from '../../../domain/Product/ProductGatewayInterface'
import {ProductListInterface} from '../../../domain/Product/ProductList'
import {ListRequestInterface} from './ListRequest'
import {CallerType} from "../../../domain/Caller/Caller";

export default class ListUseCase
{
  public productGateway

  constructor(ProductGateway: ProductGatewayInterface) {
    this.productGateway = ProductGateway
  }

  async execute(ListRequest: ListRequestInterface, signal?: AbortSignal, sort?: string, order?: string, callerType?: CallerType): Promise<{data: ProductListInterface[]|null, numberOfItems: number}|null> {
    return await this.productGateway.getList(ListRequest.currentPage, ListRequest.itemPerPage, ListRequest.filter, signal, sort, order, callerType).then(response => response)
  }
}
