import React, {FunctionComponent, MouseEvent} from 'react'

import '../../../../../assets/styles/components/_alert.scss'
import expandIcon from "../../../../../assets/images/icons/arrow-bottom--dark.svg";
import Warning from "../../../../../assets/images/icons/Warning";
import {useTranslation} from "react-i18next";

type Props = {
  errorArray: any[] | null
}

const FormErrorInfoDropdown: FunctionComponent<Props> = ({errorArray}) => {
  const {t} = useTranslation()

  function handleCollapse(event: MouseEvent<HTMLElement>) {
    event.currentTarget.classList.toggle('collapse__title--active')
  }

  function arraysEqual(a: string[], b:string[]) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }


  const translateField = (fields: string[]) => {
    if(!fields) return t('partner.alert.fields.unknownError')
    if( fields.length > 1) {
      if(arraysEqual(fields, ["product", "generalInformation", "isin"])) return t('partner.alert.fields.ISINCode')
      if(arraysEqual(fields, ['birthCity', 'code'])) return t('partner.alert.fields.birthCityCode')
      if(arraysEqual(fields, ['birthCity', 'postcode'])) return t('partner.alert.fields.birthCityPostCode')
      if(arraysEqual(fields, ['birthCountry', 'code'])) return t('partner.alert.fields.birthCountryCode')
      if(arraysEqual(fields, ['nationality', 'code'])) return t('partner.alert.fields.nationalityCode')
      if(arraysEqual(fields, ['country', 'code'])) return t('partner.alert.fields.countryCode')
      if(arraysEqual(fields, ['city', 'label'])) return t('partner.alert.fields.cityLabel')
      if(arraysEqual(fields, ['bankInformations'])) return t('partner.alert.fields.bankInformations')
      if(arraysEqual(fields, ['paymentMode'])) return t('partner.alert.fields.paymentMode')
      if(arraysEqual(fields, ['investors'])) return t('partner.alert.fields.investors')
      if(arraysEqual(fields, ['propertyType'])) return t('partner.alert.fields.propertyType')
    } else {
      return t(`partner.alert.fields.${fields[0]}`)
    }
  }

  if (errorArray && errorArray?.length) {
    return (
      <div className="form-error-info-dropdown col-md-12">
        <div>
          <button type="button" className={`collapse__title-full`} onClick={handleCollapse}>
            <div className="form-error-info-dropdown--msg flex">
              <Warning />
              <span>{t('partner.alert.msg')}</span>
            </div>
            <span className="form-error-info-dropdown--number">{errorArray.length}</span>
            <img src={expandIcon} alt=""/>
          </button>
          <div className="collapse__content-full flex-container">
            <ul>
              {errorArray?.map((err) => {
                return (
                  <li>
                    {translateField(err.fields)}
                    {err.code && ` (${err.code})`}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    )
  } else return null

}

export default FormErrorInfoDropdown