import {AddressInterface} from './Address'

interface GeneralAddressInterface {
  id: string|null
  address: AddressInterface|null
  createdAt: string|null
  updatedAt: string|null
  deletedAt: string|null
  main: boolean|null
  type: string|null
  status: string|null
  socialReason?: string|null
}

export const GENERAL_ADDRESS_STATUS_ACTIVE = 'active';
export const GENERAL_ADDRESS_STATUS_INACTIVE = 'inactive';

class GeneralAddress implements GeneralAddressInterface {

  public id: string|null
  public address: AddressInterface|null
  public createdAt: string|null
  public updatedAt: string|null
  public deletedAt: string|null
  public main: boolean|null
  public type: string|null
  public status: string|null
  public socialReason?: string|null

  constructor(
    id: string|null,
    address: AddressInterface|null,
    createdAt: string|null,
    updatedAt: string|null,
    deletedAt: string|null,
    main: boolean|null,
    type: string|null,
    status: string|null
  ) {
    this.id = id
    this.address = address
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.deletedAt = deletedAt
    this.main = main
    this.type = type
    this.status = status
  }
}

export {
  GeneralAddress
}
export type { GeneralAddressInterface }
