import {FunctionComponent} from 'react'

import quoteIcon from '../../../../assets/images/icons/quote.svg'

import '../../../../assets/styles/components/_quote.scss'
import {QuoteInterface} from '../../../../domain/Quote/Quote'

type Props = {
  classes: string
  quote: QuoteInterface
}

const Quote: FunctionComponent<Props> = ({classes, quote}) => {
  return (
    <section className={`quote ${classes}`}>
      <div className="quote__icon"><img src={quoteIcon} alt="" /></div>
      <div className="quote__text">
        {quote.content}
        <div className="quote__author">{quote.author}</div>
      </div>
    </section>
  );
}

export default Quote
