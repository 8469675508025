import React, {FunctionComponent, InputHTMLAttributes, useEffect} from "react";
import {IFormFieldProps} from "./IFormFieldProps";
import ValidatorGateway from "../../../../gateway/Validator/ValidatorGateway";
import {useTranslation} from "react-i18next";
import IbanValidatorUseCase from "../../../../useCase/Validator/IbanValidatorUseCase";
import InputMask from "react-input-mask";

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  id: string;
  name: string;
  classes?: string;
  labelClasses?: string;
  inputClasses?: string;
  enableValidation: boolean;
}

const Iban: FunctionComponent<IProps> = ({
  id,
  name,
  classes,
  label,
  register,
  error,
  required,
  labelClasses,
  inputClasses,
  enableValidation,
  ...rest
}) => {
  const ibanValidatorUseCase = new IbanValidatorUseCase(new ValidatorGateway())
  const {t} = useTranslation()

  const validateIban = async (value: string) => {
    if ([null, ''].includes(value) || !enableValidation) {
      return true
    }
    return ibanValidatorUseCase.execute(value).then((response) => {
      if (!response) {
        return false
      }

      return true
    })
  }
  return (
    <>
      <div className={ classes ? `${ classes }` : '' }>
        { label &&
          <>
            <div className="form-control">
              <div className="flex items-end">
                <label htmlFor={ id } className={labelClasses ? labelClasses + " form-control__label" : "form-control__label"}>{ label }</label>
              </div>
              <div className="form-control__input">
                <InputMask
                           id={id}
                           required={required}
                           {...register(name, { required: required, validate: validateIban })}
                           mask={enableValidation ? "aa** **** **** **** **** **** **** ****" : "**** **** **** **** **** **** **** ****"}
                           maskPlaceholder={""}
                />
              </div>
            </div>
            {error && <div className="form-control__errors">{t('error.iban')}</div>}
          </>
        }
      </div>
    </>
  );
}

export default Iban
