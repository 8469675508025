import {PersonInterface} from './Person'

interface BeneficialBeneficiariesInterface {
  id: string
  status: string|null
  ppe: boolean
  shareholding: string
  person: PersonInterface|null
}

class BeneficialBeneficiaries implements BeneficialBeneficiariesInterface {

  public id: string
  public status: string|null
  public ppe: boolean
  public shareholding: string
  public person: PersonInterface|null

  constructor(
    id: string,
    status: string|null,
    ppe: boolean,
    shareholding: string,
    person: PersonInterface|null
  ) {
    this.id = id
    this.status = status
    this.ppe = ppe
    this.shareholding = shareholding
    this.person = person
  }
}

export {
  BeneficialBeneficiaries
}
export type { BeneficialBeneficiariesInterface }
