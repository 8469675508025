import ChartBarWrapper from '../component/ChartBar/ChartBarWrapper'
import ChartDoughnutWrapper from '../component/ChartDoughnut/ChartDoughnutWrapper'
import {KpiResponseInterface, ViewModelInterface} from '../Type/Kpi'
import ChartBarPresenter from '../../../presenter/ChartBar/ChartBarPresenter'
import ChartDoughnutPresenter from '../../../presenter/ChartDoughnut/ChartDoughnutPresenter'
import BlocInformationCount from '../component/BlocInformationCount/BlocInformationCount'
import NumberPresenter from '../../../presenter/Number/NumberPresenter'

function getViewModel(kpi: KpiResponseInterface) {
  if (kpi.type === 'number') {
    const presenter = new NumberPresenter(kpi)
    presenter.load()
    return presenter.immutableViewModel()
  }

  if (kpi.type === 'bar_chart' || kpi.type === 'bar_chart_horizontal') {
    const presenter = new ChartBarPresenter(kpi)
    presenter.load()
    return presenter.immutableViewModel()
  }

  if (kpi.type === 'doughnut') {
    const presenter = new ChartDoughnutPresenter(kpi)
    presenter.load()
    return presenter.immutableViewModel()
  }
}

function renderKpiByType(kpi: ViewModelInterface) {
  if (kpi.type === 'number') {
    return <BlocInformationCount number={kpi.data} label={kpi.title} classes={`bloc-information-count--min`} description={kpi.description}/>
  }

  if (kpi.type === 'bar_chart' || kpi.type === 'bar_chart_horizontal') {
    return <ChartBarWrapper viewModel={kpi} />
  }

  if (kpi.type === 'doughnut') {
    return <ChartDoughnutWrapper viewModel={kpi} />
  }
}

export {
  getViewModel,
  renderKpiByType,
}
