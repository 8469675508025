export const DecimalStepOptions = [
  {
    value: 0,
    label: "0 - exemple 00"
  },
  {
    value: 1,
    label: "1 - exemple 00.X"
  },
  {
    value: 2,
    label: "2 - exemple 00.XX"
  },
  {
    value: 3,
    label: "3 - exemple 00.XXX"
  },
  {
    value: 4,
    label: "4 - exemple 00.XXXX"
  },
  {
    value: 5,
    label: "5 - exemple 00.XXXXX"
  },
  {
    value: 6,
    label: "6 - exemple 00.XXXXXX"
  },
  {
    value: 7,
    label: "7 - exemple 00.XXXXXXX"
  },
  {
    value: 8,
    label: "8 - exemple 00.XXXXXXXX"
  },
]

export const DefaultDecimalStep = 2