import RecurrenceGatewayInterface from '../../../domain/Movement/RecurrenceGatewayInterface'
import {ListRequestInterface} from './ListRequest'
import {MovementListInterface} from "../../../domain/Movement/MovementList";

export default class ListUseCase
{
  public productGateway

  constructor(RecurrenceGateway: RecurrenceGatewayInterface) {
    this.productGateway = RecurrenceGateway
  }

  async execute(ListRequest: ListRequestInterface, signal?: AbortSignal, sort?: string, order?: string): Promise<{data: MovementListInterface[]|null, numberOfItems: number}|null> {
    return await this.productGateway.getList(ListRequest.currentPage, ListRequest.itemPerPage, ListRequest.filter, signal, sort, order).then(response => response)
  }
}
