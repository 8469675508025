import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {useAppSelector} from '../../../../../store/hook'

import HeaderPage from '../../../../component/HeaderPage/HeaderPage'
import Form from '../../../../component/Form/Dismemberment/Form/Form'
import InitializeDismemberment from '../../../../../../useCase/Movement/Initialization/InitializeDismemberment'
import ChoiceInvestorDismemberment from '../../../../component/Sidebar/Movement/ChoiceInvestorDismemberment'
import DismembermentGateway from "../../../../../../gateway/Movement/Dismemberment/DismembermentGateway";
import DismembermentGetUseCase from "../../../../../../useCase/Movement/Get/DismembermentGetUseCase";
import {DismembermentInterface} from "../../../../../../domain/Movement/Dismemberment/Dismemberment";
import Upload from "../../../../component/Upload/Upload";
import DocumentGateway from "../../../../../../gateway/Movement/Dismemberment/Document/DocumentGateway";
import {setOpenUploadForm} from "../../../../../store/component/event";
import MatchUrl from "../../../../../../domain/Utils/MatchedUrl";
import ChoicePartner from "../../../../component/Sidebar/Prospect/ChoicePartner";
import DocumentList from "../../../../component/Document/DocumentList";
import {TError} from "../../../../../../domain/Error/Error";
import ErrorPage from "../../../Error/ErrorPage";
import Spinner from "../../../../component/Spinner/Spinner";


const AddOrEdit: FunctionComponent = () => {
  const {t} = useTranslation()
  const {uuid} = useParams()
  const navigate = useNavigate()

  const transactionRules = useAppSelector((state) => state.me.me?.rules.transaction.actions)
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const reloadMovement = useAppSelector((state) => state.movement.reloadMovement)
  const [dismemberment, setDismemberment] = useState<DismembermentInterface | null>(null)
  const openUploadForm = useAppSelector((state) => state.event.openUploadForm)
  const isLectureMode = MatchUrl.find(location.pathname, 'lecture')
  const [error, setError] = useState<TError|null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if(transactionRules) {
      if(!transactionRules?.update && !isLectureMode) navigate(`/${t('url.movements.read-dismemberment')}/${uuid}`)
    }
  }, [transactionRules])

  useEffect(() => {
    if (uuid !== undefined) {
      new DismembermentGetUseCase(new DismembermentGateway()).execute(uuid).then(response => {
        if (response !== null) {
          setDismemberment(response)
          const closedStatus = ["cancelled", "validated", "subscribed"]
          if(response?.status && closedStatus.includes(response.status)) {
            navigate(`/${t('url.movements.read-dismemberment')}/${uuid}`)
          }
          setIsLoading(false)
        }
      }).catch((e) => {
        setIsLoading(false)
        setError(e)
      })
    } else {
      setDismemberment(new InitializeDismemberment().initializeDismemberment())
      setIsLoading(false)
    }
  }, [uuid, reloadMovement])

  if(error) return <ErrorPage code={error.code.toString()} />

  return (
    <>
      {!isLectureMode && <ChoiceInvestorDismemberment/>}
      {!isLectureMode && <ChoicePartner/>}
      <DocumentList />
      {dismemberment &&
      <Upload openUploadForm={openUploadForm} gateway={new DocumentGateway(dismemberment.id ?? null, null)}
              callback={setOpenUploadForm}/>}
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''} movement`}>
        <HeaderPage title={t('movement.dismemberment.title')}/>
        {dismemberment && !isLoading ? <Form movement={dismemberment} isLectureMode={isLectureMode}/> : <section className="container--spinner"><Spinner size={150}/></section>}
      </main>
    </>
  )

}

export default AddOrEdit
