import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {setOpenHistoryTransferDetails} from '../../../../store/component/event'
import {RelationInterface} from '../../../../../domain/Prospect/Relation'
import getClassForOverlay from '../../../util/Sidebar'
import PortfolioTransfertDatalist from "../../Datalist/PortfolioTransfertDatalist";
import PartnerGateway from "../../../../../gateway/Partner/PartnerGateway";
import {IPagination} from "../../../../../domain/Pagination/IPagination";

interface IFormInput {
  relation: RelationInterface
}


const TransferHistoryDetails: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const historyTransferDetails = useAppSelector((state) => state.event.historyTransferDetails)
  const [transferDetails, setTransferDetails] = useState({})

  const preferences = localStorage.getItem("preferences")

  const [pagination, setPagination] = useState<IPagination>({
    nbElements: 0,
    itemPerPage: preferences ? JSON.parse(preferences).numberRows : 50,
    page: 1
  })

  function handleClose() {
    dispatch(setOpenHistoryTransferDetails({show: false}))
  }

  useEffect(() => {
    if(historyTransferDetails?.item?.transfer_id && historyTransferDetails.partnerId) {
      new PartnerGateway()
        .getTransferHistoryDetails(
          historyTransferDetails?.partnerId,
          historyTransferDetails?.item?.transfer_id,
          pagination.itemPerPage, pagination.page
        )
        .then(data => {
          setTransferDetails(data)
        })
    }

  }, [historyTransferDetails, pagination.page, pagination.itemPerPage])

  return (
    <>
      <div className={`overlay ${getClassForOverlay(historyTransferDetails.show)}`} onClick={() => handleClose()}/>
      <div className={`sidebar sidebar--right sidebar--right ${historyTransferDetails.show ? 'sidebar--active' : ''}`}>
        <form className="form-bloc form-bloc--prospect-relation-form">
          <div className="sidebar__content">
            <div className="title">{t('partner.form.transfer.history.sidebar.title', {
              date: historyTransferDetails?.item?.executed_at ?? "N/A",
              target: historyTransferDetails?.item?.target_social_reason
            })}</div>
            <PortfolioTransfertDatalist movementLinkedToPartner={transferDetails}
                                        noAction
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        partnerId={historyTransferDetails?.partnerId ?? null}
                                        transfertId={historyTransferDetails?.item?.transfer_id ?? null}
            />
          </div>
          <footer className="sidebar__footer">
            <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => handleClose()}>{t('common.cancel')}</button>
          </footer>
        </form>
      </div>
    </>
  );
}

export default TransferHistoryDetails
