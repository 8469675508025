import {FormEvent, FunctionComponent, ReactElement, SelectHTMLAttributes} from 'react'
import {useTranslation} from 'react-i18next'


import {IFormFieldProps} from './IFormFieldProps'
import {ReferentielItemInterface} from '../../../../domain/Referentiel/ReferentielItemInterface'

interface IProps extends SelectHTMLAttributes<HTMLSelectElement>, IFormFieldProps {
  classes: string
  containerClasses?: string
  id: string
  name: string
  label?: string | ReactElement
  labelClasses?: string
  valueClasses?: string
  options: ReferentielItemInterface[] | null
  onChange?: (e: FormEvent<HTMLSelectElement>) => void
  noChoiceOption?: boolean
}

const SelectCustom: FunctionComponent<IProps> = (
  {
    id,
    name,
    classes,
    containerClasses,
    label,
    labelClasses,
    valueClasses,
    onChange,
    register,
    required,
    noChoiceOption,
    options,
    ...rest
  }
) => {
  const {t} = useTranslation()

  const select = <div className="form-control__input">
    <select
      id={id}
      required={required}
      disabled={rest.readOnly}
      name={name}
      title={rest.help}
      {...register(name, {onChange: onChange})}
      className={valueClasses ?? ''}
    >
      {!noChoiceOption && <option value="">{t('common.choice-option')}</option>}
      {options?.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
    </select>
  </div>

  return (
    <div className={classes}>
      {label &&
          <div className={`form-control ${containerClasses}`}>
              <label htmlFor={id} className={`form-control__label ${labelClasses}`}>{label}</label>
            {select}
          </div>
      }
      {!label && select}
    </div>
  )
}

export default SelectCustom
