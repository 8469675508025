import {FunctionComponent} from 'react'

type Props = {
  color?: string
}

const Products: FunctionComponent<Props> = ({color}) => {
    return (
        <svg width="53" height="50" viewBox="0 0 53 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.2367 41.5434V8.62772H17.3365V41.5434H15.2367Z" fill={color || 'white'} stroke="#467E9E" strokeWidth="0.3"/>
            <path d="M20.4731 41.5434V8.62772H26.1181V41.5434H20.4731Z" fill={color || 'white'} stroke="#467E9E" strokeWidth="0.3"/>
            <path d="M29.1993 41.5434V8.62772H31.2991V41.5434H29.1993Z" fill={color || 'white'} stroke="#467E9E" strokeWidth="0.3"/>
            <path d="M34.4353 41.5434V8.62772H36.5352V41.5434H34.4353Z" fill={color || 'white'} stroke="#467E9E" strokeWidth="0.3"/>
            <path d="M39.6166 41.5434V8.62772H44.2253V41.5434H39.6166Z" fill={color || 'white'} stroke="#467E9E" strokeWidth="0.3"/>
            <path d="M8.3101 41.5434V8.62772H12.1007V41.5434H8.3101Z" fill={color || 'white'} stroke="#467E9E" strokeWidth="0.3"/>
            <path d="M4.06941 4.23704H3.91941V4.38704V12.2001H0.510596V0.828223H11.8825V4.23704H4.06941Z" fill={color || 'white'} stroke="#467E9E" strokeWidth="0.3"/>
            <path d="M48.6158 4.38704V4.23704H48.4658H40.6528V0.828223H52.0247V12.2001H48.6158V4.38704Z" fill={color || 'white'} stroke="#467E9E" strokeWidth="0.3"/>
            <path d="M48.4658 45.8795H48.6158V45.7295V37.9165H52.0247V49.2883H40.6528V45.8795H48.4658Z" fill={color || 'white'} stroke="#467E9E" strokeWidth="0.3"/>
            <path d="M3.91941 45.7295V45.8795H4.06941H11.8825V49.2883H0.510596V37.9165H3.91941V45.7295Z" fill={color || 'white'} stroke="#467E9E" strokeWidth="0.3"/>
        </svg>
    )
}

export default Products
