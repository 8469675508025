interface DocumentCustomerInterface {
  id: number
  updatedAt: string
  type: string
  title: string
  extension: string
}

interface DocumentCustomerToViewInterface {
  content: string
}

interface DocumentCustomerToSendInterface {
  value: string
  title: string
  type: string
  rib_id?: string
}

class DocumentCustomer implements DocumentCustomerInterface {
  public id: number
  public updatedAt: string
  public type: string
  public title: string
  public extension: string

  constructor(
    id: number,
    updatedAt: string,
    type: string,
    title: string,
    extension: string
  ) {
    this.id = id
    this.updatedAt = updatedAt
    this.type = type
    this.title = title
    this.extension = extension
  }
}

class DocumentCustomerToSend implements DocumentCustomerToSendInterface {
  public value: string
  public title: string
  public type: string

  constructor(
    value: string,
    title: string,
    type: string,
  ) {
    this.value = value
    this.title = title
    this.type = type
  }
}

export {
  DocumentCustomer as DocumentCustomer,
  DocumentCustomerToSend
}
export type { DocumentCustomerInterface, DocumentCustomerToViewInterface }
