import {Person, PersonInterface} from "../../../domain/Prospect/Person";

export default class InitializePerson {
  initializePerson(): PersonInterface {
    return new Person(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
  }

  initializePersonMoral(): PersonInterface {
    return new Person(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      'moral',
      'moral',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
  }
}
