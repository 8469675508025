import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import { setOpenPartnerAddAddressEvent } from '../../../../store/component/event';
import { addAddress, updateAddress } from '../../../../store/component/partner';
import {
  Address,
  PARTNER_ADDRESS_STATUS_INACTIVE,
  PARTNER_ADDRESS_STATUS_ACTIVE
} from '../../../../../domain/Partner/Address';
import blockScrollBody from '../../../util/BlockScroll';
import getClassForOverlay from '../../../util/Sidebar';
import {
  ReferentielInterface
} from '../../../../../domain/Referentiel/ReferentielInterface'
import { City } from '../../../../../domain/Referentiel/City/City';
import { Country } from '../../../../../domain/Referentiel/Country/Country';
import AlertInfo from '../../Alert/AlertInfo/AlertInfo';
import CheckboxToggle from '../../Elements/CheckboxToggle';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmationModal from '../../Modal/ConfirmationModal';
import { toastSuccess } from '../../../util/Toast';
import { usePrompt } from '../../../util/Navigation';
import CountryDatalist from '../../Datalist/Country/CountryDatalist';
import CityAndPostcode from '../../Datalist/CityAndPostcode/CityAndPostcode';
import SelectCustom from "../../Elements/Select";

interface IFormInput {
  id: string
  addressPrincipal: boolean
  civility: string
  lastName: string
  maidenName: string
  firstName: string
  service: string
  addressFirstLine: string
  addressSecondLine: string
  addressThirdLine: string
  zipcode: string
  city: City|null
  country: Country|null
  pnd: boolean,
  status: string
}

type Props = {
  referential: ReferentielInterface
}

const AddAddress: FunctionComponent<Props> = ({referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openPartnerAddAddress = useAppSelector((state) => state.event.openPartnerAddAddressForm)
  const addressArray = useAppSelector((state) => state.partner.addressArray)
  const [alreadyHaveMain, setAlreadyHaveMain] = useState<boolean>(false)

  const { register, control,  watch, handleSubmit, reset, setValue, getValues } = useForm<IFormInput>();

  useEffect(() => {
    const addressItemMain = addressArray.filter(addressItem => addressItem.addressPrincipal)
    const currentIsMain = openPartnerAddAddress.address !== null && addressItemMain && addressItemMain.length > 0 && addressItemMain[0].id !== openPartnerAddAddress.address.id
    setAlreadyHaveMain(addressItemMain.length > 0 && (currentIsMain || openPartnerAddAddress.address === null))
  }, [addressArray, openPartnerAddAddress])

  useEffect(() => {
    blockScrollBody(openPartnerAddAddress.show)

    if (openPartnerAddAddress.address) {
      setValue('id', openPartnerAddAddress.address.id)
      setValue('addressPrincipal', openPartnerAddAddress.address.addressPrincipal)
      setValue('civility', openPartnerAddAddress.address.civility)
      setValue('lastName', openPartnerAddAddress.address.lastName)
      setValue('maidenName', openPartnerAddAddress.address.maidenName)
      setValue('firstName', openPartnerAddAddress.address.firstName)
      setValue('service', openPartnerAddAddress.address.service)
      setValue('addressFirstLine', openPartnerAddAddress.address.addressFirstLine)
      setValue('addressSecondLine', openPartnerAddAddress.address.addressSecondLine)
      setValue('addressThirdLine', openPartnerAddAddress.address.addressThirdLine)
      setValue('zipcode', openPartnerAddAddress.address.zipcode)
      setValue('city', openPartnerAddAddress.address.city)
      setValue('country', openPartnerAddAddress.address.country)
      setValue('pnd', openPartnerAddAddress.address.pnd)
      setValue('status', openPartnerAddAddress.address.status || PARTNER_ADDRESS_STATUS_INACTIVE)
    } else {
      setValue('id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('addressPrincipal', false)
      setValue('civility', '')
      setValue('lastName', '')
      setValue('maidenName', '')
      setValue('firstName', '')
      setValue('service', '')
      setValue('addressFirstLine', '')
      setValue('addressSecondLine', '')
      setValue('addressThirdLine', '')
      setValue('zipcode', '')
      setValue('city', null)
      setValue('country', openPartnerAddAddress.defaultCountry)
      setValue('pnd', false)
      setValue('status', PARTNER_ADDRESS_STATUS_ACTIVE)
    }
  }, [openPartnerAddAddress])

  const watchCivility = watch('civility');
  const watchCountry = watch('country')
  const watchStatus = watch('status')

  useEffect(() => {
    if (watchCountry && !openPartnerAddAddress?.address?.city && !openPartnerAddAddress?.address?.zipcode) {
      setValue('city', null)
      setValue('zipcode', "")
    }
  }, [watchCountry])

  useEffect(() => {
    if (watchStatus && watchStatus !== PARTNER_ADDRESS_STATUS_ACTIVE) {
      setValue('addressPrincipal', false)
    }
  }, [watchStatus])

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    if (data.status !== PARTNER_ADDRESS_STATUS_ACTIVE) {
      data.addressPrincipal = false;
    }

    const address = new Address(
      data.id,
      data.addressPrincipal,
      data.civility,
      data.lastName,
      data.maidenName,
      data.firstName,
      data.service,
      data.addressFirstLine,
      data.addressSecondLine,
      data.addressThirdLine,
      data.zipcode,
      data.city,
      data.country,
      data.pnd,
      data.status
    )

    if (openPartnerAddAddress.address) {
      dispatch(updateAddress(address))
    } else {
      dispatch(addAddress(address))
    }
    dispatch(setOpenPartnerAddAddressEvent({show: false, address: null}))

    reset()
    setValue('id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())

    toastSuccess(t('partner.notify.add-address-success'))
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));


  function handleClose() {
    dispatch(setOpenPartnerAddAddressEvent({show: false, address: null}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openPartnerAddAddress.show)}`} onClick={() => handleClose()} />
      <div
        className={`sidebar sidebar--right sidebar--right ${openPartnerAddAddress.show ? 'sidebar--active' : ''}`}>
        <form onSubmit={handleSubmit(onSubmit)} className="form-bloc form-bloc--partner-address-form">
          <div className="sidebar__content">
            <div className="title">{t('partner.form.bloc-address.add')}</div>
            <div className="form-bloc__form flex-container">
              {alreadyHaveMain &&
                <div className="col-md-12">
                  <AlertInfo text={t('partner.form.bloc-address.main-already-exist')} />
                </div>
              }
              <CheckboxToggle
                id="addressPrincipal"
                name="addressPrincipal"
                register={register} label={t('partner.form.bloc-address.address-principal')}
                readOnly={alreadyHaveMain || watchStatus !== PARTNER_ADDRESS_STATUS_ACTIVE}
                classes="col-md-6"
              />
              <SelectCustom
                classes="col-md-6"
                id="status"
                name="status"
                options={ referential.partner.address_status }
                register={ register }
                label={ t('partner.form.bloc-address.status') }
                noChoiceOption={true}
              />
              <div className="col-md-6">
                <div className="form-control">
                  <label htmlFor="civility"
                         className="form-control__label">{t('partner.form.bloc-address.civility')}</label>
                  <div className="form-control__input">
                    <select {...register('civility')}>
                      <option value="">{t('common.choice-option')}</option>
                      {referential.global.title_physic.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-control">
                  <label htmlFor="lastName"
                         className="form-control__label">{t('partner.form.bloc-address.lastname')}</label>
                  <div className="form-control__input">
                    <input type="text" {...register('lastName')} />
                  </div>
                </div>
              </div>
              {(watchCivility === 'mme' || watchCivility === 'mrs' || watchCivility === 'mmes') &&
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="maidenName"
                           className="form-control__label">{t('partner.form.bloc-address.maiden-name')}</label>
                    <div className="form-control__input">
                      <input type="text" {...register('maidenName')} />
                    </div>
                  </div>
                </div>
              }
              <div className="col-md-12">
                <div className="form-control">
                  <label htmlFor="firstName"
                         className="form-control__label">{t('partner.form.bloc-address.firstname')}</label>
                  <div className="form-control__input">
                    <input type="text" {...register('firstName')} />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-control">
                  <label htmlFor="service"
                         className="form-control__label">{t('partner.form.bloc-address.service')}</label>
                  <div className="form-control__input">
                    <input type="text" {...register('service')} />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-control">
                  <label htmlFor="addressFirstLine"
                         className="form-control__label">{t('partner.form.bloc-address.address-first-line')}</label>
                  <div className="form-control__input">
                    <input type="text" {...register('addressFirstLine')} />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-control">
                  <label htmlFor="addressSecondLine"
                         className="form-control__label">{t('partner.form.bloc-address.address-second-line')}</label>
                  <div className="form-control__input">
                    <input type="text" {...register('addressSecondLine')} />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-control">
                  <label htmlFor="addressThirdLine"
                         className="form-control__label">{t('partner.form.bloc-address.address-third-line')}</label>
                  <div className="form-control__input">
                    <input type="text" {...register('addressThirdLine')} />
                  </div>
                </div>
              </div>
              <CityAndPostcode control={control}
                               nameCity="city"
                               namePostcode="zipcode"
                               country={getValues('country')}
                                   register={register}
                                   setValue={setValue}
                                   getValues={getValues}
                                   mode="col"
                                   classes="col-md-12 u-px0 u-mb0"
                                   disabled={false}
                                   cityMandatory={true}
              />
              <div className="col-md-12">
                  <div className="form-control">
                      <label htmlFor="country"
                             className="form-control__label">{t('partner.form.bloc-address.country')}</label>
                      <div className="form-control__input">
                          <CountryDatalist control={control} name="country" defaultValue={getValues('country')}/>
                      </div>
                  </div>
              </div>
              <div className="col-md-12">
                <div className="form-control form-control--center">
                  <label htmlFor="pnd" className="form-control__label">{t('partner.form.bloc-address.pnd')}</label>
                  <div className="checkbox-toggle__wrapper">
                    <input type="checkbox" {...register('pnd')} id="pnd"
                           className="checkbox-toggle checkbox-toggle--light no-skin checkbox-toggle-radio-default" />
                    <label className="checkbox-toggle__button" htmlFor="pnd" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="sidebar__footer">
            <button type="submit" className="button button--ink-2 u-mrm">{t('common.save')}</button>
            <button type="button" className="button button--ink-2 button--ink-2--outline"
                    onClick={() => handleClose()}>{t('common.cancel')}</button>
          </footer>
        </form>
      </div>
    </>
  );
}

export default AddAddress
