import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {CKEditor, CKEditorConfig} from "ckeditor4-react";

type Props = {
  data: string|null
  onChange: (data: string) => void
  config: CKEditorConfig
  readonly: boolean
}
const CommentBlock: FunctionComponent<Props> = ({data, onChange, config, readonly}) => {
  const {t} = useTranslation()

  return (
    <div className={`form-bloc`}>
      <div className="form-bloc__title">{t('movement.pre-subscription.form.comment.title')}</div>
      <div className="form-bloc__form flex-container">
        <div className="col-md-12 form-bloc__form">
          <CKEditor
            initData={data}
            onChange={(event) => onChange(event.editor.getData())}
            config={config}
            readOnly={readonly}
          />
        </div>
      </div>
    </div>
  )
}
export default CommentBlock
