import Caller from "../../domain/Api/Caller";
import envVariable from "../../infrastructure/presentation/util/envVariable";

export default class ValidatorGateway {
  validate(type: string, value: string): Promise<boolean> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/validator/${type}`, {
      value: value
    })
    .then(data => {
      if (data) {
        return true
      }

      return false
    })
    .catch(err => {
      return false
    })
  }
}
