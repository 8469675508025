import Caller from '../../../domain/Api/Caller'
import envVariable from '../../../infrastructure/presentation/util/envVariable'
import DocumentGatewayInterface from '../../../domain/Document/DocumentGatewayInterface'
import {DocumentListInterface, DocumentToSend, DocumentToViewInterface} from "../../../domain/Document/Document";
import {
  DocumentProductInterface,
  DocumentProductToSend,
  DocumentProductToViewInterface
} from "../../../domain/Product/Document/Document";

export default class DocumentGateway implements DocumentGatewayInterface {

  productId: string|null

  constructor(
    productId: string|null
  ) {
    this.productId = productId
  }

  getFiles(): Promise<any> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/products/${this.productId}/files`, {})
    .then(data => {
      if (data) {
        return data.data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }

  getFile(fileId: number): Promise<string|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/products/${this.productId}/files/${fileId}`, {})
    .then(data => {
      if (data) {
        return data.content
      }

      return null
    })
    .catch(() => {
      return null
    })
  }
  save(uuid: string, documents: DocumentToSend[]): Promise<[]|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/products/${uuid}/files`, {
      files: documents
    })
    .then(data => {
      if (data) {
        return data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }
  getRibFiles(productId: string, ribId: string): Promise<DocumentProductInterface[]|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/products/${productId}/rib/${ribId}/files`, {})
      .then(data => {
        if (data) {
          return data.data
        }
      })
  }
  getRibFile(productId: string, ribId: string, fileId: number): Promise<DocumentProductToViewInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/products/${productId}/rib/${ribId}/files/${fileId}`, {})
      .then(data => {
        if (data) {
          return data.content
        }
      })
  }
  saveRib(productId: string, ribId: string, documents: DocumentProductToSend[]): Promise<[]|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/products/${productId}/rib/${ribId}`, {
      files: documents
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }
  getList(): Promise<DocumentListInterface[]> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/product/${this.productId}/documents`, {})
    .then(data => {
      if (data) {
        return data
      }
    })
    .catch(() => {
      return null
    })
  }
  deleteFile(fileId: number): Promise<boolean> {
    //TODO: Fonctionnalitée uniquement disponible pour les presouscription https://myrem.atlassian.net/browse/DS-630?atlOrigin=eyJpIjoiMzJkNzMxN2Y0ZGMyNGVhMDljMDQ1NzBhM2Y1OGY2NjYiLCJwIjoiaiJ9
    return Promise.resolve(false)
  }
}
