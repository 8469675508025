import {FunctionComponent, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDropzone} from 'react-dropzone'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import getClassForOverlay from '../../../util/Sidebar'
import blockScrollBody from '../../../util/BlockScroll'
import {setOpenProductUploadForm} from '../../../../store/component/event'
import {
  DocumentProductInterface,
  DocumentProductToSend
} from '../../../../../domain/Product/Document/Document'
import Converter from '../../../util/Converter'
import DocumentProductGateway from '../../../../../gateway/Product/Document/DocumentGateway'

import '../../../../../assets/styles/components/_uploader.scss'
import uploadIcon from '../../../../../assets/images/icons/upload-file.png'
import {toastError, toastSuccess} from "../../../util/Toast";
import Spinner from "../../Spinner/Spinner";

const Upload: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const [isUploading, setIsUploading] = useState(false);

  const openProductUploadForm = useAppSelector((state) => state.event.openProductUploadForm)

  useEffect(() => {
    blockScrollBody(openProductUploadForm.show)
    setIsUploading(false)
  }, [openProductUploadForm.show])

  function handleClose() {
    dispatch(setOpenProductUploadForm({show: false, documents: null, productId: null, type: null}))
  }

  const [documents] = useState<DocumentProductInterface[]|null>(openProductUploadForm.documents)

  const onDrop = useCallback(acceptedFiles => {
    const promises: Promise<any>[] = []
    acceptedFiles.map((file: File) => {
      promises.push(new Converter().toBase64(file).then(base64 => {
        if (typeof base64 === 'string') {
          return new DocumentProductToSend(
            base64,
            file.name,
            openProductUploadForm.type || ''
        )
        }
      }))
    })
    setIsUploading(true);

    Promise.all(promises).then(documents => {
      if (documents.length > 0) {
        if (!openProductUploadForm.ribId) {
          new DocumentProductGateway(openProductUploadForm.productId).save(openProductUploadForm.productId || '', documents).then(response => {
            if (null !== response) {
              dispatch(setOpenProductUploadForm(
                {
                  show: false,
                  documents: documents,
                  productId: openProductUploadForm.productId,
                  type: openProductUploadForm.type
                }
              ))
              toastSuccess(t('common.doc-add-success'));
            } else {
              toastError(t('common.doc-add-error'));
            }
            setIsUploading(false);
          })
        } else {
          new DocumentProductGateway(openProductUploadForm.productId).saveRib(openProductUploadForm.productId || '', openProductUploadForm.ribId, documents).then(response => {
            if (null !== response) {
              dispatch(setOpenProductUploadForm(
                {
                  show: false,
                  documents: documents,
                  productId: null,
                  type: null,
                  ribId: null
                }
              ))
              toastSuccess(t('common.doc-add-success'));
            } else {
              toastError(t('common.doc-add-error'));
            }
            setIsUploading(false);
          })
        }
      }
    })
  }, [openProductUploadForm])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop
  })

  const removeFile = (documentToRemove: DocumentProductInterface) => () => {
    console.log('remove')
  }

  const show = (documentToShow: any) => () => {
    console.log(documentToShow)
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openProductUploadForm.show)}`} onClick={() => handleClose()} />
      <div
        className={`sidebar sidebar--right sidebar--right ${openProductUploadForm.show ? 'sidebar--active' : ''}`}>
            <div className="form-bloc form-bloc--product-address-form">
              <div className="sidebar__content">
                <div className="title">{t('common.add-document')}</div>
                <div className="form-bloc__form">
                  <div className="container">
                    <div{...getRootProps()} className={`uploader ${isDragActive ? 'dzu-dropzoneActive' : ''}`}>
                      <input {...getInputProps()} />

                      <div><img src={uploadIcon} alt="upload" /></div>
                      <p className="u-ptm">{t('common.upload-file')}</p>
                    </div>
                      {isUploading &&
                        <div className="u-mam">
                          <Spinner />
                        </div>
                      }
                  </div>
                </div>
                <div className="">
                  {(documents &&
                    (documents.map(document => (
                        <div className="dzu-previewContainer" key={document.id}>
                          <div className="upload-progress__wrapper">
                            <div
                              className="button button-primary--outline button--small button--width-fit button--height-fit"
                              onClick={show(document)}>{document.title}</div>
                            <div
                              className="button button-error--outline button--small button--width-fit button--height-fit"
                              onClick={removeFile(document)}>{t('button.remove')}
                            </div>
                          </div>
                        </div>
                      ))
                    )
                  )}

                  {isUploading && <Spinner />}
                </div>
                {isUploading && <Spinner />}
              </div>
              <footer className="sidebar__footer">
                <button type="button" className="button button--ink-2 button--ink-2--outline"
                        onClick={() => handleClose()}>{t('common.cancel')}</button>
              </footer>
            </div>
          </div>

    </>
  )
}

export default Upload
