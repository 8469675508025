import {PersonInterface} from './Person'

interface ProspectLegalRepresentativesInterface {
  id: string
  status: string|null
  code: string|null
  person: PersonInterface|null
}

class ProspectLegalRepresentatives implements ProspectLegalRepresentativesInterface {

  public id: string
  public status: string|null
  code: string|null
  public person: PersonInterface|null

  constructor(
    id: string,
    status: string|null,
    code: string|null,
    person: PersonInterface|null
  ) {
    this.id = id
    this.status = status
    this.code = code
    this.person = person
  }
}

export {
  ProspectLegalRepresentatives
}
export type { ProspectLegalRepresentativesInterface }
