import {AddressInterface} from './Address'
import {City} from "../Referentiel/City/City";
import {Country} from "../Referentiel/Country/Country";

interface PersonInterface {
  id: string|null
  title: string|null
  firstname: string|null
  lastname: string|null
  maidenname: string|null
  birthdate: string|null
  birthCity: City|null
  birthCountry: Country|null
  nationality: Country|null
  ppe: boolean|null
  ppeCategory: string|null
  address: AddressInterface|null
  gerant: boolean
  email: string|null
  phone: string|null
}

class Person implements PersonInterface{

  public id: string|null
  public title: string|null
  public firstname: string|null
  public lastname: string|null
  public maidenname: string|null
  public birthdate: string|null
  public birthCity: City|null
  public birthCountry: Country|null
  public nationality: Country|null
  public ppe: boolean|null
  public ppeCategory: string|null
  public address: AddressInterface|null
  public gerant: boolean
  public email: string|null
  public phone: string|null

  constructor(
    id: string|null,
    title: string|null,
    firstname: string|null,
    lastname: string|null,
    maidenname: string|null,
    birthdate: string|null,
    birthCity: City|null,
    birthCountry: Country|null,
    nationality: Country|null,
    ppe: boolean|null,
    ppeCategory: string|null,
    address: AddressInterface|null,
    gerant: boolean,
    email: string|null,
    phone: string|null
  ) {
    this.id = id
    this.title = title
    this.firstname = firstname
    this.lastname = lastname
    this.maidenname = maidenname
    this.birthdate = birthdate
    this.birthCity = birthCity
    this.birthCountry = birthCountry
    this.nationality = nationality
    this.ppe = ppe
    this.ppeCategory = ppeCategory
    this.address = address
    this.gerant = gerant
    this.email = email
    this.phone = phone
  }
}

export {
  Person
}
export type { PersonInterface }
