import {Movement} from "../../../domain/Movement/Movement";
import {RedemptionWithdrawal} from "../../../domain/Movement/RedemptionWithdrawal";

export default class InitializeRedemptionWIthDrawal {
  initializeInitializeRedemptionWIthDrawal() {
    return new RedemptionWithdrawal(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      false,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      [],
      null,
      [],
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
  }
}
