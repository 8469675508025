import {PreSubscription, PreSubscriptionInterface} from '../../../domain/PreSubscription/PreSubscription'

export default class InitializePreSubscription {
  initializePreSubscription(): PreSubscriptionInterface {
    return new PreSubscription(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      null,
      null,
      null,
      false,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      null,
      null,
      [],
      false,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
  }
}
