import React, {FunctionComponent, useEffect, useState} from 'react'
import {Controller} from 'react-hook-form'
import Select from 'react-select'

import PledgeOrganizationGateway from "../../../../../gateway/Pledge/PledgeOrganizationGateway";

type Props = {
  id?: string
  name: string
  label: string
  classes: string
  control: any
  defaultValue: {
    id: string | null
    label: string | null
    value: string | null
  } | null
}

type TSelectProps = {
  id: string,
  label: string,
  value: string
}

const customStyles = {
  control: (base: any) => ({
    ...base,
    minHeight: 25
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: 4
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 4
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: '0px 6px'
  }),
  input: (base: any) => ({
    ...base,
    margin: 0,
    padding: 0
  })
}

const PledgeOrganizationSelect: FunctionComponent<Props> = ({
                                                              classes,
                                                              id,
                                                              label,
                                                              control,
                                                              name,
                                                              defaultValue,
                                                              ...rest
                                                            }) => {
  const [pledgeOrganization, setPledgeOrganization] = useState<TSelectProps[] | []>([])

  useEffect(() => {
    new PledgeOrganizationGateway().getAll().then((data) => {
      const parseArray = parseResponseToSelectData(data.data)
      setPledgeOrganization([...parseArray])
    })
  }, [])

  const parseResponseToSelectData = (datas: []) => {
    return datas.map((data: { id: string, name: string }) => {
      return {
        id: data.id,
        label: data.name,
        value: data.id
      }
    })
  }

  return (
    <>
      <div className={`${classes}`}>
        <div className="form-control">
          <label htmlFor={id} className="form-control__label">{label}</label>
          <div className="form-control__input">
            <Controller
              name={name}
              control={control}
              render={({
                         field: {onChange, value, name}
                       }) => (
                <Select
                  name={name}
                  value={value}
                  options={pledgeOrganization}
                  onChange={(option) => onChange(option)}
                  styles={customStyles}
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default PledgeOrganizationSelect;