import {FunctionComponent, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../../store/hook";
import {setOpenPledgeOrganizationForm} from "../../../../../store/component/event";
import iconAdd from "../../../../../../assets/images/icons/add.svg";
import PledgeOrganizationDatalist from "../../../Datalist/PledgeOrganization/PledgeOrganization";

const PledgeOrganization: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()


  return (
    <div>
      <div className="flex-container">
        <div className="col-md-12">
          <div className={`form-bloc form--bloc--bank-details`}>
            <div className="form-bloc__title">{t('account.form.pledge-organization.title')}</div>
            <div className="form-bloc__shortcut u-txt-right">
              <button type="button" className="button-reset u-txt-with-icon" onClick={() => dispatch(setOpenPledgeOrganizationForm({show: true, pledgeOrganization: null}))}>
                <img src={iconAdd} alt="" />
                {t('account.form.pledge-organization.add')}
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className={`form-bloc form--bloc--bank-details`}>
            <div className="form-bloc__title">{t('account.form.pledge-organization.title')}</div>
            <div className="form-bloc__shortcut u-txt-right">
              <PledgeOrganizationDatalist />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PledgeOrganization
