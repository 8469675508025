import {FunctionComponent} from 'react'

type Props = {
  color?: string
}

const Partners: FunctionComponent<Props> = ({color}) => {
    return (
        <svg width="50" height="33" viewBox="0 0 50 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3354 0.731079C18.7055 0.731079 20.9262 1.31178 22.9428 2.2707C22.1573 2.86006 21.4317 3.51411 20.7569 4.22372C19.3767 3.71696 17.8966 3.40711 16.3354 3.40711C9.19413 3.40711 3.38429 9.21533 3.38429 16.3562C3.38429 23.5 9.19409 29.3083 16.3354 29.3083C23.4762 29.3083 29.2839 23.4999 29.2839 16.3562C29.2839 13.1858 28.1017 10.3131 26.2063 8.06474C26.8767 7.48604 27.6317 7.00645 28.4379 6.59954C30.6098 9.28349 31.9605 12.6498 31.9605 16.3562C31.9605 24.9711 24.9508 31.9818 16.3354 31.9818C7.71942 31.9818 0.709778 24.9716 0.709778 16.3567C0.709778 7.74172 7.71943 0.731079 16.3354 0.731079Z" fill={color || 'white'} stroke="#92AFC4" strokeWidth="0.5"/>
            <path d="M33.3442 0.730103C41.9591 0.730103 48.9697 7.74076 48.9697 16.3557C48.9697 24.9706 41.9591 31.9813 33.3442 31.9823C30.9946 31.9823 28.7915 31.4115 26.7878 30.4686C27.5791 29.8786 28.3125 29.2221 28.9928 28.5116C30.3534 29.0033 31.8082 29.3078 33.3442 29.3078C40.4855 29.3078 46.2937 23.4995 46.2937 16.3562C46.2937 9.21491 40.4854 3.40664 33.3442 3.40664C26.2029 3.40664 20.3936 9.21489 20.3936 16.3562C20.3936 19.5233 21.5741 22.3926 23.4648 24.6397C22.7911 25.2204 22.0505 25.7185 21.2498 26.1233C19.0723 23.4377 17.7186 20.0673 17.7186 16.3557C17.7186 7.74024 24.7282 0.730103 33.3442 0.730103Z" fill={color || 'white'} stroke="#92AFC4" strokeWidth="0.5"/>
        </svg>
    )
}

export default Partners
