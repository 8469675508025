import React, {FunctionComponent, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {SubmitHandler, useForm, useFormState} from 'react-hook-form'
import {v4 as uuidV4} from 'uuid'
import {confirmAlert} from 'react-confirm-alert'

import {ProductInterface} from '../../../../../../domain/Product/Product'
import InitialiazeProduct from '../../../../../../useCase/Product/Initialization/InitialiazeProduct'
import {ProductDepositary} from '../../../../../../domain/Product/ProductDepositary'
import {DepositaryInterface} from '../../../../../../domain/Depositary/Depositary'
import UpdateUseCase from '../../../../../../useCase/Product/Update/UpdateUseCase'
import AddUseCase from '../../../../../../useCase/Product/Add/AddUseCase'
import {ProductType} from '../../../../../../domain/Product/ProductType'
import GetReferentielUseCase from '../../../../../../useCase/Depositary/GetReferentielUseCase'
import DepositaryGateway from '../../../../../../gateway/Depositary/DepositaryGateway'
import {
  ReferentielInterface
} from '../../../../../../domain/Referentiel/ReferentielInterface'
import ProductGateway from '../../../../../../gateway/Product/ProductGateway'
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {usePrompt} from "../../../../util/Navigation";
import InitializeDepositary from "../../../../../../useCase/Depositary/Initialization/InitializeDepositary";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import InputDate from "../../../Elements/InputDate";

interface IFormInput {
  productDepositary: ProductDepositary
  depositary: string
  depositaryFile: string

}

type Props = {
  referential: ReferentielInterface
  product?: ProductInterface
  isLectureMode?: boolean
}

const Depositary: FunctionComponent<Props> = ({product, isLectureMode, referential}) => {
  const {t} = useTranslation()
  const { productType } = useParams()

  const { register, control, handleSubmit, reset, setValue } = useForm<IFormInput>();

  const [referentielDepositary, setReferentielDepositary] = useState<DepositaryInterface[]>([])

  useEffect(() => {
    if (product?.depositary) {
      setValue('depositary', product.depositary.depositary?.id ?? '')
      setValue('productDepositary', product.depositary)
      setValue('depositaryFile', product.depositary.file ? "1" : "0")
    }
  }, [product])

  useEffect(() => {
    (new GetReferentielUseCase(new DepositaryGateway())).execute().then(response => {
      setReferentielDepositary(response)
    });
  }, [])

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    reset(data)
    if (undefined === product) {
      product = new InitialiazeProduct().initializeProduct()
    }

    if (
      null == product.productType &&
      referential?.product.product_type &&
      referential?.product.product_type.findIndex(type => type.value === productType) > -1
    ) {
      product.productType = new ProductType(
        null,
        null,
        null,
        null,
        undefined !== productType ? productType : 'other',
        null,
        null,
        true
      )
    }

    product.depositary = data.productDepositary

    if (product.depositary) {
      product.depositary.depositary = new InitializeDepositary().initializeDepository()
      product.depositary.depositary.id = data.depositary
    }
    const productGateway = new ProductGateway()

    if (null !== product.id) {
      new UpdateUseCase(productGateway).execute(product).then(response => {
        if (null !== response) {
          toastSuccess(t('product.notify.update-success'))
        } else {
          toastError(t('product.notify.update-error'));
        }
      })
    }
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));


  const renderFooter = () => {
    if(isLectureMode) return null
    return <footer className={`form-bloc__footer`}>
      <button type="submit" className="button button--ink-2">{t('common.save')}</button>
      <button className="button button--ink-2 button--ink-2--outline">{t('common.cancel')}</button>
    </footer>
  }

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
      <div className="flex-container">
        <div className="col-md-12">
          <div className={`form-bloc form--bloc--fiscality`}>
            <div className="form-bloc__title">{t('product.form.depositary.title')}</div>
            <div className="form-bloc__form flex-container">
              <div className="col-md-6">
                <div className="form-control">
                  <label htmlFor="fiscalityIfu"
                         className="form-control__label">{t('product.form.depositary.name')}</label>
                  <div className="form-control__input">
                    <select {...register('depositary')} id="fiscalityIfu">
                      <option value="">{t('common.choice-option')}</option>
                      {referentielDepositary.map(depositary => <option key={uuidV4()} value={depositary.id ?? ''} selected={
                        product?.depositary?.depositary && product?.depositary?.depositary.id === depositary.id ?
                          true: false
                      }>{depositary.name}</option>)}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-control">
                  <label htmlFor="fiscalityBenefit"
                         className="form-control__label">{t('product.form.depositary.file')}</label>
                  <div className="form-control form-control--label-auto">
                    <div className="form-control__input">
                      <label className="form-label form-label--input-radio">
                        <input
                          type="radio"
                          value="1"
                          {...register('depositaryFile')}
                          onChange={(e) => {
                            if (product && product.depositary) {
                              product.depositary.file = true
                            }
                          }}
                        />
                        {t('common.yes')}
                      </label>
                    </div>
                    <div className="form-control__input">
                      <label className="form-label form-label--input-radio">
                        <input
                          type="radio"
                          value="0"
                          {...register('depositaryFile')}
                          onChange={(e) => {
                            if (product && product.depositary) {
                              product.depositary.file = false
                            }
                          }}
                        />
                        {t('common.no')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-control form-control--textarea">
                  <label htmlFor="fiscalityComment"
                         className="form-control__label">{t('product.form.depositary.periodicity')}</label>
                  <div className="form-control__input">
                    <select {...register('productDepositary.periodicity')}>
                      <option value="">{t('common.choice-option')}</option>
                      {referential.product.periodicity.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-control form-control--textarea">
                  <label htmlFor="fiscalityComment"
                         className="form-control__label">{t('product.form.depositary.prestation')}</label>
                  <div className="form-control__input">
                    <select {...register('productDepositary.prestation')}>
                      <option value="">{t('common.choice-option')}</option>
                      {referential.product.depositary_prestation.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-control form-control--textarea">
                  <label htmlFor="fiscalityComment"
                         className="form-control__label">{t('product.form.depositary.regulatory')}</label>
                  <div className="form-control__input">
                    <select {...register('productDepositary.regulatory')}>
                      <option value="">{t('common.choice-option')}</option>
                      {referential.product.depositary_regulatory.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-control form-control--textarea">
                  <label htmlFor="fiscalityComment"
                         className="form-control__label">{t('product.form.depositary.reporting')}</label>
                  <div className="form-control__input">
                    <InputDate id="generalScaleDate"
                               name="productDepositary.reporting"
                               register={register}
                               control={control}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className={`form-bloc form--bloc--fiscality`}>
          <div className="form-bloc__title">{t('product.form.depositary.pec-file')}</div>
          <div className="form-bloc__form flex-container">
            <div className="col-md-6">
              <div className="form-control form-control--textarea">

              </div>
            </div>
          </div>
        </div>
      </div>
      {renderFooter()}
    </form>
  )
}

export default Depositary
