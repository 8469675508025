import PartnerGatewayInterface from '../../../domain/Partner/PartnerGatewayInterface'
import {PartnerInterface} from '../../../domain/Partner/Partner'
import PartnerGateway from "../../../gateway/Partner/PartnerGateway";

export default class UpdateUseCase
{
  public partnerGateway

  constructor(PartnerGateway: PartnerGateway) {
    this.partnerGateway = PartnerGateway
  }

  async execute(Partner: PartnerInterface): Promise<{ uuid: string }|null> {
    return await this.partnerGateway.update(Partner).then(response => response)
  }
}
