function NumericStringToFloat(numericString: string): number {
  return parseFloat(numericString.replace(',', '.'))
}

function FloatToNumericString(value: number): string {
  return String(value).replace('.', ',')
}

export {
  NumericStringToFloat,
  FloatToNumericString
}
