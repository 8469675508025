export const QuoteList = [
  {"content": "Ceux qui ont beaucoup à espérer et rien à perdre seront toujours dangereux.", "author": "Edmund Burke"},
  {"content": "Seule la paresse fatigue le cerveau.", "author": "Louis Pauwels"},
  {"content": "L'expérience, voilà le maître en toutes choses.", "author": "Jules César"},
  {"content": "La vie, c'est comme une bicyclette, il faut avancer pour ne pas perdre l'équilibre.", "author": "Albert Einstein"},
  {"content": "Que de temps perdu à gagner du temps !", "author": "Paul Morand"},
  {"content": "La réalité est une chose au-dessus de laquelle il faut s'élever.", "author": "Liza Minnelli"},
  {"content": "Ils ne savaient pas que c'était impossible, alors ils l'ont fait.", "author": "Mark Twain"},
  {"content": "Le plaisir est fugitif, pas le bonheur.", "author": "Sharon Stone"},
  {"content": "Une sortie, c'est une entrée que l'on prend dans l'autre sens.", "author": "Boris Vian"},
  {"content": "On ne peut rêver que si on a les pieds sur terre. Plus les racines sont profondes, plus les branches sont porteuses.", "author": "Juliette Binoche"},
  {"content": "Il faut collectionner les pierres qu'on vous jette. C'est le début d'un piédestal.", "author": "Hector Berlioz"},
  {"content": "La seule chose qu'on est sûr de ne pas réussir est celle qu'on ne tente pas.", "author": "Paul-Emile Victor"},
  {"content": "Dieu a donné une soeur au souvenir et il l'a appelée espérance.", "author": "Michel-Ange"},
  {"content": "Le rire est, il faut le redire, une arme indispensable. Pour séduire, notamment...", "author": "Elisabeth Badinter "},
  {"content": "Les insomniaques ont tendance à se trouver. Le monde rétrécit à cette heure de la nuit.", "author": "James Ellroy"},
  {"content": "Ecrire, c'est aussi ne pas parler. C'est se taire. C'est hurler sans bruit.", "author": "Marguerite Duras"},
  {"content": "La vie ne vaut d'être vécue sans amour.", "author": "Serge Gainsbourg "},
  {"content": "J'aimerais pouvoir repousser les pensées qui empoisonnent ma joie, et pourtant je prends un certain plaisir à m'offrir à elles.", "author": "Frédéric Chopin"},
  {"content": "Le bonheur existe. Il est dans l'amour, la santé, la paix, le confort matériel, les arts, la nature et encore à des milliers d'endroits.", "author": "Michèle Morgan"},
  {"content": "Tout le monde savait que c'était impossible. Il est venu un imbécile qui ne le savait pas et qui l'a fait.", "author": "Marcel Pagnol"},
  {"content": "L'esprit libre et curieux de l'homme est ce qui a le plus de prix au monde.", "author": "John Steinbeck"},
  {"content": "La liberté commence où l'ignorance finit.", "author": "Victor Hugo"},
  {"content": "S'il y a de la place dans le coeur Il y a toujours de la place dans la maison.", "author": "Thomas Moore"},
  {"content": "Innover, c'est savoir abandonner des milliers de bonnes idées.", "author": "Steve Jobs"},
  {"content": "Dignité est un mot qui ne comporte pas de pluriel.", "author": "Paul Claudel"},
  {"content": "La notoriété, c'est comme de manger des cacahuètes : quand on commence, on ne peut plus s'arrêter.", "author": "Andy Warhol"},
  {"content": "Paix et liberté ne peuvent être séparées, car personne ne peut être en paix tant qu'il n'est pas libre.", "author": "Malcolm X"},
  {"content": "Le plus beau jour que j'ai jamais vécu fut celui ou j'appris à pleurer à volonté.", "author": "Kurt Cobain"},
  {"content": "Conquérir sa joie vaut mieux que de s'abandonner à sa tristesse.", "author": "André Gide"},
  {"content": "Peut-être sommes nous toutefois les gardiens de la vérité de la vie.", "author": "Henri Laurens"},
  {"content": "Une vie sans amour, c'est une vie sans soleil.", "author": "Hervé Bazin"},
  {"content": "Ceux qui se taisent disent plus de choses que ceux qui parlent tout le temps.", "author": "Octave Mirbeau"},
  {"content": "Quand on met le pied dans les idées générales, on glisse.", "author": "François Nourissier"},
  {"content": "L'amour triomphe de tout ; nous aussi cédons à l'amour.", "author": "Virgile"},
  {"content": "Maman tu es la plus belle du monde Car tant d'amour inonde tes jolis yeux.", "author": "Henri Salvador"},
  {"content": "Ce qui est terrible sur cette terre, c'est que tout le monde a ses raisons.", "author": "Jean Renoir"},
  {"content": "Les plus grandes âmes sont capables des plus grands vices aussi bien que des plus grandes vertus. ", "author": "René Descartes"},
  {"content": "Un ami, c'est à la fois nous-mêmes et l'autre, l'autre en qui nous cherchons le meilleur de nous-mêmes, mais également ce qui est meilleur que nous.", "author": "Joseph Kessel"},
  {"content": "Pour voir loin, il faut y regarder de près.", "author": "Pierre Dac"},
  {"content": "Puisqu'on ne peut changer la direction du vent, il faut apprendre à orienter les voiles.", "author": "James Dean"},
  {"content": "On n'est jamais mieux trompé sur terre que par soi-même.", "author": "Charles Dickens"},
  {"content": "L'impossible est le seul adversaire digne de l'homme.", "author": "Andrée Chedid"},
  {"content": "Chacun doit être l'aide-jardinier de sa propre âme.", "author": "Joris-Karl Huysmans"},
  {"content": "Le temps mène la vie dure à ceux qui veulent le tuer.", "author": "Jacques Prévert"},
  {"content": "Aimer un être, c'est tout simplement reconnaître qu'il existe autant que vous.", "author": "Simone Weil"},
  {"content": "Les erreurs sont les portes de la découverte.", "author": "James Joyce"},
  {"content": "La souffrance efface jusqu'aux émotions les plus primitives de l'homme.", "author": "Mary Shelley"},
  {"content": "Le bel âge après tout, c'est celui où l'on sait à quels rêves on tient le plus ; celui où l'on peut encore en réaliser quelques-uns.", "author": "Benoîte Groult "},
  {"content": "C'est dans l'effort que l'on trouve la satisfaction et non dans la réussite. Un plein effort est une pleine victoire.", "author": "Gandhi"},
  {"content": "Plus on constate qu'on n'est pas comme tout le monde, plus on veut être comme tout le monde.", "author": "François Cavanna"},
  {"content": "Il y a souvent plus d'angoisse à attendre un plaisir qu'à subir une peine.", "author": "Colette"},
  {"content": "Les hommes n'ont jamais l'air si heureux que le jour où ils abdiquent leur liberté ", "author": "Charles Nodier"},
  {"content": "Ou on tire les ficelles, ou on essaie de s'en débarrasser.", "author": "Paul Newman"},
  {"content": "La vie est un rêve, c'est le réveil qui nous tue.", "author": "Virginia Woolf"},
  {"content": "On écrit parce que personne n'écoute.", "author": "Georges Perros"},
  {"content": "La chance s'attrape par les cheveux, mais elle est chauve.", "author": "Stendhal"},
  {"content": "Il ne faut pas attendre d'être parfait pour commencer quelque chose de bien.", "author": "Abbé Pierre"},
  {"content": "Je ne trempe pas ma plume dans un encrier mais dans la vie.", "author": "Blaise Cendrars"},
  {"content": "Il n'y a pas de bonne cuisine si au départ elle n'est pas faite par amitié pour celui ou celle à qui elle est destinée.", "author": "Paul Bocuse "},
  {"content": "Bonheur : faire ce que l'on veut et vouloir ce que l'on fait.", "author": "Françoise Giroud"},
  {"content": "Plus on s'est trompé dans sa vie, plus on donne de leçons.", "author": "Gilles Deleuze"},
  {"content": "Il y a bien des manières de ne pas réussir, mais la plus sûre est de ne jamais prendre de risques.", "author": "Benjamin Franklin"},
  {"content": "Le désavantage de l'esprit - cette drôlerie supérieure - c'est qu'il s'exerce aux dépens de quelque chose ou de quelqu'un.", "author": "Anne-Marie Carrière"},
  {"content": "A la fin, nous nous souviendrons non pas des mots de nos ennemis, mais des silences de nos amis.", "author": "Martin Luther King"},
  {"content": "Le meilleur moyen de réaliser l'impossible est de croire que c'est possible.", "author": "Lewis Carroll"},
  {"content": "J'aime, donc je suis. Dès que je cesse d'aimer, je cesse d'être.", "author": "Gilbert Cesbron"},
  {"content": "Espérer,c'est toujours continuer à aimer et à croire", "author": " Agatha Christie"},
  {"content": "Tout tient à un fil, on est toujours en péril.", "author": "Alberto Giacometti"},
  {"content": "Je ne mens pas, je change juste d'avis sans arrêt.", "author": "David Bowie"},
  {"content": "Se vouloir libre, c'est aussi vouloir les autres libres.", "author": "Simone de Beauvoir"},
  {"content": "Il ne faut jamais juger les gens sur leurs fréquentations. Tenez, Judas, par exemple, il avait des amis irréprochables.", "author": "Paul Verlaine"},
  {"content": "Je préfère mourir debout que vivre à genoux.", "author": "Charb "},
  {"content": "Je ne crois pas au génie, seulement au dur travail.", "author": "Michel Petrucciani"},
  {"content": "On se sert des couleurs, mais on peint avec le sentiment.", "author": "Jean Chardin"},
  {"content": "Parler de ses peines, c'est déjà se consoler.", "author": "Albert Camus"},
  {"content": "Vous n'êtes, après tout, qu'un minuscule individu dans le vaste monde.", "author": "J. R. R. Tolkien"},
  {"content": "Faire le bien, éviter le mal, c'est ça le paradis.", "author": "Henri Vincenot"},
  {"content": "Plus l'homme est grand, plus il a de soucis.", "author": "Joachim Du Bellay"},
  {"content": "Le métier des parents, c'est de savoir se séparer de ses enfants.", "author": "Marcel Rufo"},
  {"content": "Si la musique nous est si chère, c'est qu'elle est la parole la plus profonde de l'âme, le cri harmonieux de sa joie et de sa douleur.", "author": "Romain Rolland"},
  {"content": "La technique la plus parfaite est celle que l'on ne remarque pas.", "author": "Pablo Casals"},
  {"content": "Les femmes sont cause des plus grands événements.", "author": "Pierre Bayle"},
  {"content": "Guérir parfois, soulager souvent, écouter toujours.", "author": "Louis Pasteur"},
  {"content": "Mais dans ce monde de misère Le bonheur est vite enterré.", "author": "Jean Ferrat"},
  {"content": "C'est ce que nous sommes tous, des amateurs, on ne vit jamais assez longtemps pour être autre chose.", "author": "Charlie Chaplin"},
  {"content": "L'amour se mesure à ce que l'on accepte de lui sacrifier.", "author": "Ava Gardner"},
  {"content": "Moins on parle, et bien souvent mieux on pense.", "author": "Charles-Augustin Sainte-Beuve"},
  {"content": "Il n'est point de secrets que le temps ne révèle.", "author": "Jean Racine"},
  {"content": "Plus étroits que ceux du sang et de la famille sont les liens de l'amitié.", "author": "Jean Boccace"},
  {"content": "On peut être fier de n'importe quoi si c'est tout ce que l'on a.", "author": "John Steinbeck"},
  {"content": "Les personnes fières engendrent leurs propres peines.", "author": "Emily Brontë"},
  {"content": "Deux personnes qui se regardent dans les yeux ne voient pas leurs yeux mais leurs regards.", "author": "Robert Bresson"},
  {"content": "On se porte toujours mieux à rien demander à personne.", "author": "Alphonse Boudard"},
  {"content": "L'amitié est certes le plus doux des baumes aux plaies des amours déçues.", "author": "Jane Austen"},
  {"content": "Rêve ta vie en couleur, c'est le secret du bonheur.", "author": "Walt Disney"},
  {"content": "Soyez polis envers tous, mais intimes avec peu ; et choisissez-les bien avant de leur faire confiance.", "author": "George Washington"},
  {"content": "Une idée m'est venue. J'aurais aimé savoir d'où.", "author": "Claude Roy"},
  {"content": "Le meilleur de la vie se passe à dire “Il est trop tôt, puis “Il est trop tard.", "author": "Gustave Flaubert"},
  {"content": "Le retour fait aimer l'adieu.", "author": "Alfred de Musset "},
  {"content": "Soyons reconnaissants aux personnes qui nous donnent du bonheur ; elles sont les charmants jardiniers par qui nos âmes sont fleuries.", "author": "Marcel Proust"},
  {"content": "Tout semble impossible à ceux qui n'ont jamais rien essayé.", "author": "Jean-Louis Etienne"},
  {"content": "Les gens ont peur d'eux-mêmes, de leur propre réalité ; par dessus tout de leurs sentiments.", "author": "Jim Morrison"},
  {"content": "C'est dans l'adversité que se révèlent les vrais amis.", "author": "Cicéron"},
  {"content": "Ma devise est : exister, c'est insister !", "author": "Johnny Hallyday"},
  {"content": "Tout le bonheur du monde est dans l'inattendu.", "author": "Jean d'Ormesson"},
  {"content": "L'esprit c'est comme un parachute : s'il reste fermé, on s'écrase.", "author": "Frank Zappa"},
  {"content": "La photographie, c'est la vérité et le cinéma, c'est vingt-quatre fois la vérité par seconde...", "author": "Jean-Luc Godard"},
  {"content": "La vraie maison de l'amour est toujours une cachette.", "author": "Romain Gary"},
  {"content": "L'éternité, c'est long, surtout vers la fin.", "author": "Woody Allen"},
  {"content": "On peut faire le tour du monde et rester rétréci si on ne sait pas s'ouvrir aux autres.", "author": "Robert Laffont"},
  {"content": "Le seul moment où une femme réussit à changer un homme, c'est quand il est bébé.", "author": "Natalie Wood"},
  {"content": "On peut tout fuir, sauf sa conscience.", "author": "Stefan Zweig"},
  {"content": "Je suis contre la mort. Elle est un scandale absolu.", "author": "Claude Lanzmann"},
  {"content": "Tous les chats sont mortels, Socrate est mortel, donc Socrate est un chat.", "author": "Eugène Ionesco"},
  {"content": "Les mots sont comme les sacs : ils prennent la forme de ce qu'on met dedans.", "author": "Alfred Capus "},
  {"content": "Quand ils ne savent rien nous dire, ils restent là à nous sourire.", "author": "Barbara"},
  {"content": "Qu'il est difficile de se taire quand on n'a plus rien à se dire.", "author": "Pierre Etaix"},
  {"content": "Les plus belles histoires commencent toujours par des naufrages.", "author": "Jack London"},
  {"content": "Toutes les grandeurs de ce monde ne valent pas un bon ami.", "author": "Voltaire"},
  {"content": "L'inconnu est porteur d'angoisse.", "author": "Nadine Gordimer "},
  {"content": "Jouis toujours du présent avec discernement, ainsi le passé te sera un beau souvenir et l'avenir ne sera pas un épouvantail.", "author": "Franz Schubert"},
  {"content": "Il y a un autre monde mais il est dans celui-ci.", "author": "Paul Eluard"},
  {"content": "Lire est le seul moyen de vivre plusieurs fois.", "author": "Pierre Dumayet"},
  {"content": "Mourir fait partie de la vie. Et ceux qui ont peur de la mort sont aussi ceux qui ont peur de vivre.", "author": "Clark Gable"},
  {"content": "Moi, je n'ai pas d'ami. C'est trop fatigant d'être aimable.", "author": "Jean Gabin"},
  {"content": "Ce que je ferai, ce sera l'impression de ce que j'aurai ressenti.", "author": "Claude Monet"},
  {"content": "Je veux vivre intensément, vivre tout simplement. A quoi bon s'économiser?", "author": "Thierry Le Luron"},
  {"content": "Ce que la photographie reproduit à l'infini n'a lieu qu'une fois.", "author": "Roland Barthes"},
  {"content": "L'obscurité ne chasse pas l'obscurité, seule la lumière peut le faire.", "author": "Martin Luther King"},
  {"content": "Nous vivons dans un monde où le plus n'est jamais assez, où l'opportunisme et la cupidité se généralisent.", "author": "Leonardo DiCaprio"},
  {"content": "La paix est l'histoire des sages, la guerre est l'histoire des hommes.", "author": "Richard Burton"},
  {"content": "Moi, je fais attendre les gens pour leur faire passer le temps.", "author": "Raymond Devos"},
  {"content": "Si je ne faisais que ce que je trouve bien, je ne ferais rien.", "author": "Jean Marais"},
  {"content": "On ne fait jamais attention à ce qui a été fait ; on ne voit que ce qui reste à faire.", "author": "Marie Curie"},
  {"content": "Le seul péché est de ne pas se risquer pour vivre son désir.", "author": "Françoise Dolto"},
  {"content": "Quand vous êtes très jeune, l'humour est une défense. Par la suite, il peut devenir une arme.", "author": "René Goscinny"},
  {"content": "Le cinéma, c'est un stylo, du papier et des heures à observer le monde et les gens.", "author": "Jacques Tati"},
  {"content": "Le monde aurait pu être simple comme le ciel et la mer.", "author": "André Malraux"},
  {"content": "Si tu ne renonces jamais à rien, tu ne vieilliras pas, c'est certain.", "author": "Mort Shuman"},
  {"content": "La vérité n'est pas dans un seul rêve, mais dans beaucoup de rêves.", "author": "Pier Paolo Pasolini"},
  {"content": "Le mot réalisme ne veut rien dire. Dans une certaine mesure, tout est réaliste. Il n'y a pas de frontière entre l'imaginaire et le réel.", "author": "Federico Fellini"},
  {"content": "L'homme est absurde par ce qu'il cherche, grand par ce qu'il trouve.", "author": "Paul Valéry"},
  {"content": "Peindre calmait le chaos qui agitait mon âme. C'était une façon de domestiquer ces dragons qui ont toujours surgi dans mon travail.", "author": "Niki de Saint Phalle"},
  {"content": "Question vitale : qui a inventé les inventeurs ?", "author": "Pierre Doris"},
  {"content": "C'est joli, le progrès ? Demain, quand on offrira un livre à un gamin, il le tournera dans tous les sens pour savoir où il faut mettre les piles.", "author": "Coluche"},
  {"content": "Si tu te fais de nouveaux amis, n'oublie pas les anciens.", "author": "Érasme"},
  {"content": "Il y a toujours un avenir pour ceux qui pensent à l'avenir.", "author": "François Mitterrand"},
  {"content": "Il y des gens qui trouvent toujours quelque chose à ne rien dire.", "author": "Raymond Queneau"},
  {"content": "On a tous besoin de quelqu'un, de quelque chose ou d'un ailleurs que l'on n'a pas...", "author": "Gilbert Bécaud"},
  {"content": "Il n'y a de vraiment beau que ce qui ne peut servir à rien ; tout ce qui est utile est laid.", "author": "Théophile Gautier"}
]
