import {Settings, SettingsInterface} from "../../../domain/Settings/GeneralSettings/Settings";

export default class InitializeSettings {
  initializeSettings(): SettingsInterface {
    return new Settings(
      null,
      null,
      null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
    )
  }
}
