import {Prospect, ProspectInterface} from "../Prospect/Prospect";
import {DocumentInterface} from "../Document/Document";
import {PartnerInterface} from "../Partner/Partner";
import {BankInformation} from "../Movement/BankInformation";

export const USUFRUCT = 'usufruct'

interface InvestorInterface {
  id: string|null
  type: string|null
  prospect: ProspectInterface|null
  amount: number|null
  paymentMode: string|null
  checkDepositAt: string|null
  paymentStatus: string|null
  paymentNature: string|null
  paymentDate: string|null
  dismembermentKey: string|null
  bankInformations: BankInformation[]
  partnerType: string|null
  documents: DocumentInterface[]|null
  partner?: PartnerInterface
}

class Investor implements InvestorInterface {
  id: string|null
  type: string|null
  prospect: ProspectInterface|null
  amount: number|null
  paymentMode: string|null
  checkDepositAt: string|null
  paymentStatus: string|null
  paymentNature: string|null
  paymentDate: string|null
  dismembermentKey: string|null
  bankInformations: BankInformation[]
  partnerType: string|null
  documents: DocumentInterface[]|null
  partner?: PartnerInterface

  constructor(
    id: string|null,
    type: string|null,
    prospect: ProspectInterface|null,
    amount: number|null,
    paymentMode: string|null,
    checkDepositAt: string|null,
    paymentStatus: string|null,
    paymentNature: string|null,
    paymentDate: string|null,
    dismembermentKey: string|null,
    bankInformations: BankInformation[],
    partnerType: string|null,
    documents: DocumentInterface[]|null,
    partner?: PartnerInterface
  ) {
    this.id = id
    this.type = type
    this.prospect = prospect
    this.amount = amount
    this.paymentMode = paymentMode
    this.checkDepositAt = checkDepositAt
    this.paymentStatus = paymentStatus
    this.paymentNature = paymentNature
    this.paymentDate = paymentDate
    this.dismembermentKey = dismembermentKey
    this.bankInformations = bankInformations
    this.partnerType = partnerType
    this.documents = documents
    this.partner = partner
  }
}

export {
  Investor
}

export type {InvestorInterface}
