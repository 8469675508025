import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../store/hook";
import getClassForOverlay from "../../../util/Sidebar";
import {setOpenProductScaleItemForm} from "../../../../store/component/event";
import {ReferentielInterface} from "../../../../../domain/Referentiel/ReferentielInterface";
import {ScaleItem} from "../../../../../domain/Product/ScaleItem";
import ScaleItemForm from "./Scale/ScaleItemForm";

interface ScaleItemSidebar {
  scaleItem: ScaleItem
}

type Props = {
  referential: ReferentielInterface,
  isLectureMode: boolean
}
const ScaleItemSidebar: FunctionComponent<Props> = ({referential, isLectureMode}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const openScaleItemForm = useAppSelector((state) => state.event.openProductScaleItemForm)

  function handleClose() {
    dispatch(setOpenProductScaleItemForm({show: false, scale: null}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openScaleItemForm.show)}`} onClick={() => handleClose()}/>
      <div className={`sidebar sidebar--right sidebar--right ${openScaleItemForm.show ? 'sidebar--active' : ''}`}>
        {
          openScaleItemForm.productId && openScaleItemForm.scale &&
        <ScaleItemForm
          productId={openScaleItemForm.productId}
          scale={openScaleItemForm.scale}
          referential={referential}
          scaleItem={openScaleItemForm.scaleItem}
          isLectureMode={isLectureMode || openScaleItemForm.readonly}
        />
        }
      </div>
    </>
  )
}

export default ScaleItemSidebar
