import React, {FunctionComponent, useEffect, useState} from 'react'
import {ChromePicker, Color, ColorResult} from 'react-color';
import '../../../../assets/styles/components/_color-picker.scss'
import {Control, Controller} from "react-hook-form";


interface IProps {
    name: string,
    control: Control<any, any>
    label: string
    defaultColor: string | null | undefined,
    callback: (e: any) => void
}

const ColorPicker: FunctionComponent<IProps> = (
    {
        name,
        control,
        label,
        defaultColor,
        callback
    }
) => {

    const [state, setState] = useState({
        displayColorPicker: false,
        color: control?._formValues[name] || defaultColor,
    })

    useEffect(() => {
        setState({
            ...state,
            color: control?._formValues[name] || defaultColor
        })
    }, [])

    const onClick = () => {
        setState({
            ...state,
            displayColorPicker: !state.displayColorPicker
        })
    };

    const onClose = () => {
        setState({
            ...state,
            displayColorPicker: false
        })
    };

    const changeColor = (color: ColorResult) => {
        setState({
            ...state,
            color: color.hex
        })
        callback(color.hex);
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({field}) => {
                return (
                    <div className="form-control">
                        <label className="form-control__label">{label}</label>
                        <div className="swatch" onClick={onClick}>
                            <div className="color" style={{background: state.color || ''}}/>
                        </div>
                        {state.displayColorPicker ? <div className="popover">
                            <div className="cover" onClick={onClose}/>
                            <ChromePicker
                                color={state.color as Color}
                                onChange={(color) => {
                                    changeColor(color);
                                    field.onChange(color.hex);
                                }}
                                disableAlpha
                            />
                        </div> : null}

                    </div>
                );
            }}
        />
    )
}

export default ColorPicker
