import Caller from '../../../domain/Api/Caller'
import envVariable from '../../../infrastructure/presentation/util/envVariable'
import {Agreement} from '../../../domain/Movement/Agreement'
import {ActionList} from '../../../domain/Movement/Action/Action'
import TransactionGatewayInterface from "../../../domain/Movement/TransactionGatewayInterface";

export default class AgreementGateway implements TransactionGatewayInterface{

  private prepareData(agreement: Agreement): Record<string, unknown> {
    return {
      "propertyType": agreement.propertyType,
      "possessionMode": agreement.possessionMode,
      "product": agreement.product?.id,
      "executedAt": agreement.executedAt,
      "shareCount": agreement.shareCount,
      "horodatage": agreement.horodatage,
      "comment": agreement.comment,
      "note": agreement.note,
      "allShares": agreement.allShares,
      "sharePrice": agreement.sharePrice,
      "valueDate": agreement.valueDate,
      "flatFee": agreement.flatFee,
      "amount": agreement.amount,
      "subType": agreement.subType,
      "transactionExecutionAt":  agreement.transactionExecutionAt,
      "investors": agreement.investors?.map((investor) => {
        return {
          "type" : investor.type,
          "prospect": investor.prospect ? investor.prospect.id : null,
          "partnerType": investor.partnerType,
          "partner": investor.partner?.id || null
        }
      }),
      "slices": agreement.slices?.map((slice) => {
        return {
          "id" : slice.id,
          "countShareSelected": slice.countShareSelected
        }
      })
    }
  }

  get(uuid:string) : Promise<Agreement> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/agreements/${uuid}`, {})
    .then(data => {
      if (data) {
        return data
      }

      return null
    })
  }

  create(agreement: Agreement): Promise<Agreement> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/agreements`,
      this.prepareData(agreement)
    )
    .then(data => {
      if (data?.id) {
        return data
      }

      return null
    })
  }

  update(agreement: Agreement): Promise<Agreement> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/agreements/${agreement.id}`,
      this.prepareData(agreement)
    )
    .then(data => {
      if (data?.id) {
        return data
      }

      return null
    })
  }

  getActions(uuid: string): Promise<ActionList> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/agreements/${uuid}/actions`, {})
    .then(data => {
      if (data) {
        return data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }

  setAction(uuid: string, actionSlug: string, date: string, reason: string|null): Promise<ActionList> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/agreements/action/${uuid}/${actionSlug}`, {
      'date': date,
      'reason': reason
    })
    .then(data => {
      if (data) {
        return data
      }

      return null
    })
  }

  updateAction(uuid: string, actionSlug: string, date: string): Promise<ActionList> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/${uuid}/${actionSlug}`, {
      'date': date
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
  }
}
