import {Product, ProductInterface} from "../../../domain/Product/Product";
import {NetAssetValue} from '../../../domain/Product/NetAssetValue'
import {NetAssetValueItem} from '../../../domain/Product/NetAssetValueItem'
import {DateRange} from '../../../domain/Utils/DateRange'
import {GeneralInformation} from '../../../domain/Product/GeneralInformation'
import {DefaultDecimalStep} from "../../../fixtures/DecimalStepOptions";

export default class InitialiazeProduct {
  initializeProduct(): ProductInterface {
    return new Product(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      new GeneralInformation('',null,null,'','','','',null,null,'','','','','','','','','','','','','','','','',false,false,'',[],false, false),
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      null,
      (new NetAssetValue('false', [(new NetAssetValueItem('',new DateRange('', ''), '', 0))], null)),
      null,
      null,
      null,
      null,
      null,
      DefaultDecimalStep
    )
  }
}
