import {FunctionComponent} from 'react'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import '../../../../../assets/styles/components/_tab.scss'

type Props = {
  uuid: string,
  isMultiProduct: boolean
  productType: string
  isLectureMode: boolean
}

const TabProductEdit: FunctionComponent<Props> = ({uuid, isMultiProduct, productType, isLectureMode}) => {
  const {t} = useTranslation()

  return (
    <div className={`tab tab--product-add`}>
      <NavLink
        to={`/${t(`url.product.${isLectureMode ? "read" : "edit"}`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('product.add.tab-general-information')}
      </NavLink>
      {(productType === 'fia' || productType === 'sc' || productType === 'other') &&
          <NavLink
              to={`/${t(`url.product.${isLectureMode ? "read" : "edit"}-net-asset-value`)}/${uuid}`}
              className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
          >
            {t('product.add.tab-net-asset-value')}
          </NavLink>
      }
      {isMultiProduct &&
        <NavLink
          to={`/${t(`url.product.${isLectureMode ? "read" : "edit"}-multi-product`)}/${uuid}`}
          className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
        >
          {t('product.add.tab-multi-products')}
        </NavLink>
      }
      <NavLink
        to={`/${t(`url.product.${isLectureMode ? "read" : "edit"}-distribution`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('product.add.tab-distribution')}
      </NavLink>
      <NavLink
        to={`/${t(`url.product.${isLectureMode ? "read" : "edit"}-fiscality`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('product.add.tab-fiscality')}
      </NavLink>
      <NavLink
        to={`/${t(`url.product.${isLectureMode ? "read" : "edit"}-ag`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('product.add.tab-ag')}
      </NavLink>
      <NavLink
        to={`/${t(`url.product.${isLectureMode ? "read" : "edit"}-bank-detail`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('product.add.tab-bank-detail')}
      </NavLink>
      <NavLink
        to={`/${t(`url.product.${isLectureMode ? "read" : "edit"}-commission`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('product.add.tab-commission')}
      </NavLink>
      {productType !== 'sc' && <NavLink to={`/${t(`url.product.${isLectureMode ? "read" : "edit"}-scale`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('product.add.tab-scale')}
      </NavLink>}
      <NavLink
        to={`/${t(`url.product.${isLectureMode ? "read" : "edit"}-depositary`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('product.add.tab-depositary')}
      </NavLink>
    </div>
  )
}

export default TabProductEdit
