interface DocumentProductInterface {
  id: number
  updatedAt: string
  type: string
  title: string
  extension: string
}

interface DocumentProductToViewInterface {
  content: string
}

interface DocumentProductToSendInterface {
  value: string
  title: string
  type: string
}

class DocumentProduct implements DocumentProductInterface {
  public id: number
  public updatedAt: string
  public type: string
  public title: string
  public extension: string

  constructor(
    id: number,
    updatedAt: string,
    type: string,
    title: string,
    extension: string
  ) {
    this.id = id
    this.updatedAt = updatedAt
    this.type = type
    this.title = title
    this.extension = extension
  }
}

class DocumentProductToSend implements DocumentProductToSendInterface {
  public value: string
  public title: string
  public type: string

  constructor(
    value: string,
    title: string,
    type: string
  ) {
    this.value = value
    this.title = title
    this.type = type
  }
}

export {
  DocumentProduct,
  DocumentProductToSend
}
export type { DocumentProductInterface, DocumentProductToViewInterface }
