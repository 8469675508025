import React, {FunctionComponent, useEffect, useState} from "react";
import CheckboxToggle from "../../../Elements/CheckboxToggle";
import Input from "../../../Elements/Input";
import SelectCustom from "../../../Elements/Select";
import {useTranslation} from "react-i18next";
import {ReferentielInterface} from "../../../../../../domain/Referentiel/ReferentielInterface";
import {useAppSelector} from "../../../../../store/hook";
import ProspectGateway from "../../../../../../gateway/Prospect/ProspectGateway";
import {ProspectInterface} from "../../../../../../domain/Prospect/Prospect";
import {ProductInterface} from "../../../../../../domain/Product/Product";
import {useFormContext} from "react-hook-form";
import {BankInformation} from "../../../../../../domain/Prospect/BankInformation";

type Props = {
  type: string
  title: string
  classes?: string
  isLectureMode: boolean
  name: string
  prospect: ProspectInterface
  product: ProductInterface|null
}

const BankInformationBlock: FunctionComponent<Props> = (
  {
    type,
    title,
    classes = 'col-md-6',
    isLectureMode,
    name,
    prospect,
    product
  }) => {
  const {t} = useTranslation()
  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const [ribsOptions, setRibsOptions] = useState<BankInformation[]|null>(null)
  const { register, setValue } = useFormContext();

  useEffect(() => {
    if (prospect.id) {
      if (!product) {
        new ProspectGateway().getRibs(prospect.id, type).then(response => {
          if (response) {
            setRibsOptions(response)
          }
        })
      } else {
        new ProspectGateway().getRibForProduct(prospect.id, (product?.id) ?? '', type).then(response => {
          if (response) {
            setRibsOptions(response)
          }
        })
      }
    }
  }, [prospect, product])

  const handleChangeRib = (id: string) => {
    if (ribsOptions) {
      const ribSelected = ribsOptions.find((rib) => rib.id === id)
      setValue(`${name}.bankInformation`, {
        id: ribSelected?.id ?? null,
        label: ribSelected?.label ?? null,
        bank: ribSelected?.bank ?? null,
        iban: ribSelected?.iban ?? null,
        swift: ribSelected?.swift ?? null,
      })
    }
  }

  return (
    <div className={classes}>
      <div className={`form-bloc form--bloc--banking-information`}>
        <div className="form-bloc__title">{title}</div>
        <div className="form-bloc__form flex-container">
          <CheckboxToggle id={`${name}.directDebitMandateAccepted`}
                          name={`${name}.directDebitMandateAccepted`} classes="col-md-12"
                          register={register}
                          label={t('movement.pre-subscription.form.banking-information.direct-debit-mandate-accepted')}
          />
          <Input id={`${name}.rumCode`}
                 name={`${name}.rumCode`}
                 classes="col-md-4"
                 register={register}
                 label={t('movement.pre-subscription.form.banking-information.rum-code')}
          />
          <SelectCustom
            id={`${name}.rumStatus`}
            name={`${name}.rumStatus`}
            classes="col-md-8"
            register={register}
            label={t('movement.pre-subscription.form.banking-information.rum-status')}
            options={referential?.wallet_subscription.rum_status || null}
          />
          {ribsOptions &&
            <SelectCustom
              id={`${name}.bankInformationSelected`}
              name={`${name}.bankInformationSelected`}
              classes="col-md-12"
              register={register}
              onChange={(e) => {handleChangeRib(e.currentTarget.value)}}
              label={t('movement.pre-subscription.form.banking-information.name-rib-linked-investor')}
              options={ribsOptions?.map((rib) => {
                return {value: rib.id ?? '', label: rib.label  ?? ''}
              }) ?? []}
            />
          }
          <Input
            id={`${name}.bankInformation.bank`}
            name={`${name}.bankInformation.bank`}
            classes="col-md-12"
            register={register}
            label={t('movement.pre-subscription.form.banking-information.bank-name')}
            disabled
          />
          <Input
            id={`${name}.bankInformation.iban`}
            name={`${name}.bankInformation.iban`}
            classes="col-md-12"
            register={register}
            label={t('movement.pre-subscription.form.banking-information.iban-code')}
            disabled
          />
          <Input
            id={`${name}.bankInformation.swift`}
            name={`${name}.bankInformation.swift`}
            classes="col-md-12"
            register={register}
            label={t('movement.pre-subscription.form.banking-information.swift-code')}
            disabled
          />
        </div>
      </div>
    </div >
  )
}

export default BankInformationBlock
