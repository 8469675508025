import {Customer, CustomerInterface} from '../../../domain/Customer/Customer'

export default class InitializeCustomer {
  initializeCustomer(): CustomerInterface {
    return new Customer(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      [],
      [],
      [],
      [],
      [],
      null,
      null,
      null,
      false,
      [],
      null,
      null
    )
  }
}
