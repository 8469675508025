interface LogoInterface {
  id: number
  updatedAt: string
  type: string
  title: string
  extension: string
  content: string
}

interface LogoToSendInterface {
  value: string
  title: string
  type: string
}

class Logo implements LogoInterface {
  public id: number
  public updatedAt: string
  public type: string
  public title: string
  public extension: string
  public content: string

  constructor(
    id: number,
    updatedAt: string,
    type: string,
    title: string,
    extension: string,
    content: string
  ) {
    this.id = id
    this.updatedAt = updatedAt
    this.type = type
    this.title = title
    this.extension = extension
    this.content = content
  }
}

class LogoToSend implements LogoToSendInterface {
  public value: string
  public title: string
  public type: string

  constructor(
    value: string,
    title: string,
    type: string
  ) {
    this.value = value
    this.title = title
    this.type = type
  }
}

export {
  Logo,
  LogoToSend
}
export type { LogoInterface }
