import {FunctionComponent} from 'react'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import '../../../../../assets/styles/components/_tab.scss'
import { ProspectInterface } from '../../../../../domain/Prospect/Prospect';

type Props = {
  uuid: string|null,
  isLectureMode?:boolean,
  prospect?: ProspectInterface
}

const TabProspectEditPhysicalPerson: FunctionComponent<Props> = ({uuid,isLectureMode, prospect}) => {
  const {t} = useTranslation()

  return (
    <div className={`tab tab--prospect-add`}>
      <NavLink
        to={`/${t(`url.prospect.${isLectureMode ? "read" : "edit"}-general-information`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-general-information')}
      </NavLink>
        { prospect?.persons && (( prospect.persons[0] && prospect.persons[0].legalCapacity) || (prospect.persons[1] && prospect.persons[1].legalCapacity)) ? (
            <NavLink
                to={`/${t('url.prospect.edit-legal-representatives')}/${uuid}`}
                className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
            >
                {t('account.add.tab-legal-representatives')}
            </NavLink>
        ) : ''}
      <NavLink
        to={`/${t(`url.prospect.${isLectureMode ? "read" : "edit"}-addresses`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-addresses')}
      </NavLink>
      <NavLink
        to={`/${t(`url.prospect.${isLectureMode ? "read" : "edit"}-bank-details`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-bank-details')}
      </NavLink>
      <NavLink
        to={`/${t(`url.prospect.${isLectureMode ? "read" : "edit"}-investment-information`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-investment-information')}
      </NavLink>
      <NavLink
        to={`/${t(`url.prospect.${isLectureMode ? "read" : "edit"}-particularities`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.add.tab-particularities')}
      </NavLink>
    </div>
  )
}

export default TabProspectEditPhysicalPerson
