import {
  FilterDismenbermentInterface
} from "../../../domain/Movement/Dismemberment/DismenbermentList";
import Caller from "../../../domain/Api/Caller";
import envVariable from "../../../infrastructure/presentation/util/envVariable";
import {DismembermentInterface} from "../../../domain/Movement/Dismemberment/Dismemberment";
import {ActionList} from "../../../domain/Movement/Action/Action";
import {MovementListInterface} from "../../../domain/Movement/MovementList";
import TransactionGatewayInterface from '../../../domain/Movement/TransactionGatewayInterface';

export default class DismembermentGateway implements TransactionGatewayInterface{

  private prepareData(movement: DismembermentInterface): Record<string, unknown> {
    return {
      "propertyType": 'dismemberment',
      "possessionMode": movement.possessionMode,
      "product": movement.product?.id,
      "horodatage": movement.horodatage,
      "comment": movement.comment,
      "note": movement.note,
      "investment50k": movement.investment50k,
      "shareCount": movement.shareCount,
      "sharePrice": movement.sharePrice,
      "ter": movement.ter,
      "recurrent": movement.recurrent,
      "dividendReinvestment": movement.dividendReinvestment,
      "counterpartyResearch": movement.counterpartyResearch,
      "duration": movement.duration,
      "lifeAnnuity": movement.lifeAnnuity,
      "investors": movement.investors?.map((investor) => {
        return {
          "type": investor.type,
          "dismembermentTransaction": investor.dismembermentTransaction ? investor.dismembermentTransaction.id : null,
          "dismembermentKey": investor.dismembermentKey,
          "amount": investor.amount,
          "paymentMode": investor.paymentMode,
          "checkDepositAt": investor.checkDepositAt,
          "paymentStatus": investor.paymentStatus,
          "paymentNature": investor.paymentNature,
          "paymentDate": investor.paymentDate,
          "bankInformations": investor.bankInformations.map((bankI) => {
            return {
              "id": bankI.id,
              "type": bankI.type,
              "rumCode": bankI.rumCode,
              "rumStatus": bankI.rumStatus,
              "directDebitMandateAccepted": bankI.directDebitMandateAccepted,
              "bankInformation": bankI.bankInformation?.id
            }
          }),
          "partner": investor.partner?.id || null,
          "documents": investor.documents
        }
      }),
      "recurrence": {
        "start": movement.recurrence?.period.start,
        "periodicity": movement.recurrence?.periodicity,
        "end": movement.recurrence?.period.end,
        "shareCount": movement.recurrence?.shareCount,
        "sharePrice": movement.recurrence?.sharePrice,
        "rumCode": movement.recurrence?.rumCode,
        "rumStatus": movement.recurrence?.rumStatus,
        "status": movement.recurrence?.status,
      },
    };
  }

  update(movement: DismembermentInterface): Promise<DismembermentInterface> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/dismemberments/${movement.id}`, this.prepareData(movement))
    .then(data => {
      if (data?.id) {
        return data
      }

      return null
    })  }

  create(movement: DismembermentInterface): Promise<DismembermentInterface> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/dismemberments`, this.prepareData(movement))
    .then(data => {
      if (data?.id) {
        return data
      }

      return null
    })  }

  getList(currentPage: number,
          itemsPerPage: number,
          filter: FilterDismenbermentInterface|null,
          signal?: AbortSignal,
          sort?: string,
          order?: string
  ): Promise<{data: MovementListInterface[]|null, numberOfItems: number}|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/dismemberments?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`, {
      product_acronyme: filter?.product.label || '',
      transaction_type: filter?.transactionType || '',
      status: filter?.status.map(it => it.value) || '',
      tags: filter?.tags.map(it => it.value) || '',
      property_type: filter?.propertyType.map(it => it.value) || '',
      payment_mode: filter?.paymentMode || '',
      creation_user: filter?.user?.email || ''    ,
      name: filter?.name || '',
      keywords: filter?.keywords || ''
    }, signal)
    .then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    }).catch(() => {
      return {
        data: [],
        numberOfItems: 0
      }
    });
  }

  getExport(filter: FilterDismenbermentInterface|null): Promise<string|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/dismemberments/extract`, {
      product_acronyme: filter?.product.label || '',
      transaction_type: filter?.transactionType || '',
      statut: filter?.status.map(it => it.value) || '',
      tags: filter?.tags.map(it => it.value) || '',
      property_type: filter?.propertyType.map(it => it.value) || '',
      payment_mode: filter?.paymentMode || '',
      creation_user: filter?.user?.email || '',
      name: filter?.name || '',
      keywords: filter?.keywords || ''
    })
    .then(data => {
      if (data && data.content) {
        return data.content
      }

      return null
    }).catch(() => null)
  }

  get(uuid:string) : Promise<DismembermentInterface> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/dismemberments/${uuid}`, {})
    .then(data => {
      if (data) {
        return data
      }

      return null
    })
  }
  getActions(uuid: string): Promise<ActionList> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/dismemberments/${uuid}/actions`, {})
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(() => null)
  }
  setAction(uuid: string, actionSlug: string, date: string, reason: string|null): Promise<ActionList> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/dismemberments/action/${uuid}/${actionSlug}`, {
      'date': date,
      'reason': reason
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
  }

  updateAction(uuid: string, actionSlug: string, date: string|null): Promise<ActionList> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/${uuid}/${actionSlug}`, {
      'date': date
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
  }
}
