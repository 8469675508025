import React, {FunctionComponent} from 'react'
import iconInfo from '../../../../assets/images/icons/mark-blue.svg';
import '../../../../assets/styles/components/_bloc-information-count.scss'

type Props = {
  classes?: string
  number: number
  label: string
  description: string
}

const BlocInformationCount: FunctionComponent<Props> = ({classes, number, label, description}) => {
  return (
    <div className={`bloc-information-count ${classes ? classes : ''}`}>
      <div className={`bloc-information-count__description`} title={description}>
      </div>
      <span className={`bloc-information-count__number`}>{number}</span>
      <span className={`bloc-information-count__text`}>{label}</span>
    </div>
  )
}

export default BlocInformationCount
