import React, {FunctionComponent, useEffect, useState} from "react";
import InputDate from "../../Elements/InputDate";
import {ActionItemInterface, ActionList, ActionListInterface} from "../../../../../domain/Movement/Action/Action";
import TransactionGatewayInterface from "../../../../../domain/Movement/TransactionGatewayInterface";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {v4 as uuidV4} from 'uuid'
import dayjs from "dayjs";
import {toastError, toastSuccess} from "../../../util/Toast";
import CancelTransactionModal from "../../Modal/CancelTransactionModal";
import {reloadMovement} from "../../../../store/component/movement";
import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {useCheckRole} from "../../../../../domain/CustomHooks/useCheckRole";
import envVariable from "../../../util/envVariable";
import iconEdit from '../../../../../assets/images/icons/datalist-edit.svg'
import iconEnabled from '../../../../../assets/images/icons/enabled.svg'
import iconDisabled from '../../../../../assets/images/icons/disabled.svg'

type Props = {
  gateway: TransactionGatewayInterface
  transactionUuid: string
  uuidRefresh: string
  callback?: any
  setFormError?: any
}

type ActionEditInterface = {
  engage: boolean
  verify: boolean
  confirm: boolean
  validate: boolean
  cancel: boolean
  invalidate: boolean
}

const ActionsForm: FunctionComponent<Props> = ({gateway, transactionUuid, uuidRefresh, callback, setFormError}) => {
  const {t} = useTranslation()
  const [actionArray, setActionArray] = useState<ActionListInterface>()
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const rolesLabel = useAppSelector((state) => state.me.me?.rolesLabel)
  const movementRules = useAppSelector((state) => state.me.me?.rules.transaction.actions)
  const roleIsAuthorized = useCheckRole("V2")
  const [actionEdit, setActionEdit] = useState<ActionEditInterface>({
    engage: false,
    verify: false,
    confirm: false,
    validate: false,
    cancel: false,
    invalidate: false
  })

  useEffect(() => {
    gateway.getActions(transactionUuid).then(actions => setActionArray(actions))
  }, [transactionUuid, uuidRefresh])

  useEffect(() => {
    if (actionArray) {
      Object.entries(actionArray).map((action: [string, ActionItemInterface]) => {
        setValue(action[0] as keyof ActionListInterface, action[1])
      })
      if(callback) {
        callback(actionArray)
      }
    }
  }, [actionArray])

  const { register, control, handleSubmit, reset, watch, setValue, getValues } = useForm<ActionListInterface>()
  const watchCancelReason = watch('cancel.reason')

  const setAction = (action: string) => {
    let date = getValues()[action as keyof ActionListInterface].date
    const reason = getValues()[action as keyof ActionListInterface].reason

    if (!date) {
      date = dayjs(new Date()).format('DD/MM/YYYY')
    }

    if (action === 'cancel' && (reason === null || reason === undefined)) {
      setValue('cancel.reason', null);
      setShowCancelModal(true)
      return
    }

    gateway.setAction(transactionUuid, action, date, reason).then((response: ActionList | any) => {
      setFormError && setFormError(null)
      setActionArray(response)
      dispatch(reloadMovement());
      toastSuccess(t('movement.notify.action-update'))
    }).catch((e) => {
      setFormError(e.data)
      toastError(t('movement.notify.action-error'))
    })
  }

  const cancelCancelAction = () => {
    setValue('cancel.reason', null);
    setShowCancelModal(false)
  }

  const checkRules = (actionName: string) :boolean|undefined => {
    if(actionName === "engage") return movementRules?.action_engage
    if(actionName === "verify") return movementRules?.action_verify
    if(actionName === "invalidate") return movementRules?.action_invalidate
    if(actionName === "confirm") return movementRules?.action_confirm
    if(actionName === "validate") return movementRules?.action_validate
    if(actionName === "cancel") return movementRules?.action_cancel
  }

  const disabledAccordingRoles = (actionName: string, actionEnabled:boolean) :string => {
    // add condition with env variables
    if(actionName === "validate" && envVariable("REACT_APP_MODULE_DISABLED")?.includes('MOVEMENT')) return "disabled-content"
    if(actionEnabled && rolesLabel?.length) {
      if(actionName === "invalidate" || actionName === "confirm" || actionName === "validate") {
        if(roleIsAuthorized) return ""
        else return "disabled-content"
      } else return ""
    } else return "disabled-content"
  }

  const disableAccordingWorkflow = (actionName: string, actionEnabled:boolean) :string => {
    switch (actionName) {
      case 'engage':
      case 'invalidate':
      case 'verify':
      case 'confirm':
        if(!roleIsAuthorized) return "disabled-content"
        if (!actionEnabled && !actionEdit[actionName]) return "disabled-content"

        return ''
      case 'validate':
        if(!roleIsAuthorized || envVariable("REACT_APP_MODULE_DISABLED")?.includes('MOVEMENT')) return "disabled-content"
        if (!actionEnabled && !actionEdit[actionName]) return "disabled-content"

        return ''
      default:
        return disabledAccordingRoles(actionName, actionEnabled)
    }
  }

  const editAction = (action: string, value = true) => {

    actionEdit[action as keyof ActionEditInterface] = value
    setActionEdit({
      ...actionEdit
    })
  }

  const updateActionDate = (actionName: string) => {
    const date = getValues()[actionName as keyof ActionEditInterface].date
    editAction(actionName, false);
    gateway.updateAction(transactionUuid, actionName, date).then((response: ActionList | any) => {
      setFormError && setFormError(null)
      setActionArray(response)
      dispatch(reloadMovement());
      toastSuccess(t('movement.notify.action-update'))
    }).catch((e) => {
      setFormError(e.data)
      toastError(t('movement.notify.action-error'))
    })
  }

  const isEditable = (actionName: string) => {
    if (actionName === 'invalidate' && (actionArray?.confirm.enabled || actionArray?.validate.enabled)) {
      return false;
    }

    return actionName !== 'cancel' && !actionEdit[actionName as keyof ActionEditInterface]
  }

  return (
    <>
      <CancelTransactionModal register={register} name={'cancel.reason'} onConfirm={() => setAction('cancel')} onClose={() => cancelCancelAction()} show={showCancelModal}  cancelValue={watchCancelReason?.toString()}/>
      <div className={`form-bloc form--bloc--action`}>
        <div className="form-bloc__title">{t('movement.pre-subscription.form.actions.title')}</div>
        <div className="form-bloc__form flex-container">
          {actionArray && Object.entries(actionArray).map((action: [string, ActionItemInterface]) => {
            // if(checkRules(action[0])) return
            return (
              <div key={uuidV4()} className={`col-md-6 movement-actions`}>
                <button type="button"
                        name="btn.action"
                        className={`button button--white ${disabledAccordingRoles(action[0], action[1].enabled)}`}
                        onClick={() => setAction(action[0])}
                >
                  {t(`movement.pre-subscription.form.actions.${action[0]}`)}
                </button>

                <InputDate register={register}
                           control={control}
                           classes={`movement-actions__date ${disableAccordingWorkflow(action[0], action[1].enabled)}`}
                           type={'text'}
                           name={`${action[0]}.date`}
                           id={action[0]}
                />
                {isEditable(action[0]) && <button onClick={() => editAction(action[0])} type="button" className="button-reset">
                  <img src={iconEdit} alt="edit icon"/>
                </button>}
                {actionEdit[action[0] as keyof ActionEditInterface] && <div className="flex-container">
                  <button onClick={() => updateActionDate(action[0])} type="button" className="button-reset">
                    <img src={iconEnabled} alt="edit icon"/>
                  </button>
                  <button onClick={() => editAction(action[0], false)} type="button" className="button-reset">
                    <img src={iconDisabled} alt="edit icon"/>
                  </button>
                </div>}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default ActionsForm
