import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useNavigate, useParams} from "react-router-dom";
import {ReferentielInterface} from "../../../../../domain/Referentiel/ReferentielInterface";
import {useAppSelector} from "../../../../store/hook";
import CommissioningGateway from "../../../../../gateway/Commissioning/CommissioningGateway";
import {SortInterface, SortOrder} from "../../../../../domain/Utils/List";
import {ViewModelInterface} from "../../../../../domain/Datalist/ViewModelInterface";
import CommissioningSessionDatalist from "../../Datalist/CommissioningSessionDatalist";

type TProps = {
  isLectureMode?: boolean
}

const Session: FunctionComponent<TProps> = ({isLectureMode}) => {
  const {t} = useTranslation()
  const {commissioningType, uuid} = useParams()
  const controller = new AbortController()
  const navigate = useNavigate()
  const referential: ReferentielInterface | null = useAppSelector(({referential}) => referential.referential)

  const [viewModel, setViewModel] = useState<ViewModelInterface | null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)

  if (!uuid) {
    return <div>no uuid</div>
  } else {
    return (
      <div>
        <CommissioningSessionDatalist sessionId={uuid}/>
      </div>

    )
  }


}

export default Session