interface ReferentialDocumentsSpecificity {
  formationInitialAt: {value: string, label: string, constantGED: string}
  formationYearAt: {value: string, label: string, constantGED: string}
}

const referentialDocumentsSpecificity = {
  formationInitialAt: {value: '', label: 'formationInitialAt', constantGED: 'FORMATION_INITIAL'},
  formationYearAt: {value: '', label: 'formationYearAt', constantGED: 'FORMATION_YEAR'},
}

export {
  referentialDocumentsSpecificity
}

export type {
  ReferentialDocumentsSpecificity
}
