import { FunctionComponent, useEffect } from 'react';
import {useTranslation} from 'react-i18next'

import {useAppSelector, useAppDispatch} from '../../../../store/hook'
import {
  setAddresses,
  setBeneficialBeneficiaries,
  setContacts,
  setRelationLinks
} from '../../../../store/component/partner'

import HeaderPage from '../../../component/HeaderPage/HeaderPage'
import TabPartner from '../../../component/Tab/Partner/TabParner'
import PartnerDatalist from '../../../component/Datalist/PartnerDatalist'
import DatalistFilter from '../../../component/Sidebar/Partner/DatalistFilter'

import '../../../../../assets/styles/page/_partner.scss'
import {useNavigate} from "react-router-dom";
import { setOpenDatalistFilterPartner } from '../../../../store/component/event';
import Kpi from "../../../component/Kpi/Kpi";
import PartnerGateway from "../../../../../gateway/Partner/PartnerGateway";

const PartnerDashboard: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const partnerRules = useAppSelector((state) => state.me.me?.rules.partner)

  if(!partnerRules?.visible) navigate(`/`)

  useEffect(() => {
    dispatch(setOpenDatalistFilterPartner({show: false, count: 0, filters: {
        idPartner: '',
        keywords: '',
        socialReason: '',
        name: '',
        city: '',
        product:  {
          id: '',
          value: '',
          label: ''
        },
        status: [],
        type: [],
        platform: [],
        network: [],
        commissionLevel: ""
      }}))
  }, [dispatch])

  function handleClickAddLink() {
    dispatch(setContacts([]))
    dispatch(setAddresses([]))
    dispatch(setBeneficialBeneficiaries([]))
    dispatch(setRelationLinks([]))
  }

  return (
    <>
      <DatalistFilter/>
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        <HeaderPage title={t('partner.partner')}
                    addLink={partnerRules?.actions?.create ? `/${t('url.partner.add-general-information')}` : undefined}
                    callbackClickAddLink={handleClickAddLink}/>
        <TabPartner />
        <div className="partner-keys-figures">
          <div className="partner-keys-figures__title">{t('partner.key-figures')}</div>
          <Kpi getKpi={new PartnerGateway().getKpi} />
        </div>
        {partnerRules?.actions?.list && <PartnerDatalist from={'dashboard'} callerType={"module"} />} {/* TODO add a spinner loader while loading */}
      </main>
    </>
  )
}

export default PartnerDashboard
