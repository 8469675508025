import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { v4 as uuidV4 } from 'uuid';

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';
import { useAppDispatch, useAppSelector } from '../../../../../store/hook';

import { CustomerInterface } from '../../../../../../domain/Customer/Customer';
import { ReferentielInterface } from '../../../../../../domain/Referentiel/ReferentielInterface';
import { CommunicationInterface } from '../../../../../../domain/Customer/Communication';
import { FiscalityInterface } from '../../../../../../domain/Customer/Fiscality';
import AlertInfo from '../../../Alert/AlertInfo/AlertInfo';
import InitializeCustomer from '../../../../../../useCase/Customer/Initialization/InitializeCustomer';
import ReferentielGateway from '../../../../../../gateway/Referentiel/ReferentielGateway';
import UpdateUseCase from '../../../../../../useCase/Customer/Update/UpdateUseCase';
import CustomerGateway from '../../../../../../gateway/Customer/CustomerGateway';
import AddUseCase from '../../../../../../useCase/Customer/Add/AddUseCase';
import DocumentGateway from '../../../../../../gateway/Prospect/Document/DocumentGateway'
import { City } from '../../../../../../domain/Referentiel/City/City';
import { Country } from '../../../../../../domain/Referentiel/Country/Country';
import CityDatalist from '../../../Datalist/City/CityDatalist';

import SelectCustom from '../../../Elements/Select';
import CountryDatalist from '../../../Datalist/Country/CountryDatalist';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmationModal from '../../../Modal/ConfirmationModal';
import { toastError, toastSuccess } from '../../../../util/Toast';
import { usePrompt } from '../../../../util/Navigation';
import { setReadOnlyFormElements } from '../../../../util/setReadOnlyFormElements';
import {Person, PersonInterface} from '../../../../../../domain/Customer/Person';
import InputDate from "../../../Elements/InputDate";
import FormErrorInfo from "../../../Alert/FormErrorInfo/FormErrorInfo";
import CheckboxToggle from '../../../Elements/CheckboxToggle';
import DocumentUtils from "../../../../util/DocumentUtils";
import DocumentForm from "../../Document/Form";
import FooterBlock from "../../../Block/FooterBlock";
import Checkbox from '../../../Elements/Checkbox';
import RadioYesOrNot from '../../../Elements/RadioYesOrNot';
import {DocumentInterface, DocumentType} from "../../../../../../domain/Document/DocumentInterface";
import DocumentHistoryBlock from "../../../Block/DocumentHistoryBlock";
import {AddressInterface} from "../../../../../../domain/Address/Address";

interface IFormInput {
  customerCode: string|null
  externalDepositaryCode: string|null
  externalCode: string|null
  recoveryCode: string|null
  partnerCode: string|null
  partnerName: string|null
  partnerPlatformCode: string|null
  statusCreatedAt: string|null
  status: string|null
  exitedAt: string|null
  blockedAt: string|null

  persons: {
    id: string|null
    createdAt: string|null
    updatedAt: string|null
    deletedAt: string|null
    type: string|null
    gerant: string|null
    title: string|null
    firstname: string|null
    lastname: string|null
    maidenName: string|null
    birthDate: string|null
    birthCity: City|null
    birthCountry: Country|null
    nationality: Country|null
    ppe: boolean
    ppeCategory: string|null
    legalCapacity: string|null
    familySituation: string|null
    matrimonialRegime: string|null
    email: string|null
    phone: string|null
    extranet: boolean
    extranetCreatedAt: string|null
    optinMarketing: CommunicationInterface|null
    optinDividend: CommunicationInterface|null
    optinFiscality: CommunicationInterface|null
    optinGeneralAssembly: CommunicationInterface|null
    optinInvitation: CommunicationInterface|null
    optinAll: CommunicationInterface|null
    address: AddressInterface|null
    fiscality: FiscalityInterface|null

    fiscalityIncomeTax: string|null
    fiscalitySubjectIfi: string|null
  }[]

  kycCategoryValue: string|null
  kycCategoryDate: string|null
  kycLabft: string|null
  kycLabftDate: string|null
  kycProfession: string|null
  kycFatca: boolean|null
  groupRelation: string|null

  customerExtranetRadio: string|null

  documents: {
    [key: string] : {
      [key: string] : {
        date: string
        updatedAt: string
      }
    }
  }
}

type Props = {
  setStatus: React.Dispatch<React.SetStateAction<string>>
  referential: ReferentielInterface
  customer: CustomerInterface
  isLectureMode?: boolean
  handler?: any
}

const GeneralInformationPhysicalPerson: FunctionComponent<Props> = ({customer, isLectureMode, handler, referential, setStatus}) => {
  const {t} = useTranslation()
  const { customerType } = useParams();
  const navigate = useNavigate()

  const countryByDefault = useAppSelector(({referential}) => referential.countryByDefault)
  const customerStore = useAppSelector((state) => state.customer)
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [documentArray, setDocumentArray] = useState<DocumentType>({})
  const [documentHistoryArray, setDocumentHistoryArray] = useState<DocumentInterface[]>([])
  const [defaultCountry, setDefaultCountry] = useState<Country[]>([])
  const openUploadForm = useAppSelector((state) => state.event.openUploadForm)
  const documentUtils = new DocumentUtils()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (customer) {
      setValue("customerCode", customer.customerCode)
      setValue("externalDepositaryCode", customer.externalDepositaryCode)
      setValue("externalCode", customer.externalCode)
      setValue("recoveryCode", customer.recoveryCode)
      setValue("statusCreatedAt", customer.createdAt)
      setValue("status", customer.status)
      setValue("exitedAt", customer.exitedAt)
      setValue("blockedAt", customer.blockedAt)

      if (customer.persons !== null) {
        customer.persons.map((person: PersonInterface, index) => {
          if (person.id) {

            if (index === 0) {
              setValue("customerExtranetRadio", person.extranet ? "1" : "0")
            }

            setValue(`persons.${index}.id`, person.id)
            setValue(`persons.${index}.createdAt`, person.createdAt)
            setValue(`persons.${index}.updatedAt`, person.updatedAt)
            setValue(`persons.${index}.deletedAt`, person.deletedAt)
            setValue(`persons.${index}.title`, person.title)
            setValue(`persons.${index}.firstname`, person.firstname)
            setValue(`persons.${index}.lastname`, person.lastname)
            setValue(`persons.${index}.maidenName`, person.maidenName)
            setValue(`persons.${index}.type`, person.type)
            setValue(`persons.${index}.gerant`, person.gerant ? "1" : "0")
            setValue(`persons.${index}.title`, person.title)
            setValue(`persons.${index}.birthDate`, person.birthdate)
            setValue(`persons.${index}.birthCity`, person.birthCity)
            setValue(`persons.${index}.birthCity`, person.birthCity)
            setValue(`persons.${index}.birthCountry`, person.birthCountry)
            setValue(`persons.${index}.nationality`, person.nationality)
            setValue(`persons.${index}.ppe`, person.ppe ?? false)
            setValue(`persons.${index}.ppeCategory`, person.ppeCategory)
            setValue(`persons.${index}.legalCapacity`, person.legalCapacity)
            setValue(`persons.${index}.familySituation`, person.familySituation)
            setValue(`persons.${index}.matrimonialRegime`, person.matrimonialRegime)
            setValue(`persons.${index}.email`, person.email)
            setValue(`persons.${index}.phone`, person.phone)
            setValue(`persons.${index}.extranet`, person.extranet ?? false)
            setValue(`persons.${index}.extranetCreatedAt`, person.extranetCreatedAt)
            setValue(`persons.${index}.optinMarketing`, person.optinMarketing)
            setValue(`persons.${index}.optinDividend`, person.optinDividend)
            setValue(`persons.${index}.optinFiscality`, person.optinFiscality)
            setValue(`persons.${index}.optinGeneralAssembly`, person.optinGeneralAssembly)
            setValue(`persons.${index}.optinInvitation`, person.optinInvitation)
            setValue(`persons.${index}.address`, person.address)
            setValue(`persons.${index}.fiscality`, person.fiscality)

            if (person.fiscality) {
              setValue(`persons.${index}.fiscalityIncomeTax`, person.fiscality.incomeTax ? "1" : "0")
              setValue(`persons.${index}.fiscalitySubjectIfi`, person.fiscality.subjectIfi ? "1" : "0")
              setValue(`persons.${index}.fiscality.taxResidency`, person.fiscality.taxResidency ?? "france")
            }
          }
        })
      }

      setValue("kycCategoryValue", customer.kycCategoryValue)
      setValue("kycCategoryDate", customer.kycCategoryDate)
      setValue("kycLabft", customer.kycLabft)
      setValue("kycLabftDate", customer.kycLabftDate)
      setValue("kycProfession", customer.kycProfession)
      setValue("kycFatca", customer.kycFatca)
      setValue("groupRelation", customer.groupRelation)
    }

  }, [customer])

  useEffect(() => {
    /*
    if (countryByDefault) {
      if (customer) {
        setValue('personFirstBirthCountry', (customer && customer.persons && customer.persons.length > 0 && customer.persons[0].birthCountry) ? customer.persons[0].birthCountry : countryByDefault[0]);
        setValue('personFirstNationality', (customer && customer.persons && customer.persons.length > 0 && customer.persons[0].nationality) ? customer.persons[0].nationality : countryByDefault[0]);
        setValue('personSecondBirthCountry', (customer && customer.persons && customer.persons.length > 0 && customer.persons[1] && customer.persons[1].birthCountry) ? customer.persons[1].birthCountry : countryByDefault[0]);
        setValue('personSecondNationality', (customer && customer.persons && customer.persons.length > 0 && customer.persons[1] && customer.persons[1].nationality) ? customer.persons[1].nationality : countryByDefault[0]);
      } else {
        setValue('personFirstBirthCountry', countryByDefault[0]);
        setValue('personFirstNationality', countryByDefault[0]);
        setValue('personSecondBirthCountry', countryByDefault[0]);
        setValue('personSecondNationality', countryByDefault[0]);
      }
    }*/
  }, [countryByDefault]);

  useEffect(() => {
    if (customer && customer.id) {
      const documentGateway = new DocumentGateway(customer.id)

      documentGateway.getFiles().then((response) => {
        if (response) {
          setDocumentArray(response)
        }
      })

      documentGateway.getHistory().then((response) => {
        if (response) {
          setDocumentHistoryArray(response)
        }
      })
    }
  }, [openUploadForm])

  useEffect(() => {
    if (customer && customer.persons) {
      customer.persons.map((person) => {
        if (person.id && documentArray[person.id]) {
          documentArray[person.id].map((doc: DocumentInterface) => {
            setValue(`documents.${person.id}.${doc.type}.date`, doc.date)
            setValue(`documents.${person.id}.${doc.type}.updatedAt`, doc.updatedAt)
          })
        }
      })
    }
  }, [documentArray])

  const { register, control, watch, handleSubmit, reset, setValue, getValues } = useForm<IFormInput>();

  const watchStatus = watch('status')
  const watchPersonFirstLastname = watch('persons.0.lastname')
  const watchPersonFirstFirstname = watch('persons.0.firstname')
  const watchPersonFirstBirthdate = watch('persons.0.birthDate')
  const watchPersonFirstNationality = watch('persons.0.nationality')
  const watchPersonFirstBirthCity = watch('persons.0.birthCity')
  const watchPerson= watch('persons')

  useEffect(() => {
    checkValidity()
  }, [watchStatus, watchPersonFirstLastname, watchPersonFirstFirstname, watchPersonFirstBirthdate, watchPersonFirstNationality, watchPersonFirstBirthCity])

  const checkValidity = () => {
    const errors = []
    if (!watchStatus) {
      errors.push('account.form.status.status')
    }
    if (!watchPersonFirstLastname) {
      errors.push('account.form.general-information.personLastname')
    }
    if (!watchPersonFirstFirstname) {
      errors.push('account.form.general-information.personFirstname')
    }
    if (!watchPersonFirstBirthdate) {
      errors.push('account.form.general-information.personBirthdate')
    }
    if (!watchPersonFirstNationality) {
      errors.push('account.form.general-information.personNationality')
    }
    if (!watchPersonFirstBirthCity) {
      errors.push('account.form.general-information.personBirthCity')
    }
    setFormErrors(errors);
  }

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    reset(data)
    if (undefined === customer) {
      customer = new InitializeCustomer().initializeCustomer()
    }

    if (null == customer.customerType ) {
      customer.customerType = undefined !== customerType ? customerType : 'other'
    }

    customer.customerCode = data.customerCode
    customer.externalDepositaryCode = data.externalDepositaryCode
    customer.externalCode = data.externalCode
    customer.recoveryCode = data.recoveryCode
    customer.status = data.status
    customer.exitedAt = data.exitedAt
    customer.blockedAt = data.blockedAt

    if (customerStore !== null && customerStore.partner !== null && customerStore.partner.id !== null) {
      customer.partner = customerStore.partner
    }

    customer.persons?.map((person: PersonInterface, index: number) => {
      if (customer.persons && customer.persons[index] && person && person.id) {
        customer.persons[index].title = data.persons[index].title
        customer.persons[index].type = 'invest'
        customer.persons[index].ppe = data.persons[index].ppe
        customer.persons[index].ppeCategory = data.persons[index].ppeCategory
        customer.persons[index].lastname = data.persons[index].lastname
        customer.persons[index].firstname = data.persons[index].firstname
        customer.persons[index].maidenName = data.persons[index].maidenName
        customer.persons[index].birthdate = data.persons[index].birthDate
        customer.persons[index].birthCity = data.persons[index].birthCity
        customer.persons[index].birthCountry = data.persons[index].birthCountry
        customer.persons[index].nationality = data.persons[index].nationality
        customer.persons[index].familySituation = data.persons[index].familySituation
        customer.persons[index].matrimonialRegime = data.persons[index].matrimonialRegime
        customer.persons[index].legalCapacity = data.persons[index].legalCapacity
        customer.persons[index].email = data.persons[index].email
        customer.persons[index].phone = data.persons[index].phone
        if (data.documents && data.documents[person.id]) {
          customer.persons[index].documents = documentUtils.getDocumentsForPerson(data.documents[person.id], documentArray, person.id)
        }
        customer.persons[index].extranet = data.customerExtranetRadio === "1" ? true : false
        customer.persons[index].extranetCreatedAt = data.persons[index].extranetCreatedAt
        customer.persons[index].optinMarketing = data.persons[index].optinMarketing
        customer.persons[index].optinDividend = data.persons[index].optinDividend
        customer.persons[index].optinFiscality = data.persons[index].optinFiscality
        customer.persons[index].optinGeneralAssembly = data.persons[index].optinGeneralAssembly
        customer.persons[index].optinInvitation = data.persons[index].optinInvitation
        customer.persons[index].address = data.persons[index].address

        if (data.persons[index].fiscality) {
          data.persons[index].fiscality.incomeTax = data.persons[index].fiscalityIncomeTax === "1" ? true : false
          data.persons[index].fiscality.subjectIfi = data.persons[index].fiscalitySubjectIfi === "1" ? true : false
        }

        customer.persons[index].fiscality = data.persons[index].fiscality
      }
    })

    customer.kycCategoryValue = data.kycCategoryValue
    customer.kycCategoryDate = data.kycCategoryDate
    customer.kycLabft = data.kycLabft
    customer.kycLabftDate = data.kycLabftDate
    customer.kycProfession = data.kycProfession
    customer.kycFatca = data.kycFatca
    customer.groupRelation = data.groupRelation

    setIsLoading(true)
    if (null !== customer.id) {
      new UpdateUseCase(new CustomerGateway())
        .execute(customer)
        .then(response => {
          setIsLoading(false)
          if (null !== response) {
            toastSuccess(t('account.notify.update-success'))
            if(watchStatus) {
              setStatus(watchStatus)
            } else {
              setStatus('')
            }
          } else {
            toastError(t('account.notify.update-error'));
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      new AddUseCase(new CustomerGateway())
        .execute(customer)
        .then(uuid => {
          setIsLoading(false)
          if (null !== uuid) {
            toastSuccess(t('account.notify.add-success'))
            navigate(`/${t('url.customer.edit-general-information')}/${uuid}`)
          } else {
            toastError(t('account.notify.add-error'));
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));

  const watchreceiveAllBy = watch('persons.0.optinAll.type')
  const watchPersonFirstLegalCapacity = watch('persons.0.legalCapacity')
  const watchPersonSecondLegalCapacity = watch('persons.1.legalCapacity')
  const watchPersonFirstPpe = watch('persons.0.ppe')
  const watchPersonSecondPpe = watch('persons.1.ppe')


  useEffect(() => {
    if(customer && customer.persons) {
      while (customer.persons.length < 2){
        customer.persons.push(new Person(null, null,null,null,null,null,null,null,null,null,
            null,null,null,null,null,null,null,null,null,
            null,null,null,null,null,null,null,null,null,
            null,null,null,null,null,null))
      }
      customer.persons[0].legalCapacity = getValues('persons.0.legalCapacity')
      customer.persons[1].legalCapacity = getValues('persons.1.legalCapacity')
      if (handler) {
        handler(customer)
      }
    }
  }, [watchPersonFirstLegalCapacity, watchPersonSecondLegalCapacity])

  useEffect(() => {
    if (watchreceiveAllBy) {
      setValue('persons.0.optinDividend.type', watchreceiveAllBy)
      setValue('persons.0.optinFiscality.type', watchreceiveAllBy)
      setValue('persons.0.optinGeneralAssembly.type', watchreceiveAllBy)
      setValue('persons.0.optinInvitation.type', watchreceiveAllBy)
      setValue('persons.0.optinMarketing.type', watchreceiveAllBy)
    }
  }, [watchreceiveAllBy])

  useEffect(() => {
    if (!watchPersonFirstPpe) {
      setValue( 'persons.0.ppeCategory', '')
    }
  }, [watchPersonFirstPpe])

  useEffect(() => {
    if (!watchPersonSecondPpe) {
      setValue( 'persons.1.ppeCategory', '')
    }
  }, [watchPersonSecondPpe])

  const immatriculationBlock = <div className={`form-bloc form--bloc--immatriculation`}>
    <div className="form-bloc__title">{t('account.form.immatriculation.title')}</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="customerCode" className="form-control__label">{t('account.form.immatriculation.customerCode')}</label>
          <div className="form-control__input">
            <input type="text" id="customerCode" {...register('customerCode')} disabled={true} />
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="externalDepositaryCode" className="form-control__label">{t('account.form.immatriculation.externalDepositaryCode')}</label>
          <div className="form-control__input">
            <input type="text" id="externalDepositaryCode" {...register('externalDepositaryCode')} disabled={true}/>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="externalCode" className="form-control__label">{t('account.form.immatriculation.externalCode')}</label>
          <div className="form-control__input">
            <input type="text" id="externalCode" {...register('externalCode')} disabled={true} />
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="recoveryCode" className="form-control__label">{t('account.form.immatriculation.recoveryCode')}</label>
          <div className="form-control__input">
            <input type="text" id="recoveryCode" {...register('recoveryCode')} disabled={true} />
          </div>
        </div>
      </div>
    </div>
  </div>
  const generalInformationBlock = <div className={`form-bloc form--bloc--general-information`}>
    <div className="form-bloc__title">{t('account.form.general-information.title')}</div>
    <div className="flex-container">
      {customer.persons?.map((person: PersonInterface, index: number) => {
        return person.id && <div className="col-md-6">
          <div className="form-bloc__form flex-container">
            <div className="col-md-12">
              {t('account.form.person')} {index+1 }
            </div>
            <div className="col-md-6">
              <div className="form-control">
                <label htmlFor="personFirstTitle" className="form-control__label">{t('account.form.general-information.personTitle')}</label>
                <div className="form-control__input">
                  <select {...register(`persons.${index}.title`)}>
                    <option value="" />
                    {referential.global.title_physic.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-control">
                <label htmlFor="personFirstLastname" className="form-control__label mendatory-field">{t('account.form.general-information.personLastname')}</label>
                <div className="form-control__input">
                  <input type="text" id="personFirstLastname" className={!watchPersonFirstLastname ? 'field-in-error' : ''} {...register(`persons.${index}.lastname`)} />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-control">
                <label htmlFor="personFirstFirstname" className="form-control__label mendatory-field">{t('account.form.general-information.personFirstname')}</label>
                <div className="form-control__input">
                  <input type="text" id="personFirstFirstname" className={!watchPersonFirstFirstname ? 'field-in-error' : ''} {...register(`persons.${index}.firstname`)} />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-control">
                <label htmlFor="personFirstMaidenname" className="form-control__label">{t('account.form.general-information.personMaidenname')}</label>
                <div className="form-control__input">
                  <input type="text" id="personFirstMaidenname" {...register(`persons.${index}.maidenName`)} />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-control">
                <label htmlFor="personFirstBirthdate" className="form-control__label mendatory-field">{t('account.form.general-information.personBirthdate')}</label>
                <div className="form-control__input">
                  <InputDate id="personFirstBirthdate"
                             name={`persons.${index}.birthDate`}
                             classes={!watchPersonFirstBirthdate ? 'field-in-error' : ''}
                             control={control}
                             register={register}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-control">
                <label htmlFor="personFirstNationality" className="form-control__label mendatory-field">{t('account.form.general-information.personNationality')}</label>
                <div className="form-control__input">
                  <CountryDatalist control={control} isOnError={!watchPersonFirstNationality} name={`persons.${index}.nationality`} defaultValue={getValues(`persons.${index}.nationality`)} disabled={isLectureMode}/>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-control">
                <label htmlFor="personFirstBirthCountry" className="form-control__label">{t('account.form.general-information.personBirthCountry')}</label>
                <div className="form-control__input">
                  <CountryDatalist control={control} name={`persons.${index}.birthCountry`} defaultValue={getValues(`persons.${index}.birthCountry`)} disabled={isLectureMode}/>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <CityDatalist control={control}
                            setValue={setValue}
                            labelContent={t('account.form.general-information.personBirthCity')}
                            name={`persons.${index}.birthCity`}
                            defaultValue={getValues(`persons.${index}.birthCity`)}
                            country={watchPerson?.[index].birthCountry}
                            classes={!watchPersonFirstBirthCity ? 'field-in-error' : ''}
                            mode="row"
                            disabled={isLectureMode}
              />
            </div>
            <div className="col-md-6">
              <div className="form-control">
                <label htmlFor="personFirstFamilySituation" className="form-control__label">{t('account.form.general-information.personFamilySituation')}</label>
                <div className="form-control__input">
                  <select {...register(`persons.${index}.familySituation`)}>
                    <option value="" />
                    {referential.global.family.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-control">
                <label htmlFor="personFirstMatrimonialRegime" className="form-control__label">{t('account.form.general-information.personMatrimonialRegime')}</label>
                <div className="form-control__input">
                  <select {...register(`persons.${index}.matrimonialRegime`)}>
                    <option value="" />
                    {referential.global.matrimonial_regime.map((item, index) => <option key={index}
                                                                                                   value={item.value}>{item.label}</option>)}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-control">
                <label htmlFor="personFirstLegalCapacity" className="form-control__label">{t('account.form.general-information.personLegalCapacity')}</label>
                <div className="form-control__input">
                  <select {...register(`persons.${index}.legalCapacity`)}>
                    <option value="" />
                    {referential.prospect.legal_capacity.map((item, index) => <option key={index}
                                                                                                 value={item.value}>{item.label}</option>)}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <CheckboxToggle id={"personFirstPpe"} name={`persons.${index}.ppe`} register={register} label={t('account.form.general-information.personPpe')} />
            </div>
            <div className="col-md-6">
              { watchPersonFirstPpe &&
              <SelectCustom classes="" containerClasses="" id="personFirstPpeCategory" name={`persons.${index}.ppeCategory`}
                            options={ referential.customer.ppe_category } register={ register }
                            label={ t('account.form.manager.managerPpeCategory') }/>
              }
            </div>
          </div>
        </div>
      })}
    </div>
  </div>
  const kycBlock = <div className={`form-bloc form--bloc--kyc`}>
    <div className="form-bloc__title">{t('account.form.kyc.title')}</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-8">
        <div className="form-control">
          <label htmlFor="kycCategoryValue" className="form-control__label">{t('account.form.kyc.kycCategoryValue')}</label>
          <div className="form-control__input">
            <select {...register('kycCategoryValue')}>
              <option value="" />
              {referential.prospect.kyc_category_value.map((item, index) => <option key={index}
                                                                   value={item.value}>{item.label}</option>)}
            </select>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-control">
          <label htmlFor="kycCategoryDate" className="form-control__label">{t('account.form.kyc.kycCategoryDate')}</label>
          <div className="form-control__input">
            <InputDate
                id="kycCategoryDate"
                name="kycCategoryDate"
                control={control}
                register={register}
            />
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <div className="form-control">
          <label htmlFor="kycLabft" className="form-control__label">{t('account.form.kyc.kycLabft')}</label>
          <div className="form-control__input">
            <select {...register('kycLabft')}>
              <option value="" />
              {referential.prospect.kyc_labft.map((item, index) => <option key={index}
                                                                                 value={item.value}>{item.label}</option>)}
            </select>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-control">
          <label htmlFor="kycLabftDate" className="form-control__label">{t('account.form.kyc.kycLabftDate')}</label>
          <div className="form-control__input">
            <InputDate id="kycLabftDate"
                       name="kycLabftDate"
                       control={control}
                       register={register}
            />
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <div className="form-control">
          <label htmlFor="kycProfession" className="form-control__label">{t('account.form.kyc.kycProfession')}</label>
          <div className="form-control__input">
            <select {...register('kycProfession')}>
              <option value="" />
              {referential.global.profession.map((item, index) => <option key={index}
                                                                        value={item.value}>{item.label}</option>)}
            </select>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <Checkbox id={'kycFatca'} name={'kycFatca'} register={register} label={t('account.form.kyc.kycFatca')} align={'normal'}/>
      </div>
      <SelectCustom classes="col-md-8" id="groupRelation" name="groupRelation"
                        label={t('account.form.kyc.groupRelation')}
                        options={referential.prospect.relation}
                        register={register}/>
    </div>
  </div>
  const fiscalityBlock = <div className={`form-bloc form--bloc--fiscality`}>
    <div className="form-bloc__title">{t('account.form.fiscality.title')}</div>
    {customer.persons?.map((person: PersonInterface, index: number) => {
      return person.id && <div className="form-bloc__form flex-container">
        <div className="col-md-6">
          <div className="flex-container">
            <div className="col-md-12">
              {index < 1 &&
              <div className="form-control__input">
                <select {...register(`persons.${index}.fiscality.paymentMethod`)}>
                  <option value="" />
                  {referential.prospect.payment_method.map((item, index) =>
                    <option key={index} value={item.value}>{item.label}</option>)
                  }
                </select>
              </div>
              }
            </div>
            <div className="col-md-12">
              {t('account.form.person')} {index + 1}
            </div>
            <div className="col-md-12"></div>
            <div className="col-md-6">
              <div className="form-control">
                <label htmlFor="personFirstFiscalityNif" className="form-control__label">{t('account.form.fiscality.nif')}</label>
                <div className="form-control__input">
                  <input type="text" id={`persons.${index}.fiscality.nif`} {...register(`persons.${index}.fiscality.nif`)} />
                </div>
              </div>
            </div>
            <SelectCustom classes="col-md-6" label={t('account.form.fiscality.taxResidency')}
                          id={`person.${index}.fiscality.taxResidency`} name={`persons.${index}.fiscality.taxResidency`}
                          options={referential.prospect.tax_residency || []}
                          register={register} />
            <div className="col-md-6">
              <RadioYesOrNot id={`persons.${index}.fiscalityIncomeTax`} name={`persons.${index}.fiscalityIncomeTax`} register={register} label={t('account.form.fiscality.incomeTax')}/>
            </div>
            <div className="col-md-6">
            <RadioYesOrNot id={`persons.${index}.fiscalitySubjectIfi`} name={`persons.${index}.fiscalitySubjectIfi`} register={register} label={t('account.form.fiscality.subjectIfi')}/>
            </div>
            <div className="col-md-6">
              <div className="form-control">
                <label htmlFor="personFirstFiscalityRate" className="form-control__label">{t('account.form.fiscality.rate')}</label>
                <div className="form-control__input">
                  <select {...register(`persons.${index}.fiscality.rate`)} id={`persons.${index}.fiscality.rate`}>
                    <option value="" />
                    {referential.prospect.nr_rate.map((item, index) => <option key={index}
                                                                                          value={item.value}>{item.label}</option>)}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    })}
  </div>
  const communicationBlock = <div className={`form-bloc form--bloc--communication`}>
    <div className="form-bloc__title">{t('account.form.communication.title')}</div>
    <div className="flex-container">
      <div className="col-md-12">
        <AlertInfo text={t('account.form.communication.alert-sign-elec')} />
      </div>
    </div>
    <div className="form-bloc__form flex-container">
      {customer.persons?.map((person: PersonInterface, index: number) => {
        return person.id && <div className="col-md-6">
          <div className="flex-container">
            <div className="col-md-12">{t('account.form.person')} {index + 1}</div>
            <div className="col-md-12">
              <div className="form-control">
                <label htmlFor="personFirstEmail" className="form-control__label">{t('account.form.communication.personEmail')}</label>
                <div className="form-control__input">
                  <input type="text" id={`persons.${index}.email`} {...register(`persons.${index}.email`)} />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-control">
                <label htmlFor="personFirstPhone" className="form-control__label">{t('account.form.communication.personPhone')}</label>
                <div className="form-control__input">
                  <input type="text" id={`persons.${index}.phone`} {...register(`persons.${index}.phone`)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      })}
    </div>
    {customer.persons?.map((person: PersonInterface, index: number) => {
      return index === 0 && person.id && <div className="form-bloc__form flex-container">
      <div className="col-md-12">{t('account.form.communication.communication-person')}</div>
      <div className="col-md-4">
        <RadioYesOrNot id={'customerExtranetRadio'} name={'customerExtranetRadio'} register={register} label={t('account.form.communication.personExtranet')}/>
      </div>
      <div className="col-md-4">
        <div className="form-control">
          <label htmlFor={`persons.${index}.extranetCreatedAt`} className="form-control__label">{t('account.form.communication.personExtranetCreatedAt')}</label>
          <div className="form-control__input">
            <InputDate id={`persons.${index}.extranetCreatedAt`}
                       name={`persons.${index}.extranetCreatedAt`}
                       register={register}
                       control={control}
            />
          </div>
        </div>
      </div>
      <div className="col-md-5">
        <table className="table--communication">
          <thead>
          <tr>
            <th/>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <th key={'table'+uuidV4()} className="form-control__label">{communication_type.label}</th>
              )
            })}
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <label className="form-control__label">{t('account.form.communication.optinMarketing')}</label>
            </td>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={uuidV4()}>
                  <div className="form-control__input">
                    <input type="radio" value={communication_type.value} {...register(`persons.${index}.optinMarketing.type`)} disabled={isLectureMode} />
                  </div>
                </td>
              )
            })}
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{t('account.form.communication.optinDividend')}</label>
            </td>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={uuidV4()}>
                  <div className="form-control__input">
                    <input type="radio" value={communication_type.value} {...register(`persons.${index}.optinDividend.type`)} disabled={isLectureMode} />
                  </div>
                </td>
              )
            })}
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{t('account.form.communication.optinFiscality')}</label>
            </td>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={uuidV4()}>
                  <div className="form-control__input">
                    <input type="radio" value={communication_type.value} {...register(`persons.${index}.optinFiscality.type`)} disabled={isLectureMode} />
                  </div>
                </td>
              )
            })}
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{t('account.form.communication.optinGeneralAssembly')}</label>
            </td>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={uuidV4()}>
                  <div className="form-control__input">
                    <input type="radio" value={communication_type.value} {...register(`persons.${index}.optinGeneralAssembly.type`)} disabled={isLectureMode} />
                  </div>
                </td>
              )
            })}
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{t('account.form.communication.optinInvitation')}</label>
            </td>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={uuidV4()}>
                  <div className="form-control__input">
                    <input type="radio" value={communication_type.value} {...register(`persons.${index}.optinInvitation.type`)} disabled={isLectureMode} />
                  </div>
                </td>
              )
            })}
          </tr>
          <tr>
            <td>
              <label className="form-control__label">{t('account.form.communication.optinAll')}</label>
            </td>
            {referential.prospect.communication_type.map((communication_type) => {
              return (
                <td key={uuidV4()}>
                  <div className="form-control__input">
                    <input type="radio" value={communication_type.value} {...register(`persons.${index}.optinAll.type`)} disabled={isLectureMode}  />
                  </div>
                </td>
              )
            })}
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    })}
  </div>
  const statusBlock = <div className={`form-bloc form--bloc--status`}>
    <div className="form-bloc__title">{t('account.form.status.title')}</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="statusCreatedAt" className="form-control__label">{t('account.form.status.statusCreatedAt')}</label>
          <div className="form-control__input">
            <InputDate id="statusCreatedAt"
                       name="statusCreatedAt"
                       register={register}
                       control={control}
                       disabled={true}
            />
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="status" className="form-control__label mendatory-field">{t('account.form.status.status')}</label>
          <div className="form-control__input">
            <select {...register('status')} className={!watchStatus ? 'field-in-error' : ''}>
              <option value="" />
              {referential.prospect.status.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
            </select>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="exitedAt" className="form-control__label">{t('account.form.status.exitedAt')}</label>
          <div className="form-control__input">
            <InputDate id="exitedAt"
                       name="exitedAt"
                       register={register}
                       control={control}
            />
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-control">
          <label htmlFor="blockedAt" className="form-control__label">{t('account.form.status.blockedAt')}</label>
          <div className="form-control__input">
            <InputDate id="blockedAt"
                       name="blockedAt"
                       register={register}
                       control={control}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  const documentsBlock = customer?.persons?.map((person, index) => {
    if (customer && customer.id && person && person.id) {
      return (
        <>
          <div className="col-md-6">
            <DocumentForm
              title={`${t('movement.pre-subscription.form.list-of-documents.title')} : ${person.lastname + ' ' + person.firstname}`}
              name={`documents.${person.id}`}
              uuid={person.id}
              documentGateway={new DocumentGateway(customer.id)}
              register={register}
              control={control}
              documentData={documentArray[person.id]}
              isLectureMode={isLectureMode}
            />
          </div>
        </>
      )
    }
  })

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
      <div className="flex-container">
        <div className="col-md-12">
          {formErrors.length ? <FormErrorInfo errors={formErrors} message={t('account.notify.form-error-base-message')}/> : null}
        </div>
        <div className="col-md-12">{immatriculationBlock}</div>
        <div className="col-md-12">{generalInformationBlock}</div>
        <div className="col-md-6">{kycBlock}</div>
        <div className="col-md-12">{fiscalityBlock}</div>
        <div className="col-md-12">{communicationBlock}</div>
        <div className="col-md-12">{statusBlock}</div>
        <div className="col-md-12">
          <div className="flex-container">
            {documentsBlock}
          </div>
        </div>
        <div className="col-md-12">
          {customer && customer.id && customer.recoveryCode && documentHistoryArray && (documentHistoryArray.length > 0) &&
            <div className="form-bloc">
              <DocumentHistoryBlock documents={documentHistoryArray} gateway={new DocumentGateway(customer.id)}/>
            </div>
          }
        </div>
      </div>
      {!isLectureMode && <FooterBlock disabled={!watchStatus || isLoading} />}
    </form>
  )
}

export default GeneralInformationPhysicalPerson
