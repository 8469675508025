import {Presenter} from '../Presenter'
import {ConventionAnnexInterface} from "../../domain/Partner/ConventionAnnex";

class PartnerCommissionListPresenter extends Presenter {

  constructor(getPartners: any) {
    super({
      viewModel: {
        heading: [
          {name: 'partner.heading.action',},
          {name: 'partner.form.convention-annex.acronym'},
          {name: 'partner.form.convention-annex.number'},
          {name: 'partner.form.convention-annex.date'},
          {name: 'partner.form.convention-annex.category'},
        ],
        datas: getPartners,
        filtersShortcut: [
        ],
        filters: [
        ],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': getPartners.length,
        },
        count: 0
      }
    });
  }
}

export default PartnerCommissionListPresenter
