import {FunctionComponent, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import '../../../../assets/styles/components/_completeness.scss'
import {useAppSelector} from "../../../store/hook";
import {PartnerInterface} from "../../../../domain/Partner/Partner";

type Props = {
  partner?: PartnerInterface
}

const CompletenessOfFile: FunctionComponent<Props> = ({partner}) => {
  const {t} = useTranslation()

  return (<></>)

  //return (<>
  //  <div className="completeness-of-file">
  //    <div className="completeness-of-file__title">{t('partner.add.completeness-of-the-file')}</div>
  //    <div className="completeness-of-file__status">
  //      <div className="form-control__input">
  //        <label className="form-label form-label--input-radio">
  //          <input type="radio" value="prospection" checked={partner?.completion === "prospection"}/>
  //          Prospect
  //        </label>
  //      </div>
  //      <div className="form-control__input">
  //        <label className="form-label form-label--input-radio">
  //          <input type="radio" value="waiting_document" checked={partner?.completion === "waiting_document"}/>
  //          En attente de documents
  //        </label>
  //      </div>
  //      <div className="form-control__input">
  //        <label className="form-label form-label--input-radio">
  //          <input type="radio" value="in_signup" checked={partner?.completion === "in_signup"}/>
  //          En signature
  //        </label>
  //      </div>
  //      <div className="form-control__input">
  //        <label className="form-label form-label--input-radio">
  //          <input type="radio" value="enabled" checked={partner?.completion === "enabled"}/>
  //          Actif
  //        </label>
  //      </div>
  //      <div className="form-control__input">
  //        <label className="form-label form-label--input-radio">
  //          <input type="radio" value="locked" checked={partner?.completion === "locked"}/>
  //          Bloqué
  //        </label>
  //      </div>
  //      <div className="form-control__input">
  //        <label className="form-label form-label--input-radio">
  //          <input type="radio" value="disabled" checked={partner?.completion === "disabled"}/>
  //          Inactif
  //        </label>
  //      </div>
  //    </div>
  {/*  </div>*/}
  {/*</>)*/}
}

export default CompletenessOfFile
