import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../../store/hook';
import {FormProvider, SubmitHandler, useForm, useFormState} from 'react-hook-form';
import blockScrollBody from '../../../util/BlockScroll';
import {
  setOpenUserForm,
} from '../../../../store/component/event';
import getClassForOverlay from '../../../util/Sidebar';
import {confirmAlert} from 'react-confirm-alert';
import ConfirmationModal from '../../Modal/ConfirmationModal';
import {toastError, toastSuccess} from '../../../util/Toast';
import {usePrompt} from '../../../util/Navigation';
import UserGateway from '../../../../../gateway/User/UserGateway';
import RoleGateway from '../../../../../gateway/Role/RoleGateway';
import {RoleInterface} from '../../../../../domain/Roles/Roles';
import eyeIcon from '../../../../../assets/images/icons/eye.svg'
import AlertInfo from "../../Alert/AlertInfo/AlertInfo";
import FormError from "../../../util/FormError";


interface IFormInput {
  id: null | string;
  email: null | string;
  roles: string[];
  firstname: string;
  lastname: string;
  enabled: boolean;
  first_password: string;
  second_password: string;
}

const AddUser: FunctionComponent = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const methods = useForm();

  const openForm = useAppSelector((state) => state.event.openUserForm);
  const {register, control, handleSubmit, reset, setValue, watch, setError, formState: {errors}} = useForm<IFormInput>();
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [allRoleList, setAllRoleList] = useState<RoleInterface[]>([])

  const watchPassword = watch('first_password')
  const watchSecondPassword = watch('second_password')
  const watchEmail = watch('email')
  const watchFirstname = watch('firstname')
  const watchLastname = watch('lastname')
  const watchRoles = watch('roles')

  const samePassword = t('settings.user-profiles.form.errors-msg.samePassword')

  useEffect(() => {
    (new RoleGateway()).getList().then(response => {
      if (response) {
        setAllRoleList(sortRole(response.data));
      }
    })
  }, [])

  useEffect(() => {
    reset()
    blockScrollBody(openForm.show);

    if (openForm.user) {
      setValue('id', openForm.user?.user_id || '');
      setValue('firstname', openForm.user?.prenom || '');
      setValue('lastname', openForm.user?.nom || '');
      setValue('email', openForm.user?.email || '');
      setValue('roles', openForm.user?.roles || []);
      setValue('enabled', openForm.user?.enabled || false);
      setValue('first_password', "");
      setValue('second_password', "");
    } else {
      setValue('id', 'provisional_' + crypto.getRandomValues(new Uint32Array(1)).join());
      setValue('firstname', '');
      setValue('lastname', '');
      setValue('email', '');
      setValue('roles', []);
      setValue('enabled', false);
      setValue('first_password', "");
      setValue('second_password', "");
    }
    setFormErrors([])
    checkPasswordIsValid("")
  }, [openForm]);

  function handleClose() {
    dispatch(setOpenUserForm({show: false, user: null}));
  }

  const sortRole = (roleArray: RoleInterface[]) => {
    const newArray: RoleInterface[] = []
    for (const role of roleArray) {
      switch (role.label) {
        case "Middle Office":
          newArray.push({...role, order: 1});
          break
        case "MO V2":
          newArray.push({...role, order: 2});
          break
        case "Back Office":
          newArray.push({...role, order: 3});
          break
        case "BO V2":
          newArray.push({...role, order: 4});
          break
        case "Front Office":
          newArray.push({...role, order: 5});
          break
        case "FO V2":
          newArray.push({...role, order: 6});
          break
        case "Commerciale":
          newArray.push({...role, order: 7});
          break
        case "Financiers":
          newArray.push({...role, order: 8});
          break
        case "Direction":
          newArray.push({...role, order: 9});
          break
        case "Comptable":
          newArray.push({...role, order: 10});
          break
        case "Conformité":
          newArray.push({...role, order: 11});
          break
        default:
          break
      }
    }
    return newArray.sort((a, b): number => {
      if (a.order && b.order) {
        return a.order - b.order
      } else {
        return 0
      }
    })
  }

  const togglePasswordVisibility = (event: React.MouseEvent<HTMLButtonElement>, state: string) => {
    const passwordInputEl = (event.target as HTMLElement).closest('div')?.querySelector('input')

    if (passwordInputEl?.type && state === "down") passwordInputEl.type = "text"
    if (passwordInputEl?.type && state === "up") passwordInputEl.type = "password"
  }

  const checkPasswordIsValid = (value: string) => {
    let errorsMsg: string[] = []

    const minCharMsg = t('settings.user-profiles.form.errors-msg.minCharMsg')
    const oneCharIsUppercase = t('settings.user-profiles.form.errors-msg.oneCharIsUppercase')
    const oneCharIsLowercase = t('settings.user-profiles.form.errors-msg.oneCharIsLowercase')
    const oneNumber = t('settings.user-profiles.form.errors-msg.oneNumber')
    const charNotAuthorizedMsg = t('settings.user-profiles.form.errors-msg.charNotAuthorizedMsg')

    const charNotAuthorized = ['0', 'O', 'o', '1', 'l', 'I']


    const containCharNotAuthorized = (char: string) => charNotAuthorized.includes(char)

    const containUppercaseChar = (char: string) => char === char.toUpperCase() && char.match(/[a-z]/i)

    const containLowercaseChar = (char: string) => char === char.toLowerCase() && char.match(/[a-z]/i)
    const containNumber = (char: string) => !isNaN(parseInt(char))

    if (value.length < 8) errorsMsg.push(minCharMsg)
    else errorsMsg = errorsMsg.filter(item => item !== minCharMsg)

    const stringToLettersArray = value.length ? value.split('') : null

    if (stringToLettersArray?.some(containCharNotAuthorized)) errorsMsg.push(charNotAuthorizedMsg)
    else errorsMsg = errorsMsg.filter(i => i !== charNotAuthorizedMsg)

    if (!stringToLettersArray?.some(containUppercaseChar)) errorsMsg.push(oneCharIsUppercase)
    else errorsMsg = errorsMsg.filter(i => i !== oneCharIsUppercase)

    if (!stringToLettersArray?.some(containLowercaseChar)) errorsMsg.push(oneCharIsLowercase)
    else errorsMsg = errorsMsg.filter(i => i !== oneCharIsLowercase)

    if (!stringToLettersArray?.some(containNumber)) errorsMsg.push(oneNumber)
    else errorsMsg = errorsMsg.filter(i => i !== oneNumber)

    setFormErrors([...errorsMsg])
    checkPasswordsIsSame(watchSecondPassword || "", [...errorsMsg])
  }

  const checkPasswordsIsSame = (value: string, arrErrors?: string[]) => {
    let errorsMsg: string[]
    if(arrErrors) errorsMsg = arrErrors
    else errorsMsg = formErrors

    if (watchPassword !== value) {
      const alreadyExisted = errorsMsg.find(item => item === samePassword)
      !alreadyExisted && errorsMsg.push(samePassword)
    } else errorsMsg = errorsMsg.filter(item => item !== samePassword)

    setFormErrors([...errorsMsg])
  }

  const generateErrorMsg = useCallback((): JSX.Element => {
    const msg = t('settings.user-profiles.form.errors-msg.label');

    const node = <>
      <p className="u-mbs">{msg}</p>
      <ul>
        {formErrors.map((err) => {
          return <li>{err}</li>
        })}
      </ul>
    </>

    return node

  }, [formErrors])

  const formIsValid = useCallback(() => {
    if(!watchPassword || !watchSecondPassword || !watchEmail || !watchFirstname || !watchLastname || !watchRoles.length) {
      if(openForm.user?.user_id && (watchEmail && watchFirstname && watchLastname && watchRoles.length)) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  },[watchPassword, watchSecondPassword, watchEmail, watchFirstname, watchLastname, watchRoles])

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>);
      },
    });
  };
  const onConfirm = async (data: any) => {
    const user = data;
    let filteredRolesArray: string[] | []
    if (data.roles.length === allRoleList.length) {
      filteredRolesArray = user.roles.filter((role: string) => role !== "ROLE_ADMIN")
      user.roles = [...filteredRolesArray, 'ROLE_SUPER_ADMIN']
    } else {
      filteredRolesArray = user.roles.filter((role: string) => role !== "ROLE_SUPER_ADMIN")
      user.roles = [...filteredRolesArray, 'ROLE_ADMIN']
    }
    if (openForm.user) {
      await new UserGateway().update(user).then(response => {
        if (null !== response.uuid) {
          toastSuccess(t('settings.notify.update-user-success'));

          dispatch(setOpenUserForm({show: false, user: user}));
          reset();
        } else {
          new FormError().setFormErrors(response.errors, {}, function (name: keyof IFormInput, message) {
            return setError(name, {type: 'custom', message: message});
          })
          if (response.errors.length > 0 && response.errors[0].errors.length > 0) {
            toastError(response.errors[0].errors[0]);
          }
        }
      })
    } else {
      await new UserGateway().add(user).then(response => {
        if (null !== response.uuid) {
          toastSuccess(t('settings.notify.add-user-success'));

          dispatch(setOpenUserForm({show: false, user: user}));
          reset();
        } else {
          new FormError().setFormErrors(response.errors, {}, function (name: keyof IFormInput, message) {
            return setError(name, {type: 'custom', message: message});
          })
          if (response.errors.length > 0 && response.errors[0].errors.length > 0) {
            toastError(response.errors[0].errors[0]);
          }
        }
      })
    }
  };

  const {isDirty} = useFormState({
    control,
  });
  usePrompt(isDirty, handleSubmit(onConfirm));

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openForm.show)}`}
           onClick={() => handleClose()}/>
      <div
        className={`sidebar sidebar--right sidebar--right ${openForm.show ? 'sidebar--active' : ''}`}>
        <FormProvider {...methods} >
          <form onSubmit={handleSubmit(onSubmit)} className="form-bloc form-bloc--partner-address-form">
            <div className="sidebar__content">
              <div className="title">{openForm.user?.user_id ? `${t('settings.user-profiles.heading.edit')} ${openForm.user?.nom ?? ''} ${openForm.user?.prenom ?? ''}` : t('settings.user-profiles.heading.add')}</div>
              <div className="form-bloc__form flex-container">
                <div className="col-md-12">
                  <div className="form-control relative">
                    <label htmlFor="addrFiscalitySame"
                           className="form-control__label">{t('settings.user-profiles.form.enabled')}</label>
                    <div className="checkbox-toggle__wrapper">
                      <input type="checkbox" {...register('enabled')} id="enabled"
                             className="checkbox-toggle checkbox-toggle--light no-skin checkbox-toggle-radio-default"/>
                      <label className="checkbox-toggle__button" htmlFor="enabled"/>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control relative">
                    <label htmlFor="email"
                           className="form-control__label mandatory-field">{t('settings.user-profiles.form.firstname')}</label>
                    <div className="form-control__input">
                      <input type="text" {...register('firstname')} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control relative">
                    <label htmlFor="email"
                           className="form-control__label mandatory-field">{t('settings.user-profiles.form.lastname')}</label>
                    <div className="form-control__input">
                      <input type="text" {...register('lastname')} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control relative">
                    <label htmlFor="email"
                           className="form-control__label mandatory-field">{t('settings.user-profiles.form.email')}</label>
                    <div className="form-control__input">
                      <input type="email" {...register('email')} />
                    </div>
                  </div>
                  {errors.email && <div className="form-control__errors">{errors.email.message}</div>}
                </div>
                <div className="col-md-12">
                  <div className="form-control relative">
                    <label htmlFor="first_password"
                           className={`form-control__label ${!openForm.user?.user_id && "mandatory-field"}`}>{t('settings.user-profiles.form.password')}</label>
                    <div className="form-control__input relative">
                      <input type="password"
                             className={`${formErrors.length > 0 ? 'field-in-error' : watchPassword && watchSecondPassword ? 'field-in-success' : ''} password`}
                             {...register('first_password', {
                               onChange: (e) => checkPasswordIsValid(e.target.value)
                             })}
                      />
                      <button type="button"
                              className="button--password-visibility"
                              onMouseUp={(e) => togglePasswordVisibility(e, 'up')}
                              onMouseDown={(e) => togglePasswordVisibility(e, 'down')}
                      >
                        <img src={eyeIcon} alt=""/>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control relative">
                    <label htmlFor="second_password"
                           className={`form-control__label ${!openForm.user?.user_id && "mandatory-field"}`}>{t('settings.user-profiles.form.confirmation-password')}</label>
                    <div className="form-control__input relative">
                      <input type="password"
                             className={`${formErrors.length > 0 ? 'field-in-error' : watchPassword && watchSecondPassword ? 'field-in-success' : ''} password`}
                             {...register('second_password', {
                               onChange: (e) => checkPasswordsIsSame(e.target.value)
                             })}/>
                      <button type="button"
                              className="button--password-visibility"
                              onMouseUp={(e) => togglePasswordVisibility(e, 'up')}
                              onMouseDown={(e) => togglePasswordVisibility(e, 'down')}
                      >
                        <img src={eyeIcon} alt=""/>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control relative">
                    <label htmlFor="roles"
                           className="form-control__label mandatory-field">{t('settings.user-profiles.form.roles')}
                    </label>
                    <div className="col-md-8">
                      {allRoleList.map((role) =>
                        <div className="form-control" key={role.id}>
                          <label className="form-control__label">
                            <input type="checkbox"
                                   value={role.id}
                                   {...register(`roles`)}
                            />
                            {t(`settings.user-profiles.form.role.${role.label}`)}
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  {!formIsValid() && <AlertInfo classes={"flex items-start u-mbb"}
                                                text={t('settings.user-profiles.form.errors-msg.field-not-completed')}
                  />}
                  {(formErrors.length > 0) && <AlertInfo classes={"flex items-start"}
                                                       text={generateErrorMsg()}
                  />}
                </div>
              </div>
            </div>
            <footer className="sidebar__footer">
              <button type="submit"
                      className="button button--ink-2 u-mrm"
                      disabled={(formErrors.length > 0 && !openForm.user?.user_id) || !formIsValid()}>
                {t('common.save')}
              </button>
              <button type="button" className="button button--ink-2 button--ink-2--outline"
                      onClick={() => handleClose()}>{t('common.cancel')}</button>
            </footer>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default AddUser;
