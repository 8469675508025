import React, {FunctionComponent, useState} from "react";
import {Scale} from "../../../../../../domain/Product/Scale";
import {ScaleItem} from "../../../../../../domain/Product/ScaleItem";
import {
  ReferentielInterface,
} from "../../../../../../domain/Referentiel/ReferentielInterface";
import {SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import ScaleItemGateway from "../../../../../../gateway/Product/Scale/ScaleItemGateway";
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../../util/Toast";
import Input from "../../../Elements/Input";
import InputDate from "../../../Elements/InputDate";
import RadioChoices from "../../../Elements/RadioChoices";
import {setScales} from "../../../../../store/component/product";
import {useAppDispatch} from "../../../../../store/hook";
import {setOpenProductScaleItemForm} from "../../../../../store/component/event";

type Props = {
  productId: string
  scale: Scale
  scaleItem: ScaleItem|null
  referential: ReferentielInterface
  isLectureMode: boolean
}
const ScaleItemForm: FunctionComponent<Props> = ({productId, scale, scaleItem, referential, isLectureMode}) => {
  const { register, control, handleSubmit, formState: { isValid}} = useForm<{scaleItem: ScaleItem | null}>(
    {
      defaultValues: {
        scaleItem: scaleItem
      }
    }
  );

  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const scaleItemGateway = new ScaleItemGateway()
  const [isSubmit, setSubmit] = useState<boolean>(false)

  const onSubmit: SubmitHandler<{scaleItem: ScaleItem}> = data => {
    if (!isValid) {
      return
    }
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: {scaleItem: ScaleItem}) => {
    if (scale.id) {
      setSubmit(true)
      if (data.scaleItem.id) {
        scaleItemGateway.update(productId, scale.id, data.scaleItem)
        .then((response) => {
          toastSuccess(t('product.form.scale.item.notify.edit.success'))
          dispatch(setScales(response))
          dispatch(setOpenProductScaleItemForm(response))
          setSubmit(false)
        })
        .catch((e) => {
          setSubmit(false)
          toastError(t('product.form.scale.item.notify.edit.error'))
        })
      } else {
        scaleItemGateway.create(productId, scale.id, data.scaleItem)
        .then((response) => {
          toastSuccess(t('product.form.scale.item.notify.add.success'))
          dispatch(setScales(response))
          dispatch(setOpenProductScaleItemForm(response))
          setSubmit(false)
        })
        .catch((e) => {
          setSubmit(false)
          toastError(t('product.form.scale.item.notify.edit.error'))
        })
      }
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="form-bloc">
        <div className="sidebar__content">
          <div className="title">{`${t('product.form.scale.siderbar.session.add')} au ${scale.category.label} du ${scale.date}`}</div>
          <div className="col-md-6">
            <div className="flex-container">
              <RadioChoices classes={'col-md-12 initial-grid'}
                            label={t('product.form.scale.siderbar.session.type-of')}
                            id="scale.category.id" name="scaleItem.category.id"
                            register={register}
                            options={referential.product.scale_item_category}
                            disabled={isLectureMode}
              />
              <Input register={register} classes={'col-md-12'} label={t('product.form.scale.siderbar.session.value')} type={'number'} name={'scaleItem.value'} id={'scaleItem.value'} step="0.00001" disabled={isLectureMode}/>
              <InputDate id={'scaleItem.applicationAt'} name={'scaleItem.applicationAt'} register={register} label={t('product.form.scale.siderbar.session.applicationAt')} classes={'col-md-6'} control={control} disabled={isLectureMode}/>
              <InputDate id={'scaleItem.applicationAt'} name={'scaleItem.approbationAt'} register={register} label={t('product.form.scale.siderbar.session.approbationAt')} classes={'col-md-6'} control={control} disabled={isLectureMode}/>
              <InputDate id={'scaleItem.applicationAt'} name={'scaleItem.period.start'} register={register} label={t('product.form.scale.siderbar.session.startAt')} classes={'col-md-6'} control={control} disabled={isLectureMode}/>
              <InputDate id={'scaleItem.applicationAt'} name={'scaleItem.period.end'} register={register} label={t('product.form.scale.siderbar.session.endAt')} classes={'col-md-6'} control={control} disabled={isLectureMode}/>
            </div>
          </div>
        </div>
        <footer className="sidebar__footer">
          <button type="submit" disabled={isLectureMode || isSubmit} className="button button--ink-2 u-mrm">{t('common.save')}</button>
        </footer>
      </form>
    </>
  )
}

export default ScaleItemForm
