import React, {FunctionComponent, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm, useFormState} from 'react-hook-form'
import {v4 as uuidV4} from 'uuid'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';
import {ReferentielInterface} from '../../../../../../domain/Referentiel/ReferentielInterface'
import ProductDalalist from '../../../Datalist/Product/ProductDalalist'
import Input from '../../../Elements/Input'
import SelectCustom from '../../../Elements/Select'
import InputDate from '../../../Elements/InputDate'
import Checkbox from '../../../Elements/Checkbox'
import ListToSell from '../../../Datalist/Parts/ListToSell'
import {useAppDispatch, useAppSelector} from '../../../../../store/hook'
import ProductGateway from "../../../../../../gateway/Product/ProductGateway";
import {ProductInterface} from "../../../../../../domain/Product/Product";
import {Investor} from "../../../../../../domain/PreSubscription/Investor";
import {
  setOpenChoiceInvestorEvent,
  setOpenChoicePartnerEvent,
  setOpenChoiceSliceToGiveUpEvent
} from '../../../../../store/component/event'
import {Agreement} from "../../../../../../domain/Movement/Agreement";
import AgreementGateway from '../../../../../../gateway/Movement/Agreement/AgreementGateway'
import {ActionListInterface} from '../../../../../../domain/Movement/Action/Action'
import AlertInfo from '../../../Alert/AlertInfo/AlertInfo'
import MovementTag from '../../../Tag/MovementTag/MovementTag'

import eyeIcon from '../../../../../../assets/images/icons/eye.svg'
import iconAdd from '../../../../../../assets/images/icons/add.svg'
import iconSearch from '../../../../../../assets/images/icons/datalist-search.svg'

import '../../../../../../assets/styles/page/_movement.scss'
import Status from "../../../Transaction/Status";
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {usePrompt} from "../../../../util/Navigation";
import DocumentGateway from "../../../../../../gateway/Movement/Agreement/Document/DocumentGateway";
import DocumentForm from "../../Document/Form";
import {
  reloadMovement,
  setInvestorInvestor,
  setInvestorTransferor,
  setPartner
} from "../../../../../store/component/movement";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import ActionsForm from "../../Action/ActionsForm";
import ActionReason from "../../../Transaction/ActionReason";
import RecapForm from "../../Recap/RecapForm";
import ReactTooltip from "react-tooltip";
import { Link, useNavigate } from 'react-router-dom';
import CommentBlock from "../../../Block/CommentBlock";
import NoteBlock from "../../../Block/NoteBlock";
import CKEditorUtil from "../../../../util/CKEditor";
import List from "../../../../util/List";
import DocumentUtils from "../../../../util/DocumentUtils";
import ProspectUtils from "../../../../util/ProspectUtils";
import FooterBlock from "../../../Block/FooterBlock";
import FormErrorInfoDropdown from "../../../Alert/FormErrorInfoDropdown/FormErrorInfoDropdown";
import {DocumentType, DocumentInterface} from "../../../../../../domain/Document/DocumentInterface";
import {PersonInterface} from "../../../../../../domain/Prospect/Person";
import InputNumber from "../../../Elements/InputNumber";
import {type} from "os";
import {NumericStringToFloat} from "../../../../../transformer/NumericStringToFloat";

interface IFormInput {
  generalInformation: {
    product: {
      id: string|null,
      label: string|null,
      value: string|null
    }
    movementNumber: string|null
    propertyType: string|null
    administrator: string|null
    possessionMode: string|null
    subscribedAt: string|null
    externalMovementNumber: string|null
    horodatage: string|null
    updatedAt: string|null
    lastUpdateUser: string|null
  }
  investor: {
    first: {
      id: string|null
      identity: string|null
      partnerNumber: string
      partnerSocialReason: string
      platformNetworkNumber: string|null
      platformNetworkLastName: string|null
      partnerType: string|null
    }
    second: {
      id: string|null
      identity: string|null
      partnerNumber: string
      partnerSocialReason: string
      platformNetworkNumber: string|null
      platformNetworkLastName: string|null
    }
  }
  pm: {
    documents: {
      kbis: { askedAt: string, createdAt: string }
      cniPassportValid: { askedAt: string, createdAt: string }
      signedRequestCession: { askedAt: string, createdAt: string }
      ddf: { askedAt: string, createdAt: string }
      surplusForm: { askedAt: string, createdAt: string }
      rib: { askedAt: string, createdAt: string }
      other: { askedAt: string, createdAt: string }
    }
  }
  withdrawalRequest: {
    typeOfMovement: string|null
    executedAt: string|null
    shareCount: number|null
    selectAllUnit: boolean
    choiceUnitNumbers: boolean
    sharePrice: number|null
    dateVL: string|null
    flatFee: number|null
    amount: number|null
  }
  note: string
  comment: string

  documents: {
    [key: string] : {
      [key: string] : {
        [key: string] : {
          date: string
          updatedAt: string
        }
      }
    }
  }
}

type Props = {
  movement: Agreement
  isLectureMode: boolean
}

const Form: FunctionComponent<Props> = ({movement, isLectureMode}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const partners = useAppSelector((state) => state.movement.partners)
  const investors = useAppSelector((state) => state.movement.investors)
  const shareSlices = useAppSelector((state) => state.slice.sliceToTransaction)
  const shareSlicesFreeze = useAppSelector((state) => state.slice.sliceToTransactionFreeze)
  const [product, setProduct] = useState<ProductInterface|null>(null)
  const [decimalStep, setDecimalStep] = useState<number>(8)
  const openUploadForm = useAppSelector((state) => state.event.openUploadForm)
  const [documentArray, setDocumentArray] = useState<DocumentType>({})
  const [uuidRefresh, setUuidRefresh] = useState<string>('')
  const [actions, setActions] = useState<ActionListInterface|null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formError, setFormError] = useState<any>(null)
  const [ownerLink, setOwnerLink] = useState<string|null>(null)
  const [partnerLink, setPartnerLink] = useState<string|null>(null)
  const [usuLink, setUsuLink] = useState<string|null>(null)
  const [secondPartnerLink, setSecondPartnerLink] = useState<string|null>(null)
  const documentUtils = new DocumentUtils()
  const prospectUtils = new ProspectUtils()

  useEffect(() => {
    const transferor = movement.investors?.find((investor) => {
      return investor?.type === "transferor" || null
    })
    if (transferor) dispatch(setInvestorTransferor(transferor))

    const investor = movement.investors?.find((investor) => {
      return investor?.type === "investor" || null
    })
    if (investor) dispatch(setInvestorInvestor(investor))

    if (movement && movement.comment) {
      setValue('comment', movement.comment)
    }
    if (movement && movement.note) {
      setValue('note', movement.note)
    }
  }, [movement.id])

  useEffect(() => {

    return () => {
      dispatch(setPartner({partner: null, key: "reset"}))
    }
  }, [])

  useEffect(() => {
    new DocumentGateway(movement.id, null).getFiles().then((response) => {
      if (response) {
        setDocumentArray(response)
      }
    })

    if (openUploadForm.documents) {
      handleSubmit(onConfirm)()
    }
  }, [openUploadForm])

  useEffect(() => {
    if (movement && movement.investors) {
      movement.investors.map((investor) => {
        if (investor.prospect && investor.prospect.persons) {
          investor.prospect.persons.map((person: PersonInterface) => {
            if (person.id && documentArray[person.id]) {
              documentArray[person.id].map((doc: DocumentInterface) => {
                setValue(`documents.${investor?.prospect?.prospectCode}.${person.id}.${doc.type}.date`, doc.date)
                setValue(`documents.${investor?.prospect?.prospectCode}.${person.id}.${doc.type}.updatedAt`, doc.updatedAt)
              })
            }
          })
        }
      })
    }
  }, [documentArray])

  useEffect(() => {
    if (investors.transferor && investors.transferor.prospect) {
      if (investors.transferor.prospect.persons && investors.transferor.prospect.persons.length > 0) {
        setOwnerLink(investors.transferor.prospect.id)
        setValue('investor.first.id', investors.transferor.prospect.prospectCode)
        setValue('investor.first.identity', prospectUtils.formatProspectPersonIdentity(investors.transferor.prospect))
        setValue('investor.first.platformNetworkNumber', investors.transferor.prospect.partner?.platform ?? "")
        setValue('investor.first.platformNetworkLastName', investors.transferor.prospect.partner?.platform ?? "")
      }
    }
  }, [investors.transferor])

  useEffect(() => {
    if (investors.investor && investors.investor.prospect) {
      if (investors.investor.prospect.persons && investors.investor.prospect.persons.length > 0) {
        setUsuLink(investors.investor.prospect.id)
        setValue('investor.second.id', investors.investor.prospect.prospectCode)
        setValue('investor.second.identity', prospectUtils.formatProspectPersonIdentity(investors.investor.prospect))
        setValue('investor.second.platformNetworkNumber', investors.investor.prospect.partner?.platform ?? "")
        setValue('investor.second.platformNetworkLastName', investors.investor.prospect.partner?.platform ?? "")
      }
    }
  }, [investors.investor])

  const { register, control, handleSubmit, reset, watch, setValue, getValues } = useForm<IFormInput>({
    defaultValues: {
      generalInformation: {
        product: {
          id: movement?.product?.id,
          label: movement?.product?.label,
          value: movement?.product?.id
        },
        movementNumber: (movement?.transactionCode !== null) ? movement?.transactionCode : '',
        propertyType: movement?.propertyType,
        administrator: movement.user ? `${movement.user.lastname} ${movement.user.firstname}` : '',
        possessionMode: movement?.possessionMode,
        subscribedAt: movement?.subscribedAt,
        horodatage: movement?.horodatage,
        updatedAt: movement?.updatedAt,
        lastUpdateUser: movement?.lastUpdateUser ?  `${movement?.lastUpdateUser.lastname} ${movement?.lastUpdateUser.firstname}` : '',
      },
      investor: {
        first: {
          id: null,
          identity: null,
          partnerNumber: "",
          partnerSocialReason: "",
          platformNetworkNumber: null,
          platformNetworkLastName: null
        },
        second: {
          id: null,
          identity: null,
          partnerNumber: "",
          partnerSocialReason: "",
          platformNetworkNumber: null,
          platformNetworkLastName: null
        }
      },
      withdrawalRequest: {
        selectAllUnit: movement?.allShares,
        executedAt: movement?.transactionExecutionAt,
        shareCount: movement?.shareCount,
        sharePrice: movement?.sharePrice,
        dateVL: movement?.valueDate,
        flatFee: movement?.flatFee,
        amount: movement?.amount,
        typeOfMovement: movement?.subType
      }
    }
  })
  const watchProduct = watch('generalInformation.product')
  const watchshareCount = watch('withdrawalRequest.shareCount')
  const watchChoicePartSelectAll = watch('withdrawalRequest.selectAllUnit')
  const watchChoicePart = watch('withdrawalRequest.choiceUnitNumbers')

  const watchSharePrice = watch('withdrawalRequest.sharePrice')

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    reset(data)
    movement.propertyType = data.generalInformation.propertyType
    movement.possessionMode = data.generalInformation.possessionMode
    movement.subscribedAt = data.generalInformation.subscribedAt
    movement.product = product
    movement.horodatage = data.generalInformation.horodatage
    movement.comment = data.comment
    movement.note = data.note

    movement.allShares = data.withdrawalRequest.selectAllUnit
    movement.executedAt = data.withdrawalRequest.executedAt
    movement.shareCount = data.withdrawalRequest.shareCount
    movement.sharePrice = data.withdrawalRequest.sharePrice
    movement.valueDate = data.withdrawalRequest.dateVL
    movement.flatFee = data.withdrawalRequest.flatFee
    movement.amount = data.withdrawalRequest.amount
    movement.subType =  data.withdrawalRequest.typeOfMovement
    movement.transactionExecutionAt =  data.withdrawalRequest.executedAt

    movement.slices = []
    if (shareSlices) {
      shareSlices.map(item => {
        movement.slices.push(item)
      })
    }

    const transferor = movement.investors?.find((investor) => {
      return investor?.type === "transferor" || null
    })

    const investor = movement.investors?.find((investor) => {
      return investor?.type === "investor" || null
    })

    const movementInvestors = []

    if (investors && investors.transferor) {
      let partner = undefined
      if(partners && partners.transferor) {
        partner = partners.transferor
      } else if (investors.transferor?.partner) {
        partner = investors.transferor.partner
      } else if (!investors.transferor.partner && transferor ) {
        partner = transferor.partner
      }

      const investor = new Investor(
          investors.transferor.id,
          'transferor',
          investors.transferor.prospect,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          [],
          data.investor.first.partnerType,
        investors.transferor.prospect && investors.transferor.prospect.prospectCode && data.documents && data.documents[investors.transferor.prospect.prospectCode] ?
            documentUtils.getDocumentsForProspect(data.documents[investors.transferor.prospect.prospectCode], documentArray)
          : [],
          partner
      )

      movementInvestors.push(investor)
    } else if (transferor) {
      const copyTransferor = {...transferor}
      copyTransferor.partner = partners?.transferor ?? undefined
      movementInvestors.push(copyTransferor)
    }

    if (investors && investors.investor) {
      let partner = undefined
      if(partners && partners.investor) {
        partner = partners.investor
      } else if (investors.investor?.partner) {
        partner = investors.investor.partner
      } else if (!investors.investor.partner && investor) {
        partner = investor.partner
      }

      const newInvestor = new Investor(
          investors.investor.id,
          'investor',
          investors.investor.prospect,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          [],
          data.investor.second.partnerType,
        investors.investor.prospect && investors.investor.prospect.prospectCode && data.documents && data.documents[investors.investor.prospect.prospectCode] ?
            documentUtils.getDocumentsForProspect(data.documents[investors.investor.prospect.prospectCode], documentArray)
          : [],
          partner
      )
      movementInvestors.push(newInvestor)
    } else if (investor) {
      const copyInvestorExisting = {...investor}
      copyInvestorExisting.partner = partners?.investor ?? undefined
      movementInvestors.push(copyInvestorExisting)
    }

    movement.investors = movementInvestors

    if (null !== movement.id) {
      new AgreementGateway().update(movement).then(response => {
        if (null !== response) {
          movement = response
          setUuidRefresh(uuidV4())
          toastSuccess(t('movement.gre-gre.notify.update-success'));
          dispatch(reloadMovement());
        } else {
          toastError(t('movement.gre-gre.notify.update-error'));
        }
      })
    } else {
      setIsLoading(true)
      new AgreementGateway().create(movement).then((response) => {
        if (null !== response) {
          toastSuccess(t('movement.gre-gre.notify.add-success'))
          navigate(`/${t('url.movements.edit-gre-gre')}/${response.id}`)

        } else {
          toastError(t('movement.gre-gre.notify.add-error'));
        }
        setIsLoading(false)
      })
    }
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));

  useEffect(() => {
    if(referential) {
      const investor = movement.investors?.find((investor) => {
        return investor?.type === "investor" || null
      })
      let platformTypeId = ""
      let networkTypeId = ""
      let platformType
      let networkType

      if (investor) {
        platformTypeId = (partners.investor?.platformType ?? investor.partner?.platformType) || ''
        networkTypeId = (partners.investor?.networkType ?? investor.partner?.networkType) || ''

        platformType = referential.partner?.platform.find((el) => el.value === platformTypeId)
        networkType = referential?.partner?.network.find((el) => el.value === networkTypeId)

        setSecondPartnerLink(partners.investor?.id || investor.partner?.id || null)
        setValue('investor.second.partnerNumber', partners.investor?.partnerCode || investor.partner?.partnerCode || "")
        setValue('investor.second.partnerSocialReason', partners.investor?.socialReason || investor.partner?.socialReason || "")
        setValue('investor.second.platformNetworkNumber', platformType?.label || "")
        setValue('investor.second.platformNetworkLastName', networkType?.label || "")
      } else {
        platformTypeId = partners.investor?.platformType || ''
        networkTypeId = partners.investor?.networkType || ''

        platformType = referential?.partner?.platform.find((el) => el.value === platformTypeId)
        networkType = referential?.partner?.network.find((el) => el.value === networkTypeId)

        setSecondPartnerLink(partners.investor?.id || null)
        setValue('investor.second.partnerNumber', partners.investor?.partnerCode || "")
        setValue('investor.second.partnerSocialReason', partners.investor?.socialReason || "")
        setValue('investor.second.platformNetworkNumber', platformType?.label || "")
        setValue('investor.second.platformNetworkLastName', networkType?.label || "")
      }

      const transferor = movement.investors?.find((investor) => {
        return investor?.type === "transferor" || null
      })

      if (transferor) {
        platformTypeId = (partners.transferor?.platformType ?? transferor.partner?.platformType) || ""
        networkTypeId = (partners.transferor?.networkType ?? transferor.partner?.networkType) || ""

        platformType = referential.partner.platform.find((el) => el.value === platformTypeId)
        networkType = referential?.partner?.network.find((el) => el.value === networkTypeId)

        setPartnerLink(partners.transferor?.id || transferor.partner?.id || null)
        setValue('investor.first.partnerNumber', partners.transferor?.partnerCode || transferor.partner?.partnerCode || "")
        setValue('investor.first.partnerSocialReason', partners.transferor?.socialReason || transferor.partner?.socialReason || "")
        setValue('investor.first.platformNetworkNumber', platformType?.label || "")
        setValue('investor.first.platformNetworkLastName', networkType?.label || "")
      } else {
        platformTypeId = partners.transferor?.platformType || ''
        networkTypeId = partners.transferor?.networkType || ''

        platformType = referential?.partner?.platform.find((el) => el.value === platformTypeId)
        networkType = referential?.partner?.network.find((el) => el.value === networkTypeId)

        setPartnerLink(partners.transferor?.id || null)
        setValue('investor.first.partnerNumber', partners.transferor?.partnerCode || "")
        setValue('investor.first.partnerSocialReason', partners.transferor?.socialReason || "")
        setValue('investor.first.platformNetworkNumber', platformType?.label || "")
        setValue('investor.first.platformNetworkLastName', networkType?.label || "")
      }
    }

  },[partners, referential, investors])

  useEffect(() => {
    if (watchProduct && watchProduct.id) {
      new ProductGateway().get(watchProduct.id).then(response => {
        setProduct(response)
        if (response?.decimalStep) {
          setDecimalStep(response.decimalStep)
        }
      })
    }
  }, [watchProduct.id])

  useEffect(() => {
    if (watchChoicePart) {
      setValue('withdrawalRequest.selectAllUnit', false)
    }
  }, [watchChoicePart])


  useEffect(() => {
    if (watchChoicePartSelectAll) {
      setValue('withdrawalRequest.choiceUnitNumbers', false)
    }
  }, [watchChoicePartSelectAll])

  useEffect(() => {
    if(watchSharePrice && watchshareCount) {
      const sharePrice = NumericStringToFloat(String(watchSharePrice));
      setValue('withdrawalRequest.amount', sharePrice * watchshareCount)
    }
  }, [watchSharePrice, watchshareCount])

  function handleClickChoicePartView() {
    if (watchshareCount && watchshareCount > 0 || watchChoicePartSelectAll) {
      dispatch(setOpenChoiceSliceToGiveUpEvent({
        show: true,
        prospectId: investors?.transferor?.prospect?.id,
        productId: getValues('generalInformation.product.id'),
        shareCount: watchshareCount,
        choicePartSelectAll: watchChoicePartSelectAll
        }))
    } else {
      toastError(t('movement.notify.choice-share-count-before'));
    }
  }

  const blockGeneralInformation = <div className={`form-bloc form--bloc--general-information`}>
    <div className="form-bloc__title">{t('movement.redemption-withdrawal.form.general-information.title')}</div>
    <div className="form-bloc__form flex-container">
      <ProductDalalist classes="col-md-6"
                       id="generalInformation.product"
                       name="generalInformation.product"
                       label={t('movement.redemption-withdrawal.form.general-information.product-acronym')}
                       labelClasses="mendatory-fild"
                       control={control}
                       defaultValue={getValues('generalInformation.product')}
                       disabled={isLectureMode}
      />
      <Input register={register}
             classes={'col-md-6'}
             label={t('movement.redemption-withdrawal.form.general-information.movement-number')}
             type={'text'}
             name={'generalInformation.movementNumber'}
             id={'generalInformation.movementNumber'}
             readOnly={true}
      />
      <SelectCustom register={register}
                    classes="col-md-6"
                    id="generalInformation.propertyType"
                    name="generalInformation.propertyType"
                    label={t('movement.redemption-withdrawal.form.general-information.nature-property')}
                    options={List.filterNaturePropertyList(movement.status, product?.productType ?? null, referential?.wallet_agreement.property_type ?? [])}
      />
      <Input register={register}
             classes={'col-md-6'}
             label={t('movement.gre-gre.general-information.external-movement-number')}
             type={'text'}
             name={'generalInformation.externalMovementNumber'}
             id={'generalInformation.externalMovementNumber'}
             readOnly={true}
      />
      <SelectCustom register={register}
                    classes="col-md-6"
                    id="generalInformation.possessionMode"
                    name="generalInformation.possessionMode"
                    label={t('movement.redemption-withdrawal.form.general-information.detention-mode')}
                    options={List.filterDetentionModeList(product?.productType ?? null, referential?.wallet_agreement.possession_mode ?? [])}
      />
      <Input register={register}
             classes={'col-md-6'}
             label={t('movement.redemption-withdrawal.form.general-information.administrator')}
             type={'text'}
             name={'generalInformation.administrator'}
             id={'generalInformation.administrator'}
             readOnly={true}
      />
      <InputDate id="generalInformation.horodatage"
                 label={t('movement.pre-subscription.form.general-information.timestamp-date')}
                 name="generalInformation.horodatage" classes="col-md-6" register={register} control={control} readOnly={isLectureMode}/>
      { movement.id !== null ? (
        <div className="u-txt-size-s u-txt-lightbold u-txt-italic col-md-12">
          {t('movement.pre-subscription.form.general-information.lastUpdateUser')} {getValues('generalInformation.lastUpdateUser')}
          {t('movement.pre-subscription.form.general-information.updatedAt')} {getValues('generalInformation.updatedAt')}
        </div>
      ) : ''}

    </div>
  </div>

  const blockInvestor = <div className={`form-bloc form--bloc--investor`}>
    <ReactTooltip />
    <div className="form-bloc__title">{t('movement.gre-gre.transferor.title')}</div>
    <div className="flex-container">
      <div className="col-md-6 form-bloc__form">
        <div className="flex-container">
          <div className="col-md-6">
            <button type="button" className="button button-reset u-txt-with-icon" onClick={() => !isLectureMode && dispatch(setOpenChoiceInvestorEvent({show: true, key: 'transferor', dataListType: "all"}))}>
              <img src={iconAdd} alt="" />
              {t('movement.gre-gre.transferor.title')}
            </button>
          </div>
          <div className="col-md-6">
            <button type="button" className="button button-reset u-txt-with-icon" onClick={() => dispatch(setOpenChoicePartnerEvent({show: true, key: 'transferor'}))}>
              <img src={iconAdd} alt="" />
              {t('account.search-a-partner')}
            </button>
          </div>
        </div>
        <table className="table--investor">
          <thead>
          <tr>
            <th/>
            <th className="form-control__label">{t('movement.redemption-withdrawal.form.investor.th-number')}</th>
            <th className="form-control__label">{t('movement.pre-subscription.form.investor.th-identity')}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <label htmlFor="partnerCode" className="form-control__label u-mrs">
                {t('movement.redemption-withdrawal.form.investor.investor-owner')}
              </label>
              { ownerLink &&
                <Link to={ `/${ t('url.customer.read-general-information') }/${ownerLink}` }
                      target="_blank"
                      rel="noopener noreferrer"
                >
                    <button type="button" className="button-reset">
                        <img src={iconSearch} alt="loupe"/>
                    </button>
                </Link>
              }
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.id'}
                     id={'investor.first.id'}
                     disabled
              />
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.identity'}
                     id={'investor.first.identity'}
                     readOnly={true}
                     data-tip={investors?.transferor?.prospect ? prospectUtils.formatProspectPersonIdentity(investors.transferor.prospect) : ''}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="partnerCode" className="form-control__label u-mrs">
                {t('movement.redemption-withdrawal.form.investor.investor-partner')}
              </label>
              { partnerLink &&
                  <Link to={ `/${ t('url.partner.read-general-information') }/${partnerLink}` }
                        target="_blank"
                        rel="noopener noreferrer"
                  >
                      <button type="button" className="button-reset">
                          <img src={iconSearch} alt="loupe"/>
                      </button>
                  </Link>
              }
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.partnerNumber'}
                     id={'investor.first.partnerNumber'}
                     disabled
              />
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.partnerSocialReason'}
                     id={'investor.first.partnerSocialReason'}
                     disabled
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="partnerCode" className="form-control__label">
                {t('movement.redemption-withdrawal.form.investor.platform-network')}
              </label>
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.platformNetworkNumber'}
                     id={'investor.first.platformNetworkNumber'}
                     disabled
              />
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.platformNetworkLastName'}
                     id={'investor.first.platformNetworkLastName'}
                     disabled
              />
            </td>
            <td/>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="col-md-6 form-bloc__form">
        <div className="flex-container">
          <div className="col-md-6">
            <button type="button" className="button button-reset u-txt-with-icon" onClick={() => !isLectureMode && dispatch(setOpenChoiceInvestorEvent({show: true, key: 'investor', dataListType: "all"}))}>
              <img src={iconAdd} alt="" />
              {t('movement.gre-gre.investor.title')}
            </button>
          </div>
          <div className="col-md-6">
            <button type="button" className="button button-reset u-txt-with-icon" onClick={() => dispatch(setOpenChoicePartnerEvent({show: true, key: 'investor'}))}>
              <img src={iconAdd} alt="" />
              {t('account.search-a-partner')}
            </button>
          </div>
        </div>
        <table className="table--investor">
          <thead>
          <tr>
            <th/>
            <th className="form-control__label">{t('movement.redemption-withdrawal.form.investor.th-number')}</th>
            <th className="form-control__label">{t('movement.pre-subscription.form.investor.th-identity')}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <label htmlFor="partnerCode" className="form-control__label u-mrs">
                {t('movement.redemption-withdrawal.form.investor.investor-usufructuary')}
              </label>
              { usuLink &&
                  <Link to={ `/${ t('url.customer.read-general-information') }/${ usuLink }` }
                        target="_blank"
                        rel="noopener noreferrer"
                  >
                      <button type="button" className="button-reset">
                          <img src={ iconSearch } alt="Loupe"/>
                      </button>
                  </Link>
              }
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.second.id'}
                     id={'investor.second.id'}
                     disabled
              />
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.second.identity'}
                     id={'investor.second.identity'}
                     readOnly={true}
                     data-tip={investors?.investor?.prospect ? prospectUtils.formatProspectPersonIdentity(investors.investor.prospect) : ''}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="partnerCode" className="form-control__label u-mrs">
                {t('movement.redemption-withdrawal.form.investor.usufructuary-partner')}
              </label>
              { secondPartnerLink &&
                  <Link to={ `/${ t('url.partner.read-general-information') }/${ secondPartnerLink }` }
                        target="_blank"
                        rel="noopener noreferrer"
                  >
                      <button type="button" className="button-reset">
                          <img src={ iconSearch } alt="Loupe"/>
                      </button>
                  </Link>
              }
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.second.partnerNumber'}
                     id={'investor.second.partnerNumber'}
                     disabled
              />
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.second.partnerSocialReason'}
                     id={'investor.second.partnerSocialReason'}
                     disabled
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="partnerCode" className="form-control__label">
                {t('movement.redemption-withdrawal.form.investor.platform-network')}
              </label>
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.second.platformNetworkNumber'}
                     id={'investor.second.platformNetworkNumber'}
                     disabled
              />
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.second.platformNetworkLastName'}
                     id={'investor.second.platformNetworkLastName'}
                     disabled
              />
            </td>
            <td/>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  const blockWithdrawalRequest = <div className={`form-bloc form--bloc--withdrawal-request`}>
    <div className="form-bloc__title">{t('movement.gre-gre.entering-request-mutual-agreement.title')}</div>
    <div className="flex-container">
      <SelectCustom register={register}  classes="col-md-6" id="withdrawalRequest.typeOfMovement"  name="withdrawalRequest.typeOfMovement" label={t('movement.redemption-withdrawal.form.withdrawal-request.type-of-movement')} options={referential?.wallet_agreement.transaction_subtype || null} readOnly={referential?.wallet_agreement.transaction_subtype === undefined}/>
      <InputDate register={register} control={control} classes="col-md-6" type="text" name="withdrawalRequest.executedAt" id="withdrawalRequest.executedAt" label={t('movement.redemption-withdrawal.form.withdrawal-request.execution-date')}/>
      <Input type="number"
             register={register}
             classes={`col-md-6 mandatory-field`}
             inputClasses={`${watchshareCount ? '' : 'field-in-error'}`}
             id="withdrawalRequest.shareCount"
             name="withdrawalRequest.shareCount"
             label={t('movement.redemption-withdrawal.form.withdrawal-request.number-of-shares-for-sale')}
      />
      <div className="col-md-3 choices-shares">
        <Checkbox register={register}
                  id="withdrawalRequest.selectAllUnit"
                  name="withdrawalRequest.selectAllUnit"
                  label={t('movement.redemption-withdrawal.form.withdrawal-request.shares-select-all')}
        />
        {watchChoicePartSelectAll && <div className="u-pointer" onClick={() => handleClickChoicePartView()}>
          <img src={eyeIcon} alt=""/>
        </div>}
      </div>
      <div className="col-md-3 choices-shares">
        <Checkbox register={register}
                  id="withdrawalRequest.choiceUnitNumbers"
                  name="withdrawalRequest.choiceUnitNumbers"
                  label={t('movement.mutation.form.request.choice-of-unit-numbers')}
        />
        {watchChoicePart &&
          <div className="u-pointer" onClick={() => handleClickChoicePartView()}>
            <img src={eyeIcon} alt="" />
          </div>
        }
      </div>
      <InputNumber
        id={'withdrawalRequest.sharePrice'}
        name={'withdrawalRequest.sharePrice'}
        classes="col-md-6"
        control={control}
        decimalScale={decimalStep}
        valueIsNumericString={false}
        register={register}
        label={t('movement.redemption-withdrawal.form.withdrawal-request.unit-price-vl')}
      />
      <InputDate register={register} control={control} classes="col-md-6" type="text" name="withdrawalRequest.dateVL" id="withdrawalRequest.dateVL" label={t('movement.redemption-withdrawal.form.withdrawal-request.vl-date-to-be-taken-into-account')}/>
      <Input register={register} classes="col-md-6" type="number" name="withdrawalRequest.flatFee" id="withdrawalRequest.flatFee" label={t('movement.redemption-withdrawal.form.withdrawal-request.fixed-fee')} />
      <div className="col-md-6"/>
      <Input register={register} classes="col-md-6" type="number" name="withdrawalRequest.amount" id="withdrawalRequest.amount" label={t('movement.gre-gre.entering-request-mutual-agreement.total-cost-to-pay')} readOnly /></div>
  </div>
  const blockSliceList = <div className="form-bloc form--bloc--list-slices">
    <ListToSell SliceArray={shareSlices}
                isLectureMode={isLectureMode}
                title={t('movement.heading-slice.state-recovery-heritage-post-transaction')} />
  </div>
  const blockSliceDetailsList = <div className="form-bloc form--bloc--list-slices">
    <ListToSell isLectureMode={isLectureMode}
                SliceArray={shareSlicesFreeze}
                title={t('movement.heading-slice.state-recovery-heritage-actual-transaction')}
                deleteDisabled={true}
    />
  </div>
  const blockListDocumentsInvestors = movement?.investors?.map((investor, index) => {
    if (investor && investor.prospect && investor.prospect.persons) {
      return investor.prospect.persons.map((person) => {
        if (movement.id && investor && investor.prospect && investor.prospect && person && person.id) {
          return (
            <>
              <div className="col-md-6">
                <DocumentForm
                  title={`${t('movement.pre-subscription.form.list-of-documents.title')} : ${investor?.prospect?.prospectCode} - ${person.lastname} ${person.firstname}`}
                  name={`documents.${investor.prospect.prospectCode}.${person.id}`}
                  uuid={person.id}
                  documentGateway={new DocumentGateway(movement.id, investor.prospect.id)}
                  register={register}
                  control={control}
                  documentData={documentArray[person.id]}
                  isLectureMode={isLectureMode}
                />
              </div>
            </>
          )
        }
      })
    }
  })

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, [isLectureMode]);

  const getActions = (actionArray: any) => {
    setActions(actionArray)
  }

  return (
    <>
      {referential &&
      <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
        <Status label={"movement.state"} status={movement.status} statusList={referential.wallet.status}/>
        <ActionReason reason={
          referential?.wallet_subscription?.cancel_reason?.find(reason => {
            return reason.value == movement.actionReason
          })?.label ?? movement.actionReason
        }/>
        {!movement.id && <AlertInfo text={t('movement.warning-action-possible')} classes="u-mys" />}
        {movement.id && <MovementTag transactionId={movement.id} tags={movement.tags || []} optionsReferential={referential.transaction.tag} readOnly={isLectureMode} />}
        <div className="flex-container agreement">
          <div className="col-md-6">{blockGeneralInformation}</div>
          <div className="col-md-6">{movement.id && <ActionsForm gateway={new AgreementGateway()}
                                                                 transactionUuid={movement.id}
                                                                 uuidRefresh={uuidRefresh}
                                                                 callback={getActions}
                                                                 setFormError={setFormError}
          />
          }
          </div>
          <FormErrorInfoDropdown errorArray={formError}/>
          {(actions?.invalidate.date && !actions?.confirm.date) &&
            <div className="col-md-12">
              <CommentBlock
                data={movement.comment}
                onChange={(data: string) => setValue('comment', data)}
                config={CKEditorUtil.getConfig()}
                readonly={isLectureMode}
              />
            </div>
          }
          <div className="col-md-12">{blockInvestor}</div>
          <div className="col-md-12">{blockWithdrawalRequest}</div>
          {shareSlicesFreeze.length > 0 && <div className="col-md-12">{blockSliceDetailsList}</div>}
          <div className="col-md-12">{blockSliceList}</div>
          <div className="col-md-12">
            <div className="flex-container">
              {blockListDocumentsInvestors}
            </div>
          </div>
          {movement.status === "subscribed" && movement.id &&
            <div className="col-md-12">
              <RecapForm transactionUuid={movement.id}
                         typeMvt='agreement'
              />
            </div>
          }
          <div className="col-md-12">
            <NoteBlock
              data={movement.note}
              onChange={(data: string) => {setValue('note', data)}}
              config={CKEditorUtil.getConfig()}
              readonly={isLectureMode || ['canceled', 'subscribed'].includes(movement.status ?? 'draft')}
            />
          </div>
        </div>
        {!isLectureMode && <FooterBlock disabled={!watchProduct.id || isLoading} />}
      </form>
      }
    </>
  )
}

export default Form
