import React, {useEffect, useState} from 'react';
import {getViewModel, renderKpiByType} from "../../util/Kpi";
import {KpiResponseInterface, ViewModelInterface} from "../../Type/Kpi";
import KPICarousel from "./KpiCarousel/KPICarousel";
import {v4 as uuidV4} from "uuid";
import Spinner from "../Spinner/Spinner";

interface Props {
  getKpi: () => Promise<KpiResponseInterface[] | null>
}

const Kpi = ({getKpi}: Props) => {
  const [kpisNumber, setKpisNumber] = useState<ViewModelInterface[] | null>(null)
  const [kpis, setKpis] = useState<ViewModelInterface[] | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    (getKpi()
        .then(response => {
          if (response) {
            const viewModels: ViewModelInterface[] = response.map(kpi => {
              if (getViewModel(kpi)) {
                return getViewModel(kpi)
              }
            })

            setKpisNumber(viewModels.filter(item => item.type === 'number'))
            setKpis(viewModels.filter(item => item.type !== 'number'))
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false))
    )
  }, [getKpi])

  if (isLoading) return (
    <div style={{height: "33vh"}}
         className={"flex items-center justify-center"}
    >
      <Spinner size={150}/>
    </div>
  )

  return (
    <section className="container-KPIs">
      <div className="container-numbers">
        {kpisNumber &&
          kpisNumber.map(kpi => {
            return <div className="datas__first" key={uuidV4()}>
              {renderKpiByType(kpi)}
            </div>
          })
        }
      </div>

      <div className="container-graph">
        {kpis && <KPICarousel>
          {kpis.map(kpi => {
            return <div key={uuidV4()}>
              <div>{renderKpiByType(kpi)}</div>
            </div>
          })
          }
        </KPICarousel>}
      </div>
    </section>
  )
}

export default Kpi;