import PortfolioGatewayInterface from '../../../domain/Movement/PortfolioGatewayInterface';
import { PortfolioRequestInterface } from './PortfolioRequest';
import { PortfolioListInterface } from '../../../domain/Movement/PortfolioList';

export default class PortfolioListUseCase
{
    public portfolioGateway

    constructor(PortfolioGateway: PortfolioGatewayInterface) {
        this.portfolioGateway = PortfolioGateway
    }

    async execute(uuid: string, ListRequest: PortfolioRequestInterface): Promise<{data: PortfolioListInterface[]|null, numberOfItems: number}|null> {
        return await this.portfolioGateway.getPortfolio(uuid, ListRequest.currentPage, ListRequest.itemPerPage, ListRequest.filter).then(response => response)
    }
}
