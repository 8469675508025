import {FilterInterface} from '../../../../domain/Share/Slice/SliceList'

interface ListRequestInterface {
  investorId: string
  currentPage: number
  itemPerPage: number
  filter: FilterInterface|null
}

class ListRequest implements ListRequestInterface{
  public currentPage: number
  public itemPerPage: number
  public filter: FilterInterface|null
  public investorId: string

  constructor(
    currentPage: number,
    itemPerPage: number,
    filter: FilterInterface|null,
    investorId: string,
  ) {
    this.currentPage = currentPage
    this.itemPerPage = itemPerPage
    this.filter = filter
    this.investorId = investorId
  }
}

export {
  ListRequest
}
export type { ListRequestInterface }
