import {Presenter} from '../Presenter'
import {ProductBankInformationInterface} from "../../domain/Product/BankInformation";

class ProductBankInformationListPresenter extends Presenter {

  constructor(getBankInformation: ProductBankInformationInterface[]) {
    super({
      viewModel: {
        title: '',
        heading: [
          {name: 'account.heading.Action'},
          {name: 'product.form.bank-details.type', sort: "type", labelApi: "type"},
          {name: 'product.form.bank-details.label', sort: "title", labelApi: "title"},
          {name: 'product.form.bank-details.bank', sort: "bank", labelApi: "bank"},
          {name: 'account.form.bank-details.iban', sort: "iban", labelApi: "iban"},
          {name: 'account.form.bank-details.swift', sort: "swift", labelApi: "swift"},
        ],
        data: getBankInformation,
        filtersShortcut: [
        ],
        filters: [],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });
  }
}

export default ProductBankInformationListPresenter
