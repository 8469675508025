import ValidatorGateway from "../../gateway/Validator/ValidatorGateway";

export default class IbanValidatorUseCase
{
  validatorGateway: ValidatorGateway

  constructor(
    validatorGateway: ValidatorGateway
  ) {
    this.validatorGateway = validatorGateway
  }

  async execute(iban: string): Promise<boolean> {
    return await this.validatorGateway.validate('iban', iban).then(response => {return response})
  }
}
