import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import { v4 as uuidV4 } from 'uuid'
import SelectCustom from '../../Elements/Select'
import TransactionTagGateway from '../../../../../gateway/Tag/TransactionTagGateway'
import {ReferentielItemInterface} from '../../../../../domain/Referentiel/ReferentielItemInterface'
import infoIcon from '../../../../../assets/images/icons/info.svg'
import alertIcon from '../../../../../assets/images/icons/alert-exclamation.svg'
import MovementUtil from '../../../util/MovementUtil';

type Props = {
  transactionId: string
  tags: Array<string|number>
  optionsReferential: ReferentielItemInterface[]
  readOnly?: boolean
}

interface IFormInput {
  tag: string,

}

const MovementTag: FunctionComponent<Props> = ({transactionId, tags, optionsReferential, readOnly}) => {
  const {t} = useTranslation()

  const [tagsLocal, setTagsLocal] = useState<Array<string|number>>(tags)
  const [options, setOptions] = useState<ReferentielItemInterface[]>(optionsReferential.filter(tag =>  !tags.includes(tag.value)))
  const { register, watch, setValue } = useForm<IFormInput>()
  const watchTag = watch('tag')

  useEffect(() => {
    if (watchTag !== undefined && watchTag !== '') {
      new TransactionTagGateway().add(transactionId, [watchTag]).then(response => {
        if (response !== null) {
          setTagsLocal(response)
          setOptions(optionsReferential.filter(tag =>  !response.includes(tag.value)))
          setValue('tag', '')
        }
      })
    }
  }, [watchTag])

  useEffect(() => {

    setOptions(optionsReferential.filter(tag =>  !tagsLocal.includes(tag.value)))
  }, [tagsLocal])

  function handleClickDeleteTag(tag: string|number) {
    if(readOnly){
      return
    }
    new TransactionTagGateway().remove(transactionId, [tag]).then(response => {
      if (response !== null) {
        setTagsLocal(response)
      }
    })
  }

  return (
    <>
      <div className="badge-line badge-line--wide-columns u-mbs items-center">
        <SelectCustom classes="tag-span" id="tag" label={t('movement.status')} containerClasses={'form-control--wide-columns'} labelClasses={'u-mrs'} name="tag" options={options} register={register} />
        <div className="badge-wrapper">
          {tagsLocal.map(tag => {
            const key = `movement.tags.${tag}`
            const type = MovementUtil.getType(tag)
            return (
              <div className={`badge badge--shadow ${readOnly ? '' : 'u-pointer'} status--${type}`} key={uuidV4()} onClick={() => handleClickDeleteTag(tag)}>
                <div className="badge__container">
                  <div className="badge__status">
                    {type && type.length !== 4 ? <img src={alertIcon} alt="alert" className="movement-status--tag"/> : <img src={infoIcon} alt="info" className="movement-status--tag"/> }
                    <div className="badge__text">{t(key)}</div>
                  </div>
                  {readOnly ? '' : (
                    <button type="button" className={`badge__remove status--${type}`} tabIndex={-1} title="Remove item" aria-label="Remove item">
                      <span aria-hidden="true">×</span>
                    </button>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default MovementTag
