import {Dismemberment, DismembermentInterface} from "../../../domain/Movement/Dismemberment/Dismemberment";


export default class InitializeDismemberment {
  initializeDismemberment(): DismembermentInterface {
    return new Dismemberment(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      null,
      false,
      null,
      null,
      null,
      null,
      'dismemberment',
      null,
      null,
      null,
      null,
      null,
      [],
      false,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
  }
}
