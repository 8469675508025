import DocumentGatewayInterface from "../../../../domain/Document/DocumentGatewayInterface";
import {
  DocumentInterface,
  DocumentListInterface,
  DocumentToSend,
  DocumentToViewInterface
} from "../../../../domain/Document/Document";
import Caller from "../../../../domain/Api/Caller";
import envVariable from "../../../../infrastructure/presentation/util/envVariable";

export default class DocumentGateway implements DocumentGatewayInterface {

  transactionId: string|null
  prospectId: string|null

  constructor(
    transactionId: string|null,
    prospectId: string|null
  ) {
    this.transactionId = transactionId
    this.prospectId = prospectId
  }

  getFiles(): Promise<any> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transactions/${this.transactionId}/files`, {})
    .then(data => {
      if (data) {
        return data.data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }

  getFile(fileId: number): Promise<string | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transactions/${this.transactionId}/files/${fileId}`, {})
    .then(data => {
      if (data) {
        return data.content
      }

      return null
    })
    .catch(() => {
      return null
    })
  }
  save(uuid: string, documents: DocumentToSend[]): Promise<[]|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/wallet/transactions/${this.transactionId}/files/${uuid}`, {
      files: documents
    })
    .then(data => {
      if (data) {
        return data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }
  getList(): Promise<DocumentListInterface[]> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/agreements/${this.transactionId}/${this.prospectId}/documents`, {})
    .then(data => {
      if (data) {
        return data
      }
    })
    .catch(() => {
      return null
    })
  }
  deleteFile(fileId: number): Promise<boolean> {
    //TODO: Fonctionnalitée uniquement disponible pour les presouscription https://myrem.atlassian.net/browse/DS-630?atlOrigin=eyJpIjoiMzJkNzMxN2Y0ZGMyNGVhMDljMDQ1NzBhM2Y1OGY2NjYiLCJwIjoiaiJ9
    return Promise.resolve(false)
  }
}
