import React, {FunctionComponent, FormEvent, InputHTMLAttributes} from 'react'

import {IFormFieldProps} from './IFormFieldProps'
import {ReferentielItemInterface} from '../../../../domain/Referentiel/ReferentielItemInterface'

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  id: string
  name: string
  classes?: string
  labelClasses?: string
  value?: string
  defaultValue?: string
  onChange?: (e: FormEvent<HTMLInputElement>) => void
  options: ReferentielItemInterface[]
}


const RadioChoices: FunctionComponent<IProps> = ({id, name, classes, labelClasses, label, onChange, value, defaultValue, register, error, required = false, options, disabled,  ...rest}) => {
  const radio = <div className="form-control form-control--label-auto">
      {options.map(option => {
        return <div className="form-control__input" key={option.value}>
          <label className="form-label form-label--input-radio">
            <input type="radio" value={option.value} {...register(name, {required: required})} onChange={onChange} required={required} disabled={disabled}/>
            {option.label}
          </label>
        </div>
      })}
  </div>

  return (
    <>
      <div className={`${classes}`}>
        {label &&
          <div className="form-control">
            <label htmlFor={id} className={`form-control__label ${labelClasses}`}>{label}</label>
            {radio}
          </div>
        }
        {!label &&
          <div className="form-control">
            {radio}
          </div>
        }
      </div>
    </>
  )
}

export default RadioChoices
