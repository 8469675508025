import {FilterInterface, PersonListInterface} from "../../domain/Person/PersonList";
import Caller from "../../domain/Api/Caller";
import envVariable from "../../infrastructure/presentation/util/envVariable";

export default class PersonGateway {
  getList(
      currentPage: number,
      itemsPerPage: number,
      filter: FilterInterface|null,
      signal?: AbortSignal,
      sort?: string,
      order?: string,
  ): Promise<{data: PersonListInterface[]|null, numberOfItems: number}|null> {
    let filters = '';
    if (filter?.firstname) {
      filters += '&filters[firstname]='+filter.firstname
    }
    if (filter?.lastname) {
      filters += '&filters[lastname]='+filter.lastname
    }
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/persons?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${filters}${sort ? '&sort=' + sort + '&order=' + order : ''}`, {}, signal)
    .then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    }).catch(response => {
      return {
        data: [],
        numberOfItems: 0
      }
    });
  }
}
