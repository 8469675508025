import { ReferentielItemInterface } from '../../../domain/Referentiel/ReferentielItemInterface';

export default class MovementUtil {

  static getType(tagValue: string|number): string {
    if( typeof tagValue === 'number' ) {
      return 'error'
    }
    if (['blocking_partner_authorization', 'relaunch', 'rcci_agreement'].includes(tagValue)) {
      return 'alert'
    }
    // le reste : "waiting_for_treatment", "waiting_for_items", "check_cashing_pending", "pending_funds", "pending_direct_debit", "completude", "to_throw_back"
    return 'info'
  }

  static sortState(list: ReferentielItemInterface[]): ReferentielItemInterface[] {
    // ordre de retour pour correspondre au figma :
    const sortedList = ['Brouillon', 'Saisie du dossier réalisée', 'En attente de validation interne', 'Renvoi complétude',
      'Dossier confirmé', 'Ordre passé', 'Mouvement réalisé', 'Dossier rétracté', 'Refus TR']
    const listForSorted = [...list]
    return listForSorted.sort((a, b) => sortedList.indexOf(a.label) - sortedList.indexOf(b.label))
  }
}
