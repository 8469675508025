import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";

type Props = {
  reason: string|null|number
}

const ActionReason: FunctionComponent<Props> = ({reason}) => {
  const {t} = useTranslation()

  return (
    <div className="badge-line">
      {reason &&
      <div className="form-control form-control--wide-columns u-mbs reason">
        <label className="form-control__label">{t('movement.cancel-reason')} </label>
        <input type="text" value={reason ?? ''} disabled={true}/>
      </div>
      }
    </div>
  )
}

export default ActionReason
