import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
  ReferentielInterface
} from '../../../domain/Referentiel/ReferentielInterface'
import { Country } from '../../../domain/Referentiel/Country/Country'

interface Referential {
  referential: ReferentielInterface|null
  countryByDefault: Country[]
}

const initialState: Referential = {
  referential: null,
  countryByDefault: []
}

export const referentialSlice = createSlice({
  name: 'referential',
  initialState: initialState,
  reducers: {
    InitializeReferential: (state, action: PayloadAction<ReferentielInterface>) => {
      return {...state, referential: action.payload}
    },
    InitializeDefaultCity: (state, action: PayloadAction<Country[]|[]>) => {
      return {...state, countryByDefault: action.payload}
    }
  }
})

export const {
  InitializeReferential,
  InitializeDefaultCity
} = referentialSlice.actions

export default referentialSlice.reducer