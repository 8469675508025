import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../store/hook";
import {setOpenDatalistFilterPledge, setOpenPledgeForm} from "../../../../store/component/event";
import {v4 as uuidV4} from "uuid";
import iconHeadingSearch from "../../../../../assets/images/icons/datalist-heading-search.svg";
import iconEdit from "../../../../../assets/images/icons/datalist-edit.svg";
import Pagination from "../../Pagination/Pagination";
import {SubmitHandler, useForm} from "react-hook-form";
import {
  FilterPledgeInterface,
  PledgeListInterface
} from "../../../../../domain/Pledge/PledgeList";
import PledgeGateway from "../../../../../gateway/Pledge/PledgeGateway";
import PledgePresenter
  from "../../../../../presenter/Pledge/PledgePresenter";
import download from "../../../util/Download";
import HeaderRight from "../Element/HeaderRight";
import {ListRequest} from "../../../../../useCase/Pledge/List/ListRequest";
import ListUseCase from "../../../../../useCase/Pledge/List/ListUseCase";
import InputDate from "../../Elements/InputDate";
import {setPledgeCurrentDataList} from "../../../../store/component/customer";

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

type Props = {
  investorId: string
}

const PledgeDatalist: FunctionComponent<Props> = ({investorId}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openDatalistFilterPledge = useAppSelector((state) => state.event.openDatalistFilterPledge)
  const currentPledgeDataList = useAppSelector((state) => state.customer.currentPledgeDataList)
  const customerRules = useAppSelector((state) => state.me.me?.rules.customer.actions)
  const [viewModel, setViewModel] = useState<ViewModelInterface | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)

  const {register, control, handleSubmit, setValue} = useForm()

  useEffect(() => {
    const listRequest = new ListRequest(currentPage, viewModel?.pagination.itemsPerPage || 50, openDatalistFilterPledge.filters, investorId)
    const pledge = new ListUseCase(new PledgeGateway()).execute(listRequest).then(response => {
      return response
    })
    const presenter = new PledgePresenter(pledge);
    presenter.load().then(() => {
      setViewModel(presenter.immutableViewModel())
      dispatch(setPledgeCurrentDataList(presenter.immutableViewModel()))
    })
    setValue('product', openDatalistFilterPledge.filters.product)
    setValue('dateStart', openDatalistFilterPledge.filters.dateStart)
    setValue('dateEnd', openDatalistFilterPledge.filters.dateEnd)
    setValue('status', openDatalistFilterPledge.filters.status)
  }, [currentPage, openDatalistFilterPledge.filters])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterPledge.filters])

  const onSubmit: SubmitHandler<FilterPledgeInterface> = data => {
    dispatch(setOpenDatalistFilterPledge({
      show: false,
      count: openDatalistFilterPledge.count,
      filters: {
        product: data.product,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        status: data.status
      }
    }))
  }

  const resetFilters = () => {
    dispatch(setOpenDatalistFilterPledge({
      show: false,
      count: openDatalistFilterPledge.count,
      filters: {
        product:'',
        dateStart: '',
        dateEnd: '',
        status: ''
      }
    }))
  }

  const handleCLickExport = () => {
    setLoadingExport(true)

    new PledgeGateway().getExport(openDatalistFilterPledge.filters, investorId).then(response => {
      if (response) {
        download(t('export.pledge'), response)
        setLoadingExport(false)
      }
    })
  }

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber)

  const handleClickFilter = (response: string) => {
    if(response) {
      dispatch(setOpenDatalistFilterPledge({
        show: true,
        count: openDatalistFilterPledge.count,
        filters: openDatalistFilterPledge.filters
      }))
    }
  }

  return (
    <>
      {(viewModel !== null &&
          <>
              <div className={`datalist`}>
                  <div className="datalist__header flex-container">
                      <div className="col-md-3 flex flex-col">
                          <div
                              className="form-bloc__title u-txt-left">{t('account.form.pledge.filters.subtitle-dates')}</div>
                          <div className="flex items-baseline justify-center">
                              <label htmlFor="pledge.dateStart"
                                     className="form-control__label">
                                {t('account.form.pledge.filters.dateStart')}
                              </label>
                              <InputDate register={register}
                                         control={control}
                                         classes="col-md-5"
                                         type="text"
                                         name="dateStart"
                                         id="dateStart"
                              />
                              <label htmlFor="pledge.dateEnd"
                                     className="form-control__label">
                                {t('account.form.pledge.filters.dateEnd')}
                              </label>
                              <InputDate register={register}
                                         control={control}
                                         classes="col-md-5"
                                         type="text"
                                         name="dateEnd"
                                         id="dateEnd"
                              />
                          </div>
                      </div>
                      <div className="col-md-3 flex flex-col">
                          <div
                              className="form-bloc__title u-txt-left">{t('account.form.pledge.filters.subtitle-display-pledges')}</div>
                          <div className="flex justify-evenly u-mts">
                              <div className="form-control__input">
                                  <label className="form-label form-label--input-radio">
                                      <input type="radio" value="in_progress" {...register('status')} />
                                    {t('account.form.pledge.filters.inProgress')}
                                  </label>
                              </div>
                              <div className="form-control__input">
                                  <label className="form-label form-label--input-radio">
                                      <input type="radio" value="all" {...register('status')} />
                                    {t('account.form.pledge.filters.all')}
                                  </label>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className="form-bloc__title u-txt-left"><br/></div>
                          <div className="flex justify-between">
                              <form onSubmit={handleSubmit(onSubmit)} className="filter">
                                  <div className="filter__input">
                                    {currentPledgeDataList?.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                                      <div key={uuidV4()} className="input-no-border">
                                        <img src={iconHeadingSearch} alt=""/>
                                        <input {...register(filter.field)}
                                               placeholder={t('common.search-by', {keyword: t(filter.keyword)})}
                                               className="u-mxs"
                                        />
                                      </div>
                                    ))}
                                  </div>
                                  <div className="filter__actions">
                                      <button type="submit"
                                              className="button button--submit">{t('search.submit')}</button>
                                      <button type="button" className="button button--white"
                                              onClick={() => resetFilters()}>{t('search.cancel')}</button>
                                  </div>
                              </form>
                              <HeaderRight numberOfActivatedFilters={openDatalistFilterPledge.count}
                                           handleClickFilter={handleClickFilter}
                                           handleClickExport={handleCLickExport}
                                           isLoadingExport={isLoadingExport}
                                           allowExport={customerRules?.export}
                              />
                          </div>

                      </div>
                  </div>
                  <div className="table-fix-head">
                  <table className="datalist__datas">
                      <thead>
                      <tr>
                        {currentPledgeDataList?.heading.map((title: string) => (
                          <th key={title}>{t(title)}</th>
                        ))}
                      </tr>
                      </thead>
                      <tbody>
                      {currentPledgeDataList?.data !== undefined &&
                        currentPledgeDataList?.data
                          .map((item: PledgeListInterface) => {
                            return (
                              <tr key={item.id}>
                                <td>
                                  <button type="button" className="button-reset">
                                    <img onClick={() => dispatch(setOpenPledgeForm({show: true, pledge: {
                                        id: item.id,
                                        pledgeOrganization: item.pledgeOrganization,
                                        type: item.type,
                                        reason: item.reason,
                                        product: item.product,
                                        slice: item.slice,
                                        startAt: item.startAt,
                                        endAt: item.endAt,
                                        handAt: item.handAt,
                                        quantity: item.slice?.sliceShareCount,
                                        startShare: item.slice?.start,
                                        endShare: item.slice?.end,
                                      }}))}
                                         src={iconEdit} alt=""/>
                                  </button>
                                </td>
                                <td>{item.reason ? t(`account.form.pledge.datalist.reason.${item.reason}`) : ""}</td>
                                <td>{item.pledgeOrganization?.name}</td>
                                <td>{item.type ? t(`account.form.pledge.datalist.type.${item.type}`) : ""}</td>
                                <td>{item.startAt}</td>
                                <td>{item.endAt}</td>
                                <td>{item.handAt}</td>
                                <td>{item.product?.label}</td>
                                <td>{item.slice?.sliceShareCount}</td>
                                <td>{item.slice?.start}</td>
                                <td>{item.slice?.end}</td>
                              </tr>
                            );
                          })}
                      {currentPledgeDataList?.data === undefined || currentPledgeDataList?.data.length === 0 &&
                          <tr>
                              <td colSpan={currentPledgeDataList?.heading.length}>{t('common.data-is-empty')}</td>
                          </tr>
                      }
                      </tbody>
                  </table>
                  </div>
              </div>
              <Pagination currentPage={currentPage} itemsPerPage={currentPledgeDataList?.pagination.itemsPerPage}
                          numberOfItems={currentPledgeDataList?.pagination.numberOfItems} callback={paginate}/>
          </>
      )}
    </>
  )
}

export default PledgeDatalist
