import {FunctionComponent, InputHTMLAttributes} from 'react'

import {IFormFieldViewProps} from './IFormFieldProps'

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldViewProps {
  classes?: string
  classesFormControl?: string
  value?: string
  defaultValue?: string
}

const InputView: FunctionComponent<IProps> = ({classes, classesFormControl, label, value, defaultValue, ...rest}) => {
  const inputElement = <div className="form-control__input">
    <input defaultValue={defaultValue} {...rest}
           readOnly={rest.readOnly}
           title={rest.help} />
  </div>

  return (
    <>
      <div className={`${classes}`}>
        {label &&
          <div className={`form-control ${classesFormControl ? classesFormControl : ''}`}>
            <label className="form-control__label">{label}</label>
            {inputElement}
          </div>
        }
        {!label && inputElement}
      </div>
    </>
  )
}

export default InputView
