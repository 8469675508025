import CustomerGatewayInterface from '../../../domain/Customer/CustomerGatewayInterface'
import {CustomerInterface} from '../../../domain/Customer/Customer'

export default class UpdateUseCase
{
  public prospectGateway

  constructor(CustomerGateway: CustomerGatewayInterface) {
    this.prospectGateway = CustomerGateway
  }

  async execute(Customer: CustomerInterface): Promise<CustomerInterface|null> {
    return await this.prospectGateway.update(Customer).then(response => response)
  }
}
