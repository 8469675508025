import {FunctionComponent} from 'react'

type Props = {
  color?: string
}

const Movements: FunctionComponent<Props> = ({color}) => {
    return (
        <svg width="46" height="50" viewBox="0 0 46 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.9199 40.5813C19.9077 40.5813 16.963 39.688 14.4584 38.0145C11.9537 36.3409 10.0016 33.9623 8.84887 31.1793C7.69611 28.3963 7.3945 25.334 7.98217 22.3795C8.56984 19.4251 10.0204 16.7113 12.1504 14.5813C14.2804 12.4513 16.9942 11.0007 19.9486 10.4131C22.903 9.82541 25.9654 10.127 28.7484 11.2798C31.5314 12.4325 33.91 14.3847 35.5836 16.8893C37.2571 19.3939 38.1504 22.3386 38.1504 25.3509C38.1442 29.3883 36.5376 33.2586 33.6826 36.1135C30.8277 38.9685 26.9574 40.5751 22.9199 40.5813ZM22.9199 12.4636C20.3711 12.4636 17.8795 13.2194 15.7602 14.6355C13.6409 16.0515 11.9891 18.0643 11.0136 20.4191C10.0382 22.7739 9.78303 25.3652 10.2803 27.865C10.7775 30.3649 12.0049 32.6612 13.8073 34.4635C15.6096 36.2659 17.9059 37.4933 20.4058 37.9905C22.9056 38.4878 25.4969 38.2326 27.8517 37.2571C30.2065 36.2817 32.2193 34.6299 33.6353 32.5106C35.0514 30.3913 35.8072 27.8997 35.8072 25.3509C35.801 21.9348 34.4413 18.6605 32.0258 16.245C29.6103 13.8295 26.336 12.4698 22.9199 12.4636Z" fill={color || 'white'}/>
            <path d="M23.5999 32.0988H19.5697C19.259 32.0988 18.961 31.9754 18.7413 31.7556C18.5216 31.5359 18.3982 31.2379 18.3982 30.9272C18.3982 30.6165 18.5216 30.3185 18.7413 30.0988C18.961 29.8791 19.259 29.7557 19.5697 29.7557H23.5999C24.0287 29.7557 24.44 29.5853 24.7432 29.2821C25.0464 28.9789 25.2167 28.5677 25.2167 28.1389C25.2167 27.7101 25.0464 27.2989 24.7432 26.9957C24.44 26.6925 24.0287 26.5221 23.5999 26.5221H22.2409C21.1907 26.5221 20.1835 26.1049 19.4408 25.3623C18.6982 24.6197 18.281 23.6124 18.281 22.5622C18.281 21.512 18.6982 20.5048 19.4408 19.7621C20.1835 19.0195 21.1907 18.6023 22.2409 18.6023H26.2711C26.5818 18.6023 26.8798 18.7257 27.0995 18.9454C27.3193 19.1652 27.4427 19.4631 27.4427 19.7739C27.4427 20.0846 27.3193 20.3826 27.0995 20.6023C26.8798 20.822 26.5818 20.9454 26.2711 20.9454H22.2409C21.8121 20.9454 21.4009 21.1158 21.0977 21.419C20.7945 21.7222 20.6241 22.1334 20.6241 22.5622C20.6241 22.991 20.7945 23.4022 21.0977 23.7054C21.4009 24.0086 21.8121 24.179 22.2409 24.179H23.5999C24.6502 24.179 25.6574 24.5962 26.4 25.3388C27.1426 26.0814 27.5598 27.0886 27.5598 28.1389C27.5598 29.1891 27.1426 30.1963 26.4 30.939C25.6574 31.6816 24.6502 32.0988 23.5999 32.0988Z" fill={color || 'white'}/>
            <path d="M22.9194 34.8872C22.6087 34.8872 22.3107 34.7638 22.091 34.5441C21.8713 34.3244 21.7479 34.0264 21.7479 33.7156V31.6303C21.7479 31.3195 21.8713 31.0215 22.091 30.8018C22.3107 30.5821 22.6087 30.4587 22.9194 30.4587C23.2302 30.4587 23.5281 30.5821 23.7479 30.8018C23.9676 31.0215 24.091 31.3195 24.091 31.6303V33.7156C24.091 34.0264 23.9676 34.3244 23.7479 34.5441C23.5281 34.7638 23.2302 34.8872 22.9194 34.8872Z" fill={color || 'white'}/>
            <path d="M22.9194 20.2426C22.6087 20.2426 22.3107 20.1192 22.091 19.8995C21.8713 19.6798 21.7479 19.3818 21.7479 19.0711V16.9857C21.7479 16.6749 21.8713 16.3769 22.091 16.1572C22.3107 15.9375 22.6087 15.8141 22.9194 15.8141C23.2302 15.8141 23.5281 15.9375 23.7479 16.1572C23.9676 16.3769 24.091 16.6749 24.091 16.9857V19.0711C24.091 19.3818 23.9676 19.6798 23.7479 19.8995C23.5281 20.1192 23.2302 20.2426 22.9194 20.2426Z" fill={color || 'white'}/>
            <path d="M44.0083 21.8357C43.6976 21.8357 43.3996 21.7122 43.1799 21.4925C42.9602 21.2728 42.8368 20.9748 42.8368 20.6641V17.454C42.8368 14.266 41.5704 11.2086 39.3161 8.95433C37.0619 6.70008 34.0044 5.43366 30.8165 5.43366H6.51807C6.20735 5.43366 5.90935 5.31022 5.68964 5.09051C5.46993 4.8708 5.3465 4.57281 5.3465 4.26209C5.3465 3.95137 5.46993 3.65337 5.68964 3.43366C5.90935 3.21395 6.20735 3.09052 6.51807 3.09052H30.8165C34.624 3.09671 38.2738 4.61199 40.9661 7.30432C43.6584 9.99665 45.1737 13.6465 45.1799 17.454V20.6641C45.1799 20.9748 45.0565 21.2728 44.8368 21.4925C44.6171 21.7122 44.3191 21.8357 44.0083 21.8357Z" fill={color || 'white'}/>
            <path d="M6.51846 7.77705C6.3648 7.77892 6.21246 7.74862 6.07122 7.68809C5.92998 7.62756 5.80297 7.53814 5.69836 7.42558L3.35521 5.08244C3.24314 4.97725 3.15381 4.85021 3.09275 4.70916C3.03169 4.56811 3.00018 4.41604 3.00018 4.26234C3.00018 4.10864 3.03169 3.95656 3.09275 3.81551C3.15381 3.67446 3.24314 3.54742 3.35521 3.44224L5.69836 1.0991C5.91586 0.881592 6.21086 0.759399 6.51846 0.759399C6.82605 0.759399 7.12105 0.881592 7.33855 1.0991C7.55606 1.3166 7.67825 1.6116 7.67825 1.9192C7.67825 2.22679 7.55606 2.52179 7.33855 2.7393L5.83894 4.26234L7.33855 5.78538C7.45063 5.89056 7.53996 6.0176 7.60102 6.15865C7.66208 6.29971 7.69358 6.45178 7.69358 6.60548C7.69358 6.75918 7.66208 6.91125 7.60102 7.0523C7.53996 7.19336 7.45063 7.3204 7.33855 7.42558C7.23394 7.53814 7.10693 7.62756 6.96569 7.68809C6.82445 7.74862 6.67211 7.77892 6.51846 7.77705Z" fill={color || 'white'}/>
            <path d="M39.3219 47.6104H15.0235C11.216 47.6042 7.56617 46.0889 4.87384 43.3966C2.18151 40.7042 0.66623 37.0544 0.660034 33.2469V30.0368C0.660034 29.7261 0.783467 29.4281 1.00318 29.2084C1.22289 28.9887 1.52089 28.8652 1.83161 28.8652C2.14233 28.8652 2.44032 28.9887 2.66003 29.2084C2.87974 29.4281 3.00318 29.7261 3.00318 30.0368V33.2469C3.00318 36.4349 4.2696 39.4923 6.52385 41.7466C8.77809 44.0008 11.8355 45.2672 15.0235 45.2672H39.3219C39.6326 45.2672 39.9306 45.3907 40.1503 45.6104C40.37 45.8301 40.4935 46.1281 40.4935 46.4388C40.4935 46.7495 40.37 47.0475 40.1503 47.2672C39.9306 47.4869 39.6326 47.6104 39.3219 47.6104Z" fill={color || 'white'}/>
            <path d="M39.3225 49.9537C39.1688 49.9556 39.0165 49.9253 38.8752 49.8647C38.734 49.8042 38.607 49.7148 38.5024 49.6022C38.3903 49.497 38.301 49.37 38.2399 49.2289C38.1788 49.0879 38.1473 48.9358 38.1473 48.7821C38.1473 48.6284 38.1788 48.4763 38.2399 48.3353C38.301 48.1942 38.3903 48.0672 38.5024 47.962L40.002 46.439L38.5024 44.9159C38.3947 44.8082 38.3092 44.6804 38.251 44.5397C38.1927 44.399 38.1627 44.2481 38.1627 44.0958C38.1627 43.9435 38.1927 43.7927 38.251 43.652C38.3092 43.5113 38.3947 43.3834 38.5024 43.2757C38.6101 43.168 38.7379 43.0826 38.8786 43.0243C39.0193 42.966 39.1702 42.936 39.3225 42.936C39.4748 42.936 39.6256 42.966 39.7663 43.0243C39.907 43.0826 40.0349 43.168 40.1426 43.2757L42.4857 45.6189C42.5978 45.7241 42.6871 45.8511 42.7482 45.9921C42.8092 46.1332 42.8407 46.2853 42.8407 46.439C42.8407 46.5927 42.8092 46.7447 42.7482 46.8858C42.6871 47.0268 42.5978 47.1539 42.4857 47.2591L40.1426 49.6022C40.038 49.7148 39.9109 49.8042 39.7697 49.8647C39.6285 49.9253 39.4761 49.9556 39.3225 49.9537Z" fill={color || 'white'}/>
        </svg>
    )
}

export default Movements
