import React, {FunctionComponent, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm, useFormState, FormProvider} from 'react-hook-form'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css'

import {useAppDispatch, useAppSelector} from '../../../../../store/hook'
import {
  setOpenChoiceInvestorEvent,
  setOpenChoicePartnerEvent,
  setOpenUploadForm
} from '../../../../../store/component/event'
import {ProductInterface} from '../../../../../../domain/Product/Product'
import {ReferentielInterface} from '../../../../../../domain/Referentiel/ReferentielInterface'
import {PreSubscriptionInterface} from '../../../../../../domain/PreSubscription/PreSubscription'
import {Recurrence} from '../../../../../../domain/PreSubscription/Recurrence'
import {Investor} from '../../../../../../domain/PreSubscription/Investor'
import ProductDalalist from '../../../Datalist/Product/ProductDalalist'
import ProductGateway from '../../../../../../gateway/Product/ProductGateway'
import PreSubscriptionGateway from '../../../../../../gateway/PreSubscription/PreSubscriptionGateway'
import Input from '../../../Elements/Input'
import SelectCustom from '../../../Elements/Select'
import InputDate from '../../../Elements/InputDate'
import CheckboxToggle from '../../../Elements/CheckboxToggle'
import {
  reloadMovement,
  setInvestorOwner,
  setInvestorUsufructuary,
  setPartner
} from '../../../../../store/component/movement'
import {ActionListInterface} from '../../../../../../domain/Movement/Action/Action'
import MovementTag from '../../../Tag/MovementTag/MovementTag'
import AlertInfo from '../../../Alert/AlertInfo/AlertInfo'
import eyeIcon from '../../../../../../assets/images/icons/eye.svg'
import uploadIcon from '../../../../../../assets/images/icons/upload.svg'
import iconAdd from '../../../../../../assets/images/icons/add.svg'
import iconSearch from '../../../../../../assets/images/icons/datalist-search.svg'
import '../../../../../../assets/styles/page/_movement.scss'
import Status from "../../../Transaction/Status";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {usePrompt} from "../../../../util/Navigation";
import DocumentGateway from "../../../../../../gateway/PreSubscription/Document/DocumentGateway";
import DocumentForm from "../../Document/Form"
import {v4 as uuidV4} from 'uuid'
import {Link, useNavigate} from 'react-router-dom';
import ActionsForm from "../../Action/ActionsForm";
import ActionReason from "../../../Transaction/ActionReason";
import ReactTooltip from "react-tooltip";
import RecapForm from "../../Recap/RecapForm";
import dayjs from "dayjs";
import PartnerGateway from "../../../../../../gateway/Partner/PartnerGateway";
import {ParseFloat} from "../../../../util/ParseFloat";
import FormErrorInfoDropdown from "../../../Alert/FormErrorInfoDropdown/FormErrorInfoDropdown";
import List from "../../../../util/List";
import CKEditorUtil from "../../../../util/CKEditor";
import FooterBlock from "../../../Block/FooterBlock";
import NoteBlock from "../../../Block/NoteBlock";
import CommentBlock from "../../../Block/CommentBlock";
import DocumentUtils from "../../../../util/DocumentUtils";
import ProspectUtils from "../../../../util/ProspectUtils";
import {DocumentInterface, DocumentType} from "../../../../../../domain/Document/DocumentInterface";
import {PersonInterface} from "../../../../../../domain/Prospect/Person";
import BankInformationBlock from "../../Blocks/Movement/BankInformation";
import {ReferentielItemInterface} from "../../../../../../domain/Referentiel/ReferentielItemInterface";
import BankInformationUtils from "../../../../util/BankInformationUtils";
import {BankInformationFormInterface} from "../../../../../../domain/Movement/BankInformation";
import InputNumber from "../../../Elements/InputNumber";
import {FloatToNumericString, NumericStringToFloat} from "../../../../../transformer/NumericStringToFloat";
import useDebounce from "../../../../../../domain/CustomHooks/useDebounce";

interface IFormInput {
  generalInformation: {
    product: {
      id: string | null,
      label: string | null,
      value: string | null
    }
    movementNumber: string | null
    propertyType: string | null
    administrator: string | null
    possessionMode: string | null
    subscribedAt: string | null
    horodatage: string | null
    updatedAt: string | null
    lastUpdateUser: string | null
  }
  investor: {
    first: {
      id: string | null
      identity: string | null
      partnerId: string
      partnerNumber: string
      partnerSocialReason: string
      platformNetworkNumber: string | null
      platformNetworkLastName: string | null
      partnerType: string | null
    }
    second: {
      id: string | null
      identity: string | null
      partnerNumber: string
      partnerSocialReason: string
      platformNetworkNumber: string | null
      platformNetworkLastName: string | null
      partnerType: string | null
    }
  }
  particularity: {
    minor: boolean
    notFrench: boolean
    recurrent: boolean
    counterpartyResearch: boolean
    dateStartMandat: string | null
    dateEndMandat: string | null
    ppe: boolean
    ter: boolean
    dividendReinvestment: boolean
    duration: number | null
    lifeAnnuity: boolean
    guardianship: boolean
    investment50k: boolean
  }
  movement: {
    shareCount: number | null
    sharePrice: number | null
    totalAmount: number | null
    commissionStatus: string | null
    commissionEarned: number | string | null
    commissionNotEarned: number | string | null
    netAssetValue?: number | null
    netAssetValueDate?: string | null
    totalGrossAmount?: number | null
    totalNetAmount?: number | null

    investorFirst: {
      dismembermentKey: string | null
      dismembermentAmount: number | null
      paymentMode: string | null
      checkDepositAt: string | null
      paymentStatus: string | null
      paymentNature: string | null
      settlementDate: string | null
    }
    investorSecond: {
      dismembermentKey: string | null
      dismembermentAmount: number | null
      paymentMode: string | null
      checkDepositAt: string | null
      paymentStatus: string | null
      paymentNature: string | null
      settlementDate: string | null
    }
  }
  recurrence: {
    status: string | null
    numRecurrence: string | null
    period: { start: string | null, end: string | null }
    periodicity: string | null
    shareCount: number | null
    sharePrice: number | null
    totalAmount: number | null
    rumCode: string | null
    rumStatus: string | null
  }
  note: string
  comment: string

  documents: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          date: string
          updatedAt: string
        }
      }
    }
  }

  bankInformations: BankInformationFormInterface
}

type Props = {
  preSubscription: PreSubscriptionInterface
  isLectureMode: boolean
}

const Form: FunctionComponent<Props> = ({preSubscription, isLectureMode}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  const referential: ReferentielInterface | null = useAppSelector(({referential}) => referential.referential)
  const partners = useAppSelector((state) => state.movement.partners)
  const investors = useAppSelector((state) => state.movement.investors)
  const [showUsufruitier, setShowUsuFruitier] = useState<boolean>(false)
  const [showCounterPart, setShowCounterPart] = useState<boolean>(false)
  const [product, setProduct] = useState<ProductInterface | null>(null)
  const [documentArray, setDocumentArray] = useState<DocumentType>({})
  const openUploadForm = useAppSelector((state) => state.event.openUploadForm)
  const openDocumentList = useAppSelector((state) => state.event.openDocumentList)
  const [uuidRefresh, setUuidRefresh] = useState<string>('');
  const [actions, setActions] = useState<ActionListInterface | null>(null)
  const [convention, setConvention] = useState<any | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formError, setFormError] = useState<any>(null)
  const [ownerLink, setOwnerLink] = useState<string | null>(null)
  const [partnerLink, setPartnerLink] = useState<string | null>(null)
  const [usuLink, setUsuLink] = useState<string | null>(null)
  const [secondPartnerLink, setSecondPartnerLink] = useState<string | null>(null)
  const [decimalStep, setDecimalStep] = useState<number>(8)
  const documentUtils = new DocumentUtils()
  const prospectUtils = useMemo(() => new ProspectUtils(), [])
  const bankInformationUtils = new BankInformationUtils()

  const methods = useForm<IFormInput>(
    {
      defaultValues: {
        generalInformation: {
          product: {
            id: preSubscription.product?.id,
            label: preSubscription.product?.label,
            value: preSubscription.product?.id
          },
          horodatage: preSubscription.horodatage,
          movementNumber: preSubscription.transactionCode,
          propertyType: preSubscription.propertyType,
          administrator: preSubscription.user ? `${preSubscription.user.lastname} ${preSubscription.user.firstname}` : '',
          possessionMode: preSubscription.possessionMode,
          subscribedAt: preSubscription.subscribedAt,
          updatedAt: preSubscription.updatedAt,
          lastUpdateUser: preSubscription.lastUpdateUser ? `${preSubscription.lastUpdateUser.lastname} ${preSubscription.lastUpdateUser.firstname}` : '',
        },
        investor: {
          first: investors.owner !== null && investors.owner.prospect?.persons ? {
            id: investors.owner.prospect?.prospectCode,
            identity: prospectUtils.formatProspectPersonIdentity(investors.owner.prospect),
            partnerType: investors.owner.partnerType
          } : {id: null, identity: null},
          second: investors.usufructuary !== null && investors.usufructuary.prospect?.persons ? {
            id: investors.usufructuary.prospect?.prospectCode,
            identity: prospectUtils.formatProspectPersonIdentity(investors.usufructuary.prospect),
            partnerType: investors.usufructuary.partnerType
          } : {id: null, identity: null}
        },
        particularity: {
          minor: preSubscription.minor,
          notFrench: preSubscription.notFrench,
          recurrent: preSubscription.recurrent,
          counterpartyResearch: preSubscription.counterpartyResearch,
          dateStartMandat: preSubscription.dateStartMandat,
          dateEndMandat: preSubscription.dateEndMandat,
          ppe: preSubscription.ppe,
          ter: preSubscription.ter,
          dividendReinvestment: preSubscription.dividendReinvestment,
          duration: preSubscription.duration,
          lifeAnnuity: preSubscription.lifeAnnuity,
          guardianship: preSubscription.guardianship,
          investment50k: preSubscription.investment50k
        },
        movement: {
          shareCount: preSubscription.shareCount,
          sharePrice: preSubscription.sharePrice,
          totalAmount: preSubscription.totalAmount,
          commissionStatus: preSubscription.commissionStatus,
          netAssetValueDate: preSubscription.netassetDate,
          totalNetAmount: preSubscription.totalNetAmount,
          investorFirst: {
            dismembermentKey: investors.owner?.dismembermentKey,
            dismembermentAmount: investors.owner?.amount,
            paymentMode: investors.owner?.paymentMode,
            checkDepositAt: investors.owner?.checkDepositAt,
            paymentStatus: investors.owner?.paymentStatus,
            paymentNature: investors.owner?.paymentNature,
            settlementDate: investors.owner?.paymentDate,
          },
          investorSecond: {
            dismembermentKey: investors.usufructuary?.dismembermentKey,
            dismembermentAmount: investors.usufructuary?.amount,
            paymentMode: investors.usufructuary?.paymentMode,
            checkDepositAt: investors.usufructuary?.checkDepositAt,
            paymentStatus: investors.usufructuary?.paymentStatus,
            paymentNature: investors.usufructuary?.paymentNature,
            settlementDate: investors.usufructuary?.paymentDate,
          }
        },
        recurrence: {
          status: null,
          numRecurrence: null,
          period: {
            start: preSubscription?.recurrence?.period?.start || null,
            end: preSubscription?.recurrence?.period?.end || null,
          },
          periodicity: preSubscription?.recurrence?.periodicity || null,
          shareCount: preSubscription?.recurrence?.shareCount || null,
          sharePrice: preSubscription?.recurrence?.sharePrice || null,
          totalAmount: preSubscription?.recurrence?.totalAmount || null,
          rumCode: preSubscription?.recurrence?.rumCode || null,
          rumStatus: preSubscription?.recurrence?.rumStatus || null,
        }
      }
    }
  )

  const {register, control, handleSubmit, reset, watch, setValue, getValues} = methods


  const watchHorodatage = watch('generalInformation.horodatage')
  const watchPropertyType = watch('generalInformation.propertyType')
  const watchProduct = watch('generalInformation.product')
  const watchParticularityRecurrence = watch('particularity.recurrent')
  const watchDuration = watch('particularity.duration')
  const watchLifeAnnuity = watch('particularity.lifeAnnuity')
  const watchMovementShareCount = watch('movement.shareCount')
  const watchMovementSharePrice = watch('movement.sharePrice')
  const watchReccurenceShareCount = watch('recurrence.shareCount')
  const watchReccurenceSharePrice = watch('recurrence.sharePrice')
  const watchInvestorFirstPaymentMode = watch('movement.investorFirst.paymentMode')
  const watchInvestorSecondPaymentMode = watch('movement.investorSecond.paymentMode')
  const watchCounterpartyResearch = watch('particularity.counterpartyResearch')
  const watchNetAssetValue = watch('movement.netAssetValue')
  const watchNetAssetValueDate = watch('movement.netAssetValueDate')
  const watchTotalNetAmount = watch('movement.totalNetAmount')
  const watchCommissionEarned = watch('movement.commissionEarned')
  const watchCommissionNotEarned = watch('movement.commissionNotEarned')
  const watchPartnerId = watch('investor.first.partnerId')
  const [watchFirstDismembermentKey, setWatchFirstDismembermentKey] = useState('0')
  const [watchSecondDismembermentKey, setWatchSecondDismembermentKey] = useState('0')
  const debouncedWatchFirstDismembermentKey = useDebounce(watchFirstDismembermentKey, 300)
  const debouncedWatchSecondDismembermentKey = useDebounce(watchSecondDismembermentKey, 300)

  //  DS-53 START
  const movementTotalAmount = getValues('movement.totalAmount')

  const {isDirty} = useFormState({
    control
  });

  const fetchNetAssetValueByDate = useCallback((productId: string | null) => {
    if (productId && watchNetAssetValueDate) {
      new ProductGateway()
        .getNetAssetValueByDate(productId, watchNetAssetValueDate)
        .then((response: any) => {
          setValue('movement.netAssetValue', response?.value || null)
          if (watchTotalNetAmount) {
            setValue('movement.shareCount', watchTotalNetAmount / response?.value)
          }
        })
    } else if (productId && !watchNetAssetValueDate) {
      setValue('movement.netAssetValue', null)
      setValue('movement.shareCount', null)
    }
  }, [setValue, watchNetAssetValueDate, watchTotalNetAmount])

  const fetchConventionDependPartner = useCallback((partnerId?: string | null) => {
    if ((partnerId || watchPartnerId) && watchProduct.id) {
      new PartnerGateway()
        .getConventionByProduct((partnerId || watchPartnerId), watchProduct.id)
        .then((response: any) => {
          setConvention(response)
        })
    }
  }, [watchProduct.id, watchPartnerId])

  const measuredRef = useCallback((node: HTMLFormElement | null) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node, ["movement.commissionStatus"])
    }
  }, [isLectureMode]);

  useEffect(() => {

    setValue('movement.investorFirst.dismembermentKey', investors.owner?.dismembermentKey || null)
    setValue('movement.investorFirst.dismembermentAmount', investors.owner?.amount || null)
    setValue('movement.investorFirst.checkDepositAt', investors.owner?.checkDepositAt || null)
    setValue('movement.investorFirst.paymentMode', investors.owner?.paymentMode || null)
    setValue('movement.investorFirst.paymentStatus', investors.owner?.paymentStatus || null)
    setValue('movement.investorFirst.paymentNature', investors.owner?.paymentNature || null)
    setValue('movement.investorFirst.settlementDate', investors.owner?.paymentDate || null)

    if (investors.owner && investors.owner.prospect) {

      if (preSubscription && preSubscription.investors) {
        preSubscription.investors.map((investor, index) => {
          if (investor.id === investors?.owner?.id && preSubscription && preSubscription.investors && preSubscription.investors[index]) {
            preSubscription.investors[index] = investors.owner
          }
        })
      }

      if (referential && referential.partner && referential.partner.partner_type) {
        const partnerType = referential.partner.partner_type.find((type) => {
          return type.value === investors?.owner?.prospect?.partner?.type
        })

        if (partnerType) {
          setValue('investor.first.partnerType', partnerType.label)
        }
      }

      if (investors.owner.prospect.persons) {
        setOwnerLink(investors.owner.prospect.id)
        setValue('investor.first.id', investors.owner.prospect.prospectCode)
        setValue('investor.first.identity', prospectUtils.formatProspectPersonIdentity(investors.owner.prospect))
        setValue('investor.first.platformNetworkNumber', investors.owner.prospect.partner?.platform ?? "")
        setValue('investor.first.platformNetworkLastName', investors.owner.prospect.partner?.network ?? "")

      }
    }
  }, [investors.owner])

  useEffect(() => {
    setValue('movement.investorSecond.dismembermentKey', investors.usufructuary?.dismembermentKey || null)
    setValue('movement.investorSecond.dismembermentAmount', investors.usufructuary?.amount || null)
    setValue('movement.investorSecond.checkDepositAt', investors.usufructuary?.checkDepositAt || null)
    setValue('movement.investorSecond.paymentMode', investors.usufructuary?.paymentMode || null)
    setValue('movement.investorSecond.paymentStatus', investors.usufructuary?.paymentStatus || null)
    setValue('movement.investorSecond.paymentNature', investors.usufructuary?.paymentNature || null)
    setValue('movement.investorSecond.settlementDate', investors.usufructuary?.paymentDate || null)

    if (investors.usufructuary && investors.usufructuary.prospect) {

      if (preSubscription && preSubscription.investors) {
        preSubscription.investors.map((investor, index) => {
          if (investor.id === investors?.usufructuary?.id && preSubscription && preSubscription.investors && preSubscription.investors[index]) {
            preSubscription.investors[index] = investors.usufructuary
          }
        })
      }

      if (referential && referential.partner && referential.partner.partner_type) {
        const partnerType = referential.partner.partner_type.find((type) => {
          return type.value === investors?.usufructuary?.prospect?.partner?.type
        })

        if (partnerType) {
          setValue('investor.second.partnerType', partnerType.label)
        }
      }

      if (investors.usufructuary.prospect.persons) {
        setUsuLink(investors.usufructuary.prospect.id)
        setValue('investor.second.id', investors.usufructuary.prospect.prospectCode)
        setValue('investor.second.identity', prospectUtils.formatProspectPersonIdentity(investors.usufructuary.prospect))
        setValue('investor.second.partnerNumber', investors.usufructuary.prospect.partner?.partnerCode ?? '')
        setValue('investor.second.platformNetworkNumber', investors.usufructuary.prospect.partner?.platform ?? '')
        setValue('investor.second.platformNetworkLastName', investors.usufructuary.prospect.partner?.network ?? '')
      }
    }
  }, [investors.usufructuary])

  useEffect(() => {
  if (!openUploadForm.show && !openDocumentList.show) {
      new DocumentGateway(preSubscription.id, null).getFiles().then((response) => {
        if (response) {
          setDocumentArray(response)
        }
      })
    }
  }, [openUploadForm, openDocumentList])

  useEffect(() => {
    setValue("recurrence.status", preSubscription?.recurrence?.status || null)
    setValue("recurrence.numRecurrence", preSubscription?.recurrence?.id || null)
    const investor = preSubscription.investors?.find((investor) => {
      return investor?.type === "investor" || null
    })

    if (investor) {
      dispatch(setInvestorOwner(investor))
    }

    const usufructuary = preSubscription.investors?.find((investor) => {
      return investor?.type === "usufruct" || null
    })

    if (usufructuary) {
      dispatch(setInvestorUsufructuary(usufructuary))
    }

    if (preSubscription.id) {
      setValue('generalInformation.lastUpdateUser', (preSubscription.lastUpdateUser ? `${preSubscription.lastUpdateUser.lastname} ${preSubscription.lastUpdateUser.firstname}` : ''))
      setValue('generalInformation.updatedAt', preSubscription.updatedAt)

      if (preSubscription.comment) {
        setValue('comment', preSubscription.comment)
      }
      if (preSubscription.note) {
        setValue('note', preSubscription.note)
      }
    }

    if (preSubscription && preSubscription.investors) {
      preSubscription.investors.map((investor) => {
        investor.bankInformations?.map((bankI) => {
          if (investor.prospect) {
            setValue(
              `bankInformations.${investor.prospect.prospectCode}.${bankI.type}`,
              {
                id: bankI.id,
                bankInformationSelected: bankI.bankInformation?.id ?? null,
                bankInformation: {
                  id: bankI.bankInformation?.id ?? null,
                  label: bankI.bankInformation?.label ?? null,
                  bank: bankI.bankInformation?.bank ?? null,
                  iban: bankI.bankInformation?.iban ?? null,
                  swift: bankI.bankInformation?.swift ?? null,
                },
                directDebitMandateAccepted: bankI.directDebitMandateAccepted,
                rumCode: bankI.rumCode,
                rumStatus: bankI.rumStatus
              }
            )

          }
        })
      })
    }

    return () => {
      dispatch(setPartner({partner: null, key: "reset"}))
      dispatch(setInvestorOwner({owner: null, key: "reset"}))
      dispatch(setInvestorUsufructuary({usufructuary: null, key: "reset"}))
    }
  }, [preSubscription.id])

  useEffect(() => {
    if (preSubscription && preSubscription.investors) {
      preSubscription.investors.map((investor) => {
        if (investor.prospect && investor.prospect.persons) {
          investor.prospect.persons.map((person: PersonInterface) => {
            if (person.id && documentArray[person.id]) {
              documentArray[person.id].map((doc: DocumentInterface) => {
                setValue(`documents.${investor?.prospect?.prospectCode}.${person.id}.${doc.type}.date`, doc.date)
                setValue(`documents.${investor?.prospect?.prospectCode}.${person.id}.${doc.type}.updatedAt`, doc.updatedAt)
              })
            }
          })
        }
      })
    }
  }, [documentArray, preSubscription, setValue])

  useEffect(() => {
    if (watchPropertyType) {
      setShowUsuFruitier(watchPropertyType === 'dismemberment' || watchPropertyType === 'usufruct')
      setShowCounterPart(watchPropertyType === 'np' || watchPropertyType === 'usufruct')
    }
  }, [watchPropertyType])

  useEffect(() => {
    if (watchMovementShareCount && watchMovementSharePrice) {
      setValue('movement.totalAmount', watchMovementShareCount * watchMovementSharePrice)
      if (watchFirstDismembermentKey && watchSecondDismembermentKey) {
        setBlocMouvementAMountsByKeys(
          watchMovementShareCount * watchMovementSharePrice,
          watchFirstDismembermentKey ? parseFloat(watchFirstDismembermentKey): 0,
          watchSecondDismembermentKey ? parseFloat(watchSecondDismembermentKey): 0
        )
      }
    }

    if (!watchMovementShareCount) {
      setBlocMouvementAMountsByKeys(
        0,
        watchFirstDismembermentKey ? parseFloat(watchFirstDismembermentKey): 0,
        watchSecondDismembermentKey ? parseFloat(watchSecondDismembermentKey): 0
      )
    }
  }, [setValue, watchMovementShareCount, watchMovementSharePrice])

  useEffect(() => {
    if (watchReccurenceShareCount && watchReccurenceSharePrice) {
      setValue('recurrence.totalAmount', watchReccurenceShareCount * watchReccurenceSharePrice)
    }
  }, [setValue, watchReccurenceShareCount, watchReccurenceSharePrice])

  useEffect(() => {
    if (watchProduct && watchProduct.id) {
      new ProductGateway().get(watchProduct.id).then(response => {
        setProduct(response)
      })
    }
  }, [watchProduct])

  useEffect(() => {
    if (product && product?.productType?.slug === "sc") {
      fetchNetAssetValueByDate(product.id)
      fetchConventionDependPartner()
    }
  }, [watchNetAssetValueDate, watchPartnerId, product, fetchNetAssetValueByDate, fetchConventionDependPartner])

  useEffect(() => {
    if (!watchTotalNetAmount && product && product?.productType?.slug === "sc") {
      setValue('movement.commissionEarned', 0)
      setValue('movement.commissionNotEarned', 0)
      setValue('movement.totalGrossAmount', 0)
    }

    if (watchTotalNetAmount && product && product?.productType?.slug === "sc") {

      const totalNetAmount = NumericStringToFloat(String(watchTotalNetAmount))

      let commissionEarned: number | null = null
      let commissionNotEarned: number | null = null
      let totalGrossAmount: number | null = null
      // let shareCount: number | null = null

      if (convention?.scCommissionEarned && convention?.scCommissionEarnedPercent) {

        commissionEarned = totalNetAmount * ((convention.scCommissionEarned / 100) * (convention.scCommissionEarnedPercent)) / 100
        setValue('movement.commissionEarned', commissionEarned)
      } else {
        setValue('movement.commissionEarned', '')
      }

      if (convention?.scCommissionNotEarned && convention?.scCommissionNotEarnedPercent) {
        commissionNotEarned = totalNetAmount * (convention.scCommissionNotEarned / 100 * convention.scCommissionNotEarnedPercent) / 100
        setValue('movement.commissionNotEarned', commissionNotEarned)
      } else {
        setValue('movement.commissionNotEarned', '')
      }

      if (commissionEarned && commissionNotEarned) {
        totalGrossAmount = commissionEarned + commissionNotEarned + totalNetAmount
        setValue('movement.totalGrossAmount', totalGrossAmount)
      }

      if (totalGrossAmount && watchNetAssetValue) {
        const shareCount = totalGrossAmount / watchNetAssetValue
        setValue('movement.shareCount', shareCount)
      }
    }
  }, [convention, product, setValue, watchNetAssetValue, watchTotalNetAmount])

  useEffect(() => {
    if (product !== null) {
      setValue('movement.sharePrice', Number(product.valorisation?.subscription.value) || null)
      setValue('recurrence.sharePrice', Number(product.valorisation?.subscription.value) || null)
      setDecimalStep(product?.decimalStep ?? 8)
    }
  }, [product])

  useEffect(() => {
    if (referential) {
      const usufructuary = preSubscription.investors?.find((investor) => {
        return investor?.type === "usufruct" || null
      })
      let platformTypeId = ""
      let networkTypeId = ""
      let platformType
      let networkType

      if (usufructuary) {
        platformTypeId = (partners.usufructuary?.platformType ?? usufructuary.partner?.platformType) || ''
        networkTypeId = (partners.usufructuary?.networkType ?? usufructuary.partner?.networkType) || ''

        platformType = referential.partner?.platform.find((el) => el.value === platformTypeId)
        networkType = referential?.partner?.network.find((el) => el.value === networkTypeId)
        setSecondPartnerLink(partners.usufructuary?.id || usufructuary.partner?.id || null)
        setValue('investor.second.partnerNumber', partners.usufructuary?.partnerCode || usufructuary.partner?.partnerCode || "")
        setValue('investor.second.partnerSocialReason', partners.usufructuary?.socialReason || usufructuary.partner?.socialReason || "")
        setValue('investor.second.platformNetworkNumber', platformType?.label || "")
        setValue('investor.second.platformNetworkLastName', networkType?.label || "")

      } else {
        platformTypeId = partners.usufructuary?.platformType || ''
        networkTypeId = partners.usufructuary?.networkType || ''

        platformType = referential?.partner?.platform.find((el) => el.value === platformTypeId)
        networkType = referential?.partner?.network.find((el) => el.value === networkTypeId)

        setSecondPartnerLink(partners.usufructuary?.id || null)
        setValue('investor.second.partnerNumber', partners.usufructuary?.partnerCode || "")
        setValue('investor.second.partnerSocialReason', partners.usufructuary?.socialReason || "")
        setValue('investor.second.platformNetworkNumber', platformType?.label || "")
        setValue('investor.second.platformNetworkLastName', networkType?.label || "")
      }

      const owner = preSubscription.investors?.find((investor) => {
        return investor?.type === "transferor" || investor?.type === "investor" || null
      })

      if (owner) {
        platformTypeId = (partners.owner?.platformType ?? owner.partner?.platformType) || ""
        networkTypeId = (partners.owner?.networkType ?? owner.partner?.networkType) || ""

        platformType = referential.partner?.platform.find((el) => el.value === platformTypeId)
        networkType = referential?.partner?.network.find((el) => el.value === networkTypeId)
        setPartnerLink(partners.owner?.id || owner.partner?.id || null)
        setValue('investor.first.partnerNumber', partners.owner?.partnerCode || owner.partner?.partnerCode || "")
        setValue('investor.first.partnerSocialReason', partners.owner?.socialReason || owner.partner?.socialReason || "")
        setValue('investor.first.platformNetworkNumber', platformType?.label || "")
        setValue('investor.first.platformNetworkLastName', networkType?.label || "")
        setValue('investor.first.partnerId', partners.owner?.id || owner.partner?.id || "")
      } else {
        platformTypeId = partners.owner?.platformType || ''
        networkTypeId = partners.owner?.networkType || ''

        platformType = referential?.partner?.platform.find((el) => el.value === platformTypeId)
        networkType = referential?.partner?.network.find((el) => el.value === networkTypeId)

        setPartnerLink(partners.owner?.id || null)
        setValue('investor.first.partnerNumber', partners.owner?.partnerCode || "")
        setValue('investor.first.partnerSocialReason', partners.owner?.socialReason || "")
        setValue('investor.first.platformNetworkNumber', platformType?.label || "")
        setValue('investor.first.platformNetworkLastName', networkType?.label || "")

        fetchConventionDependPartner(partners.owner?.id)
      }
    }

  }, [partners, referential, investors, preSubscription.investors, setValue, fetchConventionDependPartner])

  useEffect(() => {
    if (watchLifeAnnuity) setValue('particularity.duration', null)
  }, [setValue, watchLifeAnnuity])

  const setMovementCommissionStatus = (e: any) => {
    const commissionStatus = e.target.value
    if (preSubscription.id && commissionStatus) {
      new PreSubscriptionGateway().setMovementCommissionStatus(preSubscription.id, commissionStatus)
    }
  }
  const viewFile = (type: string, id: string | null) => {
    if (id) {
      const documentToView = documentArray[id]?.filter((document: DocumentInterface) => document.type === type)
      if (documentToView && documentToView.length > 0) {
        documentToView.map((document: DocumentInterface) => {
          if (id && document.id) {
            new DocumentGateway(preSubscription.id, null).getFile(document.id).then(response => {
              const file = window.open('')

              if (null !== file && response !== null) {
                file.document.write(
                  "<body style='margin:0;padding:0;overflow:hidden'><iframe width='100%' height='100%' style='border:0' src='" + response + "'></iframe></body>"
                )
              }
            })
          }
        })
      }
    }
  }
  const calcMinMaxPercent = (key: string) => {
    let floatKey = parseFloat(key)
    if (floatKey < 0) floatKey = 0
    if (100 < floatKey) floatKey = 100
    return floatKey
  }
  const calcMinMaxAmount = (amount: number) => {
    if (amount < 0) amount = 0
    const totalAmount = movementTotalAmount ?? 0
    if (totalAmount < amount) amount = totalAmount
    return amount
  }
  const setBlocMouvementKeys = (firstDismembermentKey: number | null, secondDismembermentKey: number | null) => {
    setValue('movement.investorFirst.dismembermentKey', firstDismembermentKey ? firstDismembermentKey.toString().replace('.', ',') : "0")
    setValue('movement.investorSecond.dismembermentKey', secondDismembermentKey ? secondDismembermentKey.toString().replace('.', ',') : "0")
  }

  const setBlocMouvementFirstKeys = (firstDismembermentKey: number | null) => {
    setValue('movement.investorFirst.dismembermentKey', firstDismembermentKey ? firstDismembermentKey.toString().replace('.', ',') : "0")
  }
  const setBlocMouvementSecondKeys = (secondDismembermentKey: number | null) => {
    setValue('movement.investorSecond.dismembermentKey', secondDismembermentKey ? secondDismembermentKey.toString().replace('.', ',') : "0")
  }
  const setBlocMouvementAmount = (firstAmount: number, secondAmount: number) => {
    setValue('movement.investorFirst.dismembermentAmount', firstAmount)
    setValue('movement.investorSecond.dismembermentAmount', secondAmount)
  }
  const setBlocMouvementAMountsByKeys = (montantTotal: number | null, firstDismKey: number | null, secondDismKey: number | null) => {
    if (montantTotal !== null) {
      if (firstDismKey !== null && !isNaN(firstDismKey)) {
        const calcFirstDismembermentAmount = (montantTotal ?? 1) * ((firstDismKey ?? 100) / 100)
        setValue('movement.investorFirst.dismembermentAmount', calcFirstDismembermentAmount)
      }
      if (secondDismKey !== null && !isNaN(secondDismKey)) {
        const calcSecondDismembermentAmount = (montantTotal ?? 1) * ((secondDismKey ?? 100) / 100)
        setValue('movement.investorSecond.dismembermentAmount', calcSecondDismembermentAmount)
      }
    }
  }

  useEffect(() => {
    const firstDismembermentKey = calcMinMaxPercent(firstKey ?? '')
    const secondDismembermentKey = 100 - firstDismembermentKey
    const secondKey = secondDismembermentKey ? secondDismembermentKey.toString().replace('.', ',') : "0"

    if (firstDismembermentKey === 100) {
      setValue('movement.investorFirst.dismembermentKey', '100')
    }

    if (['usufruct', 'np'].includes(watchPropertyType ?? '')) {
      return;
    }

    if (watchSecondDismembermentKey !== secondKey) {
      setWatchSecondDismembermentKey(secondKey)
      setValue('movement.investorSecond.dismembermentKey', secondKey)
    }

    setBlocMouvementAMountsByKeys(movementTotalAmount, firstDismembermentKey, secondDismembermentKey)
  }, [debouncedWatchFirstDismembermentKey])

  useEffect(() => {
    if (!['usufruct'].includes(watchPropertyType ?? '')) {
      return;
    }

    const secondDismembermentKey = calcMinMaxPercent(watchSecondDismembermentKey ?? '')
    const secondKey = secondDismembermentKey ? secondDismembermentKey.toString().replace('.', ',') : "0"

    if (secondDismembermentKey === 100) {
      setWatchSecondDismembermentKey(secondKey)
      setValue('movement.investorSecond.dismembermentKey', secondKey)
    }

    setBlocMouvementAMountsByKeys(movementTotalAmount, 0, secondDismembermentKey)
  }, [debouncedWatchSecondDismembermentKey])

  const calcAmountByPercentage = (firstKey: string | null, secondKey: string | null) => {
    if (['usufruct', 'np'].includes(watchPropertyType ?? '')) {
      return;
    }
    const firstKeyNum = parseFloat(firstKey ?? '0')
    const secondKeyNum = parseFloat(secondKey ?? '0');

    if (firstKeyNum === 0 && secondKeyNum === 0) return;

    let firstDismembermentKey = 0;
    let secondDismembermentKey = 0;

    if (secondKeyNum > 0) {
      secondDismembermentKey =  calcMinMaxPercent(secondKey ?? '0')
      firstDismembermentKey = 100 - secondDismembermentKey;
    }

    if (firstKeyNum > 0) {
      firstDismembermentKey = calcMinMaxPercent(firstKey ?? '0')
      secondDismembermentKey = 100 - firstDismembermentKey
    }

    const secondKeyNumber = secondDismembermentKey ? secondDismembermentKey.toString().replace('.', ',') : "0"
    const firstKeyNumber = firstDismembermentKey ? firstDismembermentKey.toString().replace('.', ',') : "0"

    if (firstKeyNum !== firstDismembermentKey) {
      setValue('movement.investorFirst.dismembermentKey', firstKeyNumber)
    }

    if (watchSecondDismembermentKey !== secondKeyNumber) {
      setWatchSecondDismembermentKey(secondKeyNumber)
      setValue('movement.investorSecond.dismembermentKey', secondKeyNumber)
    }
  }

  useEffect(() => {
    const firstKey = getValues('movement.investorFirst.dismembermentKey') ?? ""
    const secondKey = getValues('movement.investorSecond.dismembermentKey') ?? ""
    calcAmountByPercentage(firstKey, secondKey);

  }, [watchPropertyType])

  const checkRecurrenceDate = (): boolean => {
    const date = getValues('recurrence.period.end');
    return dayjs().isAfter(dayjs(date, 'DD/MM/YYYY'))
  }
  const getActions = (actionArray: any) => {
    setActions(actionArray)
  }

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: IFormInput) => {
    reset(data)

    if ((product && product?.productType?.slug === "sc") && (!watchNetAssetValue || !watchTotalNetAmount)) {
      if (!watchNetAssetValue) {
        return toastError(t('movement.pre-subscription.notify.not-vl-value-found'));
      }
      if (!watchCommissionEarned || !watchCommissionNotEarned) {
        return toastError(t('movement.pre-subscription.notify.convention-not-completed'));
      }
    } else {
      preSubscription.propertyType = data.generalInformation.propertyType
      preSubscription.possessionMode = data.generalInformation.possessionMode
      preSubscription.subscribedAt = data.generalInformation.subscribedAt
      preSubscription.minor = data.particularity.minor
      preSubscription.notFrench = data.particularity.notFrench
      preSubscription.ppe = data.particularity.ppe
      preSubscription.recurrent = data.particularity.recurrent
      preSubscription.guardianship = data.particularity.guardianship
      preSubscription.product = product
      preSubscription.horodatage = data.generalInformation.horodatage
      preSubscription.comment = data.comment
      preSubscription.note = data.note

      preSubscription.duration = data.particularity.duration
      preSubscription.dividendReinvestment = data.particularity.dividendReinvestment
      preSubscription.counterpartyResearch = showCounterPart ? data.particularity.counterpartyResearch : false
      preSubscription.dateStartMandat = preSubscription.counterpartyResearch ? data.particularity.dateStartMandat : null
      preSubscription.dateEndMandat = preSubscription.counterpartyResearch ? data.particularity.dateEndMandat : null
      preSubscription.lifeAnnuity = data.particularity.lifeAnnuity
      preSubscription.ter = data.particularity.ter
      preSubscription.investment50k = data.particularity.investment50k

      preSubscription.shareCount = data.movement.shareCount
      preSubscription.sharePrice = data.movement.sharePrice
      preSubscription.totalAmount = data.movement.totalAmount
      preSubscription.commissionStatus = data.movement.commissionStatus
      preSubscription.recurrence = new Recurrence(
        null,
        data.recurrence.status,
        data.recurrence.periodicity,
        data.recurrence.shareCount,
        data.recurrence.sharePrice,
        data.recurrence.totalAmount,
        data.recurrence.rumCode,
        data.recurrence.rumStatus,
        {
          start: data.recurrence.period.start,
          end: data.recurrence.period.end
        }
      )

      const owner = preSubscription.investors?.find((investor) => {
        return investor?.type === "transferor" || null
      })

      const usufructuary = preSubscription.investors?.find((investor) => {
        return investor?.type === "usufruct" || null
      })

      const preSubscriptionInvestors = []

      if (investors && investors.owner && investors.owner.prospect) {
        let partner = undefined
        if (partners && partners.owner) {
          partner = partners.owner
        } else if (investors.owner?.partner) {
          partner = investors.owner.partner
        } else if (!investors.owner.partner && owner) {
          partner = owner.partner
        }

        const firstDismembermentKey = data.movement.investorFirst.dismembermentKey ?
          String(data.movement.investorFirst.dismembermentKey).replace(',', '.') : null

        const investor = new Investor(
          investors.owner.id,
          'investor',
          investors.owner.prospect,
          watchPropertyType !== 'full_property' ? data.movement.investorFirst.dismembermentAmount : null,
          data.movement.investorFirst.paymentMode,
          data.movement.investorFirst.checkDepositAt,
          data.movement.investorFirst.paymentStatus,
          data.movement.investorFirst.paymentNature,
          data.movement.investorFirst.settlementDate,
          watchPropertyType !== 'full_property' ? firstDismembermentKey : null,
          investors.owner.prospect && investors.owner.prospect.prospectCode && data.bankInformations && data.bankInformations[investors.owner.prospect.prospectCode] ?
            bankInformationUtils.getBankInformationsForProspect(data.bankInformations[investors.owner.prospect.prospectCode])
            : [],
          watchInvestorFirstPaymentMode !== 'check' ? data.investor.first.partnerType : null,
          investors.owner.prospect && investors.owner.prospect.prospectCode && data.documents && data.documents[investors.owner.prospect.prospectCode] ?
            documentUtils.getDocumentsForProspect(data.documents[investors.owner.prospect.prospectCode], documentArray)
            : [],
          partner
        )

        preSubscriptionInvestors.push(investor)
      } else if (owner) {
        owner.partner = partners?.owner ?? undefined
        preSubscriptionInvestors.push(owner)
      }

      if (investors && investors.usufructuary && investors.usufructuary.prospect) {
        let partner = undefined
        if (partners && partners.usufructuary) {
          partner = partners.usufructuary
        } else if (investors.usufructuary?.partner) {
          partner = investors.usufructuary.partner
        } else if (!investors.usufructuary.partner && usufructuary) {
          partner = usufructuary.partner
        }

        const secondDismembermentKey = data.movement.investorSecond.dismembermentKey ?
          String(data.movement.investorSecond.dismembermentKey).replace(',', '.') : null
        const usufructuaryInvestor = new Investor(
          investors.usufructuary.id,
          'usufruct',
          investors.usufructuary.prospect,
          watchPropertyType !== 'full_property' ? data.movement.investorSecond.dismembermentAmount : null,
          data.movement.investorSecond.paymentMode,
          data.movement.investorSecond.checkDepositAt,
          data.movement.investorSecond.paymentStatus,
          data.movement.investorSecond.paymentNature,
          data.movement.investorSecond.settlementDate,
          watchPropertyType !== 'full_property' ? secondDismembermentKey : null,
          investors.usufructuary.prospect && investors.usufructuary.prospect.prospectCode && data.bankInformations && data.bankInformations[investors.usufructuary.prospect.prospectCode] ?
            bankInformationUtils.getBankInformationsForProspect(data.bankInformations[investors.usufructuary.prospect.prospectCode])
            : [],
          data.investor.second.partnerType,
          investors.usufructuary.prospect.prospectCode && data.documents && data.documents[investors.usufructuary.prospect.prospectCode] ?
            documentUtils.getDocumentsForProspect(data.documents[investors.usufructuary.prospect.prospectCode], documentArray)
            : [],
          partner
        )


        preSubscriptionInvestors.push(usufructuaryInvestor)
      } else if (usufructuary) {
        usufructuary.partner = partners?.usufructuary ?? undefined
        preSubscriptionInvestors.push(usufructuary)
      }

      preSubscription.investors = preSubscriptionInvestors

      if (product && product.productType?.slug === "sc") {
        preSubscription.netassetDate = watchNetAssetValueDate || ""
        preSubscription.totalNetAmount = NumericStringToFloat(String(watchTotalNetAmount)) || null
      }
      if (null !== preSubscription.id) {
        new PreSubscriptionGateway().update(preSubscription).then(id => {
          console.log('form update')
          if (null !== id) {
            setUuidRefresh(uuidV4())
            toastSuccess(t('movement.pre-subscription.notify.update-success'))
            dispatch(reloadMovement());
          } else {
            toastError(t('movement.pre-subscription.notify.add-error'));
          }
        })
      } else {
        setIsLoading(true)
        new PreSubscriptionGateway().create(preSubscription).then((id) => {
          console.log('form create')
          if (null !== id) {
            toastSuccess(t('movement.pre-subscription.notify.add-success'))
            navigate(`/${t('url.pre-subscription.edit')}/${id}`)
          } else {
            toastError(t('movement.pre-subscription.notify.add-error'));
          }
          setIsLoading(false)
        })
      }
    }
  }

  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));

  const firstKey = getValues('movement.investorFirst.dismembermentKey') ?? "0"
  const secondKey = getValues('movement.investorSecond.dismembermentKey') ?? "0"

  if (movementTotalAmount) {
    setBlocMouvementAMountsByKeys(movementTotalAmount, parseFloat(firstKey), parseFloat(secondKey))
  }
  if (watchPropertyType === 'np') {
    setBlocMouvementSecondKeys(0)
    setBlocMouvementAMountsByKeys(
      movementTotalAmount,
      parseFloat(firstKey),
      0
    )
  }
  if (watchPropertyType === 'usufruct') {
    setBlocMouvementFirstKeys(0)
    setBlocMouvementAMountsByKeys(
      movementTotalAmount,
      0,
      parseFloat(secondKey)
    )
  }

  const blockRecurrence = () => {
    if (product && product.productType?.slug === 'sc') return null
    return (
      <div
        className={`col-md-4 form-bloc form--bloc--recurrence ${!watchParticularityRecurrence ? 'disabledContent' : ''} u-mlb`}>
        <div className="form-bloc__title">{t('movement.pre-subscription.form.recurrence.title')}</div>
        <div className="form-bloc__form flex-container">
          <SelectCustom id="recurrence.status" name="recurrence.status" classes="col-md-12" register={register}
                        label={t('movement.pre-subscription.form.recurrence.status')}
                        options={referential?.wallet_subscription.recurrence_status || null}
                        readOnly={checkRecurrenceDate()}/>
          <Input id="recurrence.numRecurrence" name="recurrence.numRecurrence" classes="col-md-12" register={register}
                 label={t('movement.pre-subscription.form.recurrence.num-recurrence')} readOnly={true}/>
          <InputDate id="recurrence.period.start" name="recurrence.period.start" classes="col-md-12" register={register}
                     control={control} label={t('movement.pre-subscription.form.recurrence.start-of-recurrence')}/>
          <SelectCustom id="recurrence.periodicity" name="recurrence.periodicity" classes="col-md-12"
                        register={register} label={t('movement.pre-subscription.form.recurrence.periodicity-frequency')}
                        options={referential?.wallet_subscription.periodicity || null}/>
          <InputDate id="recurrence.period.end" name="recurrence.period.end" classes="col-md-12" register={register}
                     control={control} label={t('movement.pre-subscription.form.recurrence.end-of-recurrence')}/>
          <Input id="recurrence.shareCount" name="recurrence.shareCount" classes="col-md-12" register={register}
                 label={t('movement.pre-subscription.form.recurrence.number-of-shares')}/>
          <Input id="recurrence.sharePrice" name="recurrence.sharePrice" classes="col-md-12" register={register}
                 label={t('movement.pre-subscription.form.recurrence.starting-price')} readOnly={true}
                 formatNumber={{value: getValues('recurrence.sharePrice')}}/>
          <Input id="recurrence.totalAmount" name="recurrence.totalAmount" classes="col-md-12" register={register}
                 label={t('movement.pre-subscription.form.recurrence.total-amount')} readOnly={true}
                 formatNumber={{value: getValues('recurrence.totalAmount')}}/>
          <Input id="recurrence.rumCode" name="recurrence.rumCode" classes="col-md-12" register={register}
                 label={t('movement.pre-subscription.form.recurrence.rum-code')}/>
          <SelectCustom id="recurrence.rumStatus" name="recurrence.rumStatus" classes="col-md-12" register={register}
                        label={t('movement.pre-subscription.form.recurrence.rum-status')}
                        options={referential?.wallet_subscription.rum_status || null}/>
        </div>
      </div>
    )
  }

  const blockListDocumentsInvestors = preSubscription?.investors?.map(investor => {
    if (investor && investor.prospect && investor.prospect.persons) {
      return (
        <div key={investor.id} className="col-md-6">
          {investor.prospect.persons.map((person) => {
            if (preSubscription.id && investor && investor.prospect && investor.prospect && person && person.id) {
              return (
                <DocumentForm
                  key={person.id}
                  title={`${t('movement.pre-subscription.form.list-of-documents.title')} : ${investor?.prospect?.prospectCode} - ${person.lastname} ${person.firstname}`}
                  name={`documents.${investor.prospect.prospectCode}.${person.id}`}
                  uuid={person.id}
                  documentGateway={new DocumentGateway(preSubscription.id, investor.prospect.id)}
                  register={register}
                  control={control}
                  documentData={documentArray[person.id]}
                  isLectureMode={isLectureMode}
                />
              )
            }
          })}
        </div>
      )
    }
  })

  const blockGeneralInformation = <div className={`form-bloc form--bloc--general-information`}>
    <div className="form-bloc__title">{t('movement.pre-subscription.form.general-information.title')}</div>
    <div className="form-bloc__form flex-container">
      <ProductDalalist classes="col-md-6"
                       id="generalInformation.product"
                       name="generalInformation.product"
                       label={t('movement.pre-subscription.form.general-information.product-acronym')}
                       labelClasses={`mandatory-field`}
                       control={control}
                       defaultValue={getValues('generalInformation.product')}
                       disabled={isLectureMode}
                       openToSubscription={true}
                       selectClasses={`${watchProduct.id ? "" : "field-in-error"}`}
      />
      <Input register={register}
             type={'text'}
             classes={'col-md-6'} name={'generalInformation.movementNumber'} id={'generalInformation.movementNumber'}
             label={t('movement.pre-subscription.form.general-information.movement-number')}
             readOnly={true}
      />
      <SelectCustom classes="col-md-6 mandatory-field" id="generalInformation.propertyType" name="generalInformation.propertyType"
                    options={List.filterNaturePropertyList(preSubscription.status, product?.productType ?? null, referential?.wallet_subscription.property_type ?? [])}
                    label={t('movement.pre-subscription.form.general-information.nature-property')}
                    register={register}/>
      <Input register={register}
             classes={'col-md-6'}
             label={t('movement.pre-subscription.form.general-information.administrator')}
             type={'text'}
             name={'generalInformation.administrator'}
             id={'generalInformation.administrator'}
             readOnly={true}
      />
      <SelectCustom register={register}
                    classes="col-md-6 mandatory-field"
                    id="generalInformation.possessionMode"
                    name="generalInformation.possessionMode"
                    label={t('movement.pre-subscription.form.general-information.detention-mode')}
                    options={List.filterDetentionModeList(product?.productType ?? null, referential?.wallet_subscription.possession_mode ?? [])}
      />
      <InputDate id="generalInformation.horodatage"
                 label={t('movement.pre-subscription.form.general-information.timestamp-date')}
                 name="generalInformation.horodatage"
                 classes={`col-md-6 mandatory-field`}
                 containerClasses={`${watchHorodatage ? "" : "field-in-error"}`}
                 register={register}
                 control={control}
                 readOnly={isLectureMode}/>

      {preSubscription.id !== null ? (
        <div className="u-txt-size-s u-txt-lightbold u-txt-italic col-md-12">
          {t('movement.pre-subscription.form.general-information.lastUpdateUser')} {getValues('generalInformation.lastUpdateUser')}
          {t('movement.pre-subscription.form.general-information.updatedAt')} {getValues('generalInformation.updatedAt')}
        </div>
      ) : ''}

    </div>
  </div>

  const blockInvestor = <div className={`form-bloc form--bloc--investor`}>
    <ReactTooltip/>
    <div className="form-bloc__title">{t('movement.pre-subscription.form.investor.title')}</div>
    <div className="flex-container">
      <div className={`col-md-6 form-bloc__form ${watchPropertyType === 'usufruct' ? 'disabledContent' : ''}`}>
        <div className="flex-container">
          <div className="col-md-6">
            <button type="button" className="button button-reset u-txt-with-icon"
                    onClick={() => !isLectureMode && dispatch(setOpenChoiceInvestorEvent({
                      show: true,
                      key: 'owner',
                      dataListType: "all"
                    }))}>
              <img src={iconAdd} alt=""/>
              {t('movement.choice-investor-owner')}
            </button>
          </div>
          <div className="col-md-6">
            <button type="button" className="button button-reset u-txt-with-icon"
                    onClick={() => !isLectureMode && dispatch(setOpenChoicePartnerEvent({show: true, key: 'owner'}))}>
              <img src={iconAdd} alt=""/>
              {t('account.search-a-partner')}
            </button>
          </div>
        </div>
        <table className="table--investor">
          <thead>
          <tr>
            <th/>
            <th className="form-control__label">{t('movement.pre-subscription.form.investor.th-number')}</th>
            <th className="form-control__label">{t('movement.pre-subscription.form.investor.th-identity')}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <label htmlFor="partnerNum" className="form-control__label u-mrs">
                {t('movement.pre-subscription.form.investor.investor-owner')}
              </label>
              {ownerLink &&
                  <Link to={`/${t('url.customer.read-general-information')}/${ownerLink}`}
                        target="_blank"
                        rel="noopener noreferrer"
                  >
                      <button type="button" className="button-reset">
                          <img src={iconSearch} alt="Loupe"/>
                      </button>
                  </Link>
              }
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.id'}
                     id={'investor.first.id'}
                     readOnly={true}
              />
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.identity'}
                     id={'investor.first.identity'}
                     readOnly={true}
                     data-tip={investors?.owner?.prospect ? prospectUtils.formatProspectPersonIdentity(investors.owner.prospect) : ''}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="partnerNum" className="form-control__label u-mrs">
                {t('movement.pre-subscription.form.investor.investor-partner')}
              </label>
              {partnerLink &&
                  <Link to={`/${t('url.partner.read-general-information')}/${partnerLink}`}
                        target="_blank"
                        rel="noopener noreferrer"
                  >
                      <button type="button" className="button-reset">
                          <img src={iconSearch} alt="Loupe"/>
                      </button>
                  </Link>
              }
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.partnerNumber'}
                     id={'investor.first.partnerNumber'}
                     disabled
              />
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.partnerSocialReason'}
                     id={'investor.first.partnerSocialReason'}
                     disabled
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="partnerNum" className="form-control__label">
                {t('movement.pre-subscription.form.investor.platform-network')}
              </label>
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.platformNetworkNumber'}
                     id={'investor.first.platformNetworkNumber'}
                     disabled
              />
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.platformNetworkLastName'}
                     id={'investor.first.platformNetworkLastName'}
                     disabled
              />
            </td>
            <td/>
          </tr>
          </tbody>
        </table>
      </div>
      {product && product.productType?.slug === 'sc' ? null :
        <div className={`col-md-6 form-bloc__form ${showUsufruitier ? '' : 'disabledContent'}`}>
          <div className="flex-container">
            <div className="col-md-6">
              <button type="button" className="button button-reset u-txt-with-icon"
                      onClick={() => !isLectureMode && dispatch(setOpenChoiceInvestorEvent({
                        show: true,
                        key: 'usufructuary',
                        dataListType: "all"
                      }))}>
                <img src={iconAdd} alt=""/>
                {t('movement.choice-investor-usufructuary')}
              </button>
            </div>
            <div className="col-md-6">
              <button type="button" className="button button-reset u-txt-with-icon"
                      onClick={() => !isLectureMode && dispatch(setOpenChoicePartnerEvent({
                        show: true,
                        key: 'usufructuary'
                      }))}>
                <img src={iconAdd} alt=""/>
                {t('account.search-a-partner')}
              </button>
            </div>
          </div>
          <table className="table--investor">
            <thead>
            <tr>
              <th/>
              <th className="form-control__label">{t('movement.pre-subscription.form.investor.th-number')}</th>
              <th className="form-control__label">{t('movement.pre-subscription.form.investor.th-identity')}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <label htmlFor="partnerNum" className="form-control__label u-mrs">
                  {t('movement.pre-subscription.form.investor.investor-usufructuary')}
                </label>
                {usuLink &&
                    <Link to={`/${t('url.customer.read-general-information')}/${usuLink}`}
                          target="_blank"
                          rel="noopener noreferrer"
                    >
                        <button type="button" className="button-reset">
                            <img src={iconSearch} alt="Loupe"/>
                        </button>
                    </Link>
                }
              </td>
              <td>
                <Input register={register}
                       type={'text'}
                       name={'investor.second.id'}
                       id={'investor.second.id'}
                       readOnly={true}
                />
              </td>
              <td>
                <Input register={register}
                       type={'text'}
                       name={'investor.second.identity'}
                       id={'investor.second.identity'}
                       readOnly={true}
                       data-tip={investors?.usufructuary?.prospect ? prospectUtils.formatProspectPersonIdentity(investors.usufructuary.prospect) : ''}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="partnerNum" className="form-control__label u-mrs">
                  {t('movement.pre-subscription.form.investor.usufructuary-partner')}
                </label>
                {secondPartnerLink &&
                    <Link to={`/${t('url.partner.read-general-information')}/${secondPartnerLink}`}
                          target="_blank"
                          rel="noopener noreferrer"
                    >
                        <button type="button" className="button-reset">
                            <img src={iconSearch} alt="Loupe"/>
                        </button>
                    </Link>
                }
              </td>
              <td>
                <Input register={register}
                       type={'text'}
                       name={'investor.second.partnerNumber'}
                       id={'investor.second.partnerNumber'}
                       disabled
                />
              </td>
              <td>
                <Input register={register}
                       type={'text'}
                       name={'investor.second.partnerSocialReason'}
                       id={'investor.second.partnerSocialReason'}
                       disabled
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="partnerNum" className="form-control__label">
                  {t('movement.pre-subscription.form.investor.platform-network')}
                </label>
              </td>
              <td>
                <Input register={register}
                       type={'text'}
                       name={'investor.second.platformNetworkNumber'}
                       id={'investor.second.platformNetworkNumber'}
                       disabled
                />
              </td>
              <td>
                <Input register={register}
                       type={'text'}
                       name={'investor.second.platformNetworkLastName'}
                       id={'investor.second.platformNetworkLastName'}
                       disabled
                />
              </td>
              <td/>
            </tr>
            </tbody>
          </table>
        </div>}
    </div>
  </div>

  const blockParticularitiesSubscriber = <div className={`form-bloc`}>
    <div className="form-bloc__title">{t('movement.pre-subscription.form.particularity.caracteristic-subscriber')}</div>
    <div className="form-bloc__form flex-container">
      <CheckboxToggle classes="col-md-6" id="particularity.minor" name="particularity.minor" register={register}
                      label={t('movement.pre-subscription.form.particularity.minor')}/>
      <CheckboxToggle classes="col-md-6" id="particularity.notFrench" name="particularity.notFrench" register={register}
                      label={t('movement.pre-subscription.form.particularity.noFrenchResident')}/>
      <CheckboxToggle classes="col-md-6" id="particularity.guardianship" name="particularity.guardianship"
                      register={register} label={t('movement.pre-subscription.form.particularity.underSupervision')}/>
      <CheckboxToggle classes="col-md-6" id="particularity.ppe" name="particularity.ppe" register={register}
                      label={t('movement.pre-subscription.form.particularity.ppe')}/>
    </div>
  </div>

  const blockParticularitiesOperation = <div className={`form-bloc`}>
    <div className="form-bloc__title">{t('movement.pre-subscription.form.particularity.caracteristic-operation')}</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-6">
        <CheckboxToggle classes="col-md-6" id="particularity.recurrent" name="particularity.recurrent"
                        register={register} label={t('movement.pre-subscription.form.particularity.recurrence')}/>
        <CheckboxToggle classes="col-md-6" id="particularity.dividendReinvestment"
                        name="particularity.dividendReinvestment" register={register}
                        label={t('movement.pre-subscription.form.particularity.reinvestmentDividends')}/>
        <CheckboxToggle classes="col-md-6" id="particularity.ter" name="particularity.ter" register={register}
                        label={t('movement.pre-subscription.form.particularity.deviceBter')}/>
        <CheckboxToggle classes="col-md-6" id="particularity.investment50k" name="particularity.investment50k"
                        register={register} label={t('movement.pre-subscription.form.particularity.investment50k')}/>
      </div>
      <div className="col-md-6">
        <ReactTooltip/>
        <CheckboxToggle classes="col-md-6" id="particularity.counterpartyResearch"
                        name="particularity.counterpartyResearch" register={register}
                        label={t('movement.pre-subscription.form.particularity.counterpartyResearch')}
                        readOnly={!showCounterPart}/>
        {watchCounterpartyResearch &&
            <InputDate id="particularity.dateStartMandat"
                       name="particularity.dateStartMandat"
                       classes="col-md-12"
                       register={register}
                       control={control}
                       label={t('movement.pre-subscription.form.particularity.date-start-mandat')}
                       readOnly={isLectureMode}
            />
        }
        {watchCounterpartyResearch &&
            <InputDate id="particularity.dateEndMandat"
                       name="particularity.dateEndMandat"
                       classes="col-md-12"
                       register={register}
                       control={control}
                       label={t('movement.pre-subscription.form.particularity.date-end-mandat')}
                       readOnly={isLectureMode}
            />
        }
        <Input classes="col-md-12"
               id="particularity.duration"
               name="particularity.duration"
               inputClasses={`${(!watchLifeAnnuity && !watchDuration) ? "field-in-error" : ""}`}
               labelClasses={`${watchLifeAnnuity ? "" : "mandatory-field"}`}
               register={register}
               label={t('movement.pre-subscription.form.particularity.duration')}
               type="number"
               disabled={watchLifeAnnuity}
               tooltip={t('movement.tooltips.duration')}
        />
        <CheckboxToggle classes="col-md-6" id="particularity.lifeAnnuity" name="particularity.lifeAnnuity"
                        register={register} label={t('movement.pre-subscription.form.particularity.lifeAnnuity')}/>
      </div>


    </div>
  </div>

  const blockMovement = <div className={`form-bloc w-full`}>
    <div className="form-bloc__title"
         id={t('movement.pre-subscription.form.movement.title')}>{t('movement.pre-subscription.form.movement.title')}</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-4">
        <Input id="movement.shareCount"
               name="movement.shareCount"
               classes={`col-md-12 mandatory-field`}
               inputClasses={`${watchMovementShareCount ? '' : 'field-in-error'}`}
               register={register}
               label={t('movement.pre-subscription.form.movement.numberOfShares')}
               type="number"
               min={0}
        />
        <Input id="movement.sharePrice" name="movement.sharePrice" classes="col-md-12" register={register}
               label={t('movement.pre-subscription.form.movement.startingPrice')} type="number" readOnly={true}
               formatNumber={{value: getValues('movement.sharePrice'), scale: decimalStep}}/>
        <Input id="movement.totalAmount" name="movement.totalAmount" classes="col-md-12" register={register}
               label={t('movement.pre-subscription.form.movement.totalAmount')} type="number" readOnly={true}
               formatNumber={{value: getValues('movement.totalAmount'), scale: decimalStep}}/>
      </div>
      <div className="col-md-8">
        <table className="table--movement">
          <thead>
          <tr>
            <th/>
            <th className="form-control__label">{t('movement.pre-subscription.form.movement.th-client-owner')}</th>
            <th
              className="form-control__label">{t('movement.pre-subscription.form.movement.th-client-usufructuary')}</th>
          </tr>
          </thead>
          <tbody>
          {watchPropertyType !== 'full_property' &&
              <tr>
                  <td className="form-control__label">{t('movement.pre-subscription.form.movement.dismembermentKey')}</td>
                  <td className={`${watchPropertyType === 'usufruct' ? 'disabledContent' : ''}`}>
                      <InputNumber
                          id={'movement.investorFirst.dismembermentKey'}
                          classes={'col-md-12'}
                          name={'movement.investorFirst.dismembermentKey'}
                          onChangeCb={value => setWatchFirstDismembermentKey(value)}
                          disabled={watchPropertyType === 'usufruct'}
                          readOnly={watchPropertyType === 'usufruct'}
                          control={control}
                          decimalScale={decimalStep}
                          register={register} />
                  </td>
                  <td className={`${showUsufruitier ? '' : 'disabledContent'}`}>
                      <InputNumber
                          id={'movement.investorSecond.dismembermentKey'}
                          classes={'col-md-12'}
                          name={'movement.investorSecond.dismembermentKey'}
                          disabled={watchPropertyType !== 'usufruct'}
                          readOnly={watchPropertyType !== 'usufruct'}
                          control={control}
                          onChangeCb={value => watchPropertyType === 'usufruct' && setWatchSecondDismembermentKey(value)}
                          decimalScale={decimalStep}
                          register={register} />
                  </td>
              </tr>
          }
          {watchPropertyType !== 'full_property' &&
              <tr>
                  <td className="form-control__label">{t('movement.pre-subscription.form.movement.dismembermentAmount')}</td>
                  <td className={`${watchPropertyType === 'usufruct' ? 'disabledContent' : ''}`}>
                      <InputNumber
                          id={'movement.investorFirst.dismembermentAmount'}
                          classes={'col-md-12'}
                          name={'movement.investorFirst.dismembermentAmount'}
                          disabled={watchPropertyType === 'usufruct'}
                          readOnly={true}
                          // onChangeCb={handleFirstAmount}
                          control={control}
                          decimalScale={decimalStep}
                          register={register} />
                  </td>
                  <td className={`${showUsufruitier ? '' : 'disabledContent'}`}>
                      <InputNumber
                          id={'movement.investorSecond.dismembermentAmount'}
                          classes={'col-md-12'}
                          name={'movement.investorSecond.dismembermentAmount'}
                          disabled={!showUsufruitier}
                          readOnly={true}
                          // onChangeCb={handleSecondAmount}
                          control={control}
                          decimalScale={decimalStep}
                          register={register} />
                  </td>
              </tr>
          }
          <tr>
            <td className="form-control__label">{t('movement.pre-subscription.form.movement.paymentNature')}</td>
            <td className={`${watchPropertyType === 'usufruct' ? 'disabledContent' : ''}`}><SelectCustom
              id="movement.investorFirst.paymentNature" name="movement.investorFirst.paymentNature" classes="col-md-12"
              register={register} options={referential?.wallet_subscription.payment_nature || null}/></td>
            <td className={`${showUsufruitier ? '' : 'disabledContent'}`}><SelectCustom
              id="movement.investorSecond.paymentNature" name="movement.investorSecond.paymentNature"
              classes="col-md-12" register={register}
              options={referential?.wallet_subscription.payment_nature || null}/></td>
          </tr>
          <tr>
            <td className="form-control__label have-ged mandatory-field">
              {t('movement.pre-subscription.form.movement.paymentMode')}
            </td>
            <td className={`${watchPropertyType === 'usufruct' ? 'disabledContent' : ''}`}>
              <div className="horizontal-container-space-between">
                <SelectCustom id="movement.investorFirst.paymentMode" name="movement.investorFirst.paymentMode"
                              classes="col-md-8" register={register}
                              options={referential?.wallet_subscription.payment_method || null}/>
                <span className="col-md-4">
                  <button type="button" className="button-reset"
                          onClick={() => viewFile('Paiement', investors?.owner?.prospect?.persons ? investors.owner.prospect.persons[0].id : null)}
                  >
                    <img src={eyeIcon} alt=""/>
                  </button>
                  {!isLectureMode && <button type="button" className="button-reset"
                                             onClick={() => dispatch(setOpenUploadForm({
                                               show: true,
                                               documents: null,
                                               uuid: investors?.owner?.prospect?.persons ? investors.owner.prospect.persons[0].id : null,
                                               type: 'Paiement'
                                             }))}
                  >
                      <img src={uploadIcon} alt=""/>
                  </button>}
                </span>
              </div>
            </td>
            <td className={`${showUsufruitier ? '' : 'disabledContent'}`}>
              <div className="horizontal-container-space-between">
                <SelectCustom id="movement.investorSecond.paymentMode" name="movement.investorSecond.paymentMode"
                              classes="col-md-8" register={register}
                              options={referential?.wallet_subscription.payment_method || null}/>
                <span className="col-md-4">
                  <button type="button" className="button-reset"
                          onClick={() => viewFile('Paiement', investors?.usufructuary?.prospect?.persons ? investors.usufructuary.prospect.persons[0].id : null)}
                  >
                    <img src={eyeIcon} alt=""/>
                  </button>
                  {!isLectureMode && <button type="button" className="button-reset"
                                             onClick={() => dispatch(setOpenUploadForm({
                                               show: true,
                                               documents: null,
                                               uuid: investors?.usufructuary?.prospect?.persons ? investors.usufructuary.prospect.persons[0].id : null,
                                               type: 'Paiement'
                                             }))}
                  >
                      <img src={uploadIcon} alt=""/>
                  </button>}
                </span>
              </div>
            </td>
          </tr>
          {(watchInvestorFirstPaymentMode === 'check' || watchInvestorSecondPaymentMode === 'check') &&
              <tr>
                  <td className="form-control__label">{t('movement.pre-subscription.form.movement.checkDepositAt')}</td>
                  <td className={`${watchPropertyType === 'usufruct' ? 'disabledContent' : ''}`}>{watchInvestorFirstPaymentMode === 'check' &&
                      <InputDate id="movement.investorFirst.checkDepositAt"
                                 name="movement.investorFirst.checkDepositAt"
                                 classes="col-md-12"
                                 register={register}
                                 control={control}
                                 readOnly={isLectureMode}
                      />
                  }</td>
                  <td>{watchInvestorSecondPaymentMode === 'check' &&
                      <InputDate id="movement.investorSecond.checkDepositAt"
                                 name="movement.investorSecond.checkDepositAt"
                                 classes="col-md-12"
                                 register={register}
                                 control={control}
                                 readOnly={isLectureMode}
                      />}
                  </td>
              </tr>
          }
          <tr>
            <td className="form-control__label">{t('movement.pre-subscription.form.movement.settlementStatus')}</td>
            <td className={`${watchPropertyType === 'usufruct' ? 'disabledContent' : ''}`}><SelectCustom
              id="movement.investorFirst.paymentStatus" name="movement.investorFirst.paymentStatus" classes="col-md-12"
              register={register} options={referential?.wallet_subscription.payment_status || null}/></td>
            <td className={`${showUsufruitier ? '' : 'disabledContent'}`}><SelectCustom
              id="movement.investorSecond.paymentStatus" name="movement.investorSecond.paymentStatus"
              classes="col-md-12" register={register}
              options={referential?.wallet_subscription.payment_status || null}/></td>
          </tr>
          <tr>
            <td className="form-control__label">{t('movement.pre-subscription.form.movement.settlementDate')}</td>
            <td className={`${watchPropertyType === 'usufruct' ? 'disabledContent' : ''}`}><InputDate
              id="movement.investorFirst.settlementDate"
              name="movement.investorFirst.settlementDate"
              classes="col-md-12"
              register={register}
              control={control}
              readOnly={isLectureMode}/>
            </td>
            <td className={`${showUsufruitier ? '' : 'disabledContent'}`}><InputDate
              id="movement.investorSecond.settlementDate"
              name="movement.investorSecond.settlementDate"
              classes="col-md-12"
              register={register}
              control={control}
              readOnly={isLectureMode}/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  const blockMovementTypeSC = <div className={`form-bloc w-full`}>
    <div className="form-bloc__title">{t('movement.pre-subscription.form.movement.title')}</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-8">
        <InputNumber
          id={'movement.totalNetAmount'}
          classes={'col-md-6'}
          name={'movement.totalNetAmount'}
          control={control}
          decimalScale={decimalStep}
          valueIsNumericString={false}
          label={t('movement.pre-subscription.form.movement.totalNetAmount')}
          register={register} />

        <InputNumber
          id={'movement.commissionEarned'}
          classes={'col-md-6'}
          name={'movement.commissionEarned'}
          control={control}
          decimalScale={decimalStep}
          readOnly={true}
          label={t('movement.pre-subscription.form.movement.commissionEarned')}
          register={register} />

        <InputNumber
          id={'movement.commissionNotEarned'}
          classes={'col-md-6'}
          name={'movement.commissionNotEarned'}
          control={control}
          decimalScale={decimalStep}
          readOnly={true}
          label={t('movement.pre-subscription.form.movement.commissionNotEarned')}
          register={register} />


        <InputNumber
          id={'movement.totalGrossAmount'}
          classes={'col-md-6'}
          name={'movement.totalGrossAmount'}
          control={control}
          decimalScale={decimalStep}
          readOnly={true}
          label={t('movement.pre-subscription.form.movement.totalGrossAmount')}
          register={register} />

        <div className="col-md-12 flex-container">
          <div className="col-md-6 u-pr0">
            <Input id="movement.netAssetValue"
                   name="movement.netAssetValue"
                   classes=""
                   register={register}
                   label={t('movement.pre-subscription.form.movement.netAssetValue')}
                   type="text"
                   readOnly={true}
            />
          </div>

          <div className="form-control col-md-6">
            <label className="form-control__label">
              {t('movement.pre-subscription.form.movement.netAssetValueDate')}
            </label>
            <InputDate id="movement.netAssetValueDate"
                       name="movement.netAssetValueDate"
                       classes=""
                       register={register}
                       control={control}
            />
          </div>
        </div>

        <InputNumber
          id={'movement.shareCount'}
          classes={'col-md-6'}
          name={'movement.shareCount'}
          control={control}
          decimalScale={decimalStep}
          readOnly={true}
          label={t('movement.pre-subscription.form.movement.numberOfShares')}
          register={register} />
      </div>
      <div className="col-md-4">
        <SelectCustom id="movement.investorFirst.paymentMode"
                      name="movement.investorFirst.paymentMode"
                      classes="col-md-12"
                      register={register}
                      options={referential?.wallet_subscription.payment_method || null}
                      label={
                        <div className="have-ged mandatory-field">
                          <label className="u-mrs">{t('movement.pre-subscription.form.movement.paymentMode')}</label>
                          <button type="button"
                                  name="read.document"
                                  className="button-reset">
                            <img src={eyeIcon} alt=""/>
                          </button>
                          {!isLectureMode &&
                              <button type="button" className="button-reset"><img src={uploadIcon} alt=""/></button>}
                        </div>}
        />
        {(watchInvestorFirstPaymentMode === 'check') &&
            <div className="form-control col-md-12">
                <label
                    className="form-control__label">{t('movement.pre-subscription.form.movement.checkDepositAt')}</label>
              {watchInvestorFirstPaymentMode === 'check' && <InputDate id="movement.investorFirst.checkDepositAt"
                                                                       name="movement.investorFirst.checkDepositAt"
                                                                       classes=""
                                                                       register={register}
                                                                       control={control}/>}
            </div>
        }
        <SelectCustom id="movement.investorFirst.paymentStatus"
                      name="movement.investorFirst.paymentStatus"
                      classes="col-md-12"
                      register={register}
                      options={referential?.wallet_subscription.payment_status || null}
                      label={t('movement.pre-subscription.form.movement.settlementStatus')}
        />
        <div className="form-control col-md-12">
          <label className="form-control__label">{t('movement.pre-subscription.form.movement.settlementDate')}</label>
          <InputDate id="movement.investorFirst.settlementDate"
                     name="movement.investorFirst.settlementDate"
                     classes=""
                     register={register}
                     control={control}
          />
        </div>
      </div>
    </div>
  </div>

  return (
    <>
      {referential &&
          <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
                  <Status label={"movement.state"} status={preSubscription.status}
                          statusList={referential.wallet.status}/>
                  <ActionReason reason={
                    referential?.wallet_subscription?.cancel_reason?.find(reason => {
                      return reason.value == preSubscription.actionReason
                    })?.label ?? preSubscription.actionReason
                  }/>
                {!preSubscription.id && <AlertInfo text={t('movement.warning-action-possible')} classes="u-mys"/>}
                {preSubscription.id && <MovementTag transactionId={preSubscription.id} tags={preSubscription.tags || []}
                                                    optionsReferential={referential.transaction.tag}
                />}
                  <SelectCustom
                      id="movement.commissionStatus"
                      name="movement.commissionStatus"
                      label={t('movement.pre-subscription.form.movement.commissionStatus').toUpperCase()}
                      containerClasses="form-control--wide-columns u-mbs"
                      labelClasses="form-control__label"
                      classes="col-md-12"
                      options={referential?.wallet_subscription.commission_status || null}
                      register={register}
                      onChange={(e) => setMovementCommissionStatus(e)}/>
                  <div className="flex-container">
                      <div className="col-md-6">{blockGeneralInformation}</div>
                      <div className="col-md-6">{preSubscription.id &&
                          <ActionsForm gateway={new PreSubscriptionGateway()}
                                       transactionUuid={preSubscription.id}
                                       uuidRefresh={uuidRefresh}
                                       callback={getActions}
                                       setFormError={setFormError}
                          />
                      }</div>
                      <FormErrorInfoDropdown errorArray={formError}/>
                    {(actions?.invalidate.date && !actions?.confirm.date) &&
                        <div className="col-md-12">
                            <CommentBlock
                                data={preSubscription.comment}
                                onChange={(data: string) => setValue('comment', data)}
                                config={CKEditorUtil.getConfig()}
                                readonly={isLectureMode}
                            />
                        </div>
                    }
                      <div className="col-md-12">{blockInvestor}</div>
                      <div className="col-md-6">{blockParticularitiesSubscriber}</div>
                      <div className="col-md-6">{blockParticularitiesOperation}</div>
                      <div className="col-md-12 flex">
                        {product && product.productType?.slug === "sc" ? blockMovementTypeSC : blockMovement}
                        {blockRecurrence()}
                      </div>
                    {(watchInvestorFirstPaymentMode !== 'check') &&
                        <>
                            <div className="col-md-12">
                                <div className="flex-container">
                                  {
                                    preSubscription?.investors?.map((investor, index) => {
                                      if (investor && referential) {
                                        return referential.prospect.bank_type.map((bankType: ReferentielItemInterface) => {
                                          if (investor.prospect) {
                                            return <BankInformationBlock
                                              type={bankType.value.toString()}
                                              key={`bankInformations.${investor.prospect.prospectCode}.${bankType.value}`}
                                              name={`bankInformations.${investor.prospect.prospectCode}.${bankType.value}`}
                                              title={
                                                `${t('movement.pre-subscription.form.banking-information.title')} :
                              ${bankType.label}
                              ${investor.prospect.prospectCode}`
                                              }
                                              isLectureMode={isLectureMode}
                                              prospect={investor.prospect}
                                              product={product}
                                            />
                                          }
                                        })
                                      }
                                    })
                                  }
                                </div>
                            </div>
                        </>
                    }
                      <div className="col-md-12">
                          <div className="flex-container">
                            {blockListDocumentsInvestors}
                          </div>
                      </div>
                    {preSubscription.status === "subscribed" && preSubscription.id &&
                        <div className="col-md-12">
                            <RecapForm transactionUuid={preSubscription.id}
                                       typeMvt='preSubscription'
                            />
                        </div>
                    }
                      <div className="col-md-12">
                          <NoteBlock
                              data={preSubscription.note}
                              onChange={(data: string) => {
                                setValue('note', data)
                              }}
                              config={CKEditorUtil.getConfig()}
                              readonly={isLectureMode || ['canceled', 'subscribed'].includes(preSubscription.status ?? 'draft')}
                          />
                      </div>
                  </div>
                {!isLectureMode && <FooterBlock disabled={!watchProduct.id || isLoading}/>}
              </form>
          </FormProvider>
      }
    </>
  )
}

export default Form
