import ProductGatewayInterface from '../../../domain/Product/ProductGatewayInterface'
import {ProductInterface} from '../../../domain/Product/Product'

export default class UpdateUseCase
{
  public productGateway

  constructor(ProductGateway: ProductGatewayInterface) {
    this.productGateway = ProductGateway
  }

  async execute(Product: ProductInterface): Promise<ProductInterface|null> {
    return await this.productGateway.update(Product).then(response => response)
  }
}
