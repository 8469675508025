import {Presenter} from '../Presenter'

class NumberOfPartnerByStatePresenter extends Presenter {

  private useCase: { defaultUseCase: any }

  constructor(defaultUseCase: any) {
    super({
      viewModel: {
        type: 'doughnut',
        title: '',
        heightCanvas: 151,
        data: {
          labels: [],
          datasets: [
            {
              labels: [],
              data: [],
              backgroundColor: [],
            },
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      }
    });

    this.useCase = {
      defaultUseCase,
    }
  }

  load() {
    try {
      this._setData(this.useCase.defaultUseCase);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setData(response: {
    title: string,
    type: string,
    labels: [],
    colors: [],
    data: []
  }) {
    this.update({
      type: 'doughnut',
      title: response.title,
      data: {
        labels: response.labels,
        datasets: [
          {
            labels: response.labels,
            data: response.data,
            backgroundColor: response.colors,
          },
        ]
      }
    });
  }
}

export default NumberOfPartnerByStatePresenter
