import React, {FunctionComponent, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import {useAppSelector} from '../../../../store/hook'
import HeaderPage from '../../../component/HeaderPage/HeaderPage'

import '../../../../../assets/styles/page/_product.scss'
import TabCommissioningAdd from "../../../component/Tab/CommissioningAdd/TabCommissioningAdd";
import SettingForm from "../../../component/Form/Commissioning/Setting"
import MatchUrl from "../../../../../domain/Utils/MatchedUrl";
import CommissioningGateway from "../../../../../gateway/Commissioning/CommissioningGateway";
import Spinner from "../../../component/Spinner/Spinner";
import ErrorPage from "../../Error/ErrorPage";
import {TError} from "../../../../../domain/Error/Error";
import {CommissioningSessionInterface} from "../../../../../domain/Commissioning/CommissioningList";
import {ReferentielInterface} from "../../../../../domain/Referentiel/ReferentielInterface";
import Session from "../../../component/Form/Commissioning/Session";
import CommissioningSessionGateway from "../../../../../gateway/Commissioning/CommissioningSessionGateway";

interface Props {
  page: string
  mode: "add" | "edit" | "read"
}

const CommissioningLayout: FunctionComponent<Props> = ({page, mode}) => {
  const {t} = useTranslation()
  const {commissioningType, uuid} = useParams()
  const navigate = useNavigate()

  const isLectureMode = MatchUrl.find(location.pathname, 'lecture')
  const isSettingTab = MatchUrl.find(location.pathname, 'parametrage')

  const commissioningRules = useAppSelector((state) => state.me.me?.rules.commissioning.actions)
  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const [commissioning, setCommissioning] = useState<CommissioningSessionInterface|null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<TError|null>(null)

  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)

  useEffect(() => {
    if(commissioningRules) {
      if(!commissioningRules?.update && !isLectureMode) navigate(`/${t('url.commissioning.read-session')}/${commissioningType}/${uuid}`)
    }
  }, [commissioningRules])

  useEffect(() => {
    setIsLoading(true)
    if(uuid) {
      fetchCommissioning(uuid)
    } else {
      setIsLoading(false)
    }
  }, [uuid])

  const fetchCommissioning = (uuid: string) => {
    new CommissioningSessionGateway()
      .getCommissioningSession(uuid)
      .then((res) => {
        const copyResponse = {...res} as CommissioningSessionInterface
        if(copyResponse?.criterias?.products?.length) {
          const newArrayProducts = copyResponse.criterias?.products
            .map((
              product: {
                acronyme: string,
                id: string,
                label:string,
                session_id: string
              }) => {
            return {
              id: product.id,
              label: product.label,
              value: product.id
            }
          })
          copyResponse.criterias.products = newArrayProducts

        }

        if(copyResponse?.criterias?.partners?.length) {
          const newArrayPartners = copyResponse.criterias?.partners.map((partner: any) => {
            return {
              id: partner.id,
              label: partner.label,
              value: partner.id
            }
          })
          copyResponse.criterias.partners = newArrayPartners
        }

        setCommissioning({...copyResponse})
        setIsLoading(false)
      }).catch((e) => {
      setError(e)
      setIsLoading(false)
    })
  }

  const handleChoosePage = () => {
    switch (page) {
      case "upfront":
        if (mode === "add") {
          return <SettingForm isLectureMode={isLectureMode}/>
        }
        if (mode === "edit" && commissioning) {
          if(isSettingTab) {
            return <SettingForm commissioning={commissioning} isLectureMode={isLectureMode}/>
          } else {
            return <Session isLectureMode={isLectureMode}/>
          }
        }
        if (mode === "read" && commissioning) {
          if(isSettingTab) {
            return <SettingForm commissioning={commissioning} isLectureMode={isLectureMode}/>
          } else {
            return <Session isLectureMode={isLectureMode}/>
          }
      }
        break
      case "running":
        return console.log('case running')

      default: console.log(`Sorry, we are out of ${page}.`);
    }
  }

  if(error) return <ErrorPage code={error.code.toString()} />

  return (
    <>
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        <HeaderPage title={`${t(`commissioning.${uuid ? "edit" : "add"}.title-${commissioningType}`)} ${(uuid && commissioning?.code ) ? commissioning?.code : ""}` }/>
        <TabCommissioningAdd mode={mode} uuid={uuid}/>

        {(!isLoading && referential) ? handleChoosePage() : <section className="container--spinner"><Spinner size={150}/></section>}
      </main>
    </>
  )
}

export default CommissioningLayout