import {FunctionComponent} from 'react'

const LogoDiligencio: FunctionComponent = () => {
    return (
        <svg width="267" height="259" viewBox="0 0 267 259" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M261.48 52.9574L249.48 50.3574C246.68 49.7574 243.78 51.0574 242.48 53.5574L154.68 211.957C152.28 216.357 145.88 216.457 143.38 212.057L72.4801 88.8574C69.9801 84.5574 73.0801 79.1574 78.0801 79.1574H117.18C119.38 79.1574 121.48 80.2574 122.68 82.1574L143.28 114.457C145.88 118.557 151.98 118.457 154.38 114.157L192.58 46.6574C194.78 42.8574 192.58 38.0574 188.28 37.1574L7.88009 0.157393C2.38009 -1.04261 -1.91991 4.85739 0.880094 9.75739L142.98 255.657C145.48 260.057 151.78 259.957 154.28 255.657L265.78 62.4574C267.88 58.6574 265.78 53.8574 261.48 52.9574Z" fill="#298DA5"/>
        </svg>

    )
}

export default LogoDiligencio
