import {FunctionComponent, useEffect} from 'react'
import { v4 as uuidV4 } from 'uuid'

import '../../../../assets/styles/components/_pagination.scss'

type Props = {
  currentPage: number,
  itemsPerPage: number,
  numberOfItems: number,
  callback: (pageNumber: number) => void
}

const range = (from: number, to: number, step = 1) => {
  let i = from
  const range = []

  while (i <= to) {
    range.push(i)
    i += step
  }

  return range
}

const Pagination: FunctionComponent<Props> = ({ currentPage, itemsPerPage, numberOfItems, callback }) => {
  const totalPages = Math.ceil(numberOfItems / itemsPerPage)
  const pageNeighbours = 2

  const showPrevButton =  <>
      {(currentPage > 1 &&
      <li className={`pagination__prev`} onClick={() => callback(currentPage - 1)}>
        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.0625 6.5C0.78125 6.78125 0.78125 7.25 1.0625 7.53125L7.125 13.625C7.4375 13.9062 7.90625 13.9062 8.1875 13.625L8.90625 12.9062C9.1875 12.625 9.1875 12.1562 8.90625 11.8438L4.09375 7L8.90625 2.1875C9.1875 1.875 9.1875 1.40625 8.90625 1.125L8.1875 0.40625C7.90625 0.125 7.4375 0.125 7.125 0.40625L1.0625 6.5Z" fill="#073359"/>
        </svg>
      </li>
      )}
      {(currentPage === 1 &&
        <li className={`pagination__prev pagination__prev--disabled`}>
          <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.0625 6.5C0.78125 6.78125 0.78125 7.25 1.0625 7.53125L7.125 13.625C7.4375 13.9062 7.90625 13.9062 8.1875 13.625L8.90625 12.9062C9.1875 12.625 9.1875 12.1562 8.90625 11.8438L4.09375 7L8.90625 2.1875C9.1875 1.875 9.1875 1.40625 8.90625 1.125L8.1875 0.40625C7.90625 0.125 7.4375 0.125 7.125 0.40625L1.0625 6.5Z" fill="#073359"/>
          </svg>
        </li>
      )}
    </>
  const showNextButton =  <>
      {(currentPage !== totalPages &&
      <li className={`pagination__next`} onClick={() => callback(currentPage + 1)}>
        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.90625 7.53125C9.1875 7.25 9.1875 6.78125 8.90625 6.5L2.84375 0.40625C2.53125 0.125 2.0625 0.125 1.78125 0.40625L1.0625 1.125C0.78125 1.40625 0.78125 1.875 1.0625 2.1875L5.875 7L1.0625 11.8438C0.78125 12.1562 0.78125 12.625 1.0625 12.9062L1.78125 13.625C2.0625 13.9062 2.53125 13.9062 2.84375 13.625L8.90625 7.53125Z" fill="#073359"/>
        </svg>
      </li>
      )}
      {(currentPage === totalPages &&
        <li className={`pagination__next pagination__next--disabled`}>
          <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.90625 7.53125C9.1875 7.25 9.1875 6.78125 8.90625 6.5L2.84375 0.40625C2.53125 0.125 2.0625 0.125 1.78125 0.40625L1.0625 1.125C0.78125 1.40625 0.78125 1.875 1.0625 2.1875L5.875 7L1.0625 11.8438C0.78125 12.1562 0.78125 12.625 1.0625 12.9062L1.78125 13.625C2.0625 13.9062 2.53125 13.9062 2.84375 13.625L8.90625 7.53125Z" fill="#073359"/>
          </svg>
        </li>
      )}
    </>

  const fetchPageNumbers = () => {

    const totalNumbers = (pageNeighbours * 2) + 3
    const totalBlocks = totalNumbers + 2

    if (totalPages > totalBlocks) {

      const startPage = Math.max(2, currentPage - pageNeighbours)
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours)

      let pages = range(startPage, endPage)

      const hasLeftSpill = startPage > 2
      const hasRightSpill = (totalPages - endPage) > 1
      const spillOffset = totalNumbers - (pages.length + 1)

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case (hasLeftSpill && !hasRightSpill): {
          const extraPages = range(startPage - spillOffset, startPage - 1)
          pages = [0, ...extraPages, ...pages]
          break
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case (!hasLeftSpill && hasRightSpill): {
          const extraPages = range(endPage + 1, endPage + spillOffset)
          pages = [...pages, ...extraPages, 0]
          break
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case (hasLeftSpill && hasRightSpill):
        default: {
          pages = [0, ...pages, 0]
          break
        }
      }

      return [1, ...pages, totalPages]

    }

    return range(1, totalPages)
  }

  const pages = fetchPageNumbers()

  return (
    <>
      <nav>
      <ul className='pagination'>
        {showPrevButton}
        {pages.map(page => {
          if (page === 0) return (
            <li key={uuidV4()} className="pagination__item">
              <a className="page-link disabled-content" href="#">
                <span className="sr-only">...</span>
              </a>
            </li>
          )

          return <li key={uuidV4()}
                       className={`pagination__item ${currentPage === page ? 'pagination__item--active' : ''}`}
                       onClick={() => callback(page)}>
              <a className="page-link">
                {page}
              </a>
            </li>
        })}
        {showNextButton}
      </ul>
    </nav>
    </>
  )
}

export default Pagination
