import Caller from '../../../domain/Api/Caller'
import envVariable from '../../../infrastructure/presentation/util/envVariable'
import DocumentGatewayInterface from '../../../domain/Document/DocumentGatewayInterface'
import {DocumentInterface, DocumentToSend, DocumentToViewInterface} from '../../../domain/Partner/Document/Document'
import {DocumentBackEndInterface} from "../../../domain/Partner/Document/DocumentBackEnd";
import {DocumentListInterface} from "../../../domain/Document/Document";

export default class DocumentGateway implements DocumentGatewayInterface {

  partnerId: string|null

  constructor(
    partnerId: string|null
  ) {
    this.partnerId = partnerId
  }

  getFiles(): Promise<DocumentBackEndInterface[]|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/partners/${this.partnerId}/files`, {})
    .then(data => {
      if (data) {
        return data.data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }
  getFile(fileId: number): Promise<string|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/partners/${this.partnerId}/files/${fileId}`, {})
    .then(data => {
      if (data) {
        return data.content
      }

      return null
    })
    .catch(() => {
      return null
    })
  }
  save(uuid: string, documents: DocumentToSend[]): Promise<[]|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/partners/${uuid}/files`, {
      files: documents
    })
    .then(data => {
      if (data) {
        return data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }
  getConventionFiles(personId: string, conventionId: string): Promise<DocumentInterface[]|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/partners/${personId}/convention/${conventionId}/files`, {})
      .then(data => {
        if (data) {
          return data.data
        }
      })
  }
  getConventionFile(personId: string, conventionId: string, fileId: number): Promise<DocumentToViewInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/partners/${personId}/convention/${conventionId}/files/${fileId}`, {})
      .then(data => {
        if (data) {
          return data.content
        }
      })
  }
  saveConvention(personId: string, conventionId: string, documents: DocumentToSend[]): Promise<[]|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/partners/${personId}/convention/${conventionId}`, {
      files: documents
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }
  getList(): Promise<DocumentListInterface[]> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/partners/${this.partnerId}/documents`, {})
    .then(data => {
      if (data) {
        return data
      }
    })
    .catch(() => {
      return null
    })
  }
  deleteFile(fileId: number): Promise<boolean> {
    //TODO: Fonctionnalitée uniquement disponible pour les presouscription https://myrem.atlassian.net/browse/DS-630?atlOrigin=eyJpIjoiMzJkNzMxN2Y0ZGMyNGVhMDljMDQ1NzBhM2Y1OGY2NjYiLCJwIjoiaiJ9
    return Promise.resolve(false)
  }
}
