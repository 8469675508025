import React, {FunctionComponent, MouseEvent, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {SubmitHandler, useForm, useFormState} from 'react-hook-form';
import {useAppDispatch} from '../../../../../store/hook'

import {CommissionInterface} from '../../../../../../domain/Product/Commission';
import {ProductInterface} from '../../../../../../domain/Product/Product';
import ProductGateway from '../../../../../../gateway/Product/ProductGateway';
import InitialiazeProduct from '../../../../../../useCase/Product/Initialization/InitialiazeProduct';
import UpdateUseCase from '../../../../../../useCase/Product/Update/UpdateUseCase';
import AddUseCase from '../../../../../../useCase/Product/Add/AddUseCase';
import {ProductType} from '../../../../../../domain/Product/ProductType';

import expandIcon from '../../../../../../assets/images/icons/arrow-bottom--dark.svg';
import '../../../../../../assets/styles/components/_collapse.scss';
import {confirmAlert} from 'react-confirm-alert';
import ConfirmationModal from '../../../Modal/ConfirmationModal';
import {toastError, toastSuccess} from '../../../../util/Toast';
import {usePrompt} from '../../../../util/Navigation';
import {Scale, ScaleInterface} from '../../../../../../domain/Product/Scale';
import {ScaleItem, ScaleItemInterface} from '../../../../../../domain/Product/ScaleItem';
import {Category} from "../../../../../../domain/Product/Category";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import {ReferentielInterface} from '../../../../../../domain/Referentiel/ReferentielInterface'
import InputDate from "../../../Elements/InputDate";
import envVariable from "../../../../util/envVariable";
import {reloadProduct} from "../../../../../store/component/product";
import CheckboxToggle from "../../../Elements/CheckboxToggle";
import FormErrorInfo from "../../../Alert/FormErrorInfo/FormErrorInfo";
import SelectCustom from "../../../Elements/Select";
import Input from "../../../Elements/Input";

interface IFormInput {
  commission: CommissionInterface
  commissionupFrontTvaRadio: string
  commissionRunningTvaRadio: string
  calcul: string
}

function handleCollapse(event: MouseEvent<HTMLElement>) {
  event.currentTarget.classList.toggle('collapse__title--active')
}

type Props = {
  referential: ReferentielInterface
  product?: ProductInterface
  isLectureMode?: boolean
}
const Commission: FunctionComponent<Props> = ({product, isLectureMode, referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const productGateway = new ProductGateway()
  const {productType} = useParams()
  const [formErrors, setFormErrors] = useState<string[]>([])

  useEffect(() => {
    if (product?.commission) {
      setValue('commission', product?.commission)
      setValue('commissionupFrontTvaRadio', product?.commission.upFront.tva ? "1" : "0")
      setValue('commissionRunningTvaRadio', product?.commission.running.tva ? "1" : "0")
    }
    setValue('calcul', product?.calcul ? product?.calcul : "")
  }, [product])

  const {register, watch, control, handleSubmit, reset, setValue} = useForm<IFormInput>();
  const watchCommissionRunningType = watch('commission.running.commissionType')
  const watchCommissionUpFrontType = watch('commission.upFront.commissionType')
  const watchCommissionSCType = watch('commission.sc.commissionType')

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: IFormInput) => {
    reset(data)
    if (undefined === product) {
      product = new InitialiazeProduct().initializeProduct()
    }
    if (
      null == product.productType
    ) {
      product.productType = new ProductType(
        null,
        null,
        null,
        null,
        undefined !== productType ? productType : 'other',
        null,
        null,
        true
      )
    }
    product.commission = data.commission
    product.calcul = data.calcul

    if (null !== product.id) {
      new UpdateUseCase(productGateway).execute(product).then(response => {
        if (null !== response) {
          toastSuccess(t('product.notify.update-success'))
          dispatch(reloadProduct())
        } else {
          toastError(t('product.notify.update-error'));
        }
      })
    }
  }

  useEffect(() => {
    const isProductSC = product?.productType?.slug === 'sc'
    const formError = []
    if (isProductSC && !watchCommissionSCType) {
      formError.push('product.form.commission.commissionType')
    }
    if (!isProductSC && (!watchCommissionRunningType || !watchCommissionUpFrontType)) {
      formError.push('product.form.commission.commissionType')
    }
    setFormErrors([...formError])
  }, [watchCommissionRunningType, watchCommissionUpFrontType, watchCommissionSCType, product?.productType?.slug])


  const {isDirty} = useFormState({
    control
  });
  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));

  const renderFooter = () => {
    if (isLectureMode) return null
    return <footer className={`form-bloc__footer`}>
      <button type="submit" className="button button--ink-2">{t('common.save')}</button>
      <button className="button button--ink-2 button--ink-2--outline">{t('common.cancel')}</button>
    </footer>
  }

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, [isLectureMode]);

  const blockCommission = (commissionType: string) => {
    return (
      <div className="col-md-12">
        <div className={`form-bloc form--bloc--commission`}>
          <div className="form-bloc__title">{t(`product.form.commission.${commissionType}`)}</div>
          <div className="form-bloc__form flex-container">
            <div className="col-md-6">
              <div className="form-bloc__form flex-container">
                {(productType !== 'sc' && product?.productType?.slug !== 'sc') &&
                    <>
                        <SelectCustom id={`commission.${commissionType}.duration`}
                                      classes="col-md-8"
                                      name={`commission.${commissionType}.duration`}
                                      label={t(`product.form.commission.${commissionType}Duration`)}
                                      labelClasses="form-control__label"
                                      options={referential.product.approval || null}
                                      register={register}
                                      noChoiceOption
                        />
                        <CheckboxToggle id={`commission.${commissionType}.tva`}
                                        classesLabel="form-control--label-auto"
                                        name={`commission.${commissionType}.tva`}
                                        classes={'col-md-4'}
                                        register={register}
                                        label={t(`product.form.commission.${commissionType}Tva`)}
                        />
                    </>
                }
                <Input id={`commission.${commissionType}.commissionEarned`}
                       name={`commission.${commissionType}.commissionEarned`}
                       type={"number"}
                       step={1 / Math.pow(10, Number(envVariable('REACT_APP_COMMA_NUMBER')))}
                       label={t('product.form.commission.commissionEarned')}
                       classes={'col-md-8'}
                       register={register}
                />
                <Input id={`commission.${commissionType}.commissionNotEarned`}
                       name={`commission.${commissionType}.commissionNotEarned`}
                       type={"number"}
                       step={1 / Math.pow(10, Number(envVariable('REACT_APP_COMMA_NUMBER')))}
                       label={t('product.form.commission.commissionNotEarned')}
                       classes={'col-md-8'}
                       register={register}
                />
                <SelectCustom id={`commission.${commissionType}.commissionDetentionMode`}
                              name={`commission.${commissionType}.commissionDetentionMode`}
                              classes={"col-md-12"}
                              label={t('product.form.commission.commissionDetentionMode')}
                              options={referential.product.commission_detention_mode || null}
                              register={register}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-bloc__form flex-container">
                <SelectCustom id={`commission.${commissionType}.calcul`}
                              name={`commission.${commissionType}.calcul`}
                              classes={"col-md-12"}
                              label={t('product.form.commission.calcul')}
                              options={referential.product.calcul || null}
                              register={register}
                />
                <SelectCustom id={`commission.${commissionType}.commissionType`}
                              name={`commission.${commissionType}.commissionType`}
                              classes={"col-md-12"}
                              labelClasses={'mandatory-field'}
                              valueClasses={'field-in-error"'}
                              label={t('product.form.commission.commissionType')}
                              options={referential.product.commission_type || null}
                              register={register}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )}

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
      <div className="flex-container">
        <div className="col-md-12">
          {formErrors.length > 0 &&
              <FormErrorInfo errors={formErrors} message={t('product.form.commission.form-error-base-message')}/>}
        </div>
        {((productType === undefined || productType === 'sc') && product?.productType?.slug === 'sc') && blockCommission('sc')}
        {(productType !== 'sc' && product?.productType?.slug !== 'sc') && blockCommission('running')}
        {(productType !== 'sc' && product?.productType?.slug !== 'sc') && blockCommission('upFront')}
      </div>
      {renderFooter()}
    </form>
  )
}

export default Commission
