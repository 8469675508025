import {PreSubscriptionInterface} from "../../PreSubscription/PreSubscription";
import {Prospect, ProspectInterface} from "../../Prospect/Prospect";
import {PartnerInterface} from "../../Partner/Partner";
import {DocumentInterface} from "../../Document/Document";
import {BankInformation} from "../BankInformation";

interface InvestorInterface {
  id: string|null
  type: string|null
  amount: number|null
  paymentMode: string|null
  paymentStatus: string|null
  paymentNature: string|null
  paymentDate: string|null
  dismembermentKey: string|null
  bankInformations: BankInformation[]
  dismembermentTransaction: PreSubscriptionInterface|null
  checkDepositAt: string|null
  prospect: ProspectInterface|null
  documents: DocumentInterface[]|null
  partner?: PartnerInterface
}

class Investor implements InvestorInterface {
  id: string|null
  type: string|null
  amount: number|null
  paymentMode: string|null
  paymentStatus: string|null
  paymentNature: string|null
  paymentDate: string|null
  dismembermentKey: string|null
  bankInformations: BankInformation[]
  dismembermentTransaction: PreSubscriptionInterface|null
  checkDepositAt: string|null
  prospect: ProspectInterface|null
  documents: DocumentInterface[]|null
  partner?: PartnerInterface

  constructor(
    id: string|null,
    type: string|null,
    amount: number|null,
    paymentMode: string|null,
    paymentStatus: string|null,
    paymentNature: string|null,
    paymentDate: string|null,
    dismembermentKey: string|null,
    bankInformations: BankInformation[],
    dismembermentTransaction: PreSubscriptionInterface|null,
    checkDepositAt: string|null,
    prospect: ProspectInterface|null,
    documents: DocumentInterface[]|null,
    partner?: PartnerInterface
  ) {
    this.id = id
    this.type = type
    this.amount = amount
    this.paymentMode = paymentMode
    this.paymentStatus = paymentStatus
    this.paymentNature = paymentNature
    this.paymentDate = paymentDate
    this.dismembermentKey = dismembermentKey
    this.bankInformations = bankInformations
    this.dismembermentTransaction = dismembermentTransaction
    this.checkDepositAt = checkDepositAt
    this.prospect = prospect
    this.documents = documents
    this.partner = partner
  }
}

export {
  Investor
}

export type {InvestorInterface}
