import SliceGatewayInterface from '../../../../domain/Share/Slice/SliceGatewayInterface'
import {SliceListInterface} from '../../../../domain/Share/Slice/SliceList'
import {ListRequestInterface} from './ListRequest'

export default class ListUseCase {
  public sliceGateway

  constructor(SliceGateway: SliceGatewayInterface) {
    this.sliceGateway = SliceGateway
  }

  async execute(ListRequest: ListRequestInterface,
                signal?: AbortSignal,
                sort?: string,
                order?: string): Promise<{ data: SliceListInterface[] | null, nb_element: number } | null> {
    return await this.sliceGateway.getList(
      ListRequest.investorId,
      ListRequest.currentPage,
      ListRequest.itemPerPage,
      ListRequest.filter,
      signal,
      sort,
      order
    ).then(response => response)
  }
  async executeTypeDismemberment(ListRequest: ListRequestInterface, signal?: AbortSignal): Promise<{data: SliceListInterface[]|null, nb_element: number}|null> {
    return await this.sliceGateway.getListTypeDismemberment(ListRequest.investorId, ListRequest.currentPage, ListRequest.itemPerPage, ListRequest.filter, signal).then(response => response)
  }
}
