import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import HeaderPage from '../../../component/HeaderPage/HeaderPage'
import '../../../../../assets/styles/page/_product.scss'
import { CustomerInterface } from '../../../../../domain/Customer/Customer'
import TabCustomerEditPhysicalPerson
  from "../../../component/Tab/CustomerEditPhysicalPerson/TabCustomerEditPhysicalPerson";
import TabCustomerEditCorporation from "../../../component/Tab/CustomerEditCorporation/TabCustomerEditCorporation";
import Status from "../../../component/Transaction/Status";
import { useAppSelector } from '../../../../store/hook'
import GetNameForTitle from '../../../util/GetNameForTitle'

type Props = {
  setStatus: React.Dispatch<React.SetStateAction<string>>
  status: string
  customer?: CustomerInterface
  isLectureMode?: boolean
}
const CustomerHeaderPage: FunctionComponent<Props> = ({
                                                        customer,
                                                        isLectureMode,
                                                        status,
                                                        setStatus,
                                                      }) => {
  const { t } = useTranslation()

  const referential = useAppSelector(({referential}) => referential.referential)
  const [tab, setTab] = useState<any|null>(null)
  const [title, setTitle] = useState<string>(t('account.edit.title'))

  useEffect(() => {
    if (customer) {
      const titleFormatted = GetNameForTitle("customer", customer)
      if (customer.customerType === 'physical') {
        setTab(<TabCustomerEditPhysicalPerson uuid={customer.id}
                                              isLectureMode={isLectureMode ?? false}
                                              customer={customer} />
        )
      } else {
        setTab(<TabCustomerEditCorporation customer={customer}
                                           isLectureMode={isLectureMode ?? false} />)
      }
      setTitle(`${t('account.edit.title')} ${titleFormatted}`)
      setStatus(customer.status || '')
    }
  }, [customer])

  return (
    <>
      <HeaderPage title={title || t('account.edit.title')}
                  notePadInfo={{
                    lastNotepadAt: customer?.lastNotepadAt || "",
                    countNotepad: customer?.countNotepad || 0 }}
      />
      {referential
        ?
        <Status label={'common.status'}
                status={status}
                statusList={referential?.prospect.status}
                shortLabel
        />
        : <div className='badge-line--space-when-empty' />
      }
      {tab}
    </>
  )
}

export default CustomerHeaderPage
