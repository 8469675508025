import {FunctionComponent, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../store/hook";
import blockScrollBody from "../../../util/BlockScroll";
import {PartnerListInterface} from "../../../../../domain/Partner/PartnerList";
import {setPartner} from "../../../../store/component/customer"
import getClassForOverlay from "../../../util/Sidebar";
import ProductChildDatalist from "../../Datalist/ProductChildDatalist";
import {setOpenChoicePartnerEvent} from "../../../../store/component/event";
import PartnerDatalist from "../../Datalist/PartnerDatalist";
import PartnerSearchDatalist from "../../Datalist/PartnerSearchDatalist";
import {InitializePartner} from "../../../../../useCase/Partner/Initialization/InitializePartner";

const ChoicePartner: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const openChoicePartner = useAppSelector((state) => state.event.openChoicePartner)

  useEffect(() => {
    blockScrollBody(openChoicePartner.show)
  }, [openChoicePartner])

  const handleChoicePartner = function(selectedPartner: PartnerListInterface) {
    const partner = new InitializePartner().initializePartner()
    partner.id = selectedPartner.id
    partner.internalCommercial = selectedPartner.internalCommercialName
    partner.partnerCode = selectedPartner.partnerId
    dispatch(setPartner(partner))
    dispatch(setOpenChoicePartnerEvent({show: false}))
  }

  function handleClose() {
    dispatch(setOpenChoicePartnerEvent({show: false}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openChoicePartner.show)}`} onClick={() => handleClose()}/>
      <div className={`sidebar sidebar--right sidebar--right ${openChoicePartner.show ? 'sidebar--active-big' : ''}`}>
        <div className="form-bloc">
          <div className="sidebar__content">
            <div className="title">{t('account.search-a-partner')}</div>
            <PartnerSearchDatalist callback={handleChoicePartner} callerType={"datalist"} />
          </div>
          <footer className="sidebar__footer">
            <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => handleClose()}>{t('common.cancel')}</button>
          </footer>
        </div>
      </div>
    </>
  )
}

export default ChoicePartner
