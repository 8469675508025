import React, {FunctionComponent, useEffect, useState} from "react";
import ReferentielGateway from "../../../../gateway/Referentiel/ReferentielGateway";
import Select from "react-select";
import {Controller} from 'react-hook-form'
import {ReferentielUser} from "../../../../domain/Referentiel/ReferentielUser";
import {useTranslation} from "react-i18next";

type Props = {
  control: any
  name: string
  disabled?: boolean
  label ?: string
  classes ?: string
}

const customStyles = {
  control: (base: any) => ({
    ...base,
    minHeight: 25
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: 4
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 4
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: '0px 6px'
  }),
  input: (base: any) => ({
    ...base,
    margin : 0,
    padding: 0
  })
}

const UserDatalist: FunctionComponent<Props>= ({ control, name, disabled, label, classes }) => {
  const [userArray, setUserArray] = useState<ReferentielUser[]>([])
  const referentielGateway = new ReferentielGateway()
  const {t} = useTranslation()

  useEffect(() => {
    referentielGateway.getUsers().then((response: ReferentielUser[]) => {
      setUserArray(response)
    })
  }, [])

  const select =
    <>
      <Controller
        name={name}
        control={control}
        render={({
            field: { onChange, value, name },
        }) => (
          <Select
            name={name}
            options={userArray}
            onChange={(option) => onChange(option)}
            styles={customStyles}
            isClearable={true}
            isDisabled={disabled || false}
            value={value}
            placeholder={t('common.search-a-user')}
          />
          )
        }
      />
    </>

  return (
    <>
      <div className={`${classes}`}>
        {label &&
            <div className="form-control">
                <label htmlFor={name} className="form-control__label">{label}</label>
              {select}
            </div>
        }
        {!label && select}
      </div>
    </>
  )
}

export default UserDatalist
