import {FunctionComponent} from "react";
import RingLoader from "react-spinners/RingLoader";
import {css} from "@emotion/react";

type Props = {
  size?: number
}

const Spinner: FunctionComponent<Props> = ({size= 50}) => {

  const override = css`
  display: block;
  margin: 0 auto;
  margin-top: 5px;
  border-color: #F26E22;
`;

  return(
      <RingLoader color={'#F26E22'} loading={true} css={override} size={size} />
  )
}

export default Spinner
