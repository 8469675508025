import React, {FunctionComponent, InputHTMLAttributes} from 'react'

import '../../../../assets/styles/components/_bloc-information-shortcut.scss'
import {useTranslation} from "react-i18next";
import Modal from 'react-modal';
import '../../../../assets/styles/components/_modal.scss'
import imgAlert from '../../../../assets/images/icons/alert.svg'
import {IFormFieldProps} from "../Elements/IFormFieldProps";
import {ReferentielInterface} from "../../../../domain/Referentiel/ReferentielInterface";
import SelectCustom from "../Elements/Select";
import { useAppSelector } from '../../../store/hook'


interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  show: boolean
  onConfirm: () => void
  onClose: () => void
  cancelValue?: string
  name: string
  value?: string
  defaultValue?: string
}

const CancelTransactionModal: FunctionComponent<IProps> = ({
  show,
  onConfirm,
  onClose,
 cancelValue,
  name,
  value,
  defaultValue,
  register
}) => {

  Modal.setAppElement("#root");

  const {t} = useTranslation();

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)


  const styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <>
      {show &&
        <div className="modal">
          <Modal
            isOpen={true}
            style={styles}
          >
            <button type="button"
                    className="content__close"
                    aria-label={t('common.modal.warning.close')}
                    onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="content">
              <div className="content__image">
                <img src={imgAlert} alt="" />
              </div>
              <p className="content__text">
                {t('movement.pre-subscription.form.actions.cancel-modal.message_first')}
              </p>
              <p className="content__text">
                <div className="form-control__input col-md-12">
                    <SelectCustom id="cancel.reason" name="cancel.reason" classes="col-md-12" register={register} options={referential?.wallet_subscription.cancel_reason || null} />
                </div>
              </p>
              <div className="content__buttons">
                <button type="button"
                        className="button button--ink-2 u-mrm"
                        onClick={(d) => {
                          onConfirm();
                          setTimeout(() => {
                            onClose();
                          }, 500)
                        }}
                        disabled={!cancelValue || cancelValue === ''}
                >
                  {t('movement.pre-subscription.form.actions.cancel-modal.confirm')}
                </button>
                <button type="button"
                        className="button button--ink-2 button--ink-2--outline"
                        onClick={() => {
                          onClose();
                        }}>
                  {t('movement.pre-subscription.form.actions.cancel-modal.close')}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      }
    </>
  )
}

export default CancelTransactionModal
