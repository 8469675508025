import {Investor} from "../../../domain/Movement/Dismemberment/Investor";

export default class InitializeDismembermentInvestor {
  initializeDismembermentInvestor() {
    return new Investor(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      null,
      null,
      null,
       []
    )
  }
}
