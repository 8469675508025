import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';

import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { setCountTotal } from '../../../store/component/movement';
import { setOpenDataListFilterPortfolio } from '../../../store/component/event';
import Pagination from '../Pagination/Pagination';
import download from '../../util/Download';

import iconHeadingSearch from '../../../../assets/images/icons/datalist-heading-search.svg';
import iconEdit from '../../../../assets/images/icons/datalist-edit.svg';
import '../../../../assets/styles/components/_datalist.scss';
import { ReferentielInterface } from '../../../../domain/Referentiel/ReferentielInterface';
import iconSearch from '../../../../assets/images/icons/datalist-search.svg';
import PortfolioListPresenter from '../../../../presenter/Movement/PortfolioPresenter';
import { PortfolioRequest } from '../../../../useCase/Movement/List/PortfolioRequest';
import { FilterPortfolioInterface, PortfolioList } from '../../../../domain/Movement/PortfolioList';
import PortfolioGateway from '../../../../gateway/Movement/PortfolioGateway';
import PortfolioListUseCase from '../../../../useCase/Movement/List/PortfolioListUseCase';
import HeaderRight from './Element/HeaderRight';
import {SortOrder} from "../../../../domain/Utils/List";
import iconEsnEntry from '../../../../assets/images/icons/type-mouvement-entree.svg';
import iconEsnExit from '../../../../assets/images/icons/type-mouvement-sortie.svg';
import iconEsnNew from '../../../../assets/images/icons/type-mouvement-nouvelle-part.svg';
import iconEsnRecurrence from "../../../../assets/images/icons/type-mouvement-recurrence.svg";
import iconEsnTransferPlus from "../../../../assets/images/icons/type-mouvement-transfer+.svg";
import iconEsnTransferMinus from "../../../../assets/images/icons/type-mouvement-transfer-.svg";

interface ViewModelInterface {
    title: string;
    heading: [];
    data: [];
    filtersShortcut: [];
    filters: [];
    pagination: PaginationInterface;
    count: number;
}

interface PaginationInterface {
    itemsPerPage: number;
    numberOfItems: number;
}

type Props = {
    investorId: string
    isLectureMode: boolean
}

const PortfolioDatalist: FunctionComponent<Props> = ({ investorId, isLectureMode }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
    const openDatalistFilterPortfolio = useAppSelector((state) => state.event.openDatalistFilterPortfolio);
    const [viewModel, setViewModel] = useState<ViewModelInterface | null>(null);
    const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.DESC)
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isLoadingExport, setLoadingExport] = useState<boolean>(false);

    useEffect(() => {
        const listRequest = new PortfolioRequest(currentPage, viewModel?.pagination.itemsPerPage || 50, openDatalistFilterPortfolio.filters);
        const investments = new PortfolioListUseCase(new PortfolioGateway()).execute(investorId, listRequest).then(response => {
            return response
        })
        const presenter = new PortfolioListPresenter(investments);
        presenter.load().then(() => {
            setViewModel(presenter.immutableViewModel());
            dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems));
        });

        setValue('product', openDatalistFilterPortfolio.filters.product);
        setValue('transaction_type', openDatalistFilterPortfolio.filters.transaction_type);
        setValue('keywords', openDatalistFilterPortfolio.filters.keywords);
        setValue('investor', openDatalistFilterPortfolio.filters.investor);
        setValue('start', openDatalistFilterPortfolio.filters.start);
        setValue('end', openDatalistFilterPortfolio.filters.end);
        setValue('property_type', openDatalistFilterPortfolio.filters.property_type);
    }, [currentPage, openDatalistFilterPortfolio.filters]);

    useEffect(() => {
        setCurrentPage(1);
    }, [openDatalistFilterPortfolio.filters]);

    const { register, handleSubmit, setValue } = useForm();
    const onSubmit: SubmitHandler<FilterPortfolioInterface> = data => {
        dispatch(setOpenDataListFilterPortfolio({
                show: false,
                count: openDatalistFilterPortfolio.count,
                filters: {
                    product: data.product,
                    transaction_type: data.transaction_type,
                    keywords: data.keywords,
                    investor: data.investor,
                    start: data.start,
                    end: data.end,
                    property_type: data.property_type,
                },
            },
        ));
    };

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const handleClickFilter = (response: string) => {
        if (response) {
            dispatch(setOpenDataListFilterPortfolio({ show: true, count: openDatalistFilterPortfolio.count, filters: openDatalistFilterPortfolio.filters }));
        }
    };

    const resetFilters = () => {
        dispatch(setOpenDataListFilterPortfolio({
                show: false,
                count: 0,
                filters: {
                    product: '',
                    transaction_type: '',
                    keywords: '',
                    investor: '',
                    start: '',
                    end: '',
                    property_type: '',
                },
            },
        ));
    };


    const getUrlMovement = (movement: any, mode: string) => {
        let url = '';

        switch (movement.transaction_type) {
            case 'withdrawal':
                url = t(`url.movements.${ mode === 'read' ? 'read' : 'edit' }-redemption-withdrawal`);
                break;
            case 'agreement':
                url = t(`url.movements.${ mode === 'read' ? 'read' : 'edit' }-gre-gre`);
                break;
            case 'mutation':
                url = t(`url.movements.${ mode === 'read' ? 'read' : 'edit' }-mutations`);
                break;
            case 'subscription':
                url = t(`url.pre-subscription.${ mode === 'read' ? 'read' : 'edit' }`);
                break;
            case 'dismemberment':
                url = t(`url.movements.${ mode === 'read' ? 'read' : 'edit' }-dismemberment`);
                break;
            case 'foo5':
                url = t('url.movements.edit-land-consolidation');
                break;
        }

        return `/${ url }/${ movement.id }`;
    };

    const handleClickExport = () => {
        setLoadingExport(true);
        new PortfolioGateway().getExport(investorId, {
            product: openDatalistFilterPortfolio.filters.product,
            transaction_type: openDatalistFilterPortfolio.filters.transaction_type,
            keywords: openDatalistFilterPortfolio.filters.keywords,
            investor: openDatalistFilterPortfolio.filters.investor,
            start: openDatalistFilterPortfolio.filters.start,
            end: openDatalistFilterPortfolio.filters.end,
            property_type: openDatalistFilterPortfolio.filters.property_type,
        }).then(response => {
            if (response) {
                download(t('export.movements'), response);
                setLoadingExport(false);
            }
        });
    };

    const handlePartnerType = (partnerInvestor: string|null, partnerPropertyType: string) => {
        if(partnerPropertyType === "full_property") return "PP"
        else if(partnerPropertyType === "usufruct") return "USU"
        else if(partnerPropertyType === "dismemberment" && partnerInvestor) {
            if(partnerInvestor === "np") return "NP"
            else if(partnerInvestor === "usufruct") return "USU"
        }
        return ""
    }

    function pictoFromEsnType(esnType: string|null) {
        if(esnType === "E"){
            return <img src={iconEsnEntry} title="Entrée"/>
        }
        if(esnType === "S") {
            return <img src={iconEsnExit} title="Sortie"/>
        }
        if(esnType === "N") {
            return <img title="Nouvelle part" src={iconEsnNew} />
        }
        if(esnType === "R") {
            return <img title="Récurrence" src={iconEsnRecurrence} />
        }
        if(esnType === "T+") {
            return <img title="Transfert In" src={iconEsnTransferPlus} />
        }
        if(esnType === "T-") {
            return <img title="Transfert Out" src={iconEsnTransferMinus} />
        }
        return ""
    }

    return (<>
            { (viewModel !== null &&
                <>
                    <div className={ `datalist` }>
                        <div className="datalist__title">{ t(viewModel.title) }</div>
                        <div className="datalist__header">
                            <form onSubmit={ handleSubmit(onSubmit) } className="filter">
                                <div className="filter__input">
                                    { viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                                        <div key={ uuidV4() } className="input-no-border">
                                            <img src={ iconHeadingSearch } alt=""/>
                                            <input { ...register(filter.field) }
                                                   placeholder={ t('common.search-by', { keyword: t(filter.keyword) }) }
                                                   className="u-mxs"/>
                                        </div>
                                    )) }
                                </div>
                                <div className="filter__actions">
                                    <button type="submit" className="button button--submit">{ t('search.submit') }</button>
                                    <button type="button" className="button button--white"
                                            onClick={ () => resetFilters() }>{ t('search.cancel') }</button>
                                </div>
                            </form>
                            <HeaderRight numberOfActivatedFilters={ openDatalistFilterPortfolio.count }
                                         handleClickFilter={ handleClickFilter } handleClickExport={ handleClickExport }
                                         isLoadingExport={ isLoadingExport } allowExport={true} hideFilter/>
                        </div>
                        <div className="table-fix-head">
                        <table className="datalist__datas">
                            <thead>
                            <tr>
                                {/* viewModel.heading.map((title: { name: string, sort?: string }) => (
                                    <th key={uuidV4()} className={title.sort ? 'sortable' : ''} onClick={()=>sortData(title.sort)}>{t(title.toString())}</th>
                                )) */}
                                {viewModel.heading.map((title) => (
                                  <th key={uuidV4()}>{t(title)}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            { viewModel.data !== undefined && viewModel.data.map((item: PortfolioList) => (
                                <tr key={ uuidV4() }>
                                    <td> {item.transaction_type !== "transfer" ?
                                      <>
                                          <Link to={getUrlMovement(item, 'read')}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                          >
                                              <button type="button" className="button-reset">
                                                  <img src={iconSearch} alt=""/>
                                              </button>
                                          </Link>
                                          {!isLectureMode && <Link to={getUrlMovement(item, 'edit')}>
                                              <button type="button" className="button-reset">
                                                  <img src={iconEdit} alt=""/>
                                              </button>
                                          </Link>}
                                      </>
                                      : null}
                                    </td>
                                    <td>{ item.product_acronyme }</td>
                                    <td>{ item.transaction_code }</td>
                                    <td>
                                        <div className="ceil-esn">
                                            { pictoFromEsnType(item.esn) }
                                            { item.transfer_type && pictoFromEsnType(item?.transfer_type || null) }
                                        </div>
                                    </td>
                                    <td>
                                        { referential?.wallet_subscription.transaction_type.find(property => {
                                            return property.value === item.transaction_type;
                                        })?.label.toString() }
                                    </td>
                                    <td>
                                        { referential?.wallet_subscription.possession_mode.find(property => {
                                            return property.value === item.nature;
                                        })?.label.toString() }
                                    </td>
                                    <td>
                                        {item.property_type ? handlePartnerType(item?.partner_investor, item.property_type) : ""}
                                    </td>
                                    <td>{ item.transaction_created_at }</td>
                                    <td>{ item.date }</td>
                                    <td>{ item.count }</td>
                                    <td>{ item.share_start }</td>
                                    <td>{ item.share_end }</td>
                                    <td>{ item.transaction_type !== 'transfer' && item.possession_date }</td>
                                    <td>{ item.first }</td>
                                    <td>{ item.price }</td>
                                    <td>{ item.amount }</td>
                                    <td>{ item.investor_code }</td>
                                    <td>{ item.investor_name }</td>
                                    <td>{ item.usufruct_code }</td>
                                    <td>{ item.dismemberment_rate }</td>
                                    <td>{ item.dismemberment_end }</td>
                                    <td>{ item.transfer_date }</td>
                                    <td>{ item.transfer_id }</td>
                                    <td>{ item.transfer_partner_social_reason }</td>
                                    <td>{ item.transfer_target_social_reason }</td>

                                </tr>
                            )) }
                            { viewModel.data === undefined || viewModel.data.length === 0 &&
                                <tr>
                                    <td colSpan={ viewModel?.heading.length }>{ t('common.data-is-empty') }</td>
                                </tr>
                            }
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <Pagination currentPage={ currentPage } itemsPerPage={ viewModel.pagination.itemsPerPage }
                                numberOfItems={ viewModel.pagination.numberOfItems } callback={ paginate }/>
                </>
            ) }
        </>
    );
};

export default PortfolioDatalist;
