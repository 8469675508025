import {Presenter} from "../Presenter";
import {PledgeListInterface} from "../../domain/Pledge/PledgeList";

class PledgePresenter extends Presenter {
  private useCase: { getPledge: { data: PledgeListInterface[] | null, nb_element: number } }

  constructor(getPledge: any) {
    super({
      viewModel: {
        title: 'account.search-a-customer',
        heading: ['account.form.pledge.heading.reason',
          'account.form.pledge.heading.actions',
          'account.form.pledge.heading.organization',
          'account.form.pledge.heading.type',
          'account.form.pledge.heading.dateStart',
          'account.form.pledge.heading.dateLimit',
          'account.form.pledge.heading.dateHand',
          'account.form.pledge.heading.product',
          'account.form.pledge.heading.quantity',
          'account.form.pledge.heading.shareStart',
          'account.form.pledge.heading.shareEnd',
        ],
        datas: [],
        filtersShortcut: [
          {
            'keyword': 'filters.by-product',
            'field': 'product',
            'type': 'input'
          }
        ],
        filters: [],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getPledge,
    }
  }

  async load() {
    try {
      this._setPledge(await this.useCase.getPledge);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setPledge(pledge: { data: PledgeListInterface[] | null, nb_element: number }) {
    this.update({
      data: pledge.data,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': pledge.nb_element
      }
    })
  }
}

export default PledgePresenter
