import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {SliceListInterface} from '../../../../../domain/Share/Slice/SliceList'
import SliceListPresenterView from '../../../../../presenter/Share/Slice/SliceListPresenterView'

import removeIcon from '../../../../../assets/images/icons/remove.svg'
import '../../../../../assets/styles/components/_datalist.scss'
import {removeSliceFromTransaction} from '../../../../store/component/slice'
import iconEsnEntry from '../../../../../assets/images/icons/type-mouvement-entree.svg';
import iconEsnExit from '../../../../../assets/images/icons/type-mouvement-sortie.svg';
import iconEsnNew from '../../../../../assets/images/icons/type-mouvement-nouvelle-part.svg';
import iconEsnRecurrence from "../../../../../assets/images/icons/type-mouvement-recurrence.svg";
import iconEsnTransfer from "../../../../../assets/images/icons/type-mouvement-transfer.svg";
import iconEsnTransferPlus from "../../../../../assets/images/icons/type-mouvement-transfer+.svg";
import iconEsnTransferMinus from "../../../../../assets/images/icons/type-mouvement-transfer-.svg";

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

type Props = {
  SliceArray: any
  title?: string
  isLectureMode?: boolean
  deleteDisabled?: boolean
  priceNoDependToProduct?: boolean
}

const ListToSell: FunctionComponent<Props> = ({SliceArray, title, isLectureMode, deleteDisabled, priceNoDependToProduct}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)

  useEffect(() => {
    const presenter = new SliceListPresenterView({data: SliceArray, nb_element: SliceArray.length});
    presenter.load().then(() => {
      setViewModel(presenter.immutableViewModel())
    })
  }, [SliceArray])

  function pictoFromEsnType(esnType: string|null) {
    if(esnType === "E"){
      return <img src={iconEsnEntry} title="Entrée"/>
    }
    if(esnType === "S") {
      return <img src={iconEsnExit} title="Sortie"/>
    }
    if(esnType === "N") {
      return <img title="Nouvelle part" src={iconEsnNew} />
    }
    if(esnType === "R") {
      return <img title="Récurrence" src={iconEsnRecurrence} />
    }
    if(esnType === "T+") {
      return <img title="Transfert In" src={iconEsnTransferPlus} />
    }
    if(esnType === "T-") {
      return <img title="Transfert Out" src={iconEsnTransferMinus} />
    }
    return ""
  }

  return (<>
    {(viewModel !== null &&
      <>
      <div className={`datalist`}>
        <div className="datalist__title">{title || t(viewModel.title)}</div>
        <table className="datalist__datas">
          <thead>
          <tr>
            {viewModel.heading.map((title: string) => (
              <th key={uuidV4()}>{t(title)}</th>
            ))}
          </tr>
          </thead>
          <tbody>
          {viewModel.data?.map((item: SliceListInterface) => (
            <tr key={uuidV4()}>
              {item !== null && item !== undefined &&
                <>
                  <td>{item.product.acronym}</td>
                  <td>
                    <div className="ceil-esn">
                      { pictoFromEsnType(item.esn) }
                      { item.transfer_id && pictoFromEsnType("T") }
                    </div>
                  </td>
                  <td>{item.date}</td>
                  <td>{item.propertyNature || ""}</td>
                  <td>{item.propertyType ? t(`movement.heading-slice.${item.propertyType}`) : ""}</td>
                  <td>{item.startPart}</td>
                  <td>{item.endPart}</td>
                  <td>{item.count || ""}</td>
                  <td>{priceNoDependToProduct ? item.pricePart : item.product.price || item.product.priceVL || ""}</td>
                  <td>{item.price}</td>
                  <td>{item.countShareSelected}</td>

                </>
              }
              <td>
                {!deleteDisabled && <button type="button"
                                            className="button-reset"
                                            disabled={isLectureMode}
                                            onClick={() => dispatch(removeSliceFromTransaction(item.id))}>
                    <img src={removeIcon} alt="" className="remove-icon"/>
                </button>
                }
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
      </>
    )}
    </>
  )
}

export default ListToSell
