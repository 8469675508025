import {Presenter} from '../Presenter'

class PartnerKpiPresenter extends Presenter {

  private useCase: { defaultUseCase: any }

  constructor(defaultUseCase: any) {
    super({
      viewModel: {
        type: 'bar_chart',
        title: '',
        heightCanvas: 350,
        data: {
          labels: [],
          datasets: [
            {
              labels: [],
              data: [],
              backgroundColor: [],
            },
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            }
          }
        },
      }
    });

    this.useCase = {
      defaultUseCase
    }
  }

  load() {
    try {
      this._setData(this.useCase.defaultUseCase);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setData(response: {
    title: string,
    type: string,
    labels: [],
    colors: [],
    data: []
  }) {
    this.update({
      type: 'bar_chart',
      title: response.title,
      data: {
        labels: response.labels,
        datasets: [
          {
            label: response.title,
            data: response.data,
            backgroundColor: response.colors,
          },
        ]
      }
    });
  }
}

export default PartnerKpiPresenter
