export default class DateCleaner {
  static clean(date: string|null|undefined): string|null
  {
    if (date === null || date === undefined) {
      return null
    }

    if (date.includes('_')) {
      return null
    }

    return date
  }
}
