import React, {FunctionComponent, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm, useFormState} from 'react-hook-form'

import {useAppDispatch, useAppSelector} from '../../../../../store/hook'
import {PartnerInterface} from '../../../../../../domain/Partner/Partner'
import UpdateUseCase from '../../../../../../useCase/Partner/Update/UpdateUseCase'
import PartnerGateway from '../../../../../../gateway/Partner/PartnerGateway'
import AddUseCase from '../../../../../../useCase/Partner/Add/AddUseCase'
import {InitializePartner} from '../../../../../../useCase/Partner/Initialization/InitializePartner'
import DocumentGateway from '../../../../../../gateway/Partner/Document/DocumentGateway'
import {setOpenDocumentList, setOpenPartnerUploadForm} from '../../../../../store/component/event'

import eyeIcon from '../../../../../../assets/images/icons/eye.svg'
import uploadIcon from '../../../../../../assets/images/icons/upload.svg'
import '../../../../../../assets/styles/page/_partner-add-document-tracking.scss'
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {usePrompt} from "../../../../util/Navigation";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import InputDate from "../../../Elements/InputDate";
import {DocumentBackEndInterface} from "../../../../../../domain/Partner/Document/DocumentBackEnd";
import {reloadPartner} from "../../../../../store/component/partner";
import CheckboxToggle from "../../../Elements/CheckboxToggle";
import Spinner from "../../../Spinner/Spinner";
import {DocumentType} from "../../../../../../domain/Document/DocumentInterface";
import {DefaultPartnerDocuments} from "../../../../../../fixtures/Partner";

interface IFormInput {
  documents: {
    [p: string]: DocumentBackEndInterface & { order: number }
  } | null

  blocking: boolean | null
  blockingAt: string | null
  blockingComment: string | null
}

type Props = {
  partner: PartnerInterface
  isLectureMode: boolean
}

const DocumentTracking: FunctionComponent<Props> = ({partner, isLectureMode}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const { documents: formDocument } = useAppSelector((state) => state.event.openPartnerUploadForm)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false)
  const [documentArray, setDocumentArray] = useState<DocumentType>({})

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    watch
  } = useForm<IFormInput>({
    defaultValues: {
      documents: null,
    }
  })

  const documents = watch('documents')

  useEffect(() => {
    if (partner && partner.documentList) {
      setValue('blocking', !!partner.blockedAtStatus)
      setValue('blockingAt', partner.blockedAtStatus)
      setValue('blockingComment', partner.comment)

      if (partner.id) {
        new DocumentGateway(partner.id)
          .getFiles()
          .then(documentsFromBack => {
            let documents: { [p: string]: DocumentBackEndInterface & { order: number } } | null = DefaultPartnerDocuments
            const docs: DocumentType = {}

            if (documentsFromBack?.length) {
              documentsFromBack.forEach((doc) => {
                let orderDocument

                if (doc?.type && doc?.id) {
                  const documentFile = {
                    id: doc.id,
                    updatedAt: doc.updatedAt,
                    type: doc.type,
                    title: doc.title,
                    extension: doc.extension,
                    date: doc.updatedAt
                  }

                  docs[doc.type] = doc.type in docs ? [...docs[doc.type], documentFile] : [documentFile]
                }

                switch (doc.type) {
                  case "KYC":
                    orderDocument = 0
                    break
                  case "KBIS":
                    orderDocument = 1
                    break
                  case "STATUS":
                    orderDocument = 2
                    break
                  case "RIB":
                    orderDocument = 3
                    break
                  case "CNI":
                    orderDocument = 4
                    break
                  case "OTHER":
                    orderDocument = 5
                    break
                  default:
                    orderDocument = 99
                    break
                }

                documents = {
                  ...documents,
                  [doc.type.toLowerCase()]: {
                    ...doc,
                    order: orderDocument
                  }
                }
              })
            }

            setValue("documents", documents)
            setDocumentArray(docs)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    } else {
      setIsLoading(false)
    }
  }, [partner, setValue, formDocument])

  const b64ToBlob = async (base64Data: string) => {
    return await (await fetch(base64Data)).blob();
  }
  const viewFile = (docId: number) => {
    new DocumentGateway(partner.id)
      .getFile(docId)
      .then((response: string | null) => {
        const file = window.open('')
        if (file !== null && response !== null) {
          b64ToBlob(response).then((b) => {
            const url = URL.createObjectURL(b);
            file.document.write(
              "<body style='margin:0;padding:0;overflow:hidden'><iframe id='iframe' width='100%' height='100%' style='border:0' src='" + url + "'></iframe></body>"
            )
            URL.revokeObjectURL(url)
          })
        }
      })
  }
  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: IFormInput) => {
    reset(data)
    if (undefined === partner) {
      partner = (new InitializePartner()).initializePartner();
    }

    partner.blockedAtStatus = data.blockingAt
    partner.comment = data.blockingComment

    if (data?.documents) {
      partner.documentList = Object.entries(data.documents)
        .filter(([key, value]) => !!value?.id)
        .map(([key, value]) => {
          return {
            documentType: key.toUpperCase(),
            extension: value.extension || "",
            id: value.id || 0,
            requestAt: value.requestAt,
            sendAt: value.sendAt || "",
            title: value.title || "",
            waiting: value.waiting
          }
        })
    }

    setIsLoadingRequest(true)
    if (partner.id) {
      new UpdateUseCase(new PartnerGateway())
        .execute(partner)
        .then(response => {
          dispatch(reloadPartner());
          if (null !== response) {
            toastSuccess(t('partner.notify.update-success'))
          } else {
            toastError(t('partner.notify.update-error'));
          }
        })
    } else {
      new AddUseCase(new PartnerGateway())
        .execute(partner)
        .then(uuid => {
          if (null !== uuid) {
            toastSuccess(t('partner.notify.add-success'))
          } else {
            toastError(t('partner.notify.add-error'));
          }
        })
    }
    setIsLoadingRequest(false)

    // on reset la sideBar après l'upload
    dispatch(setOpenPartnerUploadForm({
      show: false,
      documents: null,
      partnerId: '',
      type: ''
    }))
  }

  const {isDirty} = useFormState({
    control
  });
  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));

  const renderFooter = () => {
    if (isLectureMode) return null
    return <footer className={`form-bloc__footer`}>
      <button type="submit"
              className="button button--ink-2"
              disabled={isLoadingRequest}
      >
        {t('common.save')}
      </button>
      <button className="button button--ink-2 button--ink-2--outline"
      >
        {t('common.cancel')}
      </button>
    </footer>
  }

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, [isLectureMode]);

  if (isLoading) return (
    <div style={{height: "33vh"}}
         className={"flex items-center justify-center"}
    >
      <Spinner size={50}/>
    </div>
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
      <div className={`form-bloc form-bloc--document-tracking`}>
        <div className="form-bloc__title">{t('partner.form.bloc-document-tracking.title')}</div>
        <div className="form-bloc__form document-tracking__waiting-document">
          <table className="table--document-tracking">
            <thead>
            <tr>
              <th/>
              <th className="form-control__label">{t('partner.form.bloc-document-tracking.th-request-date')}</th>
              <th className="form-control__label">{t('partner.form.bloc-document-tracking.th-received-date')}</th>
              <th className="form-control__label">{t('partner.form.bloc-document-tracking.th-document-date')}</th>
              <th className="form-control__label">{t('partner.form.bloc-document-tracking.th-waiting-document')}</th>
              <th className="form-control__label">{t('common.multiple-action')}</th>
            </tr>
            </thead>
            <tbody>
            {
              documents && Object
                .entries(documents)
                .sort((a, b) => {
                  return a[1].order - b[1].order
                })
                .map(([key, value]) => {
                  return (
                    <tr key={`document-${value.type}-${value.id}`}>
                      <td>
                        <label htmlFor="partnerNum"
                               className="form-control__label"
                        >
                          {t(`partner.form.bloc-document-tracking.${key}`)}
                        </label>
                      </td>
                      <td>
                        <div className="form-control__input">
                          <InputDate id={`documents.${key}.requestAt`}
                                     name={`documents.${key}.requestAt`}
                                     register={register}
                                     control={control}
                                     type="text"
                                     isLectureMode={isLectureMode}
                                     readOnly={isLectureMode}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-control__input">
                          <InputDate id={`documents.${key}.sendAt`}
                                     name={`documents.${key}.sendAt`}
                                     control={control}
                                     register={register}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-control__input">
                          <InputDate id={`documents.${key}.createdAt`}
                                     name={`documents.${key}.createdAt`}
                                     control={control}
                                     register={register}
                                     disabled={true}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="document-tracking__waiting-document">
                          <CheckboxToggle classesLabel="form-control--label-auto"
                                          id={`documents.${key}.waiting`}
                                          name={`documents.${key}.waiting`}
                                          isSmall={true}
                                          register={register}/>
                        </div>
                      </td>
                      <td>
                        <button type="button"
                                name="read.document"
                                className="button-reset u-mxs"
                                onClick={() => !isLectureMode && dispatch(setOpenDocumentList({
                                  show: true,
                                  documentList: value.type in documentArray ? documentArray[value.type as string] : [],
                                  gateway: new DocumentGateway(partner.id),
                                }))}
                                disabled={!value.id}
                        >
                          <img src={eyeIcon} alt=""/>
                        </button>
                        {!isLectureMode && <button type="button"
                                                   className="button-reset u-mxs"
                                                   onClick={() => !isLectureMode && dispatch(setOpenPartnerUploadForm({
                                                     show: true,
                                                     documents: null,
                                                     partnerId: partner.id,
                                                     type: key.toUpperCase()
                                                   }))}
                                                   disabled={isLectureMode}
                        >
                            <img src={uploadIcon} alt=""/>
                        </button>}
                      </td>
                    </tr>
                  )
                })
            }
            </tbody>
          </table>
          <div className="flex-container u-mtm u-align-baseline">
            <div className="col-md-4">
              <div className="flex-container">
                <CheckboxToggle classesLabel="form-control--label-auto"
                                id="blocking"
                                name="blocking"
                                label={t('partner.form.bloc-document-tracking.blocking')}
                                register={register}/>
              </div>
            </div>
            <div className="form-control col-md-4">
              <label htmlFor="blockingAt"
                     className="form-control__label"
              >
                {t('partner.form.bloc-document-tracking.blocking-at')}
              </label>
              <div className="form-control__input">
                <InputDate id="blockingAt"
                           name="blockingAt"
                           control={control}
                           register={register}
                />
              </div>
            </div>
            <div className="form-control col-md-4">
              <label htmlFor="blockingComment"
                     className="form-control__label"
              >
                {t('partner.form.bloc-document-tracking.blocking-comment')}
              </label>
              <div className="form-control__input">
                <input type="text" {...register('blockingComment')} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderFooter()}
    </form>
  )
}

export default DocumentTracking
