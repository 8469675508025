import {Investor} from "./Investor";
import {Product} from "../../Product/Product";
import {User} from "../../User/User";
import {Recurrence} from "../../PreSubscription/Recurrence";

interface DismembermentInterface {
  id: string|null
  createdAt: string|null
  updatedAt: string|null
  subscribedAt: string|null
  deletedAt: string|null
  engagedAt: string|null
  canceledAt: string|null
  verifiedLevel1At: string|null
  verifiedLevel2At: string|null
  confirmedAt: string|null
  validationScheduledAt: string|null
  executedAt: string|null
  minor: boolean
  guardianship: boolean
  notFrench: boolean
  ppe: boolean
  ter: boolean
  recurrent: boolean
  dividendReinvestment: boolean
  counterpartyResearch: boolean
  duration: number|null
  lifeAnnuity: boolean
  shareCount: number|null
  sharePrice: number|null
  totalAmount: number|null
  status: string|null
  propertyType: string|null
  possessionMode: string|null
  subscriptionCode: string|null
  investors: Investor[]|null
  recurrence: Recurrence|null
  product: Product|null
  tags: []
  investment50k: boolean
  status_label: string|null
  horodatage: string|null
  transactionCode: string|null
  user: User|null
  dateStartMandat: string|null
  dateEndMandat: string|null
  lastUpdateUser: User|null
  actionReason: string|null
  comment: string|null
  note: string|null
}

interface FilterDismenbermentInterface {
  name: string
  keywords: string
}

class Dismemberment implements DismembermentInterface {
  id: string|null
  createdAt: string|null
  updatedAt: string|null
  subscribedAt: string|null
  deletedAt: string|null
  engagedAt: string|null
  canceledAt: string|null
  verifiedLevel1At: string|null
  verifiedLevel2At: string|null
  confirmedAt: string|null
  validationScheduledAt: string|null
  executedAt: string|null
  minor: boolean
  guardianship: boolean
  notFrench: boolean
  ppe: boolean
  ter: boolean
  recurrent: boolean
  dividendReinvestment: boolean
  counterpartyResearch: boolean
  duration: number|null
  lifeAnnuity: boolean
  shareCount: number|null
  sharePrice: number|null
  totalAmount: number|null
  status: string|null
  propertyType: string|null
  possessionMode: string|null
  subscriptionCode: string|null
  investors: Investor[]|null
  recurrence: Recurrence|null
  product: Product|null
  tags: []
  investment50k: boolean
  status_label: string|null
  horodatage: string|null
  transactionCode: string|null
  user: User|null
  dateStartMandat: string|null
  dateEndMandat: string|null
  lastUpdateUser: User|null
  actionReason: string|null
  comment: string|null
  note: string|null

  constructor(
    id: string|null,
    createdAt: string|null,
    updatedAt: string|null,
    subscribedAt: string|null,
    deletedAt: string|null,
    engagedAt: string|null,
    canceledAt: string|null,
    verifiedLevel1At: string|null,
    verifiedLevel2At: string|null,
    confirmedAt: string|null,
    validationScheduledAt: string|null,
    executedAt: string|null,
    minor: boolean,
    guardianship: boolean,
    notFrench: boolean,
    ppe: boolean,
    ter: boolean,
    recurrent: boolean,
    dividendReinvestment: boolean,
    counterpartyResearch: boolean,
    duration: number|null,
    lifeAnnuity: boolean,
    shareCount: number|null,
    sharePrice: number|null,
    totalAmount: number|null,
    status: string|null,
    propertyType: string|null,
    possessionMode: string|null,
    subscriptionCode: string|null,
    investors: Investor[]|null,
    recurrence: Recurrence|null,
    product: Product|null,
    tags: [],
    investment50k: boolean,
    status_label: string|null,
    horodatage: string|null,
    transactionCode: string|null,
    user: User|null,
    dateStartMandat: string|null,
    dateEndMandat: string|null,
    lastUpdateUser: User|null,
    actionReason: string|null,
    comment: string|null,
    note: string|null
  ) {
    this.id = id
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.subscribedAt = subscribedAt
    this.deletedAt = deletedAt
    this.engagedAt = engagedAt
    this.canceledAt = canceledAt
    this.verifiedLevel1At = verifiedLevel1At
    this.verifiedLevel2At = verifiedLevel2At
    this.confirmedAt = confirmedAt
    this.validationScheduledAt = validationScheduledAt
    this.executedAt = executedAt
    this.minor = minor
    this.guardianship = guardianship
    this.notFrench = notFrench
    this.ppe = ppe
    this.ter = ter
    this.recurrent = recurrent
    this.dividendReinvestment = dividendReinvestment
    this.counterpartyResearch = counterpartyResearch
    this.duration = duration
    this.lifeAnnuity = lifeAnnuity
    this.shareCount = shareCount
    this.sharePrice = sharePrice
    this.totalAmount = totalAmount
    this.status = status
    this.propertyType = propertyType
    this.possessionMode = possessionMode
    this.subscriptionCode = subscriptionCode
    this.investors = investors
    this.recurrence = recurrence
    this.product = product
    this.tags = tags
    this.investment50k = investment50k
    this.status_label = status_label
    this.horodatage = horodatage
    this.transactionCode = transactionCode
    this.user = user
    this.dateStartMandat = dateStartMandat
    this.dateEndMandat = dateEndMandat
    this.lastUpdateUser = lastUpdateUser
    this.actionReason = actionReason
    this.comment = comment
    this.note = note
  }
}

export {Dismemberment}
export type {DismembermentInterface, FilterDismenbermentInterface}
