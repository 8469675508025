import {FunctionComponent} from 'react'

type Props = {
  color?: string
}

const Subscription: FunctionComponent<Props> = ({color}) => {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.8757 0.140015H5.59942C3.02602 0.140015 0.932861 2.2335 0.932861 4.80657V38.7061C0.932861 41.2795 3.02602 43.3726 5.59942 43.3726H31.8757C34.4491 43.3726 36.5422 41.2795 36.5422 38.7061V24.4972L40.7698 14.0274L40.7734 14.0289C40.8552 14.0618 40.9403 14.077 41.0234 14.077C41.2906 14.077 41.5432 13.9174 41.6494 13.6548L42.9325 10.4805C43.7512 8.46317 42.7755 6.15298 40.7536 5.3297C39.2657 4.73389 37.6292 5.10644 36.5422 6.14143V4.80657C36.5422 2.2335 34.4491 0.140015 31.8757 0.140015ZM35.1912 38.7061C35.1912 40.534 33.7036 42.0216 31.8757 42.0216H5.59942C3.77145 42.0216 2.28388 40.534 2.28388 38.7061V4.80657C2.28388 2.97828 3.77145 1.49103 5.59942 1.49103H31.8757C33.7036 1.49103 35.1912 2.97828 35.1912 4.80657V8.5419L34.3178 10.6955C34.2505 10.8617 34.2518 11.0478 34.3218 11.2127C34.3917 11.3783 34.5243 11.5082 34.6905 11.5755L34.699 11.5789L28.551 26.768C28.5505 26.7692 28.5494 26.77 28.5489 26.7712C28.4803 26.9408 28.3767 28.6734 27.7956 35.6623C27.7718 35.9565 27.942 36.2323 28.2158 36.3431C28.2982 36.3767 28.384 36.3926 28.4691 36.3926C28.667 36.3926 28.8603 36.3055 28.9909 36.1458C34.9299 28.9057 34.5744 29.3713 34.6298 29.2338C34.63 29.2333 34.6299 29.2329 34.6301 29.2325C34.6302 29.2322 34.6304 29.232 34.6305 29.2318L35.1912 27.8431V38.7061H35.1912ZM29.7762 27.9967L32.8957 29.2603L29.3181 33.6186L29.7762 27.9967ZM33.6306 28.1006L30.055 26.6523L35.9516 12.0841L39.5165 13.5219C38.0902 17.0549 35.0904 24.4848 33.6306 28.1006ZM40.2483 6.58242C41.5769 7.12336 42.2194 8.64457 41.6805 9.9725V9.97316L40.6487 12.5255L40.6479 12.5251C40.6477 12.525 40.6476 12.5249 40.6474 12.5248C40.6472 12.5247 40.6471 12.5247 40.6468 12.5247C40.2918 12.3813 35.5412 10.4626 35.8239 10.5768L36.8609 8.01985C37.3945 6.69391 38.9151 6.05138 40.2483 6.58242Z" fill={color || 'white'}/>
            <path d="M7.23917 8.47095H28.4689C28.8423 8.47095 29.1444 8.16882 29.1444 7.79544C29.1444 7.42207 28.8423 7.11993 28.4689 7.11993H7.23917C6.86579 7.11993 6.56366 7.42207 6.56366 7.79544C6.56366 8.16882 6.86579 8.47095 7.23917 8.47095Z" fill={color || 'white'} />
            <path d="M7.23917 15.4518H28.4689C28.8423 15.4518 29.1444 15.1497 29.1444 14.7763C29.1444 14.4029 28.8423 14.1008 28.4689 14.1008H7.23917C6.86579 14.1008 6.56366 14.4029 6.56366 14.7763C6.56366 15.1497 6.86579 15.4518 7.23917 15.4518Z" fill={color || 'white'} />
            <path d="M26.8375 21.0807H7.23917C6.86579 21.0807 6.56366 21.3828 6.56366 21.7562C6.56366 22.1296 6.86579 22.4317 7.23917 22.4317H26.8375C27.2109 22.4317 27.513 22.1296 27.513 21.7562C27.513 21.3828 27.2109 21.0807 26.8375 21.0807Z" fill={color || 'white'} />
            <path d="M24.4983 28.0606H7.23917C6.86579 28.0606 6.56366 28.3627 6.56366 28.7361C6.56366 29.1095 6.86579 29.4116 7.23917 29.4116H24.4983C24.8717 29.4116 25.1738 29.1095 25.1738 28.7361C25.1738 28.3627 24.8717 28.0606 24.4983 28.0606Z" fill={color || 'white'} />
            <path d="M24.4983 35.0419H7.23917C6.86579 35.0419 6.56366 35.3441 6.56366 35.7174C6.56366 36.0908 6.86579 36.3929 7.23917 36.3929H24.4983C24.8717 36.3929 25.1738 36.0908 25.1738 35.7174C25.1738 35.3441 24.8717 35.0419 24.4983 35.0419Z" fill={color || 'white'} />
        </svg>
    )
}

export default Subscription
