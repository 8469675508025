import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../store/hook";
import HeaderPage from "../../../component/HeaderPage/HeaderPage";
import TabCustomer from "../../../component/Tab/Customer/TabCustomer";
import PledgeOrganization from "../../../component/Form/Customer/PledgeOrganization/PledgeOrganization";
import AddPledgeOrganization from "../../../component/Sidebar/Customer/PledgeOrganization/AddPledgeOrganization";
import AddCityForm from "../../../component/Sidebar/Referentiel/AddCityForm";

const PledgeOrganizationDashboard: FunctionComponent = () => {
  const {t} = useTranslation()
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)

  return (
    <>
      <AddPledgeOrganization />
      <AddCityForm />
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        <HeaderPage title={t('account.customer')}/>
        <TabCustomer />
        <PledgeOrganization />
      </main>
    </>
  )
}

export default PledgeOrganizationDashboard
