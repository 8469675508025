import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {v4 as uuidV4} from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../store/hook'
import {setCountTotal} from '../../../store/component/product'
import {ListRequest} from '../../../../useCase/CommissioningSession/List/ListRequest'
import ListUseCase from '../../../../useCase/CommissioningSession/List/ListUseCase'
import Pagination from '../Pagination/Pagination'
import download from '../../util/Download'

import iconHeadingSearch from '../../../../assets/images/icons/datalist-heading-search.svg'
import iconSearch from '../../../../assets/images/icons/datalist-search.svg'
import iconEdit from '../../../../assets/images/icons/datalist-edit.svg'
import '../../../../assets/styles/components/_datalist.scss'
import {saveNbRowsInLocalStorage} from "../../util/SavePreferencesInLocalStorage";
import {optionsNbRows} from "../../../../fixtures/Referentiel";
import SelectCustom from "../Elements/Select";
import {SortInterface, SortOrder} from "../../../../domain/Utils/List";
import MultiSelectCustom from "../Elements/MultiSelect";
import {
  ReferentielInterface
} from '../../../../domain/Referentiel/ReferentielInterface'
import TableHead from "../Table/TableHead";
import {CallerType} from "../../../../domain/Caller/Caller";
import CommissioningSessionListPresenter from "../../../../presenter/Commissioning/CommissioningSessionListpresenter";
import {CommissioningSessionItemInterface} from "../../../../domain/Commissioning/CommissioningSessionItemInterface";
import {setOpenDatalistFilterCommissioningSession} from "../../../store/component/event";
import CommissioningSessionGateway from "../../../../gateway/Commissioning/CommissioningSessionGateway";
import {
  CommissioningSessionListInterface,
  FilterCommissioningSessionInterface
} from "../../../../domain/Commissioning/CommissioningSessionList";
import {CommissioningSessionInterface} from "../../../../domain/Commissioning/CommissioningList";

interface ViewModelInterface {
  title: string
  heading: []
  data: CommissioningSessionItemInterface[] | null
  session: CommissioningSessionInterface | null
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

type Props = {
  sessionId: string
  callerType?: CallerType,
}

const CommissioningSessionDatalist: FunctionComponent<Props> = ({sessionId, callerType}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const commissioningRules = useAppSelector((state) => state.me.me?.rules.commissioning.actions)
  const referential: ReferentielInterface | null = useAppSelector(({referential}) => referential.referential)
  const openDatalistFilterCommissioningSession = useAppSelector((state) => state.event.openDatalistFilterCommissioningSession)
  const [viewModel, setViewModel] = useState<ViewModelInterface | null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)
  const [clearMultiSelectValues, setClearMultiSelectValues] = useState<boolean>(false);
  const controller = new AbortController()

  const preferences = localStorage.getItem("preferences")

  const {register, handleSubmit, setValue, watch, control} = useForm()

  const watchNumberRows = watch('numberRows')

  useEffect(() => {
    if (!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      const listRequest = new ListRequest(currentPage, watchNumberRows || 50, openDatalistFilterCommissioningSession.filters, sessionId)
      const session = new ListUseCase(new CommissioningSessionGateway())
        .execute(listRequest, controller.signal, sortOrder.sortLabel ?? '', sortOrder.sortOrder, callerType)
        .then(response => {
          return response
        });
      const presenter = new CommissioningSessionListPresenter(session);
      presenter.load().then(() => {
        setViewModel(presenter.immutableViewModel())
        dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems))
      })

      setValue('name', openDatalistFilterCommissioningSession.filters.name)
      setValue('keywords', openDatalistFilterCommissioningSession.filters.keywords)
    }

  }, [currentPage, openDatalistFilterCommissioningSession.filters, watchNumberRows])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterCommissioningSession.filters])

  const onSubmit: SubmitHandler<FilterCommissioningSessionInterface> = data => {
    setClearMultiSelectValues(false)
    dispatch(setOpenDatalistFilterCommissioningSession({
      show: false,
      count: openDatalistFilterCommissioningSession.count,
      filters: {
        name: data.name,
        keywords: data.keywords,
        status: data.status
      }
    }))
  }

  const paginate = (pageNumber: number) => {
    controller.abort();
    setCurrentPage(pageNumber)
  }

  const handleClickFilter = (response: string) => {
    if (response) {
      dispatch(setOpenDatalistFilterCommissioningSession({
        show: true,
        count: openDatalistFilterCommissioningSession.count,
        filters: openDatalistFilterCommissioningSession.filters
      }))
    }
  }

  const resetFilters = () => {
    setClearMultiSelectValues(true)
    dispatch(setOpenDatalistFilterCommissioningSession({
      show: false,
      count: 0,
      filters: {
        name: '',
        keywords: '',
        status: []
      }
    }))
  }

  const handleClickExport = () => {
    setLoadingExport(true)
    new CommissioningSessionGateway().getExport(openDatalistFilterCommissioningSession.filters).then(response => {
      if (response) {
        download(t('export.products'), response)
        setLoadingExport(false)
      }
    })
  }

  return (<>
      {(viewModel !== null &&
          <>
              <div className={`datalist`}>
                  <div className="datalist__title">{t(viewModel.title)}</div>
                  <div className="datalist__header flex items-center justify-between">
                      <form onSubmit={handleSubmit(onSubmit)} className="">
                        {viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                          <div key={uuidV4()} className="no-border">
                            <img src={iconHeadingSearch} alt=""/>
                            <input {...register(filter.field)}
                                   placeholder={t('common.search-by', {keyword: t(filter.keyword)})}
                                   className="u-mxs"/>
                          </div>
                        ))}
                      </form>
                      <MultiSelectCustom
                          id="status"
                          name="status"
                          classes=""
                          control={control}
                          label={t("filters.display-status")}
                          options={referential?.product.status || []}
                          customOnChange={(options) => {
                            setValue('status', options)
                          }}
                          defaultValue={/* openDatalistFilterCommissioningSession.filters.status */ undefined}
                          clearValues={clearMultiSelectValues}
                      />
                      <div className="filter__actions">
                          <button type="submit" className="button button--submit">{t('search.submit')}</button>
                          <button type="button" className="button button--white"
                                  onClick={() => resetFilters()}>{t('search.cancel')}</button>
                          <button type="button" className="button button--white"
                                  onClick={() => handleClickExport()}>{t('common.export-xls')}</button>
                      </div>
                  </div>
                  <SelectCustom classes="flex justify-end u-mbs" id="numberRows"
                                name="numberRows"
                                label={t('filters.display-results-by')}
                                options={optionsNbRows}
                                register={register}
                                noChoiceOption
                  />
                  <div className="table-fix-head">
                      <table className="datalist__datas">
                          <thead>
                          <tr>
                              <th colSpan={16}/>
                              <th colSpan={6} className={"u-txt-center ceil-variant-light-blue"}>Niveau 1</th>
                              <th colSpan={6} className={"u-txt-center ceil-variant-light-orange"}>Niveau 2</th>
                              <th colSpan={6} className={"u-txt-center ceil-variant-light-blue "}>Niveau 3</th>
                              <th colSpan={6} className={"u-txt-center ceil-variant-light-orange"}>Niveau 4</th>
                          </tr>
                          {viewModel.heading && <TableHead typeFilter={"API"}
                                                           heading={viewModel.heading}
                                                           sortOrder={sortOrder}
                                                           setSortOrder={setSortOrder}
                                                           viewModel={viewModel}
                                                           setViewModel={setViewModel}
                                                           filter={openDatalistFilterCommissioningSession.filters}
                                                           watchNumberRows={watchNumberRows}
                                                           currentPage={currentPage}
                                                           listRequest={ListRequest}
                                                           listUseCase={ListUseCase}
                                                           listPresenter={CommissioningSessionListPresenter}
                                                           gateway={CommissioningSessionGateway}
                                                           investorId={sessionId}
                          />}
                          </thead>
                          <tbody>
                          {viewModel.data?.map((item: CommissioningSessionItemInterface) => (
                            <tr key={uuidV4()}>
                              {item !== null && item !== undefined &&
                                  <>
                                      <td>
                                        {commissioningRules?.read &&
                                            <Link to={`/${t('url.product.read')}/${Object.entries(item)[0][1]}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                            >
                                                <button type="button" className="button-reset">
                                                    <img src={iconSearch} alt=""/>
                                                </button>
                                            </Link>}
                                        {commissioningRules?.update &&
                                            <Link to={`/${t('url.product.edit')}/${Object.entries(item)[0][1]}`}>
                                                <button type="button" className="button-reset">
                                                    <img src={iconEdit} alt=""/>
                                                </button>
                                            </Link>}
                                      </td>
                                    {/* Object.entries(item).map((obj) => {
                                      if (obj[0] !== 'id' && obj[0] !== 'subproduct') {
                                        return <td key={uuidV4()}>{obj[1]}</td>
                                      }
                                    }) */}
                                      <td>{viewModel?.session?.type}</td>
                                      <td>{item.investor.code}</td>
                                      <td>{`${item.investor.firstname} / ${item.investor.lastname}`}</td>
                                      <td>{item.productAcronyme}</td>
                                      <td>{item.propertyType}</td>
                                      <td>{item.createdAt}</td>
                                      <td>{item.transactionCode}</td>
                                      <td>{item.transactionType}</td>
                                      <td>{item.shareCount}</td>
                                      <td>{item.sharePrice}</td>
                                      <td>{"quote part"}</td>
                                      <td>{"montant investissement"}</td>
                                      <td>{"code du partenaire commissionné"}</td>
                                      <td>{"Type de barème"}</td>
                                      <td>{"Montant de base"}</td>

                                      <td className="ceil-variant-light-blue">{item.partnerLevel["1"].code}</td>
                                      <td className="ceil-variant-light-blue">{"nom Partenaire"}</td>
                                      <td className="ceil-variant-light-blue">{item.partnerLevel["1"].taux}</td>
                                      <td className="ceil-variant-light-blue">{item.partnerLevel["1"].montant}</td>
                                      <td className="ceil-variant-light-blue">{item.partnerLevel["1"].tva}</td>
                                      <td className="ceil-variant-light-blue">{item.partnerLevel["1"].ttc}</td>

                                      <td className="ceil-variant-light-orange">{item.partnerLevel["2"].code}</td>
                                      <td className="ceil-variant-light-orange">{"nom Partenaire"}</td>
                                      <td className="ceil-variant-light-orange">{item.partnerLevel["2"].taux}</td>
                                      <td className="ceil-variant-light-orange">{item.partnerLevel["2"].montant}</td>
                                      <td className="ceil-variant-light-orange">{item.partnerLevel["2"].tva}</td>
                                      <td className="ceil-variant-light-orange">{item.partnerLevel["2"].ttc}</td>

                                      <td className="ceil-variant-light-blue">{item.partnerLevel["3"].code}</td>
                                      <td className="ceil-variant-light-blue">{"nom Partenaire"}</td>
                                      <td className="ceil-variant-light-blue">{item.partnerLevel["3"].taux}</td>
                                      <td className="ceil-variant-light-blue">{item.partnerLevel["3"].montant}</td>
                                      <td className="ceil-variant-light-blue">{item.partnerLevel["3"].tva}</td>
                                      <td className="ceil-variant-light-blue">{item.partnerLevel["3"].ttc}</td>

                                      <td className="ceil-variant-light-orange">{item.partnerLevel["4"].code}</td>
                                      <td className="ceil-variant-light-orange">{"nom Partenaire"}</td>
                                      <td className="ceil-variant-light-orange">{item.partnerLevel["4"].taux}</td>
                                      <td className="ceil-variant-light-orange">{item.partnerLevel["4"].montant}</td>
                                      <td className="ceil-variant-light-orange">{item.partnerLevel["4"].tva}</td>
                                      <td className="ceil-variant-light-orange">{item.partnerLevel["4"].ttc}</td>

                                      <td>{"Date de calcul"}</td>
                                  </>
                              }
                            </tr>
                          ))}
                          {viewModel.data?.length === 0 &&
                              <tr>
                                  <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
                              </tr>
                          }
                          </tbody>
                      </table>
                  </div>
              </div>
              <Pagination currentPage={currentPage} itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage}
                          numberOfItems={viewModel.pagination.numberOfItems} callback={paginate}/>
          </>
      )}
    </>
  )
}

export default CommissioningSessionDatalist
