import { FunctionComponent, useEffect, useState } from 'react'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import {useAppDispatch, useAppSelector} from '../../../store/hook'
import {setOpenMainNavigationEvent} from '../../../store/component/event'
import arrowBottomIcon from '../../../../assets/images/icons/arrow-bottom.svg'
import introIcon from '../../../../assets/images/icons/intro.svg'

import '../../../../assets/styles/components/_header.scss'
import { setBubbleAnimation } from '../../../store/component/me'
import GlobalSearchBar from "../Search/GlobalSearchBar";

const Header: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const referential = useAppSelector(({referential}) => referential.referential)
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const [accountSubMenu, setAccountSubMenu] = useState<boolean>(false)

  return (
    <div className="header">
      <div className={`header__burger ${openMainNavigation ? 'header__burger--opened' : ''}`}>
        <button className="menu"
                aria-label="Main Menu"
                onClick={() => dispatch(setOpenMainNavigationEvent(!openMainNavigation))}>
          <svg width="100" height="100" viewBox="0 0 100 100">
            <path className="line line--first"
                  d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
            <path className="line line--second" d="M 20,50 H 80" />
            <path className="line line--third"
                  d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
          </svg>
        </button>
      </div>
      <div className="header__intro">
        <button className="button" onClick={() => dispatch(setBubbleAnimation(true))}>
          <img src={introIcon} alt="Intro" />
          <span>{t('common.intro')}</span>
        </button>
      </div>
      <div className="header__utils">
        {/*<NavLink to={``} className="icon"><img src={inboxIcon} alt="" /></NavLink>*/}
        {/*<NavLink to={``} className="icon"><img src={outboxIcon} alt="" /></NavLink>*/}
        {/*<NavLink to={``} className="icon"><img src={ringIcon} alt="" /></NavLink>*/}
        {/*<NavLink to={``} className="icon"><img src={markRoundedIcon} alt="" /></NavLink>*/}
        {/* referential && <GlobalSearchBar referential={referential}/> */}
      </div>
      <div className="header__account">
        {/*<div className="account__icon"><img src={userPlaceholder} alt="" /></div>*/}
        <div className={`account__chevron ${accountSubMenu ? 'account__chevron--active' : ''}`} onClick={() => setAccountSubMenu(!accountSubMenu)}><img src={arrowBottomIcon} alt="" /></div>
        {accountSubMenu &&
          <div className="account__submenu">
            <NavLink to={`/${t('url.logout')}`} className="item">{t('common.logout')}</NavLink>
          </div>
        }
      </div>
    </div>
  );
}

export default Header
