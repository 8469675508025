import {FilterInterface, SliceListInterface} from '../../../domain/Share/Slice/SliceList'
import SliceGatewayInterface from '../../../domain/Share/Slice/SliceGatewayInterface'
import Caller from '../../../domain/Api/Caller'
import envVariable from '../../../infrastructure/presentation/util/envVariable'

export default class SliceGateway implements SliceGatewayInterface {
  getList(
    investorId: string,
    currentPage: number,
    itemsPerPage: number,
    filter: FilterInterface | null,
    signal?: AbortSignal,
    sort?: string,
    order?: string): Promise<{ data: SliceListInterface[] | null, nb_element: number } | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/investors/${investorId}/slices?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`, {
        'keywords': filter?.keywords || '',
        'product': filter?.productId || '',
        'propertyType': filter?.propertyType || ''
      },
      signal
    ).then(data => {
      if (data && data.data) {
        return data;
      }

      return [];
    })
      .catch(() => {
        return [];
      });
  }
  getListTypeDismemberment(investorId: string,
                           currentPage: number,
                           itemsPerPage: number,
                           filter: FilterInterface|null,
                           signal?: AbortSignal
  ): Promise<{data: SliceListInterface[]|null, nb_element: number}|null>
  {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/investors/${investorId}/slices?filters[propertyType]=${filter?.propertyType}&filters[usufruct_id]=${filter?.usufructId}`, {
      nb_element_per_page: itemsPerPage,
      page: currentPage
    }, signal)
      .then(data => {
        if (data && data.data) {
          return data;
        }

        return [];
      })
      .catch(() => {
        return [];
      });
  }
}
