import {PledgeOrganization, PledgeOrganizationInterface} from "../../domain/Pledge/PledgeOrganization";
import Caller from "../../domain/Api/Caller";
import envVariable from "../../infrastructure/presentation/util/envVariable";
import {
  FilterPledgeOrganizationInterface,
  PledgeOrganizationListInterface
} from "../../domain/Pledge/PledgeOrganizationList";

export default class PledgeOrganizationGateway {
  getExport(filter: FilterPledgeOrganizationInterface|null): Promise<string|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/pledge-organizations/extract`, {
      keywords: filter?.keywords
    })
    .then(data => {
      if (data && data.content) {
        return data.content
      }

      return null
    }).catch(response => {
      return null
    })
  }

  getList(
      currentPage: number,
      itemsPerPage: number,
      filter: FilterPledgeOrganizationInterface|null,
      signal?: AbortSignal,
      sort?: string,
      order?: string
  ): Promise<{data: PledgeOrganizationListInterface[]|null, numberOfItems: number}|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/pledge-organizations?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`, {
      keywords: filter?.keywords
    },
    signal
    ).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    }).catch(response => {
      return {
        data: [],
        numberOfItems: 0
      }
    });
  }

  add(PledgeOrganization: PledgeOrganizationInterface): Promise<string|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/pledge-organizations`, {
      "name": PledgeOrganization.name,
      "status": PledgeOrganization.status,
      "address": PledgeOrganization.address,
      "address2": PledgeOrganization.address2,
      "address3": PledgeOrganization.address3,
      "postcode": PledgeOrganization.postcode,
      "city": PledgeOrganization.city?.id,
      "country": PledgeOrganization.country?.id,
      "bank": PledgeOrganization.bank,
      "iban": PledgeOrganization.iban,
      "swift": PledgeOrganization.swift
    })
    .then(data => {
      if (data.uuid) {
        return data.uuid
      }

      return null
    })
    .catch(err => {
      return null
    })
  }

  get(id: string): Promise<PledgeOrganization|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/pledge-organizations/${id}`, {})
    .then(data => {
      if (data) {
        return data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }

  update(PledgeOrganization: PledgeOrganizationInterface): Promise<string|null> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/pledge-organizations/${PledgeOrganization.id}`, {
      "name": PledgeOrganization.name,
      "status": PledgeOrganization.status,
      "address": PledgeOrganization.address,
      "address2": PledgeOrganization.address2,
      "address3": PledgeOrganization.address3,
      "postcode": PledgeOrganization.postcode,
      "city": PledgeOrganization.city?.id,
      "country": PledgeOrganization.country?.id,
      "bank": PledgeOrganization.bank,
      "iban": PledgeOrganization.iban,
      "swift": PledgeOrganization.swift
    })
    .then(data => {
      if (data.uuid) {
        return data.uuid
      }

      return null
    })
    .catch(err => {
      return null
    })
  }
  getAll(): Promise<any> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/pledge-organizations`, {})
      .then(data => {
        if (data) {
          return data
        }

        return []
      })
      .catch(() => {
        return []
      })
  }
}
