import React, {FunctionComponent, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {v4 as uuidV4} from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../store/hook'
import {setCountTotal} from '../../../store/component/movement'
import {setOpenDatalistFilterMovement} from '../../../store/component/event'
import {
  FilterMovementInterface,
  MovementListInterface
} from '../../../../domain/Movement/MovementList'
import MovementListPresenter from '../../../../presenter/Movement/MovementListPresenter'
import {ListRequest} from '../../../../useCase/Movement/List/ListRequest'
import ListUseCase from '../../../../useCase/Movement/List/ListUseCase'
import Pagination from '../Pagination/Pagination'
import MovementGateway from '../../../../gateway/Movement/MovementGateway'
import download from '../../util/Download'
import HeaderRight from './Element/HeaderRight'

import iconHeadingSearch from '../../../../assets/images/icons/datalist-heading-search.svg'
import iconSearch from '../../../../assets/images/icons/datalist-search.svg'
import iconEdit from '../../../../assets/images/icons/datalist-edit.svg'
import '../../../../assets/styles/components/_datalist.scss'
import {optionsNbRows} from "../../../../fixtures/Referentiel";
import SelectCustom from "../Elements/Select";
import {saveNbRowsInLocalStorage} from "../../util/SavePreferencesInLocalStorage";
import UserDatalist from "./UsersDatalist";
import MovementUtil from '../../util/MovementUtil';
import TransationUtil from "../../../../domain/Utils/TransationUtil";
import {SortInterface, SortOrder} from "../../../../domain/Utils/List";
import {ReferentielInterface} from "../../../../domain/Referentiel/ReferentielInterface";
import MultiSelectCustom from "../Elements/MultiSelect";
import TableHead from "../Table/TableHead";

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

const MovementDatalist: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openDatalistFilterMovement = useAppSelector((state) => state.event.openDatalistFilterMovement)
  const transactionRules = useAppSelector((state) => state.me.me?.rules.transaction.actions)
  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)
  const [clearMultiSelectValues, setClearMultiSelectValues] = useState<boolean>(false);
  const controller = new AbortController()

  const preferences = localStorage.getItem("preferences")

  const { register, control, handleSubmit, setValue, watch } = useForm()

  const watchNumberRows = watch('numberRows')

  const movementPropertyType = useMemo(() => {
    const propertyTypes: { [key: string]: string } = {}

    if (referential?.movement?.property_type) {
      referential.movement.property_type.map(item => {
        propertyTypes[`${item.value}`] = item.label;
      })
    }

    return propertyTypes
  }, [referential?.movement?.property_type])

  const movementTransactionType = useMemo(() => {
    const transactionTypes: { [key: string]: string } = {}

    if (referential?.movement?.transaction_type) {
      referential.movement.transaction_type.map(item => {
        transactionTypes[`${item.value}`] = item.label;
      })
    }

    return transactionTypes
  }, [referential?.movement?.transaction_type])

  useEffect(() => {
    setOpenDatalistFilterMovement( {
      show: false,
      count: 0,
      filters: {
        product: {
          id: '',
          value: '',
          label: '',
        },
        transactionType: '',
        status: [],
        tags: [],
        propertyType: [],
        paymentMode: '',
        user: null,
        investor_id: '',
        name: '',
        keywords: ''
      }
    })
  }, [])

  useEffect(() => {
    if(!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      const listRequest = new ListRequest(currentPage, watchNumberRows || 50, openDatalistFilterMovement.filters)
      const movements = new ListUseCase(new MovementGateway()).execute(listRequest, controller.signal, sortOrder.sortLabel ?? '', sortOrder.sortOrder).then(response => {
        return response
      });
      const presenter = new MovementListPresenter(movements);
      presenter.load().then(() => {
        setViewModel(presenter.immutableViewModel())
        dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems))
      })
      setValue('product', openDatalistFilterMovement.filters.product)
      setValue('transactionType', openDatalistFilterMovement.filters.transactionType)
      setValue('status', openDatalistFilterMovement.filters.status)
      setValue('tags', openDatalistFilterMovement.filters.tags)
      setValue('propertyType', openDatalistFilterMovement.filters.propertyType)
      setValue('paymentMode', openDatalistFilterMovement.filters.paymentMode)
      setValue('user', openDatalistFilterMovement.filters.user)
      setValue('investor_id', openDatalistFilterMovement.filters.investor_id)
    }
  }, [currentPage, openDatalistFilterMovement.filters, watchNumberRows])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterMovement.filters])

  const onSubmit: SubmitHandler<FilterMovementInterface> = data => {
    setClearMultiSelectValues(false)
    dispatch(setOpenDatalistFilterMovement({
        show: false,
        count: openDatalistFilterMovement.count,
        filters: {
          product: data.product,
          transactionType: data.transactionType,
          status: data.status,
          tags: data.tags,
          propertyType: data.propertyType,
          paymentMode: data.paymentMode,
          user: data.user,
          investor_id: data.investor_id,
          name: data.name,
          keywords: data.keywords
        }
      }
    ))
  }

  const paginate = (pageNumber:number) => {controller.abort(); setCurrentPage(pageNumber)}

  const handleClickFilter = (response: string) => {
    if (response) {
      dispatch(setOpenDatalistFilterMovement({show: true, count: openDatalistFilterMovement.count, filters: openDatalistFilterMovement.filters}))
    }
  }

  const resetFilters = () => {
    setClearMultiSelectValues(true)
    dispatch(setOpenDatalistFilterMovement({
        show: false,
        count: 0,
        filters: {
          product: {
            id: '',
            value: '',
            label: '',
          },
          transactionType: '',
          status: [],
          tags: [],
          propertyType: [],
          paymentMode: '',
          user: null,
          investor_id: '',
          name: '',
          keywords: ''
        }
      }
    ))
  }

  const getUrlMovement = (movement: any, mode: string) => {
    let url = ''

    switch (movement.transaction_type) {
      case 'withdrawal':
        url = t(`url.movements.${mode === 'read' ? 'read' : 'edit'}-redemption-withdrawal`);
        break
      case 'agreement':
        url = t(`url.movements.${mode === 'read' ? 'read' : 'edit'}-gre-gre`);
        break
      case 'mutation':
        url = t(`url.movements.${mode === 'read' ? 'read' : 'edit'}-mutations`);
        break
      case 'subscription':
        url = t(`url.pre-subscription.${mode === 'read' ? 'read' : 'edit'}`);
        break
      case 'dismemberment':
        url = t(`url.movements.${mode === 'read' ? 'read' : 'edit'}-dismemberment`);
        break
      case 'foo5':
        url = t('url.movements.edit-land-consolidation');
        break
    }

    return `/${url}/${movement.id}`
  }

  const handleClickExport = () => {
    setLoadingExport(true)
    new MovementGateway().getExport(openDatalistFilterMovement.filters).then(response => {
      if (response) {
        download(t('export.movements'), response)
        setLoadingExport(false)
      }
    })
  }

  return (<>
    {(viewModel !== null &&
      <>
      <div className={`datalist`}>
        <div className="datalist__title">{t(viewModel.title)}</div>
        <div className="datalist__header">
          <form onSubmit={handleSubmit(onSubmit)} className="filter">
            <div className="filter__input flex items-center">
              {viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                <div key={uuidV4()} className="input-no-border">
                  <img src={iconHeadingSearch} alt="" />
                  <input {...register(filter.field)} placeholder={t('common.search-by', {keyword: t(filter.keyword)})} className="u-mxs"/>
                </div>
              ))}
              <MultiSelectCustom
                  id="status"
                  name="status"
                  classes="u-mbs"
                  control={control}
                  label={t("filters.display-status")}
                  options={referential?.global.transaction_status || []}
                  customOnChange={ (options) => {setValue('status', options)}}
                  defaultValue={openDatalistFilterMovement.filters.status}
                  clearValues={clearMultiSelectValues}
              />
              <div className="form-control__input u-mbs">
                <UserDatalist control={control} name="user"/>
              </div>
            </div>
            <div className="filter__actions">
              <button type="submit" className="button button--submit">{t('search.submit')}</button>
              <button type="button" className="button button--white" onClick={() => resetFilters()}>{t('search.cancel')}</button>
            </div>
          </form>
          <HeaderRight numberOfActivatedFilters={openDatalistFilterMovement.count}
                       handleClickFilter={handleClickFilter}
                       handleClickExport={handleClickExport}
                       isLoadingExport={isLoadingExport}
                       allowExport={transactionRules?.export}
          />
        </div>
        <SelectCustom classes="flex justify-end u-mbs" id="numberRows"
                      name="numberRows"
                      label={t('filters.display-results-by')}
                      options={optionsNbRows}
                      register={register}
                      noChoiceOption
        />
        <div className="table-fix-head">
        <table className="datalist__datas">
          <thead>
          {viewModel.heading && <TableHead typeFilter={"API"}
                                           heading={viewModel.heading}
                                           sortOrder={sortOrder}
                                           setSortOrder={setSortOrder}
                                           viewModel={viewModel}
                                           setViewModel={setViewModel}
                                           filter={openDatalistFilterMovement.filters}
                                           watchNumberRows={watchNumberRows}
                                           currentPage={currentPage}
                                           listRequest={ListRequest}
                                           listUseCase={ListUseCase}
                                           listPresenter={MovementListPresenter}
                                           gateway={MovementGateway}
          />}
          </thead>
          <tbody>
          {viewModel.data !== undefined && viewModel.data.map((item: MovementListInterface) => (
            <tr key={uuidV4()}>
              <td>
                {transactionRules?.read && <Link to={getUrlMovement(item, 'read')}
                                                 target="_blank"
                                                 rel="noopener noreferrer"
                >
                    <button type="button" className="button-reset">
                        <img src={iconSearch} alt=""/>
                    </button>
                </Link>}
                {(TransationUtil.isEditable(item) && transactionRules?.update) && <Link to={getUrlMovement(item, 'edit')}>
                    <button type="button" className="button-reset">
                        <img src={iconEdit} alt=""/>
                    </button>
                </Link>}
              </td>
              <td>{item.product_label}</td>
              <td>{item.transaction_type && item.transaction_type in movementTransactionType ? movementTransactionType[item.transaction_type as keyof typeof movementTransactionType]: item.transaction_type}</td>
              <td>{item.code}</td>
              <td>{item.engagedAt}</td>
              <td>{item.status_label}</td>
              <td>{item.property_type && item.property_type in movementPropertyType ? movementPropertyType[item.property_type as keyof typeof movementPropertyType]: item.property_type}</td>
              <td>{item.investor_id}</td>
              <td>{item.investor_name}</td>
              <td>{item.partner_code}</td>
              <td>{item.partner_name}</td>
              <td>
                {item.user?.firstname?.toUpperCase().slice(0, 1)}. {item.user?.lastname?.toUpperCase()}
              </td>
              <td>{item.payment_method}</td>
              <td>{item.payment_status}</td>
              <td>{item.payment_date}</td>
              <td>{item.share_count}</td>
              <td>{item.total_amount}</td>
              <td>
                <div className="u-mys">
                  {item.tags && item.tags.map(tag => {
                    const key = `movement.tags.${tag}`
                    const type = MovementUtil.getType(tag)
                    return <div className={`badge badge--min badge--without-cta status--${type} u-mrs u-mbs`} key={uuidV4()}>
                      <div className="badge__container">
                        <div className="badge__text">{t(key)}</div>
                      </div>
                    </div>
                  })}
                </div>
              </td>
            </tr>
          ))}
          {viewModel.data === undefined || viewModel.data.length === 0 &&
            <tr>
              <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
            </tr>
          }
          </tbody>
        </table>
        </div>
      </div>
      <Pagination currentPage={currentPage} itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage} numberOfItems={viewModel.pagination.numberOfItems} callback={paginate} />
      </>
    )}
    </>
  )
}

export default MovementDatalist
