import {Presenter} from "../Presenter";
import {PledgeOrganizationListInterface} from "../../domain/Pledge/PledgeOrganizationList";

class PledgeOrganizationPresenter extends Presenter {
  private useCase: { getPledgeOrganizations: { data: PledgeOrganizationListInterface[]|null, nb_element:number }}

  constructor(getPledgeOrganizations: any) {
    super({
      viewModel: {
        title: 'account.search-a-customer',
        heading: [
          {name: 'account.heading.Action'},
          {name: 'pledgeorganization.heading.title', sort: 'name'},
          {name: 'pledgeorganization.heading.city', sort: 'ref_city.label'},
          {name: 'pledgeorganization.heading.status', sort: 'status'},
        ],
        datas: [],
        filtersShortcut: [
          {'keyword': 'filters.by-keyword-or-id', 'field': 'keywords', 'type': 'input'},
        ],
        filters: [],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getPledgeOrganizations,
    }
  }

  async load() {
    try {
      this._setPledgeOrganizations(await this.useCase.getPledgeOrganizations);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setPledgeOrganizations(pledgeOrganization: {data: PledgeOrganizationListInterface[]|null, nb_element: number}) {
    this.update({
      data: pledgeOrganization.data,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': pledgeOrganization.nb_element
      }
    })
  }
}

export default PledgeOrganizationPresenter
