import {Presenter} from "../Presenter";
import {ProspectListInterface} from "../../domain/Prospect/ProspectList";
import {PersonListInterface} from "../../domain/Person/PersonList";

class PersonListPresenter extends Presenter {
  private useCase: { getPersons: any}

  constructor(getPersons: any) {
    super({
      viewModel: {
        title: 'person.search-a-person',
        heading: [
          {name: 'common.action'},
          {name: 'person.heading.firstname',sort: 'firstname'},
          {name: 'person.heading.lastname',sort: 'lastname'},
          {name: 'person.heading.birthdate',sort: 'birthdate'},
        ],
        datas: [],
        filtersShortcut: [
          {'keyword': 'filters.by-firstname', 'field': 'firstname', 'type': 'input'},
          {'keyword': 'filters.by-lastname', 'field': 'lastname', 'type': 'input'}
        ],
        filters: [],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getPersons,
    }
  }

  async load() {
    try {
      this._setPersons(await this.useCase.getPersons);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setPersons(persons: { data: PersonListInterface[]|null, numberOfItems: number }) {
    this.update({
      data: persons.data,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': persons.numberOfItems,
      }
    });
  }
}
export default PersonListPresenter
