import React from 'react';
import Header from "../Header/Header";
import SidebarMainNavigation from "../SidebarMainNavigation/SidebarMainNavigation";

const GeneralLayout = () => {
  return (
    <>
      <Header />
      <SidebarMainNavigation />
    </>
  );
}

export default GeneralLayout;