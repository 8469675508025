import {FilterCommissioningSessionInterface} from '../../../domain/Commissioning/CommissioningSessionList'

interface ListRequestInterface {
  currentPage: number
  itemPerPage: number
  filter: FilterCommissioningSessionInterface|null
  sessionId: string
}

class ListRequest implements ListRequestInterface{
  public currentPage: number
  public itemPerPage: number
  public filter: FilterCommissioningSessionInterface|null
  public sessionId: string

  constructor(
    currentPage: number,
    itemPerPage: number,
    filter: FilterCommissioningSessionInterface|null,
    sessionId: string
  ) {
    this.currentPage = currentPage
    this.itemPerPage = itemPerPage
    this.filter = filter
    this.sessionId = sessionId
  }
}

export {
  ListRequest
}
export type { ListRequestInterface }