import React from 'react'

const formatName = (user: any, code: string) => {
  let mainContact = user.persons?.find((person: any) => person.gerant === true)
  if (!mainContact && user.persons?.[0]) {
    mainContact = user.persons[0]
  }

  const lastname = mainContact?.lastname?.toUpperCase() || ''
  const firstname = mainContact?.firstname?.toUpperCase() || ''
  const fullName = lastname + ' ' + firstname
  const mainContactTextIdentity = ` ${fullName}`

  let secondContact = null
  let secondContactTextIdentity = ''

  if (user.persons?.[1] && (user.persons?.[1].firstname || user.persons?.[1].lastname)) {
    secondContact = user.persons[1]
    const lastnameSecondContact = secondContact?.lastname?.toUpperCase() || ''
    const firstnameSecondContact = secondContact?.firstname?.toUpperCase() || ''
    const fullNameSecondContact = lastnameSecondContact + ' ' + firstnameSecondContact
    secondContactTextIdentity = `${fullName.length > 1 ? ' & ' : ''} ${fullNameSecondContact}`
  }
  return mainContactTextIdentity + secondContactTextIdentity + code
}

export default function GetNameForTitle(type: 'prospect' | 'customer', user: any): string {
  if (type === 'customer' && user) {
    const code = user.customerCode ? ` (${user.customerCode})` : ''

    if (user.customerType === 'physical') return formatName(user, code)
    else return user.companyName + code
  } else if (type === 'prospect' && user) {
    const code = user.prospectCode ? ` (${user.prospectCode})` : ''

    if (user.prospectType === 'physical') return formatName(user, code)
    else return user.companyName + code

  } else return ''
}