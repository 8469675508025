import BankInformationGateway from "../../../../gateway/Customer/BankInformation/BankInformationGateway";
import {CustomerInterface} from '../../../../domain/Customer/Customer'
import {BankInformation} from '../../../../domain/Customer/BankInformation'

export default class DeleteUseCase
{
  public bankInformationGateway

  constructor(
    BankInformationGateway: BankInformationGateway
  ) {
    this.bankInformationGateway = BankInformationGateway
  }

  async execute(Customer: CustomerInterface, BankInformation: BankInformation): Promise<boolean> {
    return await this.bankInformationGateway.delete(Customer.id, BankInformation.id)
  }
}
