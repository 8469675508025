import { FunctionComponent, useEffect } from 'react';
import {useTranslation} from 'react-i18next'

import { useAppDispatch, useAppSelector } from '../../../../store/hook';

import HeaderPage from '../../../component/HeaderPage/HeaderPage'
import Tab from '../../../component/Tab/Product/Tab'
import ProductDatalist from '../../../component/Datalist/ProductDatalist'

import '../../../../../assets/styles/page/_product.scss'
import {useNavigate} from "react-router-dom";
import { setOpenDatalistFilterProduct } from '../../../../store/component/event';
import Kpi from "../../../component/Kpi/Kpi";
import ProductGateway from "../../../../../gateway/Product/ProductGateway";

const ProductDashboard: FunctionComponent = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const productRules = useAppSelector((state) => state.me.me?.rules.product)

  if(!productRules?.visible) navigate(`/`)

  useEffect(() => {
    dispatch(setOpenDatalistFilterProduct({show: false, count: 0, filters: {
        name: '',
        keywords: '',
        type: '',
        subproduct: null,
        status: []
      }}))
  }, [dispatch])

  return (
    <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
      <HeaderPage title={t('product.products')}
                  addLink={productRules?.actions?.create ? `/${t('url.product.add-choice-type')}` : undefined}
      />
      <Tab />
      <div className="product-keys-figures">
        <div className="product-keys-figures__title">{t('partner.key-figures')}</div>
        <Kpi getKpi={new ProductGateway().getKpi} />
      </div>
     {productRules?.actions?.list && <ProductDatalist callerType={"module"}/>}
    </main>
  )
}

export default ProductDashboard
