import {FunctionComponent, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDropzone} from 'react-dropzone'

// Notification lib
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import getClassForOverlay from '../../../util/Sidebar'
import blockScrollBody from '../../../util/BlockScroll'
import {setOpenLogoUploadForm} from '../../../../store/component/event'
import Converter from '../../../util/Converter'

import '../../../../../assets/styles/components/_uploader.scss'
import uploadIcon from '../../../../../assets/images/icons/upload-file.png'
import {LogoInterface, LogoToSend} from "../../../../../domain/Settings/GeneralSettings/Logo/Logo";
import LogoGateway from "../../../../../gateway/Settings/GeneralSettings/Logo/LogoGateway";
import {toastError, toastSuccess} from '../../../util/Toast'
import Spinner from "../../Spinner/Spinner";

const Upload: FunctionComponent = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const [isUploading, setIsUploading] = useState(false);

    const openLogoUploadForm = useAppSelector((state) => state.event.openLogoUploadForm)
    const [logo] = useState<LogoInterface | null>(openLogoUploadForm.logo)

    useEffect(() => {
        blockScrollBody(openLogoUploadForm.show)
        setIsUploading(false)
    }, [openLogoUploadForm.show])

    function handleClose() {
        dispatch(setOpenLogoUploadForm({show: false, logo: null, type: null}))
    }

    const onDrop = useCallback(acceptedFiles => {
        const promises: Promise<any>[] = []
        acceptedFiles.map((file: File) => {
            promises.push(new Converter().toBase64(file).then(base64 => {
                if (typeof base64 === 'string') {
                    return new LogoToSend(
                        base64,
                        file.name,
                        openLogoUploadForm.type || ''
                    )
                }
            }))
        })
        setIsUploading(true);

        Promise.all(promises).then(logo => {
            if (logo) {
                new LogoGateway().save(logo).then(response => {
                    if (null !== response) {
                        toastSuccess(t('common.doc-add-success'))
                        handleClose()
                    } else {
                        toastError(t('common.doc-add-error'));
                    }
                })
                setIsUploading(false);

            }
        })

    }, [openLogoUploadForm])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop
    })

    const removeFile = (logoToRemove: LogoInterface | null) => () => {
        console.log('remove')
    }

    const show = (documentToShow: any) => () => {
        console.log(documentToShow)
    }

    return (
        <>
            <div className={`overlay ${getClassForOverlay(openLogoUploadForm.show)}`}
                 onClick={() => handleClose()}/>
            <div
                className={`sidebar sidebar--right sidebar--right ${openLogoUploadForm.show ? 'sidebar--active' : ''}`}>
                <div className="form-bloc form-bloc--partner-address-form">
                    <div className="sidebar__content">
                        <div className="title">{t('common.add-document')}</div>
                        <div className="form-bloc__form">
                            <div className="container">
                                <div {...getRootProps()}
                                     className={`uploader ${isDragActive ? 'dzu-dropzoneActive' : ''}`}>
                                    <input {...getInputProps()} />

                                    <div><img src={uploadIcon} alt="upload"/></div>
                                    <p className="u-ptm">{t('common.upload-file')}</p>
                                </div>
                                {isUploading &&
                                  <div className="u-mam">
                                      <Spinner />
                                  </div>
                                }
                            </div>
                        </div>
                        <div className="">
                            <div className="dzu-previewContainer" key={logo?.id}>
                                <div className="upload-progress__wrapper">
                                    <div
                                        className="button button-primary--outline button--small button--width-fit button--height-fit"
                                        onClick={show(logo)}>{logo?.title}</div>
                                    <div
                                        className="button button-error--outline button--small button--width-fit button--height-fit"
                                        onClick={removeFile(logo)}>{t('button.remove')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="sidebar__footer">
                        <button type="submit"
                                className="button button--ink-2 u-mrm">{t('common.save')}</button>
                        <button type="button"
                                className="button button--ink-2 button--ink-2--outline"
                                onClick={() => handleClose()}>{t('common.cancel')}</button>
                    </footer>
                </div>
            </div>
        </>
    )
}

export default Upload
