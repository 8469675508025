import {useAppSelector} from "../../infrastructure/store/hook";
import {useEffect, useState} from "react";

type TRoleToCheck = "V2"

export const useCheckRole = (roleToCheck: TRoleToCheck): boolean => {
  const rolesLabel = useAppSelector((state) => state.me.me?.rolesLabel)
  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {
    if(rolesLabel) {
      checkRole()
    }
  }, [rolesLabel, isAuthorized])

  const checkRole = () => {
    let arrayRolesToCheck: string[] = []
    if(roleToCheck === "V2") {
      arrayRolesToCheck = ["BO V2", "MO V2", "ROLE_SUPER_ADMIN"]
    }
    if(rolesLabel && arrayRolesToCheck.length) {
      const roleFounded = rolesLabel.some((role) => arrayRolesToCheck.includes(role))
      setIsAuthorized(roleFounded)
    } else setIsAuthorized(false)
  }

  return isAuthorized
}