import MovementGatewayInterface from '../../../domain/Movement/MovementGatewayInterface'
import {MovementListInterface} from '../../../domain/Movement/MovementList'
import {ListRequestInterface} from './ListRequest'

export default class ListUseCase
{
  public movementGateway

  constructor(MovementGateway: MovementGatewayInterface) {
    this.movementGateway = MovementGateway
  }

  async execute(ListRequest: ListRequestInterface, signal?: AbortSignal, sort?: string, order?: string): Promise<{data: MovementListInterface[]|null, numberOfItems: number}|null> {
    return await this.movementGateway.getList(ListRequest.currentPage, ListRequest.itemPerPage, ListRequest.filter, signal, sort, order).then(response => response)
  }
}
