import {FunctionComponent, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDropzone} from 'react-dropzone'
import { v4 as uuidV4 } from 'uuid'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import getClassForOverlay from '../../../util/Sidebar'
import blockScrollBody from '../../../util/BlockScroll'
import {setOpenPartnerUploadForm} from '../../../../store/component/event'
import {DocumentInterface, DocumentToSend} from '../../../../../domain/Partner/Document/Document'
import Converter from '../../../util/Converter'
import DocumentGateway from '../../../../../gateway/Partner/Document/DocumentGateway'

import '../../../../../assets/styles/components/_uploader.scss'
import uploadIcon from '../../../../../assets/images/icons/upload-file.png'
import {toastError, toastSuccess} from "../../../util/Toast";
import Spinner from "../../Spinner/Spinner";

const Upload: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const [isUploading, setIsUploading] = useState(false);

  const openPartnerUploadForm = useAppSelector((state) => state.event.openPartnerUploadForm)
  const [documents] = useState<DocumentToSend[]|null>(openPartnerUploadForm.documents)

  useEffect(() => {
    blockScrollBody(openPartnerUploadForm.show)
    setIsUploading(false)
  }, [openPartnerUploadForm.show])

  function handleClose() {
    dispatch(setOpenPartnerUploadForm({show: false, documents: null, partnerId: null, type: null}))
  }

  const onDrop = useCallback(acceptedFiles => {
    const promises: Promise<any>[] = []
    acceptedFiles.map((file: File) => {
      promises.push(new Converter().toBase64(file).then(base64 => {
        if (typeof base64 === 'string') {
          return new DocumentToSend(
            base64,
            file.name,
            openPartnerUploadForm.type || ''
          )
        }
      }))
    })
    setIsUploading(true);

    Promise.all(promises).then(documents => {
      if (documents.length > 0) {
        if (!openPartnerUploadForm.conventionId) {
          new DocumentGateway(openPartnerUploadForm.partnerId).save(openPartnerUploadForm.partnerId || '', documents).then(response => {
            if (null !== response) {
              toastSuccess(t('common.doc-add-success'))
              dispatch(setOpenPartnerUploadForm({
                show: false,
                documents: documents,
                partnerId: openPartnerUploadForm.partnerId,
                type: openPartnerUploadForm.type
              }))
            } else {
              toastError(t('common.doc-add-error'));
            }
            setIsUploading(false);
          })
        } else {
          new DocumentGateway(openPartnerUploadForm.partnerId).saveConvention(openPartnerUploadForm.partnerId || '', openPartnerUploadForm.conventionId || '', documents).then(response => {
            if (null !== response) {
              toastSuccess(t('common.doc-add-success'))
              dispatch(setOpenPartnerUploadForm({
                show: false,
                documents: documents,
                partnerId: null,
                type: null,
                conventionId: null
              }))
            } else {
              toastError(t('common.doc-add-error'));
            }
            setIsUploading(false);
          })
        }
      }
    })

  }, [openPartnerUploadForm])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop
  })

  const removeFile = (documentToRemove: DocumentToSend) => () => {
    console.log('remove')
  }

  const show = (documentToShow: any) => () => {
    console.log(documentToShow)
  }

  return (
    <>
        <div className={`overlay ${getClassForOverlay(openPartnerUploadForm.show)}`} onClick={() => handleClose()} />
        <div
          className={`sidebar sidebar--right sidebar--right ${openPartnerUploadForm.show ? 'sidebar--active' : ''}`}>
          <div className="form-bloc form-bloc--partner-address-form">
            <div className="sidebar__content">
              <div className="title">{t('common.add-document')}</div>
              <div className="form-bloc__form">
                <div className="container">
                  <div {...getRootProps()} className={`uploader ${isDragActive ? 'dzu-dropzoneActive' : ''}`}>
                    <input {...getInputProps()} />

                    <div><img src={uploadIcon} alt="upload" /></div>
                    <p className="u-ptm">{t('common.upload-file')}</p>
                  </div>
                  {isUploading &&
                      <div className="u-mam">
                          <Spinner />
                      </div>
                  }
                </div>
              </div>
              <div className="">
                {(documents &&
                  (documents.map(document => (
                      <div className="dzu-previewContainer" key={uuidV4()}>
                        <div className="upload-progress__wrapper">
                          <div
                            className="button button-primary--outline button--small button--width-fit button--height-fit"
                            onClick={show(document)}>{document.title}</div>
                          <div
                            className="button button-error--outline button--small button--width-fit button--height-fit"
                            onClick={removeFile(document)}>{t('button.remove')}
                          </div>
                        </div>
                      </div>
                    ))
                  )
                )}
              </div>
            </div>
            <footer className="sidebar__footer">
              <button type="submit" className="button button--ink-2 u-mrm">{t('common.save')}</button>
              <button type="button" className="button button--ink-2 button--ink-2--outline"
                      onClick={() => handleClose()}>{t('common.cancel')}</button>
            </footer>
          </div>
        </div>
    </>
  )
}

export default Upload
