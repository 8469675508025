import {Presenter} from '../Presenter'
import {BankInformationInterface} from "../../domain/Customer/BankInformation";

class BankInformationListPresenter extends Presenter {

  constructor(getBankInformation: BankInformationInterface[]) {
    super({
      viewModel: {
        title: '',
        heading: [
          {name: 'account.heading.Action'},
          {name: 'account.form.bank-details.main', sort: "main", labelApi: "main"},
          {name: 'account.form.bank-details.type', sort: "type", labelApi: "type"},
          {name: 'account.form.bank-details.flux', sort: "flux", labelApi: "flux"},
          {name: 'account.form.bank-details.owner-name', sort: "owner-name", labelApi: "label"},
          {name: 'account.form.bank-details.associate-products', sort: "associate-products", labelApi: "products"},
          {name: 'account.form.bank-details.bank', sort: "bank", labelApi: "bank"},
          {name: 'account.form.bank-details.iban', sort: "iban", labelApi: "iban"},
          {name: 'account.form.bank-details.swift', sort: "swift", labelApi: "swift"},
        ],
        data: getBankInformation,
        filtersShortcut: [
        ],
        filters: [],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });
  }
}

export default BankInformationListPresenter
