import {FunctionComponent, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDropzone} from 'react-dropzone'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import getClassForOverlay from '../../../util/Sidebar'
import blockScrollBody from '../../../util/BlockScroll'
import {setOpenProspectUploadForm} from '../../../../store/component/event'
import {
  DocumentProspectInterface,
  DocumentProspectToSend
} from '../../../../../domain/Prospect/Document/Document'
import Converter from '../../../util/Converter'
import DocumentGateway from '../../../../../gateway/Prospect/Document/DocumentGateway'

import '../../../../../assets/styles/components/_uploader.scss'
import uploadIcon from '../../../../../assets/images/icons/upload-file.png'
import {toastError, toastSuccess} from "../../../util/Toast";
import Spinner from "../../Spinner/Spinner";

const Upload: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const [isUploading, setIsUploading] = useState(false);

  const openProspectUploadForm = useAppSelector((state) => state.event.openProspectUploadForm)
  const [documents] = useState<DocumentProspectInterface[]|null>(openProspectUploadForm.documents)

  useEffect(() => {
    blockScrollBody(openProspectUploadForm.show)
    setIsUploading(false)
  }, [openProspectUploadForm.show])

  function handleClose() {
    dispatch(setOpenProspectUploadForm({show: false, documents: null, prospectId: null, type: null}))
  }

  const onDrop = useCallback(acceptedFiles => {
    const promises: Promise<any>[] = []
    acceptedFiles.map((file: File) => {
      promises.push(new Converter().toBase64(file).then(base64 => {
        if (typeof base64 === 'string') {
          return new DocumentProspectToSend(
            base64,
            file.name,
            openProspectUploadForm.type || '',
          )
        }
      }))
    })
    setIsUploading(true);

    Promise.all(promises).then(documents => {
      if (documents.length > 0) {
        if (openProspectUploadForm.ribId) {
          new DocumentGateway(openProspectUploadForm.prospectId).saveRib(openProspectUploadForm.prospectId || '', openProspectUploadForm.ribId, documents).then(response => {
            if (null !== response) {
              toastSuccess(t('common.doc-add-success'))
              dispatch(setOpenProspectUploadForm({
                show: false,
                documents: documents,
                prospectId: null,
                type: null,
                ribId: null
              }))
            } else {
              toastError(t('common.doc-add-error'));
            }
            setIsUploading(false);
          })

        } else if (openProspectUploadForm.undividedId) {
          new DocumentGateway(openProspectUploadForm.prospectId).saveUndivided(openProspectUploadForm.prospectId || '', openProspectUploadForm.undividedId, documents).then(response => {
            if (null !== response) {
              toastSuccess(t('common.doc-add-success'))
              dispatch(setOpenProspectUploadForm({
                show: false,
                documents: documents,
                prospectId: null,
                type: null,
                undividedId: null
              }))
            } else {
              toastError(t('common.doc-add-error'));
            }
            setIsUploading(false);
          })
        } else if (openProspectUploadForm.beneficialOwnerId) {
          new DocumentGateway(openProspectUploadForm.prospectId).saveBeneficialOwner(openProspectUploadForm.prospectId || '', openProspectUploadForm.beneficialOwnerId, documents).then(response => {
            if (null !== response) {
              toastSuccess(t('common.doc-add-success'))
              dispatch(setOpenProspectUploadForm({
                show: false,
                documents: documents,
                prospectId: null,
                type: null,
                beneficialOwnerId: null
              }))
            } else {
              toastError(t('common.doc-add-error'));
            }
            setIsUploading(false);
          })
        } else {
          new DocumentGateway(openProspectUploadForm.prospectId).save(openProspectUploadForm.prospectId || '', documents).then(response => {
            if (null !== response) {
              toastSuccess(t('common.doc-add-success'))
              dispatch(setOpenProspectUploadForm({
                show: false,
                documents: documents,
                prospectId: openProspectUploadForm.prospectId,
                type: openProspectUploadForm.type
              }))
            } else {
              toastError(t('common.doc-add-error'));
            }
            setIsUploading(false);
          })
        }
      }
    })

  }, [openProspectUploadForm])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop
  })

  const removeFile = (documentToRemove: DocumentProspectInterface) => () => {
    console.log('remove')
  }

  const show = (documentToShow: any) => () => {
    console.log(documentToShow)
  }

  return (
    <>
        <div className={`overlay ${getClassForOverlay(openProspectUploadForm.show)}`} onClick={() => handleClose()} />
        <div
          className={`sidebar sidebar--right sidebar--right ${openProspectUploadForm.show ? 'sidebar--active' : ''}`}>
          <div className="form-bloc form-bloc--prospect-address-form">
            <div className="sidebar__content">
              <div className="title">{t('common.add-document')}</div>
              <div className="form-bloc__form">
                <div className="container">
                  <div {...getRootProps()} className={`uploader ${isDragActive ? 'dzu-dropzoneActive' : ''}`}>
                    <input {...getInputProps()} />

                    <div><img src={uploadIcon} alt="upload" /></div>
                    <p className="u-ptm">{t('common.upload-file')}</p>
                  </div>
                  {isUploading &&
                      <div className="u-mam">
                          <Spinner />
                      </div>
                  }
                </div>
              </div>
              <div className="">
                {(documents &&
                  (documents.map(document => (
                      <div className="dzu-previewContainer" key={document.id}>
                        <div className="upload-progress__wrapper">
                          <div
                            className="button button-primary--outline button--small button--width-fit button--height-fit"
                            onClick={show(document)}>{document.title}</div>
                          <div
                            className="button button-error--outline button--small button--width-fit button--height-fit"
                            onClick={removeFile(document)}>{t('button.remove')}
                          </div>
                        </div>
                      </div>
                    ))
                  )
                )}
              </div>
            </div>
            <footer className="sidebar__footer">
              <button type="button" className="button button--ink-2 button--ink-2--outline"
                      onClick={() => handleClose()}>{t('common.cancel')}</button>
            </footer>
          </div>
        </div>
    </>
  )
}

export default Upload
