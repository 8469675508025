import Caller from "../../domain/Api/Caller";
import envVariable from "../../infrastructure/presentation/util/envVariable";
import {FilterMovementInterface, MovementListInterface} from '../../domain/Movement/MovementList'
import MovementGatewayInterface from '../../domain/Movement/MovementGatewayInterface'
import {MovementInterface} from "../../domain/Movement/Movement";
import {KpiResponseInterface} from '../../infrastructure/presentation/Type/Kpi'
import {SummaryInterface} from "../../domain/Movement/Summary";
import {InvestmentListInterface} from "../../domain/Movement/InvestmentList";
import { TransactionListInterface } from '../../domain/Movement/TransactionList';

export default class MovementGateway implements MovementGatewayInterface {

  getInvestmentList(currentPage: number, itemsPerPage: number, filter: FilterMovementInterface|null, signal?: AbortSignal
  ): Promise<{data: InvestmentListInterface[]|null, numberOfItems: number}|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transactions/investissements?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}`, {
      product_acronyme: filter?.product?.label || '',
      transaction_type: filter?.transactionType || '',
      statut: filter?.status?.map(it => it?.value) || '',
      tags: filter?.tags?.map(it => it?.value) || '',
      property_type: filter?.propertyType?.map(it => it?.value) || '',
      payment_mode: filter?.paymentMode || '',
      creation_user: filter?.user?.email || '',
      investor_id: filter?.investor_id || '',
      name: filter?.name || '',
      keywords: filter?.keywords || ''
    }, signal)
    .then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    }).catch(response => {
      return {
        data: [],
        numberOfItems: 0
      }
    });
  }

  getTransactionListToCome(currentPage: number, itemsPerPage: number, filter: FilterMovementInterface|null, signal?: AbortSignal): Promise<{data: TransactionListInterface[]|null, numberOfItems: number}|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transactions?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}`, {
      product_acronyme: filter?.product?.label || '',
      transaction_type: filter?.transactionType || '',
      statut: filter?.status?.map(it => it?.value) || '',
      tags: filter?.tags?.map(it => it?.value) || '',
      property_type: filter?.propertyType?.map(it => it?.value) || '',
      payment_mode: filter?.paymentMode || '',
      creation_user: filter?.user?.email || '',
      investor_id: filter?.investor_id || '',
      name: filter?.name || '',
      keywords: filter?.keywords || ''
    }, signal)
      .then(data => {
        if (data) {
          return data
        }

        return {
          data: [],
          numberOfItems: 0
        }
      }).catch(response => {
        return {
          data: [],
          numberOfItems: 0
        }
      });
  }

  getList(
      currentPage: number,
      itemsPerPage: number,
      filter: FilterMovementInterface|null,
      signal?: AbortSignal,
      sort?: string,
      order?: string,
  ): Promise<{data: MovementListInterface[]|null, numberOfItems: number}|null> {
    const userFilter = filter?.user?.nom || filter?.user?.prenom || filter?.user?.email
    const creationUserFilter = userFilter ? '&filters[creation_user]=' + userFilter : '';
    return Caller.executeGet(
        `${envVariable('REACT_APP_API_URL')}/wallet/transactions?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${creationUserFilter}${sort ? '&sort=' + sort + '&order=' + order : ''}`,
        {
          product_acronyme: filter?.product.label || '',
          transaction_type: filter?.transactionType || '',
          status: filter?.status.map(it => it.value) || '',
          tags: filter?.tags.map(it => it.value) || '',
          property_type: filter?.propertyType.map(it => it.value) || '',
          payment_mode: filter?.paymentMode || '',
          creation_user: filter?.user?.email || '',
          investor_id: filter?.investor_id || '',
          name: filter?.name || '',
          keywords: filter?.keywords || ''
        },
        signal
    ).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    }).catch(response => {
      return {
        data: [],
        numberOfItems: 0
      }
    });
  }

  getPresubscription(uuid: string): Promise<MovementInterface> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/${uuid}`, {})
    .then(data => {
      if (data) {
        return data[0]
      }

      return null
    })
    .catch(() => {
      return null
    })
  }

  getExport(filter: FilterMovementInterface|null): Promise<string|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transactions/extract`, {
      product_acronyme: filter?.product.label || '',
      transaction_type: filter?.transactionType || '',
      status: filter?.status.map(it => it.value) || '',
      tags: filter?.tags.map(it => it.value) || '',
      property_type: filter?.propertyType.map(it => it.value) || '',
      payment_mode: filter?.paymentMode || '',
      creation_user: filter?.user?.email || '',
      investor_id: filter?.investor_id || '',
      name: filter?.name || '',
      keywords: filter?.keywords || ''
    })
    .then(data => {
      if (data && data.content) {
        return data.content
      }

      return null
    }).catch(response => {
      return null
    })
  }

  getKpi(): Promise<KpiResponseInterface[]|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transactions/kpi`, {})
    .then(data => {
      if (data) {
        return data
      }

      return null
    }).catch(response => {
      return null
    })
  }

  getSummary(uuid: string): Promise<SummaryInterface[]|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transactions/${uuid}/summary`, {})
    .then(data => {
      if (data) {
        if(data.error) {
          return []
        }
        return data
      }

      return null
    }).catch(response => {
      return null
    })
  }

}
