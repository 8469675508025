import {FilterCustomerInterface} from '../../../domain/Customer/CustomerList'
import {FilterPledgeOrganizationInterface} from "../../../domain/Pledge/PledgeOrganizationList";

interface ListRequestInterface {
  currentPage: number
  itemPerPage: number
  filter: FilterPledgeOrganizationInterface|null
}

class ListRequest implements ListRequestInterface{
  public currentPage: number
  public itemPerPage: number
  public filter: FilterPledgeOrganizationInterface|null

  constructor(
    currentPage: number,
    itemPerPage: number,
    filter: FilterPledgeOrganizationInterface|null,
  ) {
    this.currentPage = currentPage
    this.itemPerPage = itemPerPage
    this.filter = filter
  }
}

export {
  ListRequest
}
export type { ListRequestInterface }
