import {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useAppSelector} from '../../../../store/hook'

import HeaderPage from '../../../component/HeaderPage/HeaderPage'
import {
  ReferentielInterface,
} from '../../../../../domain/Referentiel/ReferentielInterface'

import imgChoiceProduct from '../../../../../assets/images/icons/choice-product.svg'

import '../../../../../assets/styles/page/_choice.scss'
import {ChoiceTypeInterface} from "../../../../../domain/ChoiceType/ChoiceType";
import {ReferentielItemInterface} from "../../../../../domain/Referentiel/ReferentielItemInterface";
import ChoiceTypes from "../../../component/ChoiceTypes/ChoiceTypes";

const ChoiceType: FunctionComponent = () => {
  const {t} = useTranslation()

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)

  const [productTypes, setProductType] = useState<ChoiceTypeInterface[]>([])

  useEffect(() => {
    if(referential) {
      const choiceTypeDataPrepared = prepareChoiceTypeDataData(referential.product.product_type)
      setProductType([...choiceTypeDataPrepared])
    }
  }, [referential])

  const prepareChoiceTypeDataData = (prospectTypeReferential: ReferentielItemInterface[]) :ChoiceTypeInterface[] => {
    return prospectTypeReferential.map((type) => {
      if(type.value === "moral") {
        return {
          label: type.label,
          url: `${t('url.product.add')}/${type.value}`,
          // img: prospectMoral
        }
      }
      else {
        return {
          label: type.label,
          url: `${t('url.product.add')}/${type.value}`,
          // img: prospectPhysical
        }
      }
    })
  }

  return (
    <>
      {referential &&
        <>
          <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''} main-content--sidebar-info`}>
        <div className="sidebar-info__main">
          <HeaderPage title={t('product.add.title')} />
          <ChoiceTypes types={productTypes} />
        </div>
        <div className="sidebar-info">
          <div className="sidebar-info__text">
            {t('product.form.choice-type-select')}
          </div>
          <div>
            <img src={imgChoiceProduct} alt="" />
          </div>
        </div>
      </main>
        </>
      }
    </>
  )
}

export default ChoiceType
