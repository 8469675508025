import { FunctionComponent, useEffect } from 'react';
import {useTranslation} from 'react-i18next'

import { useAppDispatch, useAppSelector } from '../../../../store/hook';

import HeaderPage from '../../../component/HeaderPage/HeaderPage'
import TabCustomer from '../../../component/Tab/Customer/TabCustomer'
import CustomerDatalist from '../../../component/Datalist/CustomerDatalist'

import '../../../../../assets/styles/page/_account.scss'
import {useNavigate} from "react-router-dom";
import { setOpenDatalistFilterCustomer } from '../../../../store/component/event';
import Kpi from "../../../component/Kpi/Kpi";
import CustomerGateway from "../../../../../gateway/Customer/CustomerGateway";

const CustomerDashboard: FunctionComponent = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const customerRules = useAppSelector((state) => state.me.me?.rules.customer)

  if(!customerRules?.visible) navigate(`/`)

  useEffect(() => {
    dispatch(setOpenDatalistFilterCustomer({
        show: false,
        count: 0,
        filters: {
          lastName: '',
          maidenName: '',
          keywords: '',
          birthDate: '',
          status: []
        }
      }
    ))
  }, [dispatch])
  return (
    <>
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        <HeaderPage title={t('account.customer')}/>
        <TabCustomer />
        <div className="prospect-keys-figures">
          <div className="prospect-keys-figures__title">{t('account.key-figures')}</div>
          <Kpi getKpi={new CustomerGateway().getKpi} />
        </div>
      {customerRules?.actions?.list && <CustomerDatalist callerType={"module"}/>}
      </main>
    </>
  )
}

export default CustomerDashboard
