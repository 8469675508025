import {FunctionComponent} from 'react'
import {NavLink, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import '../../../../../assets/styles/components/_tab.scss'

interface Props {
  mode: "add" | "edit" | "read"
  uuid: string | undefined
}

const TabCommissioningAdd: FunctionComponent<Props> = ({mode, uuid}) => {
  const {t} = useTranslation()
  const {commissioningType} = useParams()

  return (
    <div className={`tab tab--product-add`}>
      <NavLink
        to={`/${t(`url.commissioning.${mode}-setting`)}/${commissioningType}${uuid ? "/" + uuid : ""}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('commissioning.add.tab-setting')}
      </NavLink>
      <NavLink
        to={`/${t(`url.commissioning.${mode}-session`)}/${commissioningType}${uuid ? "/" + uuid : ""}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('commissioning.add.tab-session')}
      </NavLink>
    </div>
  )
}

export default TabCommissioningAdd
