import {CommunicationInterface} from './Communication'
import {FiscalityInterface} from './Fiscality'
import {City} from "../Referentiel/City/City";
import {Country} from "../Referentiel/Country/Country";
import {DocumentInterface} from "../Document/Document";
import {AddressInterface} from "../Address/Address";

interface PersonInterface {
  id: string|null
  createdAt: string|null
  updatedAt: string|null
  deletedAt: string|null
  type: string|null
  gerant: boolean|null
  title: string|null
  firstname: string|null
  lastname: string|null
  maidenName: string|null
  birthdate: string|null
  birthCity: City|null
  birthCityForeign: string|null
  birthCountry: Country|null
  nationality: Country|null
  ppe: boolean|null
  ppeCategory: string|null
  legalCapacity: string|null
  familySituation: string|null
  matrimonialRegime: string|null
  email: string|null
  phone: string|null
  extranet: boolean|null
  extranetCreatedAt: string|null
  optinMarketing: CommunicationInterface|null
  optinDividend: CommunicationInterface|null
  optinFiscality: CommunicationInterface|null
  optinGeneralAssembly: CommunicationInterface|null
  optinInvitation: CommunicationInterface|null
  optinAll: CommunicationInterface|null
  address: AddressInterface|null
  fiscality: FiscalityInterface|null
  documents: DocumentInterface[]|null
  code: string|null
}

class Person implements PersonInterface{

  public id: string|null
  public createdAt: string|null
  public updatedAt: string|null
  public deletedAt: string|null
  public type: string|null
  public gerant: boolean|null
  public title: string|null
  public firstname: string|null
  public lastname: string|null
  public maidenName: string|null
  public birthdate: string|null
  public birthCity: City|null
  public birthCityForeign: string|null
  public birthCountry: Country|null
  public nationality: Country|null
  public ppe: boolean|null
  public ppeCategory: string|null
  public legalCapacity: string|null
  public familySituation: string|null
  public matrimonialRegime: string|null
  public email: string|null
  public phone: string|null
  public extranet: boolean|null
  public extranetCreatedAt: string|null
  public optinMarketing: CommunicationInterface|null
  public optinDividend: CommunicationInterface|null
  public optinFiscality: CommunicationInterface|null
  public optinGeneralAssembly: CommunicationInterface|null
  public optinInvitation: CommunicationInterface|null
  public optinAll: CommunicationInterface|null
  public address: AddressInterface|null
  public fiscality: FiscalityInterface|null
  public documents: DocumentInterface[]|null
  public code: string|null

  constructor(
    id: string|null,
    createdAt: string|null,
    updatedAt: string|null,
    deletedAt: string|null,
    type: string|null,
    gerant: boolean|null,
    title: string|null,
    firstname: string|null,
    lastname: string|null,
    maidenname: string|null,
    birthdate: string|null,
    birthCity: City|null,
    birthCityForeign: string|null,
    birthCountry: Country|null,
    nationality: Country|null,
    ppe: boolean|null,
    ppeCategory: string|null,
    legalCapacity: string|null,
    familySituation: string|null,
    matrimonialRegime: string|null,
    email: string|null,
    phone: string|null,
    extranet: boolean|null,
    extranetCreatedAt: string|null,
    optinMarketing: CommunicationInterface|null,
    optinDividend: CommunicationInterface|null,
    optinFiscality: CommunicationInterface|null,
    optinGeneralAssembly: CommunicationInterface|null,
    optinInvitation: CommunicationInterface|null,
    optinAll: CommunicationInterface|null,
    address: AddressInterface|null,
    fiscality: FiscalityInterface|null,
    documents: DocumentInterface[]|null,
    code: string|null
  ) {
    this.id = id
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.deletedAt = deletedAt
    this.type = type
    this.gerant = gerant
    this.title = title
    this.firstname = firstname
    this.lastname = lastname
    this.maidenName = lastname
    this.birthdate = birthdate
    this.birthCity = birthCity
    this.birthCityForeign = birthCityForeign
    this.birthCountry = birthCountry
    this.nationality = nationality
    this.ppe = ppe
    this.ppeCategory = ppeCategory
    this.legalCapacity = legalCapacity
    this.familySituation = familySituation
    this.matrimonialRegime = matrimonialRegime
    this.email = email
    this.phone = phone
    this.extranet = extranet
    this.extranetCreatedAt = extranetCreatedAt
    this.optinMarketing = optinMarketing
    this.optinDividend = optinDividend
    this.optinFiscality = optinFiscality
    this.optinGeneralAssembly = optinGeneralAssembly
    this.optinInvitation = optinInvitation
    this.optinAll = optinAll
    this.address = address
    this.fiscality = fiscality
    this.documents = documents
    this.code = code
  }
}

export {
  Person
}
export type { PersonInterface }
