import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

import '../../../../assets/styles/components/_datalist.scss'
import iconEdit from '../../../../assets/images/icons/datalist-edit.svg'
import remove from "../../../../assets/images/icons/remove.svg";
import eyeIcon from "../../../../assets/images/icons/eye.svg";
import uploadIcon from "../../../../assets/images/icons/upload.svg";
import {BankInformationInterface} from "../../../../domain/Prospect/BankInformation";
import BankInformationListPresenter from "../../../../presenter/Customer/BankInformationListPresenter";
import {setOpenBankInformationFormEvent} from "../../../store/component/event";
import {useAppDispatch} from "../../../store/hook";
import ProductGateway from "../../../../gateway/Product/ProductGateway";
import {ProductListInterface} from "../../../../domain/Product/ProductList";
import {setBankInformations} from "../../../store/component/customer";
import {setBankInformations as setBankInformationsProspect}  from "../../../store/component/prospect";
import {SortInterface, SortOrder} from "../../../../domain/Utils/List";
import TableHead from "../Table/TableHead";
import {ReferentielInterface} from "../../../../domain/Referentiel/ReferentielInterface";

type Props = {
  bankInformation: BankInformationInterface[],
  removeItem: any,
  accountId: string | null,
  isLectureMode?: boolean,
  isProspect?: boolean,
  uploadCallback: (show: boolean, documents: any[]|null, personId: string|null, type: string|null, ribId: string|null) => void,
  viewFilesCallback: (id: string|null) => void,
  referential: ReferentielInterface
}

const BankInformationDatalist: FunctionComponent<Props> = ({ bankInformation, removeItem, accountId, isLectureMode, isProspect, uploadCallback, viewFilesCallback, referential,  ...rest}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const controller = new AbortController()

  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [productList, setProductList] = useState<ProductListInterface[]>([])

  useEffect(() => {
    new ProductGateway().getAll().then((products: ProductListInterface[]) => {
      setProductList(products)
    })
  }, [])

  useEffect(() => {
    const presenter = new BankInformationListPresenter(bankInformation);
    setViewModel(presenter.immutableViewModel())
  }, [bankInformation])

  return (<>
      {(viewModel !== null &&
        <>
          <div className={`datalist`}>
            <div className="datalist__title">{t(viewModel.title)}</div>
            <div className="datalist__header">
            </div>
            <div className="table-fix-head">
              <table className="datalist__datas">
                <thead>
                <tr>
                  {viewModel.heading.map((title: { name: string, sort?: string }) => (
                    <th key={uuidV4()}>{t(title.name)}</th>
                  ))}
                </tr>
                </thead>
                <tbody>
                {viewModel.data !== undefined &&
                viewModel.data.map((item: BankInformationInterface) => (
                  <tr key={uuidV4()}>
                    <td>
                      <div className="actions">
                        <button
                          type="button"
                          className="button-reset"
                          onClick={() => dispatch(setOpenBankInformationFormEvent({show: true, bankInformation: item}))}
                        >
                          <img src={iconEdit} alt="" />
                        </button>
                        <button
                          type="button" className="button-reset"
                          onClick={() => removeItem(item)}
                          disabled={isLectureMode}
                        >
                          <img src={remove} alt="" />
                        </button>
                        <button type="button" className="button-reset u-mxs"
                                onClick={() => viewFilesCallback(item.id)}
                        >
                          <img src={eyeIcon} alt=""/>
                        </button>
                        <button type="button" className="button-reset u-mxs"
                                onClick={() => uploadCallback(true, null, accountId, 'RIB', item.id)}
                        >
                          <img src={uploadIcon} alt=""/>
                        </button>
                      </div>
                    </td>
                    <td>
                      <div className="checkbox-toggle__wrapper">
                        {item.main ? t('common.yes') : t('common.no')}
                      </div>
                    </td>
                    <td>{
                      referential?.prospect.bank_type
                      .find(property => property.value === item.type)
                      ?.label.toString()
                    }</td>
                    <td>{
                      referential?.prospect.bank_flux
                      .find(property => property.value === item.flux)
                      ?.label.toString()
                    }</td>
                    <td>{item.label}</td>
                    <td>
                      <div className="badge-line badge-line--wide-columns">
                        <div className="badge-wrapper status-span">
                          {item.products?.map( product => {
                            return (
                              <div key={uuidV4()} className={ `badge etat etat--draft` }>
                                <div className={`badge__text etat`}>
                                  {productList.find(it => it.id === product)?.label}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </td>
                    <td>{item.bank}</td>
                    <td>{item.iban}</td>
                    <td>{item.swift}</td>
                  </tr>
                ))}
                {viewModel.data === undefined || viewModel.data.length === 0 &&
                <tr>
                  <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
                </tr>
                }
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default BankInformationDatalist
