import {ListRequestInterface} from './ListRequest'
import DismembermentGateway from "../../../gateway/Movement/Dismemberment/DismembermentGateway";
import {MovementListInterface} from "../../../domain/Movement/MovementList";

export default class ListUseCase {
  public dismenbermentGateway

  constructor(DismembermentGateway: DismembermentGateway) {
    this.dismenbermentGateway = DismembermentGateway
  }

  async execute(ListRequest: ListRequestInterface,
                signal?: AbortSignal,
                sort?: string,
                order?: string): Promise<{ data: MovementListInterface[] | null, numberOfItems: number } | null> {
    return await this.dismenbermentGateway.getList(
      ListRequest.currentPage,
      ListRequest.itemPerPage,
      ListRequest.filter,
      signal,
      sort,
      order)
      .then(response => response)
  }
}
