import ValidatorGateway from "../../gateway/Validator/ValidatorGateway";

export default class BicValidatorUseCase
{
  validatorGateway: ValidatorGateway

  constructor(
    validatorGateway: ValidatorGateway
  ) {
    this.validatorGateway = validatorGateway
  }

  async execute(bic: string): Promise<boolean> {
    return await this.validatorGateway.validate('bic', bic).then(response => {return response})
  }
}
