import Caller from '../../domain/Api/Caller';
import envVariable from '../../infrastructure/presentation/util/envVariable';
import { FilterUserInterface, UserListInterface } from '../../domain/User/UserList';
import { UserInterface } from '../../domain/User/User';
import {FormErrorInterface} from "../../domain/Error/Error";
import FormError from "../../infrastructure/presentation/util/FormError";

export default class UserGateway {

    getList(currentPage: number, itemsPerPage: number, name: string, filter: FilterUserInterface | null): Promise<{ data: UserListInterface[] | null, numberOfItems: number } | null> {
        return Caller.executeGet(`${ envVariable('REACT_APP_API_URL') }/users?params[nb_element_per_page]=${ itemsPerPage }&params[page]=${currentPage}&filters[nom]=${ name }`, {
            'firstName': filter?.firstName || '',
            'lastName': filter?.lastName || '',
            'keywords': filter?.keywords || '',
            'roles': filter?.roles || [],
        })
            .then(data => {
                if (data) {
                    return data;
                }

                return {
                    data: [],
                    numberOfItems: 0,
                };
            }).catch(response => {
                return {
                    data: [],
                    numberOfItems: 0,
                };
            });
    }

    public async getExport(filter: FilterUserInterface | null): Promise<string | null> {
        return Caller.executeGet(`${ envVariable('REACT_APP_API_URL') }/users/export`, {})
            .then(data => {
                if (data && data.content) {
                    return data.content;
                }
                return null;
            }).catch(response => {
                return null;
            });
    }

    add(User: UserInterface): Promise<{uuid: string, errors: FormErrorInterface[]}> {
        return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/users`, {
            "email": User.email,
            "roles": User.roles,
            "firstname": User.firstname,
            "lastname": User.lastname,
            "enabled": User.enabled,
            "first_password": User.first_password,
            "second_password": User.second_password
        })
            .then(data => {
                if (data.uuid) {
                    return data
                }
                return {
                    uuid: null,
                    errors: []
                }
            })
            .catch(err => {
                return {
                    uuid: null,
                    errors: new FormError().getApiErrors(err.data)
                }
            })
    }

    update(User: UserInterface): Promise<{uuid: string, errors: FormErrorInterface[]}> {
        return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/users/${User.id}`, {
            "email": User.email,
            "roles": User.roles,
            "firstname": User.firstname,
            "lastname": User.lastname,
            "enabled": User.enabled,
            "first_password": User.first_password,
            "second_password": User.second_password
        })
            .then(data => {
                if (data.uuid) {
                    return data
                }
                return {
                    uuid: null,
                    errors: []
                }
            })
            .catch(err => {
                return {
                    uuid: null,
                    errors: new FormError().getApiErrors(err.data)
                }
            })
    }

    get(id: string): Promise<UserInterface|null> {
        return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/users/${id}`, {})
            .then(data => {
                if (data) {
                    return data[0]
                }
                return null
            })
            .catch(() => {
                return null
            })
    }
}
