import {ContactList} from './Contact'
import {AddressList} from './Address'
import {DocumentBackEndInterface} from "../domain/Partner/Document/DocumentBackEnd";

export const ListPartner = [
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 2',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 3',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 4',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 5',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 6',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 7',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 8',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 9',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 10',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Johnn',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 11',
    'contact_lastname' : 'Doe',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 12',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 13',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 14',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 15',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 16',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 17',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 18',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 19',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
  {
    'id'               : '558612',
    'partner_id'       : '43359',
    'cabinet_name'     : 'Krusty Krab 20',
    'contact_lastname' : 'Fox',
    'contact_firstname': 'Marjorie',
    'city'             : 'East Aiyana'
  },
]
export const FindPartner = {
    'partnerNum' : 'P165981',
    'legalStatus' : 'opt1',
    'socialReason' : 'social reason',
    'tradeName' : 'trade name',
    'internalCommercialDedicated' : 'internal commercial dedicated',
    'externalId' : 'external id',
    'attachment' : '1',
    'platform' : 'opt2',
    'network' : 'opt2',
    'type' : 'opt1',
    'aggregator' : 'opt3',
    'status' : 'status',
    'createdAtStatus' : '2021-01-01',
    'releasedAtStatus' : '2021-01-01',
    'blockedAtStatus' : '2021-01-01',
    'rating' : '4',
    'contactList' : ContactList,
    'addressList' : AddressList
  }

export const NumberOfPartnerByState = [11, 19, 22]
export const AgreedVsProspect = [651535, 558612, 267400, 487441]
export const BreakdownOfPartnersByType = [651535, 558612, 267400, 357400]
export const DefaultPartnerDocuments = {
  'kyc': {
    id: null,
    createdAt: '',
    receivedAt: '',
    requestAt: '',
    sendAt: '',
    type: 'KYC',
    title: '',
    extension: '',
    updatedAt: '',
    waiting: true,
    order: 1
  },
  'kbis': {
    id: null,
    createdAt: '',
    receivedAt: '',
    requestAt: '',
    sendAt: '',
    type: 'KBIS',
    title: '',
    extension: '',
    updatedAt: '',
    waiting: true,
    order: 2
  },
  'status': {
    id: null,
    createdAt: '',
    receivedAt: '',
    requestAt: '',
    sendAt: '',
    type: 'STATUS',
    title: '',
    extension: '',
    updatedAt: '',
    waiting: true,
    order: 3
  },
  'rib': {
    id: null,
    createdAt: '',
    receivedAt: '',
    requestAt: '',
    sendAt: '',
    type: 'RIB',
    title: '',
    extension: '',
    updatedAt: '',
    waiting: true,
    order: 3
  },
  'cni': {
    id: null,
    createdAt: '',
    receivedAt: '',
    requestAt: '',
    sendAt: '',
    type: 'CNI',
    title: '',
    extension: '',
    updatedAt: '',
    waiting: true,
    order: 4
  },
  'other': {
    id: null,
    createdAt: '',
    receivedAt: '',
    requestAt: '',
    sendAt: '',
    type: 'OTHER',
    title: '',
    extension: '',
    updatedAt: '',
    waiting: true,
    order: 5
  }
}