import React from 'react'
import styled, { keyframes } from 'styled-components'
import { FunctionComponent } from 'react'

type Props = {
  className?: string
  label?: string
  clicked: boolean
  onClick: () => void
  btnName: string
  state: 'error' | 'success' | ''
  icon: any
}


const ButtonDownloadStyled: FunctionComponent<Props> = ({
                                                          label,
                                                          className,
                                                          clicked,
                                                          onClick,
                                                          btnName,
                                                          state,
                                                          icon
                                                        }) => {
  return (
    <Container className={`${className || ''} flex items-center`}>
      <ButtonDownload className="button u-mrs"
                      type='button'
                      onClick={onClick}
                      clicked={clicked}
                      state={state}
                      name={`downloadButton-${btnName}`}
                      id={`downloadButton-${btnName}`}
      >
        {icon}
        <ArrowCircle clicked={clicked} state={state}>
          <div className='container-arrow'>
            <div className='ms-line-down' />
            <div className='ms-line-point' />
          </div>
        </ArrowCircle>
      </ButtonDownload>
      {label && <label htmlFor={`downloadButton-${btnName}`} className="form-control__label">{label}</label>}
    </Container>
  )
}

const CrossError = (side: string) => keyframes`
  0% {
    left: ${side === "left" && "2px"};
    right: ${side === "right" && "2px"};
    height: 9px;
  }
  75% {
    height: 25px;
  }
  100% {
    left: ${side === "left" && "5px"};
    right: ${side === "right" && "5px"};
    height: 20px;
  }
`

const CrossSuccess = (side: string) => keyframes`
  0% {
    left: ${side === "left" && "2px"};
    right: ${side === "right" && "2px"};
    height: 9px;
  } 
  75% {
    height: ${side === "left" ? "13px" : "23px"};
  }
  100% {
    left: ${side === "left" && "0"};
    bottom: ${side === "left" ? "6px" : "5px"};
    height: ${side === "left" ? "8px" : "18px"};
  }
`

const ArrowUpDown = keyframes`
  0% { top: 50% }
  50% { top: 35% }
  100% { top: 50% }
`

const ArrowTransformBig = keyframes`
  0% { transform: scale(0.5); left: 7px; top: 9px }
  50% { left: 0; top: 0; }
  90% { transform: scale(1); left: 0; top: 0; }
  100% { transform: scale(0.9); left: 0; top: 0; }
`

const FolderDisappears = keyframes`
  0% { opacity: 1 }
  100% { opacity: 0 }
`

const ChangeColor = (color: string) => keyframes`
  0% { border: 2px solid var(--ink-1); transform: scale(1); left: 0; top: 0;}
  100% { border: 2px solid var(--${color}); transform: scale(1); left: 0; top: 0;}
`

const Container = styled.div`
  label {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  
`

const ButtonDownload = styled.button<{ clicked: boolean, state: string }>`
  display: flex;
  align-items: center;
  position: relative;
  width: 35px;
  height: 35px;

  svg {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: ${({ clicked, state }) => (clicked || state !== "") ? 0 : 1 };
    animation: 250ms ease-in 10ms forwards ${({ clicked, state }) => clicked && state === "" && FolderDisappears};
    path {
      fill: var(--ink-1);
    }
  }
`

const ArrowCircle = styled.div<{ clicked: boolean, state: string }>`
  position: absolute;
  left: ${({ state }) => state === "" ? "7px" : "0"};
  top: ${({ state }) => state === "" ? "9px" : "0"};
  width: 30px;
  height: 30px;
  border: 2px solid var(--ink-1);
  border-radius: 50px;
  box-shadow: inset 0 2px 0 hsla(0, 0%, 0%, .1), 0 3px 0 hsla(0, 0%, 0%, .075);
  cursor: pointer;
  transform: ${({ state }) => state === "" ? "scale(0.5)" : "scale(0.9)"};
  background-color: var(--light);
  animation: 
          500ms ease-in 100ms forwards ${({ clicked, state }) => clicked && state === "" && ArrowTransformBig},
          500ms ease-in 100ms forwards ${({ clicked, state }) => clicked && state !== "" && ChangeColor(state)};
         
  

  .container-arrow {
    display: flex;
    position: absolute;
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    animation: 1s ease 1s forwards infinite ${({ clicked, state }) => clicked && state === "" && ArrowUpDown};
  }

  // ligne de la fleche
  .ms-line-down {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: -1px;
    margin: auto;
    width: 2px;
    height: 18px;
    background-color: ${({ state }) => state === "" ? "var(--ink-1)" : `var(--${state})`};
    opacity: ${({ state }) => state === "" ? 1 : 0};
    border-radius: 50px;
    transition: background-color .5s ease;
  }

  // pointes de la fleche
  .ms-line-point {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 12px;
    height: 30px;

    &::before, &::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 9px;
      bottom: 5px;
      background: ${({ state }) => state === "" ? "var(--ink-1)" : `var(--${state})`};
    }

    &::before {
      transform: rotate(45deg);
      right: 2px;
      animation: 300ms ease-in-out 100ms forwards ${({ clicked, state }) => state === "error" ? CrossError("right") : state === "success" ? CrossSuccess("right") : ""};
    }

    &::after {
      transform: rotate(-45deg);
      left: 2px;
      animation: 250ms ease-in-out 100ms forwards ${({ clicked, state }) => state === "error" ? CrossError("left") : state === "success" ? CrossSuccess('left') : ""};
    }
  }

`

export default ButtonDownloadStyled





