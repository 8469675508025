interface RelationInterface {
  id: string|null
  personId: string|null
  lastName: string|null
  firstName: string|null
  relation: string|null
}

class Relation implements RelationInterface{

  public id: string|null
  public personId: string|null
  public lastName: string|null
  public firstName: string|null
  public relation: string|null

  constructor(
    id: string|null,
    personId: string|null,
    lastName: string|null,
    firstName: string|null,
    relation: string|null
  ) {
    this.id = id
    this.personId = personId
    this.lastName = lastName
    this.firstName = firstName
    this.relation = relation
  }
}

export {
  Relation
}
export type { RelationInterface }
