import {ListRequestInterface} from './ListRequest'
import PledgeOrganizationGateway from "../../../gateway/Pledge/PledgeOrganizationGateway";
import {PledgeOrganizationListInterface} from "../../../domain/Pledge/PledgeOrganizationList";

export default class ListUseCase
{
  public pledgeOrganizationGateway

  constructor(PledgeOrganizationGateway: PledgeOrganizationGateway) {
    this.pledgeOrganizationGateway = PledgeOrganizationGateway
  }

  async execute(ListRequest: ListRequestInterface, signal?: AbortSignal,sort?: string, order?: string): Promise<{data: PledgeOrganizationListInterface[]|null, numberOfItems: number}|null> {
    return this.pledgeOrganizationGateway.getList(ListRequest.currentPage, ListRequest.itemPerPage, ListRequest.filter, signal, sort, order).then(response => {
      return response
    })
  }
}
