import {FormApiErrorInterface, FormErrorInterface} from "../../../domain/Error/Error";
import {UseFormSetError} from "react-hook-form/dist/types/form";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {FieldPath} from "react-hook-form/dist/types/utils";

export default class FormError {
  getApiErrors(apiErrors: FormApiErrorInterface): FormErrorInterface[] {
    try {
      const formError: FormErrorInterface[] = []
      if (apiErrors.errors) {
        Object.entries(apiErrors.errors).map((obj: [string, {errors: {[key:string] : string}}]) => {
          formError.push({field: obj[0], errors: Object.entries(obj[1]?.errors)?.map((error: any) => {return error[1]})})
        })
      }
      return formError
    } catch (e) {
      return []
    }
  }

  /**
   *
   * @param errors
   * @param mappingField example {'apiField': 'frontField', ...}
   * @param setError
   */
  setFormErrors(errors: FormErrorInterface[], mappingField: {[key:string]: string}, setError: (name: any, message: string) => void) {

    errors.map((error) => {
      if (error.field in mappingField) {
        error.field = mappingField[error.field]
      }

      let errorMessage = '';
      error.errors.map((childError) => {
        errorMessage = errorMessage === '' ? childError : errorMessage+', '+childError
      })
      setError(error.field, errorMessage)
    })
  }
}
