import React, {FunctionComponent, FormEvent, InputHTMLAttributes} from 'react'

import {IFormFieldProps} from './IFormFieldProps'

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  id: string
  name: string
  classes?: string
  value?: string
  defaultValue?: string
  onChange?: (e: FormEvent<HTMLInputElement>) => void
  align?: 'flex'|'normal'
}

const Checkbox: FunctionComponent<IProps> = ({id, name, classes, label, onChange, value, defaultValue, register, error, align, required, ...rest}) => {
  const checkbox = <div className="form-control__input">
      <input type="checkbox"
             className=""
             defaultValue={defaultValue} {...rest}
             id={id}
             required={required}
             readOnly={rest.readOnly}
             title={rest.help} {...register(name)} />
  </div>

  return (
    <>
      <div className={ classes ? `${classes}` : ''}>
        {label &&
          <div className={`form-control form-control--center ${align === 'normal' ? '' : 'form-control--label-auto'}`}>
            <label htmlFor={id} className="form-control__label">{label}</label>
            {checkbox}
          </div>
        }
        {!label &&
          <div className="form-control">
            {checkbox}
          </div>
        }
      </div>
    </>
  )
}

export default Checkbox
