import {City} from "../Referentiel/City/City";

interface DepositaryInterface {
  id: string|null
  createdAt: string|null
  updatedAt: string|null
  deletedAt: string|null
  name: string|null
  address: string|null
  address2: string|null
  address3: string|null
  postcode: string|null
  city: City|null
}

class Depositary implements DepositaryInterface {
  id: string|null
  createdAt: string|null
  updatedAt: string|null
  deletedAt: string|null
  name: string|null
  address: string|null
  address2: string|null
  address3: string|null
  postcode: string|null
  city: City|null

  constructor(
    id: string|null,
    createdAt: string|null,
    updatedAt: string|null,
    deletedAt: string|null,
    name: string|null,
    address: string|null,
    address2: string|null,
    address3: string|null,
    postcode: string|null,
    city: City|null
  ) {
    this.id = id
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.deletedAt = deletedAt
    this.name = name
    this.address = address
    this.address2 = address2
    this.address3 = address3
    this.postcode = postcode
    this.city = city
  }
}

export {Depositary}
export type {DepositaryInterface}
