import {FunctionComponent} from 'react'

type Props = {
  color?: string
}

const Ged: FunctionComponent<Props> = ({color}) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.1705 1.04054L21.1704 1.04047C21.0491 0.93146 20.8892 0.886581 20.7349 0.906763C20.7104 0.902802 20.6841 0.9 20.6561 0.9H6.2232C5.92128 0.9 5.67683 1.14445 5.67683 1.44638V14.0937C5.67683 14.1696 5.69345 14.2401 5.7205 14.3037C5.61188 14.6588 5.52811 15.0258 5.47587 15.4038C2.74946 16.4302 0.9 19.0546 0.9 22.0127C0.9 25.911 4.07119 29.0822 7.96943 29.0822H22.0345C25.9327 29.0822 29.1039 25.911 29.1039 22.0127C29.1039 18.9129 27.1376 16.2801 24.3271 15.3289V4.27343C24.3271 4.25655 24.3249 4.23769 24.3231 4.22442C24.3291 4.19287 24.3327 4.15955 24.3327 4.12464C24.3327 3.93953 24.2407 3.77603 24.1003 3.67736L21.1705 1.04054ZM12.4701 9.31718C10.1275 9.31718 8.05392 10.447 6.76958 12.2027V1.99275H20.2585V4.12464C20.2585 4.42661 20.5033 4.67101 20.8048 4.67101H23.2343V14.8853C22.4231 13.2791 20.7825 12.2478 18.9286 12.2478C18.6953 12.2478 18.4632 12.2644 18.2334 12.2979C17.767 11.6414 17.1956 11.0782 16.5515 10.6224H20.9536C21.1735 10.6224 21.3512 10.4443 21.3512 10.2248C21.3512 10.0054 21.1735 9.82724 20.9536 9.82724H15.0934C14.2712 9.49721 13.3834 9.31718 12.4701 9.31718ZM6.43134 16.2434L6.50266 16.2244L6.50555 16.1507C6.63053 12.9602 9.2493 10.4102 12.4701 10.4102C14.7098 10.4102 16.6591 11.6442 17.6832 13.4683L17.723 13.5393L17.8006 13.5147C18.1568 13.402 18.5352 13.3405 18.9289 13.3405C20.6155 13.3405 22.0401 14.4582 22.5054 15.9936L22.5249 16.058L22.5919 16.0642C25.6311 16.3455 28.0114 18.9001 28.0114 22.0124C28.0111 25.314 25.3351 27.9894 22.0345 27.9894H7.96943C4.66879 27.9894 1.99275 25.3137 1.99275 22.0127C1.99275 19.2451 3.87682 16.9231 6.43134 16.2434Z" fill={color || 'white'} stroke="black" strokeWidth="0.1"/>
        </svg>
    )
}

export default Ged
