import React, {FunctionComponent, useEffect, useState} from 'react'
import {Controller} from 'react-hook-form'
import Select from 'react-select'

import ProductGateway from '../../../../../gateway/Product/ProductGateway'

type Props = {
  id?: string
  name: string
  label?: string
  labelClasses?: string
  selectClasses?: string
  classes: string
  control: any
  defaultValue: {
    id: string|null
    label: string|null
    value: string|null
  } | null,
  disabled?: boolean | undefined
  customPlaceHolder?: string
  maxContentFit?: boolean
  openToSubscription?: boolean
  customOnchange?: (option: {id: string, label: string, value: string}) => void
}

const customStyles = {
  control          : (base: any) => ({
    ...base,
    minHeight: 25
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: 4
  }),
  clearIndicator   : (base: any) => ({
    ...base,
    padding: 4
  }),
  valueContainer   : (base: any) => ({
    ...base,
    padding: '0px 6px'
  }),
  input            : (base: any) => ({
    ...base,
    margin : 0,
    padding: 0
  })
}

const ProductDalalist: FunctionComponent<Props> = (
  {
    classes,
    id,
    label,
    labelClasses,
    selectClasses = "",
    control,
    name,
    defaultValue,
    disabled,
    customPlaceHolder,
    maxContentFit,
    openToSubscription,
    customOnchange,
    ...rest
  }
  ) => {
  const [products, setProducts] = useState<[]>([])

  useEffect(() => {
    if(openToSubscription) {
      new ProductGateway().getProductOpenToSubscription().then((data) => {
        setProducts(data)
      })
    } else {
      new ProductGateway().getAll().then((data) => {
        setProducts(data)
      })
    }

  }, [])

  return (

    <>
      <div className={`${classes}`}>
        <div className="form-control">
          {label && <label htmlFor={id} className={ labelClasses ? `form-control__label ${labelClasses}` : "form-control__label"}>{label}</label>}
          <div className={maxContentFit ? "form-control__input form-control__max-content" : "form-control__input"}>
            <Controller
              name={name}
              control={control}
              render={({
                         field: {onChange, value, name}
                       }) => (
                <Select
                  name={name}
                  value={value?.id !== '' ? value : null}
                  options={products}
                  onChange={(option) => {
                    onChange(option)
                    customOnchange && option && customOnchange(option)
                  }}
                  styles={customStyles}
                  isDisabled={disabled || false}
                  placeholder={customPlaceHolder}
                  className={selectClasses || ''}
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductDalalist;
