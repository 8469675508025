import {Investor} from "../PreSubscription/Investor";
import {Product} from "../Product/Product";
import {User} from "../User/User";

interface MovementInterface {
  id: string|null
  createdAt: string|null
  updatedAt: string|null
  subscribedAt: string|null
  deletedAt: string|null
  engagedAt: string|null
  canceledAt: string|null
  verifiedLevel1At: string|null
  verifiedLevel2At: string|null
  confirmedAt: string|null
  validationScheduledAt: string|null
  executedAt: string|null
  shareCount: number|null
  sharePrice: number|null
  totalAmount: number|null
  status: string|null
  propertyType: string|null
  possessionMode: string|null
  subscriptionCode: string|null
  investors: Investor[]|null
  product: Product|null
  transactionCode: string|null
  tags: []
  status_label: string|null
  horodatage: string|null
  user: User|null
  lastUpdateUser: User|null
  actionReason: string|null
  comment: string|null
  subType: string|null
  transactionExecutionAt: string|null,
  note: string|null
}

class Movement implements MovementInterface{
  id: string|null
  createdAt: string|null
  updatedAt: string|null
  subscribedAt: string|null
  deletedAt: string|null
  engagedAt: string|null
  canceledAt: string|null
  verifiedLevel1At: string|null
  verifiedLevel2At: string|null
  confirmedAt: string|null
  validationScheduledAt: string|null
  executedAt: string|null
  shareCount: number|null
  sharePrice: number|null
  totalAmount: number|null
  status: string|null
  propertyType: string|null
  possessionMode: string|null
  subscriptionCode: string|null
  investors: Investor[]|null
  product: Product|null
  transactionCode: string|null
  tags: []
  status_label: string|null
  horodatage: string|null
  user: User|null
  lastUpdateUser: User|null
  actionReason: string|null
  comment: string|null
  subType: string|null
  transactionExecutionAt: string|null
  note: string|null

  constructor(
    id: string|null,
    createdAt: string|null,
    updatedAt: string|null,
    subscribedAt: string|null,
    deletedAt: string|null,
    engagedAt: string|null,
    canceledAt: string|null,
    verifiedLevel1At: string|null,
    verifiedLevel2At: string|null,
    confirmedAt: string|null,
    validationScheduledAt: string|null,
    executedAt: string|null,
    shareCount: number|null,
    sharePrice: number|null,
    totalAmount: number|null,
    status: string|null,
    propertyType: string|null,
    possessionMode: string|null,
    subscriptionCode: string|null,
    investors: Investor[]|null,
    product: Product|null,
    transactionCode: string|null,
    tags: [],
    status_label: string|null,
    horodatage: string|null,
    user: User|null,
    lastUpdateUser: User|null,
    actionReason: string|null,
    comment: string|null,
    subType: string|null,
    transactionExecutionAt: string|null,
    note: string|null
  ) {
    this.id = id
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.subscribedAt = subscribedAt
    this.deletedAt = deletedAt
    this.engagedAt = engagedAt
    this.canceledAt = canceledAt
    this.verifiedLevel1At = verifiedLevel1At
    this.verifiedLevel2At = verifiedLevel2At
    this.confirmedAt = confirmedAt
    this.validationScheduledAt = validationScheduledAt
    this.executedAt = executedAt
    this.shareCount = shareCount
    this.sharePrice = sharePrice
    this.totalAmount = totalAmount
    this.status = status
    this.propertyType = propertyType
    this.possessionMode = possessionMode
    this.subscriptionCode = subscriptionCode
    this.investors = investors
    this.product = product
    this.transactionCode = transactionCode
    this.tags = tags
    this.status_label = status_label
    this.horodatage = horodatage
    this.user = user
    this.lastUpdateUser = lastUpdateUser
    this.actionReason = actionReason
    this.comment = comment
    this.subType = subType
    this.transactionExecutionAt = transactionExecutionAt
    this.note = note
  }
}

export {
  Movement
}
export type { MovementInterface }
