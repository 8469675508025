import {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'

import '../../../../assets/styles/components/_hello.scss'

type Props = {
  name: string
}

const Hello: FunctionComponent<Props> = ({name}) => {
  const {t} = useTranslation()

  return (
    <h1 className={`hello`}>{t('hello.title', {name: name})}</h1>
  );
}

export default Hello
