import {Scale} from "../../../domain/Product/Scale";
import Caller from "../../../domain/Api/Caller";
import envVariable from "../../../infrastructure/presentation/util/envVariable";
import {ScaleItem} from "../../../domain/Product/ScaleItem";

export default class ScaleItemGateway {
  create(productId: string, scaleId: string, scaleItem: ScaleItem): Promise<Scale[]> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/products/${productId}/scales/${scaleId}/item`, {
      category: scaleItem.category.id,
      value: scaleItem.value,
      approbationAt: scaleItem.approbationAt,
      applicationAt: scaleItem.applicationAt,
      start: scaleItem.period?.start,
      end: scaleItem.period?.end
    })
    .then(data => {
      if (data) {
        return data
      }
    })
    .catch(response => {
      throw response
    });
  }

  update(productId: string, scaleId: string, scaleItem: ScaleItem): Promise<Scale[]> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/products/${productId}/scales/${scaleId}/item/${scaleItem.id}`, {
      category: scaleItem.category.id,
      value: scaleItem.value,
      approbationAt: scaleItem.approbationAt,
      applicationAt: scaleItem.applicationAt,
      start: scaleItem.period?.start,
      end: scaleItem.period?.end
    })
    .then(data => {
      if (data) {
        return data
      }
    })
    .catch(response => {
      throw response
    });
  }
}
