import {FunctionComponent, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

// Notification lib
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {setOpenChoiceProductChildEvent} from '../../../../store/component/event'

import blockScrollBody from '../../../util/BlockScroll'
import getClassForOverlay from '../../../util/Sidebar'
import {ProductListInterface} from '../../../../../domain/Product/ProductList'
import ProductChildDatalist from '../../Datalist/ProductChildDatalist'
import {addProductChild} from '../../../../store/component/product'

const ChoiceProduct: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openChoiceProductChild = useAppSelector((state) => state.event.openChoiceProductChild)

  useEffect(() => {
    blockScrollBody(openChoiceProductChild.show)

  }, [openChoiceProductChild])

  const handleChoiceProduct = function (selectedProduct: ProductListInterface) {
    dispatch(addProductChild(selectedProduct))
    dispatch(setOpenChoiceProductChildEvent({show: false}))
  }

  function handleClose() {
    dispatch(setOpenChoiceProductChildEvent({show: false}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openChoiceProductChild.show)}`} onClick={() => handleClose()}/>
      <div className={`sidebar sidebar--right sidebar--right ${openChoiceProductChild.show ? 'sidebar--active-big' : ''}`}>
        <div className="form-bloc">
          <div className="sidebar__content">
            <div className="title">{t('product.form.multi-product.add')}</div>
            <ProductChildDatalist callback={handleChoiceProduct} callerType={"datalist"} />
          </div>
          <footer className="sidebar__footer">
            <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => handleClose()}>{t('common.cancel')}</button>
          </footer>
        </div>
      </div>
    </>
  );
}

export default ChoiceProduct
