import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm, useFormState} from 'react-hook-form'
import {v4 as uuidV4} from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../../../store/hook'
import {setOpenProspectLegalRepresentativesForm} from '../../../../../store/component/event'
import {ProspectInterface} from '../../../../../../domain/Prospect/Prospect'
import UpdateUseCase from '../../../../../../useCase/Prospect/Update/UpdateUseCase'
import ProspectGateway from '../../../../../../gateway/Prospect/ProspectGateway'
import iconAdd from '../../../../../../assets/images/icons/add.svg'
import iconExtend from '../../../../../../assets/images/icons/expend.svg'
import AddUseCase from '../../../../../../useCase/Prospect/Add/AddUseCase'
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {usePrompt} from "../../../../util/Navigation";
import {ProspectLegalRepresentativesInterface} from "../../../../../../domain/Prospect/ProspectLegalRepresentatives";
import InitializeProspect from "../../../../../../useCase/Prospect/Initialization/InitializeProspect";
import {useParams} from "react-router-dom";
import AlertInfo from '../../../Alert/AlertInfo/AlertInfo';

interface IFormInput {
    kycRequestAt: string
    kycReceivedAt: string
    kycDocumentDate: string
    kycWaitingDocument: string
    kBisRequestAt: string
    kBisReceivedAt: string
    kBisDocumentDate: string
    kBisWaitingDocument: string
    statusRequestAt: string
    statusReceivedAt: string
    statusDocumentDate: string
    statusWaitingDocument: string
    ribRequestAt: string
    ribReceivedAt: string
    ribDocumentDate: string
    ribWaitingDocument: string
    cniManagerRequestAt: string
    cniManagerReceivedAt: string
    cniManagerDocumentDate: string
    cniManagerWaitingDocument: string
    otherRequestAt: string
    otherReceivedAt: string
    otherDocumentDate: string
    otherWaitingDocument: string
    blocking: string
    blockingAt: string
    blockingComment: string
}

type Props = {
    prospect?: ProspectInterface
    isLectureMode?: boolean
}

const TABLE_STATUS_VISIBLE = [
  'active'
]

const LegalRepresentatives: FunctionComponent<Props> = ({prospect, isLectureMode}) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const { prospectType } = useParams()

    const referential = useAppSelector(({referential}) => referential.referential)
    const legalRepresentativesArray = useAppSelector((state) => state.prospect.legalRepresentativesArray)
    const [legalRepresentativesList, setLegalRepresentativesList] = useState<ProspectLegalRepresentativesInterface[]|[]>(legalRepresentativesArray)

    const { control, handleSubmit, reset, formState: { errors } } = useForm<IFormInput>();
    const onSubmit: SubmitHandler<IFormInput> = data => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
            }
        });
    }

    const onConfirm = (data: any) => {
        reset(data)
        if (prospect) {
            prospect.legalRepresentatives = legalRepresentativesList
            new UpdateUseCase(new ProspectGateway()).execute(prospect).then(response => {
                if (null !== response) {
                    toastSuccess(t('account.notify.update-success'))
                } else {
                    toastError(t('account.notify.update-error'));
                }
            })
        }
    }

    const { isDirty } = useFormState({
        control
    });
    usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));


    useEffect(() => {
        setLegalRepresentativesList(legalRepresentativesArray)
    }, [legalRepresentativesArray])

    const renderFooter = () => {
        if(isLectureMode) return null
        return <footer className={`form-bloc__footer`}>
            <button type="submit" className="button button--ink-2">{t('common.save')}</button>
            <button className="button button--ink-2 button--ink-2--outline">{t('common.cancel')}</button>
        </footer>
    }

    return (
        <>
            {referential &&
                <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={`form-bloc`}>
                            <div className="form-bloc__title">{t('prospect.form.legal-representatives.title')}</div>
                            { legalRepresentativesList.some((rl: ProspectLegalRepresentativesInterface) => !rl.code) ? (
                                <div className="flex-container">
                                    <div className="col-md-12">
                                        <AlertInfo text={t('prospect.form.legal-representatives.alert-add-rl')} />
                                    </div>
                                </div>) : ''}
                            {!isLectureMode && <div className="form-bloc__shortcut">
                                <button type="button" className="button-reset u-txt-with-icon"
                                        onClick={() => dispatch(setOpenProspectLegalRepresentativesForm({
                                            show: true,
                                            legalRepresentatives: null
                                        }))}>
                                    <img src={iconAdd} alt=""/>
                                    {t('prospect.form.legal-representatives.add')}
                                </button>
                            </div>}
                            {legalRepresentativesList.length > 0 &&
                                legalRepresentativesList
                                  .filter((legalRepresentative) => legalRepresentative.status && TABLE_STATUS_VISIBLE.includes(legalRepresentative.status))
                                  .map(legalRepresentative => {
                                    return <div className="form-bloc__form flex-container" key={uuidV4()}>
                                        <div className="col-md-12">
                                            <div className="form-resume">
                                                <div className="form-control u-mb0">
                                                    <label htmlFor="legalRepresentativeNum" className="form-control__label">{t('prospect.form.legal-representatives.id')}</label>
                                                    <div className="form-control__input">
                                                        <input type="text" disabled={true} value={`${legalRepresentative.code || ""}`} />
                                                    </div>
                                                </div>
                                                <div className="form-control u-mb0">
                                                    <label htmlFor="legalRepresentativeNum" className="form-control__label">{t('prospect.form.legal-representatives.lastname')}</label>
                                                    <div className="form-control__input">
                                                        <input type="text" disabled={true} value={`${legalRepresentative.person?.lastname}`} />
                                                    </div>
                                                </div>
                                                <div className="form-control u-mb0">
                                                    <label htmlFor="legalStatus" className="form-control__label">{t('prospect.form.legal-representatives.firstname')}</label>
                                                    <div className="form-control__input">
                                                        <input type="text" disabled={true} value={`${legalRepresentative.person?.firstname || ""}`} />
                                                    </div>
                                                </div>
                                                <div className="form-resume__expend">
                                                    <img src={iconExtend} alt="" onClick={() => dispatch(setOpenProspectLegalRepresentativesForm({show: true, legalRepresentatives: legalRepresentative}))}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        {renderFooter()}
                    </form>
                </>
            }
        </>
    )
}

export default LegalRepresentatives
