import Caller from '../../domain/Api/Caller'
import envVariable from '../../infrastructure/presentation/util/envVariable'

export default class TransactionTagGateway {
  add(transactionId: string, tags: Array<string>): Promise<Array<string|number>|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/wallet/transactions/${transactionId}/tags`, {tags: tags})
      .then((data: Array<{tags: []}>) => {
        if (data && data.length > 0 && data[0].tags) {
          return data[0].tags
        }

        return null
      })
      .catch(() => {
        return null
      })
  }

  remove(transactionId: string, tags: Array<string|number>): Promise<Array<string>|null> {
    return Caller.executeDelete(`${envVariable('REACT_APP_API_URL')}/wallet/transactions/${transactionId}/tags`, {tags: tags})
      .then((data: Array<{tags: []}>) => {
        if (data && data.length > 0 && data[0].tags) {
          return Object.values(data[0].tags)
        }

        return null
      })
      .catch(() => {
        return null
      })
  }
}
