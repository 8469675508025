import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../store/hook";
import HeaderPage from "../../../component/HeaderPage/HeaderPage";
import TabCommissioning from "../../../component/Tab/Commissioning/TabCommissioning";
import CommissioningDatalist from "../../../component/Datalist/CommissioningDatalist";
import DatalistFilter from "../../../component/Sidebar/Partner/DatalistFilter";
import {useNavigate} from "react-router-dom";


const CommissioningDashboard: FunctionComponent = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const commissioningRules = useAppSelector((state) => state.me.me?.rules.commissioning)

  if(!commissioningRules?.visible) navigate('/')

  return (
    <>
      <DatalistFilter/>
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        <HeaderPage title={t('commissioning.title')} addLink={commissioningRules?.actions.update ? `/${t('url.commissioning.add-choice-type')}` : undefined}/>
        <TabCommissioning/>
        <CommissioningDatalist/>
      </main>
    </>
  )
}

export default CommissioningDashboard
