import React, { FunctionComponent, useEffect, useState } from 'react'
import InputDate from '../../../Elements/InputDate'
import { useTranslation } from 'react-i18next'
import Input from '../../../Elements/Input'
import CommissioningGateway
  from '../../../../../../gateway/Commissioning/CommissioningGateway'
import MultiSelect from '../../../Elements/MultiSelect'
import { useParams } from 'react-router-dom'
import SelectCustom from "../../../Elements/Select";
import {ReferentielInterface} from "../../../../../../domain/Referentiel/ReferentielInterface";
import {useAppSelector} from "../../../../../store/hook";
import MatchUrl from "../../../../../../domain/Utils/MatchedUrl";

type TProps = {
  register: any
  control: any
  getValues: any
  setValue: any
  watch: any
  classes: string
  parentPath: string
  title: string
  titleSecondary: string
}

const SessionCriteriaToggle: FunctionComponent<TProps> = ({
                                                            register,
                                                            control,
                                                            getValues,
                                                            setValue,
                                                            watch,
                                                            classes,
                                                            parentPath,
                                                            title,
                                                            titleSecondary,
                                                          }) => {
  const { t } = useTranslation()
  const { commissioningType, uuid } = useParams()
  const isLectureMode = MatchUrl.find(location.pathname, 'lecture')

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)

  const [products, setProducts] = useState<any>([])
  const [partners, setPartners] = useState<any>([])

  const periodicity = watch(`${parentPath || ''}periodicity`)
  const dateStart = watch(`${parentPath || ''}dateStart`)
  const dateEnd = watch(`${parentPath || ''}dateEnd`)
  const multiCriteria = watch(`${parentPath || ''}multiCriteria`)

  useEffect(() => {
    if (multiCriteria &&
      referential?.commission?.periodicity_type &&
      referential.commission.periodicity_type.length > 0 &&
      !getValues(`${parentPath || ''}periodicity`)
    ) {
      setValue(`${parentPath || ''}periodicity`, referential?.commission.periodicity_type[0].value)
    }
  }, [referential, multiCriteria])

  useEffect(() => {
    if (multiCriteria && dateStart && dateEnd) {
      fetchProductAndPartnerAvailable()
    }
  }, [multiCriteria, dateStart, dateEnd])

  const fetchProductAndPartnerAvailable = () => {
    const type = commissioningType ? commissioningType.replace('_', '') : ''

    new CommissioningGateway()
      .getProductsAvailable(
        type,
        dateStart.replaceAll('/', '-'),
        dateEnd.replaceAll('/', '-'))
      .then((data) => {
        if(data.length) {
          const productFiltered = [...data]
          const newArray = productFiltered.map((product) => {
            return {
              id: product.id,
              label: product.label,
              value: product.id
            }
          })
          setProducts(newArray)
        }
      })
    new CommissioningGateway()
      .getPartnersAvailable(
        type,
        dateStart.replaceAll('/', '-'),
        dateEnd.replaceAll('/', '-'))
      .then((data) => {
        if(data.length) {
          const partnersFiltered = [...data]
          const newArray = partnersFiltered.map((partner) => {
            return {
              id: partner.id,
              label: `${partner.socialReason} - ${partner.partnerCode || ""}`,
              value: partner.id,
            }
          })
          setPartners(newArray)
        }
    })
  }

  return (
    <div className={classes}>
      <div className={`form-bloc`}>
        <div className='flex form-bloc__title justify-between items-center'>
          <span className={`${multiCriteria ? '' : 'opacity-30 u-txt-size-s'}`}>{title}</span>
          <input type='checkbox'
                 name={`${parentPath || ''}multiCriteria`}
                 checked={multiCriteria}
                 onChange={(e) => {
                   setValue(`${parentPath || ''}multiCriteria`, e.target.checked)
                 }}
                 id={`${parentPath || ''}multiCriteria`}
                 className='checkbox-toggle checkbox-toggle--light no-skin checkbox-toggle-radio-default'
                 disabled={!!uuid}
          />
          <label className='checkbox-toggle__button multi-criteria'
                 htmlFor={`${parentPath || ''}multiCriteria`}
          />
          <span className={`${!multiCriteria ? '' : 'opacity-30 u-txt-size-s'}`}>{titleSecondary}</span>
        </div>

        <div className='form-bloc__form flex-container'>
          {
            !multiCriteria && <div className='col-md-12'>
              <Input register={register}
                     label={t('commissioning.form.setting.sessionCriteria.movementNb')}
                     type={'text'}
                     name={`${parentPath || ''}criteria_movement`}
                     id={`${parentPath || ''}criteria_movement`}
                     classes="mandatory-field"
              />
            </div>
          }
          {
            multiCriteria && <>
              <SelectCustom
                classes={`col-md-12`}
                id={`${parentPath || ''}periodicity`}
                name={`${parentPath || ''}periodicity`}
                options={referential?.commission.periodicity_type || []}
                register={register}
                label={t('commissioning.form.setting.sessionCriteria.periodicity')}
                noChoiceOption={true}
                disabled={!multiCriteria}
              />
              <InputDate id={`${parentPath || ''}dateStart`}
                         name={`${parentPath || ''}dateStart`}
                         label={t('commissioning.form.setting.sessionCriteria.dateStart')}
                         register={register}
                         control={control}
                         classes='col-md-6'
                         readOnly={isLectureMode}
                         disabled={!periodicity}
              />
              <InputDate id={`${parentPath || ''}dateEnd`}
                         name={`${parentPath || ''}dateEnd`}
                         label={t('commissioning.form.setting.sessionCriteria.dateEnd')}
                         register={register}
                         control={control}
                         classes='col-md-6'
                         readOnly={isLectureMode}
                         disabled={!periodicity}
              />
              <MultiSelect classes='col-md-12'
                           id={`${parentPath || ''}products`}
                           name={`${parentPath || ''}products`}
                           label={t('commissioning.form.setting.sessionCriteria.product')}
                           control={control}
                           defaultValue={getValues(`${parentPath || ''}products`)}
                           options={products || []}
                           customOnChange={(options) => {
                             setValue(`${parentPath || ''}products`, options)
                           }}
                           disabled={isLectureMode || !multiCriteria || !dateStart || !dateEnd}
              />

              <MultiSelect classes='col-md-12'
                           id={`${parentPath || ''}partners`}
                           name={`${parentPath || ''}partners`}
                           label={t('commissioning.form.setting.sessionCriteria.partner')}
                           control={control}
                           defaultValue={getValues(`${parentPath || ''}partners`)}
                           options={partners || []}
                           customOnChange={(options) => {
                             setValue(`${parentPath || ''}partners`, options)
                           }}
                           disabled={isLectureMode || !multiCriteria || !dateStart || !dateEnd}
              />
              <SelectCustom classes="col-md-12"
                            id={`${parentPath || ''}regularization`}
                            name={`${parentPath || ''}regularization`}
                            options={referential?.commission.regularisation || []}
                            label={t('commissioning.form.setting.sessionCriteria.regularization')}
                            register={register}
                            disabled={!multiCriteria}
              />
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default SessionCriteriaToggle
