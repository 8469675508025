export default class encoder {
  static dec2hex(dec: number): string {
    return ('0' + dec.toString(16)).substr(-2);
  }

  static sha256(plain: string): Promise<ArrayBuffer>  {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
  }

  static base64urlencode(a: ArrayBuffer): string {
    let str = '';
    const bytes = new Uint8Array(a);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      str += String.fromCharCode(bytes[i]);
    }
    return btoa(str)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
  }

  static async generateCodeChallengeFromVerifier(v: string): Promise<string> {
    const hashed = await encoder.sha256(v);
    const base64encoded = encoder.base64urlencode(hashed);

    return base64encoded;
  }
}
