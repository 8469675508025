import {Agreement} from "../../../domain/Movement/Agreement";

export default class InitializeAgreement {
  initializeAgreement() {
    return new Agreement(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      false,
      null,
      null,
      null,
      [],
      [],
      null,
      [],
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
  }
}
