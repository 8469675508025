import {Person, PersonInterface} from "../../../domain/Customer/Person";

export default class InitializePerson {
  initializePerson(): PersonInterface {
    return new Person(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
        null
    )
  }
}
