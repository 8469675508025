import React from "react";
import {Link} from "react-router-dom";
import iconSearch from "../../../../assets/images/icons/datalist-search.svg";
import {useTranslation} from "react-i18next";
import {MovementList} from "../../../../domain/Movement/MovementList";

export interface IMovementBadgeBox {
  title: string | null,
  transactions: MovementList[] | null
}

const MovementBadge: React.FC<IMovementBadgeBox> = ({ title = null, transactions = null }) => {
  const {t} = useTranslation()
  const getLinkTo = (type: string | null) => {
    switch (type) {
      case 'withdrawal':
        return t(`url.movements.read-redemption-withdrawal`);
      case 'agreement':
        return t(`url.movements.read-gre-gre`);
      case 'mutation':
        return t(`url.movements.read-mutations`);
      case 'subscription':
        return t(`url.pre-subscription.read`);
    }
  }

  return (
    <>
      <label>{title}</label>
      <div className="tag-box">
        {
          transactions && transactions.map(trans => (
            <div className="tag-item">
              <Link to={`/${getLinkTo(trans.transaction_type)}/${trans.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div>
                  <div className="tag-title">
                    {`N° ${trans.code}`}
                    <img src={iconSearch} alt="Loupe"/>
                  </div>
                </div>
              </Link>
            </div>
          ))
        }
      </div>
    </>
  )
}

export default MovementBadge
