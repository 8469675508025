import {CommissioningSessionListInterface} from "../../../domain/Commissioning/CommissioningSessionList";
import {ListRequestInterface} from './ListRequest'
import {CallerType} from "../../../domain/Caller/Caller";
import {CommissioningSessionInterface} from "../../../domain/Commissioning/CommissioningList";
import CommissioningSessionGateway from "../../../gateway/Commissioning/CommissioningSessionGateway";

export default class ListUseCase {
  public commissioningGateway

  constructor(CommissioningGateway: CommissioningSessionGateway) {
    this.commissioningGateway = CommissioningGateway
  }

  async execute(
    ListRequest: ListRequestInterface,
    signal?: AbortSignal,
    sort?: string,
    order?: string,
    callerType?: CallerType
  ): Promise<{
    data: CommissioningSessionListInterface[] | null,
    numberOfItems: number,
    session: CommissioningSessionInterface
  } | null> {
    return await this.commissioningGateway.getCommissioningSessionMovement(
      ListRequest.currentPage,
      ListRequest.itemPerPage,
      ListRequest.filter,
      ListRequest.sessionId,
      signal,
      sort,
      order,
      callerType
    ).then(response => {
      return response
    })
  }
}