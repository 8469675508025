import Caller from "../../../domain/Api/Caller";
import envVariable from "../../../infrastructure/presentation/util/envVariable";
import {Mutation} from "../../../domain/Movement/Mutation";
import {ActionList} from '../../../domain/Movement/Action/Action'
import TransactionGatewayInterface from "../../../domain/Movement/TransactionGatewayInterface";
import {distributionTransferorShareInterface} from "../../../domain/Movement/DistributionTransferorShare";

export default class MutationGateway implements TransactionGatewayInterface{

  private prepareData(mutation: Mutation, investorsReceivingShares: distributionTransferorShareInterface[]): Record<string, unknown> {
    let investors: any[] = []
    if(mutation.investors && mutation.investors[0]) {
      investors = [...investorsReceivingShares, {
        type: "transferor",
        prospect: mutation.investors[0].prospect?.id,
        partner: mutation.investors[0].partner?.id,
      }]
    }

    return {
      "propertyType": mutation.propertyType,
      "possessionMode": mutation.possessionMode,
      "product": mutation.product?.id,
      "executedAt": mutation.executedAt,
      "shareCount": mutation.shareCount,
      "horodatage": mutation.horodatage,
      "comment": mutation.comment,
      "note": mutation.note,
      "allShares": mutation.allShares,
      "sharePrice": mutation.sharePrice,
      "valueDate": mutation.valueDate,
      "flatFee": mutation.flatFee,
      "amount": mutation.amount,
      "subType": mutation.subType,
      "transactionExecutionAt":  mutation.transactionExecutionAt,
      "investors": investors,
      "slices": mutation.slices?.map((slice) => {
        return {
          "id" : slice.id,
          "countShareSelected": slice.countShareSelected
        }
      })
    }
  }

  get(uuid:string) : Promise<Mutation> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/mutations/${uuid}`, {})
    .then(data => {
      if (data) {
        return data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }

  create(mutation: Mutation, investorsReceivingShares: distributionTransferorShareInterface[]): Promise<Mutation> {


    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/mutations`,
      this.prepareData(mutation, investorsReceivingShares)
    )
    .then(data => {
      if (data?.id) {
        return data
      }

      return null
    })
  }

  update(mutation: Mutation, investorsReceivingShares: distributionTransferorShareInterface[]): Promise<Mutation> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/mutations/${mutation.id}`,
      this.prepareData(mutation, investorsReceivingShares)
    )
    .then(data => {
      if (data?.id) {
        return data
      }

      return null
    })
  }

  getActions(uuid: string): Promise<ActionList> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/mutations/${uuid}/actions`, {})
    .then(data => {
      if (data) {
        return data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }

  setAction(uuid: string, actionSlug: string, date: string, reason: string|null): Promise<ActionList> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/mutations/action/${uuid}/${actionSlug}`, {
      'date': date,
      'reason': reason
    })
    .then(data => {
      if (data) {
        return data
      }

      return null
    })
  }

  updateAction(uuid: string, actionSlug: string, date: string|null): Promise<ActionList> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/${uuid}/${actionSlug}`, {
      'date': date
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
  }
}
