import {Presenter} from '../Presenter'
import {ProductListInterface} from '../../domain/Product/ProductList'

class ProductListPresenter extends Presenter {

  private useCase: { getProducts: any }

  constructor(getProducts: any) {
    super({
      viewModel: {
        title: 'Tableau de la session',
        heading: [
          {name: 'product.heading.Action'},
          {name: 'product.heading.acronym', sort: 'acronyme'},
          {name: 'product.heading.name', sort: 'label'},
          {name: 'product.heading.type', sort: 'product_type_label'},
          {name: 'product.heading.subscription', sort: 'subscribable'},
          {name: 'product.heading.status', sort: 'status'},
          {name: 'product.heading.nbPart', sort: 'share_count'},
        ],
        datas: [],
        filtersShortcut: [
          {'keyword': 'filters.by-lastname', 'field': 'name', 'type': 'input'},
          {'keyword': 'filters.by-keyword-or-id', 'field': 'keywords', 'type': 'input'}
        ],
        filters: [
        ],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getProducts,
    }
  }

  async load() {
    try {
      this._setProducts(await this.useCase.getProducts);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setProducts(products: { data: ProductListInterface[]|null, nb_element: number }) {
    this.update({
      data: products.data,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': products.nb_element,
      }
    });
  }
}

export default ProductListPresenter
