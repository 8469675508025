import type {Blocker, History, Transition} from 'history';
import React, {ContextType, useCallback, useContext, useEffect} from 'react';
import {
    Navigator as BaseNavigator,
    UNSAFE_NavigationContext as NavigationContext
} from 'react-router-dom';
import WarningModal from "../component/Modal/WarningModal";
import {confirmAlert} from "react-confirm-alert";

interface Navigator extends BaseNavigator {
    block: History['block'];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & { navigator: Navigator };

export function useBlocker(blocker: Blocker, when = true) {
    const {navigator} = useContext(NavigationContext) as NavigationContextWithBlock;
    useEffect(() => {
        if (!when) {
            return;
        }
        const unblock = navigator.block((tx: Transition) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                },
            };
            blocker(autoUnblockingTx);
        });
        return unblock;
    }, [navigator, blocker, when]);
}

export function usePrompt(
    when = true,
    onConfirm: (data: any) => void,
) {
    const blocker = useCallback((tx: Transition) => {

        const openModal = () => {
            confirmAlert({
                customUI: ({onClose}) => {
                    return (<WarningModal
                        onConfirm={(data) => {
                            onConfirm(data);
                            tx.retry();
                        }}
                        onContinue={() => {
                            onClose();
                            tx.retry();
                        }}
                        onClose={onClose}
                    />)
                }
            })
        };
        openModal();
    }, []);
    return useBlocker(blocker, when);
}

