import {FunctionComponent, FormEvent, SelectHTMLAttributes} from 'react'
import {useTranslation} from 'react-i18next'

import {IFormFieldProps} from './IFormFieldProps'
import {ReferentielItemInterface} from '../../../../domain/Referentiel/ReferentielItemInterface'

interface IProps extends SelectHTMLAttributes<HTMLSelectElement>, IFormFieldProps {
  classes: string
  label?: string
  options: ReferentielItemInterface[]|null
  value?: string
  defaultValue?: string
  onChange?: (e: FormEvent<HTMLSelectElement>) => void
  classesFormControl?: string
}

const SelectCustomView: FunctionComponent<IProps> = ({ classes,
                                                   label,
                                                   onChange,
                                                   value,
                                                   defaultValue,
                                                   error,
                                                   required,
                                                   options,
                                                   classesFormControl,
                                                   ...rest}) => {
  const {t} = useTranslation()

  return (
    <>
      <div className={`${classes}`}>
          {label &&
            <div className={`form-control ${classesFormControl ? classesFormControl : ''}`}>
              <label className="form-control__label">{label}</label>
              <div className="form-control__input">
                <select required={required}
                        disabled={rest.readOnly}
                        onChange={onChange}
                        title={rest.help}>
                  <option value="">{t('common.choice-option')}</option>
                  {options?.map((option, index) => <option key={index} value={option.value} selected={defaultValue === option.value}>{option.label}</option>)}
                </select>
              </div>
            </div>
          }
          {!label &&
            <div className="form-control__input">
                <select required={required}
                        disabled={rest.readOnly}
                        onChange={onChange}
                        title={rest.help}>
                  <option value="">{t('common.choice-option')}</option>
                  {options?.map((option, index) => <option key={index} value={option.value} selected={defaultValue === option.value}>{option.label}</option>)}
                </select>
              </div>
          }
        </div>
    </>
  )
}

export default SelectCustomView
