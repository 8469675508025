import {ProductType} from "../../../domain/Product/ProductType";
import {ReferentielInterface} from "../../../domain/Referentiel/ReferentielInterface";
import {ReferentielItemInterface} from "../../../domain/Referentiel/ReferentielItemInterface";

export default class List {
  public static filterNaturePropertyList(status: string|null, productType: ProductType|null, propertyList: ReferentielItemInterface[]): ReferentielItemInterface[] {
    if (null === productType || propertyList.length < 1) {
      return propertyList
    }

    if(status === "counterparty_found" && propertyList) {
      return propertyList.map((nature) => {
        if(nature.value === "np") return {...nature, label: "NP"}
        if(nature.value === "usufruct") return {...nature, label: "USU"}
        return nature
      })
    }
    if (propertyList && productType) {
      if (productType.slug === 'scpi' || productType.slug === 'opci') {
        return propertyList.filter(nature => ['dismemberment', 'usufruct', 'np', 'full_property'].indexOf(nature.value.toString()) > -1)
      }
      if (productType.slug === 'fia' || productType.slug === 'sc' || productType.slug === 'other') {
        return propertyList.filter(nature => nature.value === 'full_property')
      }
    }
    return propertyList;
  }

  public static filterDetentionModeList(productType: ProductType|null, possessionList: ReferentielItemInterface[]): ReferentielItemInterface[] {
    if (possessionList && productType) {
      if (productType.slug === 'scpi') {
        return possessionList.filter(detention => detention.value === 'nominative_pure')
      } else if (productType.slug === 'sc') {
        return possessionList.filter(detention => detention.value === 'nominative_administered')
      } else if (productType.slug === 'fia' || productType.slug === 'other' || productType.slug === 'opci') {
        return possessionList
        .filter(detention =>
          ['nominative_pure',
            'nominative_administered',
            'nominative_administered_pea',
            'nominative_pea_pme', 'title'
          ].indexOf(detention.value.toString()) > -1)
      }
    }
    return possessionList;
  }
}
