import {combineReducers} from '@reduxjs/toolkit'

import meReducer from './component/me'
import eventReducer from './component/event'
import partnerReducer from './component/partner'
import productReducer from './component/product'
import prospectReducer from './component/prospect'
import customerReducer from './component/customer'
import movementReducer from './component/movement'
import sliceReducer from './component/slice'
import settingsReducer from './component/settings'
import userReducer from './component/user'
import referentialReducer from './component/referential'

const rootReducer = combineReducers({
  me: meReducer,
  event: eventReducer,
  partner: partnerReducer,
  product: productReducer,
  prospect: prospectReducer,
  customer: customerReducer,
  movement: movementReducer,
  slice: sliceReducer,
  settings: settingsReducer,
  user: userReducer,
  referential: referentialReducer
})

export default rootReducer
