import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

function StringDateToDateObject(dateString: string, format: string) {
  return dayjs(dateString, format)
}

function dateToString(date: Date | null): string {
  return date ? date.toLocaleDateString('fr') : '';
}

function stringToDate(date?: string|null, format = 'DD/MM/YYYY'): Date | null {
  if(!date || !dayjs(date, format).isValid()) {
    return null;
  }
  return dayjs(date, format).toDate();
}


export {
  StringDateToDateObject,
  dateToString,
  stringToDate
}
