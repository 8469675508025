import MutationGateway from '../../../gateway/Movement/Mutation/MutationGateway'
import {Mutation} from '../../../domain/Movement/Mutation'

export default class MutationGetUseCase
{
  public mutationGateway

  constructor(
    mutationGateway: MutationGateway
  ) {
    this.mutationGateway = mutationGateway
  }

  async execute(id: string): Promise<Mutation> {
    return await this.mutationGateway.get(id).then(response => {
      return response
    })
  }
}
