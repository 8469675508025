import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import eyeIcon from "../../../../assets/images/icons/eye.svg";
import {DocumentProspectInterface} from "../../../../domain/Prospect/Document/Document";
import {setOpenDocumentList} from "../../../store/component/event";
import DocumentGateway from "../../../../gateway/Prospect/Document/DocumentGateway";
import {useAppDispatch} from "../../../store/hook";
import DocumentGatewayInterface from "../../../../domain/Document/DocumentGatewayInterface";

type Props = {
  documents: DocumentProspectInterface[]
  gateway: DocumentGatewayInterface
}
const DocumentHistoryBlock: FunctionComponent<Props> = ({documents, gateway}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  function showDocumentList() {
    dispatch(setOpenDocumentList({
      show: true,
      documentList: documents,
      gateway: gateway
    }))
  }

  return (
    <span>
      <span>{t('common.history-file')} </span>
      <button type="button" className="button-reset u-pxs" onClick={() => showDocumentList()}>
        <img style={{verticalAlign: 'inherit'}} src={eyeIcon} alt="" />
      </button>
    </span>
  )
}
export default DocumentHistoryBlock
