import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../../../../store/hook'

import HeaderPage from '../../../../component/HeaderPage/HeaderPage'
import Form from '../../../../component/Form/Mutation/Form/Form'
import ChoiceSliceToGiveUp from '../../../../component/Sidebar/Movement/ChoiceSliceToGiveUp'
import ChoiceInvestor from '../../../../component/Sidebar/Movement/ChoiceInvestor'
import MutationGetUseCase from '../../../../../../useCase/Movement/Get/MutationGetUseCase'
import MutationGateway from '../../../../../../gateway/Movement/Mutation/MutationGateway'
import {Mutation} from '../../../../../../domain/Movement/Mutation'
import InitializeMutation from '../../../../../../useCase/Movement/Initialization/InitializeMutation'
import {addMultipleSliceToTransaction, addMultipleSliceToTransactionFreeze} from '../../../../../store/component/slice'
import Upload from "../../../../component/Upload/Upload";
import DocumentGateway from "../../../../../../gateway/Movement/Mutation/Document/DocumentGateway";
import {setOpenUploadForm} from "../../../../../store/component/event";
import MatchUrl from "../../../../../../domain/Utils/MatchedUrl";
import ChoicePartner from "../../../../component/Sidebar/Prospect/ChoicePartner";
import DocumentList from "../../../../component/Document/DocumentList";
import {TError} from "../../../../../../domain/Error/Error";
import ErrorPage from "../../../Error/ErrorPage";
import Spinner from "../../../../component/Spinner/Spinner";

const AddOrEdit: FunctionComponent = () => {
  const {t} = useTranslation()
  const {uuid} = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const transactionRules = useAppSelector((state) => state.me.me?.rules.transaction.actions)
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const reloadMovement = useAppSelector((state) => state.movement.reloadMovement)
  const [mutation, setMutation] = useState<Mutation | null>(null)
  const openUploadForm = useAppSelector((state) => state.event.openUploadForm)
  const isLectureMode = MatchUrl.find(location.pathname, 'lecture')
  const [error, setError] = useState<TError|null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if(transactionRules) {
      if(!transactionRules?.update && !isLectureMode) navigate(`/${t('url.movements.read-mutations')}/${uuid}`)
    }
  }, [transactionRules])

  useEffect(() => {
    if (uuid !== undefined) {
      new MutationGetUseCase(new MutationGateway()).execute(uuid).then(response => {
        if (response !== null) {
          setMutation(response)
          const closedStatus = ["cancelled", "validated", "subscribed"]
          if(response?.status && closedStatus.includes(response.status)) {
            navigate(`/${t('url.movements.read-mutations')}/${uuid}`)
          }
          const transferor = response.investors?.find((investor) => {
            return investor?.type === "transferor" || null
          })

          if (response.slices && transferor) {
            const slicesFormatted = response.slices.map((slice) => {
              return {
                ...slice,
                investorId: transferor?.prospect?.id || undefined,
                product: {
                  ...slice.product,
                  id: response?.product?.id || undefined
                }
              }
            })
            dispatch(addMultipleSliceToTransaction(slicesFormatted))
          }
          if (response.slicesDetail && transferor) {
            const slicesFormatted = response.slicesDetail.map((slice) => {
              return {
                ...slice,
                investorId: transferor?.prospect?.id || undefined,
                product: {
                  ...slice.product,
                  id: response?.product?.id || undefined
                }
              }
            })
            dispatch(addMultipleSliceToTransactionFreeze(slicesFormatted))
          }
          setIsLoading(false)
        }
      }).catch((e) => {
        setIsLoading(false)
        setError(e)
      })
    } else {
      setMutation(new InitializeMutation().initializeMutation())
      setIsLoading(false)
    }
  }, [uuid, reloadMovement])

  if(error) return <ErrorPage code={error.code.toString()} />

  return (
    <>
      {!isLectureMode && <ChoiceInvestor/>}
      {!isLectureMode && <ChoicePartner/>}
      <ChoiceSliceToGiveUp/>
      <DocumentList />
      {mutation && <Upload openUploadForm={openUploadForm} gateway={new DocumentGateway(mutation.id ?? null, null)}
                           callback={setOpenUploadForm}/>}

      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''} movement`}>
        <HeaderPage title={t('movement.mutation.title')}/>
        {mutation && !isLoading ? <Form movement={mutation} isLectureMode={isLectureMode}/> : <section className="container--spinner"><Spinner size={150}/></section>}
      </main>
    </>
  )
}

export default AddOrEdit
