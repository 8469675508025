import React, {FunctionComponent, useEffect, useState} from 'react';
import SelectCustom from "../Elements/Select";
import {optionsNbRows} from "../../../../fixtures/Referentiel";
import {v4 as uuidV4} from "uuid";
import iconSearch from "../../../../assets/images/icons/datalist-search.svg";
import Pagination from "../Pagination/Pagination";
import {useTranslation} from "react-i18next";
import HistoryTransferListPrsenter from "../../../../presenter/Partner/TransferHistoryListPresenter";
import {ReferentielInterface} from "../../../../domain/Referentiel/ReferentielInterface";
import {setOpenHistoryTransferDetails} from "../../../store/component/event";
import { useAppDispatch, useAppSelector } from '../../../store/hook'
import {Transfer} from "../../../../domain/Partner/Transfer";
import {useForm} from "react-hook-form";
import {IPagination} from "../../../../domain/Pagination/IPagination";
import {saveNbRowsInLocalStorage} from "../../util/SavePreferencesInLocalStorage";


interface ViewModelInterface {
  title: string;
  heading: [];
  data: Transfer[];
  filtersShortcut: [];
  filters: [];
  count: number;
}

type Props = {
  historyTransfers: any | null
  isLectureMode?: boolean
  partnerId?: string
  pagination: IPagination
  setPagination: any
}

const TransferHistoryDatalist: FunctionComponent<Props> = (
  {
    historyTransfers,
    partnerId,
    pagination,
    setPagination
  }) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch()

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)

  const [viewModel, setViewModel] = useState<ViewModelInterface | null>(null);
  const controller = new AbortController()

  const preferences = localStorage.getItem("preferences")

  const {watch, setValue, register} = useForm()

  const watchNumberRows = watch('numberRows')

  useEffect(() => {
    computeDisplayedData()
  }, [historyTransfers])

  useEffect(() => {
    if (!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      setPagination({...pagination, itemPerPage: parseInt(watchNumberRows, 10)})
      saveNbRowsInLocalStorage(preferences, watchNumberRows.toString())
    }
  }, [watchNumberRows])

  const paginate = (pageNumber: number) => {
    controller.abort();
    setPagination({...pagination, page: pageNumber})
  };

  const computeDisplayedData = () => {
    const presenter = new HistoryTransferListPrsenter(historyTransfers ?? []);
    setViewModel(presenter.immutableViewModel());
  };

  return (
    <>
      <div className={`datalist`}>
        {viewModel && <>
            <div className="datalist__title">{t(viewModel?.title)}</div>

            <SelectCustom classes="flex justify-end u-mbs" id="numberRows"
                          name="numberRows"
                          label={t('filters.display-results-by')}
                          options={optionsNbRows}
                          register={register}
                          noChoiceOption
            />
            <div className="overflow-auto">
                <table className="datalist__datas">
                    <thead>
                    <tr>
                      {viewModel.heading.map((title: string) => (
                        <th key={uuidV4()}>{t(title)}</th>
                      ))}
                    </tr>
                    </thead>
                    <tbody>
                    {viewModel.data !== undefined && viewModel.data.map((item: Transfer, index: number) => (
                      <tr key={uuidV4()}>
                        <td className="">
                          <button className="button"
                                  onClick={() => dispatch(setOpenHistoryTransferDetails({show: true, item, partnerId}))}
                          >
                            <img src={iconSearch} alt=""/>
                          </button>
                        </td>

                        <td>{item.date}</td>
                        <td>
                          {referential?.global.transaction_status.find(property => {
                            return property.value === item.status;
                          })?.label.toString()}
                        </td>
                        <td>{`${item?.transferor_partner_code || "N/A"} - ${item.transferor_social_reason}`}</td>
                        <td>{`${item?.target_partner_code || "N/A"} - ${item.target_social_reason}`}</td>
                      </tr>
                    ))}
                    {viewModel.data === undefined || viewModel.data.length === 0 &&
                        <tr>
                            <td colSpan={viewModel.heading.length}>{t('common.data-is-empty')}</td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        </>}
      </div>
      <Pagination currentPage={pagination.page}
                  itemsPerPage={pagination.itemPerPage}
                  numberOfItems={pagination.nbElements}
                  callback={paginate}
      />
    </>

  );
}

export default TransferHistoryDatalist;