import Caller from '../../domain/Api/Caller'
import envVariable from '../../infrastructure/presentation/util/envVariable'

export default class MeGateway {
  me(): Promise<any> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/me`, {})
    .then(data => {
      return data
    })
    .catch(() => {
      return []
    });
  }
}
