import {CustomerListInterface, FilterCustomerInterface} from "../../domain/Customer/CustomerList";
import Caller from "../../domain/Api/Caller";
import envVariable from "../../infrastructure/presentation/util/envVariable";
import ClientList from "../../domain/Client/ClientList";

export default class ClientGateway {
  getList(currentPage: number, itemsPerPage: number, filter: FilterCustomerInterface | null, signal?: AbortSignal, sort?: string,
          order?: string): Promise<{ data: ClientList[] | null, numberOfItems: number } | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/accounts?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`, {
      lastName: filter?.lastName || '',
      maidenName: filter?.maidenName || '',
      keywords: filter?.keywords || '',
      birthDate: filter?.birthDate || '',
    },
    signal
    ).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    }).catch(response => {
      return {
        data: [],
        numberOfItems: 0
      }
    });
  }
}
