import {City} from "../Referentiel/City/City";
import {Country} from "../Referentiel/Country/Country";

interface AddressInterface {
  id: string|null
  createdAt: string|null
  updatedAt: string|null
  deletedAt: string|null
  title: string|null
  firstname: string|null
  lastname: string|null
  maidenname: string|null
  address: string|null
  address2: string|null
  address3: string|null
  postcode: string|null
  city: City|null
  country: Country|null
  npai: boolean
  npaiAt: string|null
  socialReason: string|null
}

class Address implements AddressInterface{

  public id: string|null
  public createdAt: string|null
  public updatedAt: string|null
  public deletedAt: string|null
  public title: string|null
  public firstname: string|null
  public lastname: string|null
  public maidenname: string|null
  public address: string|null
  public address2: string|null
  public address3: string|null
  public postcode: string|null
  public city: City|null
  public country: Country|null
  public npai: boolean
  public npaiAt: string|null
  public socialReason: string|null

  constructor(
    id: string|null,
    createdAt: string|null,
    updatedAt: string|null,
    deletedAt: string|null,
    title: string|null,
    firstname: string|null,
    lastname: string|null,
    maidenname: string|null,
    address: string|null,
    address2: string|null,
    address3: string|null,
    postcode: string|null,
    city: City|null,
    country: Country|null,
    npai: boolean,
    npaiAt: string|null,
    socialReason: string|null
  ) {
    this.id = id
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.deletedAt = deletedAt
    this.title = title
    this.firstname = firstname
    this.lastname = lastname
    this.maidenname = maidenname
    this.address = address
    this.address2 = address2
    this.address3 = address3
    this.postcode = postcode
    this.city = city
    this.country = country
    this.npai = npai
    this.npaiAt = npaiAt
    this.socialReason = socialReason
  }
}

export {
  Address
}
export type { AddressInterface }