import {Presenter} from '../../Presenter'
import {NetAssetValueItem} from '../../../domain/Product/NetAssetValueItem'

class NetAssetListPresenter extends Presenter {

  private useCase: { getNavs: any }

  constructor(getNavs: any) {
    super({
      viewModel: {
        title: 'product.form.netAssetValue.history',
        heading: [
          {name: 'product.form.netAssetValue.puslibshedAt', sort: 'published_at'},
          {name: 'product.form.netAssetValue.value', sort: 'value'},
          {name: 'product.form.netAssetValue.startDate', sort: 'start_date'},
          {name: 'product.form.netAssetValue.startEnd', sort: 'start_end'}
        ],
        datas: [],
        filtersShortcut: [],
        filters: [],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getNavs,
    }
  }

  async load() {
    try {
      this.setNavs(await this.useCase.getNavs);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  setNavs(navs: { data: NetAssetValueItem[]|null, nb_element: number }) {
    this.update({
      data: navs.data,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': navs.nb_element,
      }
    });
  }
}

export default NetAssetListPresenter
