import React, {FunctionComponent, MouseEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../store/hook";
import HeaderPage from "../../../component/HeaderPage/HeaderPage";
import TabSettings from "../../../component/Tab/Settings/TabSettings";
import Input from "../../../component/Elements/Input";
import {SubmitHandler, useForm} from "react-hook-form";
import {setOpenLogoUploadForm} from '../../../../store/component/event';
import {
    setColorBackground,
    setColorFont,
    setColorHeader,
    setColorPrimary,
    setColorSecondary
} from '../../../../store/component/settings';
import expandIcon from "../../../../../assets/images/icons/arrow-bottom--dark.svg";
import eyeIcon from '../../../../../assets/images/icons/eye.svg'
import uploadIcon from '../../../../../assets/images/icons/upload.svg'
import GetUseCase from "../../../../../useCase/Settings/Get/GetUseCase";
import GeneralSettingsGateway
    from "../../../../../gateway/Settings/GeneralSettings/GeneralSettingsGateway";
import UpdateUseCase from "../../../../../useCase/Settings/Update/UpdateUseCase";
import {Logo} from "../../../../../domain/Settings/GeneralSettings/Logo/Logo";
import Upload from "../../../component/Sidebar/Settings/Upload";
import ColorPicker from "../../../component/Elements/ColorPicker";
import {toastError, toastSuccess} from '../../../util/Toast'
import {SettingsInterface} from "../../../../../domain/Settings/GeneralSettings/Settings";
import InitializeSettings from "../../../../../useCase/Settings/Initialization/InitializeSettings";
import CountryDatalist from "../../../component/Datalist/Country/CountryDatalist";
import {City} from "../../../../../domain/Referentiel/City/City";
import {Country} from "../../../../../domain/Referentiel/Country/Country";
import CityAndPostcode from '../../../component/Datalist/CityAndPostcode/CityAndPostcode';
import AddCityForm from '../../../component/Sidebar/Referentiel/AddCityForm';
import ReferentielGateway from '../../../../../gateway/Referentiel/ReferentielGateway';


interface IFormInput {
    companyName: string|null
    registration: string|null
    address1: string|null
    address2: string|null
    address3: string|null
    postcode: string|null
    city: City|null
    country: Country|null
    bankName: string|null
    bankIban: string|null
    bankSwift: string|null
    colorPrimary: string|null
    colorSecondary: string|null
    colorFont: string|null
    colorBackground: string|null
    colorHeader: string|null
}

function handleCollapse(event: MouseEvent<HTMLElement>) {
    event.currentTarget.classList.toggle('collapse__title--active')
}

const GeneralSettings: FunctionComponent = () => {
    const {t} = useTranslation()

    const countryByDefault = useAppSelector(({referential}) => referential.countryByDefault)
    const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
    const dispatch = useAppDispatch()
    const settingsStore = useAppSelector((state) => state.settings)
    const [generalSettings, setGeneralSettings] = useState<SettingsInterface | null>(null)


    useEffect(() => {
        if (countryByDefault) {
            if (generalSettings) {
                setValue('country', generalSettings.country ?? countryByDefault[0])
            } else {
                setValue('country', countryByDefault[0])
            }
        }
    }, [countryByDefault, generalSettings])

    useEffect(() => {
        new GetUseCase(new GeneralSettingsGateway()).execute().then(
            response => {
                if (response !== null) {
                    setGeneralSettings(response)
                }
            }
        );

    }, [])

    function viewFile(type: string) {
        // TODO : view Logo
    }

    const {register, control, handleSubmit, setValue, getValues, watch} = useForm<IFormInput>();

    const watchCountry = watch('country')

    useEffect(() => {
        if (generalSettings) {

            setValue('companyName', generalSettings.companyName)
            setValue('address1', generalSettings.address1)
            setValue('address2', generalSettings.address2)
            setValue('address3', generalSettings.address3)
            setValue('postcode', generalSettings.postcode)
            setValue('city', generalSettings.city)
            // setValue('country', generalSettings.country)
            setValue('registration', generalSettings.registration)

            setValue('bankName', generalSettings.bankName)
            setValue('bankIban', generalSettings.bankIban)
            setValue('bankSwift', generalSettings.bankSwift)

            setValue('colorPrimary', generalSettings.colorPrimary)
            setValue('colorSecondary', generalSettings.colorSecondary)
            setValue('colorFont', generalSettings.colorFont)
            setValue('colorBackground', generalSettings.colorBackground)
            setValue('colorHeader', generalSettings.colorHeader)
        }
    }, [generalSettings])

    const onSubmit: SubmitHandler<IFormInput> = data => {
        const generalSettings = new InitializeSettings().initializeSettings()
        generalSettings.companyName = data.companyName
        generalSettings.registration = data.registration
        generalSettings.address1 = data.address1
        generalSettings.address2 = data.address2
        generalSettings.address3 = data.address3
        generalSettings.postcode = data.postcode
        generalSettings.city = data.city
        generalSettings.country = data.country
        generalSettings.bankName = data.bankName
        generalSettings.bankIban = data.bankIban
        generalSettings.bankSwift = data.bankSwift
        generalSettings.colorPrimary = data.colorPrimary
        generalSettings.colorSecondary = data.colorSecondary
        generalSettings.colorFont = data.colorFont
        generalSettings.colorBackground = data.colorBackground
        generalSettings.colorHeader = data.colorHeader

        new UpdateUseCase(new GeneralSettingsGateway()).execute(generalSettings).then(response => {
                if (null !== response) {
                    toastSuccess(t('settings.notify.update-success'))
                } else {
                    toastError(t('settings.notify.update-error'))
                }
            }
        );
    }

    const blocGeneralInformation = (
        <div className={`form-bloc form-bloc--general-information`}>
            <button type="button" className="collapse__title-full form-bloc__title"
                    onClick={handleCollapse}>
                {t('settings.form.bloc-general-information.title')}
                <img src={expandIcon} alt=""/>
            </button>
            <div className="collapse__content-full  form-bloc__form flex-container">

                <div className="col-md-6">
                    <div className="flex-container">
                        <Input register={register} classes={'col-md-12'}
                               label={t('settings.form.bloc-general-information.name')}
                               type={'text'} name={'companyName'} id={'companyName'}/>
                        <Input register={register} classes={'col-md-12'}
                               label={t('settings.form.bloc-general-information.address-first-line')}
                               type={'text'} name={'address1'} id={'address1'}/>
                        <Input register={register} classes={'col-md-12'}
                               label={t('settings.form.bloc-general-information.address-second-line')}
                               type={'text'} name={'address2'} id={'address2'}/>
                        <Input register={register} classes={'col-md-12'}
                               label={t('settings.form.bloc-general-information.address-third-line')}
                               type={'text'} name={'address3'} id={'address3'}/>
                        <div className="col-md-12">
                            <div className="form-control">
                                <label htmlFor="country"
                                       className="form-control__label">{t('settings.form.bloc-general-information.country')}</label>
                                <div className="form-control__input">
                                    <CountryDatalist control={control} name="country" defaultValue={getValues('country')}/>
                                </div>
                            </div>
                        </div>
                        <CityAndPostcode control={control}
                                         nameCity={'city'}
                                         namePostcode={'postcode'}
                                         country={watchCountry}
                                         register={register}
                                         setValue={setValue}
                                         getValues={getValues}
                                         classes="col-md-6 u-px0 u-mb0 flex"
                                         mode='col'
                                         menuPortalTarget
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="flex-container">
                        <Input register={register} classes={'col-md-12'}
                               label={t('settings.form.bloc-general-information.immatriculation')}
                               type={'text'} name={'registration'} id={'registration'}/>
                    </div>
                </div>
            </div>
        </div>
    );

    const blocBankDetail = (
        <div className={`form-bloc form-bloc--bank-details`}>
            <button type="button" className="collapse__title-full form-bloc__title"
                    onClick={handleCollapse}>
                {t('settings.form.bloc-bank-details.title')}
                <img src={expandIcon} alt=""/>
            </button>
            <div className="collapse__content-full  form-bloc__form flex-container">

                <div className="col-md-6">
                    <div className="flex-container">
                        <Input register={register} classes={'col-md-12'}
                               label={t('settings.form.bloc-bank-details.bank')} type={'text'}
                               name={'bankName'} id={'bankName'}/>
                        <Input register={register} classes={'col-md-12'}
                               label={t('settings.form.bloc-bank-details.iban')} type={'text'}
                               name={'bankIban'} id={'bankIban'}/>
                        <Input register={register} classes={'col-md-12'}
                               label={t('settings.form.bloc-bank-details.swift')} type={'text'}
                               name={'bankSwift'} id={'bankSwift'}/>
                    </div>
                </div>
            </div>
        </div>
    );

    const blocCustomColor = (
        <div className={`form-bloc form-bloc--general-information`}>
            <button type="button" className="collapse__title-full form-bloc__title"
                    onClick={handleCollapse}>
                {t('settings.form.bloc-color-settings.title')}
                <img src={expandIcon} alt=""/>
            </button>
            <div className="collapse__content-full  form-bloc__form flex-container">

                <div className="col-md-6">
                    <div className="flex-container">
                        <div className="form-control">
                            <div className="col-md-12">
                            <label
                                className="form-control__label">{t('settings.form.bloc-color-settings.logo')}</label>
                            </div>
                            <div>
                                <button type="button" className="button-reset u-mxs"
                                        onClick={() => viewFile('LOGO')}>
                                    <img src={eyeIcon} alt=""/>
                                </button>
                                <button type="button" className="button-reset u-mxs"
                                        onClick={() => dispatch(setOpenLogoUploadForm({
                                            // TODO : à implémenter
                                            show: true,
                                            logo: new Logo(0, '', '', '', '', ''),
                                            type: 'LOGO'
                                        }))}>
                                    <img src={uploadIcon} alt=""/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="col-md-6">
                        <button type="button" className="collapse__title" onClick={handleCollapse}>
                            {t('settings.form.bloc-color-settings.custom-color.title')}
                            <img src={expandIcon} alt=""/>
                        </button>
                        { generalSettings &&
                            <div className="collapse__content flex-container">

                                <div className="col-md-6">
                                    <ColorPicker
                                        name={'colorPrimary'}
                                        control={control}
                                        label={t('settings.form.bloc-color-settings.custom-color.color-primary')}
                                        defaultColor={settingsStore.colors?.colorPrimary}
                                        callback={hex => {
                                            dispatch(setColorPrimary(hex))
                                        }}/>
                                </div>
                                <div className="col-md-6">
                                    <ColorPicker
                                        name={'colorBackground'}
                                        control={control}
                                        label={t('settings.form.bloc-color-settings.custom-color.color-background')}
                                        defaultColor={settingsStore.colors?.colorBackground}
                                        callback={hex => {
                                            dispatch(setColorBackground(hex))
                                        }}/>
                                </div>
                                <div className="col-md-6">
                                    <ColorPicker
                                        name={'colorSecondary'}
                                        control={control}
                                        label={t('settings.form.bloc-color-settings.custom-color.color-secondary')}
                                        defaultColor={settingsStore.colors?.colorSecondary}
                                        callback={hex => {
                                            dispatch(setColorSecondary(hex))
                                        }}/>
                                </div>
                                <div className="col-md-6">
                                    <ColorPicker
                                        name={'colorHeader'}
                                        control={control}
                                        label={t('settings.form.bloc-color-settings.custom-color.color-header')}
                                        defaultColor={settingsStore.colors?.colorHeader}
                                        callback={hex => {
                                            dispatch(setColorHeader(hex))
                                        }}/>
                                </div>

                                <div className="col-md-6">
                                    <ColorPicker
                                        name={'colorFont'}
                                        control={control}
                                        label={t('settings.form.bloc-color-settings.custom-color.color-font')}
                                        defaultColor={settingsStore.colors?.colorFont}
                                        callback={hex => {
                                            dispatch(setColorFont(hex))
                                        }}/>
                                </div>

                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <AddCityForm/>
            {<Upload/>}
            <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
                <HeaderPage title={t('settings.title')}/>
                <TabSettings/>

                <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {blocGeneralInformation}
                        {blocBankDetail}
                        {blocCustomColor}
                        <footer className={`form-bloc__footer`}>
                            <button type="submit"
                                    className="button button--ink-2">{t('common.save')}</button>
                            <button type="button"
                                    className="button button--ink-2 button--ink-2--outline">{t('common.cancel')}</button>
                        </footer>
                    </form>
                </>

            </main>
        </>
    )
}

export default GeneralSettings
