import store from '../../../infrastructure/store/store'
import {
  setBankInformations,
  setRelations,
  setBeneficialOwner,
  setPartner,
  addCurrentAddress, addCurrentFiscalAddress
} from '../../../infrastructure/store/component/customer'
import CustomerGateway from '../../../gateway/Customer/CustomerGateway'
import {CustomerInterface} from '../../../domain/Customer/Customer'
import {BankInformation} from '../../../domain/Customer/BankInformation'
import {Relation} from '../../../domain/Customer/Relation'
import {BeneficialOwner} from '../../../domain/Customer/BeneficialOwner'
import {setLegalRepresentatives} from "../../../infrastructure/store/component/customer";
import {CustomerLegalRepresentatives} from "../../../domain/Customer/CustomerLegalRepresentatives";
import {Undivided} from "../../../domain/Prospect/Undivided";
import {setUndivided} from "../../../infrastructure/store/component/prospect";

export default class GetUseCase
{
  public customerGateway

  constructor(CustomerGateway: CustomerGateway) {
    this.customerGateway = CustomerGateway
  }

  async execute(id: string): Promise<CustomerInterface|null> {
    return await this.customerGateway.get(id).then(response => {

      if (response && response.customerAddresses) {
        response.customerAddresses.forEach((address) => {
          if (address.type === "postal" && address.main) {
            store.dispatch(addCurrentAddress(address))
          }
          if (address.type === "fiscal" && address.main) {
            store.dispatch(addCurrentFiscalAddress(address))
          }
        })
      }

      if (response && response.bankInformations) {
        const bankInformations: BankInformation[] = []
        response.bankInformations.map((bankInfo) => {
          bankInformations.push(bankInfo)
        })
        store.dispatch(setBankInformations(bankInformations))
      }

      if (response && response.relations) {
        const relations: Relation[] = []

        response.relations.map((relation) => {
          relations.push(relation)
        })
        store.dispatch(setRelations(relations))

      }

      if (response && response.beneficialOwners) {
        const beneficialOwners: BeneficialOwner[] = []

        response.beneficialOwners.map((owner) => {
          beneficialOwners.push(owner)
        })

        store.dispatch(setBeneficialOwner(beneficialOwners))
      }

      if (response && response.undivideds) {
        const undivideds: Undivided[] = []

        response.undivideds.map((owner) => {
          undivideds.push(owner)
        })
        store.dispatch(setUndivided(undivideds))
      }

      if (response && response.partner) {
        store.dispatch(setPartner(response.partner))
      }

      if (response && response.legalRepresentatives) {
        const legalRepresentatives: CustomerLegalRepresentatives[] = []

        response.legalRepresentatives.map((legalRepresentative) => {
          legalRepresentatives.push(legalRepresentative)
        })

        store.dispatch(setLegalRepresentatives(legalRepresentatives))
      }

      return response
    })
  }
}
