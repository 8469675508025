import React, {FunctionComponent, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm, useFormState} from 'react-hook-form'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {setOpenPartnerRelationLinkFormEvent} from '../../../../store/component/event'
import {addRelationLink, updateRelationLink} from '../../../../store/component/partner'
import {RelationLink} from '../../../../../domain/Partner/RelationLink'
import blockScrollBody from '../../../util/BlockScroll'
import getClassForOverlay from '../../../util/Sidebar'
import {
  ReferentielInterface
} from '../../../../../domain/Referentiel/ReferentielInterface'
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import {toastSuccess} from "../../../util/Toast";
import {usePrompt} from "../../../util/Navigation";

interface IFormInput {
  id: string
  lastName: string
  firstName: string
  link: string
}

type Props = {
  referential: ReferentielInterface
}

const AddRelationLink: FunctionComponent<Props> = ({referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openPartnerRelationLinkForm = useAppSelector((state) => state.event.openPartnerRelationLinkForm)

  useEffect(() => {
    blockScrollBody(openPartnerRelationLinkForm.show)

    if (openPartnerRelationLinkForm.relationLink) {
      setValue('id', openPartnerRelationLinkForm.relationLink.id)
      setValue('lastName', openPartnerRelationLinkForm.relationLink.lastName)
      setValue('firstName', openPartnerRelationLinkForm.relationLink.firstName)
      setValue('link', openPartnerRelationLinkForm.relationLink.link)
    } else {
      setValue('id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('lastName', '')
      setValue('firstName', '')
      setValue('link', '')
    }
  }, [openPartnerRelationLinkForm])

  const { register, control, watch, handleSubmit, reset, setValue } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    reset(data)
    const relationLink = new RelationLink(
      data.id,
      data.lastName,
      data.firstName,
      data.link,
    )

    if (openPartnerRelationLinkForm.relationLink) {
      dispatch(updateRelationLink(relationLink))
    } else {
      dispatch(addRelationLink(relationLink))
    }
    dispatch(setOpenPartnerRelationLinkFormEvent({show: false, relationLink: null}))

    reset()
    setValue('id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())

    toastSuccess(t('partner.notify.add-relation-link-success'))
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));


  function handleClose() {
    dispatch(setOpenPartnerRelationLinkFormEvent({show: false, relationLink: null}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openPartnerRelationLinkForm.show)}`}
           onClick={() => handleClose()} />
      <div
        className={`sidebar sidebar--right sidebar--right ${openPartnerRelationLinkForm.show ? 'sidebar--active' : ''}`}>
        <form onSubmit={handleSubmit(onSubmit)} className="form-bloc form-bloc--partner-relationLink-form">
          <div className="sidebar__content">
            <div className="title">{t('partner.form.relation-link.add')}</div>
            <div className="form-bloc__form flex-container">
              <div className="col-md-6">
                <div className="form-control">
                  <label htmlFor="id" className="form-control__label">{t('partner.form.relation-link.id')}</label>
                  <div className="form-control__input">
                    <input type="text" {...register('id')} disabled={true} />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-control">
                  <label htmlFor="lastName"
                         className="form-control__label">{t('partner.form.relation-link.lastname')}</label>
                  <div className="form-control__input">
                    <input type="text" {...register('lastName')} />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-control">
                  <label htmlFor="firstName"
                         className="form-control__label">{t('partner.form.relation-link.firstname')}</label>
                  <div className="form-control__input">
                    <input type="text" {...register('firstName')} />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-control">
                  <label htmlFor="link"
                         className="form-control__label">{t('partner.form.relation-link.link')}</label>
                  <div className="form-control__input">
                    <select id="link" {...register('link')}>
                      <option value="">{t('common.choice-option')}</option>
                      {referential.global.relationship.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="sidebar__footer">
            <button type="submit" className="button button--ink-2 u-mrm">{t('common.save')}</button>
            <button type="button" className="button button--ink-2 button--ink-2--outline"
                    onClick={() => handleClose()}>{t('common.cancel')}</button>
          </footer>
        </form>
      </div>
    </>
  );
}

export default AddRelationLink
