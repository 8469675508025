import React, {FunctionComponent} from 'react';
import Error from "../../component/Error";
import {useParams} from "react-router-dom";

type Props = {
  code?: string | undefined
}

const ErrorPage: FunctionComponent<Props> = ({ code}) => {
  const {errorCode} = useParams()

  return (
    <div>
      <Error code={code || errorCode} />
    </div>
  );
}

export default ErrorPage;