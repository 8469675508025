import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ReferentielItemInterface } from '../../../../domain/Referentiel/ReferentielItemInterface';
import { v4 as uuidV4 } from 'uuid'
import MovementUtil from '../../util/MovementUtil';

type Props = {
  label: string | null
  status: string | null
  statusList: ReferentielItemInterface[]
  shortLabel?: boolean
}

const Status: FunctionComponent<Props> = ({ label,status , statusList, shortLabel}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={`badge-line ${shortLabel ? 'badge-line--narrow-columns' : 'badge-line--wide-columns'} u-mbb`}>
        <label className="form-control__label u-txt-uppercase u-mrs u-mts">{ t(label ?? "") } </label>
        <div className="badge-wrapper status-span">
          { MovementUtil.sortState(statusList).map(item => {
            const modifier = status && item.value === status ? 'etat--'+item.value : '';
            const shadow = status && item.value === status ? 'badge--shadow' : '';
            return (
              <div key={uuidV4()} className={ `badge etat ${modifier} ${shadow} u-mrs` }>
                <div className={`badge__text etat`}>
                  { item.label }
                </div>
              </div>
            );
          }) }
        </div>
      </div>
    </>
  );
};

export default Status;
