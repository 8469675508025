import DepositaryGateway from "../../gateway/Depositary/DepositaryGateway";
import {DepositaryInterface} from "../../domain/Depositary/Depositary";

export default class GetReferentielUseCase
{
  public depositaryGateway

  constructor(DepositaryGateway: DepositaryGateway) {
    this.depositaryGateway = DepositaryGateway
  }

  async execute(): Promise<DepositaryInterface[]> {
    return await this.depositaryGateway.getReferentiel().then(response => {
      return response
    })
  }
}
