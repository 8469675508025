import {Depositary, DepositaryInterface} from "../../../domain/Depositary/Depositary";

export default class InitializeDepositary {
  initializeDepository(): DepositaryInterface {
    return new Depositary(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    )
  }
}
