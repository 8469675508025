import {Document} from './Document'
import {MovementList} from "../Movement/MovementList";

interface BankInformationInterface {
  id: string|null
  createdAt: string|null
  updatedAt: string|null
  deletedAt: string|null
  label: string|null
  main: boolean|null
  bank: string|null
  iban: string|null
  swift: string|null
  debitMandate: boolean|null
  file: Document[]|null
  products: any[]|null
  type: string|null
  flux: string|null
  transactions: any[]|null
  status: string|null
}

class BankInformation implements BankInformationInterface{

  public id: string|null
  public createdAt: string|null
  public updatedAt: string|null
  public deletedAt: string|null
  public label: string|null
  public main: boolean|null
  public bank: string|null
  public iban: string|null
  public swift: string|null
  public debitMandate: boolean|null
  public file: Document[]|null
  public products: any[]|null
  public type: string|null
  public flux: string|null
  public transactions: MovementList[]|null
  public status: string|null

  constructor(
    id: string|null,
    createdAt: string|null,
    updatedAt: string|null,
    deletedAt: string|null,
    label: string|null,
    main: boolean|null,
    bank: string|null,
    iban: string|null,
    swift: string|null,
    debitMandate: boolean|null,
    file: Document[]|null,
    products: any[]|null,
    type: string|null,
    flux: string|null,
    transactions: any[]|null,
    status: string|null
  ) {
    this.id = id
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.deletedAt = deletedAt
    this.label = label
    this.main = main
    this.bank = bank
    this.iban = iban
    this.swift = swift
    this.debitMandate = debitMandate
    this.file = file
    this.products = products
    this.type = type
    this.flux = flux
    this.status = status
    this.transactions = transactions
  }
}

export {
  BankInformation
}
export type { BankInformationInterface }
