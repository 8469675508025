import {CommissioningListInterface, FilterCommissioningInterface} from '../../domain/Commissioning/CommissioningList'
import Caller from '../../domain/Api/Caller'
import envVariable from '../../infrastructure/presentation/util/envVariable'
import CommissioningGatewayInterface
  from "../../domain/Commissioning/CommissioningGatewayInterface";
import {CallerType} from "../../domain/Caller/Caller";

export default class CommissioningGateway implements CommissioningGatewayInterface {
  getList(currentPage: number,
          itemsPerPage: number,
          filter: FilterCommissioningInterface | null,
          signal?: AbortSignal,
          sort?: string,
          order?: string,
          callerType?: CallerType
  ): Promise<{ data: CommissioningListInterface[] | null, numberOfItems: number } | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/commission/sessions${callerType === "datalist" ? "-datalist" : ""}?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`, {
        name: filter?.name || '',
        type: filter?.type || '',
        keywords: filter?.keywords || '',
      },
      signal
    ).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    }).catch(response => {
      return {
        data: [],
        numberOfItems: 0
      }
    });
  }

  getProductsAvailable(sessionType: string, start: string, end: string, signal?: AbortSignal): Promise<any | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/commission/products-availables/type/${sessionType}/start/${start}/end/${end}`, {},
      signal
    ).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    }).catch(response => {
      return {
        data: [],
        numberOfItems: 0
      }
    });
  }

  getPartnersAvailable(sessionType: string, start: string, end: string, signal?: AbortSignal): Promise<any | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/commission/partners-availables/type/${sessionType}/start/${start}/end/${end}`, {},
      signal
    ).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    }).catch(response => {
      return {
        data: [],
        numberOfItems: 0
      }
    });
  }

  getExport(filter: FilterCommissioningInterface | null): Promise<string | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/commissionings/extract`, {
      name: filter?.name || '',
      type: filter?.type || '',
      keywords: filter?.keywords || '',
    })
      .then(data => {
        if (data && data.content) {
          return data.content
        }

        return null
      }).catch(response => {
        return null
      })
  }
}
