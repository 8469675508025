import {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'
import {Bar} from 'react-chartjs-2'

type Props = {
  viewModel: {
    title: string
    data: any
    heightCanvas: number
    options: any
  }
}

const ChartBarWrapper: FunctionComponent<Props> = ({viewModel}) => {
  const {t} = useTranslation()

  return (<>
      {viewModel !== null && viewModel.data &&
        <>
          <div className='title'>{t(viewModel.title)}</div>
          <div className="container-canvas">
            <Bar data={viewModel.data} options={viewModel.options} />
          </div>
        </>
      }
    </>
  )
}

export default ChartBarWrapper
