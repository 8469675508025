interface RelationLinkInterface {
  id: string
  lastName: string
  firstName: string
  link: string
}

class RelationLink implements RelationLinkInterface {
  public id: string
  public lastName: string
  public firstName: string
  public link: string

  constructor(
    id: string,
    lastName: string,
    firstName: string,
    link: string
  ) {
    this.id = id
    this.lastName = lastName
    this.firstName = firstName
    this.link = link
  }
}

export {
  RelationLink
}
export type { RelationLinkInterface }
