import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Movement} from '../../../domain/Movement/Movement'
import {InvestorInterface} from '../../../domain/PreSubscription/Investor'
import {InvestorInterface as DismenbermentInvestorInterface} from '../../../domain/Movement/Dismemberment/Investor'
import {SliceListInterface} from '../../../domain/Share/Slice/SliceList'
import {PartnerInterface} from "../../../domain/Partner/Partner";
import {
  distributionTransferorShareInterface,
  TInvestorReceivingShares,
  TPartnerReceivingShares
} from "../../../domain/Movement/DistributionTransferorShare";
import {v4 as uuidV4} from "uuid";

interface movement {
  reloadMovement: string
  countTotal: number
  movement: Movement|null
  presubscriptions: {
    owner: DismenbermentInvestorInterface|null
    usufructuary: DismenbermentInvestorInterface|null
  },
  investors: {
    owner: InvestorInterface|null
    usufructuary: InvestorInterface|null
    transferor: InvestorInterface|null
    investor: InvestorInterface|null
    investorsReceivingShares: TInvestorReceivingShares|null
  }
  sliceToGiveUp: {
    redemptionWithdrawal: SliceListInterface|null
    agreement: SliceListInterface|null
  }
  partners: {
    owner: PartnerInterface|null,
    usufructuary: PartnerInterface|null,
    transferor: PartnerInterface|null,
    investor: PartnerInterface|null,
    partnerReceivingShares: TPartnerReceivingShares|null
  }
}

const initialState: movement = {
  reloadMovement: uuidV4(),
  countTotal: 0,
  movement: null,
  presubscriptions: {
    owner: null,
    usufructuary: null
  },
  investors: {
    owner: null,
    usufructuary: null,
    transferor: null,
    investor: null,
    investorsReceivingShares: null
  },
  sliceToGiveUp: {
    redemptionWithdrawal: null,
    agreement: null
  },
  partners: {
    owner: null,
    usufructuary: null,
    transferor: null,
    investor: null,
    partnerReceivingShares: null
  }
}

export const movementSlice = createSlice({
  name: 'movement',
  initialState: initialState,
  reducers: {
    reloadMovement: (state) => {
      state.reloadMovement = uuidV4()
    },
    setCountTotal: (state, action: PayloadAction<number>) => {
      state.countTotal = action.payload
    },
    setInvestorOwner: (state, action) => {
      state.investors.owner = action.payload
      if(action.payload.key === 'reset') {
        state.investors.owner = null
      }
    },
    setInvestorUsufructuary: (state, action) => {
      state.investors.usufructuary = action.payload
      if(action.payload.key === 'reset') {
        state.investors.usufructuary = null
      }
    },
    setPresubscriptionOwner: (state, action:  PayloadAction<DismenbermentInvestorInterface>) => {
      state.presubscriptions.owner = action.payload
    },
    setPresubscriptionUsufructuary: (state, action:  PayloadAction<DismenbermentInvestorInterface>) => {
      state.presubscriptions.usufructuary = action.payload
    },
    setSliceToGiveUpRedemptionWithdrawal: (state, action: PayloadAction<SliceListInterface>) => {
      state.sliceToGiveUp.redemptionWithdrawal = action.payload
    },
    setInvestorTransferor: (state, action: PayloadAction<InvestorInterface>) => {
      state.investors.transferor = action.payload
    },
    setInvestorInvestor: (state, action: PayloadAction<InvestorInterface>) => {
      state.investors.investor = action.payload
    },
    setInvestorReceivingShares: (state, action: PayloadAction<TInvestorReceivingShares>) => {
      state.investors.investorsReceivingShares = action.payload
    },
    setPartner: (state, action) => {
      if(action.payload.key === "owner") {
        state.partners.owner = action.payload.partner
      }
      if(action.payload.key === "usufructuary") {
        state.partners.usufructuary = action.payload.partner
      }
      if(action.payload.key === "transferor") {
        state.partners.transferor = action.payload.partner
      }
      if(action.payload.key === "investor") {
        state.partners.investor = action.payload.partner
      }
      if(action.payload.key === "reset") {
        state.partners = {
          owner: null,
          usufructuary: null,
          transferor: null,
          investor: null,
          partnerReceivingShares: null
        }
      }
    },
    setPartnerReceivingShares: (state, action: PayloadAction<TPartnerReceivingShares>) => {
      state.partners.partnerReceivingShares = action.payload
    },
  }
})

export const {
  reloadMovement,
  setCountTotal,
  setInvestorOwner,
  setInvestorUsufructuary,
  setSliceToGiveUpRedemptionWithdrawal,
  setPresubscriptionOwner,
  setPresubscriptionUsufructuary,
  setInvestorTransferor,
  setInvestorInvestor,
  setInvestorReceivingShares,
  setPartner,
  setPartnerReceivingShares
} = movementSlice.actions

export default movementSlice.reducer
