import {DocumentInterface} from './Document'
import {Person, PersonInterface} from './Person'
import {BankInformation} from "./BankInformation";
import {RelationLink} from "../Partner/RelationLink";
import {Relation} from "./Relation";
import {FiscalityInterface} from "./Fiscality";
import {BeneficialOwner} from "./BeneficialOwner";
import {PartnerInterface} from "../Partner/Partner";
import {ProspectLegalRepresentativesInterface} from "./ProspectLegalRepresentatives";
import {Undivided} from "./Undivided";
import {GeneralAddressInterface} from "../Address/GeneralAddress";

export const TYPE_MORAL = "moral"
export const TYPE_PHYSIC = "physic"

interface ProspectInterface {
  id: string|null
  createdAt: string|null
  updatedAt: string|null
  deletedAt: string |null
  enabled: boolean|null
  prospectType: string|null
  statusCreatedAt: string|null
  status: string|null
  exitedAt: string|null
  blockedAt: string|null
  prospectCode: string|null
  externalDepositaryCode: string|null
  externalCode: string|null
  recoveryCode: string|null
  legalStatus: string|null
  companyName: string|null
  siret: string|null
  orias: string|null
  kycCategoryValue: string|null
  kycCategoryDate: string|null
  kycLabft: string|null
  kycLabftDate: string|null
  kycProfession: string|null
  kycFatca: boolean|null
  moralType: string|null
  fiscality: FiscalityInterface|null
  persons: PersonInterface[]|null
  prospectAddresses: GeneralAddressInterface[]|null
  bankInformations: BankInformation[]|null
  relations: Relation[]|null
  beneficialOwners: BeneficialOwner[]|null
  undivideds: Undivided[]|null
  groupRelation: string|null
  partner: PartnerInterface|null
  sameFiscalAddress: boolean
  legalRepresentatives: ProspectLegalRepresentativesInterface[]|null
  customer: boolean|null
  lastNotepadAt: string| null
  countNotepad: number| null

}

class Prospect implements ProspectInterface{

  public id: string|null
  public createdAt: string|null
  public updatedAt: string|null
  public deletedAt: string |null
  public enabled: boolean|null
  public prospectType: string|null
  public statusCreatedAt: string|null
  public status: string|null
  public exitedAt: string|null
  public blockedAt: string|null
  public prospectCode: string|null
  public externalDepositaryCode: string|null
  public externalCode: string|null
  public recoveryCode: string|null
  public legalStatus: string|null
  public companyName: string|null
  public siret: string|null
  public orias: string|null
  public kycCategoryValue: string|null
  public kycCategoryDate: string|null
  public kycLabft: string|null
  public kycLabftDate: string|null
  public kycProfession: string|null
  public kycFatca: boolean|null
  public moralType: string|null
  public fiscality: FiscalityInterface|null
  public persons: PersonInterface[]|null
  public prospectAddresses: GeneralAddressInterface[]|null
  public bankInformations: BankInformation[]|null
  public relations: Relation[]|null
  public beneficialOwners: BeneficialOwner[]|null
  public undivideds: Undivided[]|null
  public groupRelation: string|null
  public partner: PartnerInterface|null
  public legalRepresentatives: ProspectLegalRepresentativesInterface[]
  public sameFiscalAddress: boolean
  public customer: boolean|null
  public lastNotepadAt: string| null
  public countNotepad: number| null

  public static getFullName(prospect: ProspectInterface): string
  {
    if (prospect.prospectType === TYPE_MORAL) {
      return prospect.companyName ?? ''
    }

    if (prospect.persons && prospect.persons.length > 0 && prospect.persons[0]) {
      return Person.getFullName(prospect.persons[0])
    }

    return ''
  }

  constructor(
    id: string|null,
    createdAt: string|null,
    updatedAt: string|null,
    deletedAt: string |null,
    enabled: boolean|null,
    prospectType: string|null,
    statusCreatedAt: string|null,
    status: string|null,
    exitedAt: string|null,
    blockedAt: string|null,
    prospectCode: string|null,
    externalDepositaryCode: string|null,
    externalCode: string|null,
    recoveryCode: string|null,
    legalStatus: string|null,
    companyName: string|null,
    siret: string|null,
    orias: string|null,
    kycCategoryValue: string|null,
    kycCategoryDate: string|null,
    kycLabft: string|null,
    kycLabftDate: string|null,
    kycProfession: string|null,
    kycFatca: boolean|null,
    moralType: string|null,
    fiscality: FiscalityInterface|null,
    persons: PersonInterface[]|null,
    prospectAddresses: GeneralAddressInterface[]|null,
    bankInformations: BankInformation[]|null,
    relations: Relation[]|null,
    beneficialOwners: BeneficialOwner[]|null,
    undivideds: Undivided[]|null,
    groupRelation: string|null,
    partner: PartnerInterface|null,
    legalRepresentatives: ProspectLegalRepresentativesInterface[] = [],
    sameFiscalAddress: boolean,
    customer: boolean|null,
    lastNotepadAt: string| null,
    countNotepad: number| null
) {
    this.id = id
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.deletedAt = deletedAt
    this.enabled = enabled
    this.prospectType = prospectType
    this.statusCreatedAt = statusCreatedAt
    this.status = status
    this.exitedAt = exitedAt
    this.blockedAt = blockedAt
    this.prospectCode = prospectCode
    this.externalDepositaryCode = externalDepositaryCode
    this.externalCode = externalCode
    this.recoveryCode = recoveryCode
    this.legalStatus = legalStatus
    this.companyName = companyName
    this.siret = siret
    this.orias = orias
    this.kycCategoryValue = kycCategoryValue
    this.kycCategoryDate = kycCategoryDate
    this.kycLabft = kycLabft
    this.kycLabftDate = kycLabftDate
    this.kycProfession = kycProfession
    this.kycFatca = kycFatca
    this.moralType = moralType
    this.fiscality = fiscality
    this.persons = persons
    this.prospectAddresses = prospectAddresses
    this.bankInformations = bankInformations
    this.relations = relations
    this.beneficialOwners = beneficialOwners
    this.undivideds = undivideds
    this.groupRelation = groupRelation
    this.partner = partner
    this.legalRepresentatives = legalRepresentatives
    this.sameFiscalAddress = sameFiscalAddress
    this.customer = customer
    this.lastNotepadAt = lastNotepadAt
    this.countNotepad = countNotepad
  }

}

export {
  Prospect
}
export type { ProspectInterface }
