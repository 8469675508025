import React, {FunctionComponent, useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import GetUseCase from "../../../../../useCase/Prospect/Get/GetUseCase";
import {useAppDispatch, useAppSelector} from "../../../../store/hook";
import {ReferentielInterface} from "../../../../../domain/Referentiel/ReferentielInterface";
import MatchUrl from "../../../../../domain/Utils/MatchedUrl";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import HeaderPage from "../../../component/HeaderPage/HeaderPage";
import ProspectGateway from "../../../../../gateway/Prospect/ProspectGateway";
import {ProspectInterface} from "../../../../../domain/Prospect/Prospect";
import GeneralInformationPhysicalPerson
  from "../../../component/Form/Prospect/GeneralInformationPhysicalPerson/GeneralInformationPhysicalPerson";
import GeneralInformationMoralPerson
  from "../../../component/Form/Prospect/GeneralInformationMoralPerson/GeneralInformationMoralPerson";
import ProspectHeaderPage from "../block/ProspectHeaderPage";
import Spinner from "../../../component/Spinner/Spinner";
import TabProspectAddPhysicalPerson
  from "../../../component/Tab/ProspectAddPhysicalPerson/TabProspectAddPhysicalPerson";
import TabProspectAddCorporation from "../../../component/Tab/ProspectAddCorporation/TabProspectAddCorporation";
import DocumentList from "../../../component/Document/DocumentList";
import AddCityForm from "../../../component/Sidebar/Referentiel/AddCityForm";
import UploadLegacy from "../../../component/Sidebar/Prospect/Upload";
import Upload from "../../../component/Upload/Upload";
import AddressBlock from "../../../component/Form/Prospect/Address/Address";
import AddBankInformationForm from "../../../component/Sidebar/Prospect/AddBankInformationForm";
import BankDetailsBlock from "../../../component/Form/Prospect/BankDetails/BankDetails";
import ParticularityBlock from "../../../component/Form/Prospect/Particularity/Particularity";
import AddRelationForm from "../../../component/Sidebar/Prospect/AddRelationForm";
import LegalRepresentativesBlock from "../../../component/Form/Prospect/LegalRepresentatives/LegalRepresentatives";
import AddLegalRepresentatives from "../../../component/Sidebar/Prospect/AddLegalRepresentatives";
import BeneficialOwnerForm from "../../../component/Form/Prospect/BeneficialOwner/BeneficialOwner";
import AddBeneficialOwner from "../../../component/Sidebar/Prospect/AddBeneficialOwner";
import {setIsLoading, setOpenDatalistFilterMovement, setOpenUploadForm} from "../../../../store/component/event";
import UndividedForm from "../../../component/Form/Prospect/Undivided/Undivided";
import AddUndivided from "../../../component/Sidebar/Prospect/AddUndivided";
import TransactionDatalist from "../../../component/Datalist/TransactionDatalist";
import DocumentGateway from "../../../../../gateway/Prospect/Document/DocumentGateway";
import {TError} from "../../../../../domain/Error/Error";
import ErrorPage from "../../Error/ErrorPage";
import NotePad from '../../../component/Sidebar/Customer/NotePad'
import GetNameForTitle from '../../../util/GetNameForTitle'
import AddAccountAddressForm from "../../../component/Sidebar/Customer/AddAddressForm";
import {clearCurrentAddresses, reloadProspect} from "../../../../store/component/prospect";

interface Props {
  page: string
  mode: "add" | "edit" | "read"
}

const ProspectLayout: FunctionComponent<Props> = ({page, mode}) => {
  const {t} = useTranslation()
  const {uuid, prospectType} = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const getProspectUseCase = new GetUseCase(new ProspectGateway())

  const prospectRules = useAppSelector((state) => state.me.me?.rules.prospect.actions)
  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openProspectUploadForm = useAppSelector((state) => state.event.openProspectUploadForm)
  const openDatalistFilterMovement = useAppSelector((state) => state.event.openDatalistFilterMovement)
  const reloadCustomer = useAppSelector((state) => state.customer.reloadCustomer)
  const reloadProspectValue = useAppSelector((state) => state.prospect.reloadProspect)
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const openUploadForm = useAppSelector((state) => state.event.openUploadForm)
  const isLoading = useAppSelector((state) => state.event.isLoading)

  const [prospect, setProspect] = useState<ProspectInterface | null>(null)
  const [error, setError] = useState<TError|null>(null)
  const [status, setStatus] = useState<string>("")


  const isLectureMode = MatchUrl.find(location.pathname, 'lecture')
  const firstUpdate = useRef(true);

  useEffect(() => {
    if(prospectRules) {
      if(!prospectRules?.update && !isLectureMode) navigate(`/${t('url.prospect.read-general-information')}/${uuid}`)
    }

    return () => {
      dispatch(reloadProspect())
      dispatch(clearCurrentAddresses())
    }
  }, [prospectRules])

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (uuid && !openProspectUploadForm.show) {
      refreshProspect(uuid)
    }
  }, [reloadCustomer, reloadProspectValue, openProspectUploadForm]);

  useEffect(() => {
    if(uuid) {
      initialFetchProspect(uuid)
    }
  }, [uuid])

  const refreshProspect = useCallback((uuid) => {
    getProspectUseCase.execute(uuid).then((response) => {
      if (response !== null) {
        setProspect(response)
        dispatch(setIsLoading(false))
      }
    })
  }, [openProspectUploadForm])

  const initialFetchProspect = (uuid: string) => {
    getProspectUseCase.execute(uuid).then((res) => {
      if (res) {
        setProspect(res)
        dispatch(setOpenDatalistFilterMovement({
            show: false,
            count: 1,
            filters: {
              name: '',
              keywords: '',
              investor_id: res.id,
            }
          }
        ))
      }
      dispatch(setIsLoading(false))
    }).catch((e) => {
      setError(e)
      dispatch(setIsLoading(false))
    })
  }

  const handlerProspect = (childProspect: ProspectInterface) => {
    setProspect({...childProspect})
  }
  const updateProspectTab = (childProspect: ProspectInterface) => {
    setProspect({...childProspect});
  }


  const handleChoosePage = (referential: ReferentielInterface, prospect?: ProspectInterface,) => {
    switch (page) {
      case 'GeneralInformation':
        if (prospect && mode) {
          if (prospect.prospectType === 'physical') {
            if (mode === "edit" || mode === "read") {
              return <GeneralInformationPhysicalPerson prospect={prospect}
                                                       isLectureMode={isLectureMode}
                                                       handler={updateProspectTab}
                                                       referential={referential}
                                                       setStatus={setStatus}
              />
              break
            }
          }
          if (prospect.prospectType === 'moral') {
            if (mode === "edit" || mode === "read") {
              return <GeneralInformationMoralPerson prospect={prospect}
                                                    isLectureMode={isLectureMode}
                                                    handler={handlerProspect}
                                                    referential={referential}
                                                    setStatus={setStatus}
              />
              break
            }
          }
          break
        } else if (prospectType) {
          if (prospectType === 'physical') {
            return (
              <>
                <TabProspectAddPhysicalPerson/>
                <GeneralInformationPhysicalPerson handler={updateProspectTab}
                                                  referential={referential}
                                                  setStatus={setStatus}
                />
              </>
            )
            break
          }
          if (prospectType === 'moral') {
            return (
              <>
                <TabProspectAddCorporation/>
                <GeneralInformationMoralPerson referential={referential} setStatus={setStatus}/>
              </>
            )
            break
          }
        }
        break
      case 'Addresses':
        if (prospect && mode) {
          return <AddressBlock prospect={prospect}
                               isLectureMode={isLectureMode}
                               handler={handlerProspect}
          />
        }

        break
      case 'BankDetails':
        if (prospect && mode) {
          return <BankDetailsBlock prospect={prospect}
                                   isLectureMode={isLectureMode}
                                   referential={referential}
          />
        }
        break
      case 'Particularity':
        if (prospect && mode) {
          return <ParticularityBlock prospect={prospect}
                                     isLectureMode={isLectureMode}
                                     referential={referential}
          />
        }
        break
      case 'LegalRepresentatives':
        if (prospect && mode) {
          return <LegalRepresentativesBlock prospect={prospect} isLectureMode={isLectureMode}/>
        }
        break
      case 'BeneficialOwner':
        if (prospect && mode) {
          return <BeneficialOwnerForm prospect={prospect} isLectureMode={isLectureMode}/>
        }
        break
      case 'PendingSubscription':
        return <div>composant ne rend rien</div>
        break
      case 'InvestmentInformation':
        if (prospect?.id && openDatalistFilterMovement.filters.investor_id) {
          return (
            <>
              <TransactionDatalist investorId={prospect.id} isLectureMode={isLectureMode}  />
            </>
          )
        }
        break
      case 'Undivided':
        if (prospect) {
          return <UndividedForm prospect={prospect} isLectureMode={isLectureMode}/>
        }
        break
      default:
        console.log(`Sorry, we are out of ${page}.`);
    }
  }


  const handleSidesBarDependReferential = (referential: ReferentielInterface) => {
    return (
      <>
        {page === "Particularity" && !isLectureMode &&
            <AddRelationForm referential={referential}/>}
        {page === "LegalRepresentatives" && !isLectureMode &&
            <AddLegalRepresentatives referential={referential}/>}
        {page === "BeneficialOwner" && prospect &&
            <AddBeneficialOwner prospect={prospect} referential={referential}/>}
        {page === "Undivided" && prospect &&
            <AddUndivided prospect={prospect} referential={referential}/>}
        {page === "BankDetails" && !isLectureMode &&
            <AddBankInformationForm referential={referential}/>}
      </>
    )
  }

  if (isLoading) {
    return (
      <div>
        <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
          <ProspectHeaderPage isLectureMode={isLectureMode}
                              mode={mode}
                              status={status}
                              setStatus={setStatus}
          />
          <section className="container--spinner"><Spinner size={150}/></section>
        </main>
      </div>

    )
  }

  if(error) return <ErrorPage code={error.code.toString()} />

  return (
    <div>
      {referential && handleSidesBarDependReferential(referential)}
      { prospect && prospect.id && (page === "GeneralInformation" || page === "BankDetails" || page === "BeneficialOwner" || page === "Undivided") &&
        <>
          <UploadLegacy/>
          <DocumentList/>
          <Upload openUploadForm={openUploadForm} gateway={new DocumentGateway(prospect.id)} callback={setOpenUploadForm}/>
        </>
      }
      {(page === "GeneralInformation" ||
        page === "Addresses" ||
        page === "LegalRepresentatives" ||
        page === "BeneficialOwner" ||
        page === "Undivided") && <AddCityForm/>}

      {page === "Addresses" && !isLectureMode && <AddAccountAddressForm typeAccount={"prospect"} />}
      <NotePad title={t('common.sideBar.notePad.title', {text: GetNameForTitle("prospect", prospect)})}
               isLectureMode={isLectureMode}
      />

      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        {(mode === "edit" || mode === "read") && prospect?.id &&  <ProspectHeaderPage prospect={prospect}
                                                                                     isLectureMode={isLectureMode}
                                                                                     mode={mode}
                                                                                     status={status}
                                                                                     setStatus={setStatus}
        />}
        {mode === "add" && <HeaderPage title={t('prospect.add.title')}/>}

        {prospect && referential && (mode === "edit" || mode === "read") ?
          handleChoosePage(referential, prospect) :
          referential ? handleChoosePage(referential) : null}
      </main>
    </div>
  );

}

export default ProspectLayout;
