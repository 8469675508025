import Caller from '../../domain/Api/Caller';
import envVariable from '../../infrastructure/presentation/util/envVariable';
import PortfolioGatewayInterface from '../../domain/Movement/PortfolioGatewayInterface';
import { FilterPortfolioInterface } from '../../domain/Movement/PortfolioList';

export default class PortfolioGateway implements PortfolioGatewayInterface {

    getExport(uuid: string, filter: FilterPortfolioInterface|null): Promise<string|null> {
        return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/partners/${uuid}/portfolio/export`, {
            'product': filter?.product || '',
            'transaction_type': filter?.transaction_type || '',
            'keywords': filter?.keywords || '',
            'investor': filter?.investor || '',
            'start': filter?.start || '',
            'end': filter?.end || '',
            'property_type': filter?.property_type || ''
        })
            .then(data => {
                if (data && data.content) {
                    return data.content
                }

                return null
            }).catch(response => {
                return null
            })
    }

    getPortfolio(uuid: string, currentPage: number, itemsPerPage: number, filter: FilterPortfolioInterface|null): Promise<any> {
        return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/partners/${uuid}/portfolio?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}`, {
            'product': filter?.product || '',
            'transaction_type': filter?.transaction_type || '',
            'keywords': filter?.keywords || '',
            'investor': filter?.investor || '',
            'start': filter?.start || '',
            'end': filter?.end || '',
            'property_type': filter?.property_type || ''
        })
            .then((data) => {
                if (data) {
                    return data;
                }
                return {
                    data: [],
                    numberOfItems: 0
                };
            })
            .catch(() => {
                return null;
            })
    }
}
