import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {v4 as uuidV4} from 'uuid'
import MovementGateway from "../../../../../gateway/Movement/MovementGateway";
import {SummaryInterface} from "../../../../../domain/Movement/Summary";
import iconEsnEntry from "../../../../../assets/images/icons/type-mouvement-entree.svg";
import iconEsnExit from "../../../../../assets/images/icons/type-mouvement-sortie.svg";
import iconEsnNew from "../../../../../assets/images/icons/type-mouvement-nouvelle-part.svg";
import iconEsnRecurrence from "../../../../../assets/images/icons/type-mouvement-recurrence.svg";
import iconEsnTransfer from "../../../../../assets/images/icons/type-mouvement-transfer.svg";
import iconEsnTransferPlus from "../../../../../assets/images/icons/type-mouvement-transfer+.svg";
import iconEsnTransferMinus from "../../../../../assets/images/icons/type-mouvement-transfer-.svg";


// presous est utile pour le fix demandé par Mikael pour cacher temporairement la date des mvt pour les présouscription
type Props = {
  transactionUuid: string
  typeMvt?: 'mutation' | 'preSubscription' | 'dismemberment' | 'agreement' | 'withdrawal'
}

const RecapForm: FunctionComponent<Props> = ({transactionUuid, typeMvt}) => {
  const {t} = useTranslation()
  const [summary, setSummary] = useState<SummaryInterface[] | null>(null)

  useEffect(() => {
    new MovementGateway().getSummary(transactionUuid).then((response: SummaryInterface[] | null) => {
      if(response?.length) {
        setSummary(parseSummary(response))
      } else {
        setSummary([])
      }
    })
  }, [transactionUuid, typeMvt])

  const parseSummary = (arr: SummaryInterface[] | null): SummaryInterface[] | null => {
    if (!arr) return []
    const newArr: SummaryInterface[] = [...arr]
    arr.forEach((sum, index) => {
      if (sum.property_type === "dismemberment" && sum?.usufructuary && sum.investor) {
        sum.property_type = "NP"
        newArr.push({
          ...sum,
          property_type: "USU",
          dismembermentEndAt: sum.dismembermentEndAt,
          investor: {
            ...sum.investor,
            code: sum.usufructuary.code,
            firstname: sum.usufructuary.firstname || "",
            lastname: sum.usufructuary.lastname || "",
            recovery_code: sum.usufructuary.recovery_code,
            company_name: sum.usufructuary.company_name,
            dismemberment_key: sum.usufructuary.dismemberment_key,
            partner: {
              code: sum.usufructuary.partner.code,
              social_reason: sum.usufructuary.partner.social_reason
            }
          }
        })
      }
      if(sum.property_type === "full_property") {
        sum.property_type = "PP"
      }
      if(sum.property_type === "usufruct" && sum.usufructuary) {
        newArr[index] = {
          ...sum,
          property_type: "USU",
          dismembermentEndAt: sum.dismembermentEndAt,
          investor: {
            code: sum.usufructuary.code,
            firstname: sum.usufructuary.firstname,
            lastname: sum.usufructuary.lastname,
            recovery_code: sum.usufructuary.recovery_code,
            company_name: sum.usufructuary.company_name,
            dismemberment_key: sum.usufructuary.dismemberment_key,
            partner: {
              code: sum.usufructuary.partner.code,
              social_reason: sum.usufructuary.partner.social_reason
            }
          }
        }
      }
      if(sum.property_type === "np") {
        sum.property_type = "NP"

      }
    })
    return newArr
  }

  function pictoFromEsnType(esnType: string|null) {
    if(esnType === "E"){
      return <img src={iconEsnEntry} title="Entrée"/>
    }
    if(esnType === "S") {
      return <img src={iconEsnExit} title="Sortie"/>
    }
    if(esnType === "N") {
      return <img title="Nouvelle part" src={iconEsnNew} />
    }
    if(esnType === "R") {
      return <img title="Récurrence" src={iconEsnRecurrence} />
    }
    if(esnType === "T+") {
      return <img title="Transfert In" src={iconEsnTransferPlus} />
    }
    if(esnType === "T-") {
      return <img title="Transfert Out" src={iconEsnTransferMinus} />
    }
    return ""
  }

  if(!summary || !summary.length) return null

  return (
    <div className={`form-bloc form--bloc--summary`}>
      <div className="form-bloc__title">{t('movement.pre-subscription.form.summary.title')}</div>
      <div className="form-bloc__form flex-container overflow-auto">
        <table className="datalist__datas">
          <thead>
            <tr>
              <th>{t('movement.pre-subscription.form.summary.movement_id')}</th>
              <th>{t('movement.heading-slice.esn-type')}</th>
              <th>{t('movement.pre-subscription.form.summary.movement_date')}</th>
              <th>{t('movement.pre-subscription.form.summary.use_date')}</th>
              <th>{t('movement.pre-subscription.form.summary.movement_type')}</th>
              <th>{t('movement.pre-subscription.form.summary.nb_part')}</th>
              <th>{t('movement.pre-subscription.form.summary.start')}</th>
              <th>{t('movement.pre-subscription.form.summary.end')}</th>
              <th>{t('movement.pre-subscription.form.summary.property_type')}</th>
              <th>{t('movement.pre-subscription.form.summary.investor_number')}</th>
              <th>{t('movement.pre-subscription.form.summary.recovery_code')}</th>
              <th>{t('movement.pre-subscription.form.summary.investor_name')}</th>
              <th>{t('movement.pre-subscription.form.summary.partner_code')}</th>
              <th>{t('movement.pre-subscription.form.summary.partner_name')}</th>
              <th>{t('movement.pre-subscription.form.summary.price')}</th>
              <th>{t('movement.pre-subscription.form.summary.amount')}</th>
              <th>{t('movement.pre-subscription.form.summary.commision')}</th>
              <th>{t('movement.pre-subscription.form.summary.dismembermentEndAt')}</th>
              <th>{t('movement.pre-subscription.form.summary.dismembermentKey')}</th>
            </tr>
          </thead>
          <tbody>
          {
            summary.map(item => {
              return <tr key={uuidV4()}>
                <td>{item.movement_id}</td>
                <td>
                  <div className="ceil-esn">
                    { pictoFromEsnType(item.esn) }
                    { item.transfer_id && pictoFromEsnType("T") }
                  </div>
                </td>
                {/*fix temporaire demandé par Mikael pour cacher la date de mvt pour les présouscription*/}
                <td>{item.movement_date}</td>
                <td>{typeMvt === 'preSubscription' && item.property_type === 'NP' ? '-' : item.use_date}</td>
                <td>{item.movement_type}</td>
                <td>{item.share_count}</td>
                <td>{item.start}</td>
                <td>{item.end}</td>
                <td>{item.property_type}</td>
                <td>{item?.investor?.code}</td>
                <td>{item?.investor?.recovery_code}</td>
                <td>{item?.investor?.company_name ?? item?.investor?.lastname + " " + item?.investor?.firstname}</td>
                <td>{item?.investor?.partner.code}</td>
                <td>{item?.investor?.partner.social_reason}</td>
                <td>{item.price}</td>
                <td>{item.amount}</td>
                <td>{item.commission}</td>
                <td>{item.dismembermentEndAt ?? ""}</td>
                <td>{item?.investor?.dismemberment_key ?? ""}</td>
              </tr>
            })
          }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default RecapForm
