import { FunctionComponent, useEffect, useState } from 'react'

import {useAppSelector} from '../../../store/hook'

import Hello from '../../component/Hello/Hello'
import Quote from '../../component/Quote/Quote'
import '../../../../assets/styles/page/_dashboard.scss'
import heroIcon from '../../../../assets/images/icons/hero.svg'
import {QuoteInterface} from '../../../../domain/Quote/Quote'
import DashboardGateway from '../../../../gateway/Dashboard/DashboardGateway'
import Kpi from "../../component/Kpi/Kpi";

const Dashboard: FunctionComponent = () => {
  const user = useAppSelector((state) => state.me.me)
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const [quote, setQuote] = useState<QuoteInterface|null>(null)

  useEffect(() => {
    new DashboardGateway().getQuote().then(response => setQuote(response))
  }, [])

  return (
    <>
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        {user && <Hello name={user.firstname} />}
        {quote &&
          <div className="quote--dashboard-wrapper">
            <Quote classes={`quote--dashboard`} quote={quote}/>
            <section className={`hero--dashboard`}>
              <img src={heroIcon} alt="" />
            </section>
          </div>
        }
      <Kpi getKpi={new DashboardGateway().getKpi} />
      </main>
    </>
  )
}

export default Dashboard
