import {Presenter} from "../../Presenter";
import {Scale} from "../../../domain/Product/Scale";

class ScalesPresenter extends Presenter {

  constructor(scales: Scale[]) {
    super({
      viewModel: {
        title: 'product.form.scale.datalist.title',
        heading: [
          {name: 'product.form.scale.datalist.action'},
          {name: 'product.form.scale.datalist.type'},
          {name: 'product.form.scale.datalist.sessions'},
          {name: 'product.form.scale.datalist.date'},
        ],
        data: scales,
        filtersShortcut: [
        ],
        filters: [],
        pagination: {
          'itemsPerPage': 100,
          'numberOfItems': 0,
        },
        count: 0
      }
    });
  }
}

export default ScalesPresenter
