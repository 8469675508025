import {NetAssetValueItem} from "./NetAssetValueItem";

interface NetAssetValueInterface {
  displayHistory: string|null
  items: NetAssetValueItem[]
  periodicity: string|null
}

class NetAssetValue implements NetAssetValueInterface {
  displayHistory: string|null
  items: NetAssetValueItem[]
  periodicity: string|null

  constructor(
    displayHistory: string|null,
    items: NetAssetValueItem[],
    periodicity: string|null
  ) {
    this.displayHistory = displayHistory
    this.items = items
    this.periodicity = periodicity
  }
}

export {NetAssetValue}
export type {NetAssetValueInterface}
