import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'

interface ViewModelInterface {
  title: string
  heading: []
  datas: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

import '../../../../assets/styles/components/_datalist.scss'
import iconEdit from '../../../../assets/images/icons/datalist-edit.svg'
import remove from "../../../../assets/images/icons/remove.svg";
import eyeIcon from "../../../../assets/images/icons/eye.svg";
import uploadIcon from "../../../../assets/images/icons/upload.svg";
import {BankInformationInterface} from "../../../../domain/Prospect/BankInformation";
import {setOpenBankInformationFormEvent, setOpenPartnerConventionAnnexFormEvent} from "../../../store/component/event";
import {useAppDispatch} from "../../../store/hook";
import ListUseCase from "../../../../useCase/Product/List/ListUseCase";
import ProductGateway from "../../../../gateway/Product/ProductGateway";
import {ProductListInterface} from "../../../../domain/Product/ProductList";
import {ConventionAnnexInterface} from "../../../../domain/Partner/ConventionAnnex";
import PartnerCommissionListPresenter from "../../../../presenter/Partner/PartnerCommissionListPresenter";
import {PartnerInterface} from "../../../../domain/Partner/Partner";
import {CallerType} from "../../../../domain/Caller/Caller";

type Props = {
  callerType: CallerType,
  commissions: ConventionAnnexInterface[],
  partner: PartnerInterface,
  isLectureMode?: boolean,
  uploadCallback: (show: boolean, documents: any[]|null, personId: string|null, type: string|null, conventionId: string|null) => void,
  deleteCommissionCallback: (commissionId: string) => void,
  viewFilesCallback: (conventionId: string|null) => void
}

const ParnerCommissionDatalist: FunctionComponent<Props> = ({ callerType, commissions, partner, isLectureMode, uploadCallback, viewFilesCallback, deleteCommissionCallback, ...rest}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)

  useEffect(() => {
    const presenter = new PartnerCommissionListPresenter(commissions);
    setViewModel(presenter.immutableViewModel())
  }, [commissions])

  return (<>
      {(viewModel !== null &&
        <>
          <div className={`datalist`}>
            <table className="datalist__datas">
              <thead>
              <tr>
                {viewModel.heading.map((title: any) => (
                  <th key={uuidV4()}>{t(title.name)}</th>
                ))}
              </tr>
              </thead>
              <tbody>
              {viewModel.datas !== undefined &&
              viewModel.datas.map((item: ConventionAnnexInterface) => (
                <tr key={uuidV4()}>
                  <td>
                    <button
                      type="button"
                      className="button-reset"
                      onClick={() => dispatch(setOpenPartnerConventionAnnexFormEvent({show: true, conventionAnnex: item, partner: partner}))}
                    >
                      <img src={iconEdit} alt="" />
                    </button>
                    <button type="button" className="button-reset u-mxs"
                            onClick={() => viewFilesCallback(item.id)}
                    >
                      <img src={eyeIcon} alt=""/>
                    </button>
                    <button type="button" className="button-reset u-mxs"
                            onClick={() => uploadCallback(true, null, partner.id, 'CONVENTIONANNEX', item.id)}
                    >
                      <img src={uploadIcon} alt=""/>
                    </button>
                    <button
                      type="button"
                      className="button-reset u-mxs"
                      onClick={() => deleteCommissionCallback(item.id)}
                    >
                      <img src={remove} alt=""/>
                    </button>
                  </td>
                  <td>
                    {item.product?.label}
                  </td>
                  <td>{item.code}</td>
                  <td>{item.date || ""}</td>
                  <td className=" flex flex-col">
                    {item.scaleItems && item.scaleItems.map((it) => {
                      return <span key={it.id}>{`${it.scaleCategory?.label} - ${it.category?.label}`}</span>
                    })}
                  </td>
                </tr>
              ))}
              {viewModel.datas === undefined || viewModel.datas.length === 0 &&
                <tr>
                  <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
                </tr>
              }
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  )
}

export default ParnerCommissionDatalist
