import envVariable from "../presentation/util/envVariable";
import encoder from "../presentation/util/Encoder";

const oauth = localStorage.getItem(envVariable('REACT_APP_LOCAL_STORAGE_TOKEN_NAME')) ?
  JSON.parse(localStorage.getItem(envVariable('REACT_APP_LOCAL_STORAGE_TOKEN_NAME')) ?? '')
  : []

export default class Authentication {
  static urlToken = envVariable('REACT_APP_OAUTH_ACCESS_TOKEN_URL');
  static callbackUrl = envVariable('REACT_APP_OAUTH_CALLBACK_URL');
  static clientId = envVariable('REACT_APP_OAUTH_CLIENT_ID');
  static localStorageTokenName = envVariable('REACT_APP_LOCAL_STORAGE_TOKEN_NAME');
  static codeVerifier = localStorage.getItem('code_verifier') ?? '';
  static urlAuthorisationServer = envVariable('REACT_APP_OAUTH_AUTH_URL');

  static getToken(): string {
    const oauth = localStorage.getItem(envVariable('REACT_APP_LOCAL_STORAGE_TOKEN_NAME')) ?
      JSON.parse(localStorage.getItem(envVariable('REACT_APP_LOCAL_STORAGE_TOKEN_NAME')) ?? '')
      : []

    return `${oauth.token_type} ${oauth.access_token}`
  }

  static async authorize(): Promise<boolean> {
    try {
      // generate CodeVerifier
      const array = new Uint32Array(56 / 2);
      window.crypto.getRandomValues(array);
      const codeVerifier = Array.from(array, encoder.dec2hex).join('');
      localStorage.setItem('code_verifier', codeVerifier);

      const code_challenge = await encoder.generateCodeChallengeFromVerifier(
        codeVerifier
      );

      localStorage.setItem('code_challenge', code_challenge);

      const urlToRedirect = `${Authentication.urlAuthorisationServer}?response_type=code&state=&client_id=${Authentication.clientId}&scope=&redirect_uri=${Authentication.callbackUrl}&code_challenge=${code_challenge}&code_challenge_method=S256`
      window.location.href = urlToRedirect
      return false;
    } catch (e) {
      return false;
    }
  }

  static async login(code: string|null): Promise<boolean> {
    if (null === code) {
      return false
    }

    const options = new URLSearchParams();
    options.append('grant_type', 'authorization_code');
    options.append('client_id', this.clientId);
    options.append('redirect_uri', this.callbackUrl);
    options.append('code', code);
    options.append('code_verifier', this.codeVerifier);

    let success = false;
    await fetch(this.urlToken, {
      method: 'POST',
      body: options,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      }
    })
    .then(res => res.json())
    .then((response) => {
      if (response.access_token) {
        response.expires_at = new Date().setSeconds(new Date().getSeconds() + response.expires_in);
        localStorage.setItem(this.localStorageTokenName, JSON.stringify(response));
        success = true;
      }
    })
    ;

    return success;
  }

  static async isLogged(): Promise<boolean> {
    if (oauth.access_token) {
      if ((new Date()).getTime() > oauth.expires_at) {
        return await Authentication.refreshToken();
      }
      return true;
    }
    return false;
  }

  static async refreshToken(): Promise<boolean> {
    const codeVerifier = localStorage.getItem('code_verifier') ?? '';
    const options = new URLSearchParams();
    options.append('grant_type', 'refresh_token');
    options.append('client_id', this.clientId);
    options.append('redirect_uri', this.callbackUrl);
    options.append('client_secret', codeVerifier);
    options.append('refresh_token', oauth.refresh_token);

    let success = false;
    await fetch(this.urlToken, {
      method: 'POST',
      body: options,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      }
    })
    .then(res => res.json())
    .then((response) => {
      if (response.access_token) {
        response.expires_at = new Date().setSeconds(new Date().getSeconds() + response.expires_in);
        localStorage.setItem(this.localStorageTokenName, JSON.stringify(response));
        success = true;
      } else {
        localStorage.removeItem(envVariable('REACT_APP_LOCAL_STORAGE_TOKEN_NAME'))
        window.location.replace(`${envVariable('REACT_APP_OAUTH_LOGOUT_URL')}`)
      }
    })

    return success;
  }

  static async logout(): Promise<boolean> {
    localStorage.removeItem(envVariable('REACT_APP_LOCAL_STORAGE_TOKEN_NAME'));
    return true;
  }
}
