import {FunctionComponent} from 'react'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import '../../../../../assets/styles/components/_tab.scss'

const TabProspect: FunctionComponent = () => {
  const {t} = useTranslation()

  return (
    <div className={`tab tab--prospect`}>
      <NavLink
        to={`/${t('url.prospect.dashboard')}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.tab.dashboard')}
      </NavLink>
    </div>
  )
}

export default TabProspect
