interface UsageItemInterface {
  delay: boolean
  duration: string
}

class UsageItem implements UsageItemInterface {

  public delay: boolean
  public duration: string

  constructor(
    delay: boolean,
    duration: string
  ) {
    this.delay = delay
    this.duration = duration
  }
}

export {
  UsageItem
}
export type { UsageItemInterface }
