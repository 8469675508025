import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import HeaderPage from '../../../component/HeaderPage/HeaderPage'
import '../../../../../assets/styles/page/_product.scss'
import {ProspectInterface} from "../../../../../domain/Prospect/Prospect";
import TabProspectEditPhysicalPerson
  from "../../../component/Tab/ProspectEditPhysicalPerson/TabProspectEditPhysicalPerson";
import TabProspectEditCorporation from "../../../component/Tab/ProspectEditCorporation/TabProspectEditCorporation";
import Status from "../../../component/Transaction/Status";
import { useAppSelector } from '../../../../store/hook'
import GetNameForTitle from '../../../util/GetNameForTitle'

type Props = {
  mode: "add" | "edit" | "read"
  setStatus: React.Dispatch<React.SetStateAction<string>>
  status: string
  prospect?: ProspectInterface
  isLectureMode?: boolean
}
const ProspectHeaderPage: FunctionComponent<Props> = React.memo(({prospect, isLectureMode, mode, status, setStatus}) => {
  const {t} = useTranslation()

  const referential = useAppSelector(({referential}) => referential.referential)
  const [tab, setTab] = useState<any|null>(null)
  const [title, setTitle] = useState<string>(mode === "add" ? t('prospect.add.title') :  t('prospect.edit.title'))

  useEffect(() => {
    if (prospect) {
      const titleFormatted = GetNameForTitle("prospect", prospect)
      if (prospect.prospectType === 'physical') {
        setTab(<TabProspectEditPhysicalPerson uuid={prospect.id}
                                              isLectureMode={isLectureMode}
                                              prospect={prospect}/>)
      } else if (prospect.prospectType === 'moral') {
        setTab(<TabProspectEditCorporation prospect={prospect}
                                           isLectureMode={isLectureMode}/>)
      }
      setTitle(`${t('prospect.edit.title')} ${titleFormatted}`)
      setStatus(prospect.status || "")
    }
  }, [prospect])

  return (
    <>
      <HeaderPage title={title}
                  notePadInfo={{
                    lastNotepadAt: prospect?.lastNotepadAt || "",
                    countNotepad: prospect?.countNotepad || 0 }}
      />
      {referential
        ?
        <Status label={"common.status"}
                status={status}
                statusList={referential?.prospect?.status}
                shortLabel
        />
        : <div className="badge-line--space-when-empty"/>
      }
      {tab}
    </>
  )
})

export default ProspectHeaderPage
