import { FunctionComponent } from 'react';
import {useTranslation} from 'react-i18next'

import '../../../../../assets/styles/components/_tab.scss'

const TabProspectAddPhysicalPerson: FunctionComponent = () => {
  const {t} = useTranslation()

  return (
    <div className={`tab tab--prospect-add`}>
      <span className={'tab__item tab__item--active'}>
        {t('account.add.tab-general-information')}
      </span>
      <span className={'tab__item tab__item--disabled'}>{t('account.add.tab-addresses')}</span>
      <span className={'tab__item tab__item--disabled'}>{t('account.add.tab-bank-details')}</span>
      <span className={'tab__item tab__item--disabled'}>{t('account.add.tab-pending-subscriptions')}</span>
      <span className={'tab__item tab__item--disabled'}>{t('account.add.tab-particularities')}</span>
    </div>
  )
}

export default TabProspectAddPhysicalPerson
