import {PersonInterface} from './Person'

interface BeneficialOwnerInterface {
  id: string
  status: string|null
  ppe: boolean
  shareHolding: string
  person: PersonInterface|null
}

class BeneficialOwner implements BeneficialOwnerInterface {

  public id: string
  public status: string|null
  public ppe: boolean
  public shareHolding: string
  public person: PersonInterface|null

  constructor(
    id: string,
    status: string|null,
    ppe: boolean,
    shareholding: string,
    person: PersonInterface|null
  ) {
    this.id = id
    this.status = status
    this.ppe = ppe
    this.shareHolding = shareholding
    this.person = person
  }
}

export {
  BeneficialOwner
}
export type { BeneficialOwnerInterface }
