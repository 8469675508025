import { FunctionComponent, useEffect } from 'react';
import {useTranslation} from "react-i18next";
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import HeaderPage from "../../../component/HeaderPage/HeaderPage";
import Tab from "../../../component/Tab/Movement/Tab";
import DismenbermentDatalist from "../../../component/Datalist/DismenbermentDatalist";
import DismembermentDatalistFilter from "../../../component/Sidebar/Movement/DismembermentDatalistFilter";
import { setOpenDatalistFilterDismenberment } from '../../../../store/component/event';

const MovementDismenberment: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)

  useEffect(() => {
    dispatch(setOpenDatalistFilterDismenberment( {
      show: false,
      count: 0,
      filters: {
        product: {
          id: '',
          value: '',
          label: '',
        },
        transactionType: '',
        status: [],
        tags: [],
        propertyType: [],
        paymentMode: '',
        user: null,
        name: '',
        keywords: ''
      }
    }))
  }, [])

  return (
    <>
      <DismembermentDatalistFilter />
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        <HeaderPage title={t('movement.title')} addLink={`/${t('url.movements.choice-type')}`}/>
        <Tab />
        <div className="partner-keys-figures">
          <div className="movement-keys-figures__title">{t('common.key-figures')}</div>
        </div>
        <DismenbermentDatalist />
      </main>
    </>
  )
}

export default MovementDismenberment
