import {FunctionComponent} from 'react'

type Props = {
  color?: string
}

const Diligencio: FunctionComponent<Props> = ({color}) => {
    return (
        <svg width="697" height="158" viewBox="0 0 697 158" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M127 33.7H108.2V109.9H127V33.7Z" fill={color || 'white'}/>
                <path d="M106.5 12.6C106.5 27.2 128.7 27.2 128.7 12.6C128.7 -2.09999 106.5 -2.09999 106.5 12.6Z" fill={color || 'white'}/>
                <path d="M162.5 2.3H143.9V109.9H162.5V2.3Z" fill={color || 'white'}/>
                <path d="M197.8 33.7H179V109.9H197.8V33.7Z" fill={color || 'white'}/>
                <path d="M177.3 12.6C177.3 27.2 199.5 27.2 199.5 12.6C199.5 -2.09999 177.3 -2.09999 177.3 12.6Z" fill={color || 'white'}/>
                <path d="M336.6 31.7C312 31.7 296.3 48.3 296.3 71.4C296.3 95.7 311.8 111.9 338 111.9C349.5 111.9 362.6 107.9 370.6 99.6L358.6 87.7C354.3 92.2 345.2 94.8 338.3 94.8C326.4 94.8 318.6 89.3 316.3 81.4C313.7 74 314.6 64.9 317.1 60.2C320.6 52.3 328.2 48.4 337.2 48.4C348 48.4 355.7 53.3 356.9 63.2H325.2C324.3 65.1 323.5 67.7 323.5 71.1C323.5 74.6 324.2 77.1 325 78.7H374.9C377.9 47.4 362 31.7 336.6 31.7Z" fill={color || 'white'}/>
                <path d="M516.1 94.1C504.4 94.1 494.6 86.4 494.6 71.9C494.6 58.7 503.8 49.4 516.5 49.4C521.7 49.4 527.3 51.4 531.7 55.4L543.6 42.9C535.3 34.7 527 31.7 516.2 31.7C494.2 31.7 475.9 44.9 475.9 71.9C475.9 98.8 494.2 112.1 516.2 112.1C527.4 112.1 536.7 108.7 545.4 100.1L532.8 87.8C528.1 92.5 522.1 94.1 516.1 94.1Z" fill={color || 'white'}/>
                <path d="M572.5 33.7H553.7V109.9H572.5V33.7Z" fill={color || 'white'}/>
                <path d="M552 12.6C552 27.2 574.2 27.2 574.2 12.6C574.2 -2.09999 552 -2.09999 552 12.6Z" fill={color || 'white'}/>
                <path d="M679 13.6L668.2 30.3C674.2 37.7 676.8 47.6 676.7 56.9C676.4 74.6 666.8 93.7 641.4 93.7C616 93.7 606.5 74.8 606 57.1C605.5 38.9 616 18.5 641.4 18.5C646.8 18.5 651.4 19.5 655.5 21.1L665.9 5C658.9 1.8 650.8 0 641.4 0C604.2 0 586 28.5 586 56.8C586 85.1 603.5 112.4 641.4 112.4C679.3 112.4 696.4 84.5 696.7 56.7C696.9 40.6 691 24.5 679 13.6Z" fill={color || 'white'}/>
                <path d="M42.3 2.10001C29.1 2.10001 13.2 2.10001 0 2.10001V78.8H20.2V21.3H42.4C65.8 21.3 75.6 38.1 76.1 54.9C76.6 72.4 66.9 90.3 42.4 90.3H20.2V90.1H0V109.9C13.2 109.9 29.1 109.9 42.3 109.9C80 109.9 96.6 82.3 96.2 55.1C95.7 28.4 79.3 2.10001 42.3 2.10001Z" fill={color || 'white'}/>
                <path d="M274.3 26.3L266.8 35.8C261.6 32.3 255.4 31.5 249.4 31.5C228.2 31.5 210.9 46.3 210.9 70C210.9 93.7 225.7 108.2 249.4 108.2C258.8 108.2 268.6 113 268.6 123.4C268.6 133.9 260.1 139.7 249.4 139.7C238.6 139.7 229.5 133.2 229.5 123.4H210.9C210.9 143.9 227.4 157.3 249.4 157.3C271.4 157.3 287.4 144.4 287.4 123.4C287.4 114.1 284.5 105.5 273.2 99.2C267.3 96.4 257.4 91.1 249.8 91.1C249.7 91.1 249.7 91.1 249.6 91.1C249.5 91.1 249.4 91.1 249.3 91.1C238.4 91.1 229.4 83.3 229.4 70C229.4 56.8 238.3 48.6 249.3 48.6C260.1 48.6 269 56.9 269 70C269 74.7 267.8 78.7 265.8 82C270 83.3 275.9 85.7 281.8 89.8C286.2 83.6 287.7 76 287.7 70C287.7 61.7 285.5 54.1 279.7 47.5L288.2 36.7L274.3 26.3Z" fill={color || 'white'}/>
                <path d="M431.9 32.7C427 32.7 423 33.5 419.1 35.2V51.4C421.4 50.3 424.1 49.7 426.9 49.7C438.9 49.7 445.2 58.5 445.2 70V109.7H464V70C464.1 46.1 449.8 32.7 431.9 32.7Z" fill={color || 'white'}/>
                <path d="M389 109.8H407.6V71C407.6 69.9 407.7 68.8 407.9 67.7V33.8H389V109.8Z" fill={color || 'white'}/>
        </svg>
    )
}

export default Diligencio
