import ProspectGatewayInterface from '../../../domain/Prospect/ProspectGatewayInterface'
import {ProspectInterface} from '../../../domain/Prospect/Prospect'

export default class AddUseCase
{
  public prospectGateway

  constructor(ProspectGateway: ProspectGatewayInterface) {
    this.prospectGateway = ProspectGateway
  }

  async execute(Prospect: ProspectInterface): Promise<ProspectInterface|null> {
    return await this.prospectGateway.add(Prospect).then(response => {
      if (response) {
        return response
      }

      return null
    })
  }
}
