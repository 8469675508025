import {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import HeaderPage from '../../../component/HeaderPage/HeaderPage'
import Tab from '../../../component/Tab/PreSubscription/Tab'
import PreSubscriptionDatalist from '../../../component/Datalist/PreSubscriptionDatalist'

import '../../../../../assets/styles/page/_movement.scss'
import {useNavigate} from "react-router-dom";
import MovementDatalistFilter from "../../../component/Sidebar/Movement/PreSouscriptionDatalistFilter";
import { setOpenDatalistFilterPreSubscription } from '../../../../store/component/event';
import Kpi from "../../../component/Kpi/Kpi";
import PreSubscriptionGateway from "../../../../../gateway/PreSubscription/PreSubscriptionGateway";

const PreSubscriptionDashboard: FunctionComponent = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const subscriptionRules = useAppSelector((state) => state.me.me?.rules.subscription)

  if(!subscriptionRules?.visible) navigate(`/`)
  const [type, setType] = useState<string>('')

  useEffect(() => {
    dispatch(setOpenDatalistFilterPreSubscription({show: false, count: 0, filters: {
        product: {
          id: '',
          value: '',
          label: '',
        },
        transactionType: '',
        status: [],
        tags: [],
        propertyType: [],
        paymentMode: '',
        user: null,
        name: '',
        keywords: ''
      }}))
  }, [dispatch])

  return (
    <>
      <MovementDatalistFilter />
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        <HeaderPage title={t('movement.pre-subscription.title')} addLink={subscriptionRules?.actions?.create ? `/${t('url.pre-subscription.add')}` : undefined} />
        <Tab />
        <div className="partner-keys-figures">
          <div className="movement-keys-figures__title u-mys">{t('common.key-figures')}</div>
          <Kpi getKpi={new PreSubscriptionGateway().getKpi} />
        </div>
        {subscriptionRules?.actions?.list && <PreSubscriptionDatalist type={type}/>}
      </main>
    </>
  )
}

export default PreSubscriptionDashboard
