import {FunctionComponent} from 'react'
import {NavLink, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import '../../../../../assets/styles/components/_tab.scss'

type Props = {
  isMultiProduct: boolean
}

const TabProductAdd: FunctionComponent<Props> = ({isMultiProduct}) => {
  const {t} = useTranslation()
  const { productType } = useParams()

  return (
    <div className={`tab tab--product-add`}>
      <NavLink
        to={`/${t('url.product.add')}/${productType}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('product.add.tab-general-information')}
      </NavLink>
      {(productType === 'fia' || productType === 'sc' || productType === 'other') &&
        <span className={'tab__item tab__item--disabled'}>{t('product.add.tab-net-asset-value')}</span>
      }
      {isMultiProduct &&
      <span className={'tab__item tab__item--disabled'}>{t('product.add.tab-multi-products')}</span>}
      <span className={'tab__item tab__item--disabled'}>{t('product.add.tab-distribution')}</span>
      <span className={'tab__item tab__item--disabled'}>{t('product.add.tab-fiscality')}</span>
      <span className={'tab__item tab__item--disabled'}>{t('product.add.tab-ag')}</span>
      <span className={'tab__item tab__item--disabled'}>{t('product.add.tab-bank-detail')}</span>
      <span className={'tab__item tab__item--disabled'}>{t('product.add.tab-commission')}</span>
      <span className={'tab__item tab__item--disabled'}>{t('product.add.tab-depositary')}</span>
    </div>
  )
}

export default TabProductAdd
