import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import {setPartnerTransfertTarget} from '../../../../store/component/partner';
import { setOpenChoicePartner } from '../../../../store/component/event';
import blockScrollBody from '../../../util/BlockScroll';
import { PartnerListInterface } from '../../../../../domain/Partner/PartnerList';
import getClassForOverlay from '../../../util/Sidebar';
import PartnerDatalist from '../../Datalist/PartnerDatalist';
import PartnerGateway from '../../../../../gateway/Partner/PartnerGateway';

const ChoicePartner: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openChoicePartner = useAppSelector((state) => state.event.openChoicePartner)

  useEffect(() => {
    blockScrollBody(openChoicePartner.show)
  }, [openChoicePartner])


  const handleChoicePartner = async function (selectedPartner: PartnerListInterface) {
    const partnerFetched = await new PartnerGateway().get(selectedPartner.id)
    dispatch(setPartnerTransfertTarget(partnerFetched))
    dispatch(setOpenChoicePartner({show: false}))
  }

  const handleClose = function () {
    dispatch(setOpenChoicePartner({show: false}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openChoicePartner.show)}`} onClick={() => handleClose()}/>
      <div className={`sidebar sidebar--right sidebar--right ${openChoicePartner.show ? 'sidebar--active-big' : ''}`}>
        <div className="form-bloc">
          <div className="sidebar__content">
            <div className="title">{t('partner.add.title')}</div>
            <PartnerDatalist callback={handleChoicePartner}
                             excludeHimSelf={openChoicePartner?.excludeHimSelf}
                             partnerId={openChoicePartner?.partnerId}
                             callerType={"datalist"}
            />
          </div>
          <footer className="sidebar__footer">
            <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => handleClose()}>{t('common.cancel')}</button>
          </footer>
        </div>
      </div>
    </>
  )
}
export default ChoicePartner
