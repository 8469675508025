import React, {FunctionComponent, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../../store/hook";
import blockScrollBody from "../../../../util/BlockScroll";
import {PledgeInterface} from "../../../../../../domain/Pledge/Pledge";
import {SubmitHandler, useForm} from "react-hook-form";
import {setOpenChoiceSliceToGiveUpEvent, setOpenPledgeForm} from "../../../../../store/component/event";
import getClassForOverlay from "../../../../util/Sidebar";
import {ReferentielInterface} from "../../../../../../domain/Referentiel/ReferentielInterface";
import SelectCustom from "../../../Elements/Select";
import PledgeGateway from "../../../../../../gateway/Pledge/PledgeGateway";
import {toastError, toastSuccess} from "../../../../util/Toast";
import ProductDalalist from "../../../Datalist/Product/ProductDalalist";
import InputDate from "../../../Elements/InputDate";
import eyeIcon from "../../../../../../assets/images/icons/eye.svg";
import PledgeOrganizationSelect from "../../../Datalist/PledgeOrganization/PledgeOrganizationSelect";
import ChoiceSliceToGiveUp from "../../Movement/ChoiceSliceToGiveUp";
import {addSliceToPledge} from "../../../../../store/component/slice";
import {addPledgeInCurrentDataList, updatePledgeCurrentDataList} from "../../../../../store/component/customer";
import Input from "../../../Elements/Input";

interface IFormInput {
  pledge: PledgeInterface
}

type Props = {
  investorId: string | null
  referential: ReferentielInterface
}

const AddPledge: FunctionComponent<Props> = ({investorId, referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openPledgeForm = useAppSelector((state) => state.event.openPledgeForm)
  const slicePledgeSelected = useAppSelector((state) => state.slice.slicePledgeSelected)

  const {register, control, handleSubmit, reset, setValue, getValues, watch} = useForm<IFormInput>();

  const watchTypePledge = watch('pledge.type')
  const watchProductPledge = watch('pledge.product')

  useEffect(() => {
    blockScrollBody(openPledgeForm.show)
    reset()
    if (openPledgeForm.pledge) {
      setValue('pledge.id', openPledgeForm.pledge.id)
      setValue('pledge.pledgeOrganization', {
        id: openPledgeForm.pledge.pledgeOrganization?.id || null,
        label: openPledgeForm.pledge.pledgeOrganization?.name || "",
        value: openPledgeForm.pledge.pledgeOrganization?.id || null,
      })
      setValue('pledge.type', openPledgeForm.pledge.type)
      setValue('pledge.reason', openPledgeForm.pledge.reason)
      setValue('pledge.product', {
        id: openPledgeForm.pledge.product?.id || null,
        label: openPledgeForm.pledge.product?.generalInformation.acronyme || "",
        value: openPledgeForm.pledge.product?.id || null,
      })
      setValue('pledge.startAt', openPledgeForm.pledge.startAt)
      setValue('pledge.endAt', openPledgeForm.pledge.endAt)
      setValue('pledge.handAt', openPledgeForm.pledge.handAt)
      setValue('pledge.quantity', openPledgeForm.pledge.quantity)
      setValue('pledge.startShare', openPledgeForm.pledge.startShare)
      setValue('pledge.endShare', openPledgeForm.pledge.endShare)
    } else {
      setValue('pledge.id', 'provisional_' + crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('pledge.id', null)
      setValue('pledge.pledgeOrganization', null)
      setValue('pledge.type', null)
      setValue('pledge.reason', null)
      setValue('pledge.product', null)
      setValue('pledge.startAt', null)
      setValue('pledge.endAt', null)
      setValue('pledge.handAt', null)
      setValue('pledge.quantity', null)
      setValue('pledge.startShare', null)
      setValue('pledge.endShare', null)
    }

  }, [openPledgeForm])

  useEffect(() => {
    if (slicePledgeSelected && watchTypePledge === "slice") {
      setValue('pledge.quantity', slicePledgeSelected?.count)
      setValue('pledge.startShare', slicePledgeSelected?.startPart)
      setValue('pledge.endShare', slicePledgeSelected?.endPart)
    } else {
      setValue('pledge.quantity', openPledgeForm?.pledge?.quantity || null)
      setValue('pledge.startShare', openPledgeForm?.pledge?.startShare || null)
      setValue('pledge.endShare', openPledgeForm?.pledge?.endShare || null)
    }

  }, [slicePledgeSelected, watchTypePledge])

  const onSubmit: SubmitHandler<IFormInput> = data => {
    if (openPledgeForm.pledge?.id) {
      (new PledgeGateway())
        .update(data.pledge, investorId, slicePledgeSelected?.id || openPledgeForm.pledge?.slice?.id || null)
        .then((response) => {
          if (response) {
            toastSuccess(t('account.form.pledge.notify.edit-success'));
            dispatch(updatePledgeCurrentDataList(response))
          } else toastError(t('account.form.pledge.notify.edit-error'));
        })
    } else {
      (new PledgeGateway()).add(data.pledge, investorId, slicePledgeSelected?.id || null).then((response) => {
        if (response) {
          toastSuccess(t('account.form.pledge.notify.add-success'));
          dispatch(addPledgeInCurrentDataList(response))
        } else toastError(t('account.form.pledge.notify.add-error'));

      })
    }

    reset()
    dispatch(setOpenPledgeForm({show: false, pledge: null}))
    dispatch(addSliceToPledge(null))
  }

  function handleClose() {
    reset()
    dispatch(setOpenPledgeForm({show: false, pledge: null}))
    dispatch(addSliceToPledge(null))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openPledgeForm.show)}`} onClick={() => handleClose()}/>
      <div className={`sidebar sidebar--right sidebar--right ${openPledgeForm.show ? 'sidebar--active' : ''}`}>
        <form onSubmit={handleSubmit(onSubmit)} className="form-bloc form-bloc--customer-customerAddress-form">
          <div className="sidebar__content">
            <div className="title">{t('account.form.pledge.add')}</div>
            <div className="col-md-6">
              <div className="flex-container">
                <PledgeOrganizationSelect classes="col-md-12"
                                          id="pledge.pledgeOrganization"
                                          name="pledge.pledgeOrganization"
                                          label={t('account.form.pledge.sidebar.pledgeOrganization')}
                                          control={control}
                                          defaultValue={getValues('pledge.pledgeOrganization')}
                />

                <SelectCustom classes="col-md-12"
                              id="pledge.type"
                              name="pledge.type"
                              options={referential.customer.pledge_type}
                              register={register}
                              label={t('account.form.pledge.sidebar.type')}
                />

                <ProductDalalist classes="col-md-12"
                                 id="pledge.product"
                                 name="pledge.product"
                                 label={t('account.form.pledge.sidebar.product')}
                                 control={control}
                                 defaultValue={getValues('pledge.product')}
                />
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="search_patrimony"
                           className="form-control__label">{t('account.form.pledge.sidebar.search-patrimony')}</label>
                    <div className="form-control__input">
                      <button type="button"
                              className="button-reset"
                              name="read.document"
                              id="search_patrimony"
                              onClick={() => watchProductPledge?.id && dispatch(setOpenChoiceSliceToGiveUpEvent({
                                show: true,
                                prospectId: investorId,
                                productId: watchTypePledge === "full" ? "" : getValues('pledge.product')?.id,
                                shareCount: null,
                                choicePartSelectAll: watchTypePledge === "product",
                                isForPledge: true
                              }))}
                              disabled={!watchProductPledge?.id || watchTypePledge === "full"}
                      >
                        <img src={eyeIcon} alt=""/>
                      </button>
                    </div>
                  </div>
                </div>
                <InputDate register={register}
                           control={control}
                           classes="col-md-12"
                           type="text"
                           name="pledge.startAt"
                           id="pledge.startAt"
                           label={t('account.form.pledge.sidebar.startAt')}
                />
                <InputDate register={register}
                           control={control}
                           classes="col-md-12"
                           type="text"
                           name="pledge.endAt"
                           id="pledge.endAt"
                           label={t('account.form.pledge.sidebar.endAt')}
                />
                <InputDate register={register}
                           control={control}
                           classes="col-md-12"
                           type="text"
                           name="pledge.handAt"
                           id="pledge.handAt"
                           label={t('account.form.pledge.sidebar.handAt')}
                />
                <Input
                  id={'pledge.quantity'}
                  name={'pledge.quantity'}
                  register={register}
                  classes="col-md-12"
                  label={t('account.form.pledge.sidebar.quantity')}
                  readOnly
                />
                <Input
                  id={'pledge.startShare'}
                  name={'pledge.startShare'}
                  register={register}
                  classes="col-md-12"
                  label={t('account.form.pledge.sidebar.startShare')}
                  readOnly
                />
                <Input
                  id={'pledge.endShare'}
                  name={'pledge.endShare'}
                  register={register}
                  classes="col-md-12"
                  label={t('account.form.pledge.sidebar.quantity')}
                  readOnly
                />
                <SelectCustom classes="col-md-12"
                              id="pledge.reason"
                              name="pledge.reason"
                              options={referential.customer.pledge_reason}
                              register={register}
                              label={t('account.form.pledge.sidebar.reason')}
                />
              </div>
            </div>
          </div>

          <footer className="sidebar__footer">
            <button type="submit"
                    className="button button--ink-2 u-mrm"
            >
              {t('common.save')}
            </button>
            <button type="button"
                    className="button button--ink-2 button--ink-2--outline"
                    onClick={() => handleClose()}
            >
              {t('common.cancel')}
            </button>
          </footer>
        </form>
      </div>
      <ChoiceSliceToGiveUp/>
    </>
  )
}

export default AddPledge
