export const ListReferentielPartner = {
  "global": {
    "activity": [
      {
        "value": "army",
        "label": "Militaire"
      },
      {
        "value": "food",
        "label": "Agro alimentaire"
      },
      {
        "value": "public",
        "label": "Secteur public"
      },
      {
        "value": "private",
        "label": "Secteur privé"
      }
    ],
    "family": [
      {
        "value": "pacs",
        "label": "Pacsé"
      },
      {
        "value": "widow",
        "label": "Veuf"
      },
      {
        "value": "single",
        "label": "Célibataire"
      },
      {
        "value": "married",
        "label": "Marié"
      },
      {
        "value": "partner",
        "label": "Concubin"
      },
      {
        "value": "divorced",
        "label": "Divorcé"
      },
      {
        "value": "separate",
        "label": "Séparé"
      },
      {
        "value": "processing_divorce",
        "label": "En instance de divorce"
      }
    ],
    "matrimonial_regime": [
      {
        "value": "acquet",
        "label": "Comm. légale réduit aux acquêts"
      },
      {
        "value": "separate",
        "label": "Séparation de biens"
      },
      {
        "value": "universal",
        "label": "Comm. universelle"
      },
      {
        "value": "acquet_participation",
        "label": "Participation aux acquêts"
      }
    ],
    "profession": [
      {
        "value": "farmer",
        "label": "AGRICULTEUR EXPLOITANT"
      },
      {
        "value": "leader",
        "label": "DIRIGEANT D ENTREPRISE"
      },
      {
        "value": "artisan",
        "label": "ARTISAN OU COMMERCANT"
      },
      {
        "value": "student",
        "label": "ELEVE ETUDIANT OU APPRENTI"
      },
      {
        "value": "unknown",
        "label": "NON RENSEIGNE"
      },
      {
        "value": "employee",
        "label": "EMPLOYE"
      },
      {
        "value": "executive",
        "label": "CADRE"
      },
      {
        "value": "fundation",
        "label": "FONDATION"
      },
      {
        "value": "religious",
        "label": "RELIGIEUX"
      },
      {
        "value": "is_society",
        "label": "SOCIETE (A L IS)"
      },
      {
        "value": "retirement",
        "label": "RETRAITE"
      },
      {
        "value": "unemployed",
        "label": "SANS ACTIVITE"
      },
      {
        "value": "association",
        "label": "ASSOCIATION"
      },
      {
        "value": "independant",
        "label": "INDEPENDANT OU PROF LIBERALE"
      },
      {
        "value": "not_is_society",
        "label": "SOCIETE (NON IS)"
      },
      {
        "value": "public_employee",
        "label": "EMPLOYE DU SECTEUR PUBLIC"
      },
      {
        "value": "exonerated_society",
        "label": "SOCIETE EXONEREE"
      }
    ],
    "property_type": [
      {
        "value": "usufruct",
        "label": "Usufruit"
      },
      {
        "value": "bare_property",
        "label": "Nu propriété"
      },
      {
        "value": "dismemberment",
        "label": "Démembrement"
      },
      {
        "value": "full_property",
        "label": "Pleine propriété"
      }
    ],
    "relationship": [
      {
        "value": "owner",
        "label": "Bénéficiaire effectif"
      },
      {
        "value": "husband",
        "label": "Epoux"
      },
      {
        "value": "partner",
        "label": "Partenaire"
      },
      {
        "value": "employee",
        "label": "Salarié interne"
      },
      {
        "value": "multiple",
        "label": "Comptes multiples"
      },
      {
        "value": "ascending",
        "label": "Ascendant"
      },
      {
        "value": "descending",
        "label": "Descendant"
      }
    ],
    "title": [
      {
        "value": "m",
        "label": "M."
      },
      {
        "value": "mme",
        "label": "Mme"
      },
      {
        "value": "mlle",
        "label": "Mlle"
      }
    ],
    "title_moral": [
      {
        "value": "sa",
        "label": "SA"
      },
      {
        "value": "sc",
        "label": "SC"
      },
      {
        "value": "gie",
        "label": "GIE"
      },
      {
        "value": "ind",
        "label": "Ind"
      },
      {
        "value": "sas",
        "label": "SAS"
      },
      {
        "value": "scf",
        "label": "SCF"
      },
      {
        "value": "sci",
        "label": "SCI"
      },
      {
        "value": "scp",
        "label": "SCP"
      },
      {
        "value": "sgp",
        "label": "SGP"
      },
      {
        "value": "snc",
        "label": "SNC"
      },
      {
        "value": "earl",
        "label": "EARL"
      },
      {
        "value": "eirl",
        "label": "EIRL"
      },
      {
        "value": "eurl",
        "label": "EURL"
      },
      {
        "value": "sarl",
        "label": "SARL"
      },
      {
        "value": "sasu",
        "label": "SASU"
      },
      {
        "value": "etude",
        "label": "ETUDE"
      },
      {
        "value": "abbaye",
        "label": "ABBAYE"
      },
      {
        "value": "banque",
        "label": "BANQUE"
      },
      {
        "value": "selarl",
        "label": "SELARL"
      },
      {
        "value": "selasu",
        "label": "SELASU"
      },
      {
        "value": "cabinet",
        "label": "CABINET"
      },
      {
        "value": "domaine",
        "label": "DOMAINE"
      },
      {
        "value": "seleurl",
        "label": "SELEURL"
      },
      {
        "value": "societe",
        "label": "SOCIETE"
      },
      {
        "value": "institut",
        "label": "INSTITUT"
      },
      {
        "value": "mutuelle",
        "label": "MUTUELLE"
      },
      {
        "value": "syndicat",
        "label": "SYNDICAT"
      },
      {
        "value": "fondation",
        "label": "FONDATION"
      },
      {
        "value": "monastere",
        "label": "MONASTERE"
      },
      {
        "value": "seminaire",
        "label": "SEMINAIRE"
      },
      {
        "value": "association",
        "label": "ASSOCIATION"
      },
      {
        "value": "congregation",
        "label": "CONGREGATION"
      },
      {
        "value": "etablissement",
        "label": "ETABLISSEMENT"
      },
      {
        "value": "fonds de dotation",
        "label": "FONDS DE DOTATION"
      }
    ],
    "title_physic": [
      {
        "value": "dr",
        "label": "Dr"
      },
      {
        "value": "me",
        "label": "ME"
      },
      {
        "value": "mr",
        "label": "M."
      },
      {
        "value": "mme",
        "label": "Mme"
      },
      {
        "value": "mml",
        "label": "M. Ml"
      },
      {
        "value": "mrs",
        "label": "MRS"
      },
      {
        "value": "mmes",
        "label": "MME MME"
      },
      {
        "value": "mmme",
        "label": "M.Mme"
      },
      {
        "value": "melle",
        "label": "Mlle"
      },
      {
        "value": "succession",
        "label": "Ssion"
      }
    ]
  },
  "prospect": {
    "status": [
      {
        'value': 'prospect',
        'label': 'Prospect',
      },
      {
        'value': 'active',
        'label': 'Actif',
      },
      {
        'value': 'old',
        'label': 'Ancien client',
      },
      {
        'value': 'deceased',
        'label': 'DCD',
      }
    ]
  },
  "partner": {
    "aggregator": [
      {
        "value": "harvest",
        "label": "Harvest"
      },
      {
        "value": "manymore",
        "label": "Manymore"
      }
    ],
    "partner_type": [
      {
        "value": "cif",
        "label": "CIF/GSP"
      },
      {
        "value": "psi",
        "label": "PSI"
      },
      {
        "value": "sgp",
        "label": "SGP et autres fonds"
      },
      {
        "value": "seller",
        "label": "Démarcheur"
      },
      {
        "value": "manager",
        "label": "Manageur"
      },
      {
        "value": "network",
        "label": "Réseau"
      },
      {
        "value": "platform",
        "label": "Plateforme"
      },
      {
        "value": "insurrance",
        "label": "Compagnie d assurance"
      }
    ],
    "role": [
      {
        "value": "legal",
        "label": "Juridique"
      },
      {
        "value": "office",
        "label": "Back/Middle Office"
      },
      {
        "value": "accounting",
        "label": "Comptabilité"
      },
      {
        "value": "commercial",
        "label": "Commercial"
      },
      {
        "value": "management",
        "label": "Direction"
      },
      {
        "value": "billing",
        "label": "Facturation"
      }
    ],
    "status": [
      {
        "value": "active",
        "label": "Actif"
      },
      {
        "value": "blocked",
        "label": "Bloqué"
      },
      {
        "value": "signing",
        "label": "En signature"
      },
      {
        "value": "waiting",
        "label": "En attente de documents"
      },
      {
        "value": "inactive",
        "label": "Inactif"
      },
      {
        "value": "prospect",
        "label": "Prospect"
      }
    ]
  }
}

export const ListReferentielProspect = {
    "status": [
      {
        'value': 'prospect',
        'label': 'Prospect',
      },
      {
        'value': 'active',
        'label': 'Actif',
      },
      {
        'value': 'old',
        'label': 'Ancien client',
      },
      {
        'value': 'deceased',
        'label': 'DCD',
      }
    ]
}

export const optionsNbRows = [
  {
    value: "50",
    label: "50"
  },
  {
    value: "100",
    label: "100"
  },
  {
    value: "200",
    label: "200"
  }
]
