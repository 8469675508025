import {FilterDismenbermentInterface} from "../../../domain/Movement/Dismemberment/DismenbermentList";

interface ListRequestInterface {
  currentPage: number
  itemPerPage: number
  filter: FilterDismenbermentInterface|null
}

class ListRequest implements ListRequestInterface{
  public currentPage: number
  public itemPerPage: number
  public filter: FilterDismenbermentInterface|null

  constructor(
    currentPage: number,
    itemPerPage: number,
    filter: FilterDismenbermentInterface|null,
  ) {
    this.currentPage = currentPage
    this.itemPerPage = itemPerPage
    this.filter = filter
  }
}

export {
  ListRequest
}
export type { ListRequestInterface }
