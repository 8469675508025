import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../store/hook";
import {setOpenPledgeOrganizationForm} from "../../../../store/component/event";
import {v4 as uuidV4} from "uuid";
import iconHeadingSearch from "../../../../../assets/images/icons/datalist-heading-search.svg";
import iconEdit from "../../../../../assets/images/icons/datalist-edit.svg";
import Pagination from "../../Pagination/Pagination";
import {SubmitHandler, useForm} from "react-hook-form";
import {
  FilterPledgeOrganizationInterface,
  PledgeOrganizationListInterface
} from "../../../../../domain/Pledge/PledgeOrganizationList";
import PledgeOrganizationGateway from "../../../../../gateway/Pledge/PledgeOrganizationGateway";
import PledgeOrganizationPresenter
  from "../../../../../presenter/PledgeOrganization/PledgeOrganizationPresenter";
import download from "../../../util/Download";
import HeaderRight from "../Element/HeaderRight";
import {ListRequest} from "../../../../../useCase/PledgeOrganization/List/ListRequest";
import ListUseCase from "../../../../../useCase/PledgeOrganization/List/ListUseCase";
import {optionsNbRows} from "../../../../../fixtures/Referentiel";
import SelectCustom from "../../Elements/Select";
import {SortInterface, SortOrder} from "../../../../../domain/Utils/List";
import TableHead from "../../Table/TableHead";


interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

const PledgeOrganizationDatalist: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [filters, setFilters] = useState<FilterPledgeOrganizationInterface>({
    keywords: ''
  })
  const openPledgeOrganizationForm = useAppSelector((state) => state.event.openPledgeOrganizationForm)

  const paginate = (pageNumber:number) => {controller.abort(); setCurrentPage(pageNumber)}
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)
  const controller = new AbortController()
  const preferences = localStorage.getItem("preferences")

  const { register, handleSubmit, setValue, watch } = useForm()

  const watchNumberRows = watch('numberRows')

  useEffect(() => {
    if(!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      const listRequest = new ListRequest(currentPage, watchNumberRows || 50, filters)

      const pledgeOrganizations = new ListUseCase(new PledgeOrganizationGateway()).execute(listRequest, controller.signal).then(response => {
        return response
      })
      const presenter = new PledgeOrganizationPresenter(pledgeOrganizations);
      presenter.load().then(() => {
        setViewModel(presenter.immutableViewModel())
      })
    }
  }, [currentPage, filters, watchNumberRows, openPledgeOrganizationForm])

  const onSubmit: SubmitHandler<FilterPledgeOrganizationInterface> = data => {
    setFilters({
      keywords: data.keywords
    })
  }

  const resetFilters = () => {
    setValue('keywords', '')
    setFilters({
      keywords: ''
    })
  }

  const handleCLickExport = () => {
    setLoadingExport(true)

    new PledgeOrganizationGateway().getExport(filters).then(response => {
      if (response) {
        download(t('export.pledgeorganization'), response)
        setLoadingExport(false)
      }
    })
  }

  return (
    <>
      {(viewModel !== null &&
        <>
          <div className={`datalist`}>
            <div className="datalist__header">
              <form onSubmit={handleSubmit(onSubmit)} className="filter">
                <div className="filter__input">
                  {viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                    <div key={uuidV4()} className="input-no-border">
                      <img src={iconHeadingSearch} alt="" />
                      <input {...register(filter.field)} placeholder={t('common.search-by', {keyword: t(filter.keyword)})} className="u-mxs"/>
                    </div>
                  ))}
                </div>
                <div className="filter__actions">
                  <button type="submit" className="button button--submit">{t('search.submit')}</button>
                  <button type="button" className="button button--white" onClick={() => resetFilters()}>{t('search.cancel')}</button>
                </div>
              </form>
              <HeaderRight numberOfActivatedFilters={0} handleClickFilter={null} handleClickExport={handleCLickExport} isLoadingExport={isLoadingExport} />
            </div>
            <SelectCustom classes="flex justify-end u-mbs" id="numberRows"
                          name="numberRows"
                          label={t('filters.display-results-by')}
                          options={optionsNbRows}
                          register={register}
                          noChoiceOption
            />
            <table className="datalist__datas">
              <thead>
              {viewModel.heading && <TableHead typeFilter={"API"}
                                               heading={viewModel.heading}
                                               sortOrder={sortOrder}
                                               setSortOrder={setSortOrder}
                                               viewModel={viewModel}
                                               setViewModel={setViewModel}
                                               filter={filters}
                                               watchNumberRows={watchNumberRows}
                                               currentPage={currentPage}
                                               listRequest={ListRequest}
                                               listUseCase={ListUseCase}
                                               listPresenter={PledgeOrganizationPresenter}
                                               gateway={PledgeOrganizationGateway}
              />}
              </thead>
              <tbody>
              {viewModel.data !== undefined &&
              viewModel.data.map((item: PledgeOrganizationListInterface) => (
                <tr key={uuidV4()}>
                  <td>
                    <button type="button" className="button-reset">
                      <img onClick={() => dispatch(setOpenPledgeOrganizationForm({show: true, pledgeOrganization: item}))} src={iconEdit} alt="" />
                    </button>
                  </td>
                  <td>{item.name}</td>
                  <td>{item.city?.label}</td>
                  <td>{item.status}</td>
                </tr>
              ))}
              {viewModel.data === undefined || viewModel.data.length === 0 &&
              <tr>
                <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
              </tr>
              }
              </tbody>
            </table>
          </div>
          <Pagination currentPage={currentPage} itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage} numberOfItems={viewModel.pagination.numberOfItems} callback={paginate} />
        </>
      )}
    </>
  )
}

export default PledgeOrganizationDatalist
