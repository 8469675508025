import GeneralSettingsGateway
  from "../../../gateway/Settings/GeneralSettings/GeneralSettingsGateway";
import {SettingsInterface} from "../../../domain/Settings/GeneralSettings/Settings";

export default class UpdateUseCase
{
  public generalSettingsGateway

  constructor(generalSettingsGateway: GeneralSettingsGateway) {
    this.generalSettingsGateway = generalSettingsGateway
  }

  async execute(generalSettings: SettingsInterface): Promise<{ uuid: string }|null> {
    return await this.generalSettingsGateway.update(generalSettings).then(response => response)
  }
}
