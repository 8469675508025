import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {SubmitHandler, useForm, useFormState} from 'react-hook-form'
import {v4 as uuidV4} from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../../../store/hook'
import {
  setOpenAccountUndividedForm,
  setOpenCustomerUploadForm, setOpenDocumentList,
} from '../../../../../store/component/event'
import {ReferentielInterface} from '../../../../../../domain/Referentiel/ReferentielInterface'
import ReferentielGateway from '../../../../../../gateway/Referentiel/ReferentielGateway'

import iconAdd from '../../../../../../assets/images/icons/add.svg'
import iconExtend from '../../../../../../assets/images/icons/expend.svg'
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {usePrompt} from "../../../../util/Navigation";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import {CustomerInterface} from "../../../../../../domain/Customer/Customer";
import {Undivided} from "../../../../../../domain/Prospect/Undivided";
import InitializeCustomer from "../../../../../../useCase/Customer/Initialization/InitializeCustomer";
import UpdateUseCase from "../../../../../../useCase/Customer/Update/UpdateUseCase";
import CustomerGateway from "../../../../../../gateway/Customer/CustomerGateway";
import {reloadCustomer} from "../../../../../store/component/customer";
import AddUseCase from "../../../../../../useCase/Customer/Add/AddUseCase";
import UndividedDatalist from "../../../Datalist/UndividedDatalist";
import DocumentGateway from "../../../../../../gateway/Customer/Document/DocumentGateway";

interface IFormInput {
  id: string|null
}

type Props = {
  customer?: CustomerInterface
  isLectureMode?: boolean
}

const UndividedForm: FunctionComponent<Props> = ({customer, isLectureMode}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const { customerType } = useParams()
  const navigate = useNavigate()

  const [submitted, setSubmitted] = useState(false);
  const { register, control, handleSubmit, reset, setValue } = useForm<IFormInput>();

  const undividedArray = useAppSelector((state) => state.prospect.undividedArray)

  const [undividedList, setUndividedList] = useState<Undivided[]|[]>(undividedArray)
  const undividedRef = useRef<string|null>(null)

  useEffect(() => {
    setUndividedList(undividedArray)
  }, [undividedArray])

  useEffect(() => {
    return () => {
      dispatch(setOpenDocumentList({
        show: false,
        documentList: [],
        gateway: null,
        openCallback: null
      }))
    };
  }, []);

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    setSubmitted(true);
    reset(data)
    if (undividedList.length == 0) {
      return
    }

    if (undefined === customer) {
      customer = new InitializeCustomer().initializeCustomer()
    }

    if (null === customer.customerType ) {
      customer.customerType = undefined !== customerType ? customerType : 'moral'
    }

    customer.undivideds = undividedList

    if (null !== customer.id) {
      new UpdateUseCase(new CustomerGateway()).execute(customer).then(response => {
        if (null !== response) {
          toastSuccess(t('account.notify.update-success'))
          dispatch(reloadCustomer());
        } else {
          toastError(t('account.notify.update-error'));
        }
        setSubmitted(false);
      })
    }
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));

  const renderFooter = () => {
    if(isLectureMode) return null
    return <footer className={`form-bloc__footer`}>
      <button type="submit" className="button button--ink-2" disabled={submitted}>{t('common.save')}</button>
      <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => {
        navigate(`/${t('url.prospect.dashboard')}`)
      }}>{t('common.cancel')}</button>
    </footer>
  }

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  const uploadCallback = (undividedId: string|null) => {
    if (customer?.id) {
      dispatch(setOpenCustomerUploadForm( {
        show: true,
        documents: null,
        personId: customer.id,
        type: 'UNDIVIDED',
        undividedId: undividedId
      }))
    }
  }

  const openCallback = (fileId: number) => {
    if (customer?.id && undividedRef.current) {
      new DocumentGateway().getUndividedFile(customer?.id, undividedRef.current, fileId).then(response => {
        const file = window.open('')
        if (null !== file && response !== null) {
          file.document.write(
            "<body style='margin:0px;padding:0px;overflow:hidden'><iframe width='100%' height='100%' style='border:0' src='"+response+"'></iframe></body>"
          )
        }
      })
    }
  }

  const viewFilesCallback = (undividedId: string|null) => {
    if (customer?.id && undividedId) {
      undividedRef.current = undividedId
      new DocumentGateway().getUndividedFiles(customer?.id, undividedId).then((response) => {
        if (response && response.length > 0) {
          dispatch(setOpenDocumentList({
            show: true,
            documentList: response,
            gateway: null,
            openCallback: openCallback
          }))
        }
      })
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
        <div className={`form-bloc`}>
          <div className="form-bloc__title">{t('partner.form.undivideds.title')}</div>
          <div className="form-bloc__shortcut">
            <button type="button" className="button-reset u-txt-with-icon" onClick={() => dispatch(setOpenAccountUndividedForm({show: true, undivided: null}))}>
              <img src={iconAdd} alt="" />
              {t('partner.form.undivideds.add')}
            </button>
          </div>
          {undividedList?.length > 0 && customer?.id &&
              <UndividedDatalist indivisaireList={undividedList} uploadCallback={uploadCallback} viewFilesCallback={viewFilesCallback}/>
          }
        </div>
        {renderFooter()}
      </form>
    </>
  )
}

export default UndividedForm
