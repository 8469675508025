import Caller from '../../domain/Api/Caller'
import envVariable from '../../infrastructure/presentation/util/envVariable'
import DashboardGatewayInterface from '../../domain/Dashboard/DashboardGatewayInterface'
import {KpiResponseInterface} from '../../infrastructure/presentation/Type/Kpi'
import {QuoteInterface} from '../../domain/Quote/Quote'
import {QuoteList} from '../../fixtures/Quote'

export default class DashboardGateway implements DashboardGatewayInterface {
  getQuote(): Promise<QuoteInterface> {
    return new Promise<QuoteInterface>((resolve => {
      const min = 0
      const max = QuoteList.length
      const random = Math.floor(Math.random() * (max - min) + min)
      resolve(QuoteList[random])
  }))
  }
  getKpi(): Promise<KpiResponseInterface[]|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dashboards/kpi`, {})
    .then(data => {
      if (data) {
        return data
      }

      return null
    }).catch(response => {
      return null
    })
  }
}
