interface ProductTypeInterface {
  id: string|null
  createdAt: string|null
  updatedAt: string|null
  deletedAt: string|null
  slug: string|null
  label: string|null
  description: string|null
  enabled: boolean
}

class ProductType implements ProductTypeInterface{
  public id: string|null
  public createdAt: string|null
  public updatedAt: string|null
  public deletedAt: string|null
  public slug: string|null
  public label: string|null
  public description: string|null
  public enabled: boolean
  constructor(
    id: string|null,
    createdAt: string|null,
    updatedAt: string|null,
    deletedAt: string|null,
    slug: string|null,
    label: string|null,
    description: string|null,
    enabled: boolean
  ) {
      this.id = id
      this.createdAt = createdAt
      this.updatedAt = updatedAt
      this.deletedAt = deletedAt
      this.slug = slug
      this.label = label
      this.description = description
      this.enabled = enabled
  }
}

export {
  ProductType
}
export type { ProductTypeInterface }
