export function ElementAlreadyInDisabledParent(el: Element, parentEl: NodeListOf<Element>): boolean {
    return Array.from(parentEl).some((parEl) => parEl.contains(el))
}


export function setReadOnlyFormElements(boolean = true, node: HTMLFormElement, idExcluded: string[] = []) {
    const parentDisabled = document.querySelectorAll(".disabledContent");

    const inputElements = Array.from(node).filter(el => el.tagName === "INPUT" && !idExcluded.includes(el.id))
    const buttonElements = Array.from(node).filter(el => el.tagName === "BUTTON" && !idExcluded.includes(el.id))
    const selectElements = Array.from(node).filter(el => el.tagName === "SELECT" && !idExcluded.includes(el.id))
    const textareaElements = Array.from(node).filter(el => el.tagName === "TEXTAREA" && !idExcluded.includes(el.id))

    for (let i = 0; i < inputElements.length; i++) {
        const el = inputElements[i] as HTMLInputElement
        const parentIsDisabled = ElementAlreadyInDisabledParent(el, parentDisabled)
        //console.log('input', inputElements)
        if (el.type === "checkbox") {
            const labels = el.labels
            el.disabled = boolean;
            if (labels && labels[1] && labels[1].className.includes('checkbox-toggle__button')) {
                labels[1].classList.add("checkbox-toggle__readonly")
            }
        }
        if (!parentIsDisabled && !(el.readOnly || el.disabled)) {
            el.classList.add("form-control__readonly")
            el.readOnly = boolean;
        }

        if(el.type === "radio" || el.type === "textarea") {
            el.disabled = boolean;
        }

    }

    for (let i = 0; i < selectElements.length; i++) {
        const selectEl = selectElements[i] as HTMLSelectElement
        selectEl.classList.add("form-control__readonly")
        selectEl.disabled = boolean;

    }

    for (let i = 0; i < buttonElements.length; i++) {
        const btnEl = buttonElements[i] as HTMLButtonElement
        if (btnEl.name !== "read.document" && btnEl.name !== "btn.action" && !btnEl.className.includes('collapse__title')) {
            btnEl.disabled = boolean;
        }
    }

    for (let i = 0; i < textareaElements.length; i++) {
        const textAreaEl = textareaElements[i] as HTMLTextAreaElement
        textAreaEl.disabled = boolean;
        textAreaEl.classList.add("form-control__readonly")

    }
}