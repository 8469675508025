export default class IdCleaner {
  static clean(uuid: string|null|undefined): string|null
  {
    if (uuid === null || uuid === undefined || !uuid) {
      return null
    }

    if (uuid.includes('provisional')) {
      return null
    }

    return uuid
  }
}
