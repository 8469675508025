interface DateRangeInterface {
  start: string
  end: string
}

class DateRange implements DateRangeInterface {

  public start : string
  public end : string

  constructor(
    start : string,
    end : string
  ) {
    this.start = start
    this.end = end
  }
}

export {
  DateRange
}
export type { DateRangeInterface }
