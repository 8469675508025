import {DateRange} from "../Utils/DateRange";

interface NetAssetValueItemInterface {
  id: string
  period: DateRange
  publishedAt: string
  value: number
}

interface CreateNetAssetValueItemInterface {
  id: string
  start: string
  end: string
  publishedAt: string
  value: number
}

class NetAssetValueItem {
  id: string
  period: DateRange
  publishedAt: string
  value: number

  constructor(
    id: string,
    period: DateRange,
    publishedAt: string,
    value: number
  ) {
    this.id = id
    this.period = period
    this.publishedAt = publishedAt
    this.value = value
  }
}

class UpdateNetAssetValueItem {
  id: string
  start: string
  end: string
  publishedAt: string
  value: number

  constructor(
    id: string,
    start: string,
    end: string,
    publishedAt: string,
    value: number
  ) {
    this.id = id
    this.start = start
    this.end = end
    this.publishedAt = publishedAt
    this.value = value
  }
}

export {NetAssetValueItem}
export {UpdateNetAssetValueItem}
export type {NetAssetValueItemInterface}
export type {CreateNetAssetValueItemInterface}
