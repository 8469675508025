import {UsageItem} from './UsageItem'

interface UsageInterface {
  newPart: UsageItem
  transfer: UsageItem
  mutation: UsageItem
}

class Usage implements UsageInterface {

  public newPart: UsageItem
  public transfer: UsageItem
  public mutation: UsageItem

  constructor(
    newPart: UsageItem,
    transfer: UsageItem,
    mutation: UsageItem
  ) {
    this.newPart = newPart
    this.transfer = transfer
    this.mutation = mutation
  }
}

export {
  Usage
}
export type { UsageInterface }
