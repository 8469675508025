import Caller from '../../domain/Api/Caller'
import envVariable from '../../infrastructure/presentation/util/envVariable'


export default class NotePadGateway {
  getNote(uuid: string): Promise<any> {
    return Caller.executeGet(`${ envVariable('REACT_APP_API_URL') }/accounts/${uuid}/notepad`, {})
      .then(data => {
        if(data) {
          return data
        }
      })
      .catch(e => {
        console.error(e)
      })
  }

  postNote(uuid: string, content: string, object: string): Promise<any> {
    return Caller.executePost(`${ envVariable('REACT_APP_API_URL') }/accounts/${uuid}/notepad`, {
      content: content,
      object: object
    })
      .then(data => {
        if(data) {
          return data
        }
      })
      .catch(e => {
        console.error(e)
      })
  }

  deleteNote(uuid: string, uuidNote: string): Promise<any> {
    return Caller.executeDelete(`${ envVariable('REACT_APP_API_URL') }/accounts/${uuid}/notepad/${uuidNote}`, {})
      .then(data => {
        if(data) {
          return data
        }
      })
      .catch(e => {
        console.error(e)
      })
  }
}
