import React, {FunctionComponent} from 'react';
import Slider, {CustomArrowProps} from "react-slick";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props: CustomArrowProps) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

type Props = {
    children: JSX.Element[]
}

const KPICarousel: FunctionComponent<Props> = ({children}) => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SampleNextArrow />,
        arrows: true
    };


    return (
        <Slider  {...settings}>
            {children}
        </Slider>
    );
}

export default KPICarousel;