import {FunctionComponent, useState} from 'react'

import '../../../../assets/styles/components/_bloc-information-shortcut.scss'
import {useTranslation} from "react-i18next";
import Modal from 'react-modal';
import '../../../../assets/styles/components/_modal.scss'
import imgAlert from '../../../../assets/images/icons/alert.svg'


type Props = {
    onConfirm: (data: any) => void
    onContinue: () => void
    onClose: () => void
    message?: string
}

const WarningModal: FunctionComponent<Props> = ({onConfirm, onContinue, onClose, message}) => {

    Modal.setAppElement("#root");

    const {t} = useTranslation();

    const styles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <>
            <div className="modal">
                <Modal
                    isOpen={true}
                    style={styles}
                >
                    <button type="button"
                            className="content__close"
                            aria-label={t('common.modal.warning.close')}
                            onClick={onClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="content">
                        <div className="content__image">
                            <img src={imgAlert} alt="" />
                        </div>


                        <p className="content__text">
                            { message ? (message) : (
                                t('common.modal.warning.message1')
                            )}
                        </p>
                        <p className="content__text">
                            { message ? (message) : (
                                t('common.modal.warning.message2')
                            )}
                        </p>
                        <div className="content__buttons">
                        <button type="button"
                                className="button button--ink-2 u-mrm"
                                onClick={(data: any) => {
                                    onConfirm(data);
                                    setTimeout(() => {
                                        onClose();
                                    }, 500)
                                }}>
                            {t('common.modal.warning.save')}
                        </button>
                        <button type="button"
                                className="button button--ink-2 button--ink-2--outline"
                                onClick={() => {
                                    onContinue();
                                    onClose();
                                }}>
                            {t('common.modal.warning.continue')}
                        </button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default WarningModal
