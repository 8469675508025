import {BankInformationInterface as AccountBankInformation} from "../Prospect/BankInformation";

interface BankInformationByProspectFormInterface {
  [key: string] : {
    id: string|null,
    bankInformationSelected: string|null,
    bankInformation: {
      id: string|null,
      label: string|null,
      bank: string|null,
      iban: string|null,
      swift: string|null,
    }
    directDebitMandateAccepted: boolean|null
    rumCode: string|null
    rumStatus: string|null
  }
}

interface BankInformationFormInterface {
  [key: string] : BankInformationByProspectFormInterface
}

interface BankInformationInterface {
  directDebitMandateAccepted: boolean
  rumCode: string|null
  rumStatus: string|null
  type: string|null
  bankInformation: AccountBankInformation|null
}

class BankInformation implements BankInformationInterface{
  id: string|null
  directDebitMandateAccepted: boolean
  rumCode: string|null
  rumStatus: string|null
  type: string|null
  bankInformation: AccountBankInformation|null

  constructor(
    id: string|null,
    directDebitMandateAccepted: boolean,
    rumCode: string|null,
    rumStatus: string|null,
    type: string|null,
    bankInformation: AccountBankInformation|null
  ) {
    this.id = id
    this.directDebitMandateAccepted = directDebitMandateAccepted
    this.rumCode = rumCode
    this.rumStatus = rumStatus
    this.type = type
    this.bankInformation = bankInformation
  }
}

export {
  BankInformation
}

export type {BankInformationInterface, BankInformationFormInterface, BankInformationByProspectFormInterface}
