import ProductBankInformationListPresenter from "../../../../../presenter/Product/BankInformationListPresenter";

export {}

import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

import '../../../../../assets/styles/components/_datalist.scss'
import iconEdit from '../../../../../assets/images/icons/datalist-edit.svg'
import eyeIcon from "../../../../../assets/images/icons/eye.svg";
import uploadIcon from "../../../../../assets/images/icons/upload.svg";
import {ProductBankInformationInterface} from "../../../../../domain/Product/BankInformation";
import {useAppDispatch} from "../../../../store/hook";
import {setOpenProductBankInformation} from "../../../../store/component/event";
import {SortInterface, SortOrder} from "../../../../../domain/Utils/List";
import TableHead from "../../Table/TableHead";

type Props = {
  bankInformation: ProductBankInformationInterface[],
  personId: string | null,
  isLectureMode?: boolean,
  uploadCallback: (show: boolean, documents: any[]|null, personId: string|null, type: string|null, ribId: string|null) => void,
  viewFilesCallback: (id: string|null) => void
}

const ProductBankInformationDatalist: FunctionComponent<Props> = ({ bankInformation, personId, isLectureMode, uploadCallback, viewFilesCallback, ...rest}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const controller = new AbortController()

  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})


  useEffect(() => {
    const presenter = new ProductBankInformationListPresenter(bankInformation);
    setViewModel(presenter.immutableViewModel())
  }, [bankInformation])

  return (<>
      {(viewModel !== null &&
        <>
          <div className={`datalist`}>
            <div className="datalist__title">{t(viewModel.title)}</div>
            <div className="datalist__header">
            </div>
            <table className="datalist__datas">
              <thead>
              {viewModel.heading && <TableHead typeFilter={"FRONT"}
                                               heading={viewModel.heading}
                                               sortOrder={sortOrder}
                                               setSortOrder={setSortOrder}
                                               viewModel={viewModel}
                                               setViewModel={setViewModel}/>}
              </thead>
              <tbody>
                {viewModel.data !== undefined &&
                viewModel.data.map((item: ProductBankInformationInterface) => (
                  <tr key={uuidV4()}>
                    <td>
                      <button
                        type="button"
                        className="button-reset"
                        onClick={() => {
                          dispatch(setOpenProductBankInformation({show: true, bankInformation: item}))
                        }}
                      >
                        <img src={iconEdit} alt="" />
                      </button>
                      <button type="button" className="button-reset u-mxs"
                              onClick={() => viewFilesCallback(item.id)}
                      >
                        <img src={eyeIcon} alt=""/>
                      </button>
                      <button type="button" className="button-reset u-mxs"
                              onClick={() => uploadCallback(true, null, personId, 'RIB', item.id)}
                      >
                        <img src={uploadIcon} alt=""/>
                      </button>
                    </td>
                    <td>
                      {item.type}
                    </td>
                    <td>{item.title}</td>
                    <td>{item.bank}</td>
                    <td>{item.iban}</td>
                    <td>{item.swift}</td>
                  </tr>
                ))}
                {viewModel.data === undefined || viewModel.data.length === 0 &&
                  <tr>
                    <td colSpan={6}>{t('common.data-is-empty')}</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  )
}

export default ProductBankInformationDatalist
