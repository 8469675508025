import {Presenter} from '../Presenter'
import {MovementListInterface} from '../../domain/Movement/MovementList'

class PortfolioListPresenter extends Presenter {

    private useCase: {
        getMovements: { data: MovementListInterface[] | null, nb_element: number }
    }

    constructor(getMovements: any) {
        super({
            viewModel: {
                title: 'portfolio.search-a-movement',
                heading: [
                    'portfolio.heading.action',
                    'portfolio.heading.acronym',
                    'portfolio.heading.id_movement',
                    'portfolio.heading.esn_type',
                    'portfolio.heading.nature_movement',
                    'portfolio.heading.nature_detention',
                    'portfolio.heading.type',
                    'portfolio.heading.transaction_created_at',
                    'portfolio.heading.date_movement',
                    'portfolio.heading.quantity',
                    'portfolio.heading.no_part_start',
                    'portfolio.heading.no_part_end',
                    'portfolio.heading.date_use',
                    'portfolio.heading.date_first_payment',
                    'portfolio.heading.price',
                    'portfolio.heading.amount',
                    'portfolio.heading.id_invest',
                    'portfolio.heading.name_invest',
                    'portfolio.heading.id_usu',
                    'portfolio.heading.rate_dismemberment',
                    'portfolio.heading.end_date_dismemberment',
                    'portfolio.heading.transfer_date',
                    'portfolio.heading.transfer_id',
                    'portfolio.heading.transfer_partner_social_reason',
                    'portfolio.heading.transfer_target_social_reason',
                ],
                datas: [],
                filtersShortcut: [
                    {'keyword': 'filters.by-product', 'field': 'product', 'type': 'input'},
                    {'keyword': 'filters.by-type', 'field': 'transaction_type', 'type': 'input'},
                    {'keyword': 'filters.by-keyword-or-id', 'field': 'keywords', 'type': 'input'},
                    {'keyword': 'filters.by-id-investor', 'field': 'investor', 'type': 'input'},
                    {'keyword': 'filters.by-product', 'field': 'property_type', 'type': 'input'},
                    {'keyword': 'filters.by-date-start', 'field': 'start', 'type': 'input'},
                    {'keyword': 'filters.by-date-end', 'field': 'end', 'type': 'input'}
                ],
                filters: [
                ],
                pagination: {
                    'itemsPerPage': 50,
                    'numberOfItems': 0,
                },
                count: 0
            }
        });

        this.useCase = {
            getMovements,
        }
    }

    async load() {
        try {
            this._setMovements(await this.useCase.getMovements);
        } catch (e) {
            //Do nothing for the moment
        }
    }

    _setMovements(movements: { data: MovementListInterface[]|null, nb_element: number }) {
        this.update({
            data: movements.data,
            pagination: {
                'itemsPerPage': 50,
                'numberOfItems': movements.nb_element,
            }
        });
    }
}

export default PortfolioListPresenter
