import CustomerGatewayInterface from '../../../domain/Customer/CustomerGatewayInterface'
import {CustomerInterface} from '../../../domain/Customer/Customer'

export default class AddUseCase
{
  public customerGateway

  constructor(CustomerGateway: CustomerGatewayInterface) {
    this.customerGateway = CustomerGateway
  }

  async execute(Customer: CustomerInterface): Promise<CustomerInterface|null> {
    return await this.customerGateway.add(Customer).then(response => {
      if (response) {
        return response
      }

      return null
    })
  }
}
