import {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import {Doughnut} from 'react-chartjs-2'
ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
  viewModel: {
    title: string
    data: any
    heightCanvas: number
    options: any
  }
}

const ChartDoughnutWrapper: FunctionComponent<Props> = ({viewModel}) => {
  const {t} = useTranslation()

  return (<>
      {viewModel !== null && viewModel.data &&
        <>
          <div className='title'>{t(viewModel.title)}</div>
          <div className="container-canvas">
            <Doughnut data={viewModel.data} options={viewModel.options} />
          </div>
        </>
      }
    </>
  )
}

export default ChartDoughnutWrapper
