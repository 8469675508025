import envVariable from "../presentation/util/envVariable";
import Authentication from "./Authentication";
import {FunctionComponent} from "react";

const Logout: FunctionComponent = () => {
  Authentication.logout().then(
    function success() {
      window.location.replace(`${envVariable('REACT_APP_OAUTH_LOGOUT_URL')}`);
    }
  )
  return (
    <></>
  );
}

export default Logout
