import MovementGatewayInterface from '../../../domain/Movement/MovementGatewayInterface';
import { ListRequestInterface } from './ListRequest';
import { InvestmentListInterface } from '../../../domain/Movement/InvestmentList';

export default class InvestmentListUseCase
{
  public productGateway

  constructor(MovementGateway: MovementGatewayInterface) {
    this.productGateway = MovementGateway
  }

  async execute(ListRequest: ListRequestInterface, signal?: AbortSignal): Promise<{data: InvestmentListInterface[]|null, numberOfItems: number}|null> {
    return await this.productGateway.getInvestmentList(ListRequest.currentPage, ListRequest.itemPerPage, ListRequest.filter, signal).then(response => response)
  }
}
