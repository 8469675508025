import React, {FunctionComponent, useCallback, useEffect} from "react";
import {CustomerInterface} from "../../../../../../domain/Customer/Customer";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../../../store/hook";
import {useNavigate, useParams} from "react-router-dom";
import {NotaryInterface} from "../../../../../../domain/Customer/Notary";
import {SubmitHandler, useForm, useFormState} from "react-hook-form";
import InitializeCustomer
  from "../../../../../../useCase/Customer/Initialization/InitializeCustomer";
import UpdateUseCase from "../../../../../../useCase/Customer/Update/UpdateUseCase";
import CustomerGateway from "../../../../../../gateway/Customer/CustomerGateway";
import AddUseCase from "../../../../../../useCase/Customer/Add/AddUseCase";
import {ReferentielInterface} from "../../../../../../domain/Referentiel/ReferentielInterface";
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {usePrompt} from "../../../../util/Navigation";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import InputDate from "../../../Elements/InputDate";
import CheckboxToggle from "../../../Elements/CheckboxToggle";

interface IFormInput {
  notary: NotaryInterface|null
}

type Props = {
  referential: ReferentielInterface
  customer?: CustomerInterface
  isLectureMode?: boolean
}
const Notary: FunctionComponent<Props> = ({customer, isLectureMode, referential}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { customerType } = useParams()
  const { register, control, watch, handleSubmit, reset, setValue, getValues } = useForm<IFormInput>();

  useEffect(() => {
    if (customer && customer.notary) {
      setValue('notary', customer.notary)
    }
  }, [customer])

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    reset(data)
    if (undefined === customer) {
      customer = new InitializeCustomer().initializeCustomer()
    }

    customer.notary = data.notary

    if (null !== customer.id) {
      new UpdateUseCase(new CustomerGateway()).execute(customer).then(response => {
        if (null !== response) {
          toastSuccess(t('account.notify.update-success'));
        } else {
          toastError(t('account.notify.update-error'));
        }
      })
    }
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));


  const notaryBlock = <div className={`form-bloc form--bloc--general-information`}>
    <div className="form-bloc__title">{t('account.form.general-information.title')}</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-12">
        <div className="flex-container">
          <div className="col-md-3">
            <div className="form-control">
              <label htmlFor="deceasedAt" className="form-control__label">{t('account.form.notary.deceasedAt')}</label>
              <div className="form-control__input">
                <InputDate id="deceasedAt"
                           name="notary.deceasedAt"
                           register={register}
                           control={control}
                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-control">
              <label htmlFor="blockedAt" className="form-control__label">{t('account.form.notary.blockedAt')}</label>
              <div className="form-control__input">
                <InputDate id="blockedAt"
                           name="notary.blockedAt"
                           register={register}
                           control={control}
                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-control">
              <label htmlFor="start" className="form-control__label">{t('account.form.notary.start')}</label>
              <div className="form-control__input">
                <InputDate id="start"
                           name="notary.successionPeriod.start"
                           register={register}
                           control={control}
                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-control">
              <label htmlFor="end" className="form-control__label">{t('account.form.notary.end')}</label>
              <div className="form-control__input">
                <InputDate id="end"
                           name="notary.successionPeriod.end"
                           register={register}
                           control={control}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-control">
              <label htmlFor="personFirstMaidenname" className="form-control__label">{t('account.form.notary.matrimonial_regime')}</label>
              <div className="form-control__input">
                <select
                  value={customer && customer.persons && customer.persons[0] && customer.persons[0].matrimonialRegime ? customer.persons[0].matrimonialRegime.toString() : ""} disabled
                >
                  <option value="" />
                  {referential.global.matrimonial_regime.map((item, index) => <option key={index}
                                                                                            value={item.value}>{item.label}</option>)}
                </select>
              </div>
            </div>
          </div>
          <CheckboxToggle classes="col-md-6" id="notary.quasiUsufruit" name="notary.quasiUsufruit" register={register} label={t('account.form.notary.quasi_usufruct')} />
        </div>
      </div>
    </div>
  </div>

  const renderFooter = () => {
    if(isLectureMode) return null
    return <footer className={`form-bloc__footer`}>
      <button type="submit" className="button button--ink-2">{t('common.save')}</button>
      <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => {
        navigate(`/${t('url.customer.dashboard')}`)
      }}>{t('common.cancel')}</button>
    </footer>
  }

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
        <div className="flex-container">
          <div className="col-md-12">{notaryBlock}</div>
        </div>
        {renderFooter()}
      </form>
    </div>
  )
}

export default Notary
