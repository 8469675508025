import {BankInformation, BankInformationByProspectFormInterface} from "../../../domain/Movement/BankInformation";
import {BankInformation as AccountBankInformation} from "../../../domain/Prospect/BankInformation";

export default class BankInformationUtils {
  getBankInformationsForProspect(data: BankInformationByProspectFormInterface): BankInformation[] {
    if (!data) {
      return []
    }

    const bankInformations: BankInformation[] = [];
    Object.entries(data).map((bankInformationByType) => {
      bankInformations.push(
        new BankInformation(
          bankInformationByType[1].id,
          bankInformationByType[1].directDebitMandateAccepted ?? false,
          bankInformationByType[1].rumCode,
          bankInformationByType[1].rumStatus,
          bankInformationByType[0],
          new AccountBankInformation(
            bankInformationByType[1].bankInformation?.id ?? null,
            null,
            null,
            null,
            bankInformationByType[1].bankInformation?.label ?? null,
            null,
            bankInformationByType[1].bankInformation?.bank ?? null,
            bankInformationByType[1].bankInformation?.iban ?? null,
            bankInformationByType[1].bankInformation?.swift ?? null,
            null,
            null,
            null,
            null,
            null,
            null,
            null
          )
        )
      )
    })

    return bankInformations
  }
}
