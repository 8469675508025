import {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'
import {useAppSelector} from '../../../../store/hook'
import HeaderPage from '../../../component/HeaderPage/HeaderPage'
import imgChoiceProspect from '../../../../../assets/images/icons/choice-prospect.svg'
import {ReferentielInterface} from "../../../../../domain/Referentiel/ReferentielInterface";
import '../../../../../assets/styles/page/_commissioning.scss'
import {Link} from "react-router-dom";
import envVariable from "../../../util/envVariable";

const CommissioningChoiceType: FunctionComponent = () => {
  const {t} = useTranslation()

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const isLinksActivated = ['dev', 'demo'].includes(envVariable('REACT_APP_ENV'))


  return (
    <>
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''} main-content--sidebar-info`}>
        <div className="sidebar-info__main">
          <HeaderPage title={t('commissioning.add.title')}/>
          <div className="commissioning-choice__wrapper">
            <p>{t('commissioning.add.select-modele')}</p>
            <div className="commissioning-choice--col-2">
              {referential && referential.commission.session.map((session) => {
                let to: string
                let className: string
                if (isLinksActivated || session.value === "up_front") {
                  to=`/${t('url.commissioning.add-setting')}/${session.value}`
                  className="button button--choice button--choice--outline"
                } else {
                  to=""
                  className="button button--choice button--choice--deactivated"
                }
                return (
                  <Link key={session.value}
                        to={to}
                        className={className}
                  >
                    {session.label}
                  </Link>
                )
              })}
            </div>
          </div>
          {/* <div className="commissioning-choice__wrapper">
            <p>{t('commissioning.add.select-type')}</p>
            <div className="commissioning-choice--col-3">
              {referential && referential.commission.type.map((type) => {
                let to: string
                let className: string
                if (isLinksActivated) {
                  to = `/${t('url.prospect.add-general-information')}/${type.value}`
                  className = "button button--choice button--choice--outline"
                } else {
                  to = ""
                  className = "button button--choice button--choice--deactivated"
                }
                return (
                  <Link key={type.value}
                        to={to}
                        className={className}
                  >
                    {type.label}
                  </Link>
                )
              })}
            </div>
          </div> */}
        </div>
        <div className="sidebar-info">
          <div className="sidebar-info__text">
            {t('commissioning.add.choice-type-select')}
          </div>
          <div>
            <img src={imgChoiceProspect} alt=""/>
          </div>
        </div>
      </main>
    </>
  )
}

export default CommissioningChoiceType
