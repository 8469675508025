import {FunctionComponent} from 'react'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import '../../../../../assets/styles/components/_tab.scss'

const TabCustomer: FunctionComponent = () => {
  const {t} = useTranslation()

  return (
    <div className={`tab tab--prospect`}>
      <NavLink
        end to={`/${t('url.customer.dashboard')}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.tab.dashboard')}
      </NavLink>
      <NavLink
        to={`/${t('url.customer.dashboard-pledge-organization')}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('account.tab.dashboard-pledge-organization')}
      </NavLink>
    </div>
  )
}

export default TabCustomer
