import {toast} from 'react-toastify'
import {FunctionComponent} from "react";
import '../../../assets/styles/components/_modal.scss'
import imgValidation from '../../../assets/images/icons/validation.svg'
import imgAlert from '../../../assets/images/icons/alert.svg'
import {ToastOptions} from "react-toastify";
import {useTranslation} from "react-i18next";

interface Props {
    message?: string
    type?: 'success' | 'error' | 'warning'
    specificError?: string
}

const getImage = (type: string) => type === 'success'? imgValidation : imgAlert;

const Toast: FunctionComponent<Props> = ({message, type, specificError}) => {

    const {t} = useTranslation();

    return (
        <>
            <div className="content">
                {
                    type ? (
                        <div className="content__image">
                            <img src={getImage(type)} alt=""/>
                        </div>
                    ) : null
                }
                <p className={`content__text ${specificError ? "" : "u-pbb"}`}>
                {
                    message ? (message) : (
                        t('common.modal.validation-message')
                    )
                }
                </p>

                {specificError && <span className="content__specific-text">
                    {specificError}
                </span>}
            </div>
        </>
    );
}

export const toastOptions: ToastOptions = {
    position       : 'top-right',
    autoClose      : 5000,
    hideProgressBar: false,
    closeOnClick   : true,
    pauseOnHover   : true,
    draggable      : true,
    icon           : false,
}

export const toastSuccess = (message?: string) => {
    toast.success(<Toast message={message} type="success"/>, toastOptions);
}

export const toastError = (message?: string, specificError?: string) => {
    toast.error(<Toast message={message} specificError={specificError} type="error"/>, toastOptions);
}

export const toastWarning = (message?: string) => {
    toast.warning(<Toast message={message} type="error"/>, toastOptions);
}
