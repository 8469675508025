import {FunctionComponent, useState} from 'react'
import {useTranslation} from 'react-i18next'

type Props = {
  numberOfActivatedFilters: number
  handleClickFilter: any
  handleClickExport: () => void
  isLoadingExport: boolean
  allowExport?: boolean
  hideFilter?: boolean
}

const HeaderRight: FunctionComponent<Props> = ({numberOfActivatedFilters, handleClickFilter, handleClickExport, isLoadingExport, allowExport, hideFilter}) => {
  const {t} = useTranslation()

  return <>
    <div className="header__right">
      {!hideFilter &&
        <button type="button" className="button button--white header__right--filter" onClick={() => handleClickFilter(true)}>
          <span className="header__right--filter__icon">
            <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M2.78651 3.80947H9.62454V5.41119H2.78651V3.80947ZM0.735107 0.606018H11.6759V2.20774H0.735107V0.606018ZM4.83792 7.01292H7.57313V8.61464H4.83792V7.01292Z"
                  fill="#1D1D1D" />
            </svg>
          </span>
          <span className="header__right--filter__text">{t('common.filters')}</span>
          {/* <span className="header__right--filter__count">{numberOfActivatedFilters}</span> */}
        </button>
      }

      {isLoadingExport && <div className="loader"><div className="loader__dot"></div></div>}
      {(!isLoadingExport && allowExport) &&
        <button type="button" className="button button--white" onClick={() => handleClickExport()}>{t('common.export-xls')}</button>
      }

    </div>
  </>
}

export default HeaderRight
