import React, {FunctionComponent, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../store/hook";
import getClassForOverlay from "../../../util/Sidebar";
import {setOpenProductScaleForm} from "../../../../store/component/event";
import {Scale} from "../../../../../domain/Product/Scale";
import {ReferentielInterface} from "../../../../../domain/Referentiel/ReferentielInterface";
import ScaleForm from "./Scale/ScaleForm";

interface ScaleSidebar {
  scale: Scale
}

type Props = {
  referential: ReferentielInterface,
  isLectureMode: boolean
}
const ScaleSidebar: FunctionComponent<Props> = ({referential, isLectureMode}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const openScaleForm = useAppSelector((state) => state.event.openProductScaleForm)

  function handleClose() {
    dispatch(setOpenProductScaleForm({show: false, scale: null}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openScaleForm.show)}`} onClick={() => handleClose()}/>
      <div className={`sidebar sidebar--right sidebar--right ${openScaleForm.show ? 'sidebar--active' : ''}`}>
        {openScaleForm.productId &&
          <ScaleForm
            productId={openScaleForm.productId}
            scale={openScaleForm.scale}
            referential={referential}
            isLectureMode={isLectureMode}
          />
        }
      </div>
    </>
  )
}

export default ScaleSidebar
