import {ProspectInterface} from "../../../domain/Prospect/Prospect";

export default class ProspectUtils {
  formatProspectPersonIdentity(prospect: ProspectInterface): string  {
    let result = '';
    if (prospect.persons && prospect.prospectType !== 'moral') {
      prospect.persons.forEach( person => {
        if ( person.firstname || person.lastname) {
          if (result !== '') {
            result += ' / '
          }
          result += person.firstname + ' ' + person.lastname
        }
      })
      if (result === '') {
        result = 'Pas de personne renseignée'
      }
    } else {
      result = prospect.companyName ?? '';
    }
    return result
  }

  static formatProspectPersonIdentity(prospect: ProspectInterface): string  {
    let result = '';
    if (prospect.persons && prospect.prospectType !== 'moral') {
      prospect.persons.forEach( person => {
        if ( person.firstname || person.lastname) {
          if (result !== '') {
            result += ' / '
          }
          result += person.firstname + ' ' + person.lastname
        }
      })
      if (result === '') {
        result = 'Pas de personne renseignée'
      }
    } else {
      result = prospect.companyName ?? '';
    }
    return result
  }
}
