import React, {FunctionComponent, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm, useFormState} from 'react-hook-form'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {setOpenProductBankInformation} from '../../../../store/component/event'
import {addBankInformation, updateBankInformation} from '../../../../store/component/product'

import {
  ProductBankInformation,
  ProductBankInformationInterface
} from '../../../../../domain/Product/BankInformation'

import blockScrollBody from '../../../util/BlockScroll'
import getClassForOverlay from '../../../util/Sidebar'
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import {toastSuccess} from "../../../util/Toast";
import {usePrompt} from "../../../util/Navigation";
import Iban from "../../Elements/Iban";
import Bic from "../../Elements/Bic";

interface IFormInput {
  bankInformation: ProductBankInformationInterface
}

const AddBankInformationForm: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const referential = useAppSelector(({referential}) => referential.referential)
  const openBankInformationForm = useAppSelector((state) => state.event.openProductBankInformationForm)
  const bankInformationArray = useAppSelector((state) => state.product.bankInformationArray)

  useEffect(() => {
    blockScrollBody(openBankInformationForm.show)

    if (openBankInformationForm.bankInformation) {
      console.log(openBankInformationForm.bankInformation.type)
      setValue('bankInformation.id', openBankInformationForm.bankInformation.id)
      setValue('bankInformation.createdAt', openBankInformationForm.bankInformation.createdAt)
      setValue('bankInformation.updatedAt', openBankInformationForm.bankInformation.updatedAt)
      setValue('bankInformation.deletedAt', openBankInformationForm.bankInformation.deletedAt)
      setValue('bankInformation.title', openBankInformationForm.bankInformation.title)
      setValue('bankInformation.bank', openBankInformationForm.bankInformation.bank)
      setValue('bankInformation.iban', openBankInformationForm.bankInformation.iban)
      setValue('bankInformation.swift', openBankInformationForm.bankInformation.swift)
    } else {
      setValue('bankInformation.id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
    }

  }, [openBankInformationForm])

  const { register, control, handleSubmit, reset, setValue, setError, formState: { errors, isValid}, clearErrors} = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = data => {
    if (!isValid) {
      return
    }
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {

    const bankInformation = new ProductBankInformation(
      data.bankInformation.id,
      null,
      null,
      null,
      data.bankInformation.type,
      data.bankInformation.title,
      data.bankInformation.bank,
      data.bankInformation.iban,
      data.bankInformation.swift
    )

    if (openBankInformationForm.bankInformation) {
      dispatch(updateBankInformation(bankInformation))
    } else {
      dispatch(addBankInformation(bankInformation))
    }
    dispatch(setOpenProductBankInformation({show: false, bankInformation: null}))

    reset()
    setValue('bankInformation.id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
    toastSuccess(t('account.notify.add-bankInformation-success'))
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));


  function handleClose() {
    dispatch(setOpenProductBankInformation({show: false, bankInformation: null}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openBankInformationForm.show)}`} onClick={() => handleClose()}/>
      <div className={`sidebar sidebar--right sidebar--right ${openBankInformationForm.show ? 'sidebar--active' : ''}`}>
        <form onSubmit={handleSubmit(onSubmit)} className="form-bloc form-bloc--prospect-bankInformation-form">
          <div className="sidebar__content">
            <div className="title">{t('account.form.bank-details.add')}</div>
            <div className="col-md-6">
              <div className="flex-container">
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="type" className="form-control__label">{t('account.form.bank-details.type')}</label>
                    <div className="form-control__input">
                      <select id="type" {...register('bankInformation.type')} >
                        <option value="">{t('common.choice-option')}</option>
                        {referential &&
                          referential.product.rib_type.map((item, index) => <option key={index} value={item.value} selected={openBankInformationForm.bankInformation?.type === item.value}>{item.label}</option>)
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="title" className="form-control__label">{t('account.form.bank-details.label')}</label>
                    <div className="form-control__input">
                      <input type="text" id="title" {...register('bankInformation.title')} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="bank" className="form-control__label">{t('account.form.bank-details.bank')}</label>
                    <div className="form-control__input">
                      <input type="text" id="bank" {...register('bankInformation.bank')} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <Iban
                    label={t('account.form.bank-details.iban')}
                    id={'iban'}
                    required={false}
                    name={'bankInformation.iban'}
                    error={errors?.bankInformation?.iban}
                    register={register}
                    enableValidation={true}
                  />
                </div>
                <div className="col-md-12">
                  <Bic
                    label={t('account.form.bank-details.swift')}
                    id={'swift'}
                    required={false}
                    name={'bankInformation.swift'}
                    error={errors?.bankInformation?.swift}
                    register={register}
                    enableValidation={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <footer className="sidebar__footer">
            <button type="submit" className="button button--ink-2 u-mrm">{t('common.save')}</button>
            <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => handleClose()}>{t('common.cancel')}</button>
          </footer>
        </form>
      </div>
    </>
  );
}

export default AddBankInformationForm
