import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm} from 'react-hook-form'
import {v4 as uuidV4} from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../store/hook'
import {setOpenDatalistFilterPartner} from '../../../store/component/event'
import {setCountTotal} from '../../../store/component/partner'
import {FilterPartnerInterface, PartnerListInterface} from '../../../../domain/Partner/PartnerList'
import PartnerListPresenter from '../../../../presenter/Partner/PartnerListPresenter'
import PartnerGateway from '../../../../gateway/Partner/PartnerGateway'
import Pagination from '../Pagination/Pagination'

import '../../../../assets/styles/components/_datalist.scss'
import {getIconForStatus} from '../../util/getState'
import iconAdd from "../../../../assets/images/icons/add.svg";
import iconSearch from "../../../../assets/images/icons/datalist-search.svg";
import {optionsNbRows} from "../../../../fixtures/Referentiel";
import SelectCustom from "../Elements/Select";
import {saveNbRowsInLocalStorage} from "../../util/SavePreferencesInLocalStorage";
import HeaderRight from "./Element/HeaderRight";
import download from "../../util/Download";
import FilterForm from "../Form/Partner/filterForm/FilterForm";
import {City} from "../../../../domain/Referentiel/City/City";
import {ReferentielItemInterface} from "../../../../domain/Referentiel/ReferentielItemInterface";
import { Link } from 'react-router-dom';
import {CallerType} from "../../../../domain/Caller/Caller";

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}


type Props = {
  callerType: CallerType
  callback: (partner: PartnerListInterface) => void
}

interface PartnerDataListInterface {
  numberRows: number|null
  idPartner: string|null
  keywords: string|null
  socialReason: string|null
  name: string|null
  city: City|null
  product: {
    id: string|null,
    label: string|null,
    value: string|null
  }|null
  status: ReferentielItemInterface[]
  type: ReferentielItemInterface[]
  platform: ReferentielItemInterface[]
  network: ReferentielItemInterface[]
}

const PartnerDatalist: FunctionComponent<Props> = ({callback, callerType}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openDatalistFilterPartner = useAppSelector((state) => state.event.openDatalistFilterPartner)
  const partnerRules = useAppSelector((state) => state.me.me?.rules.partner.actions)
  const openChoicePartner = useAppSelector((state) => state.event.openChoicePartner)
  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)
  const [isFilterOpen, setFilterOpen] = useState<boolean>(false)
  const [partners, setPartners] = useState<{data: PartnerListInterface[]|null, numberOfItems: number}|null>(null)
  const controller = new AbortController()
  const preferences = localStorage.getItem("preferences")

  const { register, control, handleSubmit, setValue, watch, getValues, reset } = useForm<PartnerDataListInterface>()

  const watchNumberRows = watch('numberRows')


  useEffect(() => {
    const presenter = new PartnerListPresenter(partners);
    presenter.load().then(() => {
      setViewModel(presenter.immutableViewModel())
      dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems))
    })
  }, [partners])

  useEffect(()=> {
    if(openChoicePartner.show) setValue("numberRows", null)
  },[openChoicePartner])

  useEffect(() => {
    if(!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows.toString())
      const filters = {...openDatalistFilterPartner.filters, status: undefined}
      new PartnerGateway().getList(callerType,currentPage, watchNumberRows || 50, filters, controller.signal).then(response => {
        setPartners(response)
      })
      setValue('idPartner', openDatalistFilterPartner.filters.idPartner)
      setValue('keywords', openDatalistFilterPartner.filters.keywords)
      setValue('socialReason', openDatalistFilterPartner.filters.socialReason)
      setValue('name', openDatalistFilterPartner.filters.name)
      setValue('city', openDatalistFilterPartner.filters.city)
      setValue('product', openDatalistFilterPartner.filters.product)
      setValue('type', openDatalistFilterPartner.filters.type)
      setValue('platform', openDatalistFilterPartner.filters.platform)
      setValue('network', openDatalistFilterPartner.filters.network)
    }
  }, [currentPage, openDatalistFilterPartner.filters, watchNumberRows])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterPartner.filters])

  const onSubmit: SubmitHandler<FilterPartnerInterface> = data => {
    reset(data)
    dispatch(setOpenDatalistFilterPartner({show: false, count: openDatalistFilterPartner.count, filters: {
        idPartner: data.idPartner,
        keywords: data.keywords,
        socialReason: data.socialReason,
        name: data.name,
        city: data.city,
        product: data.product,
        status: data.status,
        type: data.type,
        platform: data.platform,
        network: data.network
      }}))
  }

  const paginate = (pageNumber:number) => {controller.abort(); setCurrentPage(pageNumber)}

  const handleClickFilter = (response: string) => {
    if (response) {
      setFilterOpen(!isFilterOpen)
    }
  }

  const resetFilters = () => {
    dispatch(setOpenDatalistFilterPartner({show: false, count: openDatalistFilterPartner.count, filters: {
        idPartner: '',
        keywords: '',
        socialReason: '',
        name: '',
        city: '',
        product:  {
          id: '',
          value: '',
          label: ''
        },
        status: [],
        type: [],
        platform: [],
        network: []
      }}))
  }

  const handleClickExport = () => {
    setLoadingExport(true)
    new PartnerGateway().getExport(openDatalistFilterPartner.filters).then(response => {
      if (response) {
        download(t('export.partners'), response)
        setLoadingExport(false)
      }
    })
  }

  return (<>
      {(viewModel !== null &&
        <>
          <div className={`datalist`}>
            <div className="horizontal-container-space-between">
              <div className="datalist__title">{t(viewModel.title)}</div>
              <div className="datalist__header">
                <HeaderRight numberOfActivatedFilters={openDatalistFilterPartner.count}
                             handleClickFilter={handleClickFilter}
                             handleClickExport={handleClickExport}
                             isLoadingExport={isLoadingExport}
                             allowExport={partnerRules?.export}

                />
              </div>
            </div>
            <div className={isFilterOpen ? 'vertical-expending vertical-expending--active' : 'vertical-expending'}>
              {control && <FilterForm register={register}
                                      control={control}
                                      getValues={getValues}
                                      setValue={setValue}
                                      handleSubmit={handleSubmit}
                                      onSubmit={onSubmit}
                                      handleClose={ () => {
                                        resetFilters();
                                        setFilterOpen(false)
                                      }}
              />}
            </div>
            <SelectCustom classes="flex justify-end u-mbs" id="numberRowsPartner"
                          name="numberRows"
                          label={t('filters.display-results-by')}
                          options={optionsNbRows}
                          register={register}
                          noChoiceOption
            />
            <div className="table-fix-head">
            <table className="datalist__datas">
              <thead>
              <tr>
                {viewModel.heading.map((heading: { name: string, sort: string }) => (
                  <th key={uuidV4()}>{t(heading.name)}</th>
                ))}
              </tr>
              </thead>
              <tbody>
              {viewModel.data !== undefined &&
              viewModel.data.map((item: PartnerListInterface) => (
                <tr key={uuidV4()}>
                  <td>
                    <button type="button" className="button-reset" onClick={() => callback(item)}>
                      <img src={iconAdd} alt="ajouter" />
                    </button>
                    <Link  to={ `/${ t('url.partner.read-general-information') }/${ item.id }` }
                           target="_blank"
                           rel="noopener noreferrer"
                    >
                      <button type="button" className="button-reset">
                        <img src={iconSearch} alt="voir"/>
                      </button>
                    </Link>
                  </td>
                  <td>{item.partnerId}</td>
                  <td>{item.cabinetName}</td>
                  <td>{item.contactName}</td>
                  <td>{item.internalCommercialName}</td>
                  <td>{item.product}</td>
                  <td>{item.city}</td>
                  <td>{`${t("partner.form.commission.level", {
                    level: item?.commissionLevel?.replace("niveau_","") || "1"
                  })}`}</td>
                  <td>{getIconForStatus(!!item.status)}</td>
                </tr>
              ))}
              {viewModel.data === undefined || viewModel.data.length === 0 &&
              <tr>
                <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
              </tr>
              }
              </tbody>
            </table>
            </div>
          </div>
          <Pagination currentPage={currentPage} itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage} numberOfItems={viewModel.pagination.numberOfItems} callback={paginate} />
        </>
      )}
    </>
  )
}

export default PartnerDatalist
