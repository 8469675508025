import BankInformationGateway from "../../../../gateway/Prospect/BankInformation/BankInformationGateway";
import {ProspectInterface} from '../../../../domain/Prospect/Prospect'
import {BankInformation} from '../../../../domain/Prospect/BankInformation'

export default class DeleteUseCase
{
  public bankInformationGateway

  constructor(
    BankInformationGateway: BankInformationGateway
  ) {
    this.bankInformationGateway = BankInformationGateway
  }

  async execute(Prospect: ProspectInterface, BankInformation: BankInformation): Promise<boolean> {
    return await this.bankInformationGateway.delete(Prospect.id, BankInformation.id)
  }
}
