import { Partner, PartnerInterface } from '../../../domain/Partner/Partner';

export class InitializePartner {
    initializePartner(): PartnerInterface {
        return new Partner(
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            false,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            [],
            [],
            [],
            [],
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            false,
            null,
            null,
            null,
            null,
            null,
            [],
            null,
            null,
            null,
            false,
            null,
            null,
            null,
            null,
            false,
            [],
            false,
            null,
            null,
            null,
            null,
            false,
            null,
            null,
            null,
            false,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            false,
          false,
            [],
        );
    }
}
