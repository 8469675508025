import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {v4 as uuidV4} from 'uuid'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../../store/hook'

import {
  setOpenProductBankInformation,
  setOpenProductUploadForm,
  setOpenProspectUploadForm
} from '../../../../../store/component/event'
import {ProductBankInformation} from '../../../../../../domain/Product/BankInformation'
import UpdateUseCase from '../../../../../../useCase/Product/Update/UpdateUseCase'
import ProductGateway from '../../../../../../gateway/Product/ProductGateway'
import AddUseCase from '../../../../../../useCase/Product/Add/AddUseCase'

import iconAdd from '../../../../../../assets/images/icons/add.svg'
import '../../../../../../assets/styles/page/_account.scss'
import {ProductInterface} from "../../../../../../domain/Product/Product";
import InitialiazeProduct
  from "../../../../../../useCase/Product/Initialization/InitialiazeProduct";
import iconEdit from "../../../../../../assets/images/icons/datalist-edit.svg";
import {ReferentielInterface} from "../../../../../../domain/Referentiel/ReferentielInterface";
import ReferentielGateway from "../../../../../../gateway/Referentiel/ReferentielGateway";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {reloadProduct} from "../../../../../store/component/product";
import BankInformationDatalist from "../../../Datalist/bankInformationDatalist";
import ProductBankInformationDatalist from "../../../Datalist/Product/productBankInformationDatalist";
import DocumentGateway from "../../../../../../gateway/Product/Document/DocumentGateway";
import {DocumentCustomerInterface} from "../../../../../../domain/Customer/Document/Document";

type Props = {
  product?: ProductInterface
  isLectureMode?: boolean
}

const BankDetails: FunctionComponent<Props> = ({product, isLectureMode}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const { productType } = useParams()
  const navigate = useNavigate()

  const openProductUploadForm = useAppSelector((state) => state.event.openProductUploadForm)

  const bankInformationArray = useAppSelector((state) => state.product.bankInformationArray)
  const [bankInformationList, setBankInformationList] = useState<ProductBankInformation[]|[]>(bankInformationArray)
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (openProductUploadForm.documents) {
      onSubmit()
    }
  }, [openProductUploadForm])

  useEffect(() => {
    setBankInformationList(bankInformationArray)
  }, [bankInformationArray])

  function onSubmit() {
    setSubmitted(true);
    if (bankInformationList.length == 0) {
      return
    }

    if (undefined === product) {
      product = new InitialiazeProduct().initializeProduct()
    }

    product.ribs = bankInformationList

    if (null !== product.id) {
      new UpdateUseCase(new ProductGateway()).execute(product).then(response => {
        if (null !== response) {
          toastSuccess(t('account.notify.update-success'))
          dispatch(reloadProduct());
        } else {
          toastError(t('account.notify.update-error'));
        }
        setSubmitted(false)
      })
    }
  }

  const uploadCallBack = (show: boolean, documents: any[]|null, productId: string|null, type: string|null, ribId: string|null) => {
    dispatch(setOpenProductUploadForm( {
      show: show,
      documents: documents,
      productId: productId,
      type: type,
      ribId: ribId
    }))

  }

  const viewFilesCallback =  (ribId: string|null) => {
    if (product?.id && ribId) {
      new DocumentGateway(product.id).getRibFiles(product.id, ribId).then((response) => {
        if (response && response.length > 0) {
          response.map((document: DocumentCustomerInterface) => {
            if (product?.id) {
              new DocumentGateway(product.id).getRibFile(product.id, ribId, document.id).then(response => {
                const file = window.open('')

                if (null !== file && response !== null) {
                  file.document.write(
                    "<body style='margin:0px;padding:0px;overflow:hidden'><iframe width='100%' height='100%' style='border:0' src='" + response + "'></iframe></body>"
                  )
                }
              })
            }
          })
        }
      })
    }
  }

  const bankDetailsBlock = <div className={`form-bloc form--bloc--bank-details`}>
    <div className="form-bloc__title">{t('account.form.bank-details.title')}</div>
    {!isLectureMode && <div className="form-bloc__shortcut">
      <button type="button" className="button-reset u-txt-with-icon"
              onClick={() => dispatch(setOpenProductBankInformation({show: true, bankInformation: null}))}>
        <img src={iconAdd} alt=""/>
        {t('account.form.bank-details.add')}
      </button>
    </div>}
    <div className="form-bloc__form flex-container">
      <div className="col-md-12">
        <ProductBankInformationDatalist viewFilesCallback={viewFilesCallback} uploadCallback={uploadCallBack} bankInformation={bankInformationArray} personId={product?.id ?? null} isLectureMode={isLectureMode}/>
      </div>
    </div>
  </div>

  return (
    <div>
      <div className="flex-container">
        <div className="col-md-12">{bankDetailsBlock}</div>
      </div>
      {!isLectureMode && <footer className={`form-bloc__footer`}>
        <button type="button"
                className="button button--ink-2"
                disabled={submitted}
                onClick={() => onSubmit()}
        >{t('common.save')}</button>
        <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => {
          navigate(`/${t('url.product.dashboard')}`)
        }}>{t('common.cancel')}</button>
      </footer>}
    </div>
  )
}

export default BankDetails
