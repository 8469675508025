export const ListProspect = [
  {
    'id' : '558612',
    'prospect_id' : '43359',
    'lastName' : 'Krusty Krab',
    'maidenName' : 'Fox',
    'firstName' : 'Krab',
    'productType' : 'Produit 1',
    'city' : 'East Aiyana',
    'commercial' : 'Commercial 1',
    'enabled' : '1'
  },
  {
    'id' : '558612',
    'prospect_id' : '43359',
    'lastName' : 'Krusty Krab 2',
    'maidenName' : 'Fox 2',
    'firstName' : 'Krab',
    'productType' : 'Produit 2',
    'city' : 'East Aiyana',
    'commercial' : 'Commercial 2',
    'enabled' : '0'
  },
]
export const FindProspect = {
  'id': null,
  'createdAt': null,
  'updatedAt': null,
  'deletedAt': null,
  'enabled': null,
  'prospectType': null,
  'status': null,
  'exitedAt': null,
  'blockedAt': null,
  'prospectCode': null,
  'externalCode': null,
  'recoveryCode': null,
  'legalStatus': null,
  'siret': null,
  'orias': null,
  'kycCategoryValue': null,
  'kycCategoryDate': null,
  'kycLabft': null,
  'kycLabftDate': null,
  'kycProfession': null,
  'kycFatca': null,
  'partnerCode': null,
  'partnerName': null,
  'partnerPlatformCode': null,
  'documents': null,
  'persons': null
}

export const NumberOfProspectByState = [11, 19, 22]
export const AgreedVsProspect = [651535, 558612, 267400, 487441]
export const BreakdownOfProspectsByType = [651535, 558612, 267400, 357400]
