import {FunctionComponent} from 'react'

type Props = {
  color?: string
}

const Exports: FunctionComponent<Props> = ({color}) => {
    return (
        <svg width="45" height="41" viewBox="0 0 45 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M23.7611 15.9936C23.1787 15.4037 22.2346 15.4061 21.6494 16.002L14.6831 23.0951C13.5513 24.2292 15.2485 25.9298 16.3803 24.7957L21.1068 20.0555C21.3394 19.8222 21.528 19.9041 21.528 20.235V33.313C21.528 33.9788 22.0608 34.5186 22.7283 34.5186C23.3911 34.5186 23.9285 33.98 23.9285 33.313V20.235C23.9285 19.9025 24.1178 19.8229 24.3498 20.0555L29.0763 24.7957C30.2075 25.9298 31.9052 24.2292 30.7734 23.0951L23.7611 15.9936Z" fill={color || 'white'}/>
            <path d="M43.7746 10.1412H1.6825V12.5464H43.7746V10.1412Z" fill={color || 'white'}/>
            <path d="M2.88543 0.520142L11.7201 0.520187C12.6719 0.520192 13.8985 0.977681 14.6153 1.6022L16.5381 3.27746C16.816 3.51956 17.4795 3.76771 17.8519 3.76771H42.5965C43.9211 3.76771 45.0008 4.84526 44.9999 6.17247L44.978 37.8039C44.9771 39.1343 43.9 40.207 42.5714 40.207H2.8856C1.55903 40.207 0.479858 39.133 0.479858 37.8062V2.92097C0.479858 1.59796 1.55887 0.520135 2.88543 0.520142ZM17.8519 6.17297C16.899 6.17297 15.6742 5.71489 14.9581 5.09096L13.0353 3.41571C12.7573 3.17351 12.0922 2.92545 11.72 2.92545L2.88572 2.9254C2.88549 2.92502 2.88512 37.802 2.88512 37.802C2.88518 37.8018 42.5714 37.8017 42.5714 37.8017C42.5721 37.8017 42.5946 6.17297 42.5946 6.17297C42.5949 6.17312 17.8519 6.17297 17.8519 6.17297Z" fill={color || 'white'}/>
        </svg>
    )
}

export default Exports
