import Caller from '../../domain/Api/Caller'
import envVariable from '../../infrastructure/presentation/util/envVariable'
import {FilterProductInterface, ProductListInterface} from '../../domain/Product/ProductList'
import ProductGatewayInterface from '../../domain/Product/ProductGatewayInterface'
import {ProductInterface} from '../../domain/Product/Product'
import {KpiResponseInterface} from '../../infrastructure/presentation/Type/Kpi'
import {ProductBankInformation} from "../../domain/Product/BankInformation";
import IdCleaner from "../../domain/Utils/IdCleaner";
import {NetAssetValueItemInterface} from "../../domain/Product/NetAssetValueItem";
import {Scale, ScalesAvailable} from '../../domain/Product/Scale';
import { ScaleItem } from '../../domain/Product/ScaleItem';
import {CallerType} from "../../domain/Caller/Caller";

type TResponseScale = {
  scales: Scale[]
  selectedScales: string[]
}

export default class ProductGateway implements ProductGatewayInterface {
  getScales(partnerId: string, productId: string): Promise<TResponseScale> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/partners/${partnerId}/scales/${productId}`, {})
    .then(data => {
      if (data) {
        return data
      }
    }).catch(response => {
      return []
    });

  }

  getScalesAvailable(partnerId: string, productId: string, start: string, end: string): Promise<ScalesAvailable| []> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/partners/${partnerId}/scales/${productId}/start/${start.replaceAll('/', '-')}/end/${end.replaceAll('/', '-')}`, {})
      .then(data => {
        if (data) {
          return data
        }
      })
  }

  getList(
      currentPage: number,
      itemsPerPage: number,
      filter: FilterProductInterface|null,
      signal?: AbortSignal,
      sort?: string,
      order?: string,
      callerType?: CallerType,
  ): Promise<{data: ProductListInterface[]|null, numberOfItems: number}|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/products${callerType === "datalist" ? "-datalist" : ""}?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`, {
      'label': filter?.name || '',
      'keywords': filter?.keywords || '',
      'type': filter?.type || '',
      'subproduct': filter?.subproduct || null,
      'status': filter?.status?.map((status) => status.value) || ""
    },
    signal
    ).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    }).catch(response => {
      return {
        data: [],
        numberOfItems: 0
      }
    });
  }

  add(Product: ProductInterface): Promise<ProductInterface|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/product`, {
      "status": Product.status,
      "productType": Product.productType?.slug,
      "classification": Product.classification,
      "generalInformation": {
        "subscribable": Product.generalInformation?.subscribable,
        "acronyme": Product.generalInformation?.acronyme,
        "isin": Product.generalInformation?.isin,
        "multiproduct": Product.generalInformation?.multiproduct,
        "subproduct": Product.generalInformation?.subproduct,
        "msConfrontation": Product.generalInformation?.msConfrontation,
        "recurrence": Product.generalInformation?.recurrence,
        "label": Product.generalInformation?.label,
        "address": Product.generalInformation?.address,
        "postcode": Product.generalInformation?.postcode,
        "city": Product.generalInformation?.city?.id,
        "country": Product.generalInformation?.country?.id,
        "visaAmf": Product.generalInformation?.visaAmf,
        "visaDate": Product.generalInformation?.visaDate,
        "marketingDate": Product.generalInformation?.marketingDate,
        "kbis": Product.generalInformation?.kbis,
        "giin": Product.generalInformation?.giin,
        "rcs": Product.generalInformation?.rcs,
        "rcsPlace": Product.generalInformation?.rcsPlace,
        "aif": Product.generalInformation?.aif,
        "lei" : Product.generalInformation?.lei,
        "shareType": Product.generalInformation?.shareType,
        "capital": Product.generalInformation?.capital,
        "validatedAt": Product.generalInformation?.validatedAt,
        "liquidationDate": Product.generalInformation?.liquidationDate
      },
      "valorisation": {
        "nominal": {
          "value": Product.valorisation?.nominal.value,
          "start": Product.valorisation?.nominal.period.start,
          "end": Product.valorisation?.nominal.period.end
        },
        "emission": {
          "value": Product.valorisation?.emission.value,
          "start": Product.valorisation?.emission.period.start,
          "end": Product.valorisation?.emission.period.end
        },
        "admission": {
          "value": Product.valorisation?.admission.value,
          "start": Product.valorisation?.admission.period.start,
          "end": Product.valorisation?.admission.period.end
        },
        "subscription": {
          "value": Product.valorisation?.subscription.value,
          "start": Product.valorisation?.subscription.period.start,
          "end": Product.valorisation?.subscription.period.end
        },
        "withdraw": {
          "value": Product.valorisation?.withdraw.value,
          "start": Product.valorisation?.withdraw.period.start,
          "end": Product.valorisation?.withdraw.period.end
        },
        "realisation": {
          "value": Product.valorisation?.realisation.value,
          "start": Product.valorisation?.realisation.period.start,
          "end": Product.valorisation?.realisation.period.end
        },
        "reconstitution": {
          "value": Product.valorisation?.reconstitution.value,
          "start": Product.valorisation?.reconstitution.period.start,
          "end": Product.valorisation?.reconstitution.period.end
        },
        "ifi": {
          "value": Product.valorisation?.ifi.value,
          "start": Product.valorisation?.ifi.period.start,
          "end": Product.valorisation?.ifi.period.end
        },
        "ifiNonResident": {
          "value": Product.valorisation?.ifiNonResident.value,
          "start": Product.valorisation?.ifiNonResident.period.start,
          "end": Product.valorisation?.ifiNonResident.period.end
        },
        "marketRate": {
          "value": Product.valorisation?.marketRate.value,
          "start": Product.valorisation?.marketRate.period.start,
          "end": Product.valorisation?.marketRate.period.end
        },
        "quaterly": {
          "value": Product.valorisation?.quaterly.value,
          "start": Product.valorisation?.quaterly.period.start,
          "end": Product.valorisation?.quaterly.period.end
        },
        "yearly": {
          "value": Product.valorisation?.yearly.value,
          "start": Product.valorisation?.yearly.period.start,
          "end": Product.valorisation?.yearly.period.end
        },
        "yield": {
          "value": Product.valorisation?.yield.value,
          "start": Product.valorisation?.yield.period.start,
          "end": Product.valorisation?.yield.period.end
        },
        "source": {
          "value": Product.valorisation?.source.value,
          "start": Product.valorisation?.source.period.start,
          "end": Product.valorisation?.source.period.end
        },
        "customerPatrimony": Product.valorisation?.customerPatrimony
      },
      "distribution": {
        "enabled": Product.distribution?.enabled,
        "periodicity": Product.distribution?.periodicity,
        "term": Product.distribution?.term
      },
      "withdraw": {
        "allowedAt": Product.withdraw?.allowedAt,
        "condition": Product.withdraw?.condition,
        "cost": Product.withdraw ? parseFloat(Product.withdraw.cost) : null
      },
      "accounting": {
        "bank" : Product.accounting?.bank,
        "iban" : Product.accounting?.iban,
        "swift" : Product.accounting?.swift,
        "periodicity": Product.accounting?.periodicity,
        "number": Product.accounting?.number,
        "cost": Product.accounting ? parseFloat(Product.accounting.cost) : null,
        "commissionEarned": Product.accounting ? parseFloat(Product.accounting.commissionEarned) : null,
        "commissionNotEarned": Product.accounting ? parseFloat(Product.accounting.commissionNotEarned) : null,
        "upFront": Product.accounting?.upFront ? {
          "duration": parseInt(Product.accounting.upFront.duration),
          "tva": Product.accounting?.upFront.tva
        } : null,
        "running": Product.accounting?.running ? {
          "duration": parseInt(Product.accounting.running.duration),
          "tva": Product.accounting?.running.tva
        } : null,
        "distribution": Product.accounting?.distribution,
      },
      "usage": {
        "newPart": {
          "delay": Product.usage?.newPart.delay,
          "duration": Product.usage?.newPart.duration
        },
        "transfer": {
          "delay": Product.usage?.transfer.delay,
          "duration": Product.usage?.transfer.duration
        },
        "mutation": {
          "delay": Product.usage?.mutation.delay,
          "duration": Product.usage?.mutation.duration
        }
      },
      /* "categorization": {
        "target": Product.categorization?.target,
        "eligibility": Product.categorization?.eligibility,
        "emt": {
          "enabled": Product.categorization?.emt?.enabled,
          "date": Product.categorization?.emt?.date
        },
        "ept": {
          "enabled": Product.categorization?.ept?.enabled,
          "date": Product.categorization?.ept?.date
        },
        "tpt": {
          "enabled": Product.categorization?.tpt?.enabled,
          "date": Product.categorization?.tpt?.date
        }
      }, */
      "generalMeeting": {
        "accountingFirm": Product.generalMeeting?.accountingFirm,
        "address": Product.generalMeeting?.address,
        "address2" : Product.generalMeeting?.address2,
        "postcode": Product.generalMeeting?.postcode,
        "city": Product.generalMeeting?.city?.id,
        "country": Product.generalMeeting?.country?.id,
        "term": Product.generalMeeting?.term,
        "provisionalGeneralAssemblyDate": Product.generalMeeting?.provisionalGeneralAssemblyDate,
        "convocation": {
          "bareOwner": Product.generalMeeting?.convocation.bareOwner,
          "usufructuary": Product.generalMeeting?.convocation.usufructuary
        },
        "vote": {
          "bareOwner": Product.generalMeeting?.vote.bareOwner,
          "usufructuary": Product.generalMeeting?.vote.usufructuary
        }
      },
      "commission": {
        "upFront": {
          "duration": Product.commission ? parseInt(Product.commission.upFront.duration) : null,
          "tva": Product.commission?.upFront.tva,
          "commissionDetentionMode": Product.commission?.upFront?.commissionDetentionMode,
          "commissionEarned": Product.commission?.upFront.commissionEarned || null,
          "commissionNotEarned": Product.commission?.upFront.commissionNotEarned || null,
          "calcul": Product.commission?.upFront.calcul,
          "commissionType": Product.commission?.upFront.commissionType
        },
        "sc": {
          "duration": Product.commission ? parseInt(Product.commission.sc.duration) : null,
          "tva": Product.commission?.sc.tva,
          "commissionDetentionMode": Product.commission?.sc?.commissionDetentionMode,
          "commissionEarned": Product.commission?.sc.commissionEarned || null,
          "commissionNotEarned": Product.commission?.sc.commissionNotEarned || null,
          "calcul": Product.commission?.sc.calcul,
          "commissionType": Product.commission?.sc.commissionType
        },
        "running": {
          "duration": Product.commission ? parseInt(Product.commission.running.duration) : null,
          "tva": Product.commission?.running.tva,
          "commissionDetentionMode": Product.commission?.running?.commissionDetentionMode,
          "commissionEarned": Product.commission?.running.commissionEarned || null,
          "commissionNotEarned": Product.commission?.running.commissionNotEarned || null,
          "calcul": Product.commission?.running.calcul,
          "commissionType": Product.commission?.running.commissionType
        },
      },
      "depositary": {
        "depositary": Product.depositary?.depositary?.id,
        "file": Product.depositary?.file,
        "periodicity": Product.depositary?.periodicity,
        "reporting": Product.depositary?.reporting,
        "prestation": Product.depositary?.prestation,
        "regulatory": Product.depositary?.regulatory
      },
      "fiscality": {
        "ifu" : Product.fiscality?.ifu,
        "benefit" : Product.fiscality?.benefit,
        "comment" : Product.fiscality?.comment,
      },
      "netAssetValue": {
        "periodicity": Product.netAssetValue?.periodicity,
        "items": Product.netAssetValue?.items.map(item => {
          return {
            "id": item.id === '' ? null : item.id,
            "publishedAt": item.publishedAt,
            "value": item.value,
            "start": item.period.start,
            "end": item.period.end
          }
        })
      },
      "childs": Product.childs,
      "ribs" : Product.ribs?.map((rib: ProductBankInformation) => {
        return {
          "id": IdCleaner.clean(rib.id),
          "type": rib.type,
          "title": rib.title,
          "bank": rib.bank,
          "iban": rib.iban,
          "swift": rib.swift
        }
      }),
      "calcul": Product.calcul,
      "decimalStep": Product.decimalStep
    })
    .then(data => {
      if (data && data.uuid) {
        return data.uuid
      }

      return null
    })
  }

  getAll(): Promise<[]> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/all-products`, {})
    .then(data => {
      if (data) {
        return data
      }

      return []
    })
    .catch(() => {
      return []
    })
  }

  getProductOpenToSubscription(): Promise<[]> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/products/opens`, {})
      .then(data => {
        if (data) {
          return data
        }

        return []
      })
      .catch(() => {
        return []
      })
  }

  get(id: string): Promise<ProductInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/product/${id}`, {})
    .then(data => {
      if (data) {
        return data[0]
      }

      return null
    })
  }

  update(Product: ProductInterface): Promise<ProductInterface|null> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/product/${Product.id}`, {
      "status": Product.status,
      "productType": Product.productType?.slug,
      "classification": Product.classification,
      "generalInformation": {
        "subscribable": Product.generalInformation?.subscribable,
        "acronyme": Product.generalInformation?.acronyme,
        "isin": Product.generalInformation?.isin,
        "multiproduct": Product.generalInformation?.multiproduct,
        "subproduct": Product.generalInformation?.subproduct,
        "msConfrontation": Product.generalInformation?.msConfrontation,
        "recurrence": Product.generalInformation?.recurrence,
        "label": Product.generalInformation?.label,
        "address": Product.generalInformation?.address,
        "postcode": Product.generalInformation?.postcode,
        "city": Product.generalInformation?.city?.id,
        "country": Product.generalInformation?.country?.id,
        "visaAmf": Product.generalInformation?.visaAmf,
        "visaDate": Product.generalInformation?.visaDate,
        "marketingDate": Product.generalInformation?.marketingDate,
        "kbis": Product.generalInformation?.kbis,
        "giin": Product.generalInformation?.giin,
        "rcs": Product.generalInformation?.rcs,
        "rcsPlace": Product.generalInformation?.rcsPlace,
        "aif": Product.generalInformation?.aif,
        "lei" : Product.generalInformation?.lei,
        "shareType": Product.generalInformation?.shareType,
        "capital": Product.generalInformation?.capital,
        "validatedAt": Product.generalInformation?.validatedAt,
        "liquidationDate": Product.generalInformation?.liquidationDate
      },
      "valorisation": {
        "nominal": {
          "value": Product.valorisation?.nominal?.value,
          "start": Product.valorisation?.nominal?.period.start,
          "end": Product.valorisation?.nominal?.period.end
        },
        "emission": {
          "value": Product.valorisation?.emission?.value,
          "start": Product.valorisation?.emission?.period?.start,
          "end": Product.valorisation?.emission?.period?.end
        },
        "admission": {
          "value": Product.valorisation?.admission?.value,
          "start": Product.valorisation?.admission?.period?.start,
          "end": Product.valorisation?.admission?.period?.end
        },
        "subscription": {
          "value": Product.valorisation?.subscription?.value,
          "start": Product.valorisation?.subscription?.period?.start,
          "end": Product.valorisation?.subscription?.period?.end
        },
        "withdraw": {
          "value": Product.valorisation?.withdraw?.value,
          "start": Product.valorisation?.withdraw?.period?.start,
          "end": Product.valorisation?.withdraw?.period?.end
        },
        "realisation": {
          "value": Product.valorisation?.realisation?.value,
          "start": Product.valorisation?.realisation?.period?.start,
          "end": Product.valorisation?.realisation?.period?.end
        },
        "reconstitution": {
          "value": Product.valorisation?.reconstitution?.value,
          "start": Product.valorisation?.reconstitution?.period?.start,
          "end": Product.valorisation?.reconstitution?.period?.end
        },
        "ifi": {
          "value": Product.valorisation?.ifi?.value,
          "start": Product.valorisation?.ifi?.period?.start,
          "end": Product.valorisation?.ifi?.period?.end
        },
        "ifiNonResident": {
          "value": Product.valorisation?.ifiNonResident?.value,
          "start": Product.valorisation?.ifiNonResident?.period?.start,
          "end": Product.valorisation?.ifiNonResident?.period?.end
        },
        "marketRate": {
          "value": Product.valorisation?.marketRate?.value,
          "start": Product.valorisation?.marketRate?.period?.start,
          "end": Product.valorisation?.marketRate?.period?.end
        },
        "quaterly": {
          "value": Product.valorisation?.quaterly?.value,
          "start": Product.valorisation?.quaterly?.period?.start,
          "end": Product.valorisation?.quaterly?.period?.end
        },
        "yearly": {
          "value": Product.valorisation?.yearly?.value,
          "start": Product.valorisation?.yearly?.period?.start,
          "end": Product.valorisation?.yearly?.period?.end
        },
        "yield": {
          "value": Product.valorisation?.yield?.value,
          "start": Product.valorisation?.yield?.period?.start,
          "end": Product.valorisation?.yield?.period?.end
        },
        "source": {
          "value": Product.valorisation?.source?.value,
          "start": Product.valorisation?.source?.period?.start,
          "end": Product.valorisation?.source?.period?.end
        },
        "customerPatrimony": Product.valorisation?.customerPatrimony
      },
      "distribution": {
        "enabled": Product.distribution?.enabled,
        "periodicity": Product.distribution?.periodicity,
        "term": Product.distribution?.term
      },
      "withdraw": {
        "allowedAt": Product.withdraw?.allowedAt,
        "condition": Product.withdraw?.condition,
        "cost": Product.withdraw ? parseFloat(Product.withdraw.cost) : null
      },
      "accounting": {
        "bank" : Product.accounting?.bank,
        "iban" : Product.accounting?.iban,
        "swift" : Product.accounting?.swift,
        "periodicity": Product.accounting?.periodicity,
        "number": Product.accounting?.number,
        "cost": Product.accounting ? parseFloat(Product.accounting.cost) : null,
        "commissionEarned": Product.accounting ? parseFloat(Product.accounting.commissionEarned) : null,
        "commissionNotEarned": Product.accounting ? parseFloat(Product.accounting.commissionNotEarned) : null,
        "upFront": Product.accounting?.upFront ? {
          "duration": parseInt(Product.accounting.upFront.duration),
          "tva": Product.accounting?.upFront.tva
        } : null,
        "running": Product.accounting?.running ? {
          "duration": parseInt(Product.accounting.running.duration),
          "tva": Product.accounting?.running.tva
        } : null,
        "distribution": Product.accounting?.distribution,
      },
      "usage": {
        "newPart": {
          "delay": Product.usage?.newPart.delay,
          "duration": Product.usage?.newPart.duration
        },
        "transfer": {
          "delay": Product.usage?.transfer.delay,
          "duration": Product.usage?.transfer.duration
        },
        "mutation": {
          "delay": Product.usage?.mutation.delay,
          "duration": Product.usage?.mutation.duration
        }
      },
      /* "categorization": {
        "target": Product.categorization?.target,
        "eligibility": Product.categorization?.eligibility,
        "emt": {
          "enabled": Product.categorization?.emt?.enabled,
          "date": Product.categorization?.emt?.date
        },
        "ept": {
          "enabled": Product.categorization?.ept?.enabled,
          "date": Product.categorization?.ept?.date
        },
        "tpt": {
          "enabled": Product.categorization?.tpt?.enabled,
          "date": Product.categorization?.tpt?.date
        }
      }, */
      "generalMeeting": {
        "accountingFirm": Product.generalMeeting?.accountingFirm,
        "address": Product.generalMeeting?.address,
        "address2" : Product.generalMeeting?.address2,
        "postcode": Product.generalMeeting?.postcode,
        "city": Product.generalMeeting?.city?.id,
        "country": Product.generalMeeting?.country?.id,
        "term": Product.generalMeeting?.term,
        "provisionalGeneralAssemblyDate": Product.generalMeeting?.provisionalGeneralAssemblyDate,
        "convocation": {
          "bareOwner": Product.generalMeeting?.convocation?.bareOwner,
          "usufructuary": Product.generalMeeting?.convocation?.usufructuary
        },
        "vote": {
          "bareOwner": Product.generalMeeting?.vote?.bareOwner,
          "usufructuary": Product.generalMeeting?.vote?.usufructuary
        }
      },
      "commission": {
        "upFront": {
          "duration": Product.commission ? parseInt(Product.commission.upFront.duration) : null,
          "tva": Product.commission?.upFront.tva,
          "commissionDetentionMode": Product.commission?.upFront?.commissionDetentionMode,
          "commissionEarned": Product.commission?.upFront.commissionEarned || null,
          "commissionNotEarned": Product.commission?.upFront.commissionNotEarned || null,
          "calcul": Product.commission?.upFront.calcul,
          "commissionType": Product.commission?.upFront.commissionType
        },
        "sc": {
          "duration": Product.commission ? parseInt(Product.commission.sc.duration) : null,
          "tva": Product.commission?.sc.tva,
          "commissionDetentionMode": Product.commission?.sc?.commissionDetentionMode,
          "commissionEarned": Product.commission?.sc.commissionEarned || null,
          "commissionNotEarned": Product.commission?.sc.commissionNotEarned || null,
          "calcul": Product.commission?.sc.calcul,
          "commissionType": Product.commission?.sc.commissionType
        },
        "running": {
          "duration": Product.commission ? parseInt(Product.commission.running.duration) : null,
          "tva": Product.commission?.running.tva,
          "commissionDetentionMode": Product.commission?.running?.commissionDetentionMode,
          "commissionEarned": Product.commission?.running.commissionEarned || null,
          "commissionNotEarned": Product.commission?.running.commissionNotEarned || null,
          "calcul": Product.commission?.running.calcul,
          "commissionType": Product.commission?.running.commissionType
        },
      },
      "depositary": {
        "depositary": Product.depositary?.depositary?.id,
        "file": Product.depositary?.file,
        "periodicity": Product.depositary?.periodicity,
        "reporting": Product.depositary?.reporting,
        "prestation": Product.depositary?.prestation,
        "regulatory": Product.depositary?.regulatory
      },
      "documentation": {
        "status": Product.documentation?.status,
        "dic": Product.documentation?.dic,
        "information": Product.documentation?.information,
        "subscription": Product.documentation?.subscription,
        "annualReport": Product.documentation?.annualReport,
      },
      "fiscality": {
        "ifu" : Product.fiscality?.ifu,
        "benefit" : Product.fiscality?.benefit,
        "comment" : Product.fiscality?.comment,
      },
      "netAssetValue": {
        "periodicity": Product.netAssetValue?.periodicity,
        "items": Product.netAssetValue?.items.map(item => {
          return {
            "id": item.id === '' ? null : item.id,
            "publishedAt": item.publishedAt,
            "value": item.value,
            "start": item.period.start,
            "end": item.period.end
          }
        })
      },
      "childs": Product.childs,
      "ribs" : Product.ribs?.map((rib: ProductBankInformation) => {
        return {
          "id": IdCleaner.clean(rib.id),
          "type": rib.type,
          "title": rib.title,
          "bank": rib.bank,
          "iban": rib.iban,
          "swift": rib.swift
        }
      }),
      "calcul": Product.calcul
    })
    .then(data => {
      if (data) {
        return data
      }

      return null
    })
  }

  getExport(filter: FilterProductInterface|null): Promise<string|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/products/extract`, {
      'label': filter?.name || '',
      'keywords': filter?.keywords || ''
    })
    .then(data => {
      if (data && data.content) {
        return data.content
      }

      return null
    }).catch(response => {
      return null
    })
  }

  getKpi(): Promise<KpiResponseInterface[]|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/products/kpi`, {})
    .then(data => {
      if (data) {
        return data
      }

      return null
    }).catch(response => {
      return null
    });
  }

  getNetAssets(uuid: string, itemsPerPage: number, currentPage: number): Promise<{
    data: NetAssetValueItemInterface[]
    current_page: string
    nb_element_per_page: number
    nb_element: number
  }> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/products/${uuid}/netassets?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}`, {})
    .then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        nb_element_per_page: 0,
        nb_element: 0,
        current_page: 1
      }
    }).catch(response => {
      return {
        data: [],
        nb_element_per_page: 0,
        nb_element: 0,
        current_page: 1
      }
    });
  }

  getNetAssetValueByDate(productId: string, date: string): Promise<{
    data: NetAssetValueItemInterface[]
    current_page: string
    nb_element_per_page: number
    nb_element: number
  }> {
    const dateFormatted = date.replaceAll('/', '-')
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/products/${productId}/vl/${dateFormatted}`, {})
      .then(data => {
        if (data) {
          return data
        }

        return null
      }).catch(err => {
        console.error("erreur: ", err)
      });
  }
}
