import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../../../../store/hook'

import HeaderPage from '../../../../component/HeaderPage/HeaderPage'
import Form from '../../../../component/Form/RedemptionWithdrawal/Form/Form'
import ChoiceSliceToGiveUp from '../../../../component/Sidebar/Movement/ChoiceSliceToGiveUp'
import ChoiceInvestor from '../../../../component/Sidebar/Movement/ChoiceInvestor'
import GetUseCase from '../../../../../../useCase/Movement/Get/RedemptionWithdrawalGetUseCase'
import InitializeRedemptionWIthDrawal from '../../../../../../useCase/Movement/Initialization/InitializeRedemptionWIthDrawal'
import {RedemptionWithdrawal} from '../../../../../../domain/Movement/RedemptionWithdrawal'
import RedemptionWithdrawalGateway from '../../../../../../gateway/Movement/RedemptionWithrawal/RedemptionWithdrawalGateway'
import {addMultipleSliceToTransaction, addMultipleSliceToTransactionFreeze} from '../../../../../store/component/slice'
import Upload from "../../../../component/Upload/Upload";
import DocumentGateway from "../../../../../../gateway/Movement/RedemptionWithrawal/Document/DocumentGateway";
import {setOpenUploadForm} from "../../../../../store/component/event";
import MatchUrl from "../../../../../../domain/Utils/MatchedUrl";
import ChoicePartner from "../../../../component/Sidebar/Prospect/ChoicePartner";
import DocumentList from "../../../../component/Document/DocumentList";
import {TError} from "../../../../../../domain/Error/Error";
import ErrorPage from "../../../Error/ErrorPage";
import Spinner from "../../../../component/Spinner/Spinner";
import {SliceListInterface} from "../../../../../../domain/Share/Slice/SliceList";

const AddOrEdit: FunctionComponent = () => {
  const {t} = useTranslation()
  const {uuid} = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const transactionRules = useAppSelector((state) => state.me.me?.rules.transaction.actions)
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const reloadMovement = useAppSelector((state) => state.movement.reloadMovement)
  const [redemptionWithdrawal, setRedemptionWithdrawal] = useState<RedemptionWithdrawal | null>(null)
  const openUploadForm = useAppSelector((state) => state.event.openUploadForm)
  const isLectureMode = MatchUrl.find(location.pathname, 'lecture')
  const [error, setError] = useState<TError|null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if(transactionRules) {
      if(!transactionRules?.update && !isLectureMode) navigate(`/${t('url.movements.read-redemption-withdrawal')}/${uuid}`)
    }
  }, [transactionRules])

  useEffect(() => {
    if (uuid !== undefined) {
      new GetUseCase(new RedemptionWithdrawalGateway()).execute(uuid).then(response => {
        if (response !== null) {
          setRedemptionWithdrawal(response)
          const closedStatus = ["cancelled", "validated", "subscribed"]
          if(response?.status && closedStatus.includes(response.status)) {
            navigate(`/${t('url.movements.read-redemption-withdrawal')}/${uuid}`)
          }
          if (response.slices) {
            dispatch(addMultipleSliceToTransaction(response.slices))
          }
          if (response.slicesDetail) {
            dispatch(addMultipleSliceToTransactionFreeze(response.slicesDetail))
          }
          setIsLoading(false)
        }
      }).catch((e) => {
        setError(e)
        setIsLoading(false)
      })
    } else {
      setRedemptionWithdrawal(new InitializeRedemptionWIthDrawal().initializeInitializeRedemptionWIthDrawal())
      setIsLoading(false)
    }
  }, [uuid, reloadMovement])

  if(error) return <ErrorPage code={error.code.toString()} />

  return (
    <>
      {!isLectureMode && <ChoiceInvestor/>}
      {!isLectureMode && <ChoicePartner/>}
      <ChoiceSliceToGiveUp/>
      <DocumentList />
      {redemptionWithdrawal &&
        <Upload openUploadForm={openUploadForm} gateway={new DocumentGateway(redemptionWithdrawal.id ?? null, null)}
              callback={setOpenUploadForm}/>
      }
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''} movement`}>
        <HeaderPage title={t('movement.redemption-withdrawal.title')}/>
        {redemptionWithdrawal && !isLoading ?  <Form movement={redemptionWithdrawal} isLectureMode={isLectureMode}/> : <section className="container--spinner"><Spinner size={150}/></section>}
      </main>
    </>
  )
}

export default AddOrEdit
