import {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'

import {useAppSelector} from '../../../../store/hook'

import HeaderPage from '../../../component/HeaderPage/HeaderPage'
import TabPartner from '../../../component/Tab/Partner/TabParner'

const PartnerCommissioning: FunctionComponent = () => {
  const {t} = useTranslation()

  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)

  return (
    <>
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        <HeaderPage title={t('partner.navigation-second')} />
        <TabPartner />
      </main>
    </>
  )
}

export default PartnerCommissioning
