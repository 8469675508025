import React, {FunctionComponent, useEffect, useState} from "react";
import {
  ReferentielInterface,
} from "../../../../../../domain/Referentiel/ReferentielInterface";
import {Scale} from "../../../../../../domain/Product/Scale";
import {SubmitHandler, useForm} from "react-hook-form";
import ScaleSidebar from "../ScaleSidebar";
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import RadioChoices from "../../../Elements/RadioChoices";
import InputDate from "../../../Elements/InputDate";
import {useTranslation} from "react-i18next";
import ScaleGateway from "../../../../../../gateway/Product/Scale/ScaleGateway";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {useAppDispatch, useAppSelector} from "../../../../../store/hook";
import {reloadProduct, setScales} from "../../../../../store/component/product";
import {setOpenProductScaleForm} from "../../../../../store/component/event";
import {ReferentielItemInterface} from "../../../../../../domain/Referentiel/ReferentielItemInterface";
import AlertInfo from "../../../Alert/AlertInfo/AlertInfo";

type Props = {
  productId: string
  scale: Scale | null,
  referential: ReferentielInterface,
  isLectureMode: boolean
}

const ScaleForm: FunctionComponent<Props> = ({productId, scale, referential, isLectureMode}) => {
  const openScaleForm = useAppSelector((state) => state.event.openProductScaleForm)
  const [referentialFiltered, setReferentialFiltered] = useState<ReferentielItemInterface[]>([])

  const {register, control, handleSubmit, formState: {isValid}} = useForm<{ scale: Scale | null }>({
    defaultValues: {
      scale: scale
    }
  });

  console.log("openScaleForm", openScaleForm)
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const scaleGateway = new ScaleGateway()
  const [isSubmit, setSubmit] = useState<boolean>(false)

  useEffect(() => {
    if (openScaleForm.scales?.length) {
      console.log('openScaleForm.scales', openScaleForm.scales)
      let refFiltered = []
      refFiltered = referential.product.scale_category.filter((category) => {
        if (openScaleForm.scales) {
          console.log('if')
          return !openScaleForm.scales.some((sc) => {
            return category.value == sc.category.id
          })
        } else {
          console.log("else")
          return referential.product.scale_category
        }
      })
      console.log('avant set refFiltered', refFiltered)
      setReferentialFiltered([...refFiltered])
      console.log('refFiltered', refFiltered)
    } else {
      setReferentialFiltered(referential.product.scale_category)
    }
  }, [openScaleForm.scales])

  const onSubmit: SubmitHandler<{ scale: Scale }> = data => {
    if (!isValid) {
      return
    }
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: { scale: Scale }) => {
    setSubmit(true)

    if (data.scale.id) {
      scaleGateway.update(productId, data.scale)
        .then((response) => {
          toastSuccess(t('product.form.scale.notify.edit.success'))

          dispatch(setScales(response))
          dispatch(setOpenProductScaleForm({show: false, scale: null}))
          setSubmit(false)
        })
        .catch((e) => {
          setSubmit(false)
          toastError(t('product.form.scale.notify.edit.error'))
        })
    } else {
      scaleGateway.create(productId, data.scale)
        .then((response) => {
          toastSuccess(t('product.form.scale.notify.add.success'))

          dispatch(setScales(response))
          dispatch(setOpenProductScaleForm({show: false, scale: null}))
          dispatch(reloadProduct())
          setSubmit(false)
        })
        .catch((e) => {
          setSubmit(false)
          toastError(t('product.form.scale.notify.add.error'))
        })
    }
  }


  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="form-bloc">
        <div className="sidebar__content">
          <div className="title">{t('product.form.scale.siderbar.add')}</div>
          <div className="col-md-6">
            <div className="flex-container">
              {referentialFiltered.length ? <RadioChoices classes={'col-md-12 initial-grid'}
                                                          label={t('product.form.scale.siderbar.type-of')}
                                                          id="scale.category.id" name="scale.category.id"
                                                          register={register}
                                                          options={referentialFiltered}
                                                          disabled={isLectureMode}
              /> : <div className="col-md-12">
                <AlertInfo classes={"flex items-start"}
                           text={"Plus auncun Barèmes disponibles"}
                />
              </div>}

              <InputDate id="generalScaleDate"
                         classes="col-md-6"
                         name="scale.date"
                         register={register}
                         control={control}
                         label={t('product.form.scale.siderbar.date')}
                         disabled={isLectureMode}
              />
            </div>
          </div>
        </div>
        <footer className="sidebar__footer">
          <button type="submit"
                  disabled={isLectureMode || isSubmit || !referentialFiltered.length}
                  className="button button--ink-2 u-mrm">{t('common.save')}</button>
        </footer>
      </form>
    </>
  )
}

export default ScaleForm
