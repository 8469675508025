import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useFormState } from "react-hook-form";

// Notification lib
import "react-toastify/dist/ReactToastify.min.css";

import { useAppDispatch, useAppSelector } from "../../../../store/hook";
import { setOpenDatalistFilterPartner } from "../../../../store/component/event";
import blockScrollBody from "../../../util/BlockScroll";
import getClassForOverlay from "../../../util/Sidebar";
import {
  ReferentielInterface
} from '../../../../../domain/Referentiel/ReferentielInterface'
import Input from "../../Elements/Input";
import { FilterPartnerInterface } from "../../../../../domain/Partner/PartnerList";
import { usePrompt } from "../../../util/Navigation";
import CityDatalist from "../../Datalist/City/CityDatalist";
import ProductDalalist from "../../Datalist/Product/ProductDalalist";
import MultiSelectCustom from "../../Elements/MultiSelect";

const DatalistFilter: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openDatalistFilterPartner = useAppSelector((state) => state.event.openDatalistFilterPartner);

  const { register, control, handleSubmit, reset, setValue, getValues } = useForm<FilterPartnerInterface>();

  useEffect(() => {
    blockScrollBody(openDatalistFilterPartner.show);
  }, [openDatalistFilterPartner]);

  useEffect(() => {
    setValue("idPartner", openDatalistFilterPartner.filters.idPartner);
    setValue("keywords", openDatalistFilterPartner.filters.keywords);
    setValue("socialReason", openDatalistFilterPartner.filters.socialReason);
    setValue("name", openDatalistFilterPartner.filters.name);
    setValue("city", openDatalistFilterPartner.filters.city);
    setValue("product", openDatalistFilterPartner.filters.product);
    setValue("status", openDatalistFilterPartner.filters.status);
    setValue("type", openDatalistFilterPartner.filters.type);
    setValue("platform", openDatalistFilterPartner.filters.platform);
    setValue("network", openDatalistFilterPartner.filters.network);
  }, [openDatalistFilterPartner.filters]);

  const onConfirm = (data: any) => {
    reset(data);
    dispatch(
      setOpenDatalistFilterPartner({
        show: false,
        count: openDatalistFilterPartner.count,
        filters: {
          ...data,
          commissionLevel: openDatalistFilterPartner.filters?.commissionLevel || ""
        },
      })
    );
  };

  const { isDirty } = useFormState({
    control,
  });
  usePrompt(isDirty, handleSubmit(onConfirm));

  function handleClose() {
    dispatch(
      setOpenDatalistFilterPartner({
        show: false,
        count: 0,
        filters: {
          idPartner: '',
          keywords: '',
          socialReason: '',
          name: '',
          city: '',
          product: {
            id: '',
            label: '',
            value: ''
          },
          status: [],
          type: [],
          platform: [],
          network: [],
          commissionLevel: ''
        }
      })
    );
  }

  return (
    <>
      {referential && (
        <>
          <div
            className={`overlay ${getClassForOverlay(
              openDatalistFilterPartner.show
            )}`}
            onClick={() => handleClose()}
          />
          <div
            className={`sidebar sidebar--right sidebar--right ${
              openDatalistFilterPartner.show ? "sidebar--active" : ""
            }`}
          >
            <form
              onSubmit={handleSubmit(onConfirm)}
              className="form-bloc form-bloc--partner-address-form"
            >
              <div className="sidebar__content">
                <div className="title">{t("common.filters")}</div>
                <div className="form-bloc__form flex-container">
                  <Input
                    register={register}
                    classes={"col-md-6"}
                    label={t("filters.by-id-partner")}
                    type={"text"}
                    name={"idPartner"}
                    id={"idPartner"}
                  />
                  <Input
                    register={register}
                    classes={"col-md-6"}
                    label={t("common.search-by", {
                      keyword: t("filters.by-keyword-or-id"),
                    })}
                    type={"text"}
                    name={"keywords"}
                    id={"keywords"}
                  />
                  <Input
                    register={register}
                    classes={"col-md-6"}
                    label={t(
                      "partner.form.bloc-general-information.social-reason"
                    )}
                    type={"text"}
                    name={"socialReason"}
                    id={"socialReason"}
                  />
                  <Input
                    register={register}
                    classes={"col-md-6"}
                    label={t(
                      "filters.by-name"
                    )}
                    type={"text"}
                    name={"name"}
                    id={"name"}
                  />
                  <div className="col-md-6">
                    <div className="form-control">
                      <label htmlFor="city" className="form-control__label">{t('account.form.address.city') || ''}</label>
                      <div className="form-control__input">
                        <CityDatalist control={control} name="city" defaultValue={getValues('city')}/>
                      </div>
                    </div>
                  </div>
                  <ProductDalalist classes="col-md-6"
                                   id="product"
                                   name="product"
                                   label={t('account.form.pledge.sidebar.product')}
                                   control={control}
                                   defaultValue={getValues('product')}
                  />
                  <MultiSelectCustom
                    id="status"
                    name="status"
                    classes="col-md-6"
                    control={control}
                    label={t("partner.form.bloc-status.status")}
                    options={referential?.partner.status || []}
                    customOnChange={ (options) => {setValue('status', options)}}
                  />
                  <MultiSelectCustom
                    id="type"
                    name="type"
                    classes="col-md-6"
                    control={control}
                    label={t("partner.form.bloc-general-information.type")}
                    options={referential?.partner.partner_type || []}
                    customOnChange={ (options) => {setValue('type', options)}}
                  />
                  <MultiSelectCustom
                    id="platform"
                    name="platform"
                    classes="col-md-6"
                    control={control}
                    label={t("partner.form.bloc-general-information.platform")}
                    options={referential?.partner.platform || []}
                    customOnChange={ (options) => {setValue('platform', options)}}
                  />
                  <MultiSelectCustom
                    id="network"
                    name="network"
                    classes="col-md-6"
                    control={control}
                    label={t("partner.form.bloc-general-information.network")}
                    options={referential?.partner.network || []}
                    customOnChange={ (options) => { setValue('network', options)}}
                  />
                </div>
              </div>
              <footer className="sidebar__footer">
                <button type="submit" className="button button--ink-2 u-mrm">
                  {t("common.save")}
                </button>
                <button
                  type="button"
                  className="button button--ink-2 button--ink-2--outline"
                  onClick={() => handleClose()}
                >
                  {t("common.cancel")}
                </button>
              </footer>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default DatalistFilter;
