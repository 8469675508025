import {Presenter} from '../Presenter'

class NumberPresenter extends Presenter {

  private useCase: { defaultUseCase: any }

  constructor(defaultUseCase: any) {
    super({
      viewModel: {
        type: 'number',
        title: '',
        description: '',
        data: 0,
        color: ''
      }
    });

    this.useCase = {
      defaultUseCase
    }
  }

  load() {
    try {
      this._setData(this.useCase.defaultUseCase);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setData(response: {
    title: string,
    type: string,
    description: '',
    data: number,
    color: string,
  }) {
    this.update({
      type: 'number',
      title: response.title,
      description: response.description,
      data: response.data,
      color: response.color
    });
  }
}

export default NumberPresenter
