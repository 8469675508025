import {Investor} from './Investor'
import {Recurrence} from './Recurrence'
import {Product} from '../Product/Product'
import {User} from "../User/User";

interface PreSubscriptionInterface {
  id: string|null
  createdAt: string|null
  updatedAt: string|null
  subscribedAt: string|null
  deletedAt: string|null
  engagedAt: string|null
  canceledAt: string|null
  verifiedLevel1At: string|null
  verifiedLevel2At: string|null
  confirmedAt: string|null
  validationScheduledAt: string|null
  executedAt: string|null
  minor: boolean
  guardianship: boolean
  notFrench: boolean
  ppe: boolean
  ter: boolean
  recurrent: boolean
  dividendReinvestment: boolean
  counterpartyResearch: boolean
  dateStartMandat: string|null
  dateEndMandat: string|null
  duration: number|null
  lifeAnnuity: boolean
  shareCount: number|null
  sharePrice: number|null
  totalAmount: number|null
  commissionStatus: string|null
  status: string|null
  propertyType: string|null
  possessionMode: string|null
  subscriptionCode: string|null
  investors: Investor[]|null
  recurrence: Recurrence|null
  product: Product|null
  tags: []
  investment50k: boolean
  status_label: string|null
  horodatage: string|null
  transactionCode: string|null
  user: User|null
  lastUpdateUser: User|null
  actionReason: string|null
  comment: string|null
  note: string|null
  netassetDate?: string
  totalNetAmount?: number|null
}

class PreSubscription implements PreSubscriptionInterface {
  public id: string|null
  public createdAt: string|null
  public updatedAt: string|null
  public subscribedAt: string|null
  public deletedAt: string|null
  public engagedAt: string|null
  public canceledAt: string|null
  public verifiedLevel1At: string|null
  public verifiedLevel2At: string|null
  public confirmedAt: string|null
  public validationScheduledAt: string|null
  public executedAt: string|null
  public minor: boolean
  public guardianship: boolean
  public notFrench: boolean
  public ppe: boolean
  public ter: boolean
  public recurrent: boolean
  public dividendReinvestment: boolean
  public counterpartyResearch: boolean
  public dateStartMandat: string|null
  public dateEndMandat: string|null
  public duration: number|null
  public lifeAnnuity: boolean
  public shareCount: number|null
  public sharePrice: number|null
  public totalAmount: number|null
  public commissionStatus: string|null
  public status: string|null
  public propertyType: string|null
  public possessionMode: string|null
  public subscriptionCode: string|null
  public investors: Investor[]|null
  public recurrence: Recurrence|null
  public product: Product|null
  public tags: []
  public investment50k: boolean
  public status_label: string|null
  public horodatage: string|null
  public transactionCode: string|null
  public user: User|null
  public lastUpdateUser: User|null
  public actionReason: string|null
  public comment: string|null
  public note: string|null
  public netassetDate?: string
  public totalNetAmount?: number | null

  constructor(
    id: string|null,
    createdAt: string|null,
    updatedAt: string|null,
    subscribedAt: string|null,
    deletedAt: string|null,
    engagedAt: string|null,
    canceledAt: string|null,
    verifiedLevel1At: string|null,
    verifiedLevel2At: string|null,
    confirmedAt: string|null,
    validationScheduledAt: string|null,
    executedAt: string|null,
    minor: boolean,
    guardianship: boolean,
    notFrench: boolean,
    ppe: boolean,
    ter: boolean,
    recurrent: boolean,
    dividendReinvestment: boolean,
    counterpartyResearch: boolean,
    dateStartMandat: string|null,
    dateEndMandat: string|null,
    duration: number|null,
    lifeAnnuity: boolean,
    shareCount: number|null,
    sharePrice: number|null,
    totalAmount: number|null,
    commissionStatus: string |null,
    status: string|null,
    propertyType: string|null,
    possessionMode: string|null,
    subscriptionCode: string|null,
    investors: Investor[]|null,
    recurrence: Recurrence|null,
    product: Product|null,
    tags: [],
    investment50k: boolean,
    status_label: string|null,
    horodatage: string|null,
    transactionCode: string|null,
    user: User|null,
    lastUpdateUser: User|null,
    actionReason: string|null,
    comment: string|null,
    note: string|null,
    netassetDate?: string,
    totalNetAmount?: number | null
  ) {
    this.id = id
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.subscribedAt = subscribedAt
    this.deletedAt = deletedAt
    this.engagedAt = engagedAt
    this.canceledAt = canceledAt
    this.verifiedLevel1At = verifiedLevel1At
    this.verifiedLevel2At = verifiedLevel2At
    this.confirmedAt = confirmedAt
    this.validationScheduledAt = validationScheduledAt
    this.executedAt = executedAt
    this.minor = minor
    this.guardianship = guardianship
    this.notFrench = notFrench
    this.ppe = ppe
    this.ter = ter
    this.recurrent = recurrent
    this.dividendReinvestment = dividendReinvestment
    this.counterpartyResearch = counterpartyResearch
    this.dateStartMandat = dateStartMandat
    this.dateEndMandat = dateEndMandat
    this.duration = duration
    this.lifeAnnuity = lifeAnnuity
    this.shareCount = shareCount
    this.sharePrice = sharePrice
    this.totalAmount = totalAmount
    this.commissionStatus = commissionStatus
    this.status = status
    this.propertyType = propertyType
    this.possessionMode = possessionMode
    this.subscriptionCode = subscriptionCode
    this.investors = investors
    this.recurrence = recurrence
    this.product = product
    this.tags = tags
    this.investment50k = investment50k
    this.status_label = status_label
    this.horodatage = horodatage
    this.transactionCode = transactionCode
    this.user = user
    this.lastUpdateUser = user
    this.actionReason = actionReason
    this.comment = comment
    this.note = note
    this.netassetDate = netassetDate
    this.totalNetAmount = totalNetAmount
  }
}

export {PreSubscription}
export type {PreSubscriptionInterface}
