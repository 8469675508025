import {Movement} from "./Movement";
import {Investor} from "../PreSubscription/Investor";
import {Product} from "../Product/Product";
import {SliceListInterface} from '../Share/Slice/SliceList'
import {User} from "../User/User";

interface RedemptionWithdrawalInterface {
  allShares: boolean
  valueDate: string|null
  penalty: number|null
  flatFee: number|null
  capitalGain: number|null
  withdrawalAmount: number|null
  investorBank: {
    amount: number|null
    iban: string|null
    bank: string|null
    swift: string|null
  }|null
  lenderBank: {
    amount: number|null
    iban: string|null
    bank: string|null
    swift: string|null
  }|null
  slices: SliceListInterface[]
  slicesDetail: SliceListInterface[]
  subType: string|null
  transactionExecutionAt: string|null
}

class RedemptionWithdrawal extends Movement implements RedemptionWithdrawalInterface {
  allShares: boolean
  valueDate: string|null
  penalty: number|null
  flatFee: number|null
  capitalGain: number|null
  withdrawalAmount: number|null
  investorBank: {
    amount: number|null
    iban: string|null
    bank: string|null
    swift: string|null
  }|null
  lenderBank: {
    amount: number|null
    iban: string|null
    bank: string|null
    swift: string|null
  }|null
  slices: SliceListInterface[]
  slicesDetail: SliceListInterface[]

  constructor(
    id: string|null,
    createdAt: string|null,
    updatedAt: string|null,
    subscribedAt: string|null,
    deletedAt: string|null,
    engagedAt: string|null,
    canceledAt: string|null,
    verifiedLevel1At: string|null,
    verifiedLevel2At: string|null,
    confirmedAt: string|null,
    validationScheduledAt: string|null,
    executedAt: string|null,
    shareCount: number|null,
    sharePrice: number|null,
    totalAmount: number|null,
    status: string|null,
    propertyType: string|null,
    possessionMode: string|null,
    subscriptionCode: string|null,
    investors: Investor[]|null,
    product: Product|null,
    allShares: boolean,
    valueDate: string|null,
    penalty: number|null,
    flatFee: number|null,
    capitalGain: number|null,
    withdrawalAmount: number|null,
    investorBank: {
      amount: number|null
      iban: string|null
      bank: string|null
      swift: string|null
    }|null,
    lenderBank: {
      amount: number|null
      iban: string|null
      bank: string|null
      swift: string|null
    }|null,
    slices: SliceListInterface[],
    slicesDetail: SliceListInterface[],
    transactionCode: string|null,
    tags: [],
    status_label: string|null,
    horodatage: string|null,
    user : User|null,
    lastUpdateUser: User|null,
    actionReason: string|null,
    comment: string|null,
    subType: string|null,
    transactionExecutionAt: string|null,
    note: string|null
) {
    super(
      id,
      createdAt,
      updatedAt,
      subscribedAt,
      deletedAt,
      engagedAt,
      canceledAt,
      verifiedLevel1At,
      verifiedLevel2At,
      confirmedAt,
      validationScheduledAt,
      executedAt,
      shareCount,
      sharePrice,
      totalAmount,
      status,
      propertyType,
      possessionMode,
      subscriptionCode,
      investors,
      product,
      transactionCode,
      tags,
      status_label,
      horodatage,
      user,
      lastUpdateUser,
      actionReason,
      comment,
      subType,
      transactionExecutionAt,
      note
  );

    this.allShares = allShares
    this.valueDate = valueDate
    this.penalty = penalty
    this.flatFee = flatFee
    this.capitalGain = capitalGain
    this.withdrawalAmount = withdrawalAmount
    this.investorBank = investorBank
    this.lenderBank = lenderBank
    this.slices = slices
    this.slicesDetail = slicesDetail
    this.status_label = status_label
  }
}

export {RedemptionWithdrawal}
export type {RedemptionWithdrawalInterface}
