import envVariable from "../presentation/util/envVariable";
import Authentication from "./Authentication";
import {FunctionComponent} from "react";
import Caller from "../../domain/Api/Caller";
import {useLocation} from 'react-router-dom';

const Authorization: FunctionComponent = () => {
  const location = useLocation();
  const code = new URLSearchParams(location.search).get("code");

  Authentication.login(code).then(
    async function success(greatAccess) {
      if (greatAccess) {
        window.location.pathname = envVariable('REACT_APP_REDIRECT_URL');
      }
    }
  )
  return (
    <></>
  );
};

export default Authorization
