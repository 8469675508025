import React, {FunctionComponent, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {SubmitHandler, useForm, useFormState} from 'react-hook-form';

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';
import {useAppDispatch, useAppSelector} from '../../../../store/hook';
import {setOpenAccountAddressFormEvent} from '../../../../store/component/event';
import {editCurrentAddress, editCurrentFiscalAddress} from '../../../../store/component/customer';
import {
  editCurrentAddress as editCurrentProspectAddress,
  editCurrentFiscalAddress as editCurrentProspectFiscalAddress
} from '../../../../store/component/prospect';
import blockScrollBody from '../../../util/BlockScroll';
import getClassForOverlay from '../../../util/Sidebar';
import dayjs from 'dayjs';
import {confirmAlert} from 'react-confirm-alert';
import ConfirmationModal from '../../Modal/ConfirmationModal';
import {toastSuccess} from '../../../util/Toast';
import {usePrompt} from '../../../util/Navigation';
import AddressBlock from "../../Block/AddressBlock";
import {AddressInterface} from "../../../../../domain/Address/Address";
import {GeneralAddressInterface, GENERAL_ADDRESS_STATUS_ACTIVE} from "../../../../../domain/Address/GeneralAddress";

interface IFormInput {
  sideBarAddress: {
    address: AddressInterface,
    status: string|null
  }
}

type Props = {
  typeAccount: "customer" | "prospect"
}

const AddAccountAddressForm: FunctionComponent<Props> = ({typeAccount}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openAccountAddressForm = useAppSelector((state) => state.event.openAccountAddressForm)

  const {
    register,
    watch,
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: {errors}
  } = useForm<IFormInput>({
    defaultValues: {
      sideBarAddress: {
        address: {
          id: null,
          createdAt: "",
          updatedAt: "",
          deletedAt: "",
          title: "",
          firstname: "",
          lastname: "",
          maidenname: "",
          address: "",
          address2: "",
          address3: "",
          postcode: "",
          city: null,
          country: null,
          npai: false,
          npaiAt: "",
          socialReason: "",
        },
        status: GENERAL_ADDRESS_STATUS_ACTIVE
      }
    }
  })
  const watchPostCode = watch('sideBarAddress.address.postcode')
  const watchCity = watch('sideBarAddress.address.city')
  const watchCountry = watch('sideBarAddress.address.country')

  useEffect(() => {
    blockScrollBody(openAccountAddressForm.show)
    if (openAccountAddressForm.defaultCountry && openAccountAddressForm.defaultUserInfo) {
      setValue("sideBarAddress.address.id", null)
      setValue("sideBarAddress.address.title", openAccountAddressForm.defaultUserInfo?.title || "")
      setValue("sideBarAddress.address.firstname", openAccountAddressForm.defaultUserInfo?.firstname || "")
      setValue("sideBarAddress.address.lastname", openAccountAddressForm.defaultUserInfo?.lastname || "")
      setValue("sideBarAddress.address.country", openAccountAddressForm.defaultCountry || null)
      setValue("sideBarAddress.address.postcode", openAccountAddressForm.defaultPostcode || null)
      setValue("sideBarAddress.address.socialReason", openAccountAddressForm.defaultUserInfo?.socialReason || "")
      setValue("sideBarAddress.address.address", openAccountAddressForm.defaultAddress || "")
      setValue("sideBarAddress.address.address2", openAccountAddressForm.defaultAddress2 || "")
      setValue("sideBarAddress.address.address3", openAccountAddressForm.defaultAddress3 || "")
      setValue("sideBarAddress.address.city", openAccountAddressForm.defaultCity || null)
    }
  }, [openAccountAddressForm])

  useEffect(() => {
    if (!watchCountry) {
      setValue('sideBarAddress.address.city', null)
      setValue('sideBarAddress.address.postcode', null)
    }
  }, [watchCountry])

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    console.log('data sidebar', data)
    if (typeAccount === "customer") {
      if (openAccountAddressForm.typeAddress === "postal") {
        dispatch(editCurrentAddress(data.sideBarAddress))
      } else {
        dispatch(editCurrentFiscalAddress(data.sideBarAddress))
      }
    }
    if (typeAccount === "prospect") {
      if (openAccountAddressForm.typeAddress === "postal") {
        dispatch(editCurrentProspectAddress(data.sideBarAddress))
      } else {
        dispatch(editCurrentProspectFiscalAddress(data.sideBarAddress))
      }
    }

    reset()
    toastSuccess(t('account.notify.add-CustomerAddress-success'))
    handleClose()
  }

  const {isDirty} = useFormState({control});
  usePrompt(isDirty, handleSubmit(onConfirm));

  function handleClose() {
    dispatch(setOpenAccountAddressFormEvent({show: false, customerAddress: null}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openAccountAddressForm.show)}`} onClick={() => handleClose()}/>
      <div className={`sidebar sidebar--right sidebar--right ${openAccountAddressForm.show ? 'sidebar--active' : ''}`}>
        <form onSubmit={handleSubmit(onSubmit)} className="form-bloc form-bloc--customer-customerAddress-form">
          <div className="sidebar__content">
            <div className="title">
              {t(`account.form.address.${openAccountAddressForm.typeAddress === "postal" ? "add" : "add-fiscal"}`)}
            </div>
            <AddressBlock register={register}
                          control={control}
                          getValues={getValues}
                          setValue={setValue}
                          typeClient={openAccountAddressForm.defaultUserInfo?.type || null}
                          watchPostCode={watchPostCode}
                          watchCity={watchCity}
                          watchCountry={watchCountry}
                          parentPath={'sideBarAddress'}
                          isInSidebar
                          typeAddress={"postal"}
                          errors={errors}
            />
          </div>
          <footer className="sidebar__footer">
            <button type="submit" className="button button--ink-2 u-mrm">{t('common.save')}</button>
            <button type="button" className="button button--ink-2 button--ink-2--outline"
                    onClick={() => handleClose()}>{t('common.cancel')}</button>
          </footer>
        </form>
      </div>
    </>
  );
}

export default AddAccountAddressForm
