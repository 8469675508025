import React, {FunctionComponent} from 'react';
import {Link} from "react-router-dom";
import {v4 as uuidV4} from "uuid";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {ChoiceTypeInterface} from "../../../../domain/ChoiceType/ChoiceType";

type Props = {
  types: ChoiceTypeInterface[]
}
const ChoiceTypes: FunctionComponent<Props> = ({types}) => {
  const {t} = useTranslation()

  return (
    <div className="product-choice__wrapper">
      <div className="product-choice">
        {types.map((item: { label: string, url: string, img?: string }) =>
          <LinkStyled key={uuidV4()}
                      to={`/${t(item.url)}`}
                      img={item?.img}
                      className="button button--choice button--choice--outline"
          >
            {item.label}
          </LinkStyled>
        )}
      </div>
    </div>
  );
}

const LinkStyled = styled(Link)<{ img?: string }>`
  display: flex;
  position: relative;
  height: 133px;
  width: 180px;
  align-items: center;
  justify-content: center;
  &::after {
    content: ${({img}) => img && `url(${img})`};
    position: absolute;
    top: 70%;
    left: 75%;
    transform: translate(-50%, -50%);
  }
`

export default ChoiceTypes;