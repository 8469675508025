import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm} from 'react-hook-form'
import {v4 as uuidV4} from 'uuid'

import {useAppDispatch} from '../../../store/hook'
import {setCurrentRelationSelect} from '../../../store/component/prospect'

import {FilterInterface, PersonListInterface} from '../../../../domain/Person/PersonList'
import {Relation} from '../../../../domain/Prospect/Relation'

import Pagination from '../Pagination/Pagination'
import iconHeadingSearch from '../../../../assets/images/icons/datalist-heading-search.svg'
import iconSearch from '../../../../assets/images/icons/datalist-search.svg'
import iconsAdd from '../../../../assets/images/icons/add.svg'
import enabledIcon from '../../../../assets/images/icons/enabled.svg'
import disabledIcon from '../../../../assets/images/icons/disabled.svg'

import '../../../../assets/styles/components/_datalist.scss'
import PersonGateway from "../../../../gateway/Person/PersonGateway";
import PersonListPresenter from "../../../../presenter/Person/PersonListPresenter";
import {saveNbRowsInLocalStorage} from "../../util/SavePreferencesInLocalStorage";
import {optionsNbRows} from "../../../../fixtures/Referentiel";
import SelectCustom from "../Elements/Select";
import {SortInterface, SortOrder} from "../../../../domain/Utils/List";
import ProspectGateway from "../../../../gateway/Prospect/ProspectGateway";
import iconChevron from "../../../../assets/images/icons/chevron.svg";
import iconChevronSelected from "../../../../assets/images/icons/chevronSelected.svg";

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

const ProspectDatalistForAddRelation: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [filter, setFilter] = useState<FilterInterface|null>(null)
  const [persons, setPersons] = useState<{data: PersonListInterface[]|null, numberOfItems: number}|null>(null)
  const controller = new AbortController()
  const preferences = localStorage.getItem("preferences")

  const { register, handleSubmit, setValue, watch } = useForm()

  const watchNumberRows = watch('numberRows')

  useEffect(() => {
    const presenter = new PersonListPresenter(persons);
    presenter.load().then(() => {
      setViewModel(presenter.immutableViewModel())
    })
  }, [persons])

  useEffect(() => {
    if(!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      new PersonGateway().getList(
        currentPage,
        watchNumberRows || 50,
        filter,
        controller.signal,
        sortOrder.sortLabel ?? '',
        sortOrder.sortOrder
      ).then(response => {
        setPersons(response)
      })
    }

  }, [currentPage, filter, watchNumberRows])

  const onSubmit: SubmitHandler<FilterInterface> = data => {
    const filterObject = {
      lastname: data.lastname,
      firstname: data.firstname
    }

    setFilter(filterObject)
  }

  const paginate = (pageNumber:number) => {controller.abort(); setCurrentPage(pageNumber)}

  function selectProspect(person: PersonListInterface) {
    const relation = new Relation(null, person.id, person.lastname, person.firstname, '')
    dispatch(setCurrentRelationSelect(relation))
  }

  function resetSearch() {
    setFilter(null)
    viewModel?.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => {
      setValue(filter.field, null)
    })
    setCurrentPage(1)
  }
  function defineSortOrder(sort: string|undefined): SortOrder {
    if (sortOrder.sortLabel !== sort) return SortOrder.ASC
    return sortOrder.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC
  }

  function sortData(sort: string|undefined) {
    if(sort) {
      const order: SortInterface = {
        sortLabel: sort,
        sortOrder: defineSortOrder(sort)
      };
      setSortOrder(order);
      new PersonGateway().getList(
          currentPage,
          watchNumberRows || 50,
          filter,
          controller.signal,
          sort,
          order.sortOrder
      ).then(response => {
        setPersons(response)
      })
    }
  }

  return (<>
    {(viewModel !== null &&
      <>
      <div className={`datalist`}>
        <div className="datalist__title">{t(viewModel.title)}</div>
        <div className="datalist__header">
          <form className="filter">
            <div className="filter__input">
              {viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                <div key={uuidV4()} className="input-no-border">
                  <img src={iconHeadingSearch} alt="" />
                  <input {...register(filter.field)} placeholder={t('common.search-by', {keyword: t(filter.keyword)})} className="u-mxs"/>
                </div>
              ))}
            </div>
            <div className="filter__actions">
              <button type="submit" onClick={handleSubmit(onSubmit)} className="button button--submit">{t('search.submit')}</button>
              <button type="reset" onClick={() => resetSearch()} className="button button--white">{t('search.cancel')}</button>
            </div>
          </form>
          {/*<HeaderRight numberOfActivatedFilters={22} />*/}
        </div>
        <SelectCustom classes="flex justify-end u-mbs" id="numberRows"
                      name="numberRows"
                      label={t('filters.display-results-by')}
                      options={optionsNbRows}
                      register={register}
                      noChoiceOption
        />
        <div className="table-fix-head">
        <table className="datalist__datas">
          <thead>
          <tr>
            {viewModel.heading.map((title: { name: string, sort?: string }) => (
                <th key={uuidV4()} className={title.sort ? 'sortable' : ''} onClick={()=>sortData(title.sort)}>
                  {t(title.name)}
                  {title.sort && sortOrder.sortLabel !== title.sort &&
                      <>
                          <img src={iconChevron} className="chevron-top" alt="chevron-top" />
                          <img src={iconChevron} className="chevron-bottom" alt="chevron-bottom" />
                      </>
                  }
                  {title.sort && sortOrder.sortLabel === title.sort && sortOrder.sortOrder === SortOrder.ASC &&
                      <>
                          <img src={iconChevronSelected} className="chevron-bottom--selected" alt="chevron-bottom" />
                      </>
                  }
                  {title.sort && sortOrder.sortLabel === title.sort && sortOrder.sortOrder === SortOrder.DESC &&
                      <>
                          <img src={iconChevronSelected} className="chevron-top--selected" alt="chevron-top" />
                      </>
                  }
                </th>
            ))}
          </tr>
          </thead>
          <tbody>
          {viewModel.data !== undefined &&
            viewModel.data.map((item: PersonListInterface) => (
            <tr key={uuidV4()}>
              {item !== null && item !== undefined &&
                <>
                  <td>
                    <button type="button" className="button-reset" onClick={() => selectProspect(item)}>
                      <img src={iconsAdd} alt="" />
                    </button>
                  </td>
                  {Object.entries(item).map((obj) => {
                    if (obj[0] === 'enabled') {
                      const img = (obj[1] === '1') ? <img src={enabledIcon} alt="" /> : <img src={disabledIcon} alt="" />
                      return <td key={uuidV4()}>{img}</td>
                    }

                    if (obj[0] !== 'id') {
                      return <td key={uuidV4()}>{obj[1]}</td>
                    }
                  })}
                </>
              }
            </tr>
          ))}
          </tbody>
        </table>
        </div>
      </div>
      <Pagination currentPage={currentPage} itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage} numberOfItems={viewModel.pagination.numberOfItems} callback={paginate} />
      </>
    )}
    </>
  )
}

export default ProspectDatalistForAddRelation
