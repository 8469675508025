interface ProductBankInformationInterface {
  id: string|null
  createdAt: string|null
  updatedAt: string|null
  deletedAt: string|null
  type: string|null
  title: string|null
  bank: string|null
  iban: string|null
  swift: string|null
}

class ProductBankInformation implements ProductBankInformationInterface {
  public id: string|null
  public createdAt: string|null
  public updatedAt: string|null
  public deletedAt: string|null
  public type: string|null
  public title: string|null
  public bank: string|null
  public iban: string|null
  public swift: string|null

  constructor(
    id: string|null,
    createdAt: string|null,
    updatedAt: string|null,
    deletedAt: string|null,
    type: string|null,
    title: string|null,
    bank: string|null,
    iban: string|null,
    swift: string|null
  ) {
    this.id = id
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.deletedAt = deletedAt
    this.type = type
    this.title = title
    this.bank = bank
    this.iban = iban
    this.swift = swift
  }
}

export {ProductBankInformation}
export type {ProductBankInformationInterface}
