import Caller from "../../../domain/Api/Caller";
import envVariable from "../../../infrastructure/presentation/util/envVariable";
import {NetAssetValueItem, UpdateNetAssetValueItem} from "../../../domain/Product/NetAssetValueItem";
import {toastError} from "../../../infrastructure/presentation/util/Toast";

export default class NetAssetGateway {
  getList(
    productId: string,
    currentPage: number,
    itemsPerPage: number,
    signal?: AbortSignal,
    sort?: string,
    order?: string,
  ): Promise<{data: NetAssetValueItem[]|null, numberOfItems: number}|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/products/${productId}/netassets?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`,
      {},
      signal
    ).then(data => {
      if (data) {
        return data
      }

      return null
    }).catch(() => {
      return {
        data: [],
        numberOfItems: 0
      }
    });
  }

  add(productId: string, netAsset: NetAssetValueItem): Promise<NetAssetValueItem|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/products/${productId}/netassets`,
      netAsset,
    ).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    }).catch((reason: any) => {
      toastError(reason.messageApi)
    });
  }

  update(productId: string, netAsset: UpdateNetAssetValueItem): Promise<NetAssetValueItem|null> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/products/${productId}/netassets/${netAsset.id}`,
      netAsset,
    ).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    }).catch((reason: any) => {
      toastError(reason.messageApi)
    });
  }

  delete(netAssetId: string): Promise<any> {
    return Caller.executeDelete(`${envVariable('REACT_APP_API_URL')}/netassets/${netAssetId}`,
      {},
    ).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    }).catch((reason: any) => {
      toastError(reason.messageApi)
    });
  }
}
