import MovementGateway from "../../../gateway/Movement/MovementGateway";
import {MovementInterface} from "../../../domain/Movement/Movement";
import PreSubscriptionGateway from "../../../gateway/PreSubscription/PreSubscriptionGateway";
import {PreSubscriptionInterface} from "../../../domain/PreSubscription/PreSubscription";
import RedemptionWithdrawalGateway from "../../../gateway/Movement/RedemptionWithrawal/RedemptionWithdrawalGateway";
import {RedemptionWithdrawal, RedemptionWithdrawalInterface} from "../../../domain/Movement/RedemptionWithdrawal";
import AgreementGateway from "../../../gateway/Movement/Agreement/AgreementGateway";
import {Agreement} from "../../../domain/Movement/Agreement";

export default class GetUseCase
{
  public agreementGateway

  constructor(
    agreementGateway: AgreementGateway
  ) {
    this.agreementGateway = agreementGateway
  }

  async execute(id: string): Promise<Agreement> {
    return await this.agreementGateway.get(id).then(response => {

      if (response && response.investors) {
        response.investors.sort(function (investor) {
          if (investor.type === "transferor") {
            return -1
          }
          if (investor.type === "investor") {
            return 1
          }

          return 0
        })
      }
      return response
    })
  }
}
