import React, {FunctionComponent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {v4 as uuidV4} from 'uuid';
import '../../../../assets/styles/components/_datalist.scss';
import {ReferentielInterface} from '../../../../domain/Referentiel/ReferentielInterface';
import iconSearch from '../../../../assets/images/icons/datalist-search.svg';
import iconEsnEntry from '../../../../assets/images/icons/type-mouvement-entree.svg';
import iconEsnExit from '../../../../assets/images/icons/type-mouvement-sortie.svg';
import iconEsnNew from '../../../../assets/images/icons/type-mouvement-nouvelle-part.svg';
import PortfolioTransfertListPresenter from '../../../../presenter/Partner/PortfolioTransfertPresenter';
import Pagination from '../Pagination/Pagination';
import SelectCustom from '../Elements/Select';
import {optionsNbRows} from '../../../../fixtures/Referentiel';
import {useForm} from "react-hook-form";
import {IPagination} from "../../../../domain/Pagination/IPagination";
import {saveNbRowsInLocalStorage} from "../../util/SavePreferencesInLocalStorage";
import iconEsnTransferPlus from "../../../../assets/images/icons/type-mouvement-transfer+.svg";
import iconEsnTransferMinus from "../../../../assets/images/icons/type-mouvement-transfer-.svg";
import PartnerGateway from "../../../../gateway/Partner/PartnerGateway";
import download from "../../util/Download";
import {useAppSelector} from "../../../store/hook";

interface ViewModelInterface {
  title: string;
  heading: [];
  datas: [];
  filtersShortcut: [];
  filters: [];
  count: number;
}

type Props = {
  movementLinkedToPartner: any | null
  isRecap?: boolean
  pagination: IPagination
  setPagination: any
  noAction?: boolean
  partnerId: string | null
  transfertId: string | null
}

const PortfolioTransfertDatalist: FunctionComponent<Props> = (
  {
    movementLinkedToPartner,
    isRecap,
    pagination,
    setPagination,
    noAction,
    partnerId,
    transfertId
  },
) => {
  const {t} = useTranslation();

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)

  const [viewModel, setViewModel] = useState<ViewModelInterface | null>(null);
  const [recapView, setRecapView] = useState<boolean>(isRecap ?? false)
  const partnerRules = useAppSelector((state) => state.me.me?.rules.partner.actions)
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)

  const controller = new AbortController()
  const preferences = localStorage.getItem("preferences")

  const {watch, setValue, register} = useForm()

  const watchNumberRows = watch('numberRows')


  useEffect(() => {
    computeDisplayedData()
  }, [movementLinkedToPartner]);

  useEffect(() => {
    if (!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      setPagination({...pagination, itemPerPage: parseInt(watchNumberRows, 10)})
      saveNbRowsInLocalStorage(preferences, watchNumberRows.toString())
    }
  }, [watchNumberRows])

  const paginate = (pageNumber: number) => {
    controller.abort();
    setPagination({...pagination, page: pageNumber})
  };

  const computeDisplayedData = () => {
    const presenter = new PortfolioTransfertListPresenter(movementLinkedToPartner?.data ?? []);
    setViewModel(presenter.immutableViewModel());
  };

  const getUrlMovement = (movement: any, mode: string) => {
    let url = '';
    switch (movement.movement_type) {
      case 'withdrawal':
        url = t(`url.movements.${mode === 'read' ? 'read' : 'edit'}-redemption-withdrawal`);
        break;
      case 'agreement':
        url = t(`url.movements.${mode === 'read' ? 'read' : 'edit'}-gre-gre`);
        break;
      case 'mutation':
        url = t(`url.movements.${mode === 'read' ? 'read' : 'edit'}-mutations`);
        break;
      case 'subscription':
        url = t(`url.pre-subscription.${mode === 'read' ? 'read' : 'edit'}`);
        break;
      case 'dismemberment':
        url = t(`url.movements.${mode === 'read' ? 'read' : 'edit'}-dismemberment`);
        break;
      case 'foo5':
        url = t('url.movements.edit-land-consolidation');
        break;
    }

    if (movement.type === 'subscription' && movement.type === 'dismemberment') {
      url = t(`url.movements.${mode === 'read' ? 'read' : 'edit'}-dismemberment`);
    }

    return `/${url}/${movement.transaction_id}`;
  };

  const handlePartnerType = (partnerInvestor: string | null, partnerPropertyType: string) => {
    if (partnerPropertyType === 'full_property') {
      return 'PP';
    } else if (partnerPropertyType === 'usufruct') {
      return 'USU';
    } else if (partnerPropertyType === 'dismemberment' && partnerInvestor) {
      if (partnerInvestor === 'np') {
        return 'NP';
      } else if (partnerInvestor === 'usufruct') {
        return 'USU';
      }
    }
    return '';
  };

  function pictoFromEsnType(esnType: string | null) {
    if (esnType === 'E') {
      return <img src={iconEsnEntry} title="Entrée"/>;
    }
    if (esnType === 'S') {
      return <img src={iconEsnExit} title="Sortie"/>;
    }
    if (esnType === 'N') {
      return <img title="Nouvelle part" src={iconEsnNew}/>;
    }
    if(esnType === "T+") {
      return <img title="Transfert entrant" src={iconEsnTransferPlus} />
    }
    if(esnType === "T-") {
      return <img title="Transfert sortant" src={iconEsnTransferMinus} />
    }
    return '';
  }

  const handleClickExport = () => {
    if (partnerId && transfertId) {
      setLoadingExport(true)
      new PartnerGateway().getTransferHistoryDetailsExport(partnerId, transfertId).then(response => {
        if (response) {
          download(t('export.partners'), response)
        }
        setLoadingExport(false)
      })
    }
  }

  return (<>
    {(viewModel !== null &&
      <>
        <div className={`datalist`}>
          {recapView && <div className="datalist__title">{t(viewModel.title)}</div> }
          <div className="datalist__header">
            <div className="filter"></div>
            <div className="header__right">
              <SelectCustom classes="flex justify-end u-mbs"
                            id="numberRows"
                            name="numberRows"
                            label={t('filters.display-results-by')}
                            options={optionsNbRows}
                            register={register}
                            noChoiceOption
              />
              {partnerId && transfertId && partnerRules?.export &&
                <>
                  {isLoadingExport ? <div className="loader"><div className="loader__dot"></div></div> :
                    <button type="button" className="button button--white" onClick={() => handleClickExport()}>{t('common.export-xls')}</button>
                  }
                </>
              }
            </div>
          </div>
          <div className="overflow-auto">
              <table className="datalist__datas">
                  <thead>
                  <tr>
                    {viewModel.heading.map((title: string) => {
                      if (noAction && title === "portfolio.heading.action") return null
                      return (
                        <th key={uuidV4()}>{t(title)}</th>
                      )
                    })}
                  </tr>
                  </thead>
                  <tbody>
                  {viewModel.datas !== undefined && viewModel.datas.map((item: any, index: number) => (
                    <tr key={uuidV4()}>
                      {!noAction && <td className="">
                        {item.transaction_id && <Link to={getUrlMovement(item, 'read')}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                        >
                            <button type="button" className="button-reset">
                                <img src={iconSearch} alt=""/>
                            </button>
                        </Link>}
                      </td>}
                      <td>{item.product_acronyme}</td>
                      <td>{item.movement_code}</td>
                      <td>
                        <div className="ceil-esn">
                          { pictoFromEsnType(item.esn) }
                          { item.transfer_type || item.transfer?.transfer_type && pictoFromEsnType(item?.transfer_type || item.transfer?.transfer_type || null) }
                        </div>
                      </td>
                      <td>
                        {referential?.wallet_subscription.property_type.find(property => {
                          return property.value === item.property_type;
                        })?.label.toString()}
                      </td>
                      <td>
                        {referential?.wallet_subscription.possession_mode.find(property => {
                          return property.value === item.possession_mode;
                        })?.label.toString()}
                      </td>
                      {/* <td>
                    {item.property_type ? handlePartnerType(item?.partner_investor, item.property_type) : ""}
                  </td> */}
                      <td>{item.movement_date}</td>
                      <td>{item.share_count}</td>
                      <td>{item.start}</td>
                      <td>{item.end}</td>
                      <td>{item.possession_at}</td>
                      <td>{item.payment_date}</td>
                      <td>{item.price}</td>
                      <td>{item?.investor?.code}</td>
                      <td>{item?.investor?.lastname}</td>
                      <td>{item?.usufruct?.code}</td>
                      <td>{item.dismemberment_rate}</td>
                      <td>{item.dismembermentEndat}</td>
                    </tr>
                  ))}
                  {viewModel.datas === undefined || viewModel.datas.length === 0 &&
                      <tr>
                          <td colSpan={viewModel.heading.length}>{t('common.data-is-empty')}</td>
                      </tr>
                  }
                  </tbody>
              </table>
          </div>
        </div>
        <Pagination currentPage={pagination.page}
                    itemsPerPage={pagination.itemPerPage}
                    numberOfItems={pagination.nbElements}
                    callback={paginate}
        />
      </>
      )}
    </>
  );
};

export default PortfolioTransfertDatalist;
