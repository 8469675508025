import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../store/hook";
import blockScrollBody from "../../util/BlockScroll";
import {setOpenDocumentList, setOpenUploadForm} from '../../../../infrastructure/store/component/event'
import getClassForOverlay from "../../util/Sidebar";
import {DocumentInterface as GedDocumentInterface} from "../../../../domain/Document/DocumentInterface";
import eyeIcon from '../../../../assets/images/icons/eye.svg'
import {SubmitHandler, useForm} from "react-hook-form";
import InputDate from "../Elements/InputDate";
import removeIcon from "../../../../assets/images/icons/remove.svg";
import {toastError, toastSuccess} from "../../util/Toast";
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../Modal/ConfirmationModal";

interface IFormInput {
  start: string
  end: string
}

const DocumentList: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const openDocumentList = useAppSelector((state) => state.event.openDocumentList)
  const [list, setList] = useState<GedDocumentInterface[]>([])
  const { register, control, handleSubmit, reset, watch, setValue, getValues } = useForm<IFormInput>()

  useEffect(() => {
    blockScrollBody(openDocumentList.show)
    setList(openDocumentList.documentList)
  }, [openDocumentList.show])

  const onSubmit: SubmitHandler<IFormInput> = data => {
    let listGed = openDocumentList.documentList

    if (data.start) {
      try {
        const start = new Date(data.start.replace(/(\d+[/])(\d+[/])/, '$2$1'))
        listGed = listGed?.filter((document: GedDocumentInterface) =>
          new Date(document.updatedAt.replace(/(\d+[/])(\d+[/])/, '$2$1')) >= start
        )
      } catch (e) {
        return
      }
    }

    if (data.end) {
      try {
        const end = new Date(data.end.replace(/(\d+[/])(\d+[/])/, '$2$1'))
        listGed = listGed?.filter((document: GedDocumentInterface) =>
          new Date(document.updatedAt.replace(/(\d+[/])(\d+[/])/, '$2$1')) <= end
        )
      } catch (e) {
        return
      }
    }

    setList(listGed)
  }

  function handleClose() {
    dispatch(setOpenDocumentList({show: false, documentList: []}))
  }

  function openDocument(id: number, title: string, ext?: string) {
    if (openDocumentList.openCallback) {
      openDocumentList.openCallback(id)
    } else if (openDocumentList.gateway) {
      openDocumentList.gateway.getFile(id).then((response: any) => {
        const file = window.open('')
        if (file !== null && response !== null) {
          b64ToBlob(response).then((b) => {
            const url = URL.createObjectURL(b);
            file.document.write(
              "<body style='margin:0px;padding:0px;overflow:hidden'><iframe id='iframe' width='100%' height='100%' style='border:0' src='"+url+"'></iframe></body>"
            )
            URL.revokeObjectURL(url)
          })
        }
      })
    }
  }

  const b64ToBlob = async (base64Data: string) => {
    return await (await fetch(base64Data)).blob();
  }

  const resetFilters = () => {
    reset()
    setList(openDocumentList.documentList)
  }

  function showDeleteFileConfirmation (document: GedDocumentInterface) {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => deleteFile(document)} onClose={onClose} message={t('common.document.modal.confirmation-message')}/>)
      }
    });
  }

  function deleteFile(document: GedDocumentInterface) {
    if (openDocumentList.gateway) {
      openDocumentList.gateway.deleteFile(document.id).then((deleted) => {
        if (deleted) {
          setList(list.filter((item) => item !== document))
          return toastSuccess(t('common.document.delete.success'));
        }

        return toastError(t('common.document.delete.error'));
      })
    }
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openDocumentList.show)}`} onClick={() => handleClose()} />
      <div className={`sidebar sidebar--right sidebar--right ${openDocumentList.show ? 'sidebar--active' : ''}`}>
        <div className="form-bloc form-bloc--prospect-address-form">
          <div className="sidebar__content">
            <div className="title">{t('common.document.list-document')}</div>
            <div dangerouslySetInnerHTML={{__html: t('common.document.list-document-message')}}></div>

            <div className="datalist">
              <div className="datalist__header">
                <form onSubmit={handleSubmit(onSubmit)} className="col-md-12">
                  <div className="form-bloc__form flex-container">
                    <InputDate id="start" label={t('common.document.date_start')}
                               name="start" classes="col-md-6" register={register} control={control}/>
                    <InputDate id="end" label={t('common.document.date_end')}
                               name="end" classes="col-md-6" register={register} control={control}/>
                  </div>
                  <div className="filter__actions">
                    <button type="submit" className="button button--submit">{t('search.submit')}</button>
                    <button type="button" className="button button--white" onClick={() => resetFilters()}>{t('search.cancel')}</button>
                  </div>
                </form>
              </div>
              <table className="datalist__datas">
                <thead>
                <tr>
                    <th>{t('common.document.date')}</th>
                    <th>{t('common.document.name')}</th>
                    <th>{t('common.multiple-action')}</th>
                </tr>
                </thead>
                <tbody>
                {list && list.map((document: GedDocumentInterface, index) => {
                  return <tr key={index}>
                    <td>{document.updatedAt}</td>
                    <td>{document.title}</td>
                    <td>
                      <button type="button" className="button-reset" onClick={() => openDocument(document.id, document.title, document.extension)}>
                        <img src={eyeIcon} alt=""/>
                      </button>
                      <button type="button"
                              className="button-reset"
                              onClick={() => showDeleteFileConfirmation(document)}
                      >
                        <img src={removeIcon} alt="delete"/>
                      </button>
                    </td>
                  </tr>
                })}
                </tbody>
              </table>
            </div>
          </div>
          <footer className="sidebar__footer">
            <button type="button" className="button button--ink-2 button--ink-2--outline"
                    onClick={() => handleClose()}>{t('common.cancel')}</button>
          </footer>
        </div>
      </div>
    </>
  )
}

export default DocumentList
