import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Colors, ColorsInterface} from "../../../domain/Settings/GeneralSettings/Colors";

interface settings {
  colors: ColorsInterface
}

const initialState: settings = {
  colors: {
    colorPrimary: '#04668C',
    colorSecondary: '#F26E22',
    colorFont: '#000000',
    colorBackground: '#FFFFFF',
    colorHeader: '#073359',
  },
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    setColors: (state, action: PayloadAction<Colors>) => {
      state.colors = action.payload

      document.documentElement.style.setProperty('--ink-1', state.colors.colorPrimary)
      document.documentElement.style.setProperty('--header-btn-intro-bg', state.colors.colorPrimary)
      document.documentElement.style.setProperty('--ink-2', state.colors.colorSecondary)
      document.documentElement.style.setProperty('--body-color', state.colors.colorFont)
      document.documentElement.style.setProperty('--light', state.colors.colorBackground)
      document.documentElement.style.setProperty('--subcolor-1', state.colors.colorHeader)
    },

    setColorPrimary: (state, action: PayloadAction<string>) => {
      document.documentElement.style.setProperty('--ink-1', action.payload)
      document.documentElement.style.setProperty('--header-btn-intro-bg', action.payload)
      state.colors.colorPrimary = action.payload
    },

    setColorSecondary: (state, action: PayloadAction<string>) => {
      document.documentElement.style.setProperty('--ink-2', action.payload)
      state.colors.colorSecondary = action.payload
    },

    setColorFont: (state, action: PayloadAction<string>) => {
      document.documentElement.style.setProperty('--body-color', action.payload)
      state.colors.colorFont = action.payload
    },

    setColorBackground: (state, action: PayloadAction<string>) => {
      document.documentElement.style.setProperty('--light', action.payload)
      state.colors.colorBackground = action.payload
    },

    setColorHeader: (state, action: PayloadAction<string>) => {
      document.documentElement.style.setProperty('--subcolor-1', action.payload)
      state.colors.colorHeader = action.payload
    },

  }
})

export const {
  setColors,
  setColorPrimary,
  setColorSecondary,
  setColorFont,
  setColorBackground,
  setColorHeader
} = settingsSlice.actions

export default settingsSlice.reducer
