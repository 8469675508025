import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {v4 as uuidV4} from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../store/hook'
import {setOpenDatalistFilterPartner} from '../../../store/component/event'
import {setCountTotal} from '../../../store/component/partner'
import {FilterPartnerInterface, PartnerListInterface} from '../../../../domain/Partner/PartnerList'
import PartnerListPresenter from '../../../../presenter/Partner/PartnerListPresenter'
import PartnerGateway from '../../../../gateway/Partner/PartnerGateway'
import Pagination from '../Pagination/Pagination'
import HeaderRight from './Element/HeaderRight'
import download from '../../util/Download'

import iconHeadingSearch from '../../../../assets/images/icons/datalist-heading-search.svg'
import iconEdit from '../../../../assets/images/icons/datalist-edit.svg'
import '../../../../assets/styles/components/_datalist.scss'
import {getIconForStatus} from '../../util/getState'
import iconAdd from "../../../../assets/images/icons/add.svg";
import iconSearch from "../../../../assets/images/icons/datalist-search.svg";
import {saveNbRowsInLocalStorage} from "../../util/SavePreferencesInLocalStorage";
import {optionsNbRows} from "../../../../fixtures/Referentiel";
import SelectCustom from "../Elements/Select";

import ReactTooltip from 'react-tooltip';
import { ReferentielInterface } from '../../../../domain/Referentiel/ReferentielInterface'
import {SortInterface, SortOrder} from "../../../../domain/Utils/List";
import MultiSelectCustom from "../Elements/MultiSelect";
import {PartnerInterface} from "../../../../domain/Partner/Partner";
import {ReferentielItemInterface} from "../../../../domain/Referentiel/ReferentielItemInterface";
import iconChevron from "../../../../assets/images/icons/chevron.svg";
import iconChevronSelected from "../../../../assets/images/icons/chevronSelected.svg";
import {CallerType} from "../../../../domain/Caller/Caller";

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

type Props = {
  callerType: CallerType
  callback?: (partner: PartnerListInterface) => void
  from?: "dashboard"
  minimumLevel?: string
  currentLevel?: string
  commissionRelations?: PartnerInterface[]
  excludeHimSelf?: boolean
  partnerId?: string
}

const PartnerDatalist: FunctionComponent<Props> = ({callerType,callback, minimumLevel, from, currentLevel, commissionRelations, excludeHimSelf, partnerId}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openDatalistFilterPartner = useAppSelector((state) => state.event.openDatalistFilterPartner)
  const partnerRules = useAppSelector((state) => state.me.me?.rules.partner.actions)
  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [partners, setPartners] = useState<{data: PartnerListInterface[]|null, numberOfItems: number}|null>(null)
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)
  const [clearMultiSelectValues, setClearMultiSelectValues] = useState<boolean>(false);

  const controller = new AbortController()
  const preferences = localStorage.getItem("preferences")

  const { register, handleSubmit, setValue, watch, getValues, control } = useForm()

  const watchNumberRows = watch('numberRows')

  useEffect(() => {
    dispatch(
      setOpenDatalistFilterPartner({
        ...openDatalistFilterPartner,
        filters: {
          ...openDatalistFilterPartner.filters,
          commissionLevel: from && from === "dashboard" ? "" : openDatalistFilterPartner.filters.commissionLevel
        },
      })
    );

  },[])

  useEffect(() => {
    const presenter = new PartnerListPresenter(partners);
    presenter.load().then(() => {
      setViewModel(presenter.immutableViewModel())
      dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems))
    })
  }, [partners])

  useEffect(() => {
    if(!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      new PartnerGateway().getList(
        callerType,
        currentPage,
        watchNumberRows || 50,
        openDatalistFilterPartner.filters,
        controller.signal,
        sortOrder.sortLabel ?? '',
        sortOrder.sortOrder
      ).then(response => {
        setPartners(response)
      })

      setValue('idPartner', openDatalistFilterPartner.filters.idPartner)
      setValue('keywords', openDatalistFilterPartner.filters.keywords)
      setValue('socialReason', openDatalistFilterPartner.filters.socialReason)
      setValue('name', openDatalistFilterPartner.filters.name)
      setValue('city', openDatalistFilterPartner.filters.city)
      setValue('product', openDatalistFilterPartner.filters.product)
      setValue('status', openDatalistFilterPartner.filters.status)
      setValue('type', openDatalistFilterPartner.filters.type)
      setValue('platform', openDatalistFilterPartner.filters.platform)
      setValue('network', openDatalistFilterPartner.filters.network)
      setValue('commissionLevel', openDatalistFilterPartner.filters.commissionLevel)
    }

  }, [currentPage, openDatalistFilterPartner.filters, watchNumberRows])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterPartner.filters])

  const onSubmit: SubmitHandler<FilterPartnerInterface> = data => {
    setClearMultiSelectValues(false)
    dispatch(setOpenDatalistFilterPartner({show: false, count: openDatalistFilterPartner.count, filters: {
        idPartner: data.idPartner ?? openDatalistFilterPartner.filters.idPartner,
        keywords: data.keywords ?? openDatalistFilterPartner.filters.keywords,
        socialReason: openDatalistFilterPartner.filters.socialReason,
        name: data.name ?? openDatalistFilterPartner.filters.name,
        city: openDatalistFilterPartner.filters.city,
        product: openDatalistFilterPartner.filters.product,
        status: data.status,
        type: openDatalistFilterPartner.filters.type,
        platform: openDatalistFilterPartner.filters.platform,
        network: openDatalistFilterPartner.filters.network,
        commissionLevel: data.commissionLevel
    }}))
  }

  const paginate = (pageNumber:number) => {controller.abort(); setCurrentPage(pageNumber)}

  const handleClickFilter = (response: string) => {
    if (response) {
      let minimumLevelSup = ""

      if(minimumLevel) {
        const levelWordSplit = minimumLevel?.split("_")
        const oneLevelAdded = parseInt(levelWordSplit[1], 10) + 1
        levelWordSplit[1] = oneLevelAdded.toString()
        minimumLevelSup = levelWordSplit.join("_")
      }
      dispatch(setOpenDatalistFilterPartner({show: true, count: openDatalistFilterPartner.count, filters:
          {
            idPartner: getValues('idPartner') ?? openDatalistFilterPartner.filters.idPartner,
            keywords: getValues('keywords') ?? openDatalistFilterPartner.filters.keywords,
            socialReason: openDatalistFilterPartner.filters.socialReason,
            name: getValues('name') ?? openDatalistFilterPartner.filters.name,
            city: openDatalistFilterPartner.filters.city,
            product: openDatalistFilterPartner.filters.product,
            status: openDatalistFilterPartner.filters.status,
            type: openDatalistFilterPartner.filters.type,
            platform: openDatalistFilterPartner.filters.platform,
            network: openDatalistFilterPartner.filters.network,
            commissionLevel: minimumLevelSup
          }
      }))
    }
  }

  const resetFilters = () => {
    setClearMultiSelectValues(true)

    let minimumLevelSup = ""

    if(minimumLevel) {
      const levelWordSplit = minimumLevel?.split("_")
      const oneLevelAdded = parseInt(levelWordSplit[1], 10) + 1
      levelWordSplit[1] = oneLevelAdded.toString()
      minimumLevelSup = levelWordSplit.join("_")
    }

    dispatch(setOpenDatalistFilterPartner({show: false, count: openDatalistFilterPartner.count, filters: {
        idPartner: '',
        keywords: '',
        socialReason: '',
        name: '',
        city: '',
        product:  {
          id: '',
          value: '',
          label: ''
        },
        status: [],
        type: [],
        platform: [],
        network: [],
        commissionLevel: minimumLevelSup
      }}))
  }

  const handleClickExport = () => {
    setLoadingExport(true)
    new PartnerGateway().getExport(openDatalistFilterPartner.filters).then(response => {
      if (response) {
        download(t('export.partners'), response)
        setLoadingExport(false)
      }
    })
  }

  const getBadgeStatus = (partnerStatus: string) => {
    if (partnerStatus) {
      return <div className="badge-wrapper">
        <div key={uuidV4()} className={`badge badge--min etat ${'etat--' + partnerStatus} u-mys`}>
          <div className="badge__container">
            <div className="badge__text ">
              {referential?.partner?.status.find(s => s.value === partnerStatus)?.label}
            </div>
          </div>
        </div>
      </div>
    }
  }

  function defineSortOrder(sort: string|undefined): SortOrder {
    if (sortOrder.sortLabel !== sort) return SortOrder.ASC
    return sortOrder.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC
  }

  function sortData(sort: string|undefined) {
    if(sort) {
      const order: SortInterface = {
        sortLabel: sort,
        sortOrder: defineSortOrder(sort)
      };
      setSortOrder(order);
      new PartnerGateway().getList(
          callerType,
          currentPage,
          watchNumberRows || 50,
          openDatalistFilterPartner.filters,
          controller.signal,
          sort,
          order.sortOrder
      ).then(response => {
          setPartners(response)
      })
    }
  }

  const handleOptions = () => {
    let referentialOptions: ReferentielItemInterface[] | []
    if(minimumLevel && currentLevel && referential?.partner.comission) {

      const levelAvailable = commissionRelations?.map((part) => {
        const levelWordSplit = part?.commissionLevel?.split("_")
        if(levelWordSplit && levelWordSplit[1]) return parseInt(levelWordSplit[1], 10)
      })

      const currentLevelSplit = currentLevel?.split("_")
      const currentLevelNumber = parseInt(currentLevelSplit[1], 10)

      const minimumLevelSplit = minimumLevel?.split("_")
      const minimumLevelNumber = parseInt(minimumLevelSplit[1], 10)

      const levelMissing: string[] = []

      if(levelAvailable) {
        for(let i = currentLevelNumber + 1; i <= minimumLevelNumber; i++){
          if(!levelAvailable.includes(i)) levelMissing.push(`niveau_${i}`)
        }
      }

      referentialOptions = referential?.partner.comission.filter((level) => level.value > minimumLevel)
      const referentialMissing = referential?.partner.comission.filter((level: any) => levelMissing.includes(level.value))

      if(referentialMissing) {
        referentialOptions = [...referentialMissing, ...referentialOptions]
      }
    } else {
      referentialOptions = referential?.partner.comission || []
    }
    return referentialOptions
  }

  return (<>
    {(viewModel !== null &&
      <>
        <div className={`datalist`}>
          <div className="datalist__title">{t(viewModel.title)}</div>
          <div className="datalist__header">
            <form onSubmit={handleSubmit(onSubmit)} className="filter">
              <div className="filter__input flex items-center">
                {viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                  <div key={uuidV4()} className="input-no-border">
                    <img src={iconHeadingSearch} alt="" />
                    <input {...register(filter.field)} placeholder={t('common.search-by', {keyword: t(filter.keyword)})} className="u-mxs"/>
                  </div>
                ))}
                {from && <MultiSelectCustom
                  id="status"
                  name="status"
                  classes="u-mbs"
                  control={control}
                  label={t("filters.display-status")}
                  options={referential?.partner.status || []}
                  customOnChange={(options) => {
                    setValue('status', options)
                  }}
                  defaultValue={openDatalistFilterPartner.filters.status}
                  clearValues={clearMultiSelectValues}
                />}
              </div>
              <div className="filter__actions">
                <button type="submit" className="button button--submit">{t('search.submit')}</button>
                <button type="button" className="button button--white" onClick={() => resetFilters()}>{t('search.cancel')}</button>
              </div>
            </form>
            <HeaderRight numberOfActivatedFilters={openDatalistFilterPartner.count}
                         handleClickFilter={handleClickFilter}
                         handleClickExport={handleClickExport}
                         isLoadingExport={isLoadingExport}
                         allowExport={partnerRules?.export}

            />
          </div>
          <div className="flex justify-between">
              <SelectCustom classes="flex justify-start u-mbs" id="commissionLevel"
                            name="commissionLevel"
                            label={t('filters.by-level')}
                            options={handleOptions()}
                            register={register}
                            noChoiceOption={(!(from && from === "dashboard"))}
              />
              <SelectCustom classes="flex justify-end u-mbs" id="numberRows"
                            name="numberRows"
                            label={t('filters.display-results-by')}
                            options={optionsNbRows}
                            register={register}
                            noChoiceOption
              />
          </div>
          <div className="table-fix-head">
          <table className="datalist__datas">
            <thead>
            <tr>
                {viewModel.heading.map((title: { name: string, sort?: string }) => (
                    <th key={uuidV4()} className={title.sort ? 'sortable' : ''} onClick={()=>sortData(title.sort)}>
                      {t(title.name)}
                      {title.sort && sortOrder.sortLabel !== title.sort &&
                          <>
                              <img src={iconChevron} className="chevron-top" alt="chevron-top" />
                              <img src={iconChevron} className="chevron-bottom" alt="chevron-bottom" />
                          </>
                      }
                      {title.sort && sortOrder.sortLabel === title.sort && sortOrder.sortOrder === SortOrder.ASC &&
                          <>
                              <img src={iconChevronSelected} className="chevron-bottom--selected" alt="chevron-bottom" />
                          </>
                      }
                      {title.sort && sortOrder.sortLabel === title.sort && sortOrder.sortOrder === SortOrder.DESC &&
                          <>
                              <img src={iconChevronSelected} className="chevron-top--selected" alt="chevron-top" />
                          </>
                      }
                    </th>
                ))}
            </tr>
            </thead>
            <tbody>
            {viewModel.data !== undefined &&
              viewModel.data.map((item: PartnerListInterface) => {
                if(excludeHimSelf && partnerId && partnerId === item.id) return
                return (
                <tr key={uuidV4()}>
                  <td>
                    {partnerRules?.read && <Link to={`/${t('url.partner.read-general-information')}/${item.id}`}
                                                 target="_blank"
                                                 rel="noopener noreferrer"
                    >
                        <button type="button" className="button-reset">
                            <img src={iconSearch} alt=""/>
                        </button>
                    </Link>}
                    {callback ?
                      <button type="button" className="button-reset" onClick={() => callback(item)}>
                        <img src={iconAdd} alt="" />
                      </button>
                      : partnerRules?.update ?
                        <Link to={`/${t('url.partner.edit-general-information')}/${item.id}`}>
                          <button type="button" className="button-reset">
                            <img src={iconEdit} alt="" />
                          </button>
                        </Link> : null
                    }

                  </td>
                  <td>{item.partnerId}</td>
                  <td>{item.cabinetName}</td>
                  <td>{item.contactName}</td>
                  <td>{item.internalCommercialName}</td>
                  <td>
                      <div className="u-mys">
                          {
                              item.conventions && item.conventions.map((convention, index) => {
                              if (index < 3) {
                                  return <div
                                      className={`badge badge--min badge--without-cta badge--partner-product u-mrs u-mbs`}
                                      key={uuidV4()}>
                                      <div className="badge__container">
                                          <div className="badge__text">{convention.product_label}</div>
                                      </div>
                                  </div>
                              }
                          })}
                          {
                              item.conventions && item.conventions.length > 3 ? (
                                  <div className={`badge badge--min badge--without-cta badge--partner-product-more u-mrs u-mbs`}>
                                    <div className="badge__container">
                                        <ReactTooltip id="more" />
                                        <a className="badge__text"
                                           data-tip=""
                                           data-for={`more-${item.id}`}
                                        >{`+${item.conventions.length -3}`}
                                        </a>
                                        <ReactTooltip id={`more-${item.id}`} place="right" effect="solid">
                                            {
                                                item.conventions.map((convention, index) => {
                                                    if (index>2) {
                                                        return <span key={index}>{convention.product_label} <br /></span>
                                                    }
                                                })
                                            }
                                        </ReactTooltip>
                                    </div>
                              </div>) : null
                          }
                      </div>
                  </td>
                  <td>{item.city}</td>
                  <td>
                    { item?.commissionLevel?.replace("niveau_","") ?`${t("partner.form.commission.level", {
                    level: item.commissionLevel.replace("niveau_","")
                  })}` : null}
                  </td>
                  <td>{getIconForStatus(item.status)}</td>
                </tr>
            )})}
            {viewModel.data === undefined || viewModel.data.length === 0 &&
              <tr>
                <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
              </tr>
            }
            </tbody>
          </table>
          </div>
        </div>
        <Pagination currentPage={currentPage} itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage} numberOfItems={viewModel.pagination.numberOfItems} callback={paginate} />
      </>
    )}
    </>
  )
}

export default PartnerDatalist
