import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Product} from '../../../domain/Product/Product'
import {ProductChildInterface} from '../../../domain/Product/ProductChild'
import {SliceListInterface} from '../../../domain/Share/Slice/SliceList'
import {ProductBankInformation, ProductBankInformationInterface} from "../../../domain/Product/BankInformation";
import {v4 as uuidV4} from "uuid";
import {Scale} from "../../../domain/Product/Scale";

interface product {
  reloadProduct: string
  countTotal: number
  product: Product|null
  productChildArray: ProductChildInterface[]
  bankInformationArray: ProductBankInformationInterface[]
  scales: Scale[]
}

const initialState: product = {
  reloadProduct: uuidV4(),
  countTotal: 0,
  product: null,
  productChildArray: [],
  bankInformationArray: [],
  scales: []
}

export const productSlice = createSlice({
  name: 'product',
  initialState: initialState,
  reducers: {
    reloadProduct: (state) => {
      state.reloadProduct = uuidV4()
    },
    setCountTotal: (state, action: PayloadAction<number>) => {
      state.countTotal = action.payload
    },
    addProduct: (state, action: PayloadAction<Product|null>) => {
      state.product = action.payload
    },
    addMultipleProductChild: (state, action: PayloadAction<ProductChildInterface[]>) => {
      state.productChildArray = action.payload
    },
    addProductChild: (state, action: PayloadAction<ProductChildInterface>) => {
      state.productChildArray = state.productChildArray.filter(item => item.id !== action.payload.id)
      state.productChildArray.push(action.payload)
    },
    removeProductChild: (state, action: PayloadAction<string>) => {
      state.productChildArray = state.productChildArray.filter(item => item.id !== action.payload)
    },
    setBankInformations: (state, action: PayloadAction<ProductBankInformation[]>) => {
      state.bankInformationArray = action.payload
    },
    addBankInformation: (state, action: PayloadAction<ProductBankInformation>) => {
      state.bankInformationArray.push(action.payload)
    },
    updateBankInformation: (state, action: PayloadAction<ProductBankInformation>) => {
      const index = state.bankInformationArray.findIndex(bankInformationItem => {
        return bankInformationItem.id === action.payload.id
      });
      if (index >=0) {
        state.bankInformationArray[index] = action.payload
      }
    },
    setScales: (state, action:PayloadAction<Scale[]>) => {
      state.scales = action.payload
    }
  }
})

export const {
  reloadProduct,
  setCountTotal,
  addProduct,
  addMultipleProductChild,
  addProductChild,
  removeProductChild,
  setBankInformations,
  addBankInformation,
  updateBankInformation,
  setScales
} = productSlice.actions

export default productSlice.reducer
