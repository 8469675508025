import React, {FunctionComponent, InputHTMLAttributes} from "react";
import {IFormFieldProps} from "./IFormFieldProps";
import BicValidatorUseCase from "../../../../useCase/Validator/BicValidatorUseCase";
import ValidatorGateway from "../../../../gateway/Validator/ValidatorGateway";
import {useTranslation} from "react-i18next";

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  id: string;
  name: string;
  classes?: string;
  labelClasses?: string;
  inputClasses?: string;
  enableValidation: boolean;
}

const Bic: FunctionComponent<IProps> = ({
                                           id,
                                           name,
                                           classes,
                                           label,
                                           register,
                                           error,
                                           required,
                                           labelClasses,
                                           inputClasses,
                                          enableValidation,
                                          ...rest
                                         }) => {
  const bicValidatorUseCase = new BicValidatorUseCase(new ValidatorGateway())
  const {t} = useTranslation()

  const validateBic = async (value: string) => {
    if ([null, ''].includes(value) || !enableValidation) {
      return true
    }
    return bicValidatorUseCase.execute(value).then((response) => {
      if (!response) {
        return false
      }

      return true
    })
  }

  return (
    <>
      <div className={ classes ? `${ classes }` : '' }>
        { label &&
          <>
            <div className="form-control">
              <div className="flex items-end">
                <label htmlFor={ id } className={labelClasses ? labelClasses + " form-control__label" : "form-control__label"}>{ label }</label>
              </div>
              <div className="form-control__input">
                <input type="text" id={id} name={name} {...register(name, { required : required, validate: validateBic})}
                />
              </div>
            </div>
            {error && <div className="form-control__errors">{t('error.bic')}</div>}
          </>
        }
      </div>
    </>
  );
}

export default Bic
