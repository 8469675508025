interface CityInterface {
  code: string
  dept: string
  id: string|null
  label: string
  postcode?: string
}

class City implements CityInterface {
  public code: string
  public dept: string
  public id: string|null
  public label: string
  public postcode?: string

  constructor(
    code: string,
    dept: string,
    id: string|null,
    label: string,
    postcode: string
  ) {
    this.code = code
    this.dept = dept
    this.id = id
    this.label = label
    this.postcode = postcode
  }
}

export {City}
export type {CityInterface}
