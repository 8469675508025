import PartnerGatewayInterface from '../../../domain/Partner/PartnerGatewayInterface'
import {PartnerInterface} from '../../../domain/Partner/Partner'
import PartnerGateway from "../../../gateway/Partner/PartnerGateway";

export default class AddUseCase
{
  public partnerGateway

  constructor(PartnerGateway: PartnerGateway) {
    this.partnerGateway = PartnerGateway
  }

  async execute(Partner: PartnerInterface): Promise<string|null> {
    return await this.partnerGateway.add(Partner).then(response => {
      if (response) {
        return response
      }

      return null
    })
  }
}
