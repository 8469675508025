import ProspectGateway from "../../../gateway/Prospect/ProspectGateway";
import {ProspectInterface} from "../../../domain/Prospect/Prospect";
import store from "../../../infrastructure/store/store";
import {
  setBankInformations,
  setRelations,
  setBeneficialOwner,
  setUndivided,
  setPartner,
  setLegalRepresentatives
} from "../../../infrastructure/store/component/prospect";
import {BankInformation} from "../../../domain/Prospect/BankInformation";
import {Relation} from "../../../domain/Prospect/Relation";
import {BeneficialOwner} from "../../../domain/Prospect/BeneficialOwner";
import {ProspectLegalRepresentatives} from "../../../domain/Prospect/ProspectLegalRepresentatives";
import {Undivided} from "../../../domain/Prospect/Undivided";
import {addCurrentAddress, addCurrentFiscalAddress} from "../../../infrastructure/store/component/prospect";

export default class GetUseCase
{
  public prospectGateway

  constructor(
    ProspectGateway: ProspectGateway
  ) {
    this.prospectGateway = ProspectGateway
  }

  async execute(id: string): Promise<ProspectInterface|null> {
    return await this.prospectGateway.get(id).then(response => {

      if (response && response.prospectAddresses) {
        response.prospectAddresses.forEach((address) => {
          if (address.type === "postal" && address.main) {
            store.dispatch(addCurrentAddress(address))
          }
          if (address.type === "fiscal" && address.main) {
            store.dispatch(addCurrentFiscalAddress(address))
          }
        })
      }

      if (response && response.bankInformations) {
        const bankInformations: BankInformation[] = []
        response.bankInformations.map((bankInfo) => {
          bankInformations.push(bankInfo)
        })
        store.dispatch(setBankInformations(bankInformations))
      }

      if (response && response.relations) {
        const relations: Relation[] = []

        response.relations.map((relation) => {
          relations.push(relation)
        })
        store.dispatch(setRelations(relations))

      }

      if (response && response.beneficialOwners) {
        const beneficialOwners: BeneficialOwner[] = []

        response.beneficialOwners.map((owner) => {
          beneficialOwners.push(owner)
        })

        store.dispatch(setBeneficialOwner(beneficialOwners))
      }

      if (response && response.undivideds) {
        const undivideds: Undivided[] = []

        response.undivideds.map((owner) => {
          undivideds.push(owner)
        })

        store.dispatch(setUndivided(undivideds))
      }

      if (response && response.partner) {
        store.dispatch(setPartner(response.partner))
      }

      if (response && response.legalRepresentatives) {
        const legalRepresentatives: ProspectLegalRepresentatives[] = []

        response.legalRepresentatives.map((legalRepresentative) => {
          legalRepresentatives.push(legalRepresentative)
        })

        store.dispatch(setLegalRepresentatives(legalRepresentatives))
      }

      return response
    })
  }
}
