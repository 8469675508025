import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {v4 as uuidV4} from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../store/hook'

import '../../../../assets/styles/components/_datalist.scss'
import {Undivided} from "../../../../domain/Prospect/Undivided";
import CustomerIndivisaireListPresenter from "../../../../presenter/Customer/CustomerIndivisaireListPresenter";
import {BeneficialOwner} from "../../../../domain/Customer/BeneficialOwner";
import {setOpenAccountBeneficialOwnerForm} from "../../../store/component/event";
import iconEdit from '../../../../assets/images/icons/datalist-edit.svg'
import eyeIcon from "../../../../assets/images/icons/eye.svg";
import uploadIcon from "../../../../assets/images/icons/upload.svg";
import {SortInterface, SortOrder} from "../../../../domain/Utils/List";
import TableHead from "../Table/TableHead";

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}


type Props = {
  beneficialOwnerList?: BeneficialOwner[] | Undivided[]
  uploadCallback: (undividedId: string|null) => void,
  viewFilesCallback: (id: string|null) => void
}

const TABLE_VISIBLE = [
  'active'
];
const BeneficialOwnerDatalist: FunctionComponent<Props> = ({beneficialOwnerList, uploadCallback, viewFilesCallback}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const customerRules = useAppSelector((state) => state.me.me?.rules.customer.actions)

  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})

  const preferences = localStorage.getItem("preferences")

  useEffect(() => {
    if (beneficialOwnerList) {
      const presenter = new CustomerIndivisaireListPresenter(beneficialOwnerList);
      presenter.load().then(() => {
        setViewModel(presenter.immutableViewModel())
      })
    }

  }, [beneficialOwnerList])

  return (<>
    {(viewModel !== null &&
      <>
      <div className={`datalist`}>
        <div className="datalist__title">{t(viewModel.title)}</div>
        <table className="datalist__datas">
          <thead>
          {viewModel.heading && <TableHead typeFilter={"FRONT"}
                                           heading={viewModel.heading}
                                           sortOrder={sortOrder}
                                           setSortOrder={setSortOrder}
                                           viewModel={viewModel}
                                           setViewModel={setViewModel}
                                           subObjectProperty={"person"}/>}
          </thead>
          <tbody>
          {viewModel.data !== undefined &&
            viewModel.data
              .filter((item: BeneficialOwner | Undivided) => item.status && TABLE_VISIBLE.includes(item.status))
              .map((item: BeneficialOwner | Undivided ) => (
            <tr key={uuidV4()}>
              <td>
                <button
                  type="button"
                  className="button-reset"
                  onClick={() => dispatch(setOpenAccountBeneficialOwnerForm({show: true, beneficialOwner: item}))}
                >
                  <img src={iconEdit} alt="" />
                </button>
                <button type="button" className="button-reset u-mxs"
                        onClick={() => viewFilesCallback(item.person?.id ?? null)}
                >
                  <img src={eyeIcon} alt=""/>
                </button>
                <button type="button" className="button-reset u-mxs"
                        onClick={() => uploadCallback(item.person?.id ?? null)}
                >
                  <img src={uploadIcon} alt=""/>
                </button>
              </td>
              <td>{item.person?.title}</td>
              <td>{item.person?.lastname}</td>
              <td>{item.person?.firstname}</td>
            </tr>
          ))}
          {viewModel.data === undefined || viewModel.data.length === 0 &&
            <tr>
              <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
            </tr>
          }
          </tbody>
        </table>
      </div>
      </>
    )}
    </>
  )
}

export default BeneficialOwnerDatalist
