import {FunctionComponent, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css'

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {setOpenChoiceInvestorEvent} from '../../../../store/component/event'
import {
  setInvestorInvestor,
  setInvestorOwner, setInvestorReceivingShares,
  setInvestorTransferor,
  setInvestorUsufructuary
} from '../../../../store/component/movement'
import {ProspectListInterface} from '../../../../../domain/Prospect/ProspectList'
import ProspectGateway from '../../../../../gateway/Prospect/ProspectGateway'
import InitializeInvestor from '../../../../../useCase/PreSubscription/Initialization/InitializeInvestor'

import blockScrollBody from '../../../util/BlockScroll'
import getClassForOverlay from '../../../util/Sidebar'
import ClientDatalist from '../../Datalist/ClientDatalist'
import {toastSuccess} from '../../../util/Toast'
import CustomerGateway from "../../../../../gateway/Customer/CustomerGateway";
import {BankInformation} from "../../../../../domain/Movement/BankInformation";
import { Prospect } from '../../../../../domain/Prospect/Prospect'
import { Person } from '../../../../../domain/Prospect/Person'
import { Customer } from '../../../../../domain/Customer/Customer'

const ChoiceInvestor: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openChoiceInvestor = useAppSelector((state) => state.event.openChoiceInvestor)
  const investors = useAppSelector((state) => state.movement.investors)

  useEffect(() => {
    blockScrollBody(openChoiceInvestor.show)

  }, [openChoiceInvestor])

  const handleChoiceInvestor = function (selectedProspect: ProspectListInterface) {
    if(openChoiceInvestor?.dataListType === 'customer') {
      console.log('selectedProspect is customer', selectedProspect)
      const copyInvestor = new InitializeInvestor().initializeInvestor()
      const copyCustomer = new Prospect(
        selectedProspect.id,
        null,
        null,
        null,
        null,
        selectedProspect.productType,
        null,
        null,
        null,
        null,
        selectedProspect.accountId,
        null,
        null,
        null,
        null,
        selectedProspect.socialReason,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        [new Person(
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          selectedProspect.firstName,
          selectedProspect.lastName,
          selectedProspect.maidenName,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        )],
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        [],
        false,
        null,
        null,
        null
      )
      copyInvestor.type = openChoiceInvestor.key || ""
      copyInvestor.prospect = copyCustomer
      toastSuccess(t('movement.notify.choice-investor-success'))
      if(openChoiceInvestor.key === 'owner') {
        dispatch(setInvestorOwner(copyInvestor))
      } else if(openChoiceInvestor.key === 'usufructuary') {
        dispatch(setInvestorUsufructuary(copyInvestor))
      } else if(openChoiceInvestor.key === 'transferor') {
        dispatch(setInvestorTransferor(copyInvestor))
      } else if(openChoiceInvestor.key === 'investor') {
        dispatch(setInvestorInvestor(copyInvestor))
      }
      dispatch(setOpenChoiceInvestorEvent({show: false, key: null, dataListType: openChoiceInvestor?.dataListType}))
    } else {
      if(openChoiceInvestor.key === 'investorReceivingShares') {
        if(selectedProspect.id) {
          dispatch(setInvestorReceivingShares({
            idInvestor: selectedProspect.id,
            code: selectedProspect.accountId || "",
            firstname: selectedProspect.firstName || '',
            lastname: selectedProspect.lastName || '',
            propertyNature: openChoiceInvestor.propertyNature,
            fullName: (selectedProspect.lastName || '') + " " +  (selectedProspect.firstName || '')
          }))
            dispatch(setOpenChoiceInvestorEvent({show: false, key: null, dataListType: openChoiceInvestor?.dataListType}))
          }
      } else {
        new ProspectGateway().get(selectedProspect.id).then((response) => {
          const investor = new InitializeInvestor().initializeInvestor()
          investor.type = openChoiceInvestor.key || ""
          investor.prospect = response;
          if (response) {
            if(openChoiceInvestor.key === 'owner') {
              investor.id = investors.owner?.id ?? null
              investor.amount = investors.owner?.amount ?? null
              investor.paymentMode = investors.owner?.paymentMode ?? null
              investor.checkDepositAt = investors.owner?.checkDepositAt ?? null
              investor.paymentStatus = investors.owner?.paymentStatus ?? null
              investor.paymentNature = investors.owner?.paymentNature ?? null
              investor.paymentDate = investors.owner?.paymentDate ?? null
              investor.dismembermentKey = investors.owner?.dismembermentKey ?? null
              investor.partnerType = investors.owner?.partnerType ?? null
              investor.partner = investors.owner?.partner

              dispatch(setInvestorOwner(investor))
            } else if(openChoiceInvestor.key === 'usufructuary') {
              investor.id = investors.usufructuary?.id ?? null
              investor.amount = investors.usufructuary?.amount ?? null
              investor.paymentMode = investors.usufructuary?.paymentMode ?? null
              investor.checkDepositAt = investors.usufructuary?.checkDepositAt ?? null
              investor.paymentStatus = investors.usufructuary?.paymentStatus ?? null
              investor.paymentNature = investors.usufructuary?.paymentNature ?? null
              investor.paymentDate = investors.usufructuary?.paymentDate ?? null
              investor.dismembermentKey = investors.usufructuary?.dismembermentKey ?? null
              investor.partnerType = investors.usufructuary?.partnerType ?? null
              investor.partner = investors.usufructuary?.partner

              dispatch(setInvestorUsufructuary(investor))
            } else if(openChoiceInvestor.key === 'transferor') {
              investor.id = investors.transferor?.id ?? null
              investor.amount = investors.transferor?.amount ?? null
              investor.paymentMode = investors.transferor?.paymentMode ?? null
              investor.checkDepositAt = investors.transferor?.checkDepositAt ?? null
              investor.paymentStatus = investors.transferor?.paymentStatus ?? null
              investor.paymentNature = investors.transferor?.paymentNature ?? null
              investor.paymentDate = investors.transferor?.paymentDate ?? null
              investor.dismembermentKey = investors.transferor?.dismembermentKey ?? null
              investor.partnerType = investors.transferor?.partnerType ?? null
              investor.partner = investors.transferor?.partner

              dispatch(setInvestorTransferor(investor))
            } else if(openChoiceInvestor.key === 'investor') {
              investor.id = investors.investor?.id ?? null
              investor.amount = investors.investor?.amount ?? null
              investor.paymentMode = investors.investor?.paymentMode ?? null
              investor.checkDepositAt = investors.investor?.checkDepositAt ?? null
              investor.paymentStatus = investors.investor?.paymentStatus ?? null
              investor.paymentNature = investors.investor?.paymentNature ?? null
              investor.paymentDate = investors.investor?.paymentDate ?? null
              investor.dismembermentKey = investors.investor?.dismembermentKey ?? null
              investor.partnerType = investors.investor?.partnerType ?? null
              investor.partner = investors.investor?.partner

              dispatch(setInvestorInvestor(investor))
            }
            toastSuccess(t('movement.notify.choice-investor-success'))
            dispatch(setOpenChoiceInvestorEvent({show: false, key: null, dataListType: openChoiceInvestor?.dataListType}))
          }
        })
      }
    }

  }

  function handleClose() {
    dispatch(setOpenChoiceInvestorEvent({show: false, key: null, dataListType: openChoiceInvestor?.dataListType}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openChoiceInvestor.show)}`} onClick={() => handleClose()}/>
      <div className={`sidebar sidebar--right sidebar--right ${openChoiceInvestor.show ? 'sidebar--active-big' : ''}`}>
        <div className="form-bloc">
        <div className="sidebar__content">
          {openChoiceInvestor.key === 'owner' && <div className="title">{t('movement.choice-investor-owner')}</div>}
          {openChoiceInvestor.key === 'usufructuary' && <div className="title">{t('movement.choice-investor-usufructuary')}</div>}
          <ClientDatalist callback={handleChoiceInvestor} callerType={"datalist"} />
      </div>
        <footer className="sidebar__footer">
        <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => handleClose()}>{t('common.cancel')}</button>
      </footer>
        </div>
    </div>
    </>
  );
}

export default ChoiceInvestor
