import React, {FunctionComponent, useCallback} from 'react';
import carbonTime from "../../../assets/images/icons/carbon_time.svg";
import codicon from "../../../assets/images/icons/codicon_debug-restart.svg";
import cibMail from "../../../assets/images/icons/cib_mail-ru.svg";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../store/hook";

type Props = {
  code: string | undefined
}

const Error: FunctionComponent<Props> = ({code}) => {
  const {t} = useTranslation()
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)

  const separateError = useCallback(() => {
    if (code) {
      const errorCodeSplitted = code.split('')
      if (errorCodeSplitted[0] === "5") {
        return "--500"
      }
      if (errorCodeSplitted[0] === "4") {
        return "--400"
      }
    } else {
      return "--400"
    }
  }, [code])

  return (
    <main
      className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''} main-content--error main-content--error${separateError()}`}>
      <div className="container-error">
        <div className="block-error-top">
          <h2>{t('error.title')}</h2>
          <p>{t('error.error-occurred')}</p>
          <span>{t('error.error-code')} {code}</span>
        </div>

        <div className="block-error-bottom">
          <p>{t('error.waiting')}</p>
          <ul>
            <li>
              <img src={carbonTime}/>
              <p>{t('error.restart-diligencio')}</p>
            </li>
            <li>
              <img src={codicon}/>
              <p>{t('error.try-again')}</p>
            </li>
            <li>
              <img src={cibMail}/>
              <p>{t('error.contact-us')}</p>
            </li>
          </ul>
        </div>
      </div>
    </main>
  );
}

export default Error;