import {CommissioningListInterface} from "../../../domain/Commissioning/CommissioningList";
import {ListRequestInterface} from './ListRequest'
import {CallerType} from "../../../domain/Caller/Caller";
import CommissioningGatewayInterface from "../../../domain/Commissioning/CommissioningGatewayInterface";

export default class ListUseCase {
  public commissioningGateway

  constructor(CommissioningGateway: CommissioningGatewayInterface) {
    this.commissioningGateway = CommissioningGateway
  }

  async execute(
    ListRequest: ListRequestInterface,
    signal?: AbortSignal,
    sort?: string,
    order?: string,
    callerType?: CallerType
  ): Promise<{
    data: CommissioningListInterface[] | null,
    numberOfItems: number
  } | null> {
    return await this.commissioningGateway.getList(
      ListRequest.currentPage,
      ListRequest.itemPerPage,
      ListRequest.filter,
      signal,
      sort,
      order,
      callerType
    ).then(response => response)
  }
}
