import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm} from 'react-hook-form'
import {v4 as uuidV4} from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {addFilter} from '../../../../store/component/slice'
import {FilterInterface, SliceListInterface} from '../../../../../domain/Share/Slice/SliceList'
import {ListRequest} from '../../../../../useCase/Share/Slice/List/ListRequest'
import ListUseCase from '../../../../../useCase/Share/Slice/List/ListUseCase'
import SliceGateway from '../../../../../gateway/Share/Slice/SliceGateway'
import SliceListPresenter from '../../../../../presenter/Share/Slice/SliceListPresenter'
import Pagination from '../../Pagination/Pagination'

import iconHeadingSearch from '../../../../../assets/images/icons/datalist-heading-search.svg'
import iconAdd from '../../../../../assets/images/icons/add.svg'
import '../../../../../assets/styles/components/_datalist.scss'
import {toastError} from "../../../util/Toast";
import {saveNbRowsInLocalStorage} from "../../../util/SavePreferencesInLocalStorage";
import {optionsNbRows} from "../../../../../fixtures/Referentiel";
import SelectCustom from "../../Elements/Select";
import iconEsnEntry from '../../../../../assets/images/icons/type-mouvement-entree.svg';
import iconEsnExit from '../../../../../assets/images/icons/type-mouvement-sortie.svg';
import iconEsnNew from '../../../../../assets/images/icons/type-mouvement-nouvelle-part.svg';
import iconEsnRecurrence from "../../../../../assets/images/icons/type-mouvement-recurrence.svg";
import iconEsnTransferPlus from "../../../../../assets/images/icons/type-mouvement-transfer+.svg";
import iconEsnTransferMinus from "../../../../../assets/images/icons/type-mouvement-transfer-.svg";
import {SortInterface, SortOrder} from "../../../../../domain/Utils/List";
import TableHead from "../../Table/TableHead";
import {IOpenChoiceSliceToGiveUp} from "../../../../store/component/event"
interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

type Props = {
  callback: any,
}

const ListToSellChoice: FunctionComponent<Props> = ({callback}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openChoiceSliceToGiveUp = useAppSelector<IOpenChoiceSliceToGiveUp>((state) => state.event.openChoiceSliceToGiveUp)
  const investorId = openChoiceSliceToGiveUp.prospectId || ""
  const productId = openChoiceSliceToGiveUp.productId || ""
  const shareCount = openChoiceSliceToGiveUp.shareCount || 0
  const choicePartSelectAll = openChoiceSliceToGiveUp.choicePartSelectAll || false
  const usufructId = openChoiceSliceToGiveUp.usufructId || ""
  const propertyType = openChoiceSliceToGiveUp.propertyType || ""
  const isForPledge = openChoiceSliceToGiveUp.isForPledge || false

  const shareSlices = useAppSelector((state) => state.slice.sliceToTransaction)
  const [countShare, setCountShare] = useState<number>(0)
  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [filter, setFilter] = useState<FilterInterface>({keywords: '', productId: ''})
  const controller = new AbortController()
  const preferences = localStorage.getItem("preferences")

  const { register, getValues, handleSubmit, setValue, watch } = useForm()

  const watchNumberRows = watch('numberRows')

  useEffect(()=> {
    if(openChoiceSliceToGiveUp.show) setValue("numberRows", undefined)
  },[openChoiceSliceToGiveUp])

  useEffect(() => {
    setFilter({
      ...filter,
      productId,
      propertyType: propertyType === "dismemberment" ? usufructId : propertyType,
    })

    if (!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      const listRequest = new ListRequest(currentPage, watchNumberRows || 50, filter, investorId)
      let slices
      if (investorId && productId) {
        if (propertyType === "dismemberment") {
          slices = new ListUseCase(new SliceGateway())
            .executeTypeDismemberment(listRequest, controller.signal)
            .then(response => response);
        } else {
          slices = new ListUseCase(new SliceGateway())
            .execute(listRequest, controller.signal)
            .then(response => response);
        }
      }
      const presenter = new SliceListPresenter(slices);
      presenter.load().then(() => {
        const mutableViewModel = presenter.immutableViewModel()
        if (choicePartSelectAll) {
          mutableViewModel.heading = mutableViewModel.heading
            .filter((heading: {name: string, sort: string}) => heading.name !== 'movement.heading-slice.save'
              && heading.name !== 'movement.heading-slice.nb-share-transfer')
        }
        if (!choicePartSelectAll && isForPledge) {
          mutableViewModel.heading = mutableViewModel.heading
            .filter((heading: {name: string, sort: string}) => heading.name !== 'movement.heading-slice.nb-share-transfer')
        }
        setViewModel(mutableViewModel)
      })
    }

  }, [currentPage, watchNumberRows])

  useEffect(() => {
    let count = 0
    shareSlices.map((slice: SliceListInterface) => count = count + Number(slice.countShareSelected))
    setCountShare(count)

    shareSlices.map((slice: SliceListInterface) => {
      setValue(slice.id, Number(slice.countShareSelected) || 0)
    })
  }, [shareSlices])

  const onSubmit: SubmitHandler<FilterInterface> = data => {
    const filterObject = {
      keywords: data.keywords,
      productId: productId
    }

    setFilter(filterObject)
    dispatch(addFilter(filterObject))
  }

  const paginate = (pageNumber:number) => {controller.abort(); setCurrentPage(pageNumber)}

  function selectSliceToGiveUp(sliceToGiveUp: SliceListInterface) {
    let countShareSubtractWithOld = countShare
    const oldSlice = shareSlices.find((slice) => sliceToGiveUp.id === slice.id)
    if(oldSlice) {
      countShareSubtractWithOld = countShareSubtractWithOld - oldSlice.countShareSelected
    }
    const sum = countShareSubtractWithOld + (Number(getValues(sliceToGiveUp.id)) || 0)

    if (sum <= shareCount) {
      const slice: SliceListInterface = {
        id: sliceToGiveUp.id,
        product: { acronym: sliceToGiveUp.product.acronym, price: sliceToGiveUp.product.price, priceVL: sliceToGiveUp.product.priceVL, id: productId },
        date: sliceToGiveUp.date,
        startPart: sliceToGiveUp.startPart,
        endPart: sliceToGiveUp.endPart,
        count: sliceToGiveUp.count,
        countShareSelected: typeof getValues(sliceToGiveUp.id) === "string" ? parseInt(getValues(sliceToGiveUp.id), 10) : getValues(sliceToGiveUp.id),
        investorId,
        price: sliceToGiveUp.price,
        propertyType: sliceToGiveUp.propertyType,
        end: null, esn: null, sliceShareCount: null, start: null
      }
      callback(slice)
    } else {
      toastError(t('movement.notify.share-count-exceeded'));
    }
  }

  function pictoFromEsnType(esnType: string|null) {
    if(esnType === "E"){
      return <img alt="E" src={iconEsnEntry} title="Entrée"/>
    }
    if(esnType === "S") {
      return <img alt="S" src={iconEsnExit} title="Sortie"/>
    }
    if(esnType === "N") {
      return <img alt="N" title="Nouvelle part" src={iconEsnNew} />
    }
    if(esnType === "R") {
      return <img alt="R" title="Récurrence" src={iconEsnRecurrence} />
    }
    if(esnType === "T+") {
      return <img alt="T+" title="Transfert In" src={iconEsnTransferPlus} />
    }
    if(esnType === "T-") {
      return <img alt="T-" title="Transfert Out" src={iconEsnTransferMinus} />
    }
    return ""
  }

  return (<>
    {(viewModel !== null &&
      <>
      <div className={`datalist`}>
        <div className="datalist__title">{t(viewModel.title)}</div>
        <div className="datalist__header">
          <form onSubmit={handleSubmit(onSubmit)} className="filter">
            <div className="filter__input">
              {viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                <div key={uuidV4()} className="input-no-border">
                  <img src={iconHeadingSearch} alt="" />
                  <input {...register(filter.field)} placeholder={t('common.search-by', {keyword: t(filter.keyword)})} className="u-mxs"/>
                </div>
              ))}
            </div>
            <div className="filter__actions">
              <button type="submit" className="button button--submit">{t('search.submit')}</button>
              <button type="reset" className="button button--white">{t('search.cancel')}</button>
            </div>
          </form>
        </div>
        <SelectCustom
            classes="flex justify-end u-mbs" id="numberRows"
            name="numberRows"
            label={t('filters.display-results-by')}
            options={optionsNbRows}
            register={register}
            noChoiceOption
        />
        <table className="datalist__datas">
          <thead>
          {viewModel.heading &&
            <TableHead
                typeFilter={"API"}
                heading={viewModel.heading}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                viewModel={viewModel}
                setViewModel={setViewModel}
                filter={filter}
                watchNumberRows={watchNumberRows}
                currentPage={currentPage}
                investorId={investorId}
                listRequest={ListRequest}
                listUseCase={ListUseCase}
                listPresenter={SliceListPresenter}
                gateway={SliceGateway}
            />
          }
          </thead>
          <tbody>
          {viewModel.data?.map((item: SliceListInterface) => (
            <tr key={uuidV4()}>
              {item !== null && item !== undefined &&
                <>
                  <td>{item.product.acronym}</td>
                  <td>
                    <div className="ceil-esn">
                      { pictoFromEsnType(item.esn) }
                      { item.transfer_id && pictoFromEsnType("T") }
                    </div>
                  </td>
                  <td>{item.date}</td>
                  <td>{item.startPart}</td>
                  <td>{item.endPart}</td>
                  <td>{item.product.price}</td>
                  <td>{item.product.priceVL}</td>
                  <td>{item.count}</td>
                  {!choicePartSelectAll && (
                      <>
                        {!isForPledge && <td><input type="number" min="0" max={item.count} {...register(item.id)}/></td>}
                        <td>
                          <button type="button" className="button-reset" onClick={() => selectSliceToGiveUp(item)}>
                            <img src={iconAdd} alt=""/>
                          </button>
                        </td>
                      </>
                    )
                  }

                </>
              }
            </tr>
          ))}
          </tbody>
        </table>
      </div>
      {!choicePartSelectAll && <div className="u-txt-right">{`${countShare} / ${shareCount}`}</div>}
      <Pagination currentPage={currentPage} itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage} numberOfItems={viewModel.pagination.numberOfItems} callback={paginate} />
      </>
    )}
    </>
  )
}

export default ListToSellChoice
