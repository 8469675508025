import React, {FunctionComponent, useEffect} from "react";
import { useTranslation } from "react-i18next";

// Notification lib
import "react-toastify/dist/ReactToastify.min.css";
import {useAppDispatch, useAppSelector} from "../../../../../store/hook";
import {
  ReferentielInterface
} from '../../../../../../domain/Referentiel/ReferentielInterface'
import blockScrollBody from "../../../../util/BlockScroll";
import Input from "../../../Elements/Input";
import CityDatalist from "../../../Datalist/City/CityDatalist";
import ProductDalalist from "../../../Datalist/Product/ProductDalalist";
import MultiSelectCustom from "../../../Elements/MultiSelect";

type Props = {
  control: any
  register: any
  setValue: any
  getValues: any
  handleSubmit: any
  onSubmit: any
  handleClose: any

}
const FilterForm: FunctionComponent<Props> = ({register, control, setValue, getValues, handleSubmit, onSubmit, handleClose}) => {
  const { t } = useTranslation();

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)

  const openDatalistFilterPartner = useAppSelector(
    (state) => state.event.openDatalistFilterPartner
  );

  useEffect(() => {
    blockScrollBody(openDatalistFilterPartner.show);
  }, [openDatalistFilterPartner]);

  useEffect(() => {
    setValue("idPartner", openDatalistFilterPartner.filters.idPartner);
    setValue("keywords", openDatalistFilterPartner.filters.keywords);
    setValue("socialReason", openDatalistFilterPartner.filters.socialReason);
    setValue("name", openDatalistFilterPartner.filters.name);
    setValue("city", openDatalistFilterPartner.filters.city);
    setValue("product", openDatalistFilterPartner.filters.product);
    setValue("status", openDatalistFilterPartner.filters.status);
    setValue("type", openDatalistFilterPartner.filters.type);
    setValue("platform", openDatalistFilterPartner.filters.platform);
    setValue("network", openDatalistFilterPartner.filters.network);
  }, [openDatalistFilterPartner.filters]);

  return (
    <>
      {referential && (
        <>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="form-bloc form-bloc--partner-address-form"
          >
            <div className="title">{t("common.filters")}</div>
            <div className="form-bloc__form flex-container">
              <Input
                classes={"col-md-6"}
                label={t("filters.by-id-partner")}
                type={"text"}
                name={"idPartner"}
                id={"idPartner"}
                register={register}
              />
              <Input
                register={register}
                classes={"col-md-6"}
                label={t("common.search-by", {
                  keyword: t("filters.by-keyword-or-id"),
                })}
                type={"text"}
                name={"keywords"}
                id={"keywords"}
              />
              <Input
                register={register}
                classes={"col-md-6"}
                label={t(
                  "partner.form.bloc-general-information.social-reason"
                )}
                type={"text"}
                name={"socialReason"}
                id={"socialReason"}
              />
              <Input
                register={register}
                classes={"col-md-6"}
                label={t(
                  "filters.by-name"
                )}
                type={"text"}
                name={"name"}
                id={"name"}
              />
              <div className="col-md-6">
                <div className="form-control">
                  <label htmlFor="city" className="form-control__label">{t('account.form.address.city') || ''}</label>
                  <div className="form-control__input">
                    <CityDatalist control={control} name="city" defaultValue={getValues('city')}/>
                  </div>
                </div>
              </div>
              <ProductDalalist classes="col-md-6"
                               id="product"
                               name="product"
                               label={t('account.form.pledge.sidebar.product')}
                               control={control}
                               defaultValue={getValues('product')}
              />
              <MultiSelectCustom
                id="status"
                name="status"
                classes="col-md-6"
                control={control}
                label={t("partner.form.bloc-status.status")}
                options={referential?.partner.status || []}
                customOnChange={ (options) => {setValue('status', options)}}
              />
              <MultiSelectCustom
                id="type"
                name="type"
                classes="col-md-6"
                control={control}
                label={t("partner.form.bloc-general-information.type")}
                options={referential?.partner.partner_type || []}
                customOnChange={ (options) => {setValue('type', options)}}
              />
              <MultiSelectCustom
                id="platform"
                name="platform"
                classes="col-md-6"
                control={control}
                label={t("partner.form.bloc-general-information.platform")}
                options={referential?.partner.platform || []}
                customOnChange={ (options) => {setValue('platform', options)}}
              />
              <MultiSelectCustom
                id="network"
                name="network"
                classes="col-md-6"
                control={control}
                label={t("partner.form.bloc-general-information.network")}
                options={referential?.partner.network || []}
                customOnChange={ (options) => { setValue('network', options)}}
              />
            </div>
            <div className="filter__actions horizontal-container-center">
              <button type="submit" className="button button--submit">{t('search.submit')}</button>
              <button type="button" className="button button--white u-mxl" onClick={() => handleClose()}>{t('search.cancel')}</button>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default FilterForm;
