import {Scale} from "../../../domain/Product/Scale"
import Caller from "../../../domain/Api/Caller";
import envVariable from "../../../infrastructure/presentation/util/envVariable";

export default class ScaleGateway {
  list(productId: string): Promise<Scale[]> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/products/${productId}/scales`, {})
    .then(data => {
      if (data) {
        return data
      }
    })
    .catch(response => {
      return []
    })
  }

  listExpired(productId: string): Promise<Scale[]> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/products/${productId}/scales-expired`, {})
    .then(data => {
      if (data) {
        return data
      }
    })
    .catch(response => {
      return []
    })
  }
  create(productId: string, scale: Scale): Promise<Scale[]> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/products/${productId}/scales`, {
      category: scale.category.id,
      date: scale.date
    })
    .then(data => {
      if (data) {
        return data
      }
    })
    .catch(response => {
      throw response
    });
  }

  update(productId: string, scale: Scale): Promise<Scale[]> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/products/${productId}/scales/${scale.id}`, {
      category: scale.category.id,
      date: scale.date
    })
    .then(data => {
      if (data) {
        return data
      }
    })
    .catch(response => {
      throw response
    });
  }
}
