import React, {FunctionComponent, useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm, useFormState} from 'react-hook-form'

import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import {usePrompt} from "../../../util/Navigation";
import {setReadOnlyFormElements} from "../../../util/setReadOnlyFormElements";
import SessionSetting from "../Blocks/SessionSetting/SessionSetting";
import Footer from "../../Footer/Footer";
import Actions from "../Blocks/Actions/Actions";
import SessionCriteriaToggle from "../Blocks/SessionCriteriaToggle/SessionCriteriaToggle";
import EditionAndDownload from '../Blocks/EditionAndDownload'
import CommissioningGateway
  from '../../../../../gateway/Commissioning/CommissioningGateway'
import {useNavigate, useParams} from "react-router-dom";
import {toastError, toastSuccess} from "../../../util/Toast";
import {CommissioningSessionInterface} from "../../../../../domain/Commissioning/CommissioningList";
import {ISettingForm} from "../../../../../domain/Commissioning/setting";
import {ReferentielInterface} from "../../../../../domain/Referentiel/ReferentielInterface";
import {useAppSelector} from "../../../../store/hook";
import CommissioningSessionGateway from "../../../../../gateway/Commissioning/CommissioningSessionGateway";

type TProps = {
  commissioning?: CommissioningSessionInterface
  isLectureMode?: boolean
}

const Setting: FunctionComponent<TProps> = ({commissioning, isLectureMode}) => {
  const {t} = useTranslation()
  const { commissioningType, uuid } = useParams()
  const navigate = useNavigate()
  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)

  const {register, control, handleSubmit, reset, getValues, watch, setValue} = useForm<ISettingForm>({
    defaultValues: {
      session: {
        sessionNb: commissioning?.code || "",
        sessionStatut: referential?.wallet?.status?.find(status => status.value === commissioning?.status )?.label || "",
        sessionPayment: commissioning?.paymentScheduledAt || "",
      },
      actions: {
        sessionCalcul: "",
        control: "",
        v2Verification: "",
        validation: "",
      },
      editions: {
        accountingEntries: "",
        statementInvoice: "",
        transferFile: "",
        sendToGED: "",
        syntheses: ""
      },
      sessionCriteria: {
        products: commissioning?.criterias.products || [],
        partners: commissioning?.criterias.partners || [],
        regularization: commissioning?.regularisation || "",
        dateStart: commissioning?.period?.start || "",
        dateEnd: commissioning?.period?.end || "",
        multiCriteria: commissioning?.criteriaType === "multi" || false,
        criteria_movement: commissioning?.criterias?.transactions?.[0].code || "",
        periodicity: commissioning?.periodicity || ""
      }
    }
  });

  const watchCriteriaMovement: string|null|undefined = watch('sessionCriteria.criteria_movement')
  const watchMultiCriteria: boolean| null = watch('sessionCriteria.multiCriteria')

  const actionArray = {
    "sessionCalcul": {
      "enabled": false,
      "date": null
    },
    "control": {
      "enabled": false,
      "date": null
    },
    "v2Verification": {
      "enabled": false,
      "date": null
    },
    "validation": {
      "enabled": false,
      "date": null
    },
    "invalidate": {
      "enabled": false,
      "date": null
    },
    "cancel": {
      "enabled": false,
      "date": null
    }
  }

  const editionArray = {
    "accountingEntries": {
      "enabled": false,
      "date": null
    },
    "statementInvoice": {
      "enabled": false,
      "date": null

    },
    "transferFile": {
      "enabled": false,
      "date": null
    },
    "sendToGED": {
      "enabled": false,
      "date": null
    },
    "syntheses": {
      "enabled": false,
      "date": null
    }
  }

  const onSubmit: SubmitHandler<ISettingForm> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: ISettingForm) => {
    const type = commissioningType ? commissioningType.replace('_', '') : ''

    if(uuid) {
      new CommissioningSessionGateway()
        .updateCommissioningSession(data, type, uuid)
        .then((res) => {
          toastSuccess(t('commissioning.notify.update-success'))
        })
        .catch((e) => {
          if(e.data?.errors?.session?.[0]) {
            const errorParsed :any = Object.values(e.data.errors.session[0])
            toastError(t('commissioning.notify.update-error'), errorParsed[0])
          } else if(e.data?.errors?.criteria_movement?.errors?.criteria_movement) {
            toastError(t('commissioning.notify.update-error'), e.data.errors.criteria_movement.errors.criteria_movement)
          } else if(e.data?.errors?.start?.errors?.start) {
            toastError(t('commissioning.notify.update-error'), e.data?.errors?.start?.errors?.start)
          } else if(e.data?.errors?.end?.errors?.end) {
            toastError(t('commissioning.notify.update-error'), e.data?.errors?.end?.errors?.end)
          } else {
            toastError(t('commissioning.notify.update-error'))
          }
        })
    } else {
      new CommissioningSessionGateway()
        .postCommissioningSession(data, type)
        .then((res) => {
          toastSuccess(t('commissioning.notify.add-success'))
          navigate(`/${t('url.commissioning.edit-setting')}/${res.type}/${res.id}`)
        })
        .catch((e) => {
          if(e.data?.errors?.session?.[0]) {
            const errorParsed :any = Object.values(e.data.errors.session[0])
            toastError(t('commissioning.notify.add-error'), errorParsed[0])
          } else if(e.data?.errors?.criteria_movement?.errors?.criteria_movement) {
            toastError(t('commissioning.notify.add-error'), e.data.errors.criteria_movement.errors.criteria_movement)
          } else if(e.data?.errors?.start?.errors?.start) {
            toastError(t('commissioning.notify.add-error'), e.data?.errors?.start?.errors?.start)
          } else if(e.data?.errors?.end?.errors?.end) {
            toastError(t('commissioning.notify.add-error'), e.data?.errors?.end?.errors?.end)
          } else {
            toastError(t('commissioning.notify.add-error'))
          }
        })
    }
    reset(data)
  };

  const {isDirty} = useFormState({ control });

  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}
          ref={measuredRef}
          className="flex-container"
    >
      <SessionSetting register={register}
                      control={control}
                      classes={"col-md-12"}
                      parentPath={"session."}
                      title={t('commissioning.form.setting.session.title')}
      />

      <SessionCriteriaToggle register={register}
                             control={control}
                             watch={watch}
                             getValues={getValues}
                             setValue={setValue}
                             classes={uuid ? "col-md-6" : "col-md-12"}
                             parentPath={"sessionCriteria."}
                             title={t('commissioning.form.setting.sessionCriteria.title')}
                             titleSecondary={t('commissioning.form.setting.sessionCriteria.titleSecondary')}
      />

      {uuid && <Actions register={register}
                        control={control}
                        actionArray={actionArray}
                        parentPath={"actions."}
                        title={t('commissioning.form.setting.actions-editions.title-action')}
      />}

      <EditionAndDownload title={t('commissioning.form.setting.actions-editions.title-edition')}
                          register={register}
                          control={control}
                          editionArray={editionArray}
                          parentPath={"editions."}
                          classes={"col-md-12"}
      />

      <Footer isLectureMode={isLectureMode}
              disabled={!watchMultiCriteria && watchCriteriaMovement === ""}
      />
    </form>

  )
}

export default Setting