import React, {
  ChangeEvent,
  FunctionComponent, SyntheticEvent,
  useEffect,
  useState
} from 'react';
import RoleGateway from "../../../../../../gateway/Role/RoleGateway";
import {useTranslation} from "react-i18next";
import RoleSettingCheckbox from "./RoleSettingCheckbox";
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {RoleInterface} from "../../../../../../domain/Roles/Roles";
import '../../../../../../assets/styles/page/_roleSetting.scss'
import {toastError, toastSuccess} from "../../../../util/Toast";
import {TActions} from "../../../../../../domain/Rule/Rule";

type TRolesResponse = {
  current_page?: number
  data?: RoleInterface[] | null
  nb_element?: boolean
  nb_element_per_page?: boolean
}

const RoleSetting: FunctionComponent = () => {
  const {t} = useTranslation()
  const [roles, setRoles] = useState<TRolesResponse | null>(null)
  const [rolesChanged, setRolesChanged] = useState<RoleInterface[] | null>(null)
  const [rolesSelectedIndex, setRoleSelectedIndex] = useState<number>(-1)
  const [roleSelected, setRoleSelected] = useState<string>("")

  useEffect(() => {
    (new RoleGateway()).getList().then(response => {
      const copyResponse = {...response}
      copyResponse?.data?.forEach((role) => {
        if(role.rules?.setting)  {
          delete (role.rules.setting)
        }
      })
      if(response?.data) {
        setRoles({...response, data: sortRole(response.data)})
      }

    })
  }, [])


  useEffect(() => {
    if (roles?.data) {
      const indexRoleSelected: number = roles?.data.findIndex((role: RoleInterface) => role.label === roleSelected)

      setRoleSelectedIndex(indexRoleSelected)
    }

  }, [roleSelected])

  const sortRole = (roleArray: RoleInterface[]) => {
    const newArray: RoleInterface[] = []
    for (const role of roleArray) {
      switch (role.label) {
        case "Middle Office": newArray.push({...role, order: 1}); break
        case "MO V2": newArray.push({...role, order: 2}); break
        case "Back Office": newArray.push({...role, order: 3}); break
        case "BO V2": newArray.push({...role, order: 4}); break
        case "Front Office": newArray.push({...role, order: 5}); break
        case "FO V2": newArray.push({...role, order: 6}); break
        case "Commerciale": newArray.push({...role, order: 7}); break
        case "Financiers": newArray.push({...role, order: 8}); break
        case "Direction": newArray.push({...role, order: 9}); break
        case "Comptable": newArray.push({...role, order: 10}); break
        case "Conformité": newArray.push({...role, order: 11}); break
        default: break
      }
    }
    return newArray.sort((a,b):number => {
      if(a.order && b.order) {
        return a.order - b.order
      } else {
        return 0
      }
    })
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>, ruleName: string, action: string): void => {
    const copyRoles = {...roles}
    if (copyRoles.data) {
      const updateRole: RoleInterface | any = copyRoles.data[rolesSelectedIndex]

      if(action === "visible") updateRole.rules[ruleName].visible = e.target?.checked
      else updateRole.rules[ruleName].actions[action] = e.target?.checked

      const alreadyUpdatedIndex = rolesChanged?.findIndex((role) => role.id === updateRole.id)

      if ((alreadyUpdatedIndex || alreadyUpdatedIndex === 0) && alreadyUpdatedIndex !== -1 && rolesChanged) {
        const copyRolesChanged = [...rolesChanged]
        copyRolesChanged[alreadyUpdatedIndex] = updateRole
        setRolesChanged(copyRolesChanged)
      } else if (rolesChanged) setRolesChanged([...rolesChanged, updateRole])
      else setRolesChanged([updateRole])

      setRoles({...copyRoles})
    }
  }

  const selectOrDeselectAll = (e: ChangeEvent<HTMLInputElement>, actions: TActions, ruleName: string, visible: boolean) => {
    const copyRoles = {...roles}
    if (!copyRoles || !copyRoles.data) return

    const updateRole = copyRoles?.data?.[rolesSelectedIndex]
    const updateRoleActions = Object.entries(copyRoles.data[rolesSelectedIndex].rules).filter((el) => el[0] === ruleName)

    if (actions.read && actions.update && actions.export && visible && updateRole) {
      updateRoleActions[0][1].actions.update = false
      updateRoleActions[0][1].actions.export = false
      updateRoleActions[0][1].actions.read = false
      if(ruleName !== "customer") updateRoleActions[0][1].actions.create = false
      updateRoleActions[0][1].actions.list = false
      updateRoleActions[0][1].visible = false

    } else if (copyRoles && copyRoles.data && updateRole) {
      updateRoleActions[0][1].actions.update = true
      updateRoleActions[0][1].actions.export = true
      updateRoleActions[0][1].actions.read = true
      if(ruleName !== "customer") updateRoleActions[0][1].actions.create = true
      updateRoleActions[0][1].actions.list = true
      updateRoleActions[0][1].visible = true
    }

    const alreadyUpdatedIndex = rolesChanged?.findIndex((role) => role.id === updateRole.id)

    if ((alreadyUpdatedIndex || alreadyUpdatedIndex === 0) && alreadyUpdatedIndex !== -1 && rolesChanged) {
      const copyRolesChanged = [...rolesChanged]
      copyRolesChanged[alreadyUpdatedIndex] = updateRole
      setRolesChanged(copyRolesChanged)
    } else if (rolesChanged) setRolesChanged([...rolesChanged, updateRole])
    else setRolesChanged([updateRole])

    setRoles({...copyRoles})
  }

  const onSubmit = (event: SyntheticEvent): void => {
    event?.preventDefault()
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={onConfirm} onClose={onClose}/>)
      }
    });
  }

  const updateRole = (role: RoleInterface) => {
    return new RoleGateway().update(role).then((res) => {
      if (null === res) {
        throw "error request"
      }
    })
  }

  const onConfirm = async () => {
    if (rolesChanged?.length) {
      try {
        const updateRoles = rolesChanged.map(async (r) => {
          await updateRole(r)
        })
        await Promise.all(updateRoles)
        toastSuccess(t('settings.notify.update-success'))
        setRolesChanged(null)
      } catch (e) {
        toastError(t('settings.notify.update-error'));
      }
    } else {
      toastError(t('settings.notify.update-error-no-change'));
    }
  }

  if (!roles) return null

  return (
    <section>
      <div>
        <p>{t('settings.form.bloc-role-settings.title')}</p>
        {roles && (
          <div className="bloc-select u-mbm">
            <label className="form-control__label u-mrxl"
                   htmlFor="roles-select">{t('settings.form.bloc-role-settings.select-label')}</label>
            <div>
              <select value={roleSelected}
                      onChange={(e) => setRoleSelected(e.target.value)}
                      id="roles-select"
              >
                <option value="">{t('common.choice-option')}</option>
                {roles?.data?.map((option: RoleInterface, index: number) =>
                  (
                    <option key={index} value={option.label}>{t(`settings.user-profiles.form.role.${option.label}`)}</option>
                  )
                )}
              </select>
            </div>
          </div>
        )
        }
        {roles?.data?.[rolesSelectedIndex] && (
          <RoleSettingCheckbox role={roles.data[rolesSelectedIndex]}
                               handleChange={handleChange}
                               selectOrDeselectAll={selectOrDeselectAll}
          />)
        }
      </div>
      <footer className={`form-bloc__footer`}>
        <button type="submit"
                onClick={onSubmit}
                className="button button--ink-2">
          {t('common.save')}
        </button>
        <button type="button"
                className="button button--ink-2 button--ink-2--outline">
          {t('common.cancel')}
        </button>
      </footer>
    </section>
  );
}

export default RoleSetting;
