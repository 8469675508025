import {CommissioningSessionInterface} from '../../domain/Commissioning/CommissioningList'
import Caller from '../../domain/Api/Caller'
import envVariable from '../../infrastructure/presentation/util/envVariable'
import {ISettingForm} from "../../domain/Commissioning/setting";
import {CallerType} from "../../domain/Caller/Caller";
import {
  CommissioningSessionListInterface,
  FilterCommissioningSessionInterface
} from "../../domain/Commissioning/CommissioningSessionList";
import CommissioningSessionGatewayInterface from "../../domain/Commissioning/CommissioningSessionGatewayInterface";

export default class CommissioningSessionGateway implements CommissioningSessionGatewayInterface {

  getCommissioningSession(id: string): Promise<CommissioningSessionInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/commission/sessions/${id}`, {})
      .then(data => {
        if (data) {
          return data
        }
      })
  }

  postCommissioningSession(session: ISettingForm, type: string): Promise<CommissioningSessionInterface> {

    let params = {}
    if(session.sessionCriteria.multiCriteria) {
      params = {
        type: type,
        criteriaType: "multi",
        start: session.sessionCriteria.dateStart,
        end: session.sessionCriteria.dateEnd,
        regularisation: session.sessionCriteria.regularization,
        criteria_products: session.sessionCriteria.products.map((it: any) => it.value),
        criteria_partners: session.sessionCriteria.partners.map((it: any) => it.value),
        paymentScheduledAt: session.session.sessionPayment,
        periodicity: session.sessionCriteria.periodicity
      }
    } else {
      params = {
        type: type,
        criteriaType: "unique",
        criteria_movement: session.sessionCriteria?.criteria_movement,
      }
    }

    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/commission/sessions`, params
    ).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    })
  }

  updateCommissioningSession(session: ISettingForm, type: string, uuid: string): Promise<CommissioningSessionInterface> {
    let params = {}

    if(session.sessionCriteria.multiCriteria) {
      params = {
        type: type,
        criteriaType: "multi",
        start: session.sessionCriteria.dateStart,
        end: session.sessionCriteria.dateEnd,
        regularisation: session.sessionCriteria.regularization,
        criteria_products: session.sessionCriteria.products.map((it: any) => it.value),
        criteria_partners: session.sessionCriteria.partners.map((it: any) => it.value),
        paymentScheduledAt: session.session.sessionPayment,
        periodicity: session.sessionCriteria.periodicity
      }
    } else {
      params = {
        type: type,
        criteriaType: "unique",
        criteria_movement: session.sessionCriteria?.criteria_movement,
      }
    }

    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/commission/sessions/${uuid}`, {...params}).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    })
  }

  getCommissioningSessionMovement(
    currentPage: number,
    itemsPerPage: number,
    filter: FilterCommissioningSessionInterface|null,
    sessionId: string,
    signal?: AbortSignal,
    sort?: string,
    order?: string,
    callerType?: CallerType,
  ): Promise<{data: CommissioningSessionListInterface[]|null, numberOfItems: number, session: CommissioningSessionInterface}|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/commission/sessions/${sessionId}/movements${callerType === "datalist" ? "-datalist" : ""}?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`, {
        'label': filter?.name || '',
        'keywords': filter?.keywords || '',
        'status': filter?.status?.map((status) => status.value) || ""
      },
      signal
    ).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    }).catch(response => {
      return {
        data: [],
        numberOfItems: 0
      }
    });
  }

  getExport(filter: FilterCommissioningSessionInterface|null): Promise<string|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/commissionings/extract`, {
      name: filter?.name || '',
      keywords: filter?.keywords || '',
    })
      .then(data => {
        if (data && data.content) {
          return data.content
        }

        return null
      }).catch(response => {
        return null
      })
  }
}
