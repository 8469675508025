interface SpecificityInterface {
  mandatSignedAt: string|null
  encartageAt: string|null
  code: string|null
  formationInitialAt: string|null
  formationYearAt: string|null
  policyNumber: string|null
  company: string|null
}

class Specificity implements SpecificityInterface{

  public mandatSignedAt: string|null
  public encartageAt: string|null
  public code: string|null
  public formationInitialAt: string|null
  public formationYearAt: string|null
  public policyNumber: string|null
  public company: string|null

  constructor(
    mandatSignedAt: string|null,
    encartageAt: string|null,
    code: string|null,
    formationInitialAt: string|null,
    formationYearAt: string|null,
    policyNumber: string|null,
    company: string|null
  ) {
    this.mandatSignedAt = mandatSignedAt
    this.encartageAt = encartageAt
    this.code = code
    this.formationInitialAt = formationInitialAt
    this.formationYearAt = formationYearAt
    this.policyNumber = policyNumber
    this.company = company
  }
}

export {
  Specificity
}
export type { SpecificityInterface }
