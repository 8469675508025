import {ListRequestInterface} from './ListRequest'
import PledgeGateway from "../../../gateway/Pledge/PledgeGateway";
import {PledgeListInterface} from "../../../domain/Pledge/PledgeList";

export default class ListUseCase
{
  public pledgeGateway

  constructor(PledgeGateway: PledgeGateway) {
    this.pledgeGateway = PledgeGateway
  }

  async execute(ListRequest: ListRequestInterface): Promise<{data: PledgeListInterface[]|null, numberOfItems: number}|null> {
    return this.pledgeGateway.getList(ListRequest.currentPage, ListRequest.itemPerPage, ListRequest.filter, ListRequest.investorId).then(response => {
      return response
    })
  }
}
