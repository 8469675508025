import {Presenter} from '../../Presenter'
import {SliceListInterface} from '../../../domain/Share/Slice/SliceList'

class ProductListPresenter extends Presenter {

  private useCase: { getSlices: any }

  constructor(getSlices: any) {
    super({
      viewModel: {
        title: 'movement.search-a-share',
        heading: [
          {name: 'movement.heading-slice.product-acronym', sort: "product.acronyme"},
          {name: 'movement.heading-slice.esn-type'},
          {name: 'movement.heading-slice.date', sort: "slice.created_at"},
          {name: 'movement.heading-slice.begin', sort: "start_part"},
          {name: 'movement.heading-slice.end',  sort: "end_part"},
          {name: 'movement.heading-slice.price', sort: "price"},
          {name: 'movement.heading-slice.price-vl', sort: "price_subscription"},
          {name: 'movement.heading-slice.nb-share-by-slice', sort: "share_count"},
          {name: 'movement.heading-slice.nb-share-transfer'},
          {name: 'movement.heading-slice.save'}
        ],
        datas: [],
        filtersShortcut: [
          {'keyword': 'filters.by-keyword-or-id', 'field': 'keywords', 'type': 'input'}
        ],
        filters: [
        ],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getSlices,
    }
  }

  async load() {
    try {
      this._setSlices(await this.useCase.getSlices);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setSlices(slices: { data: SliceListInterface[]|null, nb_element: number }) {
    this.update({
      data: slices.data,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': slices.nb_element,
      }
    });
  }
}

export default ProductListPresenter
