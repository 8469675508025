import Caller from '../../domain/Api/Caller';
import envVariable from '../../infrastructure/presentation/util/envVariable';
import {RoleInterface} from "../../domain/Roles/Roles";

type TRolesResponse = {
    current_page: number
    data: RoleInterface[] | []
    nb_element: boolean
    nb_element_per_page: boolean
}

export default class RoleGateway {
    getList(): Promise<TRolesResponse | null> {
        return Caller.executeGet(`${ envVariable('REACT_APP_API_URL') }/roles`, {})
            .then(data => {
                if (data) {
                    return data;
                }
                return {
                    data: [],
                    numberOfItems: 0,
                };
            }).catch(response => {
                return {
                    data: [],
                    numberOfItems: 0,
                };
            });
    }
    update(role: RoleInterface): Promise<any |null> {
        return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/roles/${role.id}`, {
            label: role.label,
            description: role.description,
            enabled: role.enabled,
            routes: role.routes,
            rules: role.rules
        })
            .then(data => {
                if (data) {
                    return data
                }

                return null
            })
            .catch(err => {
                return null
            })
    }
}