import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import {useTranslation} from 'react-i18next'
import {FormProvider, SubmitHandler, useForm, useFormState} from 'react-hook-form'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../../store/hook'
import {
  setOpenChoiceInvestorEvent,
  setOpenChoicePartnerEvent,
  setOpenUploadForm
} from '../../../../../store/component/event'
import {ProductInterface} from '../../../../../../domain/Product/Product'
import {ReferentielInterface} from '../../../../../../domain/Referentiel/ReferentielInterface'
import {Recurrence} from '../../../../../../domain/PreSubscription/Recurrence'
import {Investor} from '../../../../../../domain/Movement/Dismemberment/Investor'
import ProductDalalist from '../../../Datalist/Product/ProductDalalist'
import ProductGateway from '../../../../../../gateway/Product/ProductGateway'
import PreSubscriptionGateway from '../../../../../../gateway/PreSubscription/PreSubscriptionGateway'
import Input from '../../../Elements/Input'
import SelectCustom from '../../../Elements/Select'
import InputDate from '../../../Elements/InputDate'
import CheckboxToggle from '../../../Elements/CheckboxToggle'
import {BankInformation} from '../../../../../../domain/Prospect/BankInformation'
import {
  reloadMovement,
  setPartner,
  setPresubscriptionOwner,
  setPresubscriptionUsufructuary
} from '../../../../../store/component/movement'
import {ActionListInterface} from '../../../../../../domain/Movement/Action/Action'
import AlertInfo from '../../../Alert/AlertInfo/AlertInfo'

import eyeIcon from '../../../../../../assets/images/icons/eye.svg'
import uploadIcon from '../../../../../../assets/images/icons/upload.svg'
import iconAdd from '../../../../../../assets/images/icons/add.svg'
import '../../../../../../assets/styles/page/_movement.scss'
import Status from "../../../Transaction/Status";
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {usePrompt} from "../../../../util/Navigation";
import {DismembermentInterface} from "../../../../../../domain/Movement/Dismemberment/Dismemberment";
import DismembermentGateway from "../../../../../../gateway/Movement/Dismemberment/DismembermentGateway";
import GetUseCase from "../../../../../../useCase/PreSubscription/Get/GetUseCase";
import ProspectGateway from "../../../../../../gateway/Prospect/ProspectGateway";
import {RibInterface} from "../../../../../../domain/Rib/Rib";
import DocumentGateway from "../../../../../../gateway/Movement/Dismemberment/Document/DocumentGateway";
import DocumentForm from "../../Document/Form";
import ActionsForm from "../../Action/ActionsForm";
import {v4 as uuidV4} from 'uuid'
import ActionReason from "../../../Transaction/ActionReason";
import MovementTag from "../../../Tag/MovementTag/MovementTag";
import ReactTooltip from "react-tooltip";
import RecapForm from "../../Recap/RecapForm";
import iconSearch from '../../../../../../assets/images/icons/datalist-search.svg';
import DocumentUtils from "../../../../util/DocumentUtils";
import ProspectUtils from "../../../../util/ProspectUtils";
import FooterBlock from "../../../Block/FooterBlock";
import NoteBlock from "../../../Block/NoteBlock";
import CKEditorUtil from "../../../../util/CKEditor";
import CommentBlock from "../../../Block/CommentBlock";
import List from "../../../../util/List";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import FormErrorInfoDropdown from "../../../Alert/FormErrorInfoDropdown/FormErrorInfoDropdown";
import {DocumentInterface, DocumentType} from "../../../../../../domain/Document/DocumentInterface";
import {PersonInterface} from "../../../../../../domain/Prospect/Person";
import {BankInformationFormInterface} from "../../../../../../domain/Movement/BankInformation";
import BankInformationUtils from "../../../../util/BankInformationUtils";
import {ReferentielItemInterface} from "../../../../../../domain/Referentiel/ReferentielItemInterface";
import BankInformationBlock from "../../Blocks/Movement/BankInformation";
import InputNumber from "../../../Elements/InputNumber";

interface IFormInput {
  generalInformation: {
    product: {
      id: string|null,
      label: string|null,
      value: string|null
    }
    movementNumber: string|null
    propertyType: string|null
    administrator: string|null
    possessionMode: string|null
    subscribedAt: string|null
    horodatage: string|null
    updatedAt: string|null
    lastUpdateUser: string|null
  }
  investor: {
    first: {
      id: string|null
      identity: string|null
      partnerNumber: string
      partnerSocialReason: string
      platformNetworkNumber: string|null
      platformNetworkLastName: string|null
      partnerType: string|null
      prospectCode: string|null
    }
    second: {
      id: string|null
      identity: string|null
      partnerNumber: string
      partnerSocialReason: string
      platformNetworkNumber: string|null
      platformNetworkLastName: string|null
      partnerType: string|null
      prospectCode: string|null
    }
  }
  particularity: {
    minor: boolean
    notFrench: boolean
    recurrent: boolean
    counterpartyResearch: boolean
    ppe: boolean
    ter: boolean
    dividendReinvestment: boolean
    duration: number|null
    lifeAnnuity: boolean
    guardianship: boolean
    investment50k: boolean
  }
  movement: {
    shareCount: number|null
    sharePrice: number|null
    totalAmount: number|null
    investorFirst: {
      dismembermentKey: string|null
      dismembermentAmount: number|null
      paymentMode: string|null
      checkDepositAt: string|null
      paymentStatus: string|null
      paymentNature: string|null
      settlementDate: string|null
    }
    investorSecond: {
      dismembermentKey: string|null
      dismembermentAmount: number|null
      paymentMode: string|null
      checkDepositAt: string|null
      paymentStatus: string|null
      paymentNature: string|null
      settlementDate: string|null
    }
  }
  recurrence: {
    numRecurrence: string|null
    period: {start:string|null, end: string|null}
    periodicity: string|null
    shareCount: number|null
    sharePrice: number|null
    totalAmount: number|null
    rumCode: string|null
    rumStatus: string|null
  }
  note: string
  comment: string

  documents: {
    [key: string] : {
      [key: string] : {
        [key: string] : {
          date: string
          updatedAt: string
        }
      }
    }
  }

  bankInformations: BankInformationFormInterface
}

type Props = {
  movement: DismembermentInterface,
  isLectureMode: boolean
}

const Form: FunctionComponent<Props> = ({movement, isLectureMode}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const partners = useAppSelector((state) => state.movement.partners)
  const presubscriptions = useAppSelector((state) => state.movement.presubscriptions)
  const [showUsufruitier, setShowUsuFruitier] = useState<boolean>(false)
  const [showCounterPart, setShowCounterPart] = useState<boolean>(false)
  const [product, setProduct] = useState<ProductInterface|null>(null)
  const [decimalStep, setDecimalStep] = useState<number>(8)
  const openUploadForm = useAppSelector((state) => state.event.openUploadForm)
  const [documentArray, setDocumentArray] = useState<DocumentType>({})
  const [uuidRefresh, setUuidRefresh] = useState<string>('')
  const [actions, setActions] = useState<ActionListInterface|null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formError, setFormError] = useState<any>(null)
  const [ownerLink, setOwnerLink] = useState<string|null>(null)
  const [partnerLink, setPartnerLink] = useState<string|null>(null)
  const [usuLink, setUsuLink] = useState<string|null>(null)
  const [secondPartnerLink, setSecondPartnerLink] = useState<string|null>(null)
  const documentUtils = new DocumentUtils()
  const prospectUtils = new ProspectUtils()
  const bankInformationUtils = new BankInformationUtils()
  const isUpdatingDismemberment = useRef(false)

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, [isLectureMode]);

  useEffect(() => {
    new DocumentGateway(movement.id, null).getFiles().then((response) => {
      if (response) {
        setDocumentArray(response)
      }
    })
  }, [openUploadForm])

  useEffect(() => {
    if (movement && movement.investors) {
      movement.investors.map((investor) => {
        if (investor.prospect && investor.prospect.persons) {
          investor.prospect.persons.map((person: PersonInterface) => {
            if (person.id && documentArray[person.id]) {
              documentArray[person.id].map((doc: DocumentInterface) => {
                setValue(`documents.${investor?.prospect?.prospectCode}.${person.id}.${doc.type}.date`, doc.date)
                setValue(`documents.${investor?.prospect?.prospectCode}.${person.id}.${doc.type}.updatedAt`, doc.updatedAt)
              })
            }
          })
        }
      })
    }
  }, [documentArray])

  useEffect(() => {
    const investor = movement.investors?.find((investor) => {
      return investor?.type === "investor" || null
    })
    if (investor && investor.dismembermentTransaction && investor.dismembermentTransaction.id) {
        (new GetUseCase(new PreSubscriptionGateway()).execute(investor.dismembermentTransaction.id).then(response => {
          dispatch(setPresubscriptionOwner(new Investor(
            response.id,
            investor.type,
            investor.amount,
            investor.paymentMode,
            investor.paymentStatus,
            investor.paymentNature,
            investor.paymentDate,
            investor.dismembermentKey,
            investor.bankInformations,
            response,
            investor.checkDepositAt,
            null,
            investor.documents
          )))
        }))
    }

    const usufructuary = movement.investors?.find((investor) => {
      return investor?.type === "usufruct" || null
    })
    if (usufructuary && usufructuary.dismembermentTransaction && usufructuary.dismembermentTransaction.id) {
      (new GetUseCase(new PreSubscriptionGateway()).execute(usufructuary.dismembermentTransaction.id).then(response => {
        dispatch(setPresubscriptionUsufructuary(new Investor(
          response.id,
          usufructuary.type,
          usufructuary.amount,
          usufructuary.paymentMode,
          usufructuary.paymentStatus,
          usufructuary.paymentNature,
          usufructuary.paymentDate,
          usufructuary.dismembermentKey,
          usufructuary.bankInformations,
          response,
          usufructuary.checkDepositAt,
          null,
          usufructuary.documents
        )))
      }))
    }

    if (movement && movement.investors) {
      movement.investors.map((investor) => {
        investor.bankInformations.map((bankI) => {
          if (investor.prospect) {
            setValue(
              `bankInformations.${investor.prospect.prospectCode}.${bankI.type}`,
              {
                id: bankI.id,
                bankInformationSelected: bankI.bankInformation?.id ?? null,
                bankInformation: {
                  id: bankI.bankInformation?.id ?? null,
                  label: bankI.bankInformation?.label ?? null,
                  bank: bankI.bankInformation?.bank ?? null,
                  iban: bankI.bankInformation?.iban ?? null,
                  swift: bankI.bankInformation?.swift ?? null,
                },
                directDebitMandateAccepted: bankI.directDebitMandateAccepted,
                rumCode: bankI.rumCode,
                rumStatus: bankI.rumStatus
              }
            )
          }
        })
      })
    }

    return () => {
      dispatch(setPartner({partner: null, key: "reset"}))
    }
  }, [movement])

  useEffect(() => {
    if (
      presubscriptions.owner &&
      presubscriptions.owner.dismembermentTransaction &&
      presubscriptions.owner.dismembermentTransaction.investors &&
      presubscriptions.owner.dismembermentTransaction.investors[0] &&
      presubscriptions.owner.dismembermentTransaction.investors[0].prospect &&
      presubscriptions.owner.dismembermentTransaction.investors[0].prospect.persons &&
      presubscriptions.owner.dismembermentTransaction.investors[0].prospect.persons[0]
    ) {

      if (movement && movement.investors) {
        movement.investors.map((investor, index) => {
          if (investor.id === presubscriptions?.owner?.id && movement && movement.investors && movement.investors[index]) {
            movement.investors[index] = presubscriptions.owner
          }
        })
      }

      setOwnerLink(presubscriptions.owner.dismembermentTransaction?.investors[0].prospect.id)
      setValue('movement.investorFirst.dismembermentKey', presubscriptions.owner.dismembermentKey)
      setValue('movement.investorFirst.dismembermentAmount', presubscriptions.owner.amount)
      setValue('movement.investorFirst.checkDepositAt', presubscriptions.owner.checkDepositAt)
      setValue('movement.investorFirst.paymentMode', presubscriptions.owner.paymentMode)
      setValue('movement.investorFirst.paymentStatus', presubscriptions.owner.paymentStatus)
      setValue('movement.investorFirst.paymentNature', presubscriptions.owner.paymentNature)
      setValue('movement.investorFirst.settlementDate', presubscriptions.owner.paymentDate)
      setValue('investor.first.prospectCode', presubscriptions.owner.dismembermentTransaction.investors[0].prospect.prospectCode)
      setValue('investor.first.id', presubscriptions.owner.dismembermentTransaction.investors[0].prospect.id)
      setValue('investor.first.identity', prospectUtils.formatProspectPersonIdentity(presubscriptions.owner.dismembermentTransaction.investors[0].prospect))
      setValue('investor.first.platformNetworkNumber', presubscriptions.owner.dismembermentTransaction.investors[0].prospect.partner?.platform ?? "")
      setValue('investor.first.platformNetworkLastName', presubscriptions.owner.dismembermentTransaction.investors[0].prospect.partner?.network ?? "")
    }
  }, [presubscriptions.owner])

  useEffect(() => {
    if (
      presubscriptions.usufructuary &&
      presubscriptions.usufructuary.dismembermentTransaction &&
      presubscriptions.usufructuary.dismembermentTransaction.investors &&
      presubscriptions.usufructuary.dismembermentTransaction.investors[0] &&
      presubscriptions.usufructuary.dismembermentTransaction.investors[0].prospect &&
      presubscriptions.usufructuary.dismembermentTransaction.investors[0].prospect.persons &&
      presubscriptions.usufructuary.dismembermentTransaction.investors[0].prospect.persons[0]
    ) {

      if (movement && movement.investors) {
        movement.investors.map((investor, index) => {
          if (investor.id === presubscriptions?.usufructuary?.id && movement && movement.investors && movement.investors[index]) {
            movement.investors[index] = presubscriptions.usufructuary
          }
        })
      }

      setValue('movement.investorSecond.dismembermentKey', presubscriptions.usufructuary.dismembermentKey)
      setValue('movement.investorSecond.dismembermentAmount', presubscriptions.usufructuary.amount)
      setValue('movement.investorSecond.checkDepositAt', presubscriptions.usufructuary.checkDepositAt)
      setValue('movement.investorSecond.paymentMode', presubscriptions.usufructuary.paymentMode)
      setValue('movement.investorSecond.paymentStatus', presubscriptions.usufructuary.paymentStatus)
      setValue('movement.investorSecond.paymentNature', presubscriptions.usufructuary.paymentNature)
      setValue('movement.investorSecond.settlementDate', presubscriptions.usufructuary.paymentDate)

      setUsuLink(presubscriptions.usufructuary.dismembermentTransaction.investors[0].prospect.id)
      setValue('investor.second.prospectCode', presubscriptions.usufructuary.dismembermentTransaction.investors[0].prospect.prospectCode)
      setValue('investor.second.id', presubscriptions.usufructuary.dismembermentTransaction.investors[0].prospect.id)
      setValue('investor.second.identity', prospectUtils.formatProspectPersonIdentity(presubscriptions.usufructuary.dismembermentTransaction.investors[0].prospect))
      setValue('investor.second.platformNetworkNumber', presubscriptions.usufructuary.dismembermentTransaction.investors[0].prospect.partner?.platform ?? "")
      setValue('investor.second.platformNetworkLastName', presubscriptions.usufructuary.dismembermentTransaction.investors[0].prospect.partner?.network ?? "")
    }
  }, [presubscriptions.usufructuary])

  useEffect(() => {
    if (movement.id) {
      setValue('generalInformation.lastUpdateUser', (movement.lastUpdateUser ?  `${movement.lastUpdateUser.lastname} ${movement.lastUpdateUser.firstname}` : ''))
      setValue('generalInformation.updatedAt', movement.updatedAt)
    }
  }, [movement])

  const methods = useForm<IFormInput>({
    defaultValues: {
      generalInformation: {
        product: {
          id: movement.product?.id,
          label: movement.product?.label,
          value: movement.product?.id
        },
        horodatage: movement.horodatage,
        movementNumber: movement.transactionCode,
        propertyType: movement.propertyType,
        administrator: movement.user ?  `${movement.user.lastname} ${movement.user.firstname}` : '',
        possessionMode: movement.possessionMode,
        subscribedAt: movement.subscribedAt,
        updatedAt: movement.updatedAt,
        lastUpdateUser: movement.lastUpdateUser ?  `${movement.lastUpdateUser.lastname} ${movement.lastUpdateUser.firstname}` : '',
      },
      particularity: {
        minor: movement.minor,
        notFrench: movement.notFrench,
        recurrent: movement.recurrent,
        counterpartyResearch: movement.counterpartyResearch,
        ppe: movement.ppe,
        ter: movement.ter,
        dividendReinvestment: movement.dividendReinvestment,
        duration: movement.duration,
        lifeAnnuity: movement.lifeAnnuity,
        guardianship: movement.guardianship,
        investment50k: movement.investment50k
      },
      movement: {
        shareCount: movement.shareCount,
        sharePrice: movement.sharePrice,
        totalAmount: movement.totalAmount,
        investorFirst: {
          dismembermentKey: presubscriptions.owner?.dismembermentKey,
          dismembermentAmount: presubscriptions.owner?.amount,
          paymentMode: presubscriptions.owner?.paymentMode,
          checkDepositAt: presubscriptions.owner?.checkDepositAt,
          paymentStatus: presubscriptions.owner?.paymentStatus,
          paymentNature: presubscriptions.owner?.paymentNature,
          settlementDate: presubscriptions.owner?.paymentDate,
        },
        investorSecond: {
          dismembermentKey: presubscriptions.usufructuary?.dismembermentKey,
          dismembermentAmount: presubscriptions.usufructuary?.amount,
          paymentMode: presubscriptions.usufructuary?.paymentMode,
          checkDepositAt: presubscriptions.usufructuary?.checkDepositAt,
          paymentStatus: presubscriptions.usufructuary?.paymentStatus,
          paymentNature: presubscriptions.usufructuary?.paymentNature,
          settlementDate: presubscriptions.usufructuary?.paymentDate,
        }
      },
      recurrence: movement.recurrence ? {
        numRecurrence: movement.recurrence.id,
        period: movement.recurrence.period ? {
          start: movement.recurrence.period.start,
          end: movement.recurrence.period.end,
        }: {start: null, end: null},
        periodicity: movement.recurrence.periodicity,
        shareCount: movement.recurrence.shareCount,
        sharePrice: movement.recurrence.sharePrice,
        totalAmount: movement.recurrence.totalAmount,
        rumCode: movement.recurrence.rumCode,
        rumStatus: movement.recurrence.rumStatus,
      }: {numRecurrence: null, period: {start: null, end: null}, periodicity: null, shareCount: null, sharePrice: null, totalAmount: null, rumCode: null, rumStatus: null}
    }
  })
  const { register, control, handleSubmit, reset, watch, setValue, getValues } = methods

  const watchPropertyType = watch('generalInformation.propertyType')
  const watchProduct = watch('generalInformation.product')
  const watchParticularityRecurrence = watch('particularity.recurrent')
  const watchDuration = watch('particularity.duration')
  const watchLifeAnnuity = watch('particularity.lifeAnnuity')
  const watchMovementShareCount = watch('movement.shareCount')
  const watchMovementSharePrice = watch('movement.sharePrice')
  const watchReccurenceShareCount = watch('recurrence.shareCount')
  const watchReccurenceSharePrice = watch('recurrence.sharePrice')
  const watchInvestorFirstPaymentMode = watch('movement.investorFirst.paymentMode')
  const watchInvestorSecondPaymentMode = watch('movement.investorSecond.paymentMode')
  const watchCounterpartyResearch = watch('particularity.counterpartyResearch')

  //  DS-53 START
  const movementTotalAmount = getValues('movement.totalAmount')
  const calcMinMaxPercent = (key: string) => {
    let floatKey = parseFloat(key)
    if (floatKey < 0) floatKey = 0
    if (100 < floatKey) floatKey = 100
    return floatKey
  }
  const calcMinMaxAmount = (amount: number) => {
    if (amount < 0) amount = 0
    const totalAmount = movementTotalAmount ?? 0
    if (totalAmount < amount) amount = totalAmount
    return amount
  }
  const setBlocMouvementKeys = (firstDismembermentKey: number | null, secondDismembermentKey: number | null) => {
    const _firstDismembermentKey = (firstDismembermentKey !== null && !isNaN(firstDismembermentKey)) ? firstDismembermentKey.toString().replace('.', ',') : ""
    const _secondDismembermentKey = (secondDismembermentKey !== null && !isNaN(secondDismembermentKey)) ? secondDismembermentKey.toString().replace('.', ',') : ""
    setValue('movement.investorFirst.dismembermentKey', _firstDismembermentKey)
    setValue('movement.investorSecond.dismembermentKey', _secondDismembermentKey)
  }
  const setBlocMouvementAmount = (firstAmount: number, secondAmount: number) => {
    setValue('movement.investorFirst.dismembermentAmount', firstAmount)
    setValue('movement.investorSecond.dismembermentAmount', secondAmount)
  }
  const setBlocMouvementAMountsByKeys = (montantTotal: number | null, firstDismKey: number | null, secondDismKey: number | null) => {
    if (montantTotal !== null) {
      if (firstDismKey !== null && !isNaN(firstDismKey)) {
        const calcFirstDismembermentAmount = (montantTotal ?? 1) * ((firstDismKey ?? 100) / 100)
        setValue('movement.investorFirst.dismembermentAmount', calcFirstDismembermentAmount)
      }
      if (secondDismKey !== null && !isNaN(secondDismKey)) {
        const calcSecondDismembermentAmount = (montantTotal ?? 1) * ((secondDismKey ?? 100) / 100)
        setValue('movement.investorSecond.dismembermentAmount', calcSecondDismembermentAmount)
      }
    }
  }

  function handleFirstKey(value: string) {
    isUpdatingDismemberment.current = true
    const firstKey = value
    if (firstKey) {
      const firstDismembermentKey = calcMinMaxPercent(firstKey)
      const secondDismembermentKey = 100 - firstDismembermentKey
      setBlocMouvementKeys(firstDismembermentKey, secondDismembermentKey)
      setBlocMouvementAMountsByKeys(movementTotalAmount, firstDismembermentKey, secondDismembermentKey)
    }
    setTimeout(() => isUpdatingDismemberment.current = false, 100)
  }

  function handleSecondKey(value: string) {
    isUpdatingDismemberment.current = true
    const secondKey = value
    if (secondKey) {
      const secondDismembermentKey = calcMinMaxPercent(secondKey)
      const firstDismembermentKey = 100 - secondDismembermentKey
      setBlocMouvementKeys(firstDismembermentKey, secondDismembermentKey)
      setBlocMouvementAMountsByKeys(movementTotalAmount, firstDismembermentKey, secondDismembermentKey)
    }
    setTimeout(() => isUpdatingDismemberment.current = false, 100)
  }

  function handleFirstAmount(value: string) {
    const firstAmount = value ? parseFloat(value) : 0
    if (firstAmount) {
      const firstMovAmount = calcMinMaxAmount(firstAmount)
      const secondMovAmount = (movementTotalAmount ?? 0) - firstMovAmount
      setBlocMouvementAmount(firstMovAmount, secondMovAmount)
      const firstKey = firstMovAmount * 100 / (movementTotalAmount ?? 1)
      setBlocMouvementKeys(firstKey, 100 - firstKey)
    }
  }

  function handleSecondAmount(value: string) {
    const secondAmount = value ? parseFloat(value) : 0
    if (secondAmount) {
      const secondMovAmount = calcMinMaxAmount(secondAmount)
      const firstMovAmount = (movementTotalAmount ?? 0) - secondMovAmount
      setBlocMouvementAmount(firstMovAmount, secondMovAmount)
      const secondKey = secondMovAmount * 100 / (movementTotalAmount ?? 1)
      setBlocMouvementKeys(100 - secondKey, secondKey)
    }
  }

  const handleNanNumber = (s: string | null) => s !== null && s !== 'NaN' ? s : ' '

  if (movementTotalAmount) {
    const investFirstKey = getValues('movement.investorFirst.dismembermentKey')
    const investSecondKey = getValues('movement.investorSecond.dismembermentKey')
    setBlocMouvementAMountsByKeys(movementTotalAmount, parseFloat(handleNanNumber(investFirstKey)), parseFloat(handleNanNumber(investSecondKey)))
  }
  if (watchPropertyType === 'np') {
    setBlocMouvementKeys(100, 0)
    setBlocMouvementAMountsByKeys(movementTotalAmount, 100, 0)
  }
  if (watchPropertyType === 'usufruct') {
    setBlocMouvementKeys(0, 100)
    setBlocMouvementAMountsByKeys(movementTotalAmount, 0, 100)
  }
  // DS-53 END

  useEffect(() => {
    if (watchPropertyType) {
      setShowUsuFruitier(watchPropertyType === 'dismemberment')
      setShowCounterPart(watchPropertyType === 'np' || watchPropertyType === 'usufruct')
    }
  }, [watchPropertyType])

  useEffect(() => {
    if (watchReccurenceShareCount && watchReccurenceSharePrice) {
      setValue('recurrence.totalAmount', watchReccurenceShareCount * watchReccurenceSharePrice)
    }
  }, [watchReccurenceShareCount, watchReccurenceSharePrice])

  useEffect(() => {
    if (watchMovementShareCount && watchMovementSharePrice) {
      setValue('movement.totalAmount', watchMovementShareCount * watchMovementSharePrice)
    }
  }, [watchMovementShareCount, watchMovementSharePrice])

  useEffect(() => {
    if (watchProduct && watchProduct.id) {
      new ProductGateway().get(watchProduct.id).then(response => {
        setProduct(response)
      })
    }
  }, [watchProduct])

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    reset(data)
    movement.propertyType = data.generalInformation.propertyType
    movement.possessionMode = data.generalInformation.possessionMode
    movement.subscribedAt = data.generalInformation.subscribedAt
    movement.minor = data.particularity.minor
    movement.notFrench = data.particularity.notFrench
    movement.ppe = data.particularity.ppe
    movement.recurrent = data.particularity.recurrent
    movement.guardianship = data.particularity.guardianship
    movement.product = product
    movement.horodatage = data.generalInformation.horodatage
    movement.comment = data.comment
    movement.note = data.note

    movement.duration = data.particularity.duration
    movement.dividendReinvestment = data.particularity.dividendReinvestment
    movement.counterpartyResearch = showCounterPart ? data.particularity.counterpartyResearch : false
    movement.dateStartMandat = movement.counterpartyResearch ? data.particularity.dateStartMandat : null
    movement.dateEndMandat = movement.counterpartyResearch ? data.particularity.dateEndMandat : null
    movement.lifeAnnuity = data.particularity.lifeAnnuity
    movement.ter = data.particularity.ter
    movement.investment50k = data.particularity.investment50k

    movement.shareCount = data.movement.shareCount
    movement.sharePrice = data.movement.sharePrice
    movement.totalAmount = data.movement.totalAmount
    movement.recurrence = new Recurrence(
      null,
      data.recurrence.status,
      data.recurrence.periodicity,
      data.recurrence.shareCount,
      data.recurrence.sharePrice,
      data.recurrence.totalAmount,
      data.recurrence.rumCode,
      data.recurrence.rumStatus,
      {
        start: data.recurrence.period.start,
        end: data.recurrence.period.end
      }
    )

    const investors = []
    const owner = movement.investors?.find((investor) => {
      return investor?.type === "transferor" || null
    })

    const usufructuary = movement.investors?.find((investor) => {
      return investor?.type === "usufruct" || null
    })

    if (presubscriptions.owner && presubscriptions.owner.dismembermentTransaction) {
      let partner = undefined
      if(partners && partners.owner) {
        partner = partners.owner
      } else if (presubscriptions.owner?.partner) {
        partner = presubscriptions.owner.partner
      } else if (!presubscriptions.owner.partner && owner ) {
        partner = owner.partner
      }

      let ownerDocuments: any[] = [];
      presubscriptions.owner.dismembermentTransaction?.investors && presubscriptions.owner.dismembermentTransaction.investors.map((investor) => {
          if (investor.prospect && investor.prospect.prospectCode && data.documents && data.documents[investor.prospect.prospectCode]) {
            ownerDocuments = [...ownerDocuments, ...documentUtils.getDocumentsForProspect(data.documents[investor.prospect.prospectCode], documentArray)]
          }
        })

      investors.push(
        new Investor(
          presubscriptions.owner.id,
          'investor',
          data.movement.investorFirst.dismembermentAmount,
          data.movement.investorFirst.paymentMode,
          data.movement.investorFirst.paymentStatus,
          data.movement.investorFirst.paymentNature,
          data.movement.investorFirst.settlementDate,
          data.movement.investorFirst.dismembermentKey,
          owner && owner.prospect && owner.prospect.prospectCode && data.bankInformations && data.bankInformations[owner.prospect.prospectCode] ?
            bankInformationUtils.getBankInformationsForProspect(data.bankInformations[owner.prospect.prospectCode])
          : [],
          presubscriptions.owner.dismembermentTransaction,
          data.movement.investorFirst.checkDepositAt,
          null,
          ownerDocuments,
          partner
        )
      )
    } else if(owner) {
      owner.partner = partners?.owner ?? undefined
      investors.push(owner)
    }

    if (presubscriptions.usufructuary && presubscriptions.usufructuary.dismembermentTransaction) {
      let partner = undefined
      if(partners && partners.usufructuary) {
        partner = partners.usufructuary
      } else if (presubscriptions.usufructuary?.partner) {
        partner = presubscriptions.usufructuary.partner
      } else if (!presubscriptions.usufructuary.partner && usufructuary) {
        partner = usufructuary.partner
      }

      let usufructuaryDocuments: any[] = [];
      presubscriptions.usufructuary.dismembermentTransaction?.investors && presubscriptions.usufructuary.dismembermentTransaction.investors.map((usufructuary) => {
        if (usufructuary.prospect && usufructuary.prospect.prospectCode && data.documents && data.documents[usufructuary.prospect.prospectCode]) {
          usufructuaryDocuments = [...usufructuaryDocuments, ...documentUtils.getDocumentsForProspect(data.documents[usufructuary.prospect.prospectCode], documentArray)]
        }
      })

      investors.push(
        new Investor(
          presubscriptions.usufructuary.id,
          'usufruct',
          data.movement.investorSecond.dismembermentAmount,
          data.movement.investorSecond.paymentMode,
          data.movement.investorSecond.paymentStatus,
          data.movement.investorSecond.paymentNature,
          data.movement.investorSecond.settlementDate,
          data.movement.investorSecond.dismembermentKey,
          usufructuary && usufructuary.prospect && usufructuary.prospect.prospectCode && data.bankInformations && data.bankInformations[usufructuary.prospect.prospectCode] ?
            bankInformationUtils.getBankInformationsForProspect(data.bankInformations[usufructuary.prospect.prospectCode])
            : [],
          presubscriptions.usufructuary.dismembermentTransaction,
          data.movement.investorSecond.checkDepositAt,
          null,
          usufructuaryDocuments,
          partner
        )
      )
    } else if (usufructuary) {
      usufructuary.partner = partners?.usufructuary ?? undefined
      investors.push(usufructuary)
    }

    movement.investors = investors
    if (null !== movement.id) {
      new DismembermentGateway().update(movement).then(response => {
        if (null !== response) {
          movement = response
          setUuidRefresh(uuidV4())
          toastSuccess(t('movement.dismemberment.notify.update-success'));
          dispatch(reloadMovement());
        } else {
          toastError(t('movement.dismemberment.notify.update-error'));
        }
      })
    } else {
      setIsLoading(true)
      new DismembermentGateway().create(movement).then((response) => {
        if (null !== response) {
          toastSuccess(t('movement.dismemberment.notify.add-success'));
          navigate(`/${t('url.movements.edit-dismemberment')}/${response.id}`)

        } else {
          toastError(t('movement.dismemberment.notify.add-error'));
        }
        setIsLoading(false)
      })
    }
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));

  useEffect(() => {
    if(referential) {
      const usufructuary = movement.investors?.find((investor) => {
        return investor?.type === "usufruct" || null
      })
      let platformTypeId = ""
      let networkTypeId = ""
      let platformType
      let networkType

      if (usufructuary) {
        platformTypeId = (partners.usufructuary?.platformType ?? usufructuary.partner?.platformType) || ''
        networkTypeId = (partners.usufructuary?.networkType ?? usufructuary.partner?.networkType) || ''

        platformType = referential.partner?.platform.find((el) => el.value === platformTypeId)
        networkType = referential?.partner?.network.find((el) => el.value === networkTypeId)

        setSecondPartnerLink(partners.usufructuary?.id || usufructuary.partner?.id || null)
        setValue('investor.second.partnerNumber', partners.usufructuary?.partnerCode || usufructuary.partner?.partnerCode || "")
        setValue('investor.second.partnerSocialReason', partners.usufructuary?.socialReason || usufructuary.partner?.socialReason || "")
        setValue('investor.second.platformNetworkNumber', platformType?.label || "")
        setValue('investor.second.platformNetworkLastName', networkType?.label || "")
      } else {
        platformTypeId = partners.usufructuary?.platformType || ''
        networkTypeId = partners.usufructuary?.networkType || ''

        platformType = referential?.partner?.platform.find((el) => el.value === platformTypeId)
        networkType = referential?.partner?.network.find((el) => el.value === networkTypeId)

        setSecondPartnerLink(partners.usufructuary?.id || null)
        setValue('investor.second.partnerNumber', partners.usufructuary?.partnerCode || "")
        setValue('investor.second.partnerSocialReason', partners.usufructuary?.socialReason || "")
        setValue('investor.second.platformNetworkNumber', platformType?.label || "")
        setValue('investor.second.platformNetworkLastName', networkType?.label || "")
      }

      const owner = movement.investors?.find((investor) => {
        return investor?.type === "investor" || null
      })

      if (owner) {
        platformTypeId = (partners.owner?.platformType ?? owner.partner?.platformType) || ""
        networkTypeId = (partners.owner?.networkType ?? owner.partner?.networkType) || ""

        platformType = referential.partner?.platform.find((el) => el.value === platformTypeId)
        networkType = referential?.partner?.network.find((el) => el.value === networkTypeId)

        setPartnerLink(partners.owner?.id || owner.partner?.id || null)
        setValue('investor.first.partnerNumber', partners.owner?.partnerCode || owner.partner?.partnerCode || "")
        setValue('investor.first.partnerSocialReason', partners.owner?.socialReason || owner.partner?.socialReason || "")
        setValue('investor.first.platformNetworkNumber', platformType?.label || "")
        setValue('investor.first.platformNetworkLastName', networkType?.label || "")
      } else {
        platformTypeId = partners.owner?.platformType || ''
        networkTypeId = partners.owner?.networkType || ''

        platformType = referential?.partner?.platform.find((el) => el.value === platformTypeId)
        networkType = referential?.partner?.network.find((el) => el.value === networkTypeId)

        setPartnerLink(partners.owner?.id || null)
        setValue('investor.first.partnerNumber', partners.owner?.partnerCode || "")
        setValue('investor.first.partnerSocialReason', partners.owner?.socialReason || "")
        setValue('investor.first.platformNetworkNumber', platformType?.label || "")
        setValue('investor.first.platformNetworkLastName', networkType?.label || "")
      }
    }
  },[partners, referential, presubscriptions])

  function viewFile(type: string, id: string | null) {
    if(id) {
      const documentToView = documentArray[id]?.filter((document: any) => document.type === type)
      if (documentToView && documentToView.length > 0) {
        documentToView.map((document: DocumentInterface) => {
          if (id && document.id) {
            new DocumentGateway(movement.id, null).getFile(document.id).then(response => {
              const file = window.open('')

              if (null !== file && response !== null) {
                file.document.write(
                    "<body style='margin:0;padding:0;overflow:hidden'><iframe width='100%' height='100%' style='border:0' src='" + response + "'></iframe></body>"
                )
              }
            })
          }
        })
      }
    }
  }

  useEffect(() => {
    if (product !== null) {
      if (movement.sharePrice) {
        setValue('movement.sharePrice', movement.sharePrice || null)
        setValue('recurrence.sharePrice', movement.sharePrice || null)
      }
    }
  }, [product])

  useEffect(() => {
    if(watchLifeAnnuity) setValue('particularity.duration', null)
  }, [watchLifeAnnuity])

  const blockGeneralInformation = <div className={`form-bloc form--bloc--general-information`}>
    <div className="form-bloc__title">{t('movement.pre-subscription.form.general-information.title')}</div>
    <div className="form-bloc__form flex-container">
      <ProductDalalist classes="col-md-6"
                       id="generalInformation.product"
                       name="generalInformation.product"
                       label={t('movement.pre-subscription.form.general-information.product-acronym')}
                       labelClasses="mendatory-fild"
                       control={control}
                       defaultValue={getValues('generalInformation.product')}
                       disabled={isLectureMode}
      />
      <Input register={register}
             type={'text'}
             classes={'col-md-6'} name={'generalInformation.movementNumber'} id={'generalInformation.movementNumber'}
             label={t('movement.pre-subscription.form.general-information.movement-number')}
             readOnly={true}
      />
      <SelectCustom classes="col-md-6" id="generalInformation.propertyType" name="generalInformation.propertyType"
                    options={[{value: 'dismemberment', label: 'Démembrement'}]}
                    label={t('movement.pre-subscription.form.general-information.nature-property')}
                    register={register} readOnly={true} />
      <Input register={register}
             classes={'col-md-6'}
             label={t('movement.pre-subscription.form.general-information.administrator')}
             type={'text'}
             name={'generalInformation.administrator'}
             id={'generalInformation.administrator'}
             readOnly={true}
      />
      <SelectCustom register={register}
                    classes="col-md-6"
                    id="generalInformation.possessionMode"
                    name="generalInformation.possessionMode"
                    label={t('movement.pre-subscription.form.general-information.detention-mode')}
                    options={List.filterDetentionModeList(product?.productType ?? null, referential?.wallet_subscription.possession_mode ?? [])}
      />
      <InputDate id="generalInformation.horodatage"
                 label={t('movement.pre-subscription.form.general-information.timestamp-date')}
                 name="generalInformation.horodatage" classes="col-md-6" register={register} control={control} readOnly={isLectureMode}/>

      { movement.id !== null ? (
          <div className="u-txt-size-s u-txt-lightbold u-txt-italic col-md-12">
            {t('movement.pre-subscription.form.general-information.lastUpdateUser')} {getValues('generalInformation.lastUpdateUser')}
            {t('movement.pre-subscription.form.general-information.updatedAt')} {getValues('generalInformation.updatedAt')}
          </div>
      ) : ''}
    </div>
  </div>

  const blockInvestor = <div className={`form-bloc form--bloc--investor`}>
    <ReactTooltip />
    <div className="form-bloc__title">{t('movement.pre-subscription.form.investor.title')}</div>
    <div className="flex-container">
      <div className="col-md-6 form-bloc__form">
        <div className="flex-container">
          <div className="col-md-6">
            <button type="button" className="button button-reset u-txt-with-icon" onClick={() => dispatch(setOpenChoiceInvestorEvent({show: true, key: 'owner', dataListType: "subscription"}))}>
              <img src={iconAdd} alt="" />
              {t('movement.dismemberment.form.choice-investor-owner')}
            </button>
          </div>
          <div className="col-md-6">
            <button type="button" className="button button-reset u-txt-with-icon" onClick={() => dispatch(setOpenChoicePartnerEvent({show: true, key: 'owner'}))}>
              <img src={iconAdd} alt="" />
              {t('account.search-a-partner')}
            </button>
          </div>
        </div>
        <table className="table--investor">
          <thead>
          <tr>
            <th/>
            <th className="form-control__label">{t('movement.pre-subscription.form.investor.th-number')}</th>
            <th className="form-control__label">{t('movement.pre-subscription.form.investor.th-identity')}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <label htmlFor="partnerNum" className="form-control__label u-mrs">
                {t('movement.pre-subscription.form.investor.investor-owner')}
              </label>
              { ownerLink &&
                  <Link to={`/${t('url.customer.read-general-information')}/${ownerLink}`}
                        target="_blank"
                        rel="noopener noreferrer"
                  >
                      <button type="button" className="button-reset">
                          <img src={iconSearch} alt="Loupe"/>
                      </button>
                  </Link>
              }
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={"investor.first.prospectCode"}
                     readOnly={true}
                     id={"investor.first.prospectCode"}
              />
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={"investor.first.identity"}
                     readOnly={true}
                     id={"investor.first.identity"}
                     data-tip={getValues('investor.first.identity') ?? ''}
              />
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="partnerNum" className="form-control__label u-mrs">
                {t('movement.pre-subscription.form.investor.investor-partner')}
              </label>
              { partnerLink &&
                <Link to={ `/${ t('url.partner.read-general-information') }/${ partnerLink }` }
                      target="_blank"
                      rel="noopener noreferrer"
                >
                  <button type="button" className="button-reset">
                    <img src={ iconSearch } alt="Loupe"/>
                  </button>
                </Link>
              }
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.partnerNumber'}
                     id={'investor.first.partnerNumber'}
                     disabled
              />
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.partnerSocialReason'}
                     id={'investor.first.partnerSocialReason'}
                     disabled
              />
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="partnerNum" className="form-control__label">
                {t('movement.pre-subscription.form.investor.platform-network')}
              </label>
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.platformNetworkNumber'}
                     id={'investor.first.platformNetworkNumber'}
                     readOnly={true}
              />
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.platformNetworkLastName'}
                     id={'investor.first.platformNetworkLastName'}
                     readOnly={true}
              />
            </td>
            <td/>
          </tr>
          </tbody>
        </table>
      </div>
      <div className={`col-md-6 form-bloc__form ${showUsufruitier ? '' : 'disabledContent'}`}>
        <div className="flex-container">
          <div className="col-md-6">
            <button type="button" className="button button-reset u-txt-with-icon" onClick={() => dispatch(setOpenChoiceInvestorEvent({show: true, key: 'usufructuary', dataListType: "subscription"}))}>
              <img src={iconAdd} alt="" />
              {t('movement.dismemberment.form.choice-investor-usufructuary')}
            </button>
          </div>
          <div className="col-md-6">
            <button type="button" className="button button-reset u-txt-with-icon" onClick={() => dispatch(setOpenChoicePartnerEvent({show: true, key: 'usufructuary'}))}>
              <img src={iconAdd} alt="" />
              {t('account.search-a-partner')}
            </button>
          </div>
        </div>
        <table className="table--investor">
          <thead>
          <tr>
            <th/>
            <th className="form-control__label">{t('movement.pre-subscription.form.investor.th-number')}</th>
            <th className="form-control__label">{t('movement.pre-subscription.form.investor.th-identity')}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <label htmlFor="partnerNum" className="form-control__label u-mrs">
                {t('movement.pre-subscription.form.investor.investor-usufructuary')}
              </label>
              { usuLink &&
                <Link to={ `/${ t('url.customer.read-general-information') }/${ usuLink }` }
                      target="_blank"
                      rel="noopener noreferrer"
                >
                  <button type="button" className="button-reset">
                    <img src={iconSearch} alt="Loupe"/>
                  </button>
                </Link>
              }
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.second.prospectCode'}
                     id={'investor.second.prospectCode'}
                     readOnly={true}
              />
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.second.identity'}
                     id={'investor.second.identity'}
                     readOnly={true}
                     data-tip={getValues('investor.second.identity') ?? ''}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="partnerNum" className="form-control__label u-mrs">
                {t('movement.pre-subscription.form.investor.usufructuary-partner')}
              </label>
              { secondPartnerLink &&
                <Link to={ `/${ t('url.partner.read-general-information') }/${ secondPartnerLink }` }
                      target="_blank"
                      rel="noopener noreferrer"
                >
                  <button type="button" className="button-reset">
                    <img src={iconSearch} alt="Loupe"/>
                  </button>
                </Link>
              }
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.second.partnerNumber'}
                     id={'investor.second.partnerNumber'}
                     disabled
              />
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.second.partnerSocialReason'}
                     id={'investor.second.partnerSocialReason'}
                     disabled
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="partnerNum" className="form-control__label">
                {t('movement.pre-subscription.form.investor.platform-network')}
              </label>
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.second.platformNetworkNumber'}
                     id={'investor.second.platformNetworkNumber'}
                     disabled
              />
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.second.platformNetworkLastName'}
                     id={'investor.second.platformNetworkLastName'}
                     disabled
              />
            </td>
            <td/>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  const blockParticularitiesSubscriber = <div className={`form-bloc`}>
    <div className="form-bloc__title">{t('movement.pre-subscription.form.particularity.caracteristic-subscriber')}</div>
    <div className="form-bloc__form flex-container">
      <CheckboxToggle classes="col-md-6" id="particularity.minor" name="particularity.minor" register={register} label={t('movement.pre-subscription.form.particularity.minor')} />
      <CheckboxToggle classes="col-md-6" id="particularity.notFrench" name="particularity.notFrench" register={register} label={t('movement.pre-subscription.form.particularity.noFrenchResident')} />
      <CheckboxToggle classes="col-md-6" id="particularity.guardianship" name="particularity.guardianship" register={register} label={t('movement.pre-subscription.form.particularity.underSupervision')} />
      <CheckboxToggle classes="col-md-6" id="particularity.ppe" name="particularity.ppe" register={register} label={t('movement.pre-subscription.form.particularity.ppe')} />
    </div>
  </div>

  const blockParticularitiesOperation = <div className={`form-bloc`}>
    <div className="form-bloc__title">{t('movement.pre-subscription.form.particularity.caracteristic-operation')}</div>
    <div className="form-bloc__form flex-container">
      <ReactTooltip />
      <CheckboxToggle classes="col-md-6" id="particularity.recurrent" name="particularity.recurrent" register={register} label={t('movement.pre-subscription.form.particularity.recurrence')} />
      <CheckboxToggle classes="col-md-6" id="particularity.counterpartyResearch" name="particularity.counterpartyResearch" register={register} label={t('movement.pre-subscription.form.particularity.counterpartyResearch')} readOnly={!showCounterPart} />
      {watchCounterpartyResearch && (
          <div className="col-md-6">
            <div className="form-control">
              <label htmlFor="dateStartMandat"
                     className="form-control__label">{t('movement.pre-subscription.form.particularity.date-start-mandat')}</label>
              <div className="form-control__input">
                <InputDate register={register}
                           control={control}
                           classes="movement-actions__date"
                           type={'text'}
                           name={'particularity.dateStartMandat'}
                           id={'particularity.dateStartMandat'}
                />
              </div>
            </div>
          </div>
      )
      }
      {watchCounterpartyResearch && (
          <div className="col-md-6">
            <div className="form-control">
              <label htmlFor="dateEndMandat"
                     className="form-control__label">{t('movement.pre-subscription.form.particularity.date-end-mandat')}</label>
              <div className="form-control__input">
                <InputDate register={register}
                           control={control}
                           classes="movement-actions__date"
                           type={'text'}
                           name={'particularity.dateEndMandat'}
                           id={'particularity.dateEndMandat'}
                />
              </div>
            </div>
          </div>
      )
      }
      <CheckboxToggle classes="col-md-6" id="particularity.dividendReinvestment" name="particularity.dividendReinvestment" register={register} label={t('movement.pre-subscription.form.particularity.reinvestmentDividends')} />
      <Input classes="col-md-6"
             id="particularity.duration"
             name="particularity.duration"
             inputClasses={`${(!watchLifeAnnuity && !watchDuration) ? "field-in-error" : ""}`}
             labelClasses={`${watchLifeAnnuity ? "" : "mandatory-field"}`}
             register={register}
             label={t('movement.pre-subscription.form.particularity.duration')}
             type="number"
             disabled={watchLifeAnnuity}
             tooltip={t('movement.tooltips.duration')}
      />
      <CheckboxToggle classes="col-md-6" id="particularity.ter" name="particularity.ter" register={register} label={t('movement.pre-subscription.form.particularity.deviceBter')} />
      <CheckboxToggle classes="col-md-6" id="particularity.lifeAnnuity" name="particularity.lifeAnnuity" register={register} label={t('movement.pre-subscription.form.particularity.lifeAnnuity')} />
      <CheckboxToggle classes="col-md-6" id="particularity.investment50k" name="particularity.investment50k" register={register} label={t('movement.pre-subscription.form.particularity.investment50k')} />
    </div>
  </div>

  const blockMovement = <div className={`form-bloc form--bloc--movement`}>
    <div className="form-bloc__title">{t('movement.pre-subscription.form.movement.title')}</div>
    <div className="form-bloc__form flex-container">
      <div className="col-md-4">
        <Input id="movement.shareCount"
               name="movement.shareCount"
               classes={`col-md-12 mandatory-field`}
               inputClasses={`${watchMovementShareCount ? '' : 'field-in-error'}`}
               register={register}
               label={t('movement.pre-subscription.form.movement.numberOfShares')}
               type="number"
        />
        <Input id="movement.sharePrice" name="movement.sharePrice" classes="col-md-12" register={register} label={t('movement.pre-subscription.form.movement.startingPrice')} type="number" readOnly={true} formatNumber={{value:getValues('movement.sharePrice')}} />
        <Input id="movement.totalAmount" name="movement.totalAmount" classes="col-md-12" register={register} label={t('movement.pre-subscription.form.movement.totalAmount')} type="number" readOnly={true} formatNumber={{value:getValues('movement.totalAmount')}}/>
      </div>
      <div className="col-md-8">
        <table className="table--movement">
          <thead>
          <tr>
            <th/>
            <th className="form-control__label">{t('movement.pre-subscription.form.movement.th-client-owner')}</th>
            <th className="form-control__label">{t('movement.pre-subscription.form.movement.th-client-usufructuary')}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td className="form-control__label">{t('movement.pre-subscription.form.movement.dismembermentKey')}</td>
            <td>
              <InputNumber
                id={'movement.investorFirst.dismembermentKey'}
                name={'movement.investorFirst.dismembermentKey'}
                classes={'col-md-12'}
                disabled={watchPropertyType === 'usufruct'}
                readOnly={!showUsufruitier}
                control={control}
                decimalScale={decimalStep}
                onChangeCb={(value) => !isUpdatingDismemberment.current && handleFirstKey(value)}
                register={register} />
            </td>
            <td className={`${showUsufruitier ? '' : 'disabledContent'}`}>
              <InputNumber
                id={'movement.investorSecond.dismembermentKey'}
                name={'movement.investorSecond.dismembermentKey'}
                classes={'col-md-12'}
                disabled={!showUsufruitier}
                readOnly={watchPropertyType === 'usufruct'}
                control={control}
                decimalScale={decimalStep}
                onChangeCb={(value) => !isUpdatingDismemberment.current && handleSecondKey(value)}
                register={register} />
            </td>
          </tr>
          <tr>
            <td className="form-control__label">{t('movement.pre-subscription.form.movement.dismembermentAmount')}</td>
            <td>
              <InputNumber
                id={'movement.investorFirst.dismembermentAmount'}
                name={'movement.investorFirst.dismembermentAmount'}
                classes={'col-md-12'}
                disabled={watchPropertyType === 'usufruct'}
                readOnly={!showUsufruitier}
                control={control}
                decimalScale={decimalStep}
                onChangeCb={handleFirstAmount}
                register={register} />
            </td>
            <td className={`${showUsufruitier ? '' : 'disabledContent'}`}>
              <InputNumber
                id={'movement.investorSecond.dismembermentAmount'}
                name={'movement.investorSecond.dismembermentAmount'}
                classes={'col-md-12'}
                readOnly={watchPropertyType === 'usufruct'}
                control={control}
                decimalScale={decimalStep}
                onChangeCb={handleSecondAmount}
                register={register} />
            </td>
          </tr>
          <tr>
            <td className="form-control__label">{t('movement.pre-subscription.form.movement.paymentNature')}</td>
            <td><SelectCustom id="movement.investorFirst.paymentNature" name="movement.investorFirst.paymentNature" classes="col-md-12" register={register} options={referential?.wallet_subscription.payment_nature || null} /></td>
            <td className={`${showUsufruitier ? '' : 'disabledContent'}`}><SelectCustom id="movement.investorSecond.paymentNature" name="movement.investorSecond.paymentNature" classes="col-md-12" register={register} options={referential?.wallet_subscription.payment_nature || null} /></td>
          </tr>
          <tr>
            <td className="form-control__label have-ged">
              {t('movement.pre-subscription.form.movement.paymentMode')}
            </td>
            <td>
              <div className="horizontal-container-space-between">
                <SelectCustom id="movement.investorFirst.paymentMode" name="movement.investorFirst.paymentMode" classes="col-md-12" register={register} options={referential?.wallet_subscription.payment_method || null} />
                <span>
                  <button type="button" className="button-reset"
                          onClick={() => viewFile('Paiement', presubscriptions?.owner?.id || null)}
                  >
                    <img src={eyeIcon} alt="" />
                  </button>
                  {!isLectureMode && <button type="button" className="button-reset"
                                             onClick={() => dispatch(setOpenUploadForm({
                                               show: true,
                                               documents: null,
                                               uuid: presubscriptions?.owner?.dismembermentTransaction?.investors
                                                   ? presubscriptions.owner.dismembermentTransaction.investors[0]?.prospect?.id : null,
                                               type: 'Paiement'
                                             }))}
                  >
                    <img src={uploadIcon} alt=""/>
                  </button>}
                </span>
              </div>
            </td>
            <td className={`${showUsufruitier ? '' : 'disabledContent'}`}>
              <div className="horizontal-container-space-between">
                <SelectCustom id="movement.investorSecond.paymentMode" name="movement.investorSecond.paymentMode" classes="col-md-12" register={register} options={referential?.wallet_subscription.payment_method || null} />
                <span>
                  <button type="button" className="button-reset"
                          onClick={() => viewFile('Paiement', presubscriptions?.usufructuary?.id || null)}
                  >
                    <img src={eyeIcon} alt="" />
                  </button>
                  {!isLectureMode && <button type="button" className="button-reset"
                                             onClick={() => dispatch(setOpenUploadForm({
                                               show: true,
                                               documents: null,
                                               uuid: presubscriptions?.usufructuary?.dismembermentTransaction?.investors
                                                   ? presubscriptions.usufructuary.dismembermentTransaction.investors[0]?.prospect?.id : null,
                                               type: 'Paiement'
                                             }))}
                  >
                    <img src={uploadIcon} alt=""/>
                  </button>}
                </span>
              </div>
            </td>
          </tr>
          {(watchInvestorFirstPaymentMode === 'check' || watchInvestorSecondPaymentMode === 'check') &&
              <tr>
                  <td className="form-control__label">{t('movement.pre-subscription.form.movement.checkDepositAt')}</td>
                  <td>{watchInvestorFirstPaymentMode === 'check' &&
                      <InputDate id="movement.investorFirst.checkDepositAt" name="movement.investorFirst.checkDepositAt"
                                 classes="col-md-12" register={register} control={control}/>}</td>
                  <td>{watchInvestorSecondPaymentMode === 'check' &&
                      <InputDate id="movement.investorSecond.checkDepositAt"
                                 name="movement.investorSecond.checkDepositAt" classes="col-md-12"
                                 register={register} control={control}/>}</td>
              </tr>
          }
          <tr>
            <td className="form-control__label">{t('movement.pre-subscription.form.movement.settlementStatus')}</td>
            <td><SelectCustom id="movement.investorFirst.paymentStatus" name="movement.investorFirst.paymentStatus" classes="col-md-12" register={register} options={referential?.wallet_subscription.payment_status || null} /></td>
            <td className={`${showUsufruitier ? '' : 'disabledContent'}`}><SelectCustom id="movement.investorSecond.paymentStatus" name="movement.investorSecond.paymentStatus" classes="col-md-12" register={register} options={referential?.wallet_subscription.payment_status || null} /></td>
          </tr>
          <tr>
            <td className="form-control__label">{t('movement.pre-subscription.form.movement.settlementDate')}</td>
            <td><InputDate id="movement.investorFirst.settlementDate" name="movement.investorFirst.settlementDate" classes="col-md-12" register={register} control={control} readOnly={isLectureMode}/></td>
            <td className={`${showUsufruitier ? '' : 'disabledContent'}`}><InputDate id="movement.investorSecond.settlementDate" name="movement.investorSecond.settlementDate" classes="col-md-12" register={register} control={control} readOnly={isLectureMode}/></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  const blockRecurrence = <div className={`form-bloc form--bloc--recurrence ${!watchParticularityRecurrence ? 'disabledContent' : ''}`}>
    <div className="form-bloc__title">{t('movement.pre-subscription.form.recurrence.title')}</div>
    <div className="form-bloc__form flex-container">
      <Input id="recurrence.numRecurrence" name="recurrence.numRecurrence" classes="col-md-12" register={register} label={t('movement.pre-subscription.form.recurrence.num-recurrence')} readOnly={true}/>
      <InputDate id="recurrence.period.start" name="recurrence.period.start" classes="col-md-12" register={register} control={control} label={t('movement.pre-subscription.form.recurrence.start-of-recurrence')}/>
      <SelectCustom id="recurrence.periodicity" name="recurrence.periodicity" classes="col-md-12" register={register} label={t('movement.pre-subscription.form.recurrence.periodicity-frequency')} options={referential?.wallet_subscription.periodicity || null} />
      <InputDate id="recurrence.period.end" name="recurrence.period.end" classes="col-md-12" register={register} control={control} label={t('movement.pre-subscription.form.recurrence.end-of-recurrence')}/>
      <Input id="recurrence.shareCount" name="recurrence.shareCount" classes="col-md-12" register={register} label={t('movement.pre-subscription.form.recurrence.number-of-shares')} />
      <Input id="recurrence.sharePrice" name="recurrence.sharePrice" classes="col-md-12" register={register} label={t('movement.pre-subscription.form.recurrence.starting-price')} readOnly={true} formatNumber={{value:getValues('recurrence.sharePrice')}}/>
      <Input id="recurrence.totalAmount" name="recurrence.totalAmount" classes="col-md-12" register={register} label={t('movement.pre-subscription.form.recurrence.total-amount')} readOnly={true} formatNumber={{value:getValues('recurrence.totalAmount')}}/>
      <Input id="recurrence.rumCode" name="recurrence.rumCode" classes="col-md-12" register={register} label={t('movement.pre-subscription.form.recurrence.rum-code')} />
      <SelectCustom id="recurrence.rumStatus" name="recurrence.rumStatus" classes="col-md-12" register={register} label={t('movement.pre-subscription.form.recurrence.rum-status')} options={referential?.wallet_subscription.rum_status || null} />
    </div>
  </div>

  const blockPresubscriptionOwner = presubscriptions && presubscriptions.owner ? <div className={`form-bloc datalist`}>
    <div className="datalist__title">{t('movement.dismemberment.form.recap.show_nu_pro')}</div>
      <table className="datalist__datas">
        <thead>
        <tr>
          <th>{t('movement.dismemberment.form.recap.investor_id')}</th>
          <th>{t('movement.dismemberment.form.recap.name')}</th>
          <th>{t('movement.dismemberment.form.recap.product')}</th>
          <th>{t('movement.dismemberment.form.recap.share_count')}</th>
          <th>{t('movement.dismemberment.form.recap.duration')}</th>
          <th>{t('movement.dismemberment.form.recap.quotation')}</th>
          <th>{t('movement.dismemberment.form.recap.amount')}</th>
          <th>{t('movement.dismemberment.form.recap.presubscription_id')}</th>
          <th>{t('movement.dismemberment.form.recap.alert')}</th>
        </tr>
        </thead>
        <tbody>
        <tr key={uuidV4()}>
          <td>{presubscriptions.owner?.dismembermentTransaction?.investors && presubscriptions.owner.dismembermentTransaction.investors[0] && presubscriptions.owner.dismembermentTransaction.investors[0].prospect?.prospectCode}</td>
          <td>{presubscriptions.owner?.dismembermentTransaction?.investors && presubscriptions.owner?.dismembermentTransaction?.investors[0] && presubscriptions.owner?.dismembermentTransaction?.investors[0].prospect?.persons?[0] && presubscriptions.owner?.dismembermentTransaction?.investors[0].prospect.persons[0].lastname : null }</td>
          <td>{presubscriptions.owner?.dismembermentTransaction?.product?.label}</td>
          <td>{presubscriptions.owner?.dismembermentTransaction?.shareCount}</td>
          <td>{presubscriptions.owner?.dismembermentTransaction?.duration}</td>
          <td>{presubscriptions.owner?.dismembermentTransaction?.investors && presubscriptions.owner.dismembermentTransaction.investors[0] && presubscriptions.owner.dismembermentTransaction.investors[0].dismembermentKey}</td>
          <td>{presubscriptions.owner?.dismembermentTransaction?.totalAmount}</td>
          <td>{presubscriptions.owner?.dismembermentTransaction?.transactionCode}</td>
          <td>
            <div className="u-mys">
              { presubscriptions.owner?.dismembermentTransaction?.tags?.map(tag => {
                const key = `movement.tags.${tag}`
                const type = "info" // TODO tag.type
                return <div className={`badge badge--min badge--without-cta status--${type} u-mrs u-mbs`} key={uuidV4()}>
                  <div className="badge__container">
                    <div className="badge__text">{t(key)}</div>
                  </div>
                </div>
              })}
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  : <></>

  const blockPresubscriptionUsufructuary = presubscriptions && presubscriptions.usufructuary ? <div className={`form-bloc datalist`}>
      <div className="datalist__title">{t('movement.dismemberment.form.recap.show_usufruct')}</div>
      <table className="datalist__datas">
        <thead>
        <tr>
          <th>{t('movement.dismemberment.form.recap.investor_id')}</th>
          <th>{t('movement.dismemberment.form.recap.name')}</th>
          <th>{t('movement.dismemberment.form.recap.product')}</th>
          <th>{t('movement.dismemberment.form.recap.share_count')}</th>
          <th>{t('movement.dismemberment.form.recap.duration')}</th>
          <th>{t('movement.dismemberment.form.recap.quotation')}</th>
          <th>{t('movement.dismemberment.form.recap.amount')}</th>
          <th>{t('movement.dismemberment.form.recap.presubscription_id')}</th>
          <th>{t('movement.dismemberment.form.recap.alert')}</th>
        </tr>
        </thead>
        <tbody>
        <tr key={uuidV4()}>
          <td>{presubscriptions.usufructuary?.dismembermentTransaction?.investors && presubscriptions.usufructuary?.dismembermentTransaction?.investors[0] && presubscriptions.usufructuary?.dismembermentTransaction?.investors[0].prospect?.prospectCode}</td>
          <td>{presubscriptions.usufructuary?.dismembermentTransaction?.investors && presubscriptions.usufructuary?.dismembermentTransaction?.investors[0] && presubscriptions.usufructuary?.dismembermentTransaction?.investors[0].prospect?.persons?[0] && presubscriptions.usufructuary?.dismembermentTransaction?.investors[0].prospect.persons[0].lastname : null }</td>
          <td>{presubscriptions.usufructuary?.dismembermentTransaction?.product?.label}</td>
          <td>{presubscriptions.usufructuary?.dismembermentTransaction?.shareCount}</td>
          <td>{presubscriptions.usufructuary?.dismembermentTransaction?.duration}</td>
          <td>{presubscriptions.usufructuary?.dismembermentTransaction?.investors && presubscriptions.usufructuary?.dismembermentTransaction?.investors[0] && presubscriptions.usufructuary?.dismembermentTransaction?.investors[0].dismembermentKey}</td>
          <td>{presubscriptions.usufructuary?.dismembermentTransaction?.totalAmount}</td>
          <td>{presubscriptions.usufructuary?.dismembermentTransaction?.transactionCode}</td>
          <td>
            <div className="u-mys">
              {presubscriptions.usufructuary?.dismembermentTransaction?.tags?.map(tag => {
                const key = `movement.tags.${tag}`
                const type = 'alert' // TODO tag.type
                return <div className={`badge badge--min badge--without-cta status--${type} u-mrs u-mbs`} key={uuidV4()}>
                  <div className="badge__container">
                    <div className="badge__text">{t(key)}</div>
                  </div>
                </div>
              })}
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    : <></>

  const blockListDocumentsInvestors = <>
    {Object.entries(presubscriptions).map((presubscription) => {
      if (presubscription[1] && presubscription[1].dismembermentTransaction && presubscription[1].dismembermentTransaction.investors) {
        return presubscription[1].dismembermentTransaction.investors.map((investor) => {
          if (investor.prospect && investor.prospect.persons) {
            return investor.prospect.persons.map((person) => {
              if (movement.id && investor && investor.prospect && investor.prospect && person && person.id) {
                return (
                  <>
                    <div className="col-md-6">
                      <DocumentForm
                        title={`${t('movement.pre-subscription.form.list-of-documents.title')} : ${investor?.prospect?.prospectCode} - ${person.lastname} ${person.firstname}`}
                        name={`documents.${investor.prospect.prospectCode}.${person.id}`}
                        uuid={person.id}
                        documentGateway={new DocumentGateway(movement.id, investor.prospect.id)}
                        register={register}
                        control={control}
                        documentData={documentArray[person.id]}
                        isLectureMode={isLectureMode}
                      />
                    </div>
                  </>
                )
              }
            })
          }
        })
      }
    })}
  </>

  const getActions = (actionArray: any) => {
    setActions(actionArray)
  }

  return (
    <>
      {referential &&
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
            <Status label={"movement.state"} status={movement.status} statusList={referential.wallet.status} />
            <ActionReason reason={
              referential?.wallet_subscription?.cancel_reason?.find(reason => {
                return reason.value == movement.actionReason
              })?.label ?? movement.actionReason
            }/>
            {!movement.id && <AlertInfo text={t('movement.warning-action-possible')} classes="u-mys" />}
            {movement?.id && <MovementTag transactionId={movement.id} tags={movement.tags || []} optionsReferential={referential.transaction.tag} readOnly={isLectureMode} />}
            <div className="flex-container">
              <div className="col-md-6">{blockGeneralInformation}</div>
              <div className="col-md-6">{movement.id && <ActionsForm gateway={new DismembermentGateway()}
                                                                     transactionUuid={movement.id}
                                                                     uuidRefresh={uuidRefresh}
                                                                     callback={getActions}
                                                                     setFormError={setFormError}
              />
              }</div>
              <FormErrorInfoDropdown errorArray={formError}/>
              {(actions?.invalidate.date && !actions?.confirm.date) && <div className="col-md-12">
                <CommentBlock
                  data={movement.comment}
                  onChange={(data: string) => setValue('comment', data)}
                  config={CKEditorUtil.getConfig()}
                  readonly={isLectureMode}
                />
              </div>}
              <div className="col-md-12">{blockInvestor}</div>
              <div className="col-md-12">{blockPresubscriptionOwner}</div>
              <div className="col-md-12">{blockPresubscriptionUsufructuary}</div>
              <div className="col-md-6">{blockParticularitiesSubscriber}</div>
              <div className="col-md-6">{blockParticularitiesOperation}</div>
              <div className="col-md-8">
                <div className="flex-container">
                  <div className="col-md-12">{blockMovement}</div>
                </div>
              </div>
              <div className="col-md-4">{blockRecurrence}</div>
              <div className="col-md-12">
                <div className="flex-container">
                  {
                    movement?.investors?.map((investor, index) => {
                      if (investor && referential) {
                        return referential.prospect.bank_type.map((bankType: ReferentielItemInterface) => {
                          if (investor.prospect) {
                            return <BankInformationBlock
                              type={bankType.value.toString()}
                              key={`bankInformations.${investor.prospect.prospectCode}.${bankType.value}`}
                              name={`bankInformations.${investor.prospect.prospectCode}.${bankType.value}`}
                              title={
                                `${t('movement.pre-subscription.form.banking-information.title')} :
                                ${bankType.label}
                                ${investor.prospect.prospectCode}`
                              }
                              isLectureMode={isLectureMode}
                              prospect={investor.prospect}
                              product={product}
                            />
                          }
                        })}
                    })
                  }
                </div>
              </div>
              <div className="col-md-12">
                <div className="flex-container">
                  {blockListDocumentsInvestors}
                </div>
              </div>
              {movement.status === "subscribed" && movement.id &&
              <div className="col-md-12">
                <RecapForm transactionUuid={movement.id}
                           typeMvt='dismemberment'
                />
              </div>
              }
              <div className="col-md-12">
                <NoteBlock
                  data={movement.note}
                  onChange={(data: string) => {setValue('note', data)}}
                  config={CKEditorUtil.getConfig()}
                  readonly={isLectureMode || ['canceled', 'subscribed'].includes(movement.status ?? 'draft')}
                />
              </div>
            </div>
            {!isLectureMode && <FooterBlock disabled={!watchProduct.id || isLoading} />}
          </form>
        </FormProvider>
      }
    </>
  )
}

export default Form
