import React, {FunctionComponent} from 'react';

import Router from './infrastructure/route/Router';

import './assets/styles/main.scss';

const App: FunctionComponent = () => {
  return <Router />
}

export default App;
