import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../../../store/hook";
import {setOpenPledgeForm} from "../../../../../store/component/event";
import iconAdd from "../../../../../../assets/images/icons/add.svg";
import PledgeDatalist from "../../../Datalist/Pledge/PledgeDataList";

type Props = {
  investorId: string
  isLectureMode: boolean
}

const Pledge: FunctionComponent<Props> = ({investorId, isLectureMode}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  return (
    <div>
      <div className="flex-container">
        <div className="col-md-12">
          <div className={`form-bloc`}>
            <div className="form-bloc__title">{t('account.form.pledge.title')}</div>
            <div className="form-bloc__shortcut u-txt-right">
              <button type="button" className="button-reset u-txt-with-icon" onClick={() => dispatch(setOpenPledgeForm({show: true, pledge: null}))}>
                <img src={iconAdd} alt="" />
                {t('account.form.pledge.add')}
              </button>
            </div>

            <div className="col-md-12">
              <div className="form-bloc__shortcut u-txt-right">
                  <PledgeDatalist investorId={investorId} />
                </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Pledge