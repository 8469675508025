import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {FilterInterface, SliceList, SliceListInterface} from '../../../domain/Share/Slice/SliceList'

interface InitialStateInterface {
  countTotal: number
  slice: SliceList|null
  filter: FilterInterface|null
  sliceToTransaction: SliceList[]
  sliceToTransactionFreeze: SliceList[]
  slicePledgeSelected: SliceList|null
}

const initialState: InitialStateInterface = {
  countTotal: 0,
  slice: null,
  filter: null,
  sliceToTransaction: [],
  sliceToTransactionFreeze: [],
  slicePledgeSelected: null
}

export const sliceOfShareSlice = createSlice({
  name: 'slice',
  initialState: initialState,
  reducers: {
    addFilter: (state, action: PayloadAction<FilterInterface|null>) => {
      state.filter = action.payload
    },
    addMultipleSliceToTransaction: (state, action: PayloadAction<SliceListInterface[]>) => {
      state.sliceToTransaction = action.payload
    },
    addMultipleSliceToTransactionFreeze: (state, action: PayloadAction<SliceListInterface[]>) => {
      state.sliceToTransactionFreeze = action.payload
    },
    addSliceToTransaction: (state, action: PayloadAction<SliceList>) => {
      state.sliceToTransaction = state.sliceToTransaction.filter(item => item.id !== action.payload.id)

      if (action.payload.countShareSelected > 0) {
        state.sliceToTransaction.push(action.payload)
      }
    },
    addSliceToPledge: (state, action: PayloadAction<SliceList|null>) => {
      state.slicePledgeSelected = action.payload
    },
    removeSliceFromTransaction: (state, action: PayloadAction<string>) => {
      state.sliceToTransaction = state.sliceToTransaction.filter(item => item.id !== action.payload)
    },
    resetSliceFromTransaction: (state) => {
      state.sliceToTransaction = []
    },
  }
})

export const {
  addFilter,
  addMultipleSliceToTransaction,
  addMultipleSliceToTransactionFreeze,
  addSliceToTransaction,
  addSliceToPledge,
  removeSliceFromTransaction,
  resetSliceFromTransaction
} = sliceOfShareSlice.actions

export default sliceOfShareSlice.reducer
