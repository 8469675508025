import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Contact} from '../../../domain/Partner/Contact'
import {Address} from '../../../domain/Partner/Address'
import {FilterPartnerInterface} from '../../../domain/Partner/PartnerList'
import {PartnerInterface} from '../../../domain/Partner/Partner'
import {
  BeneficialBeneficiaries,
  BeneficialBeneficiariesInterface
} from '../../../domain/Partner/BeneficialBeneficiaries'
import {ConventionAnnex, ConventionAnnexInterface} from '../../../domain/Partner/ConventionAnnex'
import {RelationLink, RelationLinkInterface} from '../../../domain/Partner/RelationLink'
import {v4 as uuidV4} from 'uuid'

interface partner {
  reloadPartner: string
  countTotal: number
  contactArray: Contact[]
  addressArray: Address[]
  partner: PartnerInterface|null
  filter: FilterPartnerInterface|null
  beneficialBeneficiariesArray: BeneficialBeneficiaries[],
  conventionAnnexArray: any[],
  relationLinkArray: RelationLinkInterface[],
  partnerTransfertTarget: PartnerInterface|null,
  commissionRelations: PartnerInterface[],
}

const initialState: partner = {
  reloadPartner: uuidV4(),
  countTotal: 0,
  contactArray: [],
  addressArray: [],
  partner: null,
  filter: null,
  beneficialBeneficiariesArray: [],
  conventionAnnexArray: [],
  relationLinkArray: [],
  partnerTransfertTarget: null,
  commissionRelations: []
}

export const partnerSlice = createSlice({
  name: 'partner',
  initialState: initialState,
  reducers: {
    reloadPartner: (state) => {
      state.reloadPartner = uuidV4()
    },
    setCountTotal: (state, action: PayloadAction<number>) => {
      state.countTotal = action.payload
    },
    setContacts: (state, action: PayloadAction<Contact[]>) => {
      state.contactArray = action.payload
    },
    addContact: (state, action: PayloadAction<Contact>) => {
      state.contactArray.push(action.payload)
    },
    updateContact: (state, action: PayloadAction<Contact>) => {
      const index = state.contactArray.findIndex(contact => {
        return contact.id === action.payload.id
      });
      if (index >=0) {
        state.contactArray[index] = action.payload
      }
    },
    setAddresses: (state, action: PayloadAction<Address[]>) => {
      state.addressArray = action.payload
    },
    addAddress: (state, action: PayloadAction<Address>) => {
      state.addressArray.push(action.payload)
    },
    updateAddress: (state, action: PayloadAction<Address>) => {
      const index = state.addressArray.findIndex(address => {
        return address.id === action.payload.id
      });
      if (index >=0) {
        state.addressArray[index] = action.payload
      }
    },
    addFilter: (state, action: PayloadAction<FilterPartnerInterface|null>) => {
      state.filter = action.payload
    },
    addPartner: (state, action: PayloadAction<PartnerInterface|null>) => {
      state.partner = action.payload
    },
    savePartner: (state, action: PayloadAction<any>) => {
      state.partner = {...state.partner, ...action.payload}
    },
    setBeneficialBeneficiaries: (state, action: PayloadAction<BeneficialBeneficiaries[]>) => {
      state.beneficialBeneficiariesArray = action.payload
    },
    addBeneficialBeneficiaries: (state, action: PayloadAction<BeneficialBeneficiariesInterface>) => {
      state.beneficialBeneficiariesArray.push(action.payload)
    },
    updateBeneficialBeneficiaries: (state, action: PayloadAction<BeneficialBeneficiaries>) => {
      const index = state.beneficialBeneficiariesArray.findIndex(beneficialBeneficiaries => {
        return beneficialBeneficiaries.id === action.payload.id
      });
      if (index >=0) {
        state.beneficialBeneficiariesArray[index] = action.payload
      }
    },
    setConventionAnnex: (state, action: PayloadAction<ConventionAnnex[]>) => {
      state.conventionAnnexArray = [...action.payload]
    },
    addConventionAnnex: (state, action: PayloadAction<any>) => {
      state.conventionAnnexArray.push(action.payload)
    },
    updateConventionAnnex: (state, action: PayloadAction<any>) => {
      const index = state.conventionAnnexArray.findIndex(conventionAnnex => {
        return conventionAnnex.id === action.payload.id
      });
      if (index >=0) {
        state.conventionAnnexArray[index] = action.payload
      }
    },
    setCommissionRelations: (state, action: PayloadAction<PartnerInterface[]>) => {
      state.commissionRelations = action.payload
    },
    addCommissionRelation: (state, action: PayloadAction<PartnerInterface>) => {
      state.commissionRelations.push(action.payload)
    },
    deleteCommissionRelation: (state, action: PayloadAction<PartnerInterface>) => {
      state.commissionRelations = state.commissionRelations.filter(relation => {
        return relation.id !== action.payload.id
      })
    },
    setRelationLinks: (state, action: PayloadAction<RelationLink[]>) => {
      state.relationLinkArray = action.payload
    },
    addRelationLink: (state, action: PayloadAction<RelationLinkInterface>) => {
      state.relationLinkArray.push(action.payload)
    },
    updateRelationLink: (state, action: PayloadAction<RelationLink>) => {
      const index = state.relationLinkArray.findIndex(relationLink => {
        return relationLink.id === action.payload.id
      });
      if (index >=0) {
        state.relationLinkArray[index] = action.payload
      }
    },
    setPartnerTransfertTarget: (state, action) => {
      state.partnerTransfertTarget = action.payload
    }
  }
})

export const {
  reloadPartner,
  setCountTotal,
  addContact,
  setContacts,
  setAddresses,
  addAddress,
  updateAddress,
  addFilter,
  addPartner,
  setBeneficialBeneficiaries,
  addBeneficialBeneficiaries,
  updateBeneficialBeneficiaries,
  setConventionAnnex,
  addConventionAnnex,
  updateConventionAnnex,
  setRelationLinks,
  addRelationLink,
  updateContact,
  savePartner,
  updateRelationLink,
  setPartnerTransfertTarget,
  setCommissionRelations,
  addCommissionRelation,
  deleteCommissionRelation
} = partnerSlice.actions

export default partnerSlice.reducer
