export enum SortOrder {
  DESC = "DESC",
  ASC = "ASC"
}

export interface SortInterface {
  sortLabel: string|null
  sortOrder: SortOrder
  sortLabelApi?: string
}
