import {ScaleAvailable} from "../../../../../../domain/Product/Scale";
import InputDate from "../../../Elements/InputDate";
import {ScaleItemInterface} from "../../../../../../domain/Product/ScaleItem";
import expandIcon from "../../../../../../assets/images/icons/arrow-bottom--dark.svg";
import React, {FunctionComponent, MouseEvent, useEffect} from "react";
import {useAppSelector} from "../../../../../store/hook";
import {useTranslation} from "react-i18next";

type Props = {
  scale: ScaleAvailable,
  register: any,
  control: any,
  nameScale: string,
  setValue: any,
  scaleSelected: { scaleLabelCategory: string, subScaleLabelCategory: string[] },
  setScaleSelected: any
}

const ScaleBlock: FunctionComponent<Props> = ({
                                                scale,
                                                register,
                                                control,
                                                nameScale,
                                                setValue,
                                                scaleSelected,
                                                setScaleSelected
                                              }) => {
  const {t} = useTranslation()

  const openPartnerConventionAnnex = useAppSelector((state) => state.event.openPartnerConventionAnnexForm)

  useEffect(() => {
    setValue(`scales.${nameScale}.date`, scale.date)
    scale.items.forEach((item) => {
      setValue(`scales.${nameScale}.${item.category.label}`, item)
    })
  }, [])

  const selectScale = (scale: ScaleAvailable, scaleItem: ScaleItemInterface) => {
    if (scaleSelected.scaleLabelCategory === nameScale) {
      if (scaleSelected.subScaleLabelCategory.includes(scaleItem.id)) {
        const newSubScaleArray = scaleSelected.subScaleLabelCategory.filter((sub) => sub !== scaleItem.id)
        setScaleSelected((current: { scaleLabelCategory: string, subScaleLabelCategory: string[] }) => (
          {
            ...current,
            scaleLabelCategory: nameScale,
            subScaleLabelCategory: newSubScaleArray
          }
        ))
      } else {
        setScaleSelected((current: { scaleLabelCategory: string, subScaleLabelCategory: string[] }) => (
          {
            ...current,
            scaleLabelCategory: nameScale,
            subScaleLabelCategory: [...scaleSelected.subScaleLabelCategory, scaleItem.id]
          }
        ))
      }

    } else {
      setScaleSelected({
        ...scaleSelected,
        scaleLabelCategory: nameScale,
        subScaleLabelCategory: [scaleItem.id]
      })
    }

  }

  function handleCollapse(event: MouseEvent<HTMLElement>) {
    event.currentTarget.classList.toggle('collapse__title--active')
  }

  return (
    <div className={`form-bloc-scale form--bloc--general-scale`}>
      <div className="form-bloc__title">{scale.label}</div>
      <div className="form-bloc__form flex-container">
        <div className="col-md-4">
          <div className="form-control">
            <label htmlFor={`scales.${nameScale}.date`}
                   className="form-control__label">{t('product.form.scale.date')}
            </label>
            <div className="form-control__input">
              <InputDate id={`scales.${nameScale}.date`}
                         name={`scales.${nameScale}.date`}
                         register={register}
                         control={control}
                         required={scaleSelected.scaleLabelCategory.toLowerCase() === nameScale.toLowerCase()}
                         defaultValue={scale.date}
                         disabled={!nameScale.includes("Dérogatoire")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-bloc__form flex-container">
        {scale.items?.map((item, indexItem) => {
          return (
            <div className="col-md-12" key={item.id}>
              <button type="button"
                      className={`collapse__title ${openPartnerConventionAnnex?.conventionAnnex?.scaleItems.find((scaleItem) => scaleItem.id === item.id) ? 'collapse__title--active' : ''}`}
                      onClick={handleCollapse}>
                {item.category.label}
                <img src={expandIcon} alt=""/>
              </button>
              <div className="collapse__content-full flex-container">
                <div className="col-md-12 u-mts">
                  <div className="form-control">
                    <label htmlFor={`scales.${nameScale}.${item.category.label}.value`}
                           className="form-control__label">{t('product.form.scale.value')}</label>
                    <div className="form-control__input">
                      <input type="number"
                             step="0.01"
                             id={`scales.${nameScale}.${item.category.label}.value`}
                             defaultValue={item.value}
                             {...register(`scales.${nameScale}.${item.category.label}.value`)}
                             disabled={!nameScale.includes("Dérogatoire")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-control">
                    <label htmlFor={`scales.${nameScale}.${item.category.label}.approbationAt`}
                           className="form-control__label">{t('product.form.scale.approval')}</label>
                    <div className="form-control__input">
                      <InputDate id={`scales.${nameScale}.${item.category.label}.approbationAt`}
                                 name={`scales.${nameScale}.${item.category.label}.approbationAt`}
                                 register={register}
                                 control={control}
                                 defaultValue={item.approbationAt}
                                 disabled={!nameScale.includes("Dérogatoire")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-control">
                    <label htmlFor={`scales.${nameScale}.${item.category.label}.applicationAt`}
                           className="form-control__label">{t('product.form.scale.application')}</label>
                    <div className="form-control__input">
                      <InputDate id={`scales.${nameScale}.${item.category.label}.applicationAt`}
                                 name={`scales.${nameScale}.${item.category.label}.applicationAt`}
                                 register={register}
                                 control={control}
                                 defaultValue={item.applicationAt}
                                 disabled={!nameScale.includes("Dérogatoire")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-control">
                    <label htmlFor={`scales.${nameScale}.${item.category.label}.period.start`}
                           className="form-control__label">{t('product.form.scale.periodStart')}</label>
                    <div className="form-control__input">
                      <InputDate id={`scales.${nameScale}.${item.category.label}.period.start`}
                                 name={`scales.${nameScale}.${item.category.label}.period.start`}
                                 register={register}
                                 control={control}
                                 defaultValue={item.period?.start}
                                 disabled={!nameScale.includes("Dérogatoire")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-control">
                    <label htmlFor={`scales.${nameScale}.${item.category.label}.period.end`}
                           className="form-control__label">{t('product.form.scale.periodEnd')}</label>
                    <div className="form-control__input">
                      <InputDate id={`scales.${nameScale}.${item.category.label}.period.end`}
                                 name={`scales.${nameScale}.${item.category.label}.period.end`}
                                 register={register}
                                 control={control}
                                 defaultValue={item.period?.end}
                                 disabled={!nameScale.includes("Dérogatoire")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control form-control--center">
                    <input type="checkbox"
                           onChange={(e) => e?.target.checked ? selectScale(scale, item) : setScaleSelected({
                             scaleLabelCategory: '',
                             subScaleLabelCategory: []
                           })}
                           checked={scaleSelected.subScaleLabelCategory.includes(item.id)}
                    />
                    <label className="form-control__label" htmlFor={`scales.${nameScale}.${item.category.label}.checked`}>Ce
                      barème s'applique au
                      partenaire</label>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ScaleBlock