import React, {FunctionComponent} from 'react';
import {ReferentielInterface} from "../../../../domain/Referentiel/ReferentielInterface";

type Props = {
  referential: ReferentielInterface
  status: string
  size?: "xs"
}
const StatusChips: FunctionComponent<Props> = ({size, status, referential}) => {
  return (
    <div className={ `badge etat ${'etat--'+status} badge--shadow u-mrs` }>
      <div className={`badge__text etat-small${size ? "-" + size : ""}`}>
        { referential.prospect.status.find(property => property.value === status)?.label.toString() }
      </div>
    </div>
  );
}

export default StatusChips;