import MovementGatewayInterface from '../../../domain/Movement/MovementGatewayInterface';
import { ListRequestInterface } from './ListRequest';
import { TransactionListInterface } from '../../../domain/Movement/TransactionList';

export default class TransactionListUseCase
{
  public productGateway

  constructor(MovementGateway: MovementGatewayInterface) {
    this.productGateway = MovementGateway
  }

  async execute(ListRequest: ListRequestInterface, signal?: AbortSignal): Promise<{data: TransactionListInterface[]|null, numberOfItems: number}|null> {
    return await this.productGateway.getTransactionListToCome(ListRequest.currentPage, ListRequest.itemPerPage, ListRequest.filter, signal).then(response => response)
  }

}
