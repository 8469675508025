import { FormEvent, FunctionComponent, InputHTMLAttributes, useState } from 'react';

import { IFormFieldProps } from './IFormFieldProps';
import iconMark from '../../../../assets/images/icons/mark-blue.svg';
import ReactTooltip from 'react-tooltip';

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  id: string;
  name: string;
  classes?: string;
  defaultValue?: string;
  onChange?: (e: FormEvent<HTMLInputElement>) => void;
  formatNumber?: { value: number | null, scale?: number };
  tooltip?: string;
  labelClasses?: string;
  inputClasses?: string;
}

const Input: FunctionComponent<IProps> = ({
                                            id,
                                            name,
                                            classes,
                                            label,
                                            onChange,
                                            defaultValue,
                                            register,
                                            required,
                                            formatNumber,
                                            tooltip,
                                            labelClasses,
                                            inputClasses,
                                            ...rest
                                          }) => {

  const [state, setState] = useState({ isEditing: false });

  function format(value: number | null, scale = 2): string | undefined {
    if (value) {
      const formatter = new Intl.NumberFormat('fr-FR', {
        minimumFractionDigits: scale,
      });
      return formatter.format(value);
    }
    return undefined;
  }

  function toggleEditing() {
    setState({ isEditing: !state.isEditing });
  }

  const inputElement = <div className="form-control__input">
    {
      formatNumber ? (
            state.isEditing ? (
                <input defaultValue={defaultValue} {...rest}
                       id={id}
                       required={required}
                       readOnly={rest.readOnly}
                       title={rest.help}
                       { ...register(name)}
                       type="text"
                       value={format(formatNumber.value, formatNumber?.scale ?? 2)}
                       onBlur={toggleEditing.bind(this)}
                       className={inputClasses ?? ''}
                />
            ) : (
                <input defaultValue={defaultValue} {...rest}
                       id={id}
                       required={required}
                       readOnly
                       title={rest.help}
                       { ...register(name) }
                       type="text"
                       value={format(formatNumber.value, formatNumber?.scale ?? 2)}
                       onFocus={toggleEditing.bind(this)}
                       className={inputClasses ?? ''}
          />
        )
      ) : onChange ? (
        <input defaultValue={ defaultValue } { ...rest }
               id={ id }
               required={ required }
               readOnly={ rest.readOnly }
               title={ rest.help }
               { ...register(name) }
               onChange={ onChange }
               className={inputClasses ?? ''}
        />
        ) : (
          <input defaultValue={defaultValue} {...rest}
                 id={id}
                 required={required}
                 readOnly={rest.readOnly}
                 title={rest.help}
                 { ...register(name) }
                 className={inputClasses ?? ''}
          />
        )
    }
  </div>;

  return (
    <>
      <div className={ classes ? `${ classes }` : '' }>
        { label &&
            <div className="form-control">
                <div className="flex items-end">
                    <ReactTooltip/>
                    <label htmlFor={ id } className={(labelClasses ? labelClasses + " form-control__label" : "form-control__label") + (required ? " mandatory-field " : " ")}>{ label }</label>
                    { tooltip && <div data-tip={ tooltip }>
                        <img src={ iconMark } className="mark-tooltip" alt="help mark"/>
                    </div> }
                </div>
              { inputElement }
            </div>
        }
        { !label && inputElement }
      </div>
    </>
  );
};

export default Input;
