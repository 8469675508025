import {FunctionComponent, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../store/hook";
import {addCommissionRelation} from "../../../../store/component/partner";
import {setOpenCommissionRelationForm, setOpenDatalistFilterPartner} from "../../../../store/component/event";
import blockScrollBody from "../../../util/BlockScroll";
import {PartnerListInterface} from "../../../../../domain/Partner/PartnerList";
import getClassForOverlay from "../../../util/Sidebar";
import PartnerDatalist from "../../Datalist/PartnerDatalist";
import {InitializePartner} from "../../../../../useCase/Partner/Initialization/InitializePartner";

const AddCommissionRelation: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openCommissionRelationForm = useAppSelector((state) => state.event.openCommissionRelationForm)
  const openDatalistFilterPartner = useAppSelector((state) => state.event.openDatalistFilterPartner)
  const commissionRelations = useAppSelector((state) => state.partner.commissionRelations)

  useEffect(() => {
    if(openCommissionRelationForm.level) {
      const levelWordSplit = openCommissionRelationForm.level.split("_")
      const oneLevelAdded = parseInt(levelWordSplit[1], 10) + 1
      levelWordSplit[1] = oneLevelAdded.toString()

      dispatch(setOpenDatalistFilterPartner({
        ...openDatalistFilterPartner,
        filters: {
          ...openDatalistFilterPartner.filters,
          commissionLevel: levelWordSplit.join("_"),
        }
      }))
    }

    blockScrollBody(openCommissionRelationForm.show)
  }, [openCommissionRelationForm])

  const handleChoicePartner = function (selectedPartner: PartnerListInterface) {
    const partnerModel = new InitializePartner().initializePartner()
    partnerModel.socialReason = selectedPartner.cabinetName
    partnerModel.id = selectedPartner.id
    partnerModel.partnerCode = selectedPartner.partnerId
    partnerModel.commissionLevel = selectedPartner.commissionLevel
    partnerModel.commissioned = selectedPartner.commissioned
    dispatch(addCommissionRelation(partnerModel))
    dispatch(setOpenCommissionRelationForm({show: false}))
  }

  const handleClose = function () {
    dispatch(setOpenCommissionRelationForm({show: false}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openCommissionRelationForm.show)}`} onClick={() => handleClose()}/>
      <div className={`sidebar sidebar--right sidebar--right ${openCommissionRelationForm.show ? 'sidebar--active-big' : ''}`}>
        <div className="form-bloc">
          <div className="sidebar__content">
            <div className="title">{t('partner.form.commission.add-relation')}</div>
            <PartnerDatalist callback={handleChoicePartner}
                             minimumLevel={openCommissionRelationForm?.level}
                             currentLevel={openCommissionRelationForm?.currentLevel}
                             commissionRelations={commissionRelations}
                             callerType={"datalist"}
            />
          </div>
          <footer className="sidebar__footer">
            <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => handleClose()}>{t('common.cancel')}</button>
          </footer>
        </div>
      </div>
    </>
  )
}
export default AddCommissionRelation
