import {Document, DocumentInterface} from "../../../domain/Document/Document";
import {DocumentType} from "../../../domain/Document/DocumentInterface";

export default class DocumentUtils {
  getDocumentsForProspect(data: any, documentArray: DocumentType): Document[] {
    if (!data) {
      return []
    }

    const documentsOfProspect : Document[] = [];
    Object.entries(data).map((documents: any) => {
      Object.entries(documents[1]).map((document: any) => {
        const alreadyDocument = documentArray[documents[0]]?.find((doc: any) => {
          return doc.type === document[0]
        })

        if (alreadyDocument) {
          documentsOfProspect.push(
            new Document(
              alreadyDocument.id,
              alreadyDocument.updatedAt,
              document[1].date,
              documents[0],
              alreadyDocument.type,
              alreadyDocument.title,
              alreadyDocument.extension
            )
          )
        } else if (document[1].date) {
          documentsOfProspect.push(
            new Document(
              null,
              null,
              document[1].date,
              documents[0],
              document[0],
              null,
              null
            )
          )
        }
      })
    })

    return documentsOfProspect
  }

  getDocumentsForPerson(data:any, documentArray: any, personId: string): DocumentInterface[] {
    if (!data) {
      return []
    }
    const documentsOfPerson: Document[] = [];
    Object.entries(data).map((document: any) => {
      const alreadyDocument = documentArray[document[0]]?.find((doc: any) => {
        return doc.type === document[0]
      })

      if (alreadyDocument) {
        alreadyDocument.date = document[1].date
        alreadyDocument.documentType = alreadyDocument.type
        alreadyDocument.person = personId
        documentsOfPerson.push(alreadyDocument)
      } else if (document[1].date) {
        documentsOfPerson.push(
          new Document(
            null,
            null,
            document[1].date,
            personId,
            document[0],
            null,
            null
          )
        )
      }
    })

    return documentsOfPerson
  }
}
