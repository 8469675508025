import { FilterPortfolioInterface } from '../../../domain/Movement/PortfolioList';

interface PortfolioRequestInterface {
    currentPage: number
    itemPerPage: number
    filter: FilterPortfolioInterface|null
}

class PortfolioRequest implements PortfolioRequestInterface{
    public currentPage: number
    public itemPerPage: number
    public filter: FilterPortfolioInterface|null

    constructor(
        currentPage: number,
        itemPerPage: number,
        filter: FilterPortfolioInterface|null,
    ) {
        this.currentPage = currentPage
        this.itemPerPage = itemPerPage
        this.filter = filter
    }
}

export {
    PortfolioRequest
}
export type { PortfolioRequestInterface }
