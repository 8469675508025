import React, {ChangeEvent, FunctionComponent} from 'react';
import {useTranslation} from "react-i18next";
import {RoleInterface} from "../../../../../../domain/Roles/Roles";
import '../../../../../../assets/styles/page/_roleSetting.scss'

type Props = {
  role: RoleInterface
  handleChange: (e: ChangeEvent<HTMLInputElement>, ruleName: string, action: string) => void
  selectOrDeselectAll: any
}

const RoleSettingCheckbox: FunctionComponent<Props> = ({role, handleChange, selectOrDeselectAll}) => {
  const {t} = useTranslation()

  return (
    <form className="">
      {
        role?.rules && role?.label && Object.entries(role.rules).map((rule: any) => {
          let allActionChecked
          if(rule[0] === "customer") {
            allActionChecked = rule[1].actions.export && rule[1].actions.read && rule[1].actions.update && rule[1].visible
          } else {
            allActionChecked = rule[1].actions.export && rule[1].actions.read && rule[1].actions.update && rule[1].actions.create && rule[1].visible
          }
          const visibleObject = Object.entries(rule[1])[1] as [string, boolean]
          const nameFormatVisible = `${(role?.label).replace(" ", "")}.${rule[0]}.${visibleObject[0]}`
          return (
            <React.Fragment key={rule[0]}>
              <div className="bloc-checkbox u-mbb">
                <label>{t(`settings.form.bloc-role-settings.modules.module`) + " " + t(`settings.form.bloc-role-settings.modules.${rule[0]}`)}</label>
                <div className={`checkbox-toggle__wrapper bloc-checkbox--${visibleObject[0]}`}>
                  <span className="form-control__label u-mrb">{t(`settings.form.bloc-role-settings.checkboxes.label.${visibleObject[0]}`)}</span>
                  <input type="checkbox"
                         name={nameFormatVisible}
                         checked={visibleObject[1]}
                         onChange={(e) => handleChange(e, rule[0], visibleObject[0])}
                         id={nameFormatVisible}
                         className="checkbox-toggle checkbox-toggle--light no-skin checkbox-toggle-radio-default"
                  />
                  <label className="checkbox-toggle__button" htmlFor={nameFormatVisible}/>
                </div>
                {Object.entries(rule[1].actions)
                  .filter((val => val[0] === "read" || val[0] === "update" || val[0] === "export" || val[0] === "create" || val[0] === "list"))
                  .map((item: [string, any]) => {
                    const nameFormat = `${(role?.label).replace(" ", "")}.${rule[0]}.${item[0]}`
                    return (
                      <div key={item[0]} className={`checkbox-toggle__wrapper bloc-checkbox--${item[0]}`}>
                        <span className="form-control__label u-mrb">{t(`settings.form.bloc-role-settings.checkboxes.label.${item[0]}`)}</span>
                        <input type="checkbox"
                               name={nameFormat}
                               checked={item[1]}
                               onChange={(e) => handleChange(e, rule[0], item[0])}
                               id={nameFormat}
                               className="checkbox-toggle checkbox-toggle--light no-skin checkbox-toggle-radio-default"
                        />
                        <label className="checkbox-toggle__button" htmlFor={nameFormat}/>
                      </div>
                    )
                  })}
                {rule[0] !== "setting" && (
                  <div className={`checkbox-toggle__wrapper bloc-checkbox--select-all`}>
                    <span className="form-control__label u-mrb">{t('settings.form.bloc-role-settings.checkboxes.label.select-all')}</span>
                    <input type="checkbox"
                           checked={allActionChecked}
                           onChange={(e) => selectOrDeselectAll(e, rule[1].actions, rule[0], rule[1].visible)}
                           id={`select-all-${rule[0]}`}
                           className="checkbox-toggle checkbox-toggle--light no-skin checkbox-toggle-radio-default"
                    />
                    <label className="checkbox-toggle__button" htmlFor={`select-all-${rule[0]}`}/>
                  </div>
                )}
              </div>
            </React.Fragment>
          )
        })
      }
    </form>
  );
}

export default RoleSettingCheckbox;
