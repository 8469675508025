import {Presenter} from '../Presenter'
import {MovementListInterface} from '../../domain/Movement/MovementList'

class PortfolioTransfertListPresenter extends Presenter {


    constructor(getParts: any[]) {
        super({
            viewModel: {
                title: 'portfolio.search-a-movement',
                heading: [
                    'portfolio.heading.action',
                    'portfolio.heading.acronym',
                    'portfolio.heading.id_movement',
                    'portfolio.heading.esn_type',
                    'portfolio.heading.type',
                    'portfolio.heading.nature_detention',
                    'portfolio.heading.date_movement',
                    'portfolio.heading.quantity',
                    'portfolio.heading.no_part_start',
                    'portfolio.heading.no_part_end',
                    'portfolio.heading.date_use',
                    'portfolio.heading.date_first_payment',
                    'portfolio.heading.price',
                    'portfolio.heading.id_invest',
                    'portfolio.heading.name_invest',
                    'portfolio.heading.id_usu',
                    'portfolio.heading.rate_dismemberment',
                    'portfolio.heading.end_date_dismemberment'
                ],
                datas: getParts,
                count: getParts.length
            }
        });
    }

}

export default PortfolioTransfertListPresenter
