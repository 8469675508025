import {DepositaryInterface} from "../../domain/Depositary/Depositary";
import {ListDepositary} from "../../fixtures/Depositary";
import Caller from "../../domain/Api/Caller";
import envVariable from '../../infrastructure/presentation/util/envVariable'

export default class DepositaryGateway {
  getReferentiel(): Promise<DepositaryInterface[]> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/depositaries`, {})
    .then(data => {
      if (data) {
        return data
      }

      return [];
    })
    .catch(() => {
      return [];
    });
  }
}
