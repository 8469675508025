import React, {FunctionComponent, useEffect, useState} from "react";
import ReferentielGateway from "../../../../../gateway/Referentiel/ReferentielGateway";
import Select from "react-select";
import {Controller, FieldErrors} from 'react-hook-form'
import {Country} from "../../../../../domain/Referentiel/Country/Country";
import {calculateRgba} from "react-spinners/helpers";
import useDebounce from "../../../../../domain/CustomHooks/useDebounce";
import {useAppSelector} from "../../../../store/hook";
import {useTranslation} from "react-i18next";
import {TargetPropertyInObjWithStringPath} from "../../../util/TargetPropertyInObjWithStringPath";

type Props = {
  control: any
  name: string
  defaultValue: Country|null
  disabled?: boolean
  isOnError?: boolean
  errors?: FieldErrors
}

const defaultCustomStyles = {
  control: (base: any) => ({
    ...base,
    minHeight: 25
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: 4
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 4
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: '0px 6px',
  }),
  input: (base: any) => ({
    ...base,
    margin : 0,
    padding: 0
  })
}

const CountryDatalist: FunctionComponent<Props>= ({ control, name, defaultValue, disabled, isOnError, errors }) => {
  const { t } = useTranslation()
  const [countryArray, setCountryArray] = useState<Country[]>([])
  const [customStyles, setCustomStyles] = useState<any>(defaultCustomStyles)
  const [inputValue, setInputValue] = useState<string>('')
  const debounceValue= useDebounce<string>(inputValue, 500)

  const countryByDefault = useAppSelector(({referential}) => referential.countryByDefault)

  const referentialGateway = new ReferentielGateway()

  useEffect(() => {
    if (defaultValue) {
      setCountryArray([defaultValue])
    }
  }, [defaultValue])

  useEffect(() => {
    if (isOnError) {
      setCustomStyles({
        control: (base: any) => ({
          ...base,
          minHeight: 25,
          borderBottom: '1px solid #A6440C',
        }),
        dropdownIndicator: (base: any) => ({
          ...base,
          padding: 4
        }),
        clearIndicator: (base: any) => ({
          ...base,
          padding: 4
        }),
        valueContainer: (base: any) => ({
          ...base,
          padding: '0px 6px'
        }),
        input: (base: any) => ({
          ...base,
          margin : 0,
          padding: 0
        })
      })
    } else {
      setCustomStyles(defaultCustomStyles)
    }
  }, [isOnError])

  useEffect(() => {
    updateReferential(inputValue)
  },[debounceValue])

  function updateReferential (search: string) {
    if (search.length > 3) {
      referentialGateway.getCountry(search).then((response: Country[]) => {
        setCountryArray(response)
      })
    }
  }

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          required: t('error.required-field') as string
        }}
        render={({
            field: { onChange, value, name },
        }) => (
          <Select
            name={name}
            onInputChange={ (string) => setInputValue(string) }
            defaultValue={defaultValue}
            options={countryArray}
            onChange={(option) =>  onChange(option)}
            styles={customStyles}
            isClearable={true}
            isDisabled={disabled || false}
            value={value || {id: "404", label: "Saisissez le non d'un pays..."}}
            className={isOnError ? 'field-on-error' : ''}
            aria-label={"country"}
          />
          )
        }
      />
      {errors && TargetPropertyInObjWithStringPath(name, errors) && <p className="input-error-msg">{TargetPropertyInObjWithStringPath(name, errors)?.message}</p>}
    </>
  )

}

export default CountryDatalist
