import React, {FunctionComponent, useEffect, useState} from "react";
import {v4 as uuidV4} from "uuid";
import InputDate from "../../Elements/InputDate";
import eyeIcon from "../../../../../assets/images/icons/eye.svg";
import {setOpenUploadForm, setOpenDocumentList} from "../../../../store/component/event";
import uploadIcon from "../../../../../assets/images/icons/upload.svg";
import {FormDocumentInterface} from "../../../../../domain/Document/FormDocument";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../../store/hook";
import DocumentGatewayInterface from "../../../../../domain/Document/DocumentGatewayInterface";
import {DocumentListInterface} from "../../../../../domain/Document/Document";
import {DocumentInterface} from "../../../../../domain/Document/DocumentInterface";

interface Props {
  title: string
  name: string
  uuid: string
  documentGateway: DocumentGatewayInterface
  register: any
  control: any
  documentData: DocumentInterface[]
  isLectureMode?: boolean
}

const Form: FunctionComponent<Props> = (
    {
      title,
      name,
      uuid,
      documentGateway,
      register,
      control,
      documentData,
      isLectureMode
    }
  ) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const [documentList, setDocumentList] = useState<DocumentListInterface[]>([]);

  useEffect(() => {
    documentGateway.getList().then((response) => {
      setDocumentList(response)
    })
  }, [])

  function showDocumentList(type: string) {
    dispatch(setOpenDocumentList({
      show: true,
      documentList: documentData?.filter((document: any) => document.type === type),
      gateway: documentGateway
    }))
  }

 function renderDocumentFormLine (document: FormDocumentInterface) {
    return (
      <tr key={uuidV4()}>
        <td className={`form-control__label`}>
          {document.label}
        </td>
        {document.value &&
          <>
            <td>
              <InputDate register={register}
                         control={control}
                         name={`${name}.${document.value}.date`}
                         id={`${name}.${document.value}.date`}
                         readOnly={isLectureMode}
              />
            </td>
            <td>
              <InputDate
                         disabled
                         register={register}
                         control={control}
                         name={`${name}.${document.value}.updatedAt`}
                         id={`${name}.${document.value}.updatedAt`}
              />
            </td>
            <td>
              <button type="button" className="button-reset" onClick={() => showDocumentList(document.value ?? '')}>
                <img src={eyeIcon} alt="" />
              </button>
              {!isLectureMode && <button type="button" className="button-reset"
                         onClick={() => dispatch(setOpenUploadForm({
                             show: true,
                             documents: null,
                             uuid: uuid,
                             type: document.value
                         }))}
                >
                <img src={uploadIcon} alt=""/>
              </button>}
            </td>
          </>
        }
      </tr>
    )
 }

  return (
    <div className={`form-bloc form--bloc--list-documents`}>
      <div className="form-bloc__title">{title}</div>
      <div className="form-bloc__form flex-container">
        <div className="col-md-12">
          <table className="table--list-documents">
            <thead>
            <tr>
              <th/>
              <th className="form-control__label">{t('movement.redemption-withdrawal.form.list-of-documents.askedAt')}</th>
              <th className="form-control__label">{t('movement.redemption-withdrawal.form.list-of-documents.createdAt')}</th>
              <th className="form-control__label"/>
            </tr>
            </thead>
            <tbody>
            {documentList.map(item => (item &&
              <>
               { renderDocumentFormLine(item)}

                {item.documents &&
                  item.documents.map(document => {
                    return renderDocumentFormLine(document)
                  })
                }
              </>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Form
