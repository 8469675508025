import React, {FunctionComponent, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {v4 as uuidV4} from 'uuid'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../../store/hook'

import {CustomerInterface} from '../../../../../../domain/Customer/Customer'
import {
  setIsLoading,
  setOpenBankInformationFormEvent,
  setOpenCustomerUploadForm
} from '../../../../../store/component/event'
import {BankInformation, BankInformationInterface} from '../../../../../../domain/Customer/BankInformation'
import ProductGateway from '../../../../../../gateway/Product/ProductGateway'
import {setBankInformations, setProductsReferentiel} from '../../../../../store/component/customer'
import InitializeCustomer
  from '../../../../../../useCase/Customer/Initialization/InitializeCustomer'
import UpdateUseCase from '../../../../../../useCase/Customer/Update/UpdateUseCase'
import CustomerGateway from '../../../../../../gateway/Customer/CustomerGateway'
import BankInformationGateway
  from '../../../../../../gateway/Customer/BankInformation/BankInformationGateway'
import AddUseCase from '../../../../../../useCase/Customer/Add/AddUseCase'

import iconAdd from '../../../../../../assets/images/icons/add.svg'
import '../../../../../../assets/styles/page/_account.scss'
import DeleteUseCase from "../../../../../../useCase/Customer/BankInformation/Delete/DeleteUseCase";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import {reloadCustomer} from "../../../../../store/component/customer";
import BankInformationDatalist from "../../../Datalist/bankInformationDatalist";
import DocumentGateway from "../../../../../../gateway/Customer/Document/DocumentGateway";
import {DocumentCustomerInterface, DocumentCustomerToSend} from "../../../../../../domain/Customer/Document/Document";
import AlertInfo from "../../../Alert/AlertInfo/AlertInfo";
import {ReferentielInterface} from "../../../../../../domain/Referentiel/ReferentielInterface";

type Props = {
  customer?: CustomerInterface
  isLectureMode?: boolean
  referential: ReferentielInterface
}

const BankDetails: FunctionComponent<Props> = ({customer, isLectureMode, referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const { customerType } = useParams()
  const navigate = useNavigate()

  const [submitted, setSubmitted] = useState(false);
  const [inError, setInError] = useState<boolean>(false);
  const isLoading = useAppSelector((state) => state.event.isLoading)

  const bankInformationArray = useAppSelector((state) => state.customer.bankInformationArray)
  const [bankInformationList, setBankInformationList] = useState<BankInformation[]|[]>(bankInformationArray)

  useEffect(() => {
    new ProductGateway().getAll().then((data) => {
      dispatch(setProductsReferentiel(data))
    })
  }, [])

  useEffect(() => {
    setBankInformationList(bankInformationArray)
    setInError(!bankInformationArray.some(item => item.main) && bankInformationArray.length > 0)
  }, [bankInformationArray])

  function removeItem(bankInformation: BankInformation) {
    if (customer) {
      new DeleteUseCase(new BankInformationGateway()).execute(customer, bankInformation).then((response) => {
        const bankInformationFilteredList = bankInformationList.filter(function(item) {
          return item.id !== bankInformation.id
        })
        setBankInformationList(bankInformationFilteredList)
        dispatch(setBankInformations(bankInformationFilteredList))
      })
    }
  }

  function onSubmit() {
    if (bankInformationList.length == 0) {
      return
    }

    if (undefined === customer) {
      customer = new InitializeCustomer().initializeCustomer()
    }

    if (null == customer.customerType ) {
      customer.customerType = undefined !== customerType ? customerType : 'physical'
    }

    customer.bankInformations = bankInformationList

    if (null !== customer.id) {
      dispatch(setIsLoading(true))
      new UpdateUseCase(new CustomerGateway()).execute(customer).then(response => {
        if (null !== response) {
          toastSuccess(t('account.notify.update-success'));
          dispatch(reloadCustomer());
        } else {
          toastError(t('account.notify.update-error'));
        }
      })
    } else {
      dispatch(setIsLoading(true))
      new AddUseCase(new CustomerGateway()).execute(customer).then(uuid => {
        if (null !== uuid) {
          toastSuccess(t('account.notify.add-success'));
          window.location.href = `/${t('url.customer.edit')}/${uuid}`

        } else {
          toastError(t('account.notify.add-error'));
        }
        dispatch(setIsLoading(false))
      })
    }
  }

  const uploadCallBack = (show: boolean, documents: any[]|null, personId: string|null, type: string|null, ribId: string|null) => {
    dispatch(setOpenCustomerUploadForm( {
      show: show,
      documents: documents,
      personId: personId,
      type: type,
      ribId: ribId
    }))
  }

  const viewFilesCallback = (ribId: string|null) => {
    if (customer?.id && ribId) {
      new DocumentGateway().getRibFiles(customer?.id, ribId).then((response) => {
        if (response && response.length > 0) {
          response.map((document: DocumentCustomerInterface) => {
            if (customer?.id) {
              new DocumentGateway().getRibFile(customer?.id, ribId, document.id).then(response => {
                const file = window.open('')

                if (null !== file && response !== null) {
                  file.document.write(
                    "<body style='margin:0px;padding:0px;overflow:hidden'><iframe width='100%' height='100%' style='border:0' src='" + response + "'></iframe></body>"
                  )
                }
              })
            }
          })
        }

      })
    }
  }

  const bankDetailsBlock = <div className={`form-bloc form--bloc--bank-details`}>
    <div className="form-bloc__title">{t('account.form.bank-details.title')}</div>
    {!isLectureMode && <div className="form-bloc__shortcut u-txt-right">
      <button type="button" className="button-reset u-txt-with-icon"
              onClick={() => dispatch(setOpenBankInformationFormEvent({show: true, bankInformation: null}))}>
        <img src={iconAdd} alt=""/>
        {t('account.form.bank-details.add')}
      </button>
    </div>}
    <div className="form-bloc__form flex-container">
      <div className="col-md-12">
        <BankInformationDatalist referential={referential} viewFilesCallback={viewFilesCallback} uploadCallback={uploadCallBack} bankInformation={bankInformationArray} removeItem={removeItem} accountId={customer?.id ?? null} isLectureMode={isLectureMode}/>
      </div>
    </div>
  </div>

  const renderFooter = () => {
    if(isLectureMode) return null
    return <footer className={`form-bloc__footer`}>
      <button type="button"
              className="button button--ink-2"
              disabled={isLoading}
              onClick={() => onSubmit()}
      >{t('common.save')}</button>
      <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => {
        navigate(`/${t('url.customer.dashboard')}`)
      }}>{t('common.cancel')}</button>
    </footer>
  }

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  return (
    <div>

      <div className="flex-container" ref={measuredRef}>
        <div className="col-md-12">
          {inError &&
              <AlertInfo text={t('prospect.form.bank-information.missing-default-rib')} />
          }
        </div>
        <div className="col-md-12">{bankDetailsBlock}</div>
      </div>
      {renderFooter()}
    </div>
  )
}

export default BankDetails
