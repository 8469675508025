import React, {FunctionComponent, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useFieldArray, useForm, useFormState} from 'react-hook-form'
import {v4 as uuidV4} from 'uuid'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css'

import {useAppDispatch, useAppSelector} from '../../../../../store/hook'
import {ReferentielInterface} from '../../../../../../domain/Referentiel/ReferentielInterface'
import ProductDalalist from '../../../Datalist/Product/ProductDalalist'
import Input from '../../../Elements/Input'
import SelectCustom from '../../../Elements/Select'
import InputDate from '../../../Elements/InputDate'
import Checkbox from '../../../Elements/Checkbox'
import ListToSell from '../../../Datalist/Parts/ListToSell'
import ProductGateway from '../../../../../../gateway/Product/ProductGateway'
import {ProductInterface} from '../../../../../../domain/Product/Product'
import {Investor} from '../../../../../../domain/PreSubscription/Investor'
import {
  setOpenChoiceInvestorEvent,
  setOpenChoicePartnerEvent,
  setOpenChoiceSliceToGiveUpEvent
} from '../../../../../store/component/event'
import {Mutation} from '../../../../../../domain/Movement/Mutation'
import {ActionListInterface} from '../../../../../../domain/Movement/Action/Action'
import MutationGateway from '../../../../../../gateway/Movement/Mutation/MutationGateway'
import AlertInfo from '../../../Alert/AlertInfo/AlertInfo'
import MovementTag from '../../../Tag/MovementTag/MovementTag'

import eyeIcon from '../../../../../../assets/images/icons/eye.svg'
import iconAdd from '../../../../../../assets/images/icons/add.svg'
import '../../../../../../assets/styles/page/_movement.scss'
import Status from "../../../Transaction/Status";
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {usePrompt} from "../../../../util/Navigation";
import {
  reloadMovement,
  setInvestorTransferor,
  setPartner
} from "../../../../../store/component/movement";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import ActionReason from "../../../Transaction/ActionReason";
import ActionsForm from "../../Action/ActionsForm";
import {
  addMultipleSliceToTransaction,
  resetSliceFromTransaction
} from "../../../../../store/component/slice";
import SliceGateway from "../../../../../../gateway/Share/Slice/SliceGateway";
import ReactTooltip from "react-tooltip";
import RecapForm from "../../Recap/RecapForm";
import removeIcon from "../../../../../../assets/images/icons/remove.svg";
import {
  DistributionTransferorShare,
  distributionTransferorShareInterface
} from "../../../../../../domain/Movement/DistributionTransferorShare";
import { Link, useNavigate } from 'react-router-dom';
import iconSearch from '../../../../../../assets/images/icons/datalist-search.svg';
import FooterBlock from "../../../Block/FooterBlock";
import NoteBlock from "../../../Block/NoteBlock";
import CKEditorUtil from "../../../../util/CKEditor";
import CommentBlock from "../../../Block/CommentBlock";
import ProspectUtils from "../../../../util/ProspectUtils";
import List from "../../../../util/List";
import FormErrorInfoDropdown from "../../../Alert/FormErrorInfoDropdown/FormErrorInfoDropdown";
import {Prospect} from "../../../../../../domain/Prospect/Prospect";
import InputNumber from "../../../Elements/InputNumber";

interface IFormInput {
  distributionTransferorShare: distributionTransferorShareInterface[] | [],
  generalInformation: {
    product: {
      id: string|null,
      label: string|null,
      value: string|null
    }
    movementNumber: string|null
    propertyType: string|null
    administrator: string|null
    possessionMode: string|null
    subscribedAt: string|null
    externalMovementNumber: string|null
    horodatage: string|null
    updatedAt: string|null
    lastUpdateUser: string|null
  }
  actions: {
    engage: string|null
    returnCompleteness: string|null
    cancel: string|null
    verify: string|null
    confirmation: string|null
    validation: string|null
  }
  investor: {
    first: {
      id: string|null
      code: string|null
      identity: string|null
      partnerNumber: string
      partnerSocialReason: string
      deceasedAt: string
      platformNetworkNumber: string|null
      platformNetworkLastName: string|null
    }
  }
  withdrawalRequest: {
    typeOfMovement: string|null
    executedAt: string|null
    shareCount: number|null
    selectAllUnit: boolean
    choiceUnitNumbers: boolean
    sharePrice: number|string|null
    dateVL: string|null
    flatFee: number|null
    amount: number|string|null
  }
  note: string
  comment: string
}

type Props = {
  movement: Mutation
  isLectureMode: boolean
}

const Form: FunctionComponent<Props> = ({movement,isLectureMode}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const partners = useAppSelector((state) => state.movement.partners)
  const investors = useAppSelector((state) => state.movement.investors)
  const shareSlices = useAppSelector((state) => state.slice.sliceToTransaction)
  const shareSlicesFreeze = useAppSelector((state) => state.slice.sliceToTransactionFreeze)
  const [product, setProduct] = useState<ProductInterface|null>(null)
  const [decimalStep, setDecimalStep] = useState<number>(8)
  const [actionArray, setActionArray] = useState<ActionListInterface|null>(null)
  const [uuidRefresh, setUuidRefresh] = useState<string>('')
  const [actions, setActions] = useState<ActionListInterface|null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formError, setFormError] = useState<any>(null)
  const [stateBtnDistributionTransferorShare, setStateBtnDistributionTransferorShare] = useState({
    np: {
      investor: true,
      partner: true,
      hide: movement.propertyType === "usufruct",
    },
    usu: {
      investor: true,
      partner: true,
      hide: movement.propertyType === "np"
    }
  })
  const [investorLink, setInvestorLink] = useState<string|null>(null)
  const [partnerLink, setPartnerLink] = useState<string|null>(null)
  const prospectUtils = new ProspectUtils()

  const {np, usu} = stateBtnDistributionTransferorShare

  useEffect(() => {
    const transferor = movement.investors?.find((investor) => {
      return investor?.type === "transferor" || null
    })

    const investors = movement.investors?.filter((investor) => investor?.type === "investor").map((invest :any, index):DistributionTransferorShare => {

      return new DistributionTransferorShare(
        uuidV4(),
         invest.shareCount,
        {
          investor: invest.prospect ? {
            idInvestor: invest.prospect.id,
            code: invest.prospect.prospectCode,
            firstname: invest.prospect.persons[0].firstname,
            lastname: invest.prospect.persons[0].lastname,
            fullName: Prospect.getFullName(invest.prospect)
            ,
            propertyNature: 'np'
          } : {
            idInvestor: "",
            code: "",
            firstname: "",
            lastname: "",
            fullName: '',
            propertyNature: 'np',
          },
          partner: invest.partner ? {
            idPartner: invest.partner.id,
            code: invest.partner.partnerCode,
            socialReason: invest.partner.socialReason,
            propertyNature: 'np'
          } : {idPartner: "", code: "", socialReason: "", propertyNature: 'np'}
        },
        {
          investor: invest.usufruct ? {
            idInvestor: invest.usufruct.id,
            code: invest.usufruct.prospectCode,
            firstname: invest.usufruct.persons.length > 0 && invest.usufruct.persons[0] ? invest.usufruct.persons[0].firstname : '',
            lastname: invest.usufruct.persons.length > 0 && invest.usufruct.persons[0] ? invest.usufruct.persons[0].lastname : '',
            fullName: Prospect.getFullName(invest.usufruct)
            ,
            propertyNature: 'usu'
          } : invest.prospect && movement.propertyType === 'pp' ? {
            idInvestor: invest.prospect.id,
            code: invest.prospect.prospectCode,
            firstname: invest.prospect.persons[0].firstname,
            lastname: invest.prospect.persons[0].lastname,
            fullName: Prospect.getFullName(invest.prospect),
            propertyNature: 'usu'
          } : {
            idInvestor: "",
            code: "",
            firstname: "",
            lastname: "",
            fullName: '',
            propertyNature: 'usu',
          },
          partner: invest.usufructPartner ? {
            idPartner: invest.usufructPartner.id,
            code: invest.usufructPartner.partnerCode,
            socialReason: invest.usufructPartner.socialReason,
            propertyNature: 'usu'
          } : invest.partner && movement.propertyType === 'pp' ? {
            idPartner: invest.partner.id,
            code: invest.partner.partnerCode,
            socialReason: invest.partner.socialReason,
            propertyNature: 'usu'
          } : {idPartner: "", code: "", socialReason: "", propertyNature: 'usu'}
        },
        {
          investor: invest.prospect && !invest.usufruct ? {
            idInvestor: invest.prospect.id,
            code: invest.prospect.prospectCode,
            firstname: invest.prospect.persons[0].firstname,
            lastname: invest.prospect.persons[0].lastname,
            fullName: Prospect.getFullName(invest.prospect),
            propertyNature: 'pp'
          } : {
            idInvestor: "",
            code: "",
            firstname: "",
            lastname: "",
            fullName: '',
            propertyNature: 'pp',
          },
          partner: invest.partner && !invest.usufructPartner? {
            idPartner: invest.partner.id,
            code: invest.partner.partnerCode,
            socialReason: invest.partner.socialReason,
            propertyNature: 'pp'
          } : {idPartner: "", code: "", socialReason: "", propertyNature: 'pp'}
        },
        )
      })

    if(investors) prepend(investors)

    setValue("distributionTransferorShare", investors || [])

    if (transferor) dispatch(setInvestorTransferor(transferor))

    if (movement.id) {
      new MutationGateway().getActions(movement.id).then(response => setActionArray(response))
    }
    return () => {
      dispatch(setPartner({partner: null, key: "reset"}))
    }
  }, [movement.id])

  useEffect(() => {
    if (movement.investors === null) {
      movement.investors = []
    }

    if (investors.transferor) {
      movement.investors[0] = investors.transferor
    }

    if (investors.investor) {
      movement.investors[1] = investors.investor
    }

  }, [investors])

  useEffect(() => {
    if (investors.transferor && investors.transferor.prospect) {
      const investorTyped = investors.transferor.prospect as unknown as any
      const investorCode: string = investorTyped.prospectCode || investorTyped.customerCode
      if (investorTyped.persons && investorTyped.persons.length > 0) {
        setInvestorLink(investorTyped.id)
        setValue('investor.first.id', investorTyped?.id ?? "")
        setValue('investor.first.code', investorCode ?? "")
        setValue('investor.first.identity', prospectUtils.formatProspectPersonIdentity(investors.transferor.prospect))
        setValue('investor.first.platformNetworkNumber', investorTyped.partner?.platform ?? "")
        setValue('investor.first.platformNetworkLastName', investorTyped.partner?.platform ?? "")
        setValue('investor.first.deceasedAt', investorTyped?.notary?.deceasedAt ?? "")
      }
    }
  }, [investors.transferor])

  const { register, control, handleSubmit, reset, watch, setValue, getValues, clearErrors, setError, formState: {errors} } = useForm<IFormInput>({
    defaultValues: {
      distributionTransferorShare: [],
      generalInformation: {
        product: movement && movement.product ? {
          id: movement.product.id,
          label: movement.product.label,
          value: movement.product.id
        } : {id: null, label: null, value: null},
        movementNumber: (movement?.transactionCode !== null) ? movement?.transactionCode : '',
        propertyType: movement?.propertyType,
        administrator: movement.user ? `${movement.user.lastname} ${movement.user.firstname}` : '',
        possessionMode: movement?.possessionMode,
        subscribedAt: movement?.subscribedAt,
        horodatage: movement?.horodatage,
        updatedAt: movement?.updatedAt,
        lastUpdateUser: movement?.lastUpdateUser ?  `${movement?.lastUpdateUser.lastname} ${movement?.lastUpdateUser.firstname}` : '',
      },
      investor: {
        first:{
          id: null,
          code: null,
          identity: null,
          partnerNumber: "",
          partnerSocialReason: "",
          platformNetworkNumber: null,
          platformNetworkLastName: null
        }
      },
      withdrawalRequest: {
        selectAllUnit: movement?.allShares,
        executedAt: movement?.transactionExecutionAt,
        shareCount: movement?.shareCount,
        sharePrice: movement?.sharePrice,
        dateVL: movement?.valueDate,
        flatFee: movement?.flatFee,
        amount: movement?.amount,
        typeOfMovement: movement?.subType
      },
    }
  })

  const watchProduct = watch('generalInformation.product')
  const watchPropertyType = watch('generalInformation.propertyType')
  const watchshareCount = watch('withdrawalRequest.shareCount')
  const watchChoicePartSelectAll = watch('withdrawalRequest.selectAllUnit')
  const watchChoicePart = watch('withdrawalRequest.choiceUnitNumbers')
  const watchDistributionTransferorShare = watch('distributionTransferorShare')
  const watchInvestorId = watch('investor.first.id')

  const { fields, append, remove, update, prepend } = useFieldArray({
    control,
    name: "distributionTransferorShare",
  });
  const { isDirty } = useFormState({
    control
  });

  useEffect(() => {
    if(movement.propertyType !== watchPropertyType) {
      setValue('distributionTransferorShare', [])
    }
    if(watchPropertyType === 'np') {
      setStateBtnDistributionTransferorShare({
          np: {
            investor: true,
            partner: true,
            hide: false,
          },
          usu: {
            investor: false,
            partner: false,
            hide: true
          }
        })
    } else if (watchPropertyType === 'usufruct') {
      setStateBtnDistributionTransferorShare(
        {
          np: {
            investor: false,
            partner: false,
            hide: true,
          },
          usu: {
            investor: true,
            partner: true,
            hide: false
          }
        }
      )
    } else if (watchPropertyType === 'full_property') {
      setStateBtnDistributionTransferorShare(
        {
          np: {
            investor: true,
            partner: true,
            hide: false,
          },
          usu: {
            investor: true,
            partner: true,
            hide: false
          }
        }
      )
    } else {
      setStateBtnDistributionTransferorShare(
        {
          np: {
            investor: false,
            partner: false,
            hide: true,
          },
          usu: {
            investor: false,
            partner: false,
            hide: true
          }
        }
      )
    }

  }, [watchPropertyType])

  useEffect(() => {
    if(investors.investorsReceivingShares){
      let investorIsCompleted = false
      let investorAlreadyExist = undefined

      if(watchPropertyType === 'full_property') {
        if(watchDistributionTransferorShare.length &&
          (watchDistributionTransferorShare[watchDistributionTransferorShare.length - 1].np.investor.idInvestor === '' ||
            watchDistributionTransferorShare[watchDistributionTransferorShare.length - 1].usu.investor.idInvestor === '')) {
          investorIsCompleted = false
        } else {
          investorIsCompleted = true
        }
      }
      if(watchPropertyType === 'np') {
        if(watchDistributionTransferorShare.length &&
          (watchDistributionTransferorShare[watchDistributionTransferorShare.length - 1].np.investor.idInvestor === '' )) {
          investorIsCompleted = false
        } else {
          investorAlreadyExist = watchDistributionTransferorShare
            .find((inv) => inv.np.investor.idInvestor === investors?.investorsReceivingShares?.idInvestor)
          investorIsCompleted = true
        }
      }

      if(watchPropertyType === 'usufruct') {
        if(watchDistributionTransferorShare.length &&
          (watchDistributionTransferorShare[watchDistributionTransferorShare.length - 1].usu.investor.idInvestor === '' )) {
          investorIsCompleted = false
        } else {
          investorAlreadyExist = watchDistributionTransferorShare
            .find((inv) => inv.usu.investor.idInvestor === investors?.investorsReceivingShares?.idInvestor)
          investorIsCompleted = true
        }
      }

      if(investorAlreadyExist) {
        return toastError(t('movement.mutation.notify.already-added'))
      }

      if(!investorIsCompleted) {

        const copyInvestorReceivingShares = watchDistributionTransferorShare[watchDistributionTransferorShare.length -1]

        if(investors.investorsReceivingShares.propertyNature === "np"){
          copyInvestorReceivingShares.np.investor = investors.investorsReceivingShares
          update(watchDistributionTransferorShare.length - 1, copyInvestorReceivingShares)
          setStateBtnDistributionTransferorShare({...stateBtnDistributionTransferorShare, np: {...stateBtnDistributionTransferorShare.np, investor: false, partner: true}})
        }
        if(investors.investorsReceivingShares.propertyNature === "usu") {
          copyInvestorReceivingShares.usu.investor = investors.investorsReceivingShares
          update(watchDistributionTransferorShare.length - 1, copyInvestorReceivingShares)
          setStateBtnDistributionTransferorShare({...stateBtnDistributionTransferorShare, usu: {...stateBtnDistributionTransferorShare.usu, investor: false, partner: true}})
        }
      } else {
        const {propertyNature} = investors.investorsReceivingShares
        let shareCountSubtract = watchshareCount || 0
        if(watchDistributionTransferorShare.length && watchshareCount) {
          watchDistributionTransferorShare.forEach((share) => {
            shareCountSubtract = shareCountSubtract - share.nbShares
          })
        }
        const newDistributionTransferorShare = new DistributionTransferorShare(
          uuidV4(), // doit etre l'id de la part
          shareCountSubtract,
          {
            investor: propertyNature === "np" ? investors.investorsReceivingShares : {idInvestor: '', code: '', firstname: '', lastname: '', fullName: '', propertyNature: 'np'},
            partner: {idPartner: '', code: '', socialReason: '',  propertyNature: 'np'}
          },
          {
            investor: propertyNature === "usu" ? investors.investorsReceivingShares : {idInvestor: '', code: '', firstname: '', lastname: '', fullName: '', propertyNature: 'usu'},
            partner: {idPartner: '', code: '', socialReason: '',  propertyNature: 'usu'}
          },
          {
            investor: propertyNature === "pp" ? investors.investorsReceivingShares : {idInvestor: '', code: '', firstname: '', lastname: '', fullName: '',propertyNature: 'pp'},
            partner: {idPartner: '', code: '', socialReason: '',  propertyNature: 'pp'}
          },
        )
        append(newDistributionTransferorShare)

        setStateBtnDistributionTransferorShare({
          np: {
            ...stateBtnDistributionTransferorShare.np,
            investor: propertyNature !== "np",
            partner: propertyNature === "np"
          },
          usu: {
            ...stateBtnDistributionTransferorShare.usu,
            investor: propertyNature !== "usu",
            partner: propertyNature === "usu"
          },
        })
        toastSuccess(t('movement.notify.choice-investor-success'))
      }
    }
  },[investors.investorsReceivingShares])

  useEffect(() => {
    if(partners.partnerReceivingShares) {
      let partnerIsCompleted = false

      if(watchPropertyType === 'full_property') {
        if(watchDistributionTransferorShare.length &&
          (watchDistributionTransferorShare[watchDistributionTransferorShare.length - 1].np.partner.idPartner === '' ||
            watchDistributionTransferorShare[watchDistributionTransferorShare.length - 1].usu.partner.idPartner === '')) {
          partnerIsCompleted = false
        } else {
          partnerIsCompleted = true
        }
      }
      if(watchPropertyType === 'np') {
        if(watchDistributionTransferorShare.length &&
          (watchDistributionTransferorShare[watchDistributionTransferorShare.length - 1].np.partner.idPartner === '' )) {
          partnerIsCompleted = false
        } else {
          partnerIsCompleted = true
        }
      }

      if(watchPropertyType === 'usufruct') {
        if(watchDistributionTransferorShare.length &&
          (watchDistributionTransferorShare[watchDistributionTransferorShare.length - 1].usu.partner.idPartner === '' )) {
          partnerIsCompleted = false
        } else {
          partnerIsCompleted = true
        }
      }

      if(!partnerIsCompleted) {
        const copyPartnerReceivingShares = watchDistributionTransferorShare[watchDistributionTransferorShare.length -1]
        if(partners.partnerReceivingShares.propertyNature === "np"){
          copyPartnerReceivingShares.np.partner = partners.partnerReceivingShares
          update(watchDistributionTransferorShare.length - 1, copyPartnerReceivingShares)
          setStateBtnDistributionTransferorShare({...stateBtnDistributionTransferorShare, np: {...stateBtnDistributionTransferorShare.np, investor: false, partner: false}})
        } else if(partners.partnerReceivingShares.propertyNature === "usu") {
          copyPartnerReceivingShares.usu.partner = partners.partnerReceivingShares
          update(watchDistributionTransferorShare.length - 1, copyPartnerReceivingShares)
          setStateBtnDistributionTransferorShare({...stateBtnDistributionTransferorShare, usu: {...stateBtnDistributionTransferorShare.usu, investor: false, partner: false}})
        }
      }
    }

  },[partners.partnerReceivingShares])

  useEffect(() => {
    checkPairIsValid()
  }, [watchDistributionTransferorShare])

  const checkPairIsValid = () => {
    if(watchDistributionTransferorShare.length) {
      if(watchPropertyType === "full_property" && (!np.investor && !np.partner && !usu.investor && !usu.partner)) {
        setStateBtnDistributionTransferorShare({
          np: {...stateBtnDistributionTransferorShare.np, investor: true, partner: false },
          usu: {...stateBtnDistributionTransferorShare.usu, investor: true, partner: false }
        })
      } else if(watchPropertyType === "np" && (!np.investor && !np.partner)) {
        setStateBtnDistributionTransferorShare({
          ...stateBtnDistributionTransferorShare,
          np: {...stateBtnDistributionTransferorShare.np, investor: true, partner: false }
        })
      } else if(watchPropertyType === "usufruct" && (!usu.investor && !usu.partner)) {
        setStateBtnDistributionTransferorShare({
          ...stateBtnDistributionTransferorShare,
          usu: {...stateBtnDistributionTransferorShare.usu, investor: true, partner: false }
        })
      }
    } else {
      setStateBtnDistributionTransferorShare({
        np: {...stateBtnDistributionTransferorShare.np, investor: true, partner: false},
        usu: {...stateBtnDistributionTransferorShare.usu, investor: true, partner: false}
      })
    }
  }

  const checkAfterDelete = () => {
    if(watchDistributionTransferorShare.length) {
      const stateBtnDistribution = {...stateBtnDistributionTransferorShare}
      watchDistributionTransferorShare.forEach((share) => {
        if(share.np.investor.idInvestor) {
          stateBtnDistribution.np.investor = false
        }
        if(share.usu.investor.idInvestor) {
          stateBtnDistribution.usu.investor = false
        }
        if(share.np.partner.idPartner) {
          stateBtnDistribution.np.partner = false
        }
        if(share.usu.partner.idPartner) {
          stateBtnDistribution.np.partner = false
        }
      })
      setStateBtnDistributionTransferorShare(stateBtnDistribution)
    }
  }

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }

  const onConfirm = (data: any) => {
    if(watchPropertyType === 'full_property' && (!np.investor || !usu.investor)) {
      return toastError(t('movement.mutation.notify.pair-investor-partner-not-respected'));
    }
    if(watchPropertyType === 'np' && !np.investor) {
      return toastError(t('movement.mutation.notify.pair-investor-partner-not-respected'));
    }
    if(watchPropertyType === 'usu' && !usu.investor) {
      return toastError(t('movement.mutation.notify.pair-investor-partner-not-respected'));
    }
    if (!watchshareCount) {
      return toastError(t("movement.mutation.notify.share-count-required"));
    }
    if(watchshareCount && watchDistributionTransferorShare.length) {
      let totalSharesDistributed = 0
      watchDistributionTransferorShare.forEach((share) => {
        totalSharesDistributed += typeof share.nbShares === 'string' ? parseInt(share.nbShares, 10) : share.nbShares
      })

      const shareCount = typeof watchshareCount === 'string' ? parseInt(watchshareCount, 10) : watchshareCount
      if(totalSharesDistributed !== shareCount) {
        return toastError(t("movement.mutation.notify.share-count-not-equal"))
      }
    }

    reset(data)
    movement.propertyType = data.generalInformation.propertyType
    movement.possessionMode = data.generalInformation.possessionMode
    movement.subscribedAt = data.generalInformation.subscribedAt
    movement.product = product
    movement.horodatage = data.generalInformation.horodatage
    movement.comment = data.comment
    movement.note = data.note

    movement.allShares = data.withdrawalRequest.selectAllUnit
    movement.executedAt = data.withdrawalRequest.executedAt
    movement.shareCount = data.withdrawalRequest.shareCount
    movement.sharePrice = data.withdrawalRequest.sharePrice
    movement.valueDate = data.withdrawalRequest.dateVL
    movement.flatFee = data.withdrawalRequest.flatFee
    movement.amount = data.withdrawalRequest.amount

    movement.subType = data.withdrawalRequest.typeOfMovement
    movement.transactionExecutionAt = data.withdrawalRequest.executedAt
    movement.slices = []
    if (shareSlices) {
      shareSlices.map(item => {
        movement.slices.push(item)
      })
    }

    const transferor = movement.investors?.find((investor) => {
      return investor?.type === "transferor" || null
    })

    const movementInvestors = []
    if (investors && investors.transferor) {
      let partner = undefined
      if (partners && partners.transferor) {
        partner = partners.transferor
      } else if (investors.transferor?.partner) {
        partner = investors.transferor.partner
      } else if (!investors.transferor.partner && transferor) {
        partner = transferor.partner
      }
      const investor = new Investor(
        investors.transferor.id,
        'transferor',
        investors.transferor.prospect,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        [],
        data.investor.first.partnerType,
        null,
        partner
      )

      movementInvestors.push(investor)
    } else if (transferor) {
      const copyTransferor = {...transferor}
      copyTransferor.partner = partners?.transferor ?? undefined
      movementInvestors.push(copyTransferor)
    }

    movement.investors = movementInvestors

    const investorsFormatted = data.distributionTransferorShare.map((inv: distributionTransferorShareInterface) => {
      return {
        type: "investor",
        prospect: inv.np.investor.idInvestor,
        partner: inv.np.partner.idPartner,
        usufruct: inv.usu.investor.idInvestor,
        usufructPartner: inv.usu.partner.idPartner,
        shareCount: inv.nbShares
      }
    })

    if (null !== movement.id) {
      new MutationGateway().update(movement, investorsFormatted).then(response => {
        if (null !== response) {
          movement = response
          setUuidRefresh(uuidV4())
          toastSuccess(t('movement.mutation.notify.update-success'))
          dispatch(reloadMovement());
        } else {
          toastError(t('movement.mutation.notify.update-error'));
        }
      })
    } else {
      setIsLoading(true)
      new MutationGateway().create(movement, investorsFormatted).then((response) => {
        if (null !== response) {
          toastSuccess(t('movement.mutation.notify.add-success'))
          navigate(`/${t('url.movements.edit-mutations')}/${response.id}`)

        } else {
          toastError(t('movement.mutation.notify.add-error'));
        }
        setIsLoading(false)
      })
    }
  }

  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));

  useEffect(() => {
    if(referential) {
      let platformTypeId = ""
      let networkTypeId = ""
      let platformType
      let networkType


      const transferor = movement.investors?.find((investor) => {
        return investor?.type === "transferor" || null
      })

      if (transferor) {
        platformTypeId = (partners.transferor?.platformType ?? transferor.partner?.platformType) || ""
        networkTypeId = (partners.transferor?.networkType ?? transferor.partner?.networkType) || ""

        platformType = referential.partner?.platform.find((el) => el.value === platformTypeId)
        networkType = referential?.partner?.network.find((el) => el.value === networkTypeId)

        setPartnerLink(partners.transferor?.id || transferor.partner?.id || null)
        setValue('investor.first.partnerNumber', partners.transferor?.partnerCode || transferor.partner?.partnerCode || "")
        setValue('investor.first.partnerSocialReason', partners.transferor?.socialReason || transferor.partner?.socialReason || "")
        setValue('investor.first.platformNetworkNumber', platformType?.label || "")
        setValue('investor.first.platformNetworkLastName', networkType?.label || "")
      } else {
        platformTypeId = partners.transferor?.platformType || ''
        networkTypeId = partners.transferor?.networkType || ''

        platformType = referential?.partner?.platform.find((el) => el.value === platformTypeId)
        networkType = referential?.partner?.network.find((el) => el.value === networkTypeId)

        setPartnerLink(partners.transferor?.id || null)
        setValue('investor.first.partnerNumber', partners.transferor?.partnerCode || "")
        setValue('investor.first.partnerSocialReason', partners.transferor?.socialReason || "")
        setValue('investor.first.platformNetworkNumber', platformType?.label || "")
        setValue('investor.first.platformNetworkLastName', networkType?.label || "")
      }
    }

  },[partners, referential, investors])

  useEffect(() => {
    if (watchProduct && watchProduct.id) {
      new ProductGateway().get(watchProduct.id).then(response => {
        if(response?.valorisation?.withdraw.value) setValue("withdrawalRequest.sharePrice", response?.valorisation?.withdraw.value)
        setProduct(response)
        if (response?.decimalStep) {
          setDecimalStep(response.decimalStep)
        }
      })
      if(shareSlices.length && watchInvestorId && (shareSlices[0].investorId !== watchInvestorId)) {
        dispatch(resetSliceFromTransaction())
        setValue('withdrawalRequest.shareCount', null)
        setValue('withdrawalRequest.choiceUnitNumbers', false)
        setValue('withdrawalRequest.selectAllUnit', false)
        remove()
      }
      if(shareSlices.length && watchProduct?.id && (shareSlices[0].product.id !== watchProduct.id)) {
        dispatch(resetSliceFromTransaction())
        setValue('withdrawalRequest.shareCount', null)
        setValue('withdrawalRequest.choiceUnitNumbers', false)
        setValue('withdrawalRequest.selectAllUnit', false)
        remove()
      }
    }
  }, [watchProduct.id, watchInvestorId])

  useEffect(() => {
    if (watchChoicePart) {
      setValue('withdrawalRequest.selectAllUnit', false)
    }
    if(watchshareCount && !watchChoicePartSelectAll) {
      setValue('withdrawalRequest.choiceUnitNumbers', true)
    }
  }, [watchChoicePart])

  useEffect(() => {
    if (watchChoicePartSelectAll) {
      setValue('withdrawalRequest.choiceUnitNumbers', false)
      if(watchInvestorId && watchProduct) {
        const filter = {
          keywords: "",
          productId: watchProduct.id || "",
          propertyType: watchPropertyType === "full_property" ? watchPropertyType : watchPropertyType === "np" || watchPropertyType === "usufruct" ? "dismemberment" : ""
        }
        new SliceGateway().getList(watchInvestorId,1, 50, filter).then(response => {
          if(response?.data) {
            let nbShareCount = 0
            const parsedSlices = response.data.map((slice) => {
              nbShareCount += slice.count
              return {
                ...slice,
                countShareSelected: slice.count,
                investorId: watchInvestorId,
                product: {
                  ...slice.product,
                  id: watchProduct.id || ""
                }
              }
            })
            dispatch(addMultipleSliceToTransaction(parsedSlices))
            setValue('withdrawalRequest.shareCount', nbShareCount)
          }
        });
      }
    }
  }, [watchChoicePartSelectAll])

  useEffect(() => {
    let sharePrice = getValues('withdrawalRequest.sharePrice')
    if(typeof sharePrice === 'string') {
      sharePrice = parseInt(sharePrice, 10)
    }
    if (watchshareCount && sharePrice) {
      if(sharePrice) setValue("withdrawalRequest.amount", watchshareCount * sharePrice)
    }

  }, [watchshareCount])

  function handleClickChoicePartView() {
    if ((watchshareCount && watchshareCount > 0) || watchChoicePartSelectAll) {
      dispatch(setOpenChoiceSliceToGiveUpEvent({
        show: true,
        prospectId: watchInvestorId,
        productId: getValues('generalInformation.product.id'),
        shareCount: watchshareCount,
        choicePartSelectAll: watchChoicePartSelectAll,
        propertyType: watchPropertyType === "full_property" ? watchPropertyType : watchPropertyType === "np" || watchPropertyType === "usufruct" ? "dismemberment" : ""
      }))
    } else {
      toastError(t('movement.notify.choice-share-count-before'));
    }
  }

  function handleRemoveShareRow(i:number) {
    remove(i)
    checkAfterDelete()
  }

  function handleInputFlatFee(value: string) {
    const flatFee = value ? parseFloat(value) : 0
    setValue('withdrawalRequest.flatFee', flatFee)
  }

  const blockGeneralInformation = <div className={`form-bloc form--bloc--general-information`}>
    <div className="form-bloc__title">{t('movement.mutation.form.general-information.title')}</div>
    <div className="form-bloc__form flex-container">
      <ProductDalalist classes="col-md-6"
                       id="generalInformation.product"
                       name="generalInformation.product"
                       label={t('movement.mutation.form.general-information.product-acronym')}
                       labelClasses="mendatory-fild"
                       control={control}
                       defaultValue={getValues('generalInformation.product')}
                       disabled={isLectureMode}
      />
      <Input register={register}
             classes={'col-md-6'}
             label={t('movement.mutation.form.general-information.movement-number')}
             type={'text'}
             name={'generalInformation.movementNumber'}
             id={'generalInformation.movementNumber'}
             readOnly={true}
      />
      <SelectCustom register={register}
                    classes="col-md-6"
                    id="generalInformation.propertyType"
                    name="generalInformation.propertyType"
                    label={t('movement.mutation.form.general-information.nature-property')}
                    options={List.filterNaturePropertyList(movement.status, product?.productType ?? null, referential?.wallet_mutation.property_type ?? [])}
      />
      <Input register={register}
             classes={'col-md-6'}
             label={t('movement.gre-gre.general-information.external-movement-number')}
             type={'text'}
             name={'generalInformation.externalMovementNumber'}
             id={'generalInformation.externalMovementNumber'}
             readOnly={true}
      />
      <SelectCustom register={register}
                    classes="col-md-6"
                    id="generalInformation.possessionMode"
                    name="generalInformation.possessionMode"
                    label={t('movement.mutation.form.general-information.detention-mode')}
                    options={List.filterDetentionModeList(product?.productType ?? null, referential?.wallet_mutation.possession_mode ?? [])}
      />
      <Input register={register}
             classes={'col-md-6'}
             label={t('movement.mutation.form.general-information.administrator')}
             type={'text'}
             name={'generalInformation.administrator'}
             id={'generalInformation.administrator'}
             readOnly={true}
      />
      <InputDate id="generalInformation.horodatage"
                 label={t('movement.pre-subscription.form.general-information.timestamp-date')}
                 name="generalInformation.horodatage"
                 classes="col-md-6"
                 control={control}
                 register={register}
                 readOnly={isLectureMode}
                 isLectureMode={isLectureMode}
      />
      { movement.id !== null ? (
        <div className="u-txt-size-s u-txt-lightbold u-txt-italic col-md-12">
          {t('movement.pre-subscription.form.general-information.lastUpdateUser')} {getValues('generalInformation.lastUpdateUser')}
          {t('movement.pre-subscription.form.general-information.updatedAt')} {getValues('generalInformation.updatedAt')}
        </div>
      ) : ''}

    </div>
  </div>
  const blockInvestor = <div className={`form-bloc form--bloc--investor`}>
    <ReactTooltip />
    <div className="form-bloc__title">{t('movement.gre-gre.transferor.title')}</div>
    <div className="flex-container">
      <div className="col-md-9 form-bloc__form">
        <div className="flex-container">
          <div className="col-md-6">
            <button type="button"
                    className="button button-reset u-txt-with-icon"
                    onClick={() => !isLectureMode &&
                      (!actionArray?.confirm.enabled && !actionArray?.validate.enabled) &&
                      dispatch(setOpenChoiceInvestorEvent({
                        show: true,
                        key: 'transferor',
                        dataListType: "customer"
                      }))}
                    disabled={!isLectureMode && (actionArray?.confirm.enabled || actionArray?.validate.enabled)}
            >
              <img src={iconAdd} alt="" />
              {t('movement.mutation.form.transferor.title')}
            </button>
          </div>
          <div className="col-md-6">
            <button type="button"
                    className="button button-reset u-txt-with-icon"
                    onClick={() => !isLectureMode &&
                      (!actionArray?.confirm.enabled && !actionArray?.validate.enabled) &&
                      dispatch(setOpenChoicePartnerEvent({
                        show: true,
                        key: 'transferor'
                      }))}
                    disabled={!isLectureMode && (actionArray?.confirm.enabled || actionArray?.validate.enabled)}
            >
              <img src={iconAdd} alt="" />
              {t('account.search-a-partner')}
            </button>
          </div>
        </div>
        <table className="table--investor">
          <thead>
          <tr>
            <th/>
            <th className="form-control__label">{t('movement.mutation.form.investor.th-number')}</th>
            <th className="form-control__label">{t('movement.pre-subscription.form.investor.th-identity')}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <label htmlFor="partnerNum" className="form-control__label u-mrs">
                {t('movement.mutation.form.transferor.investor-owner-transferor')}
              </label>
              { investorLink &&
                <Link to={ `/${ t('url.customer.read-general-information') }/${investorLink}` }
                      target="_blank"
                      rel="noopener noreferrer"
                >
                  <button type="button" className="button-reset">
                      <img src={iconSearch} alt="loupe"/>
                  </button>
                </Link>
              }
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.code'}
                     id={'investor.first.code'}
                     disabled
              />
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.identity'}
                     id={'investor.first.identity'}
                     readOnly={true}
                     data-tip={investors?.transferor?.prospect ? prospectUtils.formatProspectPersonIdentity(investors.transferor.prospect) : ''}
              />
            </td>
            <td>
              <InputDate register={register}
                         control={control}
                         classes="col-md-8 u-mxs u-mb0"
                         type="text"
                         name="investor.first.deceasedAt"
                         id="investor.first.deceasedAt"
                         label={t('movement.mutation.form.investor.investor-deceasedAt')}
                         disabled={true}
                         readOnly={isLectureMode}
                         isLectureMode={isLectureMode}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="partnerNum" className="form-control__label u-mrs">
                {t('movement.mutation.form.investor.investor-partner')}
              </label>
              { partnerLink &&
                <Link to={ `/${ t('url.partner.read-general-information') }/${partnerLink}` }
                      target="_blank"
                      rel="noopener noreferrer"
                >
                  <button type="button" className="button-reset">
                      <img src={iconSearch} alt="loupe"/>
                  </button>
                </Link>
              }
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.partnerNumber'}
                     id={'investor.first.partnerNumber'}
                     disabled
              />
            </td>
            <td>
              <Input register={register}
                     type={'text'}
                     name={'investor.first.partnerSocialReason'}
                     id={'investor.first.partnerSocialReason'}
                     disabled
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  const blockWithdrawalRequest = <div className={`form-bloc form--bloc--withdrawal-request`}>
    <div className="form-bloc__title">{t('movement.mutation.form.entering-request-mutual-agreement.title')}</div>
    <div className="flex-container">
      <SelectCustom register={register}  classes="col-md-6" id="withdrawalRequest.typeOfMovement"  name="withdrawalRequest.typeOfMovement" label={t('movement.mutation.form.request.type-of-movement')} options={referential?.wallet_mutation?.transaction_subtype || null} readOnly={referential?.wallet_mutation?.transaction_subtype === undefined}/>
      <InputDate control={control}
                 register={register}
                 classes="col-md-6"
                 type="text"
                 name="withdrawalRequest.executedAt"
                 id="withdrawalRequest.executedAt"
                 label={t('movement.mutation.form.request.execution-date')}
                 readOnly={isLectureMode}
                 isLectureMode={isLectureMode}
      />
      <Input type="number"
             register={register}
             classes={`col-md-6 mandatory-field`}
             inputClasses={`${watchshareCount ? '' : 'field-in-error'}`}
             id="withdrawalRequest.shareCount"
             name="withdrawalRequest.shareCount"
             label={t('movement.mutation.form.request.number-of-shares-for-sale')}
             min="0"
             disabled={watchChoicePartSelectAll}
      />
      <div className="col-md-3 choices-shares">
        <Checkbox register={register}
                  id="withdrawalRequest.selectAllUnit"
                  name="withdrawalRequest.selectAllUnit"
                  label={t('movement.mutation.form.request.shares-select-all')}
        />
        {watchChoicePartSelectAll && <div className="u-pointer" onClick={() => handleClickChoicePartView()}>
            <img src={eyeIcon} alt=""/>
        </div>}
      </div>
      <div className="col-md-3 choices-shares">
        <Checkbox register={register}
                  id="withdrawalRequest.choiceUnitNumbers"
                  name="withdrawalRequest.choiceUnitNumbers"
                  label={t('movement.mutation.form.request.choice-of-unit-numbers')}
        />
        {watchChoicePart &&
            <div className="u-pointer" onClick={() => handleClickChoicePartView()}>
                <img src={eyeIcon} alt=""/>
            </div>
        }
      </div>
      <Input register={register}
             classes="col-md-6"
             type="text"
             name="withdrawalRequest.sharePrice"
             id="withdrawalRequest.sharePrice"
             label={t('movement.mutation.form.request.unit-price-vl')}
             readOnly
      />
      <InputDate register={register}
                 control={control}
                 classes="col-md-6"
                 type="text"
                 name="withdrawalRequest.dateVL"
                 id="withdrawalRequest.dateVL"
                 label={t('movement.mutation.form.request.vl-date-to-be-taken-into-account')}
                 readOnly={isLectureMode}
                 isLectureMode={isLectureMode}

      />
      <Input register={register}
             classes="col-md-6"
             type="number"
             name="withdrawalRequest.amount"
             id="withdrawalRequest.amount"
             label={t('movement.gre-gre.entering-request-mutual-agreement.total-cost-to-pay')}
             readOnly
      />
      <div className="col-md-6"/>
      <InputNumber
        id={'withdrawalRequest.flatFee'}
        classes="col-md-6"
        name={'withdrawalRequest.flatFee'}
        control={control}
        decimalScale={decimalStep}
        onChangeCb={handleInputFlatFee}
        label={t('movement.mutation.form.request.fixed-fee')}
        register={register} />
    </div>
  </div>
  const blockSliceList = <div className="form-bloc form--bloc--list-slices">
    <ListToSell isLectureMode={isLectureMode}
                SliceArray={shareSlices}
                title={t('movement.heading-slice.state-recovery-heritage-post-transaction')}
    />
  </div>
  const blockSliceDetailsList = <div className="form-bloc form--bloc--list-slices">
    <ListToSell isLectureMode={isLectureMode}
                SliceArray={shareSlicesFreeze}
                title={t('movement.heading-slice.state-recovery-heritage-actual-transaction')}
                deleteDisabled={true}
    />
  </div>
  const blockChoiceInvestorForShareParts = <div className={`form-bloc form--bloc--investor`}>
    <div className="form-bloc__title">{t('movement.mutation.form.choice-investor-share-parts.title')}</div>
    <div className="flex-container">
      <div className="col-md-12 form-bloc__form">
        <section className="table--choice-investor-receiving-shares">
          <div className="thead">
            <div className="tr">
              <th />
              {!stateBtnDistributionTransferorShare.np.hide && (
                <>
                  <th className="form-control__label">
                  <button type="button"
                          className="button button-reset u-txt-with-icon"
                          onClick={() => !isLectureMode &&
                            (!actionArray?.confirm.enabled && !actionArray?.validate.enabled) &&
                            dispatch(setOpenChoiceInvestorEvent({
                              show: true,
                              key: 'investorReceivingShares',
                              dataListType: "all",
                              propertyNature: 'np'
                              }
                            ))}
                          disabled={!isLectureMode &&
                            (actionArray?.confirm.enabled || actionArray?.validate.enabled) ||
                            !stateBtnDistributionTransferorShare.np.investor
                  }
                  >
                    <img src={iconAdd} alt="" />
                    {t('movement.mutation.form.choice-investor-share-parts.add-investor-to-np')}
                  </button>
                  </th>
                  <th className="form-control__label">
                    <button type="button"
                            className="button button-reset u-txt-with-icon"
                            onClick={() => !isLectureMode &&
                              (!actionArray?.confirm.enabled && !actionArray?.validate.enabled) &&
                              dispatch(setOpenChoicePartnerEvent({
                                show: true,
                                key: 'investorReceivingShares',
                                propertyNature: 'np'
                              }
                              ))}
                            disabled={!isLectureMode &&
                              (actionArray?.confirm.enabled || actionArray?.validate.enabled) ||
                              !stateBtnDistributionTransferorShare.np.partner
                    }
                    >
                      <img src={iconAdd} alt="" />
                      {t('movement.mutation.form.choice-investor-share-parts.add-partner-to-np')}
                    </button>
                  </th>
                </>
                )
              }
              {!stateBtnDistributionTransferorShare.usu.hide && (
                <>
                  <th className="form-control__label">
                    <button type="button"
                            className="button button-reset u-txt-with-icon"
                            onClick={() => !isLectureMode &&
                              (!actionArray?.confirm.enabled && !actionArray?.validate.enabled) &&
                              dispatch(setOpenChoiceInvestorEvent({
                                show: true,
                                key: 'investorReceivingShares',
                                dataListType: "all",
                                propertyNature: 'usu'
                              }
                              ))}
                            disabled={!isLectureMode &&
                              (actionArray?.confirm.enabled || actionArray?.validate.enabled) ||
                              !stateBtnDistributionTransferorShare.usu.investor
                    }
                    >
                      <img src={iconAdd} alt="" />
                      {t('movement.mutation.form.choice-investor-share-parts.add-investor-to-usu')}
                    </button>
                  </th>
                  <th className="form-control__label">
                    <button type="button"
                            className="button button-reset u-txt-with-icon"
                            onClick={() => !isLectureMode &&
                              (!actionArray?.confirm.enabled && !actionArray?.validate.enabled) &&
                              dispatch(setOpenChoicePartnerEvent({
                                show: true,
                                key: 'investorReceivingShares',
                                propertyNature: 'usu'}
                              ))}
                            disabled={!isLectureMode &&
                              (actionArray?.confirm.enabled || actionArray?.validate.enabled) ||
                              !stateBtnDistributionTransferorShare.usu.partner
                    }
                    >
                      <img src={iconAdd} alt="" />
                      {t('movement.mutation.form.choice-investor-share-parts.add-partner-to-usu')}
                    </button>
                  </th>
                </>
                )
              }
            </div>
            <div className="tr">
              <th className="form-control__label">{t('movement.mutation.form.choice-investor-share-parts.th-nbShare')}</th>

              {!stateBtnDistributionTransferorShare.np.hide && (
                <>
                  <th className="form-control__label">{t('movement.mutation.form.choice-investor-share-parts.th-id-investor')}</th>
                  <th className="form-control__label">{t('movement.mutation.form.choice-investor-share-parts.th-fullName')}</th>

                  <th className="form-control__label">{t('movement.mutation.form.choice-investor-share-parts.th-id-partner')}</th>
                  <th className="form-control__label">{t('movement.mutation.form.choice-investor-share-parts.th-socialRaison')}</th>
                </>
                )
              }

              {!stateBtnDistributionTransferorShare.usu.hide && (
                <>
                  <th className="form-control__label">{t('movement.mutation.form.choice-investor-share-parts.th-id-investor')}</th>
                  <th className="form-control__label">{t('movement.mutation.form.choice-investor-share-parts.th-fullName')}</th>

                  <th className="form-control__label">{t('movement.mutation.form.choice-investor-share-parts.th-id-partner')}</th>
                  <th className="form-control__label">{t('movement.mutation.form.choice-investor-share-parts.th-socialRaison')}</th>
                </>
                )
              }

              <th className="form-control__label">{t('movement.mutation.form.choice-investor-share-parts.th-action')}</th>
            </div>
          </div>
          <div className="tbody">
          {watchDistributionTransferorShare.length !== 0 && fields.map((field:distributionTransferorShareInterface) => {
            const index = watchDistributionTransferorShare.findIndex((el => el.idShare === field.idShare))
            if(index === -1) return
            return (
              <div className="tr" key={field.idShare}>
                <input
                  type={'number'}
                  {...register(`distributionTransferorShare.${index}.nbShares` as const, {
                    valueAsNumber: true
                  })}
                  defaultValue={watchDistributionTransferorShare[index].nbShares}
                  className="input--small"
                />

                {!stateBtnDistributionTransferorShare.np.hide && (
                  <>
                    <input type={'text'}
                         name={`distributionTransferorShare.${index}.np.investor.code`}
                         id={`distributionTransferorShare.${index}.np.investor.code`}
                         defaultValue={watchDistributionTransferorShare[index]?.np?.investor?.code}
                         readOnly
                         disabled
                         className="input--small"
                    />

                    <input id={`distributionTransferorShare.${index}.np.investor.fullName`}
                           name={`distributionTransferorShare.${index}.np.investor.fullName`}
                           defaultValue={
                             watchDistributionTransferorShare[index]?.np?.investor?.fullName
                           }
                           disabled
                           className="input--large"
                    />

                    <input type={'text'}
                           name={`distributionTransferorShare.${index}.np.partner.code`}
                           id={`distributionTransferorShare.${index}.np.partner.code`}
                           defaultValue={watchDistributionTransferorShare[index]?.np?.partner?.code}
                           readOnly
                           disabled
                           className="input--small"
                    />

                    <input type={'text'}
                           name={`distributionTransferorShare.${index}.np.partner.socialReason`}
                           id={`distributionTransferorShare.${index}.np.partner.socialReason`}
                           defaultValue={watchDistributionTransferorShare[index]?.np?.partner?.socialReason}
                           readOnly
                           disabled
                           className="input--large"
                    />
                  </>
                  )
                }

                {!stateBtnDistributionTransferorShare.usu.hide && (
                  <>
                    <input type={'text'}
                         name={`distributionTransferorShare.${index}.usu.investor.code`}
                         id={`distributionTransferorShare.${index}.usu.investor.code`}
                         defaultValue={watchDistributionTransferorShare[index]?.usu?.investor?.code}
                         readOnly
                         disabled
                         className="input--small"
                    />

                    <input id={`distributionTransferorShare.${index}.usu.investor.fullName`}
                           name={`distributionTransferorShare.${index}.usu.investor.fullName`}
                           defaultValue={watchDistributionTransferorShare[index]?.usu?.investor?.fullName}
                           disabled
                           className="input--large"
                    />

                    <input type={'text'}
                           name={`distributionTransferorShare.${index}.usu.partner.code`}
                           id={`distributionTransferorShare.${index}.usu.partner.code`}
                           defaultValue={watchDistributionTransferorShare[index]?.usu?.partner?.code}
                           readOnly
                           disabled
                           className="input--small"
                    />

                    <input type={'text'}
                           name={`distributionTransferorShare.${index}.usu.partner.socialReason`}
                           id={`distributionTransferorShare.${index}.usu.partner.socialReason`}
                           defaultValue={watchDistributionTransferorShare[index]?.usu?.partner?.socialReason}
                           readOnly
                           disabled
                           className="input--large"
                    />
                  </>
                  )
                }

                <button type="button"
                        className="button-reset"
                        disabled={isLectureMode}
                        onClick={() => handleRemoveShareRow(index)}
                >
                  <img src={removeIcon} alt="" className="remove-icon" />
                </button>
              </div>
            )
          })}
          </div>
        </section>

      </div>
    </div>
  </div>
  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, [isLectureMode]);

  const getActions = (actionArray: any) => {
    setActions(actionArray)
  }

  return (
    <>
      {referential &&
      <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
        <Status label={"movement.state"} status={movement.status} statusList={referential.wallet.status}/>
        <ActionReason reason={
          referential?.wallet_subscription?.cancel_reason?.find(reason => {
            return reason.value == movement.actionReason
          })?.label ?? movement.actionReason
        }/>
        {!movement.id && <AlertInfo text={t('movement.warning-action-possible')} classes="u-mys" />}
        {movement.id && <MovementTag transactionId={movement.id} tags={movement.tags || []} optionsReferential={referential.transaction.tag} readOnly={isLectureMode} />}
        <div className="flex-container mutation">
          <div className="col-md-6">{blockGeneralInformation}</div>
          <div className="col-md-6">{movement.id && <ActionsForm gateway={new MutationGateway()}
                                                                 transactionUuid={movement.id}
                                                                 uuidRefresh={uuidRefresh}
                                                                 callback={getActions}
                                                                 setFormError={setFormError}
          />}
          </div>
          <FormErrorInfoDropdown errorArray={formError}/>
          {(actions?.invalidate.date && !actions?.confirm.date) &&
          <div className="col-md-12">
            <CommentBlock
              data={movement.comment}
              onChange={(data: string) => setValue('comment', data)}
              config={CKEditorUtil.getConfig()}
              readonly={isLectureMode}
            />
          </div>}
          <div className="col-md-12">{blockInvestor}</div>
          <div className="col-md-12">{blockWithdrawalRequest}</div>
          {shareSlicesFreeze.length > 0 && <div className="col-md-12">{blockSliceDetailsList}</div>}
          <div className="col-md-12">{blockSliceList}</div>
          <div className="col-md-12">{blockChoiceInvestorForShareParts}</div>
          {movement.status === "subscribed" && movement.id &&
            <div className="col-md-12">
              <RecapForm transactionUuid={movement.id}
                         typeMvt='mutation'
              />
            </div>
          }
          <div className="col-md-12">
            <NoteBlock
              data={movement.note}
              onChange={(data: string) => {setValue('note', data)}}
              config={CKEditorUtil.getConfig()}
              readonly={isLectureMode || ['canceled', 'subscribed'].includes(movement.status ?? 'draft')}
            />
          </div>
        </div>
        {!isLectureMode && <FooterBlock disabled={!watchProduct.id || isLoading} />}
      </form>
      }
    </>
  )
}

export default Form
