interface DocumentInterface {
  id: number
  title: string
  documentType: string
  requestAt: string
  sendAt: string
  waiting: boolean
  extension: string
}

interface DocumentToViewInterface {
  content: string
}

interface DocumentToSendInterface {
  value: string
  title: string
  type: string
}

class Document implements DocumentInterface {
  public id: number
  public title: string
  public documentType: string
  public requestAt: string
  public sendAt: string
  public waiting: boolean
  public extension: string

  constructor(
    id: number,
    title: string,
    documentType: string,
    requestAt: string,
    sendAt: string,
    waiting: boolean,
    extension: string
  ) {
    this.id = id
    this.title = title
    this.documentType = documentType
    this.requestAt = requestAt
    this.sendAt = sendAt
    this.waiting = waiting
    this.extension = extension
  }
}

class DocumentToSend implements DocumentToSendInterface {
  public value: string
  public title: string
  public type: string

  constructor(
    value: string,
    title: string,
    type: string
  ) {
    this.value = value
    this.title = title
    this.type = type
  }
}

export {
  Document,
  DocumentToSend
}
export type { DocumentInterface, DocumentToViewInterface }
