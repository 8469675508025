import { FunctionComponent, useEffect } from 'react';
import {useTranslation} from 'react-i18next'

import { useAppDispatch, useAppSelector } from '../../../../store/hook';

import HeaderPage from '../../../component/HeaderPage/HeaderPage'
import TabProspect from '../../../component/Tab/Prospect/TabProspect'
import ProspectDatalist from '../../../component/Datalist/ProspectDatalist'

import '../../../../../assets/styles/page/_account.scss'
import {useNavigate} from "react-router-dom";
import { setOpenDatalistFilterProspect } from '../../../../store/component/event';
import Kpi from "../../../component/Kpi/Kpi";
import ProspectGateway from "../../../../../gateway/Prospect/ProspectGateway";

const ProspectDashboard: FunctionComponent = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const prospectRules = useAppSelector((state) => state.me.me?.rules.prospect)

  if(!prospectRules?.visible) navigate(`/`)

  useEffect(() => {
    dispatch(setOpenDatalistFilterProspect({
        show: false,
        count: 0,
        filters: {
          lastName: '',
          maidenName: '',
          keywords: '',
          birthDate: '',
          status: []
        }
      }
    ))
  }, [dispatch])

  return (
    <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
      <HeaderPage title={t('account.prospect')} addLink={prospectRules?.actions?.create ? `/${t('url.prospect.add-choice-type')}` : undefined} />
      <TabProspect />
      <div className="prospect-keys-figures">
        <div className="prospect-keys-figures__title">{t('account.key-figures')}</div>
        <Kpi getKpi={new ProspectGateway().getKpi} />
      </div>
    {prospectRules?.actions?.list && <ProspectDatalist callerType={"module"}/>}
    </main>
  )
}

export default ProspectDashboard
