import {Product} from '../Product/Product'
import {ScaleItem, ScaleItemInterface} from "../Product/ScaleItem";
import {ScaleInterface} from "../Product/Scale";
import {DateRange} from "../Utils/DateRange";

interface ConventionAnnexInterface {
  id: string
  acronym: string|null
  code: string|null
  date: string|null
  period: DateRange|null
  particularite: string|null
  product: Product|null
  scaleItems: ScaleItemInterface[]
  derogatory: ScaleInterface|null
  scCommissionEarned: string|null
  scCommissionEarnedPercent: string|null
  scCommissionNotEarned: string|null
  scCommissionNotEarnedPercent: string|null
}

class ConventionAnnex implements ConventionAnnexInterface {
  public id: string
  public acronym: string|null
  public code: string|null
  public date:  string|null
  public period: DateRange|null
  public particularite: string|null
  public product: Product|null
  public scaleItems: ScaleItemInterface[]
  public derogatory: ScaleInterface|null
  public scCommissionEarned: string|null
  public scCommissionEarnedPercent: string|null
  public scCommissionNotEarned: string|null
  public scCommissionNotEarnedPercent: string|null

  constructor(
    id: string,
    acronym: string|null,
    code: string|null,
    date: string|null,
    period: DateRange|null,
    particularite: string|null,
    product: Product|null,
    scaleItems: ScaleItemInterface[],
    derogatory: ScaleInterface|null,
    scCommissionEarned: string|null,
    scCommissionEarnedPercent: string|null,
    scCommissionNotEarned: string|null,
    scCommissionNotEarnedPercent: string|null,
  ) {
    this.id = id
    this.acronym = acronym
    this.code = code
    this.date = date
    this.period = period
    this.particularite = particularite
    this.product = product
    this.scaleItems = scaleItems
    this.derogatory = derogatory
    this.scCommissionEarned = scCommissionEarned
    this.scCommissionEarnedPercent = scCommissionEarnedPercent
    this.scCommissionNotEarned = scCommissionNotEarned
    this.scCommissionNotEarnedPercent = scCommissionNotEarnedPercent
  }
}

export {
  ConventionAnnex
}
export type { ConventionAnnexInterface }
