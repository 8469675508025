import {ListRequestInterface} from './ListRequest'
import PreSubscriptionGateway from "../../../gateway/PreSubscription/PreSubscriptionGateway";
import {MovementListInterface} from "../../../domain/Movement/MovementList";

export default class ListUseCase {
  public preSubscriptionGateway

  constructor(preSubscriptionGateway: PreSubscriptionGateway) {
    this.preSubscriptionGateway = preSubscriptionGateway
  }

  async execute(ListRequest: ListRequestInterface,
                signal?: AbortSignal,
                sort?: string,
                order?: string): Promise<{ data: MovementListInterface[] | null, numberOfItems: number } | null> {
    return await this.preSubscriptionGateway.getList(
      ListRequest.currentPage,
      ListRequest.itemPerPage,
      ListRequest.filter,
      signal,
      sort,
      order)
      .then(response => response)
  }
}
