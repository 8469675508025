import React, {FunctionComponent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {SubmitHandler, useForm, useFormState} from 'react-hook-form';

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';
import {useAppDispatch, useAppSelector} from '../../../../../store/hook';
import {setOpenAccountAddressFormEvent} from '../../../../../store/component/event';
import {CustomerInterface} from '../../../../../../domain/Customer/Customer';
import AlertInfo from '../../../Alert/AlertInfo/AlertInfo';
import iconAdd from '../../../../../../assets/images/icons/add.svg';
import dayjs from 'dayjs';
import {confirmAlert} from 'react-confirm-alert';
import ConfirmationModal from '../../../Modal/ConfirmationModal';
import {usePrompt} from '../../../../util/Navigation';
import {setReadOnlyFormElements} from '../../../../util/setReadOnlyFormElements';
import AddressBlock from "../../../Block/AddressBlock";
import {
  GENERAL_ADDRESS_STATUS_ACTIVE,
  GENERAL_ADDRESS_STATUS_INACTIVE,
  GeneralAddressInterface
} from "../../../../../../domain/Address/GeneralAddress";
import {
  addCurrentFiscalAddress,
  editCurrentFiscalAddress,
  reloadCustomer
} from "../../../../../store/component/customer";
import {toastError, toastSuccess, toastWarning} from "../../../../util/Toast";
import UpdateUseCase from "../../../../../../useCase/Customer/Update/UpdateUseCase";
import CustomerGateway from "../../../../../../gateway/Customer/CustomerGateway";
import {AddressInterface} from "../../../../../../domain/Address/Address";
import {CUSTOMER_ADDRESS_STATUS} from "../../../../../../fixtures/Customer";

interface IFormInput {
  sameFiscalAddress: boolean
  customerAddress: GeneralAddressInterface
  customerFiscalAddress: GeneralAddressInterface
}

type Props = {
  customer: CustomerInterface
  isLectureMode?: boolean
  handler?: any
}


const Address: FunctionComponent<Props> = ({customer, isLectureMode, handler}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const firstUpdate = useRef(true);

  const navigate = useNavigate()
  const countryByDefault = useAppSelector(({referential}) => referential.countryByDefault)
  const referential = useAppSelector(({referential}) => referential.referential)
  const customerCurrentAddress = useAppSelector((state) => state.customer.customerCurrentAddress)
  const customerCurrentFiscalAddress = useAppSelector((state) => state.customer.customerCurrentFiscalAddress)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const defaultFirstname = customer?.persons?.[0].firstname || null
  const defaultLastname = customer?.persons?.[0].lastname || null
  const defaultTitle = customer?.customerType === "moral" ? "" : customer?.persons?.[0].title || null
  const defaultSocialReason = customer?.companyName || null

  const {register, control, watch, handleSubmit, setValue, getValues} = useForm<IFormInput>({
    defaultValues: {
      customerAddress: {
        address: {
          id: customerCurrentAddress?.address?.id || null,
          firstname: customerCurrentAddress?.address?.firstname || defaultFirstname,
          lastname: customerCurrentAddress?.address?.lastname || defaultLastname,
          title: customerCurrentAddress?.address?.title || defaultTitle,
          socialReason: customerCurrentAddress?.address?.socialReason || defaultSocialReason,
          address: customerCurrentAddress?.address?.address || "",
          address2: customerCurrentAddress?.address?.address2 || "",
          address3: customerCurrentAddress?.address?.address3 || "",
          city: customerCurrentAddress?.address?.city || null,
          country: customerCurrentAddress?.address?.country || null,
          postcode: customerCurrentAddress?.address?.postcode || "",
          npai: !!(customerCurrentAddress?.address?.npaiAt),
          npaiAt: customerCurrentAddress?.address?.npaiAt || "",
          updatedAt: customerCurrentAddress?.address?.updatedAt || ""
        },
        status: customerCurrentAddress?.status || GENERAL_ADDRESS_STATUS_INACTIVE
      },
      customerFiscalAddress: {
        address: {
          id: customerCurrentFiscalAddress?.address?.id || null,
          firstname: customerCurrentFiscalAddress?.address?.firstname || defaultFirstname,
          lastname: customerCurrentFiscalAddress?.address?.lastname || defaultLastname,
          title: customerCurrentFiscalAddress?.address?.title || defaultTitle,
          socialReason: customerCurrentFiscalAddress?.address?.socialReason || defaultSocialReason,
          address: customerCurrentFiscalAddress?.address?.address || "",
          address2: customerCurrentFiscalAddress?.address?.address2 || "",
          address3: customerCurrentFiscalAddress?.address?.address3 || "",
          city: customerCurrentFiscalAddress?.address?.city || null,
          country: customerCurrentFiscalAddress?.address?.country || null,
          postcode: customerCurrentFiscalAddress?.address?.postcode || "",
          npai: !!(customerCurrentFiscalAddress?.address?.npaiAt),
          npaiAt: customerCurrentFiscalAddress?.address?.npaiAt || "",
          updatedAt: customerCurrentFiscalAddress?.address?.updatedAt || ""
        },
        status: customerCurrentFiscalAddress?.status || GENERAL_ADDRESS_STATUS_INACTIVE
      },
      sameFiscalAddress: customer.sameFiscalAddress
    }
  });

  const watchSameFiscalAddress = watch('sameFiscalAddress')
  const watchAddressNpai = watch('customerAddress.address.npai')
  const watchAddressFiscalNpai = watch("customerFiscalAddress.address.npai")
  const watchPostCode = watch('customerAddress.address.postcode')
  const watchCity = watch('customerAddress.address.city')
  const watchCountry = watch('customerAddress.address.country')
  const watchAddressFiscalCountry = watch('customerFiscalAddress.address.country')
  const watchCurrentAddressStatus = watch('customerAddress.status')
  const watchCurrentAddressFiscalStatus = watch('customerFiscalAddress.status')

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  const statusAddress = useMemo(() => {
    return !!(watchCurrentAddressStatus && watchCurrentAddressStatus === CUSTOMER_ADDRESS_STATUS.ACTIVE);
  }, [watchCurrentAddressStatus])

  const statusAddressFiscal = useMemo(() => {
    return !!(watchCurrentAddressFiscalStatus && watchCurrentAddressFiscalStatus === CUSTOMER_ADDRESS_STATUS.ACTIVE);
  }, [watchCurrentAddressFiscalStatus])

  useEffect(() => {
    if (customerCurrentAddress) {
      setValue("customerAddress.address.id", customerCurrentAddress?.address?.id || null)
      setValue("customerAddress.address.firstname", customerCurrentAddress?.address?.firstname || "")
      setValue("customerAddress.address.lastname", customerCurrentAddress?.address?.lastname || "")
      setValue("customerAddress.address.socialReason", customerCurrentAddress?.address?.socialReason || "")
      setValue("customerAddress.address.title", customerCurrentAddress?.address?.title || "")
      setValue("customerAddress.address.address", customerCurrentAddress?.address?.address || "")
      setValue("customerAddress.address.address2", customerCurrentAddress?.address?.address2 || "")
      setValue("customerAddress.address.address3", customerCurrentAddress?.address?.address3 || "")
      setValue("customerAddress.address.city", customerCurrentAddress?.address?.city || null)
      setValue("customerAddress.address.country", customerCurrentAddress?.address?.country || null)
      setValue("customerAddress.address.postcode", customerCurrentAddress?.address?.postcode || "")
      setValue("customerAddress.address.updatedAt", dayjs().format('DD/MM/YYYY'))
      setValue('customerAddress.address.npai', !!customerCurrentAddress?.address?.npaiAt)
      setValue('customerAddress.status', customerCurrentAddress?.status || GENERAL_ADDRESS_STATUS_INACTIVE)
      if (!customerCurrentFiscalAddress) {
        setValue('sameFiscalAddress', true)
      }
    }
    if (customerCurrentFiscalAddress) {
      setValue("customerFiscalAddress.address.id", customerCurrentFiscalAddress?.address?.id || null)
      setValue("customerFiscalAddress.address.firstname", customerCurrentFiscalAddress?.address?.firstname || "")
      setValue("customerFiscalAddress.address.lastname", customerCurrentFiscalAddress?.address?.lastname || "")
      setValue("customerFiscalAddress.address.socialReason", customerCurrentFiscalAddress?.address?.socialReason || "")
      setValue("customerFiscalAddress.address.title", customerCurrentFiscalAddress?.address?.title || "")
      setValue("customerFiscalAddress.address.address", customerCurrentFiscalAddress?.address?.address || "")
      setValue("customerFiscalAddress.address.address2", customerCurrentFiscalAddress?.address?.address2 || "")
      setValue("customerFiscalAddress.address.address3", customerCurrentFiscalAddress?.address?.address3 || "")
      setValue("customerFiscalAddress.address.city", customerCurrentFiscalAddress?.address?.city || null)
      setValue("customerFiscalAddress.address.country", customerCurrentFiscalAddress?.address?.country || null)
      setValue("customerFiscalAddress.address.postcode", customerCurrentFiscalAddress?.address?.postcode || "")
      setValue("customerFiscalAddress.address.updatedAt", dayjs().format('DD/MM/YYYY'))
      setValue('customerFiscalAddress.address.npai', !!customerCurrentFiscalAddress?.address?.npaiAt)
      setValue('customerFiscalAddress.status', customerCurrentFiscalAddress?.status || GENERAL_ADDRESS_STATUS_INACTIVE)
    }
  }, [customerCurrentAddress, customerCurrentFiscalAddress])

  useEffect(() => {
    if (watchSameFiscalAddress) {
      setValue("customerFiscalAddress.address.firstname", customerCurrentAddress?.address?.firstname || "")
      setValue("customerFiscalAddress.address.lastname", customerCurrentAddress?.address?.lastname || "")
      setValue("customerFiscalAddress.address.socialReason", customerCurrentAddress?.address?.socialReason || "")
      setValue("customerFiscalAddress.address.title", customerCurrentAddress?.address?.title || "")
      setValue("customerFiscalAddress.address.address", customerCurrentAddress?.address?.address || "")
      setValue("customerFiscalAddress.address.address2", customerCurrentAddress?.address?.address2 || "")
      setValue("customerFiscalAddress.address.address3", customerCurrentAddress?.address?.address3 || "")
      setValue("customerFiscalAddress.address.city", customerCurrentAddress?.address?.city || null)
      setValue("customerFiscalAddress.address.country", customerCurrentAddress?.address?.country || null)
      setValue("customerFiscalAddress.address.postcode", customerCurrentAddress?.address?.postcode || "")
      setValue("customerFiscalAddress.address.updatedAt", dayjs().format('DD/MM/YYYY'))
      setValue('customerFiscalAddress.address.npai', watchAddressNpai ?? !!customerCurrentAddress?.address?.npaiAt)
      setValue('customerFiscalAddress.status', customerCurrentAddress?.status || GENERAL_ADDRESS_STATUS_INACTIVE)

      if (customerCurrentFiscalAddress && customerCurrentAddress?.address) {
        dispatch(editCurrentFiscalAddress(customerCurrentAddress))
      } else if (customerCurrentAddress) {
        dispatch(addCurrentFiscalAddress(customerCurrentAddress))
      }
    }
  }, [watchSameFiscalAddress, customerCurrentAddress])

  useEffect(() => {
    const anyAddressFiscalWithEmptyCountry = customer?.customerAddresses?.filter(a => a.type === 'fiscal').some((a: GeneralAddressInterface) => a.address?.country === null)
    if (watchAddressFiscalCountry && anyAddressFiscalWithEmptyCountry) {
      setValue('customerFiscalAddress.address.city', null)
      setValue('customerFiscalAddress.address.postcode', null)
    }
  }, [watchAddressFiscalCountry])

  useEffect(() => {
    const anyAddressWithEmptyCountry = customer?.customerAddresses?.filter(a => a.type !== 'fiscal' && a.main).some((a: GeneralAddressInterface) => a.address?.country === null)
    if (watchCountry && anyAddressWithEmptyCountry) {
      setValue('customerAddress.address.city', null)
      setValue('customerAddress.address.postcode', null)
    }
  }, [watchCountry])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (watchAddressNpai) {
      setValue('customerAddress.address.npaiAt', dayjs().format('DD/MM/YYYY'))
      setValue('customerAddress.address.id', customerCurrentAddress?.address?.id || null)
    } else {
      setValue('customerAddress.address.npaiAt', null)
      setValue('customerAddress.address.id', customerCurrentAddress?.address?.id || null)
    }
    if (watchAddressFiscalNpai || (watchAddressNpai && watchSameFiscalAddress)) {
      setValue('customerFiscalAddress.address.npaiAt', dayjs().format('DD/MM/YYYY'))
      setValue('customerFiscalAddress.address.npai', true)
      setValue('customerFiscalAddress.address.id', customerCurrentFiscalAddress?.address?.id || null)
    } else {
      setValue('customerFiscalAddress.address.npaiAt', null)
      setValue('customerFiscalAddress.address.npai', false)
      setValue('customerFiscalAddress.address.id', customerCurrentFiscalAddress?.address?.id || null)
    }
  }, [watchAddressNpai, watchAddressFiscalNpai])

  const onSubmit: SubmitHandler<IFormInput> = data => {
    if (!!customerCurrentAddress && watchCurrentAddressStatus !== GENERAL_ADDRESS_STATUS_ACTIVE) {
      toastError(t('common.address.status-active-required', {type: "postale"}));
      return;
    }

    if (!!customerCurrentFiscalAddress && watchCurrentAddressFiscalStatus !== GENERAL_ADDRESS_STATUS_ACTIVE) {
      toastError(t('common.address.status-active-required', {type: "fiscale"}));
      return;
    }

    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: IFormInput) => {
    if (data.customerAddress.address?.npai) {
      toastWarning(t('common.address.pnd-required', {type: "postale"}));
    }
    if (data?.sameFiscalAddress && data.customerFiscalAddress.address?.npai) {
      toastWarning(t('common.address.pnd-required', {type: "fiscale"}));
    }
    const customerCopy = {...customer}
    let oldAddresses: GeneralAddressInterface[] = []

    if (customer.customerAddresses) {
      oldAddresses = customer.customerAddresses.map((el) => {
        if (el.type === 'postal') {
          if (el?.address?.id !== customerCurrentAddress?.address?.id) {
            el.main = false
            el.status = GENERAL_ADDRESS_STATUS_INACTIVE
          }
        }
        if (el.type === 'fiscal') {
          if (el?.address?.id !== customerCurrentFiscalAddress?.address?.id) {
            el.main = false
            el.status = GENERAL_ADDRESS_STATUS_INACTIVE
          }
        }

        return el
      })
    }

    const postalAddressIsValid = data.customerAddress.address ? addressIsValid(data.customerAddress.address) : false
    const fiscalAddressIsValid = data.customerFiscalAddress.address ? addressIsValid(data.customerFiscalAddress.address) : false

    const newPostalAddress = !data.customerAddress.address?.id && postalAddressIsValid
    const newFiscalAddress = !data.customerFiscalAddress.address?.id && fiscalAddressIsValid

    if (data.sameFiscalAddress && newPostalAddress) {
      customerCopy.customerAddresses = [
        {
          ...data.customerAddress,
          type: "postal",
          main: true
        }, {
          ...data.customerAddress,
          type: "fiscal",
          main: true
        }
      ]
    } else if (!data.sameFiscalAddress) {
      if (newPostalAddress && newFiscalAddress) {
        customerCopy.customerAddresses = [
          {
            ...data.customerAddress,
            type: "postal",
            main: true
          },
          {
            ...data.customerFiscalAddress,
            type: "fiscal",
            main: true
          }
        ]
      } else if (!newPostalAddress && newFiscalAddress) {
        customerCopy.customerAddresses = [{...data.customerFiscalAddress, type: "fiscal", main: true}]
      } else if (newPostalAddress && !newFiscalAddress) {
        customerCopy.customerAddresses = [{...data.customerAddress, type: "postal", main: true}]
      }
    } else customerCopy.customerAddresses = []

    if (oldAddresses.length && customerCopy.customerAddresses?.length) {
      const oldAddressIds = new Set(oldAddresses.map(item => item.address?.id || null))
      customerCopy.customerAddresses = [
        ...customerCopy.customerAddresses.filter(item => !oldAddressIds.has(item.address?.id || null)),
        ...oldAddresses
      ]
    } else if (oldAddresses.length) {
      customerCopy.customerAddresses = [...oldAddresses]
    }

    customerCopy.sameFiscalAddress = data.sameFiscalAddress

    new UpdateUseCase(new CustomerGateway()).execute(customerCopy).then(response => {
      if (null !== response) {
        if (handler) {
          handler(customerCopy)
        }
        dispatch(reloadCustomer());
        setIsLoading(false)
        toastSuccess(t('account.notify.update-success'))
      } else {
        toastError(t('account.notify.update-error'));
      }
    }).catch(() => {
      setIsLoading(false)
      toastError(t('account.notify.update-error'));
    })
  }

  const addressIsValid = (address: AddressInterface): boolean => {
    const {firstname, lastname, city, country, postcode} = address
    if (address.address && firstname && lastname && city && country && postcode) {
      return true
    } else return false
  }

  const {isDirty} = useFormState({
    control
  });
  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));

  const renderFooter = () => {
    if (isLectureMode) return null
    return <footer className={`form-bloc__footer`}>
      <button type="submit" className="button button--ink-2" disabled={isLoading}>{t('common.save')}</button>
      <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => {
        navigate(`/${t('url.customer.dashboard')}`)
      }}>{t('common.cancel')}</button>
    </footer>
  }

  return ((referential && !isLoading) ?
      <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
        <div className="flex-container">
          <div className="col-md-12">
            <div className="flex-container">
              <div className="col-md-12">
                <AlertInfo text={t('account.form.address.alert-unique-addr-by-num-client')}/>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className={`col-md-6 ${isLectureMode ? 'block-address-lecture-mode': 'block-address'}`}>
              <div className="form-bloc__title">{t('account.form.address.title')}</div>
              {!isLectureMode && <div className="form-bloc__shortcut u-txt-right">
                  <button type="button"
                          className="button-reset u-txt-with-icon"
                          onClick={() => dispatch(setOpenAccountAddressFormEvent({
                            show: true,
                            typeAddress: "postal",
                            defaultCountry: countryByDefault[0],
                            defaultUserInfo: {
                              firstname: customer?.persons?.[0].firstname || null,
                              lastname: customer?.persons?.[0].lastname || null,
                              title: customer?.persons?.[0].title || null,
                              type: customer?.customerType,
                              socialReason: customer?.companyName || null
                            }
                          }))}
                          disabled={isLectureMode || statusAddress}
                  >
                      <img src={iconAdd} alt=""/>
                    {t('account.form.address.add')}
                  </button>
              </div>
              }
              <AddressBlock register={register}
                            control={control}
                            getValues={getValues}
                            setValue={setValue}
                            typeClient={customer.customerType}
                            watchPostCode={watchPostCode}
                            watchCity={watchCity}
                            watchCountry={watchCountry}
                            watchActive={watchCurrentAddressStatus}
                            isCheckboxStatus={true}
                            parentPath={'customerAddress'}
                            typeAddress={"postal"}
                            currentAddress={customerCurrentAddress?.address}
              />
            </div>

            {!watchSameFiscalAddress && <div className={`col-md-6 ${isLectureMode ? 'block-address-lecture-mode': 'block-address'}`}>
                <div className="form-bloc__title">{t('account.form.address.titleFiscality')}</div>
              {!isLectureMode && <div className="form-bloc__shortcut u-txt-right">
                  <button type="button"
                          className="button-reset u-txt-with-icon"
                          onClick={() => dispatch(setOpenAccountAddressFormEvent({
                            show: true,
                            typeAddress: "fiscal",
                            defaultCountry: countryByDefault[0],
                            defaultUserInfo: {
                              firstname: customer?.persons?.[0].firstname || null,
                              lastname: customer?.persons?.[0].lastname || null,
                              title: customer?.persons?.[0].title || null,
                              type: customer?.customerType,
                              socialReason: customer?.companyName || null
                            }
                          }))}
                          disabled={isLectureMode || statusAddressFiscal}
                  >
                      <img src={iconAdd} alt=""/>
                    {t('account.form.address.add-fiscal')}
                  </button>
              </div>
              }
                <AddressBlock register={register}
                              control={control}
                              getValues={getValues}
                              setValue={setValue}
                              typeClient={customer.customerType}
                              watchPostCode={watchPostCode}
                              watchCity={watchCity}
                              watchActive={watchCurrentAddressFiscalStatus}
                              watchCountry={watchCountry}
                              isCheckboxStatus={true}
                              parentPath={'customerFiscalAddress'}
                              typeAddress={"fiscal"}
                              currentAddress={customerCurrentFiscalAddress?.address}
                />
            </div>
            }
          </div>
        </div>
        {renderFooter()}
      </form> : null
  )
}

export default Address
