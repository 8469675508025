import {Presenter} from '../Presenter'
import {PartnerListInterface} from '../../domain/Partner/PartnerList'
import { UserListInterface } from '../../domain/User/UserList';

class UserListPresenter extends Presenter {

  private useCase: { getUsers: any }

  constructor(getUsers: any) {
    super({
      viewModel: {
        title: 'settings.user-profiles.heading.title',
        heading: [
          'settings.user-profiles.list.action-edit',
          'settings.user-profiles.list.firstname',
          'settings.user-profiles.list.lastname',
          'settings.user-profiles.list.email',
          'settings.user-profiles.list.roles',
          'settings.user-profiles.list.enabled',
        ],
        datas: [],
        filtersShortcut: [
          {'keyword': 'filters.by-lastname', 'field': 'lastName', 'type': 'input'},
          {'keyword': 'filters.by-keyword-or-id', 'field': 'keywords', 'type': 'input'}
        ],
        filters: [
          {'title': 'filters.lastname', 'type': 'input'},
          {'title': 'filters.keyword-or-id', 'type': 'input'}
        ],
        pagination: {
          'itemsPerPage': 5,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getUsers,
    }
  }

  async load() {
    try {
      this._setUsers(await this.useCase.getUsers);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setUsers(users: { data: UserListInterface[]|null, nb_element: number }) {
    this.update({
      data: users.data,
      pagination: {
        'itemsPerPage': 5,
        'numberOfItems': users.nb_element,
      }
    });
  }
}

export default UserListPresenter
