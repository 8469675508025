import {Presenter} from '../Presenter'
import {MovementListInterface} from "../../domain/Movement/MovementList";

class ReccurenceListPresenter extends Presenter {

  private useCase: {
    getRecurrences: { data: MovementListInterface[] | null, nb_element: number }
  }

  constructor(getRecurrences: any) {
    super({
      viewModel: {
        title: 'movement.search-a-movement',
        heading: [
          {name: 'movement.heading.action'},
          {name: 'movement.heading.product', sort: "product_label"},
          {name: 'movement.heading.movement_type', sort: "transaction_type"},
          {name: 'movement.heading.id', sort: "transaction_code"},
          {name: 'movement.heading.engage', sort: "action_engaged_at"},
          {name: 'movement.heading.recurrence-state', sort: "transaction.status"},
          {name: 'movement.heading.property_type', sort: "property_type"},
          {name: 'movement.heading.invest_id', sort: "account_code"},
          {name: 'movement.heading.invest_lastname', sort: "person_lastname"},
          {name: 'movement.heading.partner_id', sort: "partner_code"},
          {name: 'movement.heading.partner_lastname', sort: "partner_name"},
          {name: 'movement.heading.gestionnaire', sort: "gestion"},
          {name: 'movement.heading.periodicity', sort: "periodicity"},
          {name: 'movement.heading.payment_method', sort: "payment_mode"},
          {name: 'movement.heading.payment_status', sort: "payment_status"},
          {name: 'movement.heading.payment_date', sort: "payment_date"},
          {name: 'movement.heading.share_count', sort: "transaction.share_count"},
          {name: 'movement.heading.amount', sort: "transaction.total_amount"},
          {name: 'movement.heading.status', sort: "tags"}
        ],
        data: [],
        filtersShortcut: [
          {'keyword': 'filters.by-keyword-or-id', 'field': 'keywords', 'type': 'input'}
        ],
        filters: [],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getRecurrences,
    }
  }

  async load() {
    try {
      this._setRecurrences(await this.useCase.getRecurrences);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setRecurrences(recurrences: { data: MovementListInterface[] | null, nb_element: number }) {
    this.update({
      data: recurrences.data,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': recurrences.nb_element,
      }
    });
  }
}

export default ReccurenceListPresenter
