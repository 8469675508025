import ProductGatewayInterface from '../../../domain/Product/ProductGatewayInterface';
import { ProductInterface } from '../../../domain/Product/Product';
import store from '../../../infrastructure/store/store';

import { setBankInformations, setScales } from '../../../infrastructure/store/component/product';

export default class GetUseCase
{
  public productGateway

  constructor(ProductGateway: ProductGatewayInterface) {
    this.productGateway = ProductGateway
  }

  async execute(id: string): Promise<ProductInterface|null> {
    return await this.productGateway.get(id).then(response => {
      if (response) {
        console.log(response.scales)
        store.dispatch(setScales(response.scales))

        if (response.ribs) {
          store.dispatch(setBankInformations(response.ribs))
        }
      }

      return response
    })
  }
}
