import {PersonInterface} from './Person'

interface ContactInterface {
  id: string
  role: string
  status: string|null
  person: PersonInterface|null
}

class Contact implements ContactInterface {
  public id: string
  public role: string
  public status: string|null
  public person: PersonInterface|null

  constructor(
    id: string,
    role: string,
    status: string|null,
    person: PersonInterface|null,
  ) {
    this.id = id
    this.role = role
    this.status = status
    this.person = person
  }
}

export {
  Contact
}
export type { ContactInterface }
