import {Pledge, PledgeInterface} from "../../domain/Pledge/Pledge";
import Caller from "../../domain/Api/Caller";
import envVariable from "../../infrastructure/presentation/util/envVariable";
import {
  FilterPledgeInterface,
  PledgeListInterface
} from "../../domain/Pledge/PledgeList";

export default class PledgeGateway {
  getExport(filter: FilterPledgeInterface|null, customerId:string): Promise<string|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/customers/${customerId}/pledges/export`, {
      product: filter?.product || '',
      start: filter?.dateStart || '',
      end: filter?.dateEnd || '',
      status: filter?.status || '',
    })
      .then(data => {
        if (data && data.content) {
          return data.content
        }

        return null
      }).catch(response => {
        return null
      })
  }

  getList(currentPage: number, itemsPerPage: number, filter: FilterPledgeInterface|null, customerId: string): Promise<{data: PledgeListInterface[]|null, numberOfItems: number}|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/customers/${customerId}/pledges?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}`, {
      product: filter?.product || '',
      start: filter?.dateStart || '',
      end: filter?.dateEnd || '',
      status: filter?.status || '',
    })
      .then(data => {
        if (data) {
          return data
        }

        return {
          data: [],
          numberOfItems: 0
        }
      }).catch(response => {
        return {
          data: [],
          numberOfItems: 0
        }
      });
  }

  add(Pledge: PledgeInterface, customerId: string|null, sliceId: string|null): Promise<string|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/customers/${customerId}/pledges`, {
      "pledgeOrganization": Pledge?.pledgeOrganization?.id,
      "type": Pledge.type,
      "reason": Pledge.reason,
      "startAt": Pledge.startAt,
      "endAt": Pledge.endAt,
      "handAt": Pledge.handAt,
      "slice": sliceId,
      "product": Pledge?.product?.id
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(err => {
        return null
      })
  }

  get(id: string): Promise<Pledge|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/customers/pledge/${id}`, {})
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }

  update(Pledge: PledgeInterface, customerId: string|null, sliceId: string|null): Promise<string|null> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/customers/${customerId}/pledges/${Pledge.id}`, {
      "pledgeOrganization": Pledge?.pledgeOrganization?.id,
      "type": Pledge.type,
      "reason": Pledge.reason,
      "startAt": Pledge.startAt,
      "endAt": Pledge.endAt,
      "handAt": Pledge.handAt,
      "slice": sliceId,
      "product": Pledge?.product?.id
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(err => {
        return null
      })
  }
}