import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'
import { v4 as uuidV4 } from 'uuid'

import {useAppDispatch, useAppSelector} from '../../../store/hook'
import {setOpenDatalistFilterClient} from '../../../store/component/event'
import CustomerListPresenter from '../../../../presenter/Customer/CustomerListPresenter'
import ClientGateway from '../../../../gateway/Client/ClientGateway'
import {getIconForStatus} from '../../util/getState'

import Pagination from '../Pagination/Pagination'

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

import iconHeadingSearch from '../../../../assets/images/icons/datalist-heading-search.svg'
import iconEdit from '../../../../assets/images/icons/datalist-edit.svg'
import '../../../../assets/styles/components/_datalist.scss'
import {ClientListInterface, FilterClientListInterface} from "../../../../domain/Client/ClientList";
import iconAdd from "../../../../assets/images/icons/add.svg";
import iconSearch from "../../../../assets/images/icons/datalist-search.svg"
import CustomerGateway from "../../../../gateway/Customer/CustomerGateway";
import {optionsNbRows} from "../../../../fixtures/Referentiel";
import SelectCustom from "../Elements/Select";
import {saveNbRowsInLocalStorage} from "../../util/SavePreferencesInLocalStorage";
import {SortInterface, SortOrder} from "../../../../domain/Utils/List";
import { TYPE_MORAL } from '../../../../domain/Prospect/Prospect';
import iconChevron from "../../../../assets/images/icons/chevron.svg";
import iconChevronSelected from "../../../../assets/images/icons/chevronSelected.svg";
import { Link } from 'react-router-dom';
import {CallerType} from "../../../../domain/Caller/Caller";

type Props = {
  callback: any
  callerType: CallerType
}

const CustomerDatalist: FunctionComponent<Props> = ({ callerType, callback, ...rest}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openChoiceInvestor = useAppSelector((state) => state.event.openChoiceInvestor)
  const openDatalistFilterClient = useAppSelector((state) => state.event.openDatalistFilterClient)
  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [customers, setCustomers] = useState<{data: ClientListInterface[]|null, numberOfItems: number}|null>(null)
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)
  const controller = new AbortController()
  const preferences = localStorage.getItem("preferences")

  const { register, handleSubmit, setValue, watch } = useForm()

  const watchNumberRows = watch('numberRows')

  useEffect(() => {
    const presenter = new CustomerListPresenter(customers);
    presenter.load().then(() => {
      setViewModel(presenter.immutableViewModel())
    })
  }, [customers])

  useEffect(()=> {
    if(openChoiceInvestor.show) setValue("numberRows", undefined)
  },[openChoiceInvestor])

  useEffect(() => {
    if(!openChoiceInvestor.show) return
    if(!watchNumberRows) {
      setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      if(openChoiceInvestor.dataListType === "customer") {
        (new CustomerGateway()).getList(callerType,currentPage, watchNumberRows || 50, openDatalistFilterClient.filters, controller.signal).then(response => {
          setCustomers(response)
        })
      } else {
        (new ClientGateway()).getList(currentPage, watchNumberRows || 50, openDatalistFilterClient.filters, controller.signal).then(response => {
          setCustomers(response)
        })
      }

      setValue('lastName', openDatalistFilterClient.filters.lastName)
      setValue('maidenName', openDatalistFilterClient.filters.maidenName)
      setValue('keywords', openDatalistFilterClient.filters.keywords)
      setValue('birthDate', openDatalistFilterClient.filters.birthDate)
    }

  }, [currentPage, openDatalistFilterClient.filters, watchNumberRows])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterClient.filters])

  const onSubmit: SubmitHandler<FilterClientListInterface> = data => {
    dispatch(setOpenDatalistFilterClient({
        show: false,
        count: openDatalistFilterClient.count,
        filters: {
          lastName: data.lastName,
          maidenName: data.maidenName,
          keywords: data.keywords,
          birthDate: data.birthDate
        }
      }
    ))
  }
  const paginate = (pageNumber:number) => {controller.abort(); setCurrentPage(pageNumber)}

  const handleClickFilter = (response: string) => {
    if (response) {
      dispatch(setOpenDatalistFilterClient({show: true, count: openDatalistFilterClient.count, filters: openDatalistFilterClient.filters}))
    }
  }

  const selectClient = (client: ClientListInterface) => {
    callback(client)
  }

  const resetFilters = () => {
    dispatch(setOpenDatalistFilterClient({
        show: false,
        count: 0,
        filters: {
          lastName: '',
          maidenName: '',
          keywords: '',
          birthDate: '',
        }
      }
    ))
  }

  function defineSortOrder(sort: string|undefined): SortOrder {
    if (sortOrder.sortLabel !== sort) return SortOrder.ASC
    return sortOrder.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC
  }

  function sortData(sort: string | undefined) {
    if(sort) {
      const order: SortInterface = {
        sortLabel: sort,
        sortOrder: defineSortOrder(sort)
      };
      setSortOrder(order);
      if(openChoiceInvestor.dataListType === "customer") {
        new CustomerGateway()
          .getList(callerType,currentPage, watchNumberRows || 50, openDatalistFilterClient.filters, controller.signal, sort, order.sortOrder)
          .then(response => {
            setCustomers(response)
          })
      } else {
        new ClientGateway()
          .getList(currentPage, watchNumberRows || 50, openDatalistFilterClient.filters, controller.signal, sort, order.sortOrder)
          .then(response => {
            setCustomers(response)
          })
      }

    }
  }

  return (<>
      {(viewModel !== null &&
        <>
          <div className={`datalist`}>
            <div className="datalist__title">{t(viewModel.title)}</div>
            <div className="datalist__header">
              <form onSubmit={handleSubmit(onSubmit)} className="filter">
                <div className="filter__input">
                  {viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                    <div key={uuidV4()} className="input-no-border">
                      <img src={iconHeadingSearch} alt="" />
                      <input {...register(filter.field)} placeholder={t('common.search-by', {keyword: t(filter.keyword)})} className="u-mxs"/>
                    </div>
                  ))}
                </div>
                <div className="filter__actions">
                  <button type="submit" className="button button--submit">{t('search.submit')}</button>
                  <button type="button" className="button button--white" onClick={() => resetFilters()}>{t('search.cancel')}</button>
                </div>
              </form>
            </div>
            <SelectCustom classes="flex justify-end u-mbs" id="numberRows"
                          name="numberRows"
                          label={t('filters.display-results-by')}
                          options={optionsNbRows}
                          register={register}
                          noChoiceOption
            />
            <div className="table-fix-head">
            <table className="datalist__datas">
              <thead>
              <tr>
                {viewModel.heading.map((title: { name: string, sort?: string }) => (
                  <th key={uuidV4()} className={title.sort ? 'sortable' : ''} onClick={()=>sortData(title.sort)}>
                    {t(title.name)}
                    {title.sort && sortOrder.sortLabel !== title.sort &&
                        <>
                            <img src={iconChevron} className="chevron-top" alt="chevron-top" />
                            <img src={iconChevron} className="chevron-bottom" alt="chevron-bottom" />
                        </>
                    }
                    {title.sort && sortOrder.sortLabel === title.sort && sortOrder.sortOrder === SortOrder.ASC &&
                        <>
                            <img src={iconChevronSelected} className="chevron-bottom--selected" alt="chevron-bottom" />
                        </>
                    }
                    {title.sort && sortOrder.sortLabel === title.sort && sortOrder.sortOrder === SortOrder.DESC &&
                        <>
                            <img src={iconChevronSelected} className="chevron-top--selected" alt="chevron-top" />
                        </>
                    }
                  </th>
                ))}
              </tr>
              </thead>
              <tbody>
              {viewModel.data !== undefined &&
              viewModel.data.map((item: ClientListInterface) => (
                <tr key={uuidV4()}>
                  <td>
                    <button type="button" className="button-reset" onClick={() => selectClient(item)}>
                      <img src={iconAdd} alt="ajouter" />
                    </button>
                    <Link to={ `/${ t('url.customer.read-general-information') }/${ item.id }` }
                          target="_blank"
                          rel="noopener noreferrer"
                    >
                      <button type="button" className="button-reset">
                        <img src={iconSearch} alt="voir"/>
                      </button>
                    </Link>
                  </td>
                  <td>{item.accountId}</td>
                  <td>{item.lastName}</td>
                  <td>{item.maidenName === TYPE_MORAL ? '' : item.maidenName}</td>
                  <td>{item.firstName}</td>
                  <td>{item.socialReason}</td>
                  <td>{item.city}</td>
                  <td>{item.productType}</td>
                  <td>{item.productAcronym}</td>
                  <td>{item.status ? t(`prospect.datalist.status.${item.status}`) : ""}</td>
                </tr>
              ))}
              {viewModel.data === undefined || viewModel.data.length === 0 &&
                <tr>
                  <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
                </tr>
              }
              </tbody>
            </table>
            </div>
          </div>
          <Pagination currentPage={currentPage} itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage} numberOfItems={viewModel.pagination.numberOfItems} callback={paginate} />
        </>
      )}
    </>
  )
}

export default CustomerDatalist
