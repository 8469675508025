import React, {FunctionComponent} from 'react';

type TProps = {
  width?: number
  height?: number
  color?: string
}

const Warning: FunctionComponent<TProps> = ({width, height, color}) => {
  return (
    <svg width={width || "19"} height={height || "16"} viewBox="0 0 19 16" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.9083 15.0204L10.0874 0.326531C9.95589 0.108163 9.72901 0 9.5 0C9.271 0 9.04199 0.108163 8.91264 0.326531L0.0917152 15.0204C-0.169096 15.4571 0.157448 16 0.67907 16H18.3209C18.8426 16 19.1691 15.4571 18.9083 15.0204ZM8.82147 6.04082C8.82147 5.95102 8.8978 5.87755 8.9911 5.87755H10.0089C10.1022 5.87755 10.1785 5.95102 10.1785 6.04082V9.79592C10.1785 9.88571 10.1022 9.95918 10.0089 9.95918H8.9911C8.8978 9.95918 8.82147 9.88571 8.82147 9.79592V6.04082ZM9.5 13.2245C9.23366 13.2193 8.98006 13.1138 8.79363 12.9306C8.60719 12.7475 8.50277 12.5013 8.50277 12.2449C8.50277 11.9885 8.60719 11.7423 8.79363 11.5592C8.98006 11.376 9.23366 11.2705 9.5 11.2653C9.76634 11.2705 10.0199 11.376 10.2064 11.5592C10.3928 11.7423 10.4972 11.9885 10.4972 12.2449C10.4972 12.5013 10.3928 12.7475 10.2064 12.9306C10.0199 13.1138 9.76634 13.2193 9.5 13.2245Z"
        fill={color || "#A6440C"}/>
    </svg>
  );
}

export default Warning;