import React, {FunctionComponent} from "react";
import AlertInfo from "../AlertInfo/AlertInfo";
import {useTranslation} from "react-i18next";

type Props = {
  errors: string[]
  message: string
}
const FormErrorInfo: FunctionComponent<Props> = React.memo( ({errors, message}) => {
  const {t} = useTranslation()

  const generateErrorMessage = (): string => {
    errors.forEach(error => {
      message += t(error)
      if (error !== errors[errors.length - 1]) {
        message += ' / '
      }
    })
    return message
  }

  return (
    <AlertInfo text={generateErrorMessage()} />
  )
})

export default FormErrorInfo
