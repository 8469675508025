import {Investor} from "../../../domain/PreSubscription/Investor";

export default class InitializeInvestor {
  initializeInvestor() {
    return new Investor(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      null,
      null,
      undefined
    )
  }
}
