import {Presenter} from '../../Presenter'
import {SliceListInterface} from '../../../domain/Share/Slice/SliceList'

class ProductListPresenter extends Presenter {

  private useCase: { getSlices: any }

  constructor(getSlices: any) {
    super({
      viewModel: {
        title: 'movement.heading-slice.view-list-recovery-heritage',
        heading: [
          'movement.heading-slice.product-acronym',
          'movement.heading-slice.esn-type',
          'movement.heading-slice.date',
          'movement.heading-slice.type',
          'movement.heading-slice.nature',
          'movement.heading-slice.begin',
          'movement.heading-slice.end',
          'movement.heading-slice.nb-shares-sliced',
          'movement.heading-slice.price-share-with-vl',
          'movement.heading-slice.amount',
          'movement.heading-slice.nb-shares-transfert',
          'common.multiple-action'
        ],
        datas: [],
        pagination: {
          'itemsPerPage': 0,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getSlices,
    }
  }

  async load() {
    try {
      this._setSlices(await this.useCase.getSlices);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setSlices(slices: { data: SliceListInterface[]|null, nb_element: number }) {
    this.update({
      data: slices.data,
      pagination: {
        'itemsPerPage': 0,
        'numberOfItems': slices.nb_element,
      }
    });
  }
}

export default ProductListPresenter
