import {Movement} from './Movement'
import {Investor} from '../PreSubscription/Investor'
import {Product} from '../Product/Product'
import {SliceListInterface} from '../Share/Slice/SliceList'
import {User} from "../User/User";

interface MutationInterface {
  allShares: boolean
  valueDate: string|null
  flatFee: number|null
  amount: number|null
  slices: SliceListInterface[]
  slicesDetail: SliceListInterface[]
}

class Mutation extends Movement implements MutationInterface {
  allShares: boolean
  valueDate: string|null
  flatFee: number|null
  amount: number|null
  slices: SliceListInterface[]
  slicesDetail: SliceListInterface[]

  constructor(
    id: string|null,
    createdAt: string|null,
    updatedAt: string|null,
    subscribedAt: string|null,
    deletedAt: string|null,
    engagedAt: string|null,
    canceledAt: string|null,
    verifiedLevel1At: string|null,
    verifiedLevel2At: string|null,
    confirmedAt: string|null,
    validationScheduledAt: string|null,
    executedAt: string|null,
    shareCount: number|null,
    sharePrice: number|null,
    totalAmount: number|null,
    status: string|null,
    propertyType: string|null,
    possessionMode: string|null,
    subscriptionCode: string|null,
    investors: Investor[]|null,
    product: Product|null,
    allShares: boolean,
    valueDate: string|null,
    flatFee: number|null,
    amount: number|null,
    slices: SliceListInterface[],
    slicesDetail: SliceListInterface[],
    transactionCode: string|null,
    tags: [],
    status_label: string|null,
    horodatage: string|null,
    user : User|null,
    lastUpdateUser: User|null,
    actionReason: string|null,
    comment: string|null,
    subType: string|null,
    transactionExecutionAt: string|null,
    note: string|null
  ) {
    super(
      id,
      createdAt,
      updatedAt,
      subscribedAt,
      deletedAt,
      engagedAt,
      canceledAt,
      verifiedLevel1At,
      verifiedLevel2At,
      confirmedAt,
      validationScheduledAt,
      executedAt,
      shareCount,
      sharePrice,
      totalAmount,
      status,
      propertyType,
      possessionMode,
      subscriptionCode,
      investors,
      product,
      transactionCode,
      tags,
      status_label,
      horodatage,
      user,
      lastUpdateUser,
      actionReason,
      comment,
      subType,
      transactionExecutionAt,
      note
    );

    this.allShares = allShares
    this.valueDate = valueDate
    this.flatFee = flatFee
    this.amount = amount
    this.slices = slices
    this.slicesDetail = slicesDetail
    this.status_label = status_label
  }
}

export {Mutation}
export type {MutationInterface}
