import React, {FunctionComponent, useCallback, useEffect, useLayoutEffect, useState} from 'react';
import GetUseCase from "../../../../../useCase/Partner/Get/GetUseCase";
import PartnerGateway from "../../../../../gateway/Partner/PartnerGateway";
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import { ReferentielInterface } from '../../../../../domain/Referentiel/ReferentielInterface'
import {PartnerInterface} from "../../../../../domain/Partner/Partner";
import MatchUrl from "../../../../../domain/Utils/MatchedUrl";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import ContactForm from "../../../component/Sidebar/Partner/ContactForm";
import AddAddress from "../../../component/Sidebar/Partner/AddAddress";
import Upload from "../../../component/Sidebar/Partner/Upload";
import DocumentList from "../../../component/Document/DocumentList";
import HeaderPage from "../../../component/HeaderPage/HeaderPage";
import Status from "../../../component/Transaction/Status";
import TabPartnerEdit from "../../../component/Tab/PartnerEdit/TabParnerEdit";
import GeneralInformation from "../../../component/Form/Partner/GeneralInformation/GeneralInformation";
import DocumentTracking from "../../../component/Form/Partner/DocumentTracking/DocumentTracking";
import BeneficialBeneficiaries from "../../../component/Form/Partner/BeneficialBeneficiaries/BeneficialBeneficiaries";
import AddBeneficialBeneficiaries from "../../../component/Sidebar/Partner/AddBeneficialBeneficiaries";
import PortfolioDatalist from "../../../component/Datalist/PortfolioDatalist";
import Commissions from "../../../component/Form/Partner/Commissions/Commissions";
import AddConventionAnnex from "../../../component/Sidebar/Partner/AddConventionAnnex/AddConventionAnnex";
import AddCommissionRelation from "../../../component/Sidebar/Partner/AddCommissionRelation";
import AddRelationLink from "../../../component/Sidebar/Partner/AddRelationLink";
import Particularities from "../../../component/Form/Partner/Particularities/Particularities";
import TabPartnerAdd from "../../../component/Tab/PartnerAdd/TabParnerAdd";
import {InitializePartner} from "../../../../../useCase/Partner/Initialization/InitializePartner";
import Spinner from "../../../component/Spinner/Spinner";
import AddCityForm from '../../../component/Sidebar/Referentiel/AddCityForm';
import ChoicePartner from "../../../component/Sidebar/Partner/ChoicePartner";
import CompletenessOfFile from "../../../component/CompletenessOfFile/CompletenessOfFile";
import Transfert from "../../../component/Form/Partner/Transfert/Transfert";
import TransferHistoryDetails from "../../../component/Sidebar/Partner/TransferHistoryDetails";
import { setOpenDatalistFilterPartner } from '../../../../store/component/event';
import {TError} from "../../../../../domain/Error/Error";
import ErrorPage from "../../Error/ErrorPage";
import Convention from "../../../component/Form/Partner/Convention/Convention";

interface Props {
  page: string
  mode: "add" | "edit" | "read"
}

const PartnerLayout: FunctionComponent<Props> = ({page, mode}) => {
  const {t} = useTranslation()
  const {uuid} = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const getPartnerUseCase = new GetUseCase(new PartnerGateway())

  const partnerRules = useAppSelector((state) => state.me.me?.rules.partner.actions)
  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const reload = useAppSelector((state) => state.partner.reloadPartner)

  const [partner, setPartner] = useState<PartnerInterface | null>(null)
  const [title, setTitle] = useState<string>(t('partner.edit.title'))
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [status, setStatus] = useState<string>("")
  const [error, setError] = useState<TError|null>(null)

  const isLectureMode = MatchUrl.find(location.pathname, 'lecture')

  useEffect(() => {
    if(partnerRules) {
      if(!partnerRules?.update && !isLectureMode) navigate(`/${t('url.partner.read-general-information')}/${uuid}`)
    }
  }, [partnerRules])

  useEffect(() => {
    dispatch(setOpenDatalistFilterPartner({show: false, count: 0, filters: {
        idPartner: '',
        keywords: '',
        socialReason: '',
        name: '',
        city: '',
        product:  {
          id: '',
          value: '',
          label: ''
        },
        status: [],
        type: [],
        platform: [],
        network: [],
        commissionLevel: ""
      }}))
  }, [])

  useEffect(() => {
    if (partner && partner.contactList) {
      if(partner.status) {
        setStatus(partner.status)
      }

      const socialReason = partner.socialReason || ""
      setTitle(`${t('partner.edit.title')}
        ${socialReason && " : " + socialReason}
        ${partner?.partnerCode && `(${partner?.partnerCode})`}`
      )
    }
  }, [partner, status])

  useLayoutEffect(() => {
    setIsLoading(true)
    if (uuid) {
      refreshPartner(uuid)
    }
    setIsLoading(false)
  }, [reload, uuid])

  const refreshPartner = useCallback((uuid) => {
    getPartnerUseCase.execute(uuid).then(response => {
      if (response !== null) {
        setPartner(response)
      }
    }).catch((e) => {
      setError(e)
    })
  }, [])

  const handleChoosePage = (partner?: PartnerInterface) => {
    if (!referential) return <section className="container--spinner"><Spinner size={150}/></section>

    switch (page) {
      case 'GeneralInformation':
        if ((mode === "edit" || mode === "read") && partner) {
          return <GeneralInformation partner={partner}
                                     isLectureMode={isLectureMode}
                                     referential={referential}
                                     setStatus={setStatus}
          />
        }
        if (mode === "add") {
          return <GeneralInformation partner={new InitializePartner().initializePartner()}
                                     referential={referential}
                                     setStatus={setStatus}
          />
        }
        break;
      case 'DocumentTracking':
        if ((mode === "edit" || mode === "read") && partner?.id) {
          return (
            <DocumentTracking partner={partner} isLectureMode={isLectureMode}/>
          )
        }
        break;
      case 'BeneficialBeneficiaries':
        if (mode === "edit" || mode === "read") {
          return <BeneficialBeneficiaries partner={partner || undefined}
                                          isLectureMode={isLectureMode}
                                          referential={referential}
          />
        }
        break;
      case 'Portfolio':
        if ((mode === "edit" || mode === "read") && uuid) {
          return <PortfolioDatalist investorId={uuid} isLectureMode={isLectureMode}/>
        }
        break;
      case 'Commissions':
        if ((mode === "edit" || mode === "read")) return <Commissions partner={partner}
                                                                      isLectureMode={isLectureMode}
                                                                      referential={referential}
        />
        break;
      case 'Convention':
        if ((mode === "edit" || mode === "read")) return <Convention partner={partner}
                                                                     isLectureMode={isLectureMode}
                                                                     referential={referential}
        />
        break;
      case 'Particularities':
        if ((mode === "edit" || mode === "read")) return <Particularities partner={partner || undefined}
                                                                          isLectureMode={isLectureMode}
                                                                          referential={referential}
        />
        break;
      case 'Transfert':
        if ((mode === "edit" || mode === "read")) return <Transfert partner={partner || undefined}
                                                                    isLectureMode={isLectureMode}
                                                                    referential={referential}
        />
        break;
      default:
        console.log(`Sorry, we are out of ${page}.`);
    }
  }

  const handleSidesBarDependLectureMode = () => {
    return (
      <>
        {referential && page === "GeneralInformation" &&
            <ContactForm referential={referential}/>}
        {referential && page === "GeneralInformation" &&
            <AddAddress referential={referential}/>}
        {mode === "edit" && (page === "GeneralInformation" || page === "DocumentTracking") && <Upload/>}
        {referential && page === "BeneficialBeneficiaries" &&
            <AddBeneficialBeneficiaries referential={referential}/>}
        {!isLectureMode && referential && page === "Particularities" &&
            <AddRelationLink referential={referential}/>}

      </>
    )
  }

  if(error) return <ErrorPage code={error.code.toString()} />

  return (
    <div>
      {!isLectureMode && (page === "Particularities" || page === "Transfert" )&& <ChoicePartner/>}
      {referential && page === "Transfert" && <TransferHistoryDetails />}
      {page === "Particularities" && <CompletenessOfFile partner={partner || undefined} />}
      {!isLectureMode && handleSidesBarDependLectureMode()}

      {(page === "DocumentTracking" || page === "Convention")  && <DocumentList/>}
      {page === "Convention" && <AddConventionAnnex isLectureMode={isLectureMode}/>}
      {page === "Convention" && <Upload />}
      {page === "Commissions" && <AddCommissionRelation/>}
      <AddCityForm />
      {/* check if necessary in this page  page === "Commissions" && <DatalistFilter/> */}

      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        <HeaderPage title={mode === 'add' ? t('partner.add.title') : title}/>
        {partner && referential
          ?
          <Status label={"partner.form.bloc-general-information.status"}
                  status={status}
                  statusList={referential.partner.status}/>
          :
          <div className="badge-line--space-when-empty"/>
        }
        {partner && (mode === "edit" || mode === "read") ?
          <>
            {uuid && <TabPartnerEdit uuid={uuid} isLectureMode={isLectureMode}/>}
            {!isLoading && handleChoosePage(partner)}
          </>
          :
          <>
            <TabPartnerAdd/>
            {handleChoosePage()}
          </>
        }

      </main>
    </div>
  );
}

export default PartnerLayout;
