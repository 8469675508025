import {FunctionComponent} from 'react'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import '../../../../../assets/styles/components/_tab.scss'

type Props = {
  uuid: string|null
  isLectureMode: boolean
}

const TabPartnerEdit: FunctionComponent<Props> = ({uuid, isLectureMode}) => {
  const {t} = useTranslation()

  return (
    <div className={`tab tab--partner-add`}>
      <NavLink
        to={`/${t(`url.partner.${isLectureMode ? "read" : "edit"}-general-information`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('partner.add.tab-general-information')}
      </NavLink>
      <NavLink
        to={`/${t(`url.partner.${isLectureMode ? "read" : "edit"}-document-tracking`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('partner.add.tab-document-tracking')}
      </NavLink>
      <NavLink
        to={`/${t(`url.partner.${isLectureMode ? "read" : "edit"}-beneficial-beneficiaries`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('partner.add.tab-beneficial-beneficiaries')}
      </NavLink>
      <NavLink
        to={`/${t(`url.partner.${isLectureMode ? "read" : "edit"}-portfolio`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('partner.add.tab-portfolio')}
      </NavLink>
      <NavLink
        to={`/${t(`url.partner.${isLectureMode ? "read" : "edit"}-commissioning`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('partner.add.tab-commissioning')}
      </NavLink>
        <NavLink
            to={`/${t(`url.partner.${isLectureMode ? "read" : "edit"}-convention`)}/${uuid}`}
            className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
        >
            {t('partner.add.tab-convention')}
        </NavLink>
      <NavLink
        to={`/${t(`url.partner.${isLectureMode ? "read" : "edit"}-particularities`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('partner.add.tab-particularities')}
      </NavLink>
      <NavLink
        to={`/${t(`url.partner.${isLectureMode ? "read" : "edit"}-transfert`)}/${uuid}`}
        className={({isActive}) => isActive ? 'tab__item tab__item--active' : 'tab__item'}
      >
        {t('partner.add.tab-transfert')}
      </NavLink>
    </div>
  )
}

export default TabPartnerEdit
