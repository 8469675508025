export function saveNbRowsInLocalStorage(preferences: string|null, watchNumberRows: string) {
  if(preferences) {
    const preferencesParse = JSON.parse(preferences)
    localStorage.setItem("preferences",
      JSON.stringify({
        ...preferencesParse,
        numberRows: watchNumberRows || preferencesParse.numberRows
      })
    )
  } else {
    const newPreferencesObj = { numberRows: 50 }
    localStorage.setItem("preferences", JSON.stringify(newPreferencesObj))
  }
}

export function saveAnimationInLocalStorage(preferences: string|null, value?: boolean) {
  if(preferences) {
    const preferencesParse = JSON.parse(preferences)
    localStorage.setItem("preferences",
      JSON.stringify({
        ...preferencesParse,
        animation: value
      })
    )
  } else {
    const newPreferencesObj = { animation: value, numberRows: 50 }
    localStorage.setItem("preferences", JSON.stringify(newPreferencesObj))
  }
}