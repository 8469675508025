import PartnerGatewayInterface from '../../../domain/Partner/PartnerGatewayInterface'
import {PartnerInterface} from '../../../domain/Partner/Partner'
import PartnerGateway from "../../../gateway/Partner/PartnerGateway";
import store from "../../../infrastructure/store/store";
import  {setContacts, setAddresses, setBeneficialBeneficiaries, setConventionAnnex, setRelationLinks, setPartnerTransfertTarget} from "../../../infrastructure/store/component/partner";


export default class GetUseCase
{
  public partnerGateway

  constructor(PartnerGateway: PartnerGateway) {
    this.partnerGateway = PartnerGateway
  }

  async execute(id: string): Promise<PartnerInterface|null> {
    const state = store.getState();

    return await this.partnerGateway.get(id).then(partner => {
      store.dispatch(setContacts(partner?.contactList ?? []))
      store.dispatch(setAddresses(partner?.addressList ?? []))
      store.dispatch(setBeneficialBeneficiaries(partner?.beneficialBeneficiariesList ?? []))
      store.dispatch(setRelationLinks(partner?.relations ?? []))
      store.dispatch(setPartnerTransfertTarget(partner?.partnerTransfert ?? null))

      return partner
    })
  }
}
