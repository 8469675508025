import Caller from "../../../domain/Api/Caller";
import envVariable from "../../../infrastructure/presentation/util/envVariable";

export default class BankInformationGateway {
  delete(prospectId: string|null, bankInformationId: string|null): Promise<any> {
    return Caller.executeDelete(`${envVariable('REACT_APP_API_URL')}/customers/${prospectId}/rib/${bankInformationId}`, {})
    .then(data => {
      if (data) {
        return data.data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }
}
