import MovementGateway from "../../../gateway/Movement/MovementGateway";
import {MovementInterface} from "../../../domain/Movement/Movement";
import PreSubscriptionGateway from "../../../gateway/PreSubscription/PreSubscriptionGateway";
import {PreSubscriptionInterface} from "../../../domain/PreSubscription/PreSubscription";
import RedemptionWithdrawalGateway from "../../../gateway/Movement/RedemptionWithrawal/RedemptionWithdrawalGateway";
import {RedemptionWithdrawal, RedemptionWithdrawalInterface} from "../../../domain/Movement/RedemptionWithdrawal";

export default class GetUseCase
{
  public redemptionWithdrawalGateway

  constructor(
    redemptionWithdrawalGateway: RedemptionWithdrawalGateway
  ) {
    this.redemptionWithdrawalGateway = redemptionWithdrawalGateway
  }

  async execute(id: string): Promise<RedemptionWithdrawal> {
    return await this.redemptionWithdrawalGateway.get(id).then(response => {

      if (response && response.investors) {
        response.investors.sort(function (investor) {
          if (investor.type === "investor" || investor.type === "transferor") {
            return -1
          }
          if (investor.type === "usufruct") {
            return 1
          }

          return 0
        })
      }
      return response
    })
  }
}
