import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { UserInterface } from '../../../domain/User/User';
import { FilterUserInterface, UserListInterface } from '../../../domain/User/UserList';

interface userDataList {
  countTotal: number
  currentUser: UserInterface|null
  filter: FilterUserInterface|null
}

const initialState: userDataList = {
  countTotal: 0,
  currentUser: null,
  filter: null
}


export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setCountTotal: (state, action: PayloadAction<number>) => {
      state.countTotal = action.payload
    },
    addFilter: (state, action: PayloadAction<FilterUserInterface|null>) => {
      state.filter = action.payload
    },
    addUser: (state, action: PayloadAction<UserInterface|null>) => {
      state.currentUser = action.payload
    },
    saveUser: (state, action: PayloadAction<any>) => {
      state.currentUser = {...state.currentUser, ...action.payload}
    },
    updateUser: (state, action: PayloadAction<any>) => {
      state.currentUser = {...state.currentUser, ...action.payload}
    }
  }
})

export const {
  setCountTotal,
  addFilter,
  addUser,
  updateUser,
} = userSlice.actions


export default userSlice.reducer
