import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../store/hook";
import HeaderPage from "../../../component/HeaderPage/HeaderPage";
import TabSettings from "../../../component/Tab/Settings/TabSettings";
import GetUseCase from "../../../../../useCase/Settings/Get/GetUseCase";
import GeneralSettingsGateway
    from "../../../../../gateway/Settings/GeneralSettings/GeneralSettingsGateway";
import Upload from "../../../component/Sidebar/Settings/Upload";
import UserDatalist from '../../../component/Datalist/UserDatalist';
import AddUser from '../../../component/Sidebar/Settings/AddUser';
import {SettingsInterface} from "../../../../../domain/Settings/GeneralSettings/Settings";


const UserProfiles: FunctionComponent = () => {
    const {t} = useTranslation()

    const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
    const userRules = useAppSelector((state) => state.me.me?.rules.user.actions)
    const [generalSettings, setGeneralSettings] = useState<SettingsInterface | null>(null)

    useEffect(() => {
        new GetUseCase(new GeneralSettingsGateway()).execute().then(
            response => {
                if (response !== null) {
                    setGeneralSettings(response)
                }
            }
        );

    }, [])


    return (
        <>
            <AddUser/>
            {<Upload/>}
            <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
                <HeaderPage title={t('settings.title')}/>
                <TabSettings/>
                {userRules?.list && <UserDatalist/>}
            </main>
        </>
    )
}

export default UserProfiles
