import {Presenter} from "../Presenter";
import {Transfer} from "../../domain/Partner/Transfer";

class HistoryTransferListPresenter extends Presenter {

  constructor(HistoryTransfer: Transfer[]) {
    super({
      viewModel: {
        title: 'partner.form.transfer.history.title',
        heading: [
          'partner.form.transfer.history.action',
          'partner.form.transfer.history.date',
          'partner.form.transfer.history.status',
          'partner.form.transfer.history.transferor',
          'partner.form.transfer.history.partnerTarget',
        ],
        data: HistoryTransfer,
        count: HistoryTransfer.length
      }
    });
  }

}

export default HistoryTransferListPresenter