import { ProductType } from './ProductType';
import { GeneralInformation } from './GeneralInformation';
import { Valorisation } from './Valorisation';
import { Distribution } from './Distribution';
import { Withdrawal } from './Withdrawal';
import { Accounting } from './Accounting';
import { Usage } from './Usage';
import { Categorization } from './Categorization';
import { Documentation } from './Documentation';
import { Fiscality } from './Fiscality';
import { GeneralMeeting } from './GeneralMeeting';
import { Commission } from './Commission';
import { NetAssetValue } from './NetAssetValue';
import { ProductBankInformation } from './BankInformation';
import { ProductDepositary } from './ProductDepositary';
import { Scale } from './Scale';

interface ProductInterface {
  id: string|null
  createdAt: string|null
  updatedAt: string|null
  deletedAt: string|null
  label: string|null
  productType: ProductType|null
  status: string|null
  generalInformation: GeneralInformation|null
  valorisation: Valorisation|null
  distribution: Distribution|null
  withdraw: Withdrawal|null
  accounting: Accounting|null
  usage: Usage|null
  categorization: Categorization|null
  //eligibility: Eligibility
  documentation: Documentation|null
  fiscality: Fiscality|null
  generalMeeting: GeneralMeeting|null
  commission: Commission|null
  scales: Scale[]
  depositary: ProductDepositary|null
  netAssetValue: NetAssetValue|null
  childs: any
  unicia: string|null
  classification: string|null
  ribs: ProductBankInformation[]|null
  calcul: string|null,
  decimalStep: number
}

class Product implements ProductInterface{
  public id: string|null
  public createdAt: string|null
  public updatedAt: string|null
  public deletedAt: string|null
  public label: string|null
  public productType: ProductType|null
  public status: string|null
  public generalInformation: GeneralInformation|null
  public valorisation: Valorisation|null
  public distribution: Distribution|null
  public withdraw: Withdrawal|null
  public accounting: Accounting|null
  public usage: Usage|null
  public categorization: Categorization|null
  //public eligibility: Eligibility
  public documentation: Documentation|null
  public fiscality: Fiscality|null
  public generalMeeting: GeneralMeeting|null
  public commission: Commission|null
  public scales: Scale[]
  public depositary: ProductDepositary|null
  public netAssetValue: NetAssetValue|null
  public childs: any
  public unicia: string|null
  public classification: string|null
  public ribs: ProductBankInformation[]|null
  public calcul: string|null
  public decimalStep: number

  constructor(
    id: string|null,
    createdAt: string|null,
    updatedAt: string|null,
    deletedAt: string|null,
    label: string|null,
    productType: ProductType|null,
    status: string|null,
    generalInformation: GeneralInformation|null,
    valorisation: Valorisation|null,
    distribution: Distribution|null,
    withdraw: Withdrawal|null,
    accounting: Accounting|null,
    usage: Usage|null,
    categorization: Categorization|null,
    //eligibility: Eligibility,
    documentation: Documentation|null,
    fiscality: Fiscality|null,
    generalMeeting: GeneralMeeting|null,
    commission: Commission|null,
    scales: Scale[],
    depositary: ProductDepositary|null,
    netAssetValue: NetAssetValue|null,
    childs: any,
    unicia: string|null,
    classification: string|null,
    ribs: ProductBankInformation[]|null,
    calcul: string|null,
    decimalStep: number
  ) {
    this.id = id
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.deletedAt = deletedAt
    this.label = label
    this.productType = productType
    this.status = status
    this.generalInformation = generalInformation
    this.valorisation = valorisation
    this.distribution = distribution
    this.withdraw = withdraw
    this.accounting = accounting
    this.usage = usage
    this.categorization = categorization
    //this.eligibility = eligibility
    this.documentation = documentation
    this.fiscality = fiscality
    this.generalMeeting = generalMeeting
    this.commission = commission
    this.scales = scales
    this.depositary = depositary
    this.netAssetValue = netAssetValue
    this.childs = childs
    this.unicia = unicia
    this.classification = classification
    this.ribs = ribs
    this.calcul = calcul
    this.decimalStep = decimalStep
  }
}

export {
  Product
}
export type { ProductInterface }
