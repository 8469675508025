import Caller from '../../domain/Api/Caller';
import envVariable from '../../infrastructure/presentation/util/envVariable';
import {
  ReferentielInterface
} from '../../domain/Referentiel/ReferentielInterface';
import { City, CityInterface } from '../../domain/Referentiel/City/City';
import { Country } from '../../domain/Referentiel/Country/Country';
import { ReferentielUser } from '../../domain/Referentiel/ReferentielUser';

export default class ReferentielGateway {

  getCountry(search: string|null): Promise<Country[]|[]> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/countries/${search}`, {})
    .then(data => {
      if (data && data.data) {
        return data.data;
      }

      return [];
    })
    .catch(() => {
      return [];
    });
  }

  getCity(search: string|null): Promise<City[]|[]> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/cities/${search}`, {})
    .then(data => {
      if (data && data.data) {
        return data.data;
      }

      return [];
    })
    .catch(() => {
      return [];
    });
  }

  getCities(zip: string, countryId: string): Promise<City[]|[]> {
    const zipPart = zip.length > 0 ? `&postcode=${zip}` : ''
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/reference/cities?country=${countryId}${zipPart}`, {})
      .then(data => {
        if (data) {
          return data;
        }
        return []
      })
      .catch(() => {
        return []
      })
  }

  addForeignCity(city: CityInterface, countryId: string): Promise<CityInterface|null|string> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/reference/city`, {
      "code": city.code,
      "label": city.label.toUpperCase(),
      "department": city.dept,
      "country": countryId,
      "postcode": city.postcode ?? ''
    })
      .then(data => {
        if (data) {
          if(data.error) {
            return data.error
          }
          return data
        }

        return null
      })
      .catch(err => {
        return null
      })
  }

  getCityFromPostalCode(postCode: string|null): Promise<City[]|[]> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/postcode/${postCode}`, {})
      .then(data => {
        if (data && data.data) {
          return data.data;
        }

        return [];
      })
      .catch(() => {
        return [];
      });
  }

  getGlobal(): Promise<ReferentielInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/references`, {})
      .then(data => {
        if (data) {
          return data;
        }

        return null;
      })
      .catch(() => {
        return null;
      });
  }

  getUsers(): Promise<ReferentielUser[]|[]> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/users`, {"all": true})
        .then(data => {
          if (data && data.data) {
            return data.data.map((user: ReferentielUser) => {
              return {
                id: user.user_id,
                label: `${user.prenom} ${user.nom}`,
                name: user.nom,
                firstname: user.prenom,
                email: user.email
              }
            });
          }

          return [];
        })
        .catch(() => {
          return [];
        });
  }
}
