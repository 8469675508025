import React, {FunctionComponent, useEffect, useState} from 'react';
import {setOpenChoicePartner,} from "../../../../../store/component/event";
import iconAdd from "../../../../../../assets/images/icons/add.svg";
import {registerLocale} from "react-datepicker";
import iconEdit from "../../../../../../assets/images/icons/datalist-edit.svg";
import {
  ReferentielInterface
} from '../../../../../../domain/Referentiel/ReferentielInterface'
import {PartnerInterface} from "../../../../../../domain/Partner/Partner";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../../store/hook";
import {useForm} from "react-hook-form";
import iconRemove from "../../../../../../assets/images/icons/remove.svg";
import PortfolioTransfertDatalist from "../../../Datalist/PortfolioTransfertDatalist";
import MultiSelectCustom from "../../../Elements/MultiSelect";
import PartnerGateway from "../../../../../../gateway/Partner/PartnerGateway";
import {setPartnerTransfertTarget} from "../../../../../store/component/partner";
import {ListReferentielPartner} from "../../../../../../fixtures/Referentiel";
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import TransferHistoryDatalist from "../../../Datalist/TransferHistoryDatalist";
import {TransferObjectSubmit} from "../../../../../../domain/Partner/Transfer";
import {dateToString} from "../../../../../transformer/StringDateToDateObject/StringDateToDateObject";
import fr from 'date-fns/locale/fr'
import {toastError, toastSuccess} from "../../../../util/Toast";
import {IPagination} from "../../../../../../domain/Pagination/IPagination";
import InputDate from "../../../Elements/InputDate";

registerLocale('fr', fr)

interface IFormInput {
  transfer: string | null
  transferredAt: string | null
  selectedInvestors: any[]
  partnerCibleTransfer: string | null

}

type Props = {
  referential: ReferentielInterface
  partner?: PartnerInterface
  isLectureMode?: boolean
}

const Transfert: FunctionComponent<Props> = ({partner, isLectureMode, referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const Partner = useAppSelector((state) => state.partner)

  const [addedTransfert, setAddedTransfert] = useState<boolean>(false)
  const [movementLinkedToPartner, setMovementLinkedToPartner] = useState<any>(null)
  const [investorsList, setInvestorList] = useState<any[] | []>([])
  const [historyTransfer, setHistoryTransfer] = useState<any>(null)

  const preferences = localStorage.getItem("preferences")

  const [pagination, setPagination] = useState<IPagination>({
    nbElements: 0,
    itemPerPage: preferences ? JSON.parse(preferences).numberRows : 50,
    page: 1
  })

  const [paginationHistory, setPaginationHistory] = useState<IPagination>({
    nbElements: 0,
    itemPerPage: preferences ? parseInt(JSON.parse(preferences).numberRows, 10) : 50,
    page: 1
  })

  const {register, control, handleSubmit, setValue, getValues} = useForm<IFormInput>()

  const watchSelectedInvestors = getValues('selectedInvestors')

  useEffect(() => {
    const socialReason = Partner.partnerTransfertTarget?.socialReason ?? ""
    const partnerCode = Partner.partnerTransfertTarget?.partnerCode ?? ""
    const status = Partner.partnerTransfertTarget?.status ?? ""
    const string = getPartnerTransferStringFromPartner(socialReason, partnerCode, status)
    setValue("partnerCibleTransfer", string)

    if (partner?.id) {
      new PartnerGateway().getInvestorList(partner.id).then(it => {
        const newInvestoList: any[] = []
        for (const [key, values] of Object.entries(it)) {
          const invest = values as any
          newInvestoList.push(
            {
              value: {...invest, id: key}, label: invest.firstname + ' ' + invest.lastname
            }
          )
        }
        setInvestorList(newInvestoList)
      }).catch((e) => console.error('erreur', e))

    }
  }, [Partner.partnerTransfertTarget])

  useEffect(() => {
    if (partner?.id) {
      new PartnerGateway()
        .getTransferHistoryLinkedToPartner(
          partner.id,
          paginationHistory.itemPerPage,
          paginationHistory.page)
        .then(data => {
          setHistoryTransfer(data.data)
          setPaginationHistory({
            nbElements: data.nb_element,
            itemPerPage: data.nb_element_per_page,
            page: parseInt(data.current_page, 10)
          })
        })
    }
  }, [paginationHistory.page, paginationHistory.itemPerPage])

  useEffect(() => {
    if (watchSelectedInvestors) {
      searchMovements(watchSelectedInvestors)
    }
  }, [pagination.page, pagination.itemPerPage])

  const getPartnerTransferStringFromPartner = (socialReason: string, partnerCode: string, status: string) => {
    let string = ""
    if (socialReason) {
      string = string.concat(socialReason)
    }
    if (partnerCode) {
      string = string.concat(" - " + partnerCode)
    }
    if (status) {
      string = string.concat(" - " + ListReferentielPartner.partner.status.find(s => s.value === status)?.label)
    }
    return string
  }

  const searchMovements = (selectedInvestors: any[]) => {
    if (partner?.id) {
      if (selectedInvestors.length && selectedInvestors.includes('selectAll')) {
        new PartnerGateway().getAllAccountMovementLinkedToPartner(partner.id, pagination.itemPerPage, pagination.page).then(it => {
          setPagination({
            nbElements: it.nb_element,
            itemPerPage: it.nb_element_per_page,
            page: parseInt(it.current_page, 10)
          })
          setMovementLinkedToPartner(it)
        })
      } else if (selectedInvestors.length) {
        new PartnerGateway().getMovementLinkedToPartner(partner.id, selectedInvestors, pagination.itemPerPage, pagination.page).then(it => {
          setPagination({
            nbElements: it.nb_element,
            itemPerPage: it.nb_element_per_page,
            page: parseInt(it.current_page, 10)
          })
          setMovementLinkedToPartner(it)
        })
      } else {
        setPagination({
          ...pagination,
          nbElements: 0,
          page: 1
        })
        setMovementLinkedToPartner(null)
      }
    }
  }

  const removePartner = (): void => {
    dispatch(setPartnerTransfertTarget(null))
    setValue("partnerCibleTransfer", null)
  }

  const onSubmitSelection = (data: any) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal message={t('partner.form.transfer.confirmation-selection')}
                                   onConfirm={() => onConfirmSelection(data)}
                                   onClose={onClose}/>)
      }
    });
  }

  const onConfirmSelection = (data: any) => {
    if (!data.transferredAt) {
      toastError(t('partner.form.transfer.notify.error-date'));
    } else if (partner?.id) {
      const transferObj: TransferObjectSubmit = {
        target: Partner?.partnerTransfertTarget?.id || "",
        date: data.transferredAt || "",
        accounts: getValues('selectedInvestors').includes('selectAll') ? [] : getValues('selectedInvestors'),
        all: !!getValues('selectedInvestors').includes('selectAll')
      }
      new PartnerGateway().postTransfer(partner.id, transferObj)
        .then(data => {
          toastSuccess(t('partner.form.transfer.notify.success'));
        })
        .then(() => new PartnerGateway()
          .getTransferHistoryLinkedToPartner(partner?.id ?? "",
            paginationHistory.itemPerPage,
            paginationHistory.page)
          .then(data => {
            setHistoryTransfer(data.data)
          }))
        .catch((e) => console.error('error', e))
      dispatch(setPartnerTransfertTarget(null))
      setValue("partnerCibleTransfer", null)
      setValue('selectedInvestors', [])
      setValue('transferredAt', null)
      setMovementLinkedToPartner(null)
    }
  }

  return (
    <div>
      <div className={`form-bloc`}>
        <div className="form-bloc__title">{t('partner.form.transfer.creation')}</div>
        {!isLectureMode && <div className="form-bloc__shortcut">
            <button type="button" className="button-reset u-txt-with-icon"
                    onClick={() => setAddedTransfert(true)}
            >
                <img src={iconAdd} alt=""/>
              {t('partner.form.transfer.add')}
            </button>
        </div>}

        {
          addedTransfert && (
            <>
              <div className="form-bloc__title">{t('partner.form.transfer.transfer-details')}</div>
              <div className="form-bloc__form flex-container">
                <div className="col-md-6">
                  <div className="form-control">
                    <label htmlFor="transferredAt"
                           className="form-control__label mandatory-field">{t('partner.form.transfer.transfer-date')}</label>
                    <div className="form-control__input">
                      <InputDate id="transferredAt"
                                 name="transferredAt"
                                 register={register}
                                 control={control}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-control">
                    <label htmlFor="partnerCibleTransfer" className="form-control__label">
                      {t('partner.form.transfer.partner-cible-transfer')}

                    </label>
                    <div className="form-control__input flex">
                      <input type="text" disabled={true} {...register("partnerCibleTransfer")}/>
                      <button type="button"
                              className="button-reset u-mxs"
                              onClick={() => dispatch(setOpenChoicePartner({
                                show: true,
                                excludeHimSelf: true,
                                partnerId: partner?.id
                              }))}
                              disabled={isLectureMode}
                      >
                        <img src={iconEdit} alt="Edit"/>
                      </button>
                      {Partner.partnerTransfertTarget &&
                          <button type="button"
                                  className="button-reset u-mxs"
                                  onClick={removePartner}
                                  disabled={isLectureMode}>
                              <img src={iconRemove} alt="Remove"/>
                          </button>
                      }
                    </div>
                  </div>
                </div>
                {Partner.partnerTransfertTarget &&
                    <form onSubmit={handleSubmit(onSubmitSelection)} className="flex-container u-prs u-pxs w-full">
                        <div className="col-md-12 u-txt-bold">
                          {t("partner.form.transfer.movement-selection")}
                        </div>
                        <div className="col-md-12 u-txt">
                          {t("partner.form.transfer.search-header")}
                        </div>
                        <div className="col-md-6">
                            <MultiSelectCustom
                                id="selectedInvestors"
                                name="selectedInvestors"
                                classes="w-100"
                                control={control}
                                label={t("partner.form.transfer.investor-multiselect-label")}
                                options={investorsList}
                                customOnChange={(options: any[]) => {
                                  if (options.find((opt) => opt.value === "selectAll")) {
                                    setValue('selectedInvestors', options.map(it => it.value))
                                    searchMovements(options.map(it => it.value))
                                  } else {
                                    setValue('selectedInvestors', options.map(it => it.value.id))
                                    searchMovements(options.map(it => it.value.id))
                                  }
                                }}
                                selectAllOption={investorsList?.length > 1}
                                selectAllOptionLabel={t('partner.form.transfer.investor-multiselect-select-all-label')}
                            />

                        </div>
                        <div className="col-12 overflow-hidden">
                            <PortfolioTransfertDatalist movementLinkedToPartner={movementLinkedToPartner}
                                                        pagination={pagination}
                                                        setPagination={setPagination}
                                                        partnerId={null}
                                                        transfertId={null}
                            />
                        </div>
                      {movementLinkedToPartner?.data?.length > 0 && <div className="col-12">
                          <button className="button button--submit" type="submit">
                            {t('partner.form.transfer.confirm')}
                          </button>
                      </div>}
                    </form>}
              </div>
            </>
          )
        }
      </div>
      <div className={`form-bloc`}>
        <TransferHistoryDatalist historyTransfers={historyTransfer}
                                 partnerId={partner?.id || ''}
                                 isLectureMode={isLectureMode}
                                 pagination={paginationHistory}
                                 setPagination={setPaginationHistory}
        />
      </div>
    </div>
  );
}

export default Transfert;
