import React, {FunctionComponent, useEffect, useState} from 'react';
import {Controller, FieldErrors} from 'react-hook-form';
import Select, {StylesConfig} from 'react-select';

import ReferentielGateway from '../../../../../gateway/Referentiel/ReferentielGateway';
import {City} from '../../../../../domain/Referentiel/City/City';
import {useTranslation} from 'react-i18next';
import Input from '../../Elements/Input';
import {Country} from '../../../../../domain/Referentiel/Country/Country';
import iconAdd from '../../../../../assets/images/icons/add.svg';
import {useAppDispatch, useAppSelector} from '../../../../store/hook';
import {setOpenCityForm} from '../../../../store/component/event';
import ReactTooltip from 'react-tooltip';
import useDebounce from "../../../../../domain/CustomHooks/useDebounce";
import iconMark from "../../../../../assets/images/icons/mark-blue.svg";
import {TargetPropertyInObjWithStringPath} from "../../../util/TargetPropertyInObjWithStringPath";

type Props = {
  control: any
  nameCity: string
  namePostcode: string
  country: Country | null
  register: any
  setValue: any
  getValues: any
  mode?: 'col' | 'row'
  disabled?: boolean
  classes?: string
  cityMandatory?: boolean
  cityIsOnError?: boolean
  postCodeMandatory?: boolean
  postCodeIsOnError?: boolean
  menuPortalTarget?: boolean
  errors?: FieldErrors
}

const defaultCustomStyles: StylesConfig = {
  container: (base: any) => ({
    ...base,
    width: '100%'
  }),
  control: (base: any) => ({
    ...base,
    minHeight: 25,
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: 4,
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 4,
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: '0px 6px',
  }),
  input: (base: any) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isFocused && '#DEEBFF',
    color: 'black',
  }),
};


const CityAndPostcode: FunctionComponent<Props> = ({
                                                     control,
                                                     nameCity,
                                                     namePostcode,
                                                     country,
                                                     disabled,
                                                     register,
                                                     setValue,
                                                     getValues,
                                                     mode,
                                                     classes,
                                                     cityMandatory,
                                                     cityIsOnError,
                                                     postCodeMandatory,
                                                     postCodeIsOnError,
                                                     menuPortalTarget,
                                                     errors
                                                   }) => {

  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const [cityArray, setCityArray] = useState<City[]>([]);
  const [customStyles, setCustomStyles] = useState<any>(defaultCustomStyles)
  const referentielGateway = new ReferentielGateway();
  const [isCityReadOnly, setIsCityReadOnly] = useState(!!getValues(namePostcode) ?? disabled);
  const [displayCta, setDisplayCta] = useState<boolean>(false);
  const openCityForm = useAppSelector((state) => state.event.openCityForm);

  const [inputValue, setInputValue] = useState<string>('')
  const debounceValue = useDebounce<string>(inputValue, 500)

  useEffect(() => {
    if (!country) {
      setValue(nameCity, null)
      setValue(namePostcode, null)
      setCityArray([])
      setIsCityReadOnly(true)
    }
    if (country) {
      if (country.label.toLowerCase() === 'france') {
        setDisplayCta(false);
      } else {
        setDisplayCta(true)
      }
    }
  }, [country]);

  useEffect(() => {
    if (getValues(nameCity)) {
      setCityArray([getValues(nameCity)])
    }
  }, [getValues(nameCity)]);

  useEffect(() => {
    if (cityIsOnError) {
      setCustomStyles({
        container: (base: any) => ({
          ...base,
          width: '100%'
        }),
        control: (base: any) => ({
          ...base,
          minHeight: 25,
          borderBottom: '1px solid #A6440C',
        }),
        dropdownIndicator: (base: any) => ({
          ...base,
          padding: 4
        }),
        clearIndicator: (base: any) => ({
          ...base,
          padding: 4
        }),
        valueContainer: (base: any) => ({
          ...base,
          padding: '0px 6px'
        }),
        input: (base: any) => ({
          ...base,
          margin: 0,
          padding: 0
        })
      })
    } else {
      setCustomStyles(defaultCustomStyles)
    }
  }, [cityIsOnError])

  useEffect(() => {
    if (openCityForm && openCityForm.show === false && openCityForm.postcode && country) {
      referentielGateway.getCities(openCityForm.postcode, country.id).then((response) => {
        setCityArray(response)
        setValue(namePostcode, openCityForm.postcode)
        if (response.length === 1) {
          setValue(nameCity, response[0])
        }
      });
    }
  }, [openCityForm])

  useEffect(() => {
    if (debounceValue) {
      handleChange(inputValue)
    }
  }, [debounceValue])

  function updateCityState(zip: string) {
    setValue(nameCity, null)
    if (zip === '') {
      setIsCityReadOnly(true)
    } else {
      setIsCityReadOnly(false)
    }
  }

  function handleChange(value: string) {
    updateCityState(value);
    if (value.length > 3 && country) {
      referentielGateway.getCities(value, country.id).then((response) => {
        setCityArray(response);
        if (response.length === 1) {
          setValue(nameCity, response[0])
        }
      });
    }
  }

  const onChangeSelect = (option: City[]) => {
    setValue(nameCity, option);
  };

  return (
    <div className={classes}>
      <div className="col-md-12">
        <div className="form-control">
          <div className="flex items-end">
            <ReactTooltip/>
            <label htmlFor={namePostcode}
                   className={`form-control__label ${postCodeMandatory ? 'mandatory-field' : ''}`}>
              {t('account.form.address.postcode')}
            </label>
            <div
              data-tip={"Si le pays de l’adresse est à l'étranger, vous devez d’abord sélectionner le pays avant de saisir un code postal."}>
              <img src={iconMark} className="mark-tooltip" alt="help mark"/>
            </div>
          </div>

          <div className="form-control__input">
            <input type="text"
                   id={namePostcode}
                   name={namePostcode}
                   className={postCodeIsOnError ? 'field-in-error' : ''}
                   defaultValue={getValues(namePostcode)}
                   {...register(namePostcode, {
                     onChange: (e: any) => setInputValue(e.currentTarget.value),
                     required: t('error.required-field')
                   })}
                   disabled={disabled || !country}

            />
            {errors && TargetPropertyInObjWithStringPath(namePostcode, errors) &&
                <p className="input-error-msg">{TargetPropertyInObjWithStringPath(namePostcode, errors)?.message}</p>}
          </div>
        </div>
      </div>
      <div className={mode && mode === 'col' ? 'col-md-12' : 'col-md-6'}>
        <div className="form-control">
          <div className="flex justify-between items-baseline">

            <label htmlFor="city"
                   className={cityMandatory ? 'form-control__label mandatory-field' : 'form-control__label'}>
              {t('account.form.address.city')}
            </label>

            {displayCta && !disabled &&
                <button type="button" className="button-reset"
                        onClick={() => dispatch(setOpenCityForm({
                          show: true,
                          postcode: getValues(namePostcode),
                          country: country
                        }))}>
                    <img src={iconAdd} alt="add" title="Ajouter une ville"/>
                </button>
            }
          </div>
          <Controller
            name={nameCity}
            control={control}
            rules={{
              required: t('error.required-field') as string
            }}
            render={({
                       field: {onChange, value, name},
                     }) => {
              return (
                <div className="flex flex-col items-baseline">
                  <Select
                    name={name}
                    defaultValue={getValues(nameCity)}
                    options={cityArray}
                    onChange={(option) => onChangeSelect(option)}
                    styles={customStyles}
                    isClearable={true}
                    isDisabled={isCityReadOnly || false}
                    value={value}
                    menuPortalTarget={menuPortalTarget ? document.body : null}
                    aria-label={'city'}
                  />
                  {errors && TargetPropertyInObjWithStringPath(nameCity, errors) &&
                      <p className="input-error-msg">{TargetPropertyInObjWithStringPath(nameCity, errors)?.message}</p>}
                </div>
              );
            }
            }
          />
        </div>
      </div>
    </div>

  );
};

export default CityAndPostcode;

