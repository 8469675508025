import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import { setOpenCityForm } from '../../../../store/component/event';
import blockScrollBody from '../../../util/BlockScroll';
import getClassForOverlay from '../../../util/Sidebar';
import { FormProvider, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import ConfirmationModal from '../../Modal/ConfirmationModal';
import { confirmAlert } from 'react-confirm-alert';
import { Country } from '../../../../../domain/Referentiel/Country/Country';
import { City } from '../../../../../domain/Referentiel/City/City';
import { usePrompt } from '../../../util/Navigation';
import ReferentielGateway from '../../../../../gateway/Referentiel/ReferentielGateway';
import { toastError, toastSuccess } from '../../../util/Toast';

interface IFormInput {
  label?: string,
  department?: string,
  postcode?: string
  code?: string
}

const AddCityForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const methods = useForm();

  const referentielGateway = new ReferentielGateway();
  const openCityForm = useAppSelector((state) => state.event.openCityForm);
  const { register, control, watch, handleSubmit, reset, setValue, getValues } = useForm<IFormInput>();
  const [country, setCountry] = useState<Country | null>(null);


  useEffect(() => {
    blockScrollBody(openCityForm.show);
    setValue('postcode', openCityForm.postcode ?? '');
    setValue('label', openCityForm.label ?? '');
    setValue('department', '99');
    setValue('code', '99999')
    setCountry(openCityForm.country ?? null);
  }, [openCityForm]);

  const handleClose = function() {
    dispatch(setOpenCityForm({ show: false, postcode: getValues('postcode'), label: getValues('label') }));
  };

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (<ConfirmationModal onConfirm={ () => onConfirm(data) } onClose={ onClose }/>);
      },
    });
  };
  const onConfirm = (data: any) => {
    const city = new City(
      data.code,
      data.department ?? '99',
      null,
      data.label,
      data.postcode
    );
    if(city && country) {
      referentielGateway.addForeignCity(city, country.id).then((response) => {
        if (response) {
          if(typeof response === "string" && response.includes("23505")) {
            toastError("Une erreur est survenue: le code saisi est déjà utilisé, veuillez le changer pour enregistrer la nouvelle ville.")
          } else {
            toastSuccess(t('common.modal.validation-message'))
            handleClose()
          }
        }
      })
        .catch((e) => {
          console.log(e)
        })
    }
    return null;
  };

  const { isDirty } = useFormState({
    control,
  });
  usePrompt(isDirty, handleSubmit(onConfirm));


  return (
    <>
      <div className={ `overlay ${ getClassForOverlay(openCityForm.show) }` } onClick={ () => handleClose() }/>
      <div className={ `sidebar sidebar--right sidebar--right ${ openCityForm.show ? 'sidebar--active' : '' }` }>
        <FormProvider { ...methods }>
          <form onSubmit={ handleSubmit(onSubmit) } className="form-bloc">
            <div className="sidebar__content">
              <div className="title">Ajouter une nouvelle ville</div>
              <div className="form-bloc__form flex-container">
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="postcode" className="form-control__label">
                      Code postal
                    </label>
                    <div className="form-control__input">
                      <input type="text" { ...register('postcode') }/>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="label" className="form-control__label">
                      Nom de la ville
                    </label>
                    <div className="form-control__input">
                      <input type="text" { ...register('label') }/>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="department" className="form-control__label">
                      Département
                    </label>
                    <div className="form-control__input">
                      <input type="text" { ...register('department') }/>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="department" className="form-control__label">
                      Pays
                    </label>
                    <div className="form-control__input">
                      <input type="text" value={ country?.label } disabled/>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="code" className="form-control__label">
                      Code
                    </label>
                    <div className="form-control__input">
                      <input type="code" { ...register('code') }/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer className="sidebar__footer">
              <button type="submit" className="button button--ink-2 u-mrm">{ t('common.save') }</button>
              <button type="button" className="button button--ink-2 button--ink-2--outline"
                      onClick={ () => handleClose() }>{ t('common.cancel') }</button>
            </footer>
          </form>
        </FormProvider>
      </div>
    </>
  );
};
export default AddCityForm;
