import React, { FunctionComponent, useState } from 'react'
import { v4 as uuidV4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import ButtonDownloadStyled from '../../StyledComponents/ButtonDownloadStyled'
import Folder from '../../../../../assets/images/icons/Folder'

type TProps = {
  title: string
  register: any
  control: any
  editionArray: any
  parentPath: string
  classes?: string
}

const EditionAndDownload: FunctionComponent<TProps> = ({
                                                         editionArray,
                                                         classes,
                                                         title
                                                       }) => {

  const { t } = useTranslation()
  const [click, setClick] = useState<boolean>(false)

  const [state, setState] = useState<'error' | 'success' | ''>("")

  const waitAndFail = (duration: number) => {
    setClick(c => !c)
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(duration)
        setState('success')
      }, duration)
    })
  }

  return (
    <div className={classes}>
      <div className='form-bloc form-bloc--action'>
        <div className='form-bloc__title'>{title}</div>
        <div className='form-bloc__form flex-container'>
          {editionArray && Object.entries(editionArray).map((action: [string, any]) => {
            return (
              <div key={uuidV4()} className={`col-md-4 relative`}>
                <ButtonDownloadStyled onClick={() => waitAndFail(2000)}
                                      clicked={click}
                                      state={state}
                                      label={t(`commissioning.form.setting.actions-editions.${action[0]}`)}
                                      btnName={action[0] || ""}
                                      icon={<Folder width={14} height={30} />}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default EditionAndDownload