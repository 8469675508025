import React, {FunctionComponent, useCallback, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {SubmitHandler, useForm, useFormState} from 'react-hook-form'
import {ProductInterface} from '../../../../../../domain/Product/Product'
import {
  CreateNetAssetValueItemInterface
} from '../../../../../../domain/Product/NetAssetValueItem'
import { ReferentielInterface } from '../../../../../../domain/Referentiel/ReferentielInterface'
import SelectCustom from '../../../Elements/Select'
import Input from '../../../Elements/Input'
import InputDate from '../../../Elements/InputDate'
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {usePrompt} from "../../../../util/Navigation";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import envVariable from "../../../../util/envVariable";
import NetAssetDatalist from "../../../Datalist/Product/NetAssetDatalist";
import NetAssetGateway from "../../../../../../gateway/Product/NetAsset/NetAssetGateway";

type Props = {
  product: ProductInterface
  referential: ReferentielInterface
  isLectureMode?: boolean
}

interface IFormInput {
  netAssetValueItem: CreateNetAssetValueItemInterface
}

const NetAssetValue: FunctionComponent<Props> = ({product, isLectureMode, referential}) => {
  const {t} = useTranslation()
  const netAssetListRef = useRef<any>()
  const defaultFormValue = {
    netAssetValueItem: {
      publishedAt: undefined,
      value: undefined,
      start: undefined,
      end: undefined
    }
  }

  const {register, control, handleSubmit, reset} = useForm<IFormInput>({
    defaultValues: defaultFormValue
  })

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }

  const onConfirm = (data: any) => {
    if (data.netAssetValueItem && product?.id) {
      new NetAssetGateway().add(product.id, data.netAssetValueItem).then(response => {
        if (response?.id) {
          reset(defaultFormValue)
          t('product.notify.update-success')
          netAssetListRef?.current?.reload();
        }
      })
    }
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));


  const renderFooter = () => {
    if(isLectureMode) return null
    return <footer className={`form-bloc__footer`}>
      <button type="submit" className="button button--ink-2">{t('common.save')}</button>
      <button type="button" className="button button--ink-2 button--ink-2--outline">{t('common.cancel')}</button>
    </footer>
  }

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
      <div className={`form-bloc form--bloc--net-asset-value`}>
        <div className="form-bloc__title">{t('product.form.netAssetValue.title')}</div>
        <p>{t('product.form.netAssetValue.add')}</p>
        <div className="form-bloc__form flex-container">
          <SelectCustom id="netAssetValue.periodicity" name="netAssetValue.periodicity"
                        classes="col-md-6"
                        register={register} label={t('product.form.netAssetValue.periodicity')}
                        options={referential?.product.periodicity || null}/>
          <div className="col-md-6"></div>
          <table className="col-md-6">
            <thead>
            <tr>
              <th className="form-control__label u-txt-left">{t('product.form.netAssetValue.puslibshedAt')}</th>
              <th className="form-control__label u-txt-left">{t('product.form.netAssetValue.value')}</th>
              <th className="form-control__label u-txt-left">{t('product.form.netAssetValue.startDate')}</th>
              <th className="form-control__label u-txt-left">{t('product.form.netAssetValue.startEnd')}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <InputDate
                  id={"publishedAt"}
                  register={register}
                  control={control}
                  type={'text'}
                  name={'netAssetValueItem.publishedAt'}
                />
              </td>
              <td>
                <Input type={'number'}
                       step={1/Math.pow(10, Number(envVariable('REACT_APP_COMMA_NUMBER')))}
                       name={'netAssetValueItem.value'}
                       id={"value"}
                       register={register}
                />
              </td>
              <td>
                <InputDate
                  id={"start"}
                  register={register}
                  control={control}
                  type={'text'}
                  name={'netAssetValueItem.start'}
                />
              </td>
              <td>
                <InputDate
                  id={"end"}
                  register={register}
                  control={control}
                  type={'text'}
                  name={'netAssetValueItem.end'}
                />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className={`form-bloc form--bloc--net-asset-value`}>
        <div className="form-bloc__title">{t('product.form.netAssetValue.history')}</div>
        <div className="form-bloc__form flex-container">
          <NetAssetDatalist ref={netAssetListRef} productId={product?.id ? product.id : ''} />
        </div>
      </div>
      {renderFooter()}
    </form>
  )
}

export default NetAssetValue
