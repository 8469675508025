import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {SubmitHandler, useForm} from 'react-hook-form';
import { v4 as uuidV4 } from 'uuid';

import { useAppDispatch, useAppSelector } from '../../../store/hook';
import {
    setOpenDatalistFilterPartner,
    setOpenDatalistFilterProspect,
    setOpenDatalistFilterUser,
    setOpenUserForm
} from '../../../store/component/event';
import Pagination from '../Pagination/Pagination';
import download from '../../util/Download';

import '../../../../assets/styles/components/_datalist.scss';
import iconAdd from '../../../../assets/images/icons/add.svg';
import UserListPresenter from '../../../../presenter/User/UserListPresenter';
import { UserListInterface } from '../../../../domain/User/UserList';
import UserGateway from '../../../../gateway/User/UserGateway';
import { setCountTotal } from '../../../store/component/user';
import { getIconForStatus } from '../../util/getState';
import iconEdit from '../../../../assets/images/icons/datalist-edit.svg';
import { RoleInterface } from '../../../../domain/Roles/Roles';
import RoleGateway from '../../../../gateway/Role/RoleGateway';
import HeaderRight from "./Element/HeaderRight";
import {optionsNbRows} from "../../../../fixtures/Referentiel";
import SelectCustom from "../Elements/Select";
import {saveNbRowsInLocalStorage} from "../../util/SavePreferencesInLocalStorage";
import iconHeadingSearch from "../../../../assets/images/icons/datalist-heading-search.svg";
import {FilterPartnerInterface} from "../../../../domain/Partner/PartnerList";

interface ViewModelInterface {
    title: string;
    heading: [];
    data: [];
    filtersShortcut: [];
    filters: [];
    pagination: PaginationInterface;
    count: number;
}

interface PaginationInterface {
    itemsPerPage: number;
    numberOfItems: number;
}

type Props = {
    callback?: (user: UserListInterface) => void
}

const UserDatalist: FunctionComponent<Props> = ({ callback }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const openDatalistFilterUser = useAppSelector((state) => state.event.openDatalistFilterUser);
    const userRules = useAppSelector((state) => state.me.me?.rules.user.actions)
    const openUserForm = useAppSelector((state) => state.event.openUserForm);
    const [viewModel, setViewModel] = useState<ViewModelInterface | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [users, setUsers] = useState<{ data: UserListInterface[] | null, numberOfItems: number } | null>(null);
    const [isLoadingExport, setLoadingExport] = useState<boolean>(false);
    const [allRoleList, setAllRoleList] = useState<RoleInterface[]>([]);

    const controller = new AbortController()
    const preferences = localStorage.getItem("preferences")

    const { register, control, watch, handleSubmit, reset, setValue } = useForm();

    const watchNumberRows = watch('numberRows')

    useEffect(() => {
        (new RoleGateway()).getList().then(response => {
            if (response) {
                setAllRoleList(response.data);
            }
        });
    }, []);

    /* useEffect(() => {
        if (openUserForm.user && !openUserForm.show) {
            (new UserGateway()).getList(currentPage, viewModel?.pagination.itemsPerPage || 5, '', openDatalistFilterUser.filters).then(response => {
                setUsers(response);
            });
        }
    },[openUserForm]); */

    useEffect(() => {
        const presenter = new UserListPresenter(users);
        presenter.load().then(() => {
            setViewModel(presenter.immutableViewModel());
            dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems));
        });
    }, [users]);

    useEffect(() => {
        if(!watchNumberRows) {
            setValue("numberRows", preferences ? JSON.parse(preferences).numberRows : 50)
        } else {
            saveNbRowsInLocalStorage(preferences, watchNumberRows)
            new UserGateway().getList(currentPage, watchNumberRows || 5, '', openDatalistFilterUser.filters).then(response => {
                setUsers(response);
            });
        }

    }, [currentPage, openDatalistFilterUser.filters, watchNumberRows, openUserForm]);

    useEffect(() => {
        setCurrentPage(1);
    }, [openDatalistFilterUser.filters]);


    const paginate = (pageNumber:number) => {controller.abort(); setCurrentPage(pageNumber)}

    const handleClickFilter = (response: string) => {
        if (response) {
            dispatch(setOpenDatalistFilterUser({
                show: true,
                count: openDatalistFilterUser.count,
                filters: openDatalistFilterUser.filters,
            }));
        }
    };

    const resetFilters = () => {
        dispatch(setOpenDatalistFilterUser({
              show: false,
              count: 0,
              filters: {
                  lastName: '',
                  maidenName: '',
                  keywords: '',
                  birthDate: '',
              }
          }
        ))
    }

    const handleClickExport = () => {
        setLoadingExport(true);
        new UserGateway().getExport(openDatalistFilterUser.filters).then(response => {
            if (response) {
                download(t('export.users'), response);
                setLoadingExport(false);
            }
        });
    };

    const onSubmit: SubmitHandler<FilterPartnerInterface> = data => {
        console.log("submit")
    }


    return (<>
        { (viewModel !== null &&
            <>
                <div className={ `datalist` }>
                    <div className="datalist__title">{ t(viewModel.title) }</div>
                    {userRules?.create && <div className="form-bloc__shortcut">
                        <button type="button" className="button-reset u-txt-with-icon"
                                onClick={() => dispatch(setOpenUserForm({show: true, user: null}))}>
                            <img src={iconAdd} alt=""/>
                            {t('settings.user-profiles.heading.add')}
                        </button>
                    </div>}
                    <div className="datalist__header">
                        <form onSubmit={handleSubmit(onSubmit)} className="filter">
                            <div className="filter__input">
                                {viewModel.filtersShortcut.map((filter: { keyword: string, field: string, type: string }) => (
                                  <div key={uuidV4()} className="input-no-border">
                                      <img src={iconHeadingSearch} alt="" />
                                      <input {...register(filter.field)} placeholder={t('common.search-by', {keyword: t(filter.keyword)})} className="u-mxs"/>
                                  </div>
                                ))}
                            </div>
                            <div className="filter__actions">
                                <button type="submit" className="button button--submit">{t('search.submit')}</button>
                                <button type="button" className="button button--white" onClick={() => resetFilters()}>{t('search.cancel')}</button>
                            </div>
                        </form>
                        <HeaderRight numberOfActivatedFilters={openDatalistFilterUser.count}
                                     handleClickFilter={handleClickFilter}
                                     handleClickExport={handleClickExport}
                                     isLoadingExport={isLoadingExport}
                                     allowExport={userRules?.export}
                        />
                    </div>
                    <SelectCustom classes="flex justify-end u-mbs" id="numberRows"
                                  name="numberRows"
                                  label={t('filters.display-results-by')}
                                  options={optionsNbRows}
                                  register={register}
                                  noChoiceOption
                    />
                    <div className="table-fix-head">
                    <table className="datalist__datas">
                        <thead>
                        <tr>
                            { viewModel.heading.map((title: string) => (
                                <th key={ uuidV4() }>{ t(title) }</th>
                            )) }
                        </tr>
                        </thead>
                        <tbody>
                        { viewModel.data !== undefined &&
                        viewModel.data.map((item: UserListInterface) => (
                            <tr key={ uuidV4() }>
                                <td>
                                    { userRules?.create && callback ?
                                      <button type="button" className="button-reset" onClick={ () => callback(item) }>
                                          <img src={ iconAdd } alt=""/>
                                      </button>
                                      : userRules?.update ?
                                        <button type="button" className="button-reset"
                                                onClick={ () => dispatch(setOpenUserForm({ show: true, user: item })) }>
                                            <img src={ iconEdit } alt=""/>
                                        </button> : <></>
                                    }

                                </td>
                                <td>{ item.prenom }</td>
                                <td>{ item.nom }</td>
                                <td>{ item.email }</td>
                                <td>
                                    { item.roles.map((roleId: string) => (
                                        <span key={ uuidV4() }>{ allRoleList.find(role => role.id == roleId)?.label } </span>
                                    )) }
                                </td>
                                <td>{ getIconForStatus(item.enabled) }</td>
                            </tr>
                        )) }
                        { viewModel.data === undefined || viewModel.data.length === 0 &&
                        <tr>
                            <td colSpan={ viewModel?.heading.length}>{ t('common.data-is-empty') }</td>
                        </tr>
                        }
                        </tbody>
                    </table>
                    </div>
                </div>
                <Pagination currentPage={ currentPage } itemsPerPage={ watchNumberRows || viewModel.pagination.itemsPerPage }
                            numberOfItems={ viewModel.pagination.numberOfItems } callback={ paginate }/>
            </>
        ) }
    </>);

};

export default UserDatalist;
