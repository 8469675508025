import React, {FunctionComponent} from "react";
import {ProductInterface} from "../../../../../../domain/Product/Product";
import {useTranslation} from "react-i18next";
import {setOpenProductScaleForm} from "../../../../../store/component/event";
import iconAdd from "../../../../../../assets/images/icons/add.svg";
import {useAppDispatch, useAppSelector} from "../../../../../store/hook";
import ScaleDatalist from "../../../Datalist/Product/ScaleDatalist";

type Props = {
  product: ProductInterface
  isLectureMode: boolean
}

const Scale: FunctionComponent<Props> = ({product, isLectureMode}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const scales = useAppSelector((state) => state.product.scales)

  return (
    <>
      <div className="flex-container">
        {!isLectureMode &&
          <div className="col-md-12">
            <div className="form-bloc">
              <div className="form-bloc__title">{t('product.form.scale.create')}</div>
              <div className="form-bloc__shortcut">
                <button type="button" className="button-reset u-txt-with-icon"
                        onClick={() => dispatch(setOpenProductScaleForm({show: true, scale: null, scales: scales, productId: product.id}))}>
                  <img src={iconAdd} alt=""/>
                  {t('product.form.scale.add')}
                </button>
              </div>
            </div>
          </div>
        }
        <div className="col-md-12 w-full">
          {product.id &&
          <div className="flex-container">
            <div className="col-md-12 w-full">
              <div className="form-bloc">
                <ScaleDatalist scales={scales} productId={product.id} isLectureMode={isLectureMode}/>
              </div>
            </div>
            <div className="col-md-12 w-full">
              <div className="form-bloc">
                  <ScaleDatalist productId={product.id} disabledAction />
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </>
  )
}

export default Scale
