import React, {FunctionComponent, FormEvent, InputHTMLAttributes} from 'react'
import {Controller} from 'react-hook-form'

import {IFormFieldProps} from './IFormFieldProps'
import {useTranslation} from 'react-i18next'

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  id: string
  name: string
  classes?: string
  value?: string
  defaultValue?: string
  onChange?: (e: FormEvent<HTMLInputElement>) => void
}

const RadioYesOrNot: FunctionComponent<IProps> = ({id, name, classes, label, onChange, value, defaultValue, register, error, required, ...rest}) => {
  const {t} = useTranslation()

  const radioYesOrNot = <div className="form-control form-control--label-auto">
    <div className="form-control__input">
      <label className="form-label form-label--input-radio">
        <input type="radio" value={"1"} {...register(name)} />
        {t('common.yes')}
      </label>
    </div>
    <div className="form-control__input">
      <label className="form-label form-label--input-radio">
        <input type="radio" value={"0"} {...register(name)} />
        {t('common.no')}
      </label>
    </div>
  </div>

  return (
    <>
      <div className={`${classes}`}>
        {label &&
          <div className="form-control">
            <label htmlFor={id} className="form-control__label">{label}</label>
            {radioYesOrNot}
          </div>
        }
        {!label &&
          <div className="form-control">
            {radioYesOrNot}
          </div>
        }
      </div>
    </>
  )
}

export default RadioYesOrNot
