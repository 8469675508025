import {FunctionComponent, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {setOpenChoiceSliceToGiveUpEvent} from '../../../../store/component/event'
import blockScrollBody from '../../../util/BlockScroll'
import getClassForOverlay from '../../../util/Sidebar'
import {SliceListInterface} from '../../../../../domain/Share/Slice/SliceList'
import ListToSellChoice from '../../Datalist/Parts/ListToSellChoice'
import {addSliceToPledge, addSliceToTransaction} from '../../../../store/component/slice'
import {toastError, toastSuccess} from "../../../util/Toast";

const ChoiceInvestor: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openChoiceSliceToGiveUp = useAppSelector((state) => state.event.openChoiceSliceToGiveUp)

  useEffect(() => {
    blockScrollBody(openChoiceSliceToGiveUp.show)
  }, [openChoiceSliceToGiveUp])

  const handleChoiceSliceToGiveUp = function (selectedSliceToGiveUp: SliceListInterface) {
    if(!openChoiceSliceToGiveUp.choicePartSelectAll && openChoiceSliceToGiveUp.isForPledge) {
      dispatch(addSliceToPledge(selectedSliceToGiveUp))
      handleClose()
    }
    else if (selectedSliceToGiveUp.count >= selectedSliceToGiveUp.countShareSelected) {
      if(openChoiceSliceToGiveUp.isForPledge) {
        dispatch(addSliceToPledge(selectedSliceToGiveUp))
      } else {
        dispatch(addSliceToTransaction(selectedSliceToGiveUp))
      }
      handleClose()
      toastSuccess(t('movement.notify.choice-share-success'))
    } else {
      toastError(t('movement.notify.share-count-exceeded'))
    }
  }

  function handleClose() {
    dispatch(setOpenChoiceSliceToGiveUpEvent({show: false, prospectId: null, productId: null, choicePartSelectAll: openChoiceSliceToGiveUp.choicePartSelectAll || false}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openChoiceSliceToGiveUp.show)}`} onClick={() => handleClose()}/>
      <div className={`sidebar sidebar--right sidebar--right ${openChoiceSliceToGiveUp.show ? 'sidebar--active-big' : ''}`}>
        <div className="form-bloc">
        <div className="sidebar__content">
          <div className="title">{openChoiceSliceToGiveUp.choicePartSelectAll ? t('movement.patrimony-visualization') : t('movement.choice-slices')}</div>
          <ListToSellChoice callback={handleChoiceSliceToGiveUp}/>
      </div>
        <footer className="sidebar__footer">
        <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => handleClose()}>{t('common.cancel')}</button>
      </footer>
        </div>
    </div>
    </>
  );
}

export default ChoiceInvestor
