import {Presenter} from '../Presenter'
import {ProductListInterface} from '../../domain/Product/ProductList'
import {CommissioningSessionListInterface} from "../../domain/Commissioning/CommissioningSessionList";
import {CommissioningSessionInterface} from "../../domain/Commissioning/CommissioningList";
import {CommissioningSessionItemInterface} from "../../domain/Commissioning/CommissioningSessionItemInterface";

class CommissioningSessionListPresenter extends Presenter {

  private useCase: { getSession: any }

  constructor(getSession: any) {
    super({
      viewModel: {
        title: 'product.search-a-product',
        heading: [
          {name: 'product.heading.Action'},
          {name: 'nom de session', sort: 'share_count'},
          {name: 'n° investisseur', sort: 'share_count'},
          {name: 'nom investisseur', sort: 'share_count'},
          {name: 'nom du produit', sort: 'share_count'},
          {name: 'nature propriete', sort: 'acronyme'},
          {name: 'Date de souscription', sort: 'share_count'},
          {name: 'ID de la souscription', sort: 'label'},
          {name: 'type de mouvement', sort: 'product_type_label'},
          {name: 'Nombre de part', sort: 'subscribable'},
          {name: 'Valeur de la part', sort: 'status'},
          {name: 'Quote part', sort: 'share_count'},
          {name: 'montant investissement', sort: 'share_count'},
          {name: 'code du partenaire commissionné', sort: 'share_count'},
          {name: 'Type de barème', sort: 'share_count'},
          {name: 'Montant de base', sort: 'share_count'},
          {name: 'ID Partenaire Niveau 1', sort: 'share_count'},
          {name: 'Nom partenaire Niveau 1', sort: 'share_count'},
          {name: 'Taux1', sort: 'share_count'},
          {name: 'montant1', sort: 'share_count'},
          {name: 'Montant T.V.A 1', sort: 'share_count'},
          {name: 'Montant T.T.C 1', sort: 'share_count'},
          {name: 'ID Partenaire Niveau 2', sort: 'share_count'},
          {name: 'Nom partenaire Niveau 2', sort: 'share_count'},
          {name: 'Taux2', sort: 'share_count'},
          {name: 'montant2', sort: 'share_count'},
          {name: 'Montant T.V.A 2', sort: 'share_count'},
          {name: 'Montant T.T.C 2', sort: 'share_count'},
          {name: 'ID Partenaire Niveau 3', sort: 'share_count'},
          {name: 'Nom partenaire Niveau 3', sort: 'share_count'},
          {name: 'Taux3', sort: 'share_count'},
          {name: 'montant3', sort: 'share_count'},
          {name: 'Montant T.V.A 3', sort: 'share_count'},
          {name: 'Montant T.T.C 3', sort: 'share_count'},
          {name: 'ID Partenaire Niveau 4', sort: 'share_count'},
          {name: 'Nom partenaire Niveau 4', sort: 'share_count'},
          {name: 'Taux4', sort: 'share_count'},
          {name: 'montant4', sort: 'share_count'},
          {name: 'Montant T.V.A 4', sort: 'share_count'},
          {name: 'Montant T.T.C 4', sort: 'share_count'},
          {name: 'Date du calcul', sort: 'share_count'},
        ],
        data: [],
        filtersShortcut: [
          {'keyword': 'filters.by-keyword-or-id', 'field': 'keywords', 'type': 'input'}
        ],
        filters: [
        ],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getSession,
    }
  }

  async load() {
    try {
      this.setCommissioningSession(await this.useCase.getSession);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  setCommissioningSession(data: {
    data: CommissioningSessionItemInterface[]|null,
    session: CommissioningSessionInterface[]|null,
    nb_element: number
  }) {
    this.update({
      data: data.data,
      session: data.session,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': data.nb_element,
      }
    });
  }
}

export default CommissioningSessionListPresenter
