import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../../store/hook";
import blockScrollBody from "../../../../util/BlockScroll";
import {PledgeOrganizationInterface} from "../../../../../../domain/Pledge/PledgeOrganization";
import {SubmitHandler, useForm} from "react-hook-form";
import {setOpenPledgeOrganizationForm} from "../../../../../store/component/event";
import getClassForOverlay from "../../../../util/Sidebar";
import CountryDatalist from "../../../Datalist/Country/CountryDatalist";
import {ReferentielInterface} from "../../../../../../domain/Referentiel/ReferentielInterface";
import ReferentielGateway from "../../../../../../gateway/Referentiel/ReferentielGateway";
import SelectCustom from "../../../Elements/Select";
import PledgeOrganizationGateway from "../../../../../../gateway/Pledge/PledgeOrganizationGateway";
import eyeIcon from "../../../../../../assets/images/icons/eye.svg";
import uploadIcon from "../../../../../../assets/images/icons/upload.svg";
import email from "../../../../../../assets/images/icons/email.svg";
import {toastSuccess} from "../../../../util/Toast";
import {Country} from "../../../../../../domain/Referentiel/Country/Country";
import CityAndPostcode from "../../../Datalist/CityAndPostcode/CityAndPostcode";
import {City} from "../../../../../../domain/Referentiel/City/City";

interface IFormInput {
  id: string|null
  name: string|null
  status: string|null
  address: string|null
  address2: string|null
  address3: string|null
  postcode: string|null
  city: City|null
  country: Country|null
  bank: string|null
  iban: string|null
  swift: string|null
}

const AddPledgeOrganization: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const countryByDefault = useAppSelector(({referential}) => referential.countryByDefault)
  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openPledgeOrganizationForm = useAppSelector((state) => state.event.openPledgeOrganizationForm)

  const { register, control, handleSubmit, reset, setValue, getValues, watch } = useForm<IFormInput>();

  const watchCountry = watch('country')
  const watchCity = watch('city')
  const watchPostCode = watch('postcode')


  useEffect(() => {
    blockScrollBody(openPledgeOrganizationForm.show)
    reset()
    if (openPledgeOrganizationForm.pledgeOrganization) {

      setValue('id', openPledgeOrganizationForm.pledgeOrganization.id)
      setValue('name', openPledgeOrganizationForm.pledgeOrganization.name)
      setValue('status', openPledgeOrganizationForm.pledgeOrganization.status)
      setValue('address', openPledgeOrganizationForm.pledgeOrganization.address)
      setValue('address2', openPledgeOrganizationForm.pledgeOrganization.address2)
      setValue('address3', openPledgeOrganizationForm.pledgeOrganization.address3)
      setValue('postcode', openPledgeOrganizationForm.pledgeOrganization.postcode)
      setValue('city', openPledgeOrganizationForm.pledgeOrganization.city)
      // setValue('country', openPledgeOrganizationForm.pledgeOrganization.country)
      setValue('bank', openPledgeOrganizationForm.pledgeOrganization.bank)
      setValue('iban', openPledgeOrganizationForm.pledgeOrganization.iban)
      setValue('swift', openPledgeOrganizationForm.pledgeOrganization.swift)
    } else {
      setValue('id', 'provisional_'+crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('city', null)
    }

  }, [openPledgeOrganizationForm])

  useEffect(() => {
    if(countryByDefault[0] && openPledgeOrganizationForm) {
      setValue('country', openPledgeOrganizationForm?.pledgeOrganization?.country || countryByDefault[0])
    }
  }, [countryByDefault, openPledgeOrganizationForm])

  const onSubmit: SubmitHandler<IFormInput> = data => {
    const pledgeOrganizationObj: PledgeOrganizationInterface = {
      id: data.id,
      name: data.name,
      status: data.status,
      address: data.address,
      address2: data.address2,
      address3: data.address3,
      postcode: data.postcode,
      city: data.city,
      country: data.country,
      bank: data.bank,
      iban: data.iban,
      swift: data.swift
    }

    if (openPledgeOrganizationForm.pledgeOrganization?.id) {
      (new PledgeOrganizationGateway()).update(pledgeOrganizationObj).then((response) => {
        toastSuccess(t('pledgeorganization.notify.add-success'));
      })
    } else {
      (new PledgeOrganizationGateway()).add(pledgeOrganizationObj).then((response) => {
        toastSuccess(t('pledgeorganization.notify.edit-success'));
      })
    }

    reset()
    dispatch(setOpenPledgeOrganizationForm({show: false, pledgeOrganization: null}))
  }

  function handleClose() {
    dispatch(setOpenPledgeOrganizationForm({show: false, pledgeOrganization: null}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openPledgeOrganizationForm.show)}`} onClick={() => handleClose()}/>
      <div className={`sidebar sidebar--right sidebar--right ${openPledgeOrganizationForm.show ? 'sidebar--active' : ''}`}>
        <form onSubmit={handleSubmit(onSubmit)} className="form-bloc form-bloc--customer-customerAddress-form">
          <div className="sidebar__content">
            <div className="title">{t('pledgeorganization.form.add')}</div>
            <div className="col-md-6">
              <div className="flex-container">
                <div className="col-md-6">
                  <div className="form-control">
                    <label htmlFor="name" className="form-control__label">{t('pledgeorganization.form.name')}</label>
                    <div className="form-control__input">
                      <input type="hidden" id="id" {...register('id')} />
                      <input type="text" id="name" {...register('name')} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  {referential &&
                    <SelectCustom classes="col-md-12"
                                  id="status"
                                  name="status"
                                  options={referential.global.active}
                                  register={register}
                                  label={t('pledgeorganization.form.status')}
                    />
                  }
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="address" className="form-control__label">{t('pledgeorganization.form.address1')}</label>
                    <div className="form-control__input">
                      <input type="text" id="address" {...register('address')} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="address2" className="form-control__label">{t('pledgeorganization.form.address2')}</label>
                    <div className="form-control__input">
                      <input type="text" id="address2" {...register('address2')} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="address3" className="form-control__label">{t('pledgeorganization.form.address3')}</label>
                    <div className="form-control__input">
                      <input type="text" id="address3" {...register('address3')} />
                    </div>
                  </div>
                </div>
                <CityAndPostcode control={control}
                                 nameCity="city"
                                 namePostcode="postcode"
                                 country={watchCountry}
                                 register={register}
                                 setValue={setValue}
                                 getValues={getValues}
                                 mode="row"
                                 classes="col-md-12 flex u-px0"
                />
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="country" className="form-control__label">{t('pledgeorganization.form.country')}</label>
                    <div className="form-control__input">
                      <CountryDatalist control={control} name={'country'} defaultValue={getValues('country')} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="bank" className="form-control__label">{t('pledgeorganization.form.bank')}</label>
                    <div className="form-control__input">
                      <input type="text" id="bank" {...register('bank')} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="iban" className="form-control__label">{t('pledgeorganization.form.iban')}</label>
                    <div className="form-control__input">
                      <input type="text" id="iban" {...register('iban')} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="swift" className="form-control__label">{t('pledgeorganization.form.swift')}</label>
                    <div className="form-control__input">
                      <input type="text" id="swift" {...register('swift')} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-control">
                    <label htmlFor="swift" className="form-control__label">{t('pledgeorganization.form.actions')}</label>
                    <div className="form-control__input">
                      <button type="button" className="button-reset"><img src={eyeIcon} alt="" /></button>
                      <button type="button" className="button-reset"><img src={uploadIcon} alt="" /></button>
                      <button type="button" className="button-reset"><img src={email} alt="" /></button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <footer className="sidebar__footer">
            <button type="submit" className="button button--ink-2 u-mrm">{t('common.save')}</button>
            <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => handleClose()}>{t('common.cancel')}</button>
          </footer>
        </form>
      </div>
    </>
  )
}

export default AddPledgeOrganization
