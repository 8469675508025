import React, {FormEvent, FunctionComponent, InputHTMLAttributes} from 'react'

import {IFormFieldProps} from './IFormFieldProps'
import {Control, Controller, useWatch} from "react-hook-form";
import {NumericFormat} from "react-number-format";

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  id: string
  name: string
  classes?: string
  containerClasses?: string
  labelClasses?: string
  defaultValue?: string
  disabled?: boolean
  readonly?: boolean
  required?: boolean
  control: Control<any, any>
  decimalScale: number
  onChangeCb?: (value: string) => void
  valueIsNumericString?: boolean
}

const InputNumber: FunctionComponent<IProps> = ({
                                                id,
                                                name,
                                                classes,
                                                containerClasses,
                                                labelClasses,
                                                label,
                                                defaultValue,
                                                disabled,
                                                readOnly,
                                                required,
                                                control,
                                                decimalScale,
                                                onChangeCb,
                                                valueIsNumericString= true
                                              }) => {


  const val = useWatch({
    defaultValue: defaultValue,
    control: control,
    name: name
  })

  const formInput =
    <Controller
      name={name}
      control={control}
      render={({field}) => {
        return (
          <NumericFormat
            allowedDecimalSeparators={['.', ',']}
            onValueChange={
              (val) => {
                field.onChange(val.floatValue)
                if (onChangeCb) {
                  const floatValue = val.floatValue ? String(val.floatValue) : ''
                  onChangeCb(floatValue)
                }
              }
            }
            id={id}
            decimalScale={decimalScale}
            decimalSeparator=","
            required={required}
            disabled={disabled}
            readOnly={readOnly}
            value={val}
            fixedDecimalScale
          />
        );
      }}
    />

  return (
    <div className={`${classes}`}>
      {label ?
        <div className="form-control relative">
          <label htmlFor={id} className={`form-control__label ${labelClasses || ""}`}>{label}</label>
          <div className={`form-control__input ${containerClasses || ""}`}>
            { formInput }
          </div>
        </div>
        :
        <div className={`form-control__input ${containerClasses || ""}`}>
          {formInput}
        </div>
      }
    </div>
  )
}

export default InputNumber
