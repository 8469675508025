import {Presenter} from '../Presenter'
import {MovementListInterface} from '../../domain/Movement/MovementList'

class DismembermentInvestorChoiceListPresenter extends Presenter {

  private useCase: {
    getMovements: { data: MovementListInterface[] | null, nb_element: number }
  }

  constructor(getMovements: any) {
    super({
      viewModel: {
        title: 'movement.search-a-movement',
        heading: [
          'movement.heading.action',
          'movement.heading.product',
          'movement.heading.movement_type',
          'movement.heading.id',
          'movement.heading.status',
          'movement.heading.property_type',
          'movement.heading.invest_id',
          'movement.heading.invest_lastname',
          'movement.heading.partner_id',
          'movement.heading.partner_lastname',
          'movement.heading.payment_method',
          'movement.heading.payment_date',
          'movement.heading.state',
          'movement.heading.engage',
          'movement.heading.gestionnaire',
        ],
        datas: [],
        filtersShortcut: [
          {'keyword': 'filters.by-lastname', 'field': 'name', 'type': 'input'},
          {'keyword': 'filters.by-keyword-or-id', 'field': 'keywords', 'type': 'input'}
        ],
        filters: [
        ],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getMovements,
    }
  }

  async load() {
    try {
      this._setMovements(await this.useCase.getMovements);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setMovements(movements: { data: MovementListInterface[]|null, nb_element: number }) {
    this.update({
      data: movements.data,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': movements.nb_element,
      }
    });
  }
}

export default DismembermentInvestorChoiceListPresenter
