import {Presenter} from '../Presenter'
import {PartnerListInterface} from '../../domain/Partner/PartnerList'

class PartnerListPresenter extends Presenter {

  private useCase: { getPartners: any }

  constructor(getPartners: any) {
    super({
      viewModel: {
        title: 'partner.search-a-partner',
        heading: [
          {name: 'partner.heading.action'},
          {name: 'partner.heading.partner_id', sort: 'partner_code'},
          {name: 'partner.heading.cabinet_name', sort: 'social_reason'},
          {name: 'partner.heading.contact_name', sort: 'contact_name'},
          {name: 'partner.heading.internal_commercial_name', sort: 'internal_commercial'},
          {name: 'partner.heading.products', sort: 'convention.product_label'},
          {name: 'partner.heading.city', sort: 'address_table.city'},
          {name: 'partner.heading.level', sort: 'commission_level'},
          {name: 'partner.heading.status', sort: 'status'},
        ],
        datas: [],
        filtersShortcut: [
          {'keyword': 'filters.by-name', 'field': 'name', 'type': 'input'},
          {'keyword': 'filters.by-keyword-or-id', 'field': 'keywords', 'type': 'input'}
        ],
        filters: [
          {'title': 'filters.name', 'type': 'input'},
          {'title': 'filters.keyword-or-id', 'type': 'input'}
        ],
        pagination: {
          'itemsPerPage': 50,
          'numberOfItems': 0,
        },
        count: 0
      }
    });

    this.useCase = {
      getPartners,
    }
  }

  async load() {
    try {
      this._setPartners(await this.useCase.getPartners);
    } catch (e) {
      //Do nothing for the moment
    }
  }

  _setPartners(partners: { data: PartnerListInterface[]|null, nb_element: number }) {
    this.update({
      data: partners.data,
      pagination: {
        'itemsPerPage': 50,
        'numberOfItems': partners.nb_element,
      }
    });
  }
}

export default PartnerListPresenter
