import Caller from '../../../../domain/Api/Caller'
import envVariable from '../../../../infrastructure/presentation/util/envVariable'
import LogoGatewayInterface
  from "../../../../domain/Settings/GeneralSettings/Logo/LogoGatewayInterface";
import {
  LogoInterface,
  LogoToSend,
} from "../../../../domain/Settings/GeneralSettings/Logo/Logo";

export default class LogoGateway implements LogoGatewayInterface {

  getFile(fileId: number): Promise<LogoInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/settings/files/${fileId}`, {})
    .then(data => {
      if (data) {
        return data.content
      }

      return null
    })
    .catch(() => {
      return null
    })
  }
  save(logo: LogoToSend[]): Promise<any> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/settings/files`, {
      files: logo
    })
    .then(data => {
      if (data) {
        return data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }
}
