import {FilterCustomerInterface} from '../../../domain/Customer/CustomerList'
import {FilterPledgeInterface} from "../../../domain/Pledge/PledgeList";

interface ListRequestInterface {
  currentPage: number
  itemPerPage: number
  filter: FilterPledgeInterface|null
  investorId: string
}

class ListRequest implements ListRequestInterface{
  public currentPage: number
  public itemPerPage: number
  public filter: FilterPledgeInterface|null
  public investorId: string

  constructor(
    currentPage: number,
    itemPerPage: number,
    filter: FilterPledgeInterface|null,
    investorId: string,
  ) {
    this.currentPage = currentPage
    this.itemPerPage = itemPerPage
    this.filter = filter
    this.investorId = investorId
  }
}

export {
  ListRequest
}
export type { ListRequestInterface }
