import {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useAppSelector} from '../../../../store/hook'

import HeaderPage from '../../../component/HeaderPage/HeaderPage'

import imgChoiceProspect from '../../../../../assets/images/icons/choice-prospect.svg'

import '../../../../../assets/styles/page/_account.scss';
import {ChoiceTypeInterface} from "../../../../../domain/ChoiceType/ChoiceType";

import prospectMoral from "../../../../../assets/images/icons/prospectMoral.svg"
import prospectPhysical from "../../../../../assets/images/icons/prospectPhysical.svg"
import {ReferentielItemInterface} from "../../../../../domain/Referentiel/ReferentielItemInterface";
import ChoiceTypes from "../../../component/ChoiceTypes/ChoiceTypes";

const ChoiceType: FunctionComponent = () => {
  const {t} = useTranslation()

  const referential = useAppSelector(({referential}) => referential.referential)
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)

  const [prospectTypes, setProspectType] = useState<ChoiceTypeInterface[]>([])

  useEffect(() => {
    if(referential) {
      const choiceTypeDataPrepared = prepareChoiceTypeDataData(referential.prospect.prospect_type)
      setProspectType([...choiceTypeDataPrepared])
    }
  }, [referential])

  const prepareChoiceTypeDataData = (prospectTypeReferential: ReferentielItemInterface[]) :ChoiceTypeInterface[] => {
      return prospectTypeReferential.map((type) => {
        if(type.value === "moral") {
          return {
            label: t('account.account_type_choice.'+type.value),
            url: `${t('url.prospect.add-general-information')}/${type.value}`,
            img: prospectMoral
          }
        }
        else {
          return {
            label: t('account.account_type_choice.'+type.value),
            url: `${t('url.prospect.add-general-information')}/${type.value}`,
            img: prospectPhysical
          }
        }
      })
  }

  return (
    <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''} main-content--sidebar-info`}>
      <div className="sidebar-info__main">
        <HeaderPage title={t('prospect.add.title')} />
        <ChoiceTypes types={prospectTypes} />
      </div>
      <div className="sidebar-info">
        <div className="sidebar-info__text">
          {t('account.form.choice-type-select')}
        </div>
        <div>
          <img src={imgChoiceProspect} alt="" />
        </div>
      </div>
    </main>
  )
}

export default ChoiceType
