import React, {
  FunctionComponent,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '../../../../store/hook'
import { setOpenNotePadEvent } from '../../../../store/component/event'
import blockScrollBody from '../../../util/BlockScroll'
import getClassForOverlay from '../../../util/Sidebar'
import CKEditorUtil from '../../../util/CKEditor'
import NoteBlock from '../../Block/NoteBlock'
import expandIcon
  from '../../../../../assets/images/icons/arrow-bottom--dark.svg'
import removeIcon from '../../../../../assets/images/icons/remove.svg'
import NotePadGateway from '../../../../../gateway/NotePad/NotePad'
import { useParams } from 'react-router-dom'
import {
  dateToString,
} from '../../../../transformer/StringDateToDateObject/StringDateToDateObject'
import { toastError } from '../../../util/Toast'

type Props = {
  title: string
  isLectureMode: boolean
}

interface NotesInterface {
  author: string
  author_id: number
  content: string
  object: string
  created_at: string
  deletable: boolean
  id: string
}

const NotePad: FunctionComponent<Props> = ({ title, isLectureMode }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { uuid } = useParams()

  const [note, setNote] = useState<string>('')
  const [object, setObject] = useState<string>('')
  const [notes, setNotes] = useState<NotesInterface[]>([])

  const openNotePad = useAppSelector((state) => state.event.openNotePad)
  const user = useAppSelector((state) => state.me.me)

  useEffect(() => {
    if (openNotePad.show) {
      fetchNotePadData()
    }
    blockScrollBody(openNotePad.show)

  }, [openNotePad])

  function handleClose() {
    dispatch(setOpenNotePadEvent({ show: false }))
  }

  const fetchNotePadData = () => {
    if (uuid) {
      new NotePadGateway()
        .getNote(uuid)
        .then((response) => {
          setNotes([...response])
        })
    }
  }

  const sendNote = () => {
    if (uuid) {
      new NotePadGateway()
        .postNote(uuid, note, object)
        .then((response) => {
          setNote('')
          setObject('')
          setNotes(n => [response, ...n])
          const frame = document.getElementsByClassName('cke_wysiwyg_frame')[0] as HTMLIFrameElement

          if (frame?.contentDocument?.body?.innerHTML) {
            frame.contentDocument.body.innerHTML = ''
          }
        })
    }
  }

  const deleteNote = (e: any, note: NotesInterface) => {
    e.stopPropagation()
    if (uuid) {
      new NotePadGateway()
        .deleteNote(uuid, note.id)
        .then(() => {
          const newNotes = notes.filter((n) => n.id !== note.id)
          setNotes([...newNotes])
        })
        .catch(() => {
          toastError(t('common.sideBar.notePad.error-forbidden'))
        })
    }
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openNotePad.show)}`}
           onClick={() => handleClose()}
      />
      <div
        className={`sidebar sidebar--right sidebar--right ${openNotePad.show ? 'sidebar--active-big' : ''}`}>
        <div className='form-bloc'>
          <div className='sidebar__content'>
            <div className='title'>{title}</div>
            <div className='container-object form-control__input'>
              <label htmlFor='object' className="mandatory-field">Objet: </label>
              <div className="block-object">
                <input type='text'
                       id='object'
                       value={object}
                       onChange={(e) => setObject(e.target.value)}
                       placeholder={t('common.sideBar.notePad.placeholder-object')}
                       maxLength={50}
                />
                <span className="u-txt-size-s">{object.length} / 50</span>
              </div>
              <span className='info-author'>{`${user?.firstname} ${user?.lastname} - ${dateToString(new Date())}`}</span>
            </div>
            <NoteBlock
              data={note}
              onChange={(data: string) => {
                {
                  setNote(data)
                }
              }}
              config={CKEditorUtil.getConfig()}
              readonly={isLectureMode}
              alreadyTitle
            />

            {
              notes && notes.map((note) => {
                return (
                  <div key={note.id}
                       className='form-bloc__form flex-container u-pbs u-mx0 relative'>
                    <details className={`col-md-12 collapse-native`}>
                      <summary>
                        <div className='flex justify-between w-30'>
                          <span className=''>{note.author}</span>
                          <span>{note.created_at}</span>
                        </div>
                        <div className='flex w-40'>
                          <span
                            className='collapse-native--object'>{note.object}</span>
                        </div>
                        <div className='u-mlAuto'>
                          {note.deletable && <button className='button u-mlAuto '
                                                     onClick={(e) => deleteNote(e, note)}
                          >
                            <img src={removeIcon}
                                 className='u-mlAuto u-mrs remove'
                                 alt=''
                                 onClick={(e) => deleteNote(e, note)} />
                          </button>}
                          <img src={expandIcon}
                               className='arrow'
                               alt=''
                          />
                        </div>
                      </summary>
                      <div className='col-md-12 collapse-native--content'
                           dangerouslySetInnerHTML={{ __html: note.content }}
                      />
                    </details>
                  </div>
                )
              })
            }

          </div>
          <footer className='sidebar__footer'>
            <button type='button'
                    className='button button--ink-2 u-mrm'
                    onClick={() => sendNote()}
                    disabled={note === '' || object === ""}
            >
              {t('common.save')}
            </button>
            <button type='button'
                    className='button button--ink-2 button--ink-2--outline'
                    onClick={() => handleClose()}>
              {t('common.cancel')}
            </button>
          </footer>
        </div>
      </div>
    </>
  )
}

export default NotePad
