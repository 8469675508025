import {City} from "../Referentiel/City/City";
import {Country} from "../Referentiel/Country/Country";

interface AddressInterface {
  id: string
  addressPrincipal: boolean
  civility: string
  lastName: string
  maidenName: string
  firstName: string
  service: string
  addressFirstLine: string
  addressSecondLine: string
  addressThirdLine: string
  zipcode: string
  city: City|null
  country: Country|null
  pnd: boolean
  status: string|null
}

export const PARTNER_ADDRESS_STATUS_ACTIVE = 'active'
export const PARTNER_ADDRESS_STATUS_INACTIVE = 'inactive'

class Address implements AddressInterface {
  public id: string
  public addressPrincipal: boolean
  public civility: string
  public lastName: string
  public maidenName: string
  public firstName: string
  public service: string
  public addressFirstLine: string
  public addressSecondLine: string
  public addressThirdLine: string
  public zipcode: string
  public city: City|null
  public country: Country|null
  public pnd: boolean
  public status: string|null

  constructor(
    id: string,
    addressPrincipal: boolean,
    civility: string,
    lastName: string,
    maidenName: string,
    firstName: string,
    service: string,
    addressFirstLine: string,
    addressSecondLine: string,
    addressThirdLine: string,
    zipcode: string,
    city: City|null,
    country: Country|null,
    pnd: boolean,
    status: string|null
  ) {
    this.id = id
    this.addressPrincipal = addressPrincipal
    this.civility = civility
    this.lastName = lastName
    this.maidenName = maidenName
    this.firstName = firstName
    this.service = service
    this.addressFirstLine = addressFirstLine
    this.addressSecondLine = addressSecondLine
    this.addressThirdLine = addressThirdLine
    this.zipcode = zipcode
    this.city = city
    this.country = country
    this.pnd = pnd
    this.status = status
  }
}

export {
  Address
}
export type { AddressInterface }
