import {FunctionComponent, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../store/hook";
import {DocumentInterface, DocumentToSend} from "../../../../domain/Document/Document";
import blockScrollBody from "../../util/BlockScroll";
import Converter from "../../util/Converter";
import DocumentGatewayInterface from "../../../../domain/Document/DocumentGatewayInterface";
import {toastError, toastSuccess} from "../../util/Toast";
import {useDropzone} from "react-dropzone";
import {openUploadForm} from '../../../../infrastructure/store/component/event'
import getClassForOverlay from "../../util/Sidebar";
import uploadIcon from "../../../../assets/images/icons/upload-file.png";
import Spinner from "../Spinner/Spinner";

type Props = {
  openUploadForm: openUploadForm
  gateway: DocumentGatewayInterface
  callback: any
}

const Upload: FunctionComponent<Props> = ({openUploadForm, gateway, callback}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const [documents, setDocuments] = useState<DocumentInterface[]|null>(openUploadForm.documents)
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    blockScrollBody(openUploadForm.show)
    setIsUploading(false)
  }, [openUploadForm.show])

  function handleClose() {
    dispatch(callback({show: false, documents: null, uuid: null, type: null}))
  }

  const onDrop = useCallback(acceptedFiles => {
    const promises: Promise<any>[] = []
    acceptedFiles.map((file: File) => {
      promises.push(new Converter().toBase64(file).then(base64 => {
        if (typeof base64 === 'string') {
          return new DocumentToSend(
            base64,
            file.name,
            openUploadForm.type || ''
          )
        }
      }))
    })
    setIsUploading(true);

    Promise.all(promises).then(documents => {
      if (documents.length > 0 && openUploadForm.uuid) {
        gateway.save(openUploadForm.uuid, documents).then(response => {
          if (null !== response) {
            dispatch(callback(
              {
                show: false,
                documents: documents,
                uuid: openUploadForm.uuid,
                type: openUploadForm.type
              }
            ))
            toastSuccess(t('common.doc-add-success'))
          } else {
            toastError(t('common.doc-add-error'));
          }
          setIsUploading(false);
        })
      }
    })
  }, [openUploadForm])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop
  })

  const removeFile = (documentToRemove: DocumentInterface) => () => {
    console.log('remove')
  }

  const show = (documentToShow: DocumentInterface) => () => {
    console.log(documentToShow)
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openUploadForm.show)}`} onClick={() => handleClose()} />
      <div className={`sidebar sidebar--right sidebar--right ${openUploadForm.show ? 'sidebar--active' : ''}`}>
        <div className="form-bloc form-bloc--prospect-address-form">
          <div className="sidebar__content">
            <div className="title">{t('common.add-document')}</div>
            <div className="form-bloc__form">
              <div className="container">
                <div {...getRootProps()} className={`uploader ${isDragActive ? 'dzu-dropzoneActive' : ''}`}>
                  <input {...getInputProps()} />

                  <div><img src={uploadIcon} alt="upload" /></div>
                  <p className="u-ptm">{t('common.upload-file')}</p>
                </div>
                {isUploading &&
                    <div className="u-mam">
                        <Spinner />
                    </div>
                }
              </div>
            </div>
            <div className="">
              {(documents &&
                (documents.map(document => (
                    <div className="dzu-previewContainer" key={document.id}>
                      <div className="upload-progress__wrapper">
                        <div
                          className="button button-primary--outline button--small button--width-fit button--height-fit"
                          onClick={show(document)}>{document.title}</div>
                        <div
                          className="button button-error--outline button--small button--width-fit button--height-fit"
                          onClick={removeFile(document)}>{t('button.remove')}
                        </div>
                      </div>
                    </div>
                  ))
                )
              )}
            </div>
          </div>
          <footer className="sidebar__footer">
            <button type="button" className="button button--ink-2 button--ink-2--outline"
                    onClick={() => handleClose()}>{t('common.cancel')}</button>
          </footer>
        </div>
      </div>
    </>
  )
}

export default Upload
