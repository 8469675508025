import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";

type Props = {
  disabled: boolean
}
const FooterBlock: FunctionComponent<Props> = ({disabled}) => {
  const {t} = useTranslation()

  return (
    <footer className={`form-bloc__footer`}>
      <button type="submit" className="button button--ink-2" disabled={disabled}>{t('common.save')}</button>
      <button type="button" className="button button--ink-2 button--ink-2--outline">{t('common.cancel')}</button>
    </footer>
  )
}
export default FooterBlock
