import {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

// Notification lib
import 'react-toastify/dist/ReactToastify.min.css';

import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {setOpenChoiceInvestorEvent} from '../../../../store/component/event'
import { setPresubscriptionOwner, setPresubscriptionUsufructuary} from '../../../../store/component/movement'


import blockScrollBody from '../../../util/BlockScroll'
import getClassForOverlay from '../../../util/Sidebar'
import WarrantSearchDatalist from '../../Datalist/WarrantSearchDatalist'
import GetUseCase from '../../../../../useCase/PreSubscription/Get/GetUseCase'
import PreSubscriptionGateway from '../../../../../gateway/PreSubscription/PreSubscriptionGateway'
import {toastSuccess} from '../../../util/Toast'
import InitializeDismembermentInvestor
  from "../../../../../useCase/Movement/Initialization/InitializeDismembermentInvestor";

const ChoiceInvestor: FunctionComponent = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const openChoiceInvestor = useAppSelector((state) => state.event.openChoiceInvestor)

  useEffect(() => {
    blockScrollBody(openChoiceInvestor.show)
  }, [openChoiceInvestor])

  const handleChoiceWarrantSearch = function (warrantSearchId: string) {
    new GetUseCase(new PreSubscriptionGateway()).execute(warrantSearchId).then(response => {
      if (response) {
        const investor = new InitializeDismembermentInvestor().initializeDismembermentInvestor()
        investor.type = (openChoiceInvestor.key === 'owner') ? 'investor' : 'usufruct'
        investor.dismembermentTransaction = response
        if (openChoiceInvestor.key === 'owner') {
          dispatch(setPresubscriptionOwner(investor))
        } else {
          dispatch(setPresubscriptionUsufructuary(investor))
        }
        toastSuccess(t('movement.notify.choice-investor-success'))
        dispatch(setOpenChoiceInvestorEvent({show: false, key: 'owner', dataListType: "subscription"}))
      }
    })
  }

  function handleClose() {
    dispatch(setOpenChoiceInvestorEvent({show: false, key: 'owner', dataListType: "subscription"}))
  }

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openChoiceInvestor.show)}`} onClick={() => handleClose()}/>
      <div className={`sidebar sidebar--right sidebar--right ${openChoiceInvestor.show ? 'sidebar--active-big' : ''}`}>
        <div className="form-bloc">
        <div className="sidebar__content">
          {openChoiceInvestor.key === 'owner' && <div className="title">{t('movement.choice-investor-owner')}</div>}
          {openChoiceInvestor.key === 'usufructuary' && <div className="title">{t('movement.choice-investor-usufructuary')}</div>}
          <WarrantSearchDatalist callback={handleChoiceWarrantSearch} type={openChoiceInvestor.key === 'owner' ? 'np' : 'usufruct'}/>
      </div>
        <footer className="sidebar__footer">
        <button type="button" className="button button--ink-2 button--ink-2--outline" onClick={() => handleClose()}>{t('common.cancel')}</button>
      </footer>
        </div>
    </div>
    </>
  );
}

export default ChoiceInvestor
