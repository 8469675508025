
type TInvestorReceivingShares = {
  idInvestor: string
  code: string
  firstname: string
  lastname: string
  fullName: string
  propertyNature: 'np' | 'usu'| 'pp' | undefined
}

type TPartnerReceivingShares = {
  idPartner: string
  code: string
  socialReason: string
  propertyNature: 'np' | 'usu' | 'pp' | undefined
}

interface IPairInvestorPartner {
  investor: TInvestorReceivingShares,
  partner: TPartnerReceivingShares
}

interface distributionTransferorShareInterface {
  idShare: string|null
  nbShares: number
  np: IPairInvestorPartner
  usu: IPairInvestorPartner
  pp: IPairInvestorPartner
}

class DistributionTransferorShare implements distributionTransferorShareInterface {
  idShare: string|null = null
  nbShares: number = 0
  np: IPairInvestorPartner
  usu: IPairInvestorPartner
  pp: IPairInvestorPartner

  constructor(
    idShare: string|null,
    nbShares: number,
    np: IPairInvestorPartner,
    usu: IPairInvestorPartner,
    pp: IPairInvestorPartner,
  ) {
    this.idShare = idShare
    this.nbShares = nbShares
    this.np = np
    this.usu = usu
    this.pp = pp
  }
}

export {DistributionTransferorShare}
export type {distributionTransferorShareInterface, TInvestorReceivingShares, TPartnerReceivingShares}
