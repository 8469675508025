import React, {FunctionComponent} from 'react';
import {useTranslation} from "react-i18next";

type TProps = {
  isLectureMode?: boolean | undefined
  disabled?: boolean
}

const Footer: FunctionComponent<TProps> = ({isLectureMode, disabled= false}) => {
  const {t} = useTranslation()

  if (isLectureMode) return null
  return <footer className={`form-bloc__footer col-md-12`}>
    <button type="submit"
            className="button button--ink-2"
            disabled={disabled}
    >
      {t('common.save')}
    </button>
    <button className="button button--ink-2 button--ink-2--outline" disabled>{t('common.cancel')}</button>
  </footer>
}

export default Footer;