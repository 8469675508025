import {Mutation} from "../../../domain/Movement/Mutation";

export default class InitializeMutation {
  initializeMutation() {
    return new Mutation(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      false,
      null,
      null,
      null,
      [],
      [],
      null,
      [],
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
  }
}
