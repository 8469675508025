import {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'

import {useAppSelector} from '../../../../store/hook'

import HeaderPage from '../../../component/HeaderPage/HeaderPage'

import imgChoice from '../../../../../assets/images/icons/choice-product.svg'

import '../../../../../assets/styles/page/_choice.scss'
import ChoiceTypes from "../../../component/ChoiceTypes/ChoiceTypes";
import agreementBg from "../../../../../assets/images/icons/agreement.svg"
import preSubscriptionBg from "../../../../../assets/images/icons/preSubscription.svg"
import redemptionWithdrawalBg from "../../../../../assets/images/icons/redemptionWithdrawal.svg"
import dismembermentBg from "../../../../../assets/images/icons/dismemberment.svg"
import mutationBg from "../../../../../assets/images/icons/mutation.svg"
import landConsolidationBg from "../../../../../assets/images/icons/landConsolidation.svg"
import {ChoiceTypeInterface} from "../../../../../domain/ChoiceType/ChoiceType";

const movements: ChoiceTypeInterface[] = [
  {label: 'Souscriptions achats', url: 'url.pre-subscription.add', img: preSubscriptionBg},
  {label: 'Retraits Rachats', url: 'url.movements.add-redemption-withdrawal', img: redemptionWithdrawalBg},
  {label: 'Gré à gré', url: 'url.movements.add-gre-gre', img: agreementBg},
  {label: 'Mutations', url: 'url.movements.add-mutations', img: mutationBg},
  {label: 'Démembrement', url: 'url.movements.add-dismemberment', img: dismembermentBg},
  {label: 'Remembrement', url: 'url.movements.add-land-consolidation', img: landConsolidationBg}
]


const ChoiceType: FunctionComponent = () => {
  const {t} = useTranslation()

  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)

  return (
    <>
      <main
        className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''} main-content--sidebar-info`}>
        <div className="sidebar-info__main">
          <HeaderPage title={t('movement.choice-type')} />
          <ChoiceTypes types={movements} />
        </div>
        <div className="sidebar-info">
          <div className="sidebar-info__text">
            {t('movement.choice-type-txt')}
          </div>
          <div>
            <img src={imgChoice} alt="" />
          </div>
        </div>
      </main>
    </>
  )
}

export default ChoiceType
