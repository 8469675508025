import React, {
  useEffect,
  useState,
  forwardRef,
  MutableRefObject,
  ForwardRefExoticComponent, RefAttributes, useImperativeHandle
} from "react";
import {NetAssetValueItem, UpdateNetAssetValueItem} from "../../../../../domain/Product/NetAssetValueItem";
import {setCountTotal} from "../../../../store/component/partner";
import NetAssetListPresenter from "../../../../../presenter/Product/NetAsset/NetAssetListPresenter";
import {ViewModelInterface} from "../../../../../domain/Datalist/ViewModelInterface";
import {useAppDispatch} from "../../../../store/hook";
import {v4 as uuidV4} from "uuid";
import iconChevron from "../../../../../assets/images/icons/chevron.svg";
import {SortInterface, SortOrder} from "../../../../../domain/Utils/List";
import iconChevronSelected from "../../../../../assets/images/icons/chevronSelected.svg";
import {t} from "i18next";
import NetAssetGateway from "../../../../../gateway/Product/NetAsset/NetAssetGateway";
import Pagination from "../../Pagination/Pagination";
import iconEdit from "../../../../../assets/images/icons/datalist-edit.svg";
import iconTrash from "../../../../../assets/images/icons/remove.svg";
import iconDisable from "../../../../../assets/images/icons/disabled.svg";
import iconEnable from "../../../../../assets/images/icons/enabled.svg";
import {dateToString, stringToDate} from "../../../../transformer/StringDateToDateObject/StringDateToDateObject";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import {DateRange} from "../../../../../domain/Utils/DateRange";
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../util/Toast";
import {
  CreateNetAssetValueItemInterface
} from '../../../../../domain/Product/NetAssetValueItem'

type Props = {
  productId: string,
  ref: MutableRefObject<unknown>
}

const NetAssetDatalist: ForwardRefExoticComponent<Pick<Props, 'productId'> & RefAttributes<unknown>> = forwardRef(({productId}, ref) => {
  const controller = new AbortController()

  const dispatch = useAppDispatch()
  const [navs, setNavs] = useState<{data: NetAssetValueItem[]|null, numberOfItems: number}|null>(null)
  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({sortLabel: null, sortOrder: SortOrder.ASC})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [nbItems, setNbItems] = useState<number>(5)
  const [editableId, setEditableId] = useState<string>('')
  const [selectedPublishedAt, setSelectedPublishedAt] = useState<string>('')
  const [selectedValue, setSelectedValue] = useState<number>(0)
  const [selectedPeriodStart, setSelectedPeriodStart] = useState<string>('')
  const [selectedPeriodEnd, setSelectedPeriodEnd] = useState<string>('')

  useEffect(() => {
    const presenter = new NetAssetListPresenter(navs);
    presenter.load().then(() => {
      setViewModel(presenter.immutableViewModel())
      dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems))
    })
  }, [navs])

  useEffect(() => {
    reload();
  }, [currentPage, nbItems])

  const defineSortOrder = (sort: string|undefined): SortOrder => {
    if (sortOrder.sortLabel !== sort) return SortOrder.ASC
    return sortOrder.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC
  }

  const sortData = (sort: string|undefined) => {
    if(sort) {
      const order: SortInterface = {
        sortLabel: sort,
        sortOrder: defineSortOrder(sort)
      };
      setSortOrder(order);
      reload()
    }
  }

  const reload = () => {
    new NetAssetGateway().getList(
      productId,
      currentPage,
      nbItems || 5,
      controller.signal,
      sortOrder.sortLabel ?? '',
      sortOrder.sortOrder,
    ).then(response => {
      setNavs(response)
    })
  }

  const paginate = (pageNumber:number) => {controller.abort(); setCurrentPage(pageNumber)}

  const onSubmitEdit = () => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={onConfirmEdit} onClose={onClose}/>)
      }
    });
  }

  const onConfirmEdit = () => {
    const nav = new UpdateNetAssetValueItem(
      editableId,
      selectedPeriodStart,
      selectedPeriodEnd,
      selectedPublishedAt,
      selectedValue
    )

    if (selectedPeriodEnd <= selectedPeriodStart || selectedPeriodEnd <= selectedPublishedAt) {
      toastError(t('product.form.netAssetValue.validate.errorDateEnd'))
      return
    }

    new NetAssetGateway().update(productId, nav).then(response => {
      if (response?.id) {
        reload()
        setEditableId('')
        toastSuccess(t('product.notify.update-success'))
      }
    })
  }

  const confirmDelete = (id: string) => {
    return (confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirmDeleteNav(id)} onClose={onClose}
                                   message={t('product.form.netAssetValue.confirm-delete')}/>)
      }
    }))
  }

  const onConfirmDeleteNav = (id: string) => {
    new NetAssetGateway().delete(id).then(response => {
      if (response?.id) {
        reload()
        setEditableId('')
        toastSuccess(t('product.notify.update-success'))
      }
    })
  }

  useImperativeHandle(ref, () => {
    return {
      reload
    };
  });

  return (
    <>
      {(viewModel !== null &&
          <>
              <table className="datalist__datas col-md-7">
                  <thead>
                  <tr>
                    {viewModel.heading.map((title: { name: string, sort?: string }) => (
                      <th key={uuidV4()} className={title.sort ? 'sortable' : ''} onClick={()=>sortData(title.sort)}>
                        {t(title.name)}
                        {title.sort && sortOrder.sortLabel !== title.sort &&
                            <>
                                <img src={iconChevron} className="chevron-top" alt="chevron-top" />
                                <img src={iconChevron} className="chevron-bottom" alt="chevron-bottom" />
                            </>
                        }
                        {title.sort && sortOrder.sortLabel === title.sort && sortOrder.sortOrder === SortOrder.ASC &&
                            <>
                                <img src={iconChevronSelected} className="chevron-bottom--selected" alt="chevron-bottom" />
                            </>
                        }
                        {title.sort && sortOrder.sortLabel === title.sort && sortOrder.sortOrder === SortOrder.DESC &&
                            <>
                                <img src={iconChevronSelected} className="chevron-top--selected" alt="chevron-top" />
                            </>
                        }
                      </th>
                    ))}
                    <th key={uuidV4()}>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  {viewModel.data !== undefined &&
                      viewModel.data.map((item: NetAssetValueItem) => {
                          return (
                              <tr key={uuidV4()}>
                                  <td>
                                      {item.id === editableId ?
                                          <DatePicker
                                              selected={stringToDate(selectedPublishedAt)}
                                              onChange={
                                                  (date: Date | null) => {
                                                      setSelectedPublishedAt(dateToString(date))
                                                  }
                                              }
                                              showPopperArrow={false}
                                              placeholderText="__/__/____"
                                              locale="fr"
                                              dateFormat="dd/MM/yyyy"
                                              minDate={new Date(1900, 0, 1)}
                                              maxDate={new Date(3000, 12, 31)}
                                              customInput={
                                                  <InputMask
                                                      value={item.publishedAt}
                                                      mask="99/99/9999"
                                                      maskPlaceholder="__/__/____"
                                                      alwaysShowMask={true}
                                                      required={true}
                                                  />
                                              }
                                          /> :
                                          item.publishedAt
                                      }
                                  </td>
                                  <td>
                                      {item.id === editableId ?
                                          <input
                                              value={selectedValue}
                                              onChange={e => {
                                                  setSelectedValue(parseFloat(e.target.value))
                                              }}
                                          /> :
                                          item.value
                                      }
                                  </td>
                                  <td>
                                      {item.id === editableId ?
                                          <DatePicker
                                              selected={stringToDate(selectedPeriodStart)}
                                              onChange={
                                                  (date: Date | null) => {
                                                    setSelectedPeriodStart(dateToString(date))
                                                  }
                                              }
                                              showPopperArrow={false}
                                              placeholderText="__/__/____"
                                              locale="fr"
                                              dateFormat="dd/MM/yyyy"
                                              minDate={new Date(1900, 0, 1)}
                                              maxDate={new Date(3000, 12, 31)}
                                              customInput={
                                                  <InputMask
                                                      value={item.period.start}
                                                      mask="99/99/9999"
                                                      maskPlaceholder="__/__/____"
                                                      alwaysShowMask={true}
                                                      required={true}
                                                  />
                                              }
                                          /> :
                                          item.period.start
                                      }
                                  </td>
                                  <td>
                                      {item.id === editableId ?
                                          <DatePicker
                                              selected={stringToDate(selectedPeriodEnd)}
                                              onChange={
                                                  (date: Date | null) => {
                                                    setSelectedPeriodEnd(dateToString(date))
                                                  }
                                              }
                                              showPopperArrow={false}
                                              placeholderText="__/__/____"
                                              locale="fr"
                                              dateFormat="dd/MM/yyyy"
                                              minDate={new Date(1900, 0, 1)}
                                              maxDate={new Date(3000, 12, 31)}
                                              customInput={
                                                  <InputMask
                                                      value={item.period.start}
                                                      mask="99/99/9999"
                                                      maskPlaceholder="__/__/____"
                                                      alwaysShowMask={true}
                                                      required={true}
                                                  />
                                              }
                                          /> :
                                          item.period.end
                                      }
                                  </td>
                                  <td>
                                    {item.id !== editableId ?
                                        <>
                                            <button
                                                type="button"
                                                className="button"
                                                onClick={() => {
                                                    setEditableId(item.id)
                                                    setSelectedPublishedAt(item.publishedAt)
                                                    setSelectedValue(item.value)
                                                    setSelectedPeriodStart(item.period.start)
                                                    setSelectedPeriodEnd(item.period.end)
                                                }}
                                            >
                                                <img src={iconEdit} alt=""/>
                                            </button>
                                            <button
                                                type="button"
                                                className="button"
                                                onClick={() => confirmDelete(item.id)}
                                            >
                                              <img src={iconTrash} alt=""/>
                                            </button>
                                        </>:
                                        <>
                                            <button
                                                type="button"
                                                className="button"
                                                onClick={onSubmitEdit}
                                            >
                                                <img src={iconEnable} alt=""/>
                                            </button>
                                            <button
                                                type="button"
                                                className="button"
                                                onClick={() => setEditableId('')}
                                            >
                                                <img src={iconDisable} alt=""/>
                                            </button>
                                        </>
                                    }
                                  </td>
                              </tr>
                          )
                      })
                  }
                  {viewModel.data === undefined || viewModel.data.length === 0 &&
                      <tr>
                          <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
                      </tr>
                  }
                  </tbody>
              </table>
              <div className="col-md-1" />
              <div className="col-md-6 u-txt-center">
                  <Pagination currentPage={currentPage} itemsPerPage={nbItems} numberOfItems={viewModel.pagination.numberOfItems} callback={paginate} />
              </div>
          </>
      )}
    </>
  )
})

export default NetAssetDatalist;
