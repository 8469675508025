import React, {FunctionComponent, useEffect, useState} from "react";
import {Scale} from "../../../../../domain/Product/Scale";
import {ViewModelInterface} from "../../../../../domain/Datalist/ViewModelInterface";
import ScalesPresenter from "../../../../../presenter/Product/Scale/ScalesPresenter";
import {useTranslation} from "react-i18next";
import {
  setOpenProductScaleForm,
  setOpenProductScaleItemForm
} from "../../../../store/component/event";
import iconEdit from "../../../../../assets/images/icons/datalist-edit.svg";
import {useAppDispatch} from "../../../../store/hook";
import iconAdd from "../../../../../assets/images/icons/add.svg";
import ScaleGateway from "../../../../../gateway/Product/Scale/ScaleGateway";

type Scales = {
  productId: string,
  scales?: Scale[],
  isLectureMode?: boolean
  disabledAction?: boolean
}
const ScaleDatalist: FunctionComponent<Scales> = ({scales, productId, isLectureMode, disabledAction}) => {
  const [viewModel, setViewModel] = useState<ViewModelInterface|null>(null)
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const title = (scales && viewModel?.title) ? t(viewModel.title) : t('product.form.scale.datalist.title-expired')

  useEffect(() => {
    if(scales) {
      const presenter = new ScalesPresenter(scales)
      setViewModel(presenter.immutableViewModel())
    } else {
      new ScaleGateway().listExpired(productId).then(scales => {
        const presenter = new ScalesPresenter(scales)
        setViewModel(presenter.immutableViewModel())
      })
    }
  }, [scales, productId])

  return (
    <>
      {(viewModel &&
          <div className="datalist">
            <div className="datalist__title">{title}</div>
            <div className="overflow-auto">
              <table className="datalist__datas table__ScaleDataList">
                <thead>
                <tr>
                  {
                    viewModel.heading.map((heading, index) => {
                      return <th key={`scale_head_${index}`}>
                        {t(heading.name)}
                      </th>
                    })
                  }
                </tr>
                </thead>
                <tbody>
                  {
                    viewModel.data && viewModel.data.map((scale: Scale, index) => {
                      return <tr key={`scale_tr_${index}`}>
                        <td>
                          {(!isLectureMode && !disabledAction) &&
                          <button type="button" className="button-reset"
                                  onClick={() => dispatch(setOpenProductScaleItemForm({show: true, scale: scale, scaleItem: null,  productId: productId}))}>
                            <img src={iconAdd} alt=""/>
                              <span className="u-mlb">{t('product.add.session-type')}</span>
                          </button>
                          }
                          {!disabledAction && <button
                            type="button"
                            className="button-reset"
                            onClick={() => dispatch(setOpenProductScaleForm({
                              show: true,
                              scale: scale,
                              productId: productId
                            }))}
                          >
                            <img src={iconEdit} alt=""/>
                          </button>}
                        </td>
                        <td>{scale.category.label}</td>
                        <td>
                          {scale.items && scale.items.map((scaleItem) => {
                            return <div key={`scale_head_${scaleItem.id}`} className="badge badge--min badge--without-cta badge--partner-product u-mrs"
                              onClick={() => dispatch(setOpenProductScaleItemForm({show: true, scale: scale, scaleItem: scaleItem, productId: productId, readonly: false}))}
                            >
                              <div className="badge__container">
                                <div className="badge__text etat-small">
                                  {scaleItem.category.label}
                                </div>
                              </div>
                            </div>
                          })}
                        </td>
                        <td>{scale.date}</td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
      )}
    </>
  )
}

export default ScaleDatalist
