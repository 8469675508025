import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';

import { GeneralMeetingInterface } from '../../../../../../domain/Product/GeneralMeeting';
import { ProductInterface } from '../../../../../../domain/Product/Product';
import ProductGateway from '../../../../../../gateway/Product/ProductGateway';
import InitialiazeProduct from '../../../../../../useCase/Product/Initialization/InitialiazeProduct';
import UpdateUseCase from '../../../../../../useCase/Product/Update/UpdateUseCase';
import AddUseCase from '../../../../../../useCase/Product/Add/AddUseCase';
import { ProductType } from '../../../../../../domain/Product/ProductType';
import CountryDatalist from '../../../Datalist/Country/CountryDatalist';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmationModal from '../../../Modal/ConfirmationModal';
import { toastError, toastSuccess } from '../../../../util/Toast';
import { usePrompt } from '../../../../util/Navigation';
import { setReadOnlyFormElements } from '../../../../util/setReadOnlyFormElements';
import InputDate from '../../../Elements/InputDate';
import CityAndPostcode from '../../../Datalist/CityAndPostcode/CityAndPostcode';
import Checkbox from '../../../Elements/Checkbox';

interface IFormInput {
  generalMeeting: GeneralMeetingInterface
}

type Props = {
  product?: ProductInterface
  isLectureMode?: boolean
}

const GeneralMeeting: FunctionComponent<Props> = ({product, isLectureMode}) => {
  const {t} = useTranslation()
  const { productType } = useParams()
  const productGateway = new ProductGateway()

  const { register, control, handleSubmit, reset, setValue , getValues} = useForm<IFormInput>({
    defaultValues: {
      generalMeeting: {
        accountingFirm: product?.generalMeeting ? product.generalMeeting.accountingFirm : '',
        address: product?.generalMeeting ? product.generalMeeting.address : '',
        address2: product?.generalMeeting ? product.generalMeeting.address2 : '',
        postcode: product?.generalMeeting ? product.generalMeeting.postcode : '',
        city: product?.generalMeeting ? product.generalMeeting.city : null,
        country: product?.generalMeeting ? product.generalMeeting.country : null,
        term: product?.generalMeeting ? product.generalMeeting.term : '',
        provisionalGeneralAssemblyDate: product?.generalMeeting ? product.generalMeeting.provisionalGeneralAssemblyDate : '',
        convocation: {
          bareOwner: product?.generalMeeting && product?.generalMeeting.convocation ? product?.generalMeeting.convocation.bareOwner : false,
          usufructuary: product?.generalMeeting && product?.generalMeeting.convocation ? product?.generalMeeting.convocation.usufructuary : false
        },
        vote: {
          bareOwner: product?.generalMeeting && product?.generalMeeting.vote ? product?.generalMeeting.vote.bareOwner : false,
          usufructuary: product?.generalMeeting && product?.generalMeeting.vote ? product?.generalMeeting.vote.usufructuary : false
        }
      }
    }
  });

  useEffect(() => {
      if (product?.generalMeeting) {
        setValue('generalMeeting', product.generalMeeting)
      }
    }, [product])
  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    reset(data)
    if (undefined === product) {
      product = new InitialiazeProduct().initializeProduct()
    }

    if (
      null == product.productType
    ) {
      product.productType = new ProductType(
        null,
        null,
        null,
        null,
        undefined !== productType ? productType : 'other',
        null,
        null,
        true
      )
    }

    product.generalMeeting = data.generalMeeting

    if (null !== product.id) {
      new UpdateUseCase(productGateway).execute(product).then(response => {
        if (null !== response) {
          toastSuccess(t('product.notify.update-success'))
        } else {
          toastError(t('product.notify.update-error'));
        }
      })
    }
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));


  const renderFooter = () => {
    if(isLectureMode) return null
    return <footer className={`form-bloc__footer`}>
      <button type="submit" className="button button--ink-2">{t('common.save')}</button>
      <button className="button button--ink-2 button--ink-2--outline">{t('common.cancel')}</button>
    </footer>
  }

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
      <div className="flex-container">
        <div className="col-md-12">
          <div className={`form-bloc form--bloc--generalMeeting`}>
            <div className="form-bloc__title">{t('product.form.generalMeeting.title')}</div>
            <div className="form-bloc__form flex-container">
              <div className="col-md-6">
                <div className="flex-container">
                  <div className="col-md-12">
                    <div className="form-control">
                      <label htmlFor="generalMeetingAccountingFirm" className="form-control__label">{t('product.form.generalMeeting.accountingFirm')}</label>
                      <div className="form-control__input">
                        <input type="text" id="generalMeetingAccountingFirm" {...register('generalMeeting.accountingFirm')} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-control">
                      <label htmlFor="generalMeetingAddress" className="form-control__label">{t('product.form.generalMeeting.address')}</label>
                      <div className="form-control__input">
                        <input type="text" id="generalMeetingAddress" {...register('generalMeeting.address')} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-control">
                      <label htmlFor="generalMeetingAddressSecond" className="form-control__label">{t('product.form.generalMeeting.address2')}</label>
                      <div className="form-control__input">
                        <input type="text" id="generalMeetingAddressSecond" {...register('generalMeeting.address2')} />
                      </div>
                    </div>
                  </div>
                  <CityAndPostcode control={control}
                                   nameCity="generalMeeting.city"
                                   namePostcode="generalMeeting.postcode"
                                   country={getValues('generalMeeting.country')}
                                   register={register}
                                   setValue={setValue}
                                   getValues={getValues}
                                   mode="row"
                                   classes="col-md-12 flex u-px0"
                                   disabled={isLectureMode}
                  />
                  <div className="col-md-12">
                    <div className="form-control">
                      <label htmlFor="generalMeetingCountry" className="form-control__label">{t('product.form.generalMeeting.country')}</label>
                      <div className="form-control__input">
                        <CountryDatalist control={control} name="generalMeeting.country" defaultValue={getValues('generalMeeting.country')} disabled={isLectureMode}/>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div className="col-md-12">
                    <div className="form-control">
                      <label htmlFor="provisionalGeneralAssemblyDate" className="form-control__label">
                        {t('product.form.generalMeeting.provisionalGeneralAssemblyDate')}
                      </label>
                      <div className="form-control__input">
                        <InputDate id="provisionalGeneralAssemblyDate"
                                   name="generalMeeting.provisionalGeneralAssemblyDate"
                                   register={register}
                                   control={control}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-control">
                      <label className="form-control__label">
                        {t('product.form.generalMeeting.convocation')}
                      </label>
                      <div className="flex-container">
                        <div className="col-md-3">
                          <Checkbox id={'convocationBareOwner'} name={'convocationBareOwner'} register={register} label={t('product.form.generalMeeting.convocationBareOwner')}/>
                        </div>
                        <div className="col-md-3">
                          <Checkbox id={'convocationUsufructuary'} name={'convocationUsufructuary'} register={register} label={t('product.form.generalMeeting.convocationUsufructuary')}/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-control">
                      <label className="form-control__label">
                        {t('product.form.generalMeeting.vote')}
                      </label>
                      <div className="flex-container">
                        <div className="col-md-3">
                          <Checkbox id={'voteBareOwner'} name={'voteBareOwner'} register={register} label={t('product.form.generalMeeting.voteBareOwner')}/>
                        </div>
                        <div className="col-md-3">
                          <Checkbox id={'voteUsufructuary'} name={'voteUsufructuary'} register={register} label={t('product.form.generalMeeting.voteUsufructuary')}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="flex-container">
                  <div className="col-md-6">
                    <div className="form-control">
                      <label htmlFor="generalMeetingTerm" className="form-control__label">
                        {t('product.form.generalMeeting.term')}
                      </label>
                      <div className="form-control__input">
                        <InputDate id="generalMeetingTerm"
                                   name="generalMeeting.term"
                                   register={register}
                                   control={control}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderFooter()}
    </form>
  )
}

export default GeneralMeeting
