import Caller from "../../domain/Api/Caller";
import envVariable from "../../infrastructure/presentation/util/envVariable";
import {FilterRecurrenceInterface} from '../../domain/Movement/RecurrenceList'
import RecurrenceGatewayInterface from '../../domain/Movement/RecurrenceGatewayInterface';
import {MovementListInterface} from "../../domain/Movement/MovementList";

export default class RecurrenceGateway implements RecurrenceGatewayInterface {
  getList(
    currentPage: number,
    itemsPerPage: number,
    filter: FilterRecurrenceInterface|null,
    signal?: AbortSignal,
    sort?: string,
    order?: string
  ): Promise<{data: MovementListInterface[]|null, numberOfItems: number}|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/subscriptions/recurrence?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`, {
        product_acronyme: filter?.product.label || '',
        transaction_type: filter?.transactionType || '',
        status: filter?.status.map(it => it.value) || '',
        tags: filter?.tags.map(it => it.value) || '',
        property_type: filter?.propertyType.map(it => it.value) || '',
        payment_mode: filter?.paymentMode || '',
        creation_user: filter?.user?.email || '',
        name: filter?.name || '',
        keywords: filter?.keywords || ''
    },
    signal
    ).then(data => {
      if (data) {
        return data
      }

      return {
        data: [],
        numberOfItems: 0
      }
    }).catch(response => {
      return {
        data: [],
        numberOfItems: 0
      }
    });
  }

  getExport(filter: FilterRecurrenceInterface|null): Promise<string|null> {
      return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/subscriptions/recurrence/extract`, {
        product_acronyme: filter?.product.label || '',
        transaction_type: filter?.transactionType || '',
        statut: filter?.status.map(it => it.value) || '',
        tags: filter?.tags.map(it => it.value) || '',
        property_type: filter?.propertyType.map(it => it.value) || '',
        payment_mode: filter?.paymentMode || '',
        creation_user: filter?.user?.email || '',
        name: filter?.name || '',
        keywords: filter?.keywords || ''
      })
      .then(data => {
        if (data && data.content) {
          return data.content
        }

        return null
      }).catch(response => {
        return null
      })
    }


}
