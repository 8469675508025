import React from 'react'

const Folder = ({ width = 14, height = 17, color = "#1D1D1D" }) => {
  return (<svg width={width} height={height} viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.52391 13.3899H1.76621C1.29795 13.3895 0.849044 13.2032 0.517832 12.872C0.186747 12.5409 0.000534536 12.092 0 11.6238V2.35726C0 1.88887 0.186095 1.4397 0.517297 1.10848C0.848499 0.777272 1.29769 0.591187 1.76621 0.591187H8.68845C9.1567 0.591709 9.60561 0.777933 9.93669 1.10902C10.2678 1.4401 10.454 1.88904 10.4545 2.35726V8.49173C10.4545 8.59117 10.4018 8.68318 10.3161 8.73355C10.2303 8.78405 10.1242 8.78523 10.0373 8.73681C9.28234 8.31881 8.37353 8.2858 7.59027 8.64794C6.80701 9.00995 6.24324 9.72366 6.07252 10.5695C5.90169 11.4154 6.14442 12.2918 6.72582 12.9295C6.80073 13.0117 6.82004 13.1302 6.77502 13.2318C6.73013 13.3335 6.62938 13.3992 6.5182 13.399L6.52391 13.3899ZM1.76621 1.15265C1.44674 1.15304 1.14057 1.28015 0.914825 1.50604C0.688927 1.73194 0.561831 2.03811 0.561296 2.35743V11.6239C0.561818 11.9434 0.688927 12.2496 0.914825 12.4753C1.14059 12.7012 1.44676 12.8283 1.76621 12.8289H5.95897C5.47285 12.049 5.33727 11.1007 5.58533 10.2159C5.83355 9.33115 6.44233 8.59159 7.26308 8.17829C8.08365 7.76486 9.04037 7.71578 9.89894 8.04309V2.35763C9.89842 2.03725 9.77066 1.73032 9.54357 1.50428C9.3165 1.27825 9.0089 1.15178 8.68852 1.15282L1.76621 1.15265Z" fill={color}/>
    <path d="M8.82507 3.44044H1.62788C1.47284 3.44044 1.34717 3.31476 1.34717 3.15973C1.34717 3.00482 1.47284 2.87915 1.62788 2.87915H8.82507C8.98011 2.87915 9.10578 3.00482 9.10578 3.15973C9.10578 3.31476 8.98011 3.44044 8.82507 3.44044Z" fill={color}/>
    <path d="M8.82507 5.35639H1.62788C1.47284 5.35639 1.34717 5.23072 1.34717 5.07568C1.34717 4.92078 1.47284 4.7951 1.62788 4.7951H8.82507C8.98011 4.7951 9.10578 4.92078 9.10578 5.07568C9.10578 5.23072 8.98011 5.35639 8.82507 5.35639Z" fill={color}/>
    <path d="M8.82507 7.27005H1.62788C1.47284 7.27005 1.34717 7.14451 1.34717 6.98947C1.34717 6.83444 1.47284 6.70889 1.62788 6.70889H8.82507C8.98011 6.70889 9.10578 6.83444 9.10578 6.98947C9.10578 7.14451 8.98011 7.27005 8.82507 7.27005Z" fill={color}/>
    <path d="M6.63047 9.18573H1.62788C1.47284 9.18573 1.34717 9.06019 1.34717 8.90515C1.34717 8.75012 1.47284 8.62457 1.62788 8.62457H6.63047C6.7855 8.62457 6.91117 8.75012 6.91117 8.90515C6.91117 9.06019 6.7855 9.18573 6.63047 9.18573Z" fill={color}/>
    <path d="M5.7438 11.0997H1.62788C1.47284 11.0997 1.34717 10.974 1.34717 10.8191C1.34717 10.6641 1.47284 10.5384 1.62788 10.5384H5.7438C5.89884 10.5384 6.02438 10.6641 6.02438 10.8191C6.02438 10.974 5.89884 11.0997 5.7438 11.0997Z" fill={color}/>
  </svg>)
}

export default Folder