import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { v4 as uuidV4 } from 'uuid';

import { useAppDispatch, useAppSelector } from '../../../../../store/hook';
import { setOpenChoicePartner, setOpenPartnerRelationLinkFormEvent } from '../../../../../store/component/event';
import { RelationLink } from '../../../../../../domain/Partner/RelationLink';

import { PartnerInterface } from '../../../../../../domain/Partner/Partner';
import { InitializePartner } from '../../../../../../useCase/Partner/Initialization/InitializePartner';
import UpdateUseCase from '../../../../../../useCase/Partner/Update/UpdateUseCase';
import PartnerGateway from '../../../../../../gateway/Partner/PartnerGateway';
import AddUseCase from '../../../../../../useCase/Partner/Add/AddUseCase';

import iconAdd from '../../../../../../assets/images/icons/add.svg'
import iconEdit from '../../../../../../assets/images/icons/datalist-edit.svg'
import {
  ReferentielInterface
} from '../../../../../../domain/Referentiel/ReferentielInterface'
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {usePrompt} from "../../../../util/Navigation";
import { reloadPartner } from '../../../../../store/component/partner';
import InputDate from "../../../Elements/InputDate";
import {useCheckRole} from "../../../../../../domain/CustomHooks/useCheckRole";
import RadioYesOrNot from '../../../Elements/RadioYesOrNot';

interface IFormInput {
  extranetAdministratorName: string|null
  createdAt: string|null
  underAccessCode: string|null
  digitalUse: string|null
  receiptOfPaperReporting: string|null
  needPaperBrochure: string|null
  commission: string|null
  invitation: string|null
  receiveAllBy: string|null
  ifNetworksPlatformAccessToTheList: string|null
  bsOperationsSubjectToHeadquarters: string|null
  transfer: string|null
  transferredAt: string|null
  partnerCibleTransfer: string|null
  radiation: string|null
  radiationAt: string|null
  radiationComment: string|null
  selectedInvestors: any[]
  keyWords: string|null
}

type Props = {
  referential: ReferentielInterface
  partner?: PartnerInterface
  isLectureMode?: boolean
}

const Particularities: FunctionComponent<Props> = ({partner, isLectureMode, referential}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const relationLinkArray = useAppSelector((state) => state.partner.relationLinkArray)
  const [relationLinkList, setRelationLinkList] = useState<RelationLink[]|[]>(relationLinkArray)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const roleIsAuthorized = useCheckRole("V2")

  const { register, control, watch, handleSubmit, reset, setValue, getValues } = useForm<IFormInput>()
  const watchreceiveAllBy = watch('receiveAllBy')
  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    reset(data)
    if (undefined === partner) {
      partner = (new InitializePartner()).initializePartner();
    }

    partner.externalAdministratorName = data.extranetAdministratorName
    partner.underCodeAccess = data.underAccessCode === "1"
    partner.digitalUsage = data.digitalUse === "1"
    partner.receptionPaperReporting = data.receiptOfPaperReporting
    partner.needPaper = data.needPaperBrochure
    partner.commission = data.commission
    partner.invitation = data.invitation
    partner.externalAccessList = data.ifNetworksPlatformAccessToTheList
    partner.subjectBs = data.bsOperationsSubjectToHeadquarters === "1"
    partner.transfert = data.transfer === "1"
    partner.transfertAt = data.transferredAt
    partner.radiation = data.radiation === "1"
    if(partner.radiation) {
      partner.status = referential?.partner.status.find(s=>s.label ==='Inactif')?.value.toString() ?? partner.status
    }
    partner.radiationAt = data.radiationAt
    partner.radiationComment = data.radiationComment
    partner.relations = relationLinkArray

    setIsLoading(true)
    if (partner.id !== null) {
      new UpdateUseCase(new PartnerGateway()).execute(partner).then(response => {
        dispatch(reloadPartner());
        if (null !== response) {
          toastSuccess(t('partner.notify.update-success'))
          dispatch(reloadPartner());
        } else {
          toastError(t('partner.notify.update-error'));
        }
      })
    }
  }

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm));


  useEffect(() => {
    setRelationLinkList(relationLinkArray)
  }, [relationLinkArray])

  useEffect(() => {
    if (partner) {
      setValue("extranetAdministratorName", partner.externalAdministratorName)
      setValue("createdAt", partner.createdAt)
      setValue("underAccessCode", partner.underCodeAccess ? "1" : "0")
      setValue("digitalUse", partner.digitalUsage ? "1" : "0")
      setValue("receiptOfPaperReporting", partner.receptionPaperReporting)
      setValue("needPaperBrochure", partner.needPaper)
      setValue("commission", partner.commission)
      setValue("invitation", partner.invitation)
      setValue("ifNetworksPlatformAccessToTheList", partner.externalAccessList)
      setValue("bsOperationsSubjectToHeadquarters", partner.subjectBs ? "1" : "0")
      setValue("transfer", partner.transfert ? "1" : "0")
      setValue("transferredAt", partner.transfertAt)
      setValue("radiation", partner.radiation ? "1" : "0")
      setValue("radiationAt", partner.radiationAt)
      setValue("radiationComment", partner.radiationComment)
    }
    setIsLoading(false)
  }, [partner])

  useEffect(() => {
    if (watchreceiveAllBy) {
      setValue('receiptOfPaperReporting', watchreceiveAllBy)
      setValue('needPaperBrochure', watchreceiveAllBy)
      setValue('commission', watchreceiveAllBy)
      setValue('invitation', watchreceiveAllBy)
    }
  }, [watchreceiveAllBy])

  const renderFooter = () => {
    if(isLectureMode) return null
    return <footer className={`form-bloc__footer`}>
      <button type="submit" className="button button--ink-2" disabled={isLoading}>{t('common.save')}</button>
      <button className="button button--ink-2 button--ink-2--outline">{t('common.cancel')}</button>
    </footer>
  }

  const measuredRef = useCallback((node) => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
      <div className={`form-bloc`}>
        <div className="form-bloc__title">{t('partner.form.particularities.title')}</div>
        <div className="form-bloc__form flex-container">
          <div className="col-md-6">
            <div className="form-control">
              <label htmlFor="extranetAdministratorName" className="form-control__label">{t('partner.form.communication.extranet-administrator-name')}</label>
              <div className="form-control__input">
                <input type="text" {...register('extranetAdministratorName')} />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-control">
              <label htmlFor="createdAt" className="form-control__label">{t('partner.form.communication.created-at')}</label>
              <div className="form-control__input">
                <InputDate id="createdAt"
                           name="createdAt"
                           register={register}
                           control={control}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <RadioYesOrNot id={'underAccessCode'} name={'underAccessCode'} register={register} label={t('partner.form.communication.under-access-codes')}/>
          </div>
          <div className="col-md-12">
            <RadioYesOrNot id={'digitalUse'} name={'digitalUse'} register={register} label={t('partner.form.communication.digital-use')}/>
          </div>
          <div className="col-md-12">
            <div className="flex-container">
              <div className="col-md-6">
                <table>
                  <thead style={{height: 40}}>
                  <th style={{width: 200}}/>
                  <th className="form-control__label">{t('partner.form.communication.mail')}</th>
                  <th className="form-control__label">{t('partner.form.communication.email')}</th>
                  <th className="form-control__label">{t('partner.form.communication.any')}</th>
                  </thead>
                  <tbody>
                  <tr className="u-txt-v-align-baseline">
                    <td className="form-control__label">{t('partner.form.communication.receipt-of-paper-reporting')}</td>
                    <td>
                      <div className="form-control__input col-md-1">
                        <label className="form-label form-label--input-radio">
                          <input type="radio" value="mail" {...register('receiptOfPaperReporting')} />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="form-control__input col-md-1">
                        <label className="form-label form-label--input-radio">
                          <input type="radio" value="email" {...register('receiptOfPaperReporting')} />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="form-control__input col-md-1">
                        <label className="form-label form-label--input-radio">
                          <input type="radio" value="any" {...register('receiptOfPaperReporting')} />
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr className="u-txt-v-align-baseline">
                    <td className="form-control__label">{t('partner.form.communication.need-paper-brochure')}</td>
                    <td>
                      <div className="form-control__input col-md-1">
                        <label className="form-label form-label--input-radio">
                          <input type="radio" value="mail" {...register('needPaperBrochure')} />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="form-control__input col-md-1">
                        <label className="form-label form-label--input-radio">
                          <input type="radio" value="email" {...register('needPaperBrochure')} />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="form-control__input col-md-1">
                        <label className="form-label form-label--input-radio">
                          <input type="radio" value="any" {...register('needPaperBrochure')} />
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr className="u-txt-v-align-baseline">
                    <td className="form-control__label">{t('partner.form.communication.commission')}</td>
                    <td>
                      <div className="form-control__input col-md-1">
                        <label className="form-label form-label--input-radio">
                          <input type="radio" value="mail" {...register('commission')} />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="form-control__input col-md-1">
                        <label className="form-label form-label--input-radio">
                          <input type="radio" value="email" {...register('commission')} />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="form-control__input col-md-1">
                        <label className="form-label form-label--input-radio">
                          <input type="radio" value="any" {...register('commission')} />
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr className="u-txt-v-align-baseline">
                    <td className="form-control__label">{t('partner.form.communication.invitation')}</td>
                    <td>
                      <div className="form-control__input col-md-1">
                        <label className="form-label form-label--input-radio">
                          <input type="radio" value="mail" {...register('invitation')} />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="form-control__input col-md-1">
                        <label className="form-label form-label--input-radio">
                          <input type="radio" value="email" {...register('invitation')} />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="form-control__input col-md-1">
                        <label className="form-label form-label--input-radio">
                          <input type="radio" value="any" {...register('invitation')} />
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr className="u-txt-v-align-baseline">
                    <td className="form-control__label">{t('partner.form.communication.receive-all-by')}</td>
                    <td>
                      <div className="form-control__input col-md-1">
                        <label className="form-label form-label--input-radio">
                          <input type="radio" value="mail" {...register('receiveAllBy')} />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="form-control__input col-md-1">
                        <label className="form-label form-label--input-radio">
                          <input type="radio" value="email" {...register('receiveAllBy')} />
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="form-control__input col-md-1">
                        <label className="form-label form-label--input-radio">
                          <input type="radio" value="any" {...register('receiveAllBy')} />
                        </label>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="flex-container">
              <div className="col-md-6">
                <div className="form-control">
                  <label htmlFor="ifNetworksPlatformAccessToTheList" className="form-control__label">{t('partner.form.communication.if-networks-platform-access-to-the-list')}</label>
                  <div className="form-control__input">
                    <input type="text" {...register('ifNetworksPlatformAccessToTheList')} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <RadioYesOrNot id={'bsOperationsSubjectToHeadquarters'} name={'bsOperationsSubjectToHeadquarters'} register={register} label={t('partner.form.communication.bs-operations-subject-to-headquarters')}/>
          </div>
        </div>
      </div>
      <div className={`form-bloc`}>
        <div className="form-bloc__title">{t('partner.form.relation-link.title')}</div>
        {!isLectureMode && <div className="form-bloc__shortcut">
          <button type="button" className="button-reset u-txt-with-icon"
                  onClick={() => dispatch(setOpenPartnerRelationLinkFormEvent({show: true, relationLink: null}))}>
            <img src={iconAdd} alt=""/>
            {t('partner.form.relation-link.add')}
          </button>
        </div>}
        {relationLinkList.length > 0 &&
          relationLinkList.map(relationLink => {
            return <div className="form-bloc__form flex-container" key={uuidV4()}>
              <div className="col-md-12">
                <div className="form-resume">
                  <div className="form-control u-mb0">
                    <label htmlFor="partnerNum" className="form-control__label">{t('partner.form.relation-link.id')}</label>
                    <div className="form-control__input">
                      <input type="text" disabled={true} value={`${relationLink.id}`} />
                    </div>
                  </div>
                  <div className="form-control u-mb0">
                    <label htmlFor="partnerNum" className="form-control__label">{t('partner.form.relation-link.lastname')}</label>
                    <div className="form-control__input">
                      <input type="text" disabled={true} value={`${relationLink.lastName}`} />
                    </div>
                  </div>
                  <div className="form-control u-mb0">
                    <label htmlFor="legalStatus" className="form-control__label">{t('partner.form.relation-link.firstname')}</label>
                    <div className="form-control__input">
                      <input type="text" disabled={true} value={`${relationLink.firstName}`} />
                    </div>
                  </div>
                  <div className="form-control u-mb0">
                    <label htmlFor="legalStatus" className="form-control__label">{t('partner.form.relation-link.link')}</label>
                    <div className="form-control__input">
                      <select disabled>
                        <option value="">{t('common.choice-option')}</option>
                        {referential.global.relationship.map((item, index) => <option key={index} value={item.value} selected={relationLink.link === item.value}>{item.label}</option>)}
                      </select>
                    </div>
                  </div>
                  <div className="form-resume__expend">
                    <button type="button"
                            className="button-reset u-mxs"
                            onClick={() => dispatch(setOpenPartnerRelationLinkFormEvent({show: true, relationLink: relationLink}))}
                            disabled={isLectureMode}
                    >
                      <img src={iconEdit} alt=""  />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          })
        }
      </div>
      {roleIsAuthorized && <div className={`form-bloc`}>
        <div className="form-bloc__title">{t('partner.form.radiation.title')}</div>
        <div className="form-bloc__form flex-container">
          <div className="col-md-3">
              <RadioYesOrNot id={'radiation'} name={'radiation'} register={register} label={t('partner.form.radiation.title')}/>
          </div>
          <div className="col-md-3">
            <div className="form-control">
              <label htmlFor="radiationAt" className="form-control__label">{t('partner.form.radiation.radiation-date')}</label>
              <div className="form-control__input">
                <InputDate id="radiationAt"
                           name="radiationAt"
                           register={register}
                           control={control}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-control form-control--textarea">
              <label htmlFor="radiationComment" className="form-control__label">{t('partner.form.radiation.comment')}</label>
              <div className="form-control__input">
                <textarea {...register('radiationComment')} />
              </div>
            </div>
          </div>
        </div>
      </div>}
      {renderFooter()}
    </form>
  )
}

export default Particularities
