import React, { FunctionComponent } from 'react'
import { NavLink } from 'react-router-dom'
import file from '../../../../assets/images/icons/file.svg'

import '../../../../assets/styles/components/_header-page.scss'
import { setOpenNotePadEvent } from '../../../store/component/event'
import { useAppDispatch } from '../../../store/hook'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { useTranslation } from 'react-i18next'

type Props = {
  title: string,
  notePadInfo?: { lastNotepadAt: string, countNotepad: number }
  addLink?: string
  callbackClickAddLink?: (response: boolean) => void
}

const HeaderPage: FunctionComponent<Props> = React.memo(({
                                                           title,
                                                           addLink,
                                                           callbackClickAddLink,
                                                           notePadInfo,
                                                         }) => {

  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  function handleClickAddLink() {
    return callbackClickAddLink ? callbackClickAddLink(true) : null
  }

  return (
    <div className={`header-page`}>
      <div className='header-page__title'>{title}</div>
      <div className='header-page__actions'>
        {notePadInfo && <>
          <ReactTooltip id="notification-note" place="left" />
          <BtnNotificationStyled type='button'
                                 className='button'
                                 onClick={() => dispatch(setOpenNotePadEvent({ show: true }))}
                                 data-tip={notePadInfo.lastNotepadAt ? `${t('common.tooltip.notificationIcon')} ${notePadInfo.lastNotepadAt}` :  t('common.tooltip.no-notes')}
                                 data-for="notification-note"
                                 countNotepad={notePadInfo.countNotepad}
          >
            <img src={file} />
          </BtnNotificationStyled>
        </>
        }
        {addLink &&
          <NavLink to={addLink} className='item'
                   onClick={() => handleClickAddLink()}>
            <svg width='28'
                 height='28'
                 viewBox='0 0 28 28'
                 fill='none'
                 xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M20.9045 14.0033C20.9045 14.458 20.536 14.827 20.0809 14.827H14.8235V20.0861C14.8235 20.5413 14.4546 20.9099 14 20.9099C13.5454 20.9099 13.1765 20.5413 13.1765 20.0861V14.827H7.91812C7.46353 14.827 7.09459 14.458 7.09459 14.0033C7.09459 13.5486 7.46353 13.1796 7.91812 13.1796H13.1765V7.92045C13.1765 7.46576 13.5454 7.09673 14 7.09673C14.4546 7.09673 14.8235 7.46576 14.8235 7.92045V13.1796H20.0809C20.536 13.1796 20.9045 13.5486 20.9045 14.0033ZM28 14.0033C28 17.7435 26.5435 21.2601 23.8993 23.9049C21.1699 26.635 17.5849 28 14 28C10.4151 28 6.83012 26.635 4.10071 23.9049C1.45647 21.2601 0 17.7435 0 14.0033C0 10.2627 1.45647 6.74653 4.10071 4.10167C6.74494 1.45681 10.2607 0 14 0C17.7393 0 21.2551 1.45681 23.8993 4.10167C26.5435 6.74606 28 10.2627 28 14.0033ZM26.3529 14.0033C26.3529 10.7028 25.0682 7.59991 22.7351 5.26618C20.4019 2.93245 17.2998 1.64745 14 1.64745C10.7002 1.64745 7.59812 2.93245 5.26494 5.26618C2.93176 7.59991 1.64706 10.7028 1.64706 14.0033C1.64706 17.3038 2.93176 20.4067 5.26494 22.7404C10.0819 27.5575 17.9186 27.558 22.7346 22.7404C25.0682 20.4062 26.3529 17.3038 26.3529 14.0033Z'
                fill='#1D1D1D' />
            </svg>
          </NavLink>
        }
      </div>
    </div>
  )
})

const BtnNotificationStyled = styled.button<{countNotepad?: number}>`
  position: relative;

  &::before {
    display: flex;
    align-items: center;
    position: absolute;
    content: ${({countNotepad}) => countNotepad &&`"${countNotepad}"`};
    top: 50%;
    right: 50%;
    transform: translate(100%, -115%);
    background-color: var(--pending-funds);
    color: var(--light);
    padding: 5px;
    width: auto;
    height: 20px;
    border-radius: 25px;
    font-weight: bold;
  }
`

export default HeaderPage
