import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

import ReferentielGateway from '../../../../../gateway/Referentiel/ReferentielGateway';
import { City } from '../../../../../domain/Referentiel/City/City';
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import { Country } from '../../../../../domain/Referentiel/Country/Country';
import { setOpenCityForm } from '../../../../store/component/event';
import iconAdd from '../../../../../assets/images/icons/add.svg';
import useDebounce from "../../../../../domain/CustomHooks/useDebounce";

type Props = {
  control: any
  name: string
  defaultValue: City | null
  disabled?: boolean
  classes?: string
  mode?: 'col' | 'row'
  labelContent?: string
  country?: Country | null
  setValue?: any
}

const customStyles = {
  container: (base: any) => ({
    ...base,
    width: '100%'
  }),
  control: (base: any) => ({
    ...base,
    minHeight: 25,
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: 4,
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 4,
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: '0px 6px',
  }),
  input: (base: any) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
};

const CityDatalist: FunctionComponent<Props> = ({
                                                  control,
                                                  name,
                                                  defaultValue,
                                                  disabled,
                                                  classes,
                                                  mode,
                                                  labelContent,
                                                  country,
                                                  setValue,
                                                }) => {
  const dispatch = useAppDispatch();
  const [cityArray, setCityArray] = useState<City[]>([]);
  const referentielGateway = new ReferentielGateway();
  const [displayCta, setDisplayCta] = useState<boolean>(false);
  const openCityForm = useAppSelector((state) => state.event.openCityForm);
  const [isCityReadOnly, setIsCityReadOnly] = useState(!!country ?? disabled);

  const [inputValue, setInputValue] = useState<string>('')
  const debounceValue= useDebounce<string>(inputValue, 500)

  useEffect(() => {
    if (openCityForm && openCityForm.show === false && openCityForm.postcode && country) {
      referentielGateway.getCities(openCityForm.postcode, country.id).then((response) => {
        setCityArray(response)
        if (response.length === 1) {
          setValue && setValue(name, response[0])
        }
      })
    }
  }, [openCityForm])

  useEffect(() => {
    if (defaultValue) {
      setCityArray([defaultValue]);
    }
  }, [defaultValue]);

  useEffect(() => {
    setIsCityReadOnly(false);
    setDisplayCta(false);
    setValue && setValue(name, null);
    setCityArray([]);
    if (!country) {
      setIsCityReadOnly(true);
    }
    if(country && defaultValue) {
      setValue && setValue(name, defaultValue)
    }
    if (country && country.label.toLowerCase() !== 'france') {
      setDisplayCta(true);
    } else {
      setDisplayCta(false);
    }
  }, [country]);

  useEffect(() => {
    updateReferential(inputValue)
  },[debounceValue])

  function updateReferential(search: string) {
    if (country && search.length >= 1) {
      if (country.label.toLowerCase() === 'france') {
        referentielGateway.getCity(search).then((response) => {
          setCityArray(response);
          if (response && response.length === 1) {
            setValue && setValue(name, response[0]);
          }
        });
      } else {
        referentielGateway.getCities('', country.id).then((response) => {
          setCityArray(response);
          if (response && response.length === 1) {
            setValue && setValue(name, response[0]);
          }
        });
      }

    }
  }

  return (
    <div className="flex" >

      { labelContent && <div className="form-control">
          <div className="flex items-baseline">
            <label htmlFor={ name } className="form-control__label">
              { labelContent }
            </label>
            { displayCta && !disabled &&
                <button type="button" className="button-reset u-txt-with-icon u-mls"
                        onClick={ () => dispatch(setOpenCityForm({ show: true, postcode: '', country: country })) }>
                    <img src={ iconAdd } alt="add" title="Ajouter une ville"/>
                </button>
            }

          </div>
        </div> }

      <div className="w-full">
        <Controller
          name={ name }
          control={ control }
          render={ ({
                      field: { onChange, value, name },
                    }) => (
            <>
              <Select
                className={classes}
                name={ name }
                onInputChange={ (string) => setInputValue(string) }
                defaultValue={ defaultValue }
                options={ cityArray }
                onChange={ (option) => onChange(option) }
                styles={ customStyles }
                isClearable={ true }
                isDisabled={ isCityReadOnly || false }
                value={ value }
                aria-label={'city'}
              />
            </>
          ) }
        />

      </div>
    </div>
  );
};

export default CityDatalist;
