import {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'

import {useAppSelector} from '../../../../store/hook'

import HeaderPage from '../../../component/HeaderPage/HeaderPage'
import Tab from '../../../component/Tab/PreSubscription/Tab'
import RecurrenceDatalist from '../../../component/Datalist/RecurrenceDatalist'

import '../../../../../assets/styles/page/_movement.scss'
import {useNavigate} from "react-router-dom";
import RecurrenceDatalistFilter from "../../../component/Sidebar/Movement/RecurrenceDatalistFilter";

const MovementRecurrence: FunctionComponent = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const transactionRules = useAppSelector((state) => state.me.me?.rules.transaction)

  if(!transactionRules?.visible) navigate(`/`)

  return (
    <>
      <RecurrenceDatalistFilter />
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        <HeaderPage title={t('movement.pre-subscription.title')}
                    addLink={transactionRules?.actions?.create ? `/${t('url.movements.choice-type')}` : undefined}
        />
        <Tab />
        <div className="partner-keys-figures">
          <div className="movement-keys-figures__title">{t('common.key-figures')}</div>
        </div>
       {transactionRules?.actions?.list && <RecurrenceDatalist/>}
      </main>
    </>
  )
}

export default MovementRecurrence
