import {RedemptionWithdrawal} from '../../../domain/Movement/RedemptionWithdrawal'
import Caller from '../../../domain/Api/Caller'
import envVariable from '../../../infrastructure/presentation/util/envVariable'
import {ActionList} from '../../../domain/Movement/Action/Action'
import TransactionGatewayInterface from "../../../domain/Movement/TransactionGatewayInterface";

export default class RedemptionWithdrawalGateway implements TransactionGatewayInterface{

  private prepareData(redemptionWithdrawal: RedemptionWithdrawal): Record<string, unknown> {
    return {
      "propertyType": redemptionWithdrawal.propertyType,
      "possessionMode": redemptionWithdrawal.possessionMode,
      "product": redemptionWithdrawal.product?.id,
      "shareCount": redemptionWithdrawal.shareCount,
      "horodatage": redemptionWithdrawal.horodatage,
      "comment": redemptionWithdrawal.comment,
      "note": redemptionWithdrawal.note,
      "allShares": redemptionWithdrawal.allShares,
      "sharePrice": redemptionWithdrawal.sharePrice,
      "valueDate": redemptionWithdrawal.valueDate,
      "penalty": redemptionWithdrawal.penalty,
      "flatFee": redemptionWithdrawal.flatFee,
      "capitalGain": redemptionWithdrawal.capitalGain,
      "withdrawalAmount": redemptionWithdrawal.withdrawalAmount,
      "subType": redemptionWithdrawal.subType,
      "transactionExecutionAt":  redemptionWithdrawal.transactionExecutionAt,
      "investorBank": redemptionWithdrawal.investorBank ? {
        "amount": redemptionWithdrawal.investorBank.amount,
        "iban": redemptionWithdrawal.investorBank.iban,
        "bank": redemptionWithdrawal.investorBank.bank,
        "swift": redemptionWithdrawal.investorBank.swift
      } : null,
      "lenderBank": redemptionWithdrawal.lenderBank ? {
        "amount": redemptionWithdrawal.lenderBank.amount,
      } : null,
      "investors": redemptionWithdrawal.investors?.map((investor) => {
        return {
          "type" : investor.type,
          "prospect": investor.prospect ? investor.prospect.id : null,
          "partnerType": investor.partnerType,
          "partner": investor.partner?.id || null,
          "documents": investor.documents,
          "bankInformations": investor.bankInformations.map((bankI) => {
            return {
              "id": bankI.id,
              "type": bankI.type,
              "rumCode": bankI.rumCode,
              "rumStatus": bankI.rumStatus,
              "directDebitMandateAccepted": bankI.directDebitMandateAccepted,
              "bankInformation": bankI.bankInformation?.id
            }
          })
        }
      }),
      "slices": redemptionWithdrawal.slices?.map((slice) => {
        return {
          "id" : slice.id,
          "countShareSelected": slice.countShareSelected
        }
      })
    }
  }

  get(uuid:string) : Promise<RedemptionWithdrawal> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/withdrawals/${uuid}`, {})
    .then(data => {
      if (data) {
        return data
      }

      return null
    })
  }

  create(redemptionWithdrawal: RedemptionWithdrawal): Promise<RedemptionWithdrawal> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/withdrawals`,
      this.prepareData(redemptionWithdrawal)
    )
    .then(data => {
      if (data?.id) {
        return data
      }

      return null
    })
  }

  update(redemptionWithdrawal: RedemptionWithdrawal): Promise<RedemptionWithdrawal> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/withdrawals/${redemptionWithdrawal.id}`,
      this.prepareData(redemptionWithdrawal)
    )
    .then(data => {
      if (data?.id) {
        return data
      }

      return null
    })
  }

  getActions(uuid: string): Promise<ActionList> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/withdrawals/${uuid}/actions`, {})
    .then(data => {
      if (data) {
        return data
      }

      return null
    })
    .catch(() => {
      return null
    })
  }

  setAction(uuid: string, actionSlug: string, date: string, reason: string|null): Promise<ActionList> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/withdrawals/action/${uuid}/${actionSlug}`, {
      'date': date,
      'reason': reason
    })
    .then(data => {
      if (data) {
        return data
      }

      return null
    })
  }

  updateAction(uuid: string, actionSlug: string, date: string|null): Promise<ActionList> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/wallet/transaction/${uuid}/${actionSlug}`, {
      'date': date
    })
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
  }
}
