import DismembermentGateway from "../../../gateway/Movement/Dismemberment/DismembermentGateway";
import {DismembermentInterface} from "../../../domain/Movement/Dismemberment/Dismemberment";

export default class DismembermentGetUseCase
{
  public dismembermentGateway

  constructor(
    dismembermentGateway: DismembermentGateway
  ) {
    this.dismembermentGateway = dismembermentGateway
  }

  async execute(id: string): Promise<DismembermentInterface> {
    return await this.dismembermentGateway.get(id).then(response => {

      if (response && response.investors) {
        response.investors.sort(function (investor) {
          if (investor.type === "investor") {
            return -1
          }
          if (investor.type === "usufruct") {
            return 1
          }

          return 0
        })
      }

      return response
    })
  }
}
