import React, {FunctionComponent, useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {SubmitHandler, useForm, useFormState} from 'react-hook-form'

import {DistributionInterface} from '../../../../../../domain/Product/Distribution'
import ProductGateway from '../../../../../../gateway/Product/ProductGateway'
import InitialiazeProduct from '../../../../../../useCase/Product/Initialization/InitialiazeProduct'
import {ProductInterface} from '../../../../../../domain/Product/Product'
import UpdateUseCase from '../../../../../../useCase/Product/Update/UpdateUseCase'
import AddUseCase from '../../../../../../useCase/Product/Add/AddUseCase'
import {ProductType} from '../../../../../../domain/Product/ProductType'
import {confirmAlert} from "react-confirm-alert";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import {toastError, toastSuccess} from "../../../../util/Toast";
import {usePrompt} from "../../../../util/Navigation";
import {setReadOnlyFormElements} from "../../../../util/setReadOnlyFormElements";
import {
  ReferentielInterface
} from '../../../../../../domain/Referentiel/ReferentielInterface'

interface IFormInput {
  distribution: DistributionInterface
  distributionRadio: string
}

type Props = {
  product?: ProductInterface
  referential: ReferentielInterface
  isLectureMode?: boolean
}
const Distribution: FunctionComponent<Props> = ({product, isLectureMode, referential}) => {
  const {t} = useTranslation()
  const { productType } = useParams()
  const productGateway = new ProductGateway()

  useEffect(() => {
    if (product?.distribution) {
      setValue('distribution', product.distribution)
      setValue('distributionRadio', product.distribution.enabled ? "1" : "0")
    }
  }, [product])

  const { register, control, handleSubmit, reset, setValue } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (<ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose}/>)
      }
    });
  }
  const onConfirm = (data: any) => {
    reset(data)
    if (undefined === product) {
      product = new InitialiazeProduct().initializeProduct()
    }

    if (
      null == product.productType &&
      referential.product.product_type.findIndex(type => type.value === productType) > -1
    ) {
      product.productType = new ProductType(
        null,
        null,
        null,
        null,
        undefined !== productType ? productType : 'other',
        null,
        null,
        true
      )
    }

    product.distribution = data.distribution

    if (null !== product.id) {
      new UpdateUseCase(productGateway).execute(product).then(response => {
        if (null !== response) {
          toastSuccess(t('product.notify.update-success'))
        } else {
          toastError(t('product.notify.update-error'));
        }
      })
    }
  };

  const { isDirty } = useFormState({
    control
  });
  usePrompt(isDirty, handleSubmit(onConfirm));


  const renderFooter = () => {
    if(isLectureMode) return null
    return <footer className={`form-bloc__footer`}>
      <button type="submit" className="button button--ink-2">{t('common.save')}</button>
      <button className="button button--ink-2 button--ink-2--outline">{t('common.cancel')}</button>
    </footer>
  }

  const measuredRef = useCallback((node) => {
    console.log("set read only", isLectureMode)
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
      <div className="flex-container">
        <div className="col-md-6">
          <div className={`form-bloc form--bloc--distribution`}>
            <div className="form-bloc__title">{t('product.form.distribution.title')}</div>
            <div className="form-bloc__form flex-container">
              <div className="col-md-12">
                <div className="form-control">
                  <label htmlFor="distributionEnabled" className="form-control__label">{t('product.form.distribution.enabled')}</label>
                  <div className="form-control form-control--label-auto">
                    <div className="form-control__input">
                      <label className="form-label form-label--input-radio">
                        <input
                          type="radio"
                          value="1"
                          {...register('distributionRadio')}
                          onChange={(e) => {
                            if (product && product.distribution) {
                              product.distribution.enabled = true
                            }
                          }}
                        />
                        {t('common.yes')}
                      </label>
                    </div>
                    <div className="form-control__input">
                      <label className="form-label form-label--input-radio">
                        <input
                          type="radio"
                          value="0"
                          {...register('distributionRadio')}
                          onChange={(e) => {
                            if (product && product.distribution) {
                              product.distribution.enabled = false
                            }
                          }}
                        />
                        {t('common.no')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-control">
                  <label htmlFor="distributionPeriodicity" className="form-control__label">{t('product.form.distribution.periodicity')}</label>
                  <div className="form-control__input">
                    <select {...register('distribution.periodicity')}>
                      <option value="">{t('common.choice-option')}</option>
                      {referential.product.periodicity.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-control">
                  <label htmlFor="distributionTerm" className="form-control__label">{t('product.form.distribution.term')}</label>
                  <div className="form-control__input">
                    <select {...register('distribution.term')}>
                      <option value="">{t('common.choice-option')}</option>
                      {referential.product.distribution_term.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderFooter()}
    </form>
  )
}

export default Distribution
